<nav>
  <div class="nav-title title-p2">
    {{ 'SETTINGS.NAV.ORGANIZATION_TITLE' | translate }}
  </div>
  <a
    class="nav-button"
    [routerLink]="routesEnum.COMPANY_PROFILE"
    routerLinkActive="is-active"
    *ngIf="canAccessProfilePage$ | async"
  >
    <span>{{ 'SETTINGS.NAV.COMPANY_PROFILE' | translate }}</span>
  </a>
  <a
    class="nav-button"
    [routerLink]="routesEnum.ROLES"
    routerLinkActive="is-active"
    *ngIf="canAccessAdministrationPage$ | async"
  >
    <span>{{ 'SETTINGS.NAV.ROLES_AND_PERMISSIONS' | translate }}</span>
  </a>
  <a
    class="nav-button"
    [routerLink]="routesEnum.GROUPS"
    routerLinkActive="is-active"
    *ngIf="groupsPageFlag$ | async"
  >
    <span>{{ 'SETTINGS.NAV.GROUPS' | translate }}</span>
  </a>
  <div class="nav-title title-p2">
    {{ 'SETTINGS.NAV.SYSTEM_TITLE' | translate }}
  </div>
  <a
    class="nav-button"
    [routerLink]="routesEnum.NOTIFICATIONS"
    routerLinkActive="is-active"
    *ngIf="notificationsPageFlag$ | async"
  >
    <span>{{ 'SETTINGS.NAV.NOTIFICATIONS' | translate }}</span>
  </a>
  <a
    class="nav-button"
    [routerLink]="routesEnum.PROFILE"
    routerLinkActive="is-active"
    *ngIf="canAccessMyProfilPage$ | async"
  >
    <span>{{ 'SETTINGS.NAV.PROFILE_LINK' | translate }}</span>
  </a>
  <a
    class="nav-button"
    [routerLink]="routesEnum.LANGUAGE_AND_FORMATS"
    routerLinkActive="is-active"
    *ngIf="canAccessLanguageAndFormatPage$ | async"
  >
    <span>{{ 'SETTINGS.NAV.LANGUAGE_AND_FORMATS_LINK' | translate }}</span>
  </a>
</nav>
<div class="settings-body" [class]="displaySetting">
  <router-outlet></router-outlet>
</div>
