<div class="main">
  <ng-content></ng-content>
</div>
<div class="controls">
  <button
    astus-design-button
    size="sm"
    [color]="processState === 'error' ? 'danger' : 'primary'"
    [disabled]="disabled"
    [ngClass]="{
      blockInput: processState === 'success' || processState === 'processing'
    }"
    (click)="saveEmitter.emit(); this.blurButton()"
  >
    <ng-container *ngIf="processState === 'processing'; else buttonTemplate">
      <ad-spinner class="light"></ad-spinner>
    </ng-container>

    <ng-template #buttonTemplate>
      <ng-container>
        {{ saveButtonLabel }}
      </ng-container>
    </ng-template>
  </button>
  <button
    astus-design-button
    size="sm"
    kind="text"
    color="resolve"
    theme="light"
    class="cancel-btn"
    [disabled]="disabled && !enableCancel"
    [ngClass]="{
      blockInput: processState === 'success' || processState === 'processing'
    }"
    (click)="cancelEmitter.emit(); this.blurButton()"
  >
    {{ 'SETTINGS.BUTTON_LAYOUT.CANCEL' | translate }}
  </button>
</div>
