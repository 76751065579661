export enum settingsNotificationsScheduleDaysEnum {
  EVERYDAY = 'everyday',
  MONDAYTOFRIDAY = 'mondayToFriday',
  CUSTOM = 'custom',
}

export enum settingsNotificationsInactivityEnum {
  DISABLED = 'disabled',
  INSTANTLY = 'instantly',
  MINUTE = 60,
  MINUTES5 = 300,
  MINUTES15 = 900,
  MINUTES30 = 1800,
  HOUR = 3600,
}
