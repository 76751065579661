<form class="vehicle-form-container" [formGroup]="vehicleForm">
  <!-- General information vehicle fields container -->
  <div class="fields-container">
    <div class="section-divider">
      <span class="text-caption--semibold">
        {{ 'VEHICLES_FORM.SECTION.GENERAL' | translate }}</span
      >
    </div>
    <ad-input
      [label]="'VEHICLES_FORM.LABEL.NAME' | translate"
      [placeholder]="'VEHICLES_FORM.PLACEHOLDER.NAME' | translate"
      formControlName="name"
    ></ad-input>
    <ad-dropdown
      [label]="'VEHICLES_FORM.LABEL.TYPE' | translate"
      [placeholder]="'VEHICLES_FORM.PLACEHOLDER.TYPE' | translate"
      formControlName="vehicleType"
      [items]="vehicleFormDropdowns.vehicleType"
      (dropdownElementChange)="onItemTypeChange($event, 'modelId')"
    ></ad-dropdown>

    <div class="field-group" *ngIf="groupsFlag$ | async">
      <astus-resource-group-input
        [label]="'VEHICLES_FORM.LABEL.GROUP' | translate"
        [placeholder]="'VEHICLES_FORM.PLACEHOLDER.GROUP' | translate"
        formControlName="groups"
      >
      </astus-resource-group-input>
    </div>
  </div>

  <!-- Details vehicle fields container -->
  <div class="fields-container--space-between">
    <div class="section-divider">
      <span class="text-caption--semibold">
        {{ 'VEHICLES_FORM.SECTION.DETAILS' | translate }}
      </span>
    </div>

    <ad-input
      [label]="'VEHICLES_FORM.LABEL.VIN' | translate"
      [placeholder]="'VEHICLES_FORM.PLACEHOLDER.VIN' | translate"
      formControlName="vin"
      [required]="true"
    ></ad-input>
    <ad-input
      [label]="'VEHICLES_FORM.LABEL.LICENSE' | translate"
      [placeholder]="'VEHICLES_FORM.PLACEHOLDER.LICENSE' | translate"
      formControlName="plateNumber"
    ></ad-input>

    <div class="two-field-item">
      <div class="dropdown-container">
        <ad-dropdown
          [label]="'VEHICLES_FORM.LABEL.COUNTRY' | translate"
          [placeholder]="'VEHICLES_FORM.PLACEHOLDER.COUNTRY' | translate"
          [items]="vehicleFormDropdowns.country"
          formControlName="country"
        ></ad-dropdown>
      </div>

      <div class="dropdown-container">
        <ad-dropdown
          class="dropdown"
          [label]="'VEHICLES_FORM.LABEL.PROVINCE' | translate"
          [placeholder]="'VEHICLES_FORM.PLACEHOLDER.PROVINCE' | translate"
          [items]="vehicleFormDropdowns.province"
          formControlName="province"
        ></ad-dropdown>
      </div>
    </div>

    <div class="three-field-item">
      <ad-input
        [label]="'VEHICLES_FORM.LABEL.BRAND' | translate"
        [placeholder]="'VEHICLES_FORM.PLACEHOLDER.BRAND' | translate"
        formControlName="brand"
      ></ad-input>
      <ad-input
        [label]="'VEHICLES_FORM.LABEL.MODEL' | translate"
        [placeholder]="'VEHICLES_FORM.PLACEHOLDER.MODEL' | translate"
        formControlName="model"
      ></ad-input>
      <ad-input
        [type]="'number'"
        [label]="'VEHICLES_FORM.LABEL.YEAR' | translate"
        [placeholder]="'VEHICLES_FORM.PLACEHOLDER.YEAR' | translate"
        formControlName="year"
      ></ad-input>
    </div>

    <ad-dropdown
      [label]="'VEHICLES_FORM.LABEL.FUEL_TYPE' | translate"
      [placeholder]="'VEHICLES_FORM.PLACEHOLDER.FUEL_TYPE' | translate"
      formControlName="fuelType"
      [items]="vehicleFormDropdowns.fuelType"
    ></ad-dropdown>
    <ad-dropdown
      [label]="'VEHICLES_FORM.LABEL.CARRIER' | translate"
      [placeholder]="'VEHICLES_FORM.PLACEHOLDER.CARRIER' | translate"
      formControlName="carrier"
      [items]="vehicleFormDropdowns.carrier"
      (dropdownElementChange)="onItemTypeChange($event, 'carrierId')"
    ></ad-dropdown>
    <ad-dropdown
      [label]="'VEHICLES_FORM.LABEL.HOMEPORT' | translate"
      [placeholder]="'VEHICLES_FORM.PLACEHOLDER.HOMEPORT' | translate"
      formControlName="homePort"
      [items]="vehicleFormDropdowns.homePort"
      (dropdownElementChange)="onItemTypeChange($event, 'homePortId')"
    ></ad-dropdown>
    <ad-dropdown
      [label]="'VEHICLES_FORM.LABEL.TIMEZONE' | translate"
      [placeholder]="'VEHICLES_FORM.PLACEHOLDER.TIMEZONE' | translate"
      formControlName="timezone"
      [items]="vehicleFormDropdowns.timeZone"
    ></ad-dropdown>
  </div>

  <!-- Telematic module fields container -->
  <div class="fields-container--space-between">
    <div class="section-divider">
      <span class="text-caption--semibold">
        {{ 'VEHICLES_FORM.SECTION.TELEMATIC' | translate }}
      </span>
    </div>

    <ad-dropdown
      [label]="'VEHICLES_FORM.LABEL.TAG_SERIAL_NUMBER' | translate"
      [placeholder]="'VEHICLES_FORM.PLACEHOLDER.TAG_SERIAL_NUMBER' | translate"
      formControlName="tagSerialNumber"
      [items]="vehicleFormDropdowns.tagSerialNumber"
      (dropdownElementChange)="onItemTypeChange($event, 'iotDeviceId')"
    ></ad-dropdown>
    <ad-dropdown
      [label]="'VEHICLES_FORM.LABEL.IMEI' | translate"
      [placeholder]="'VEHICLES_FORM.PLACEHOLDER.IMEI' | translate"
      [items]="vehicleFormDropdowns.imei"
      formControlName="imei"
      (dropdownElementChange)="onItemTypeChange($event, 'iotDeviceId')"
    ></ad-dropdown>
    <ad-dropdown
      [label]="'VEHICLES_FORM.LABEL.MODULE_TYPE' | translate"
      [placeholder]="'VEHICLES_FORM.PLACEHOLDER.MODULE_TYPE' | translate"
      formControlName="tagType"
      [items]="vehicleFormDropdowns.moduleType"
      (dropdownElementChange)="onItemTypeChange($event, 'iotDeviceId')"
    ></ad-dropdown>

    <div class="two-field-item">
      <ad-input
        [label]="'VEHICLES_FORM.LABEL.SOFTWARE_VERSION' | translate"
        formControlName="softwareVersion"
      ></ad-input>
      <ad-input
        [label]="'VEHICLES_FORM.LABEL.LAST_CONNECTION' | translate"
        formControlName="lastConnection"
      ></ad-input>
    </div>
  </div>

  <!-- Share resources field container -->
  <div class="fields-container--space-between">
    <div class="section-divider">
      <span class="text-caption--semibold">
        {{ 'VEHICLES_FORM.SECTION.SHARE' | translate }}
      </span>
    </div>

    <div class="two-field-item">
      <ad-radio
        [size]="'sm'"
        [text]="'VEHICLES_FORM.RADIO.INACTIVE' | translate"
        value="inactive"
        formControlName="active"
        [selectedValue]="vehicleForm.controls['active'].value"
      ></ad-radio>
      <ad-radio
        [size]="'sm'"
        [text]="'VEHICLES_FORM.RADIO.ACTIVE' | translate"
        value="active"
        formControlName="active"
        [selectedValue]="vehicleForm.controls['active'].value"
      ></ad-radio>
    </div>
  </div>
</form>
