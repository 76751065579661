import { UserFormDropdown } from '../models/user-form-dropdown.models';

export const userDropdownsData: UserFormDropdown = {
  language: { items: [], placeholder: null },
  rolesPermissions: { items: [], placeholder: null },
  licenceClass: { items: [], placeholder: null },
  homePortType: { items: [], placeholder: null },
  timeZone: { items: [], placeholder: null },
  licenseIssuingCountry: { items: [], placeholder: null },
  licenseIssuingSubDivision: { items: [], placeholder: null },
  singleJob: {
    items: [
      { label: 'JH00020003', value: 'JH00020003' },
      { label: 'JH00020004', value: 'JH00020004' },
      { label: 'JH00020005', value: 'JH00020005' },
    ],
    placeholder: null,
  },
};
