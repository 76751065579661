export class SortingHelper {
  static sortByProperty<T>(arr: T[], key: keyof T, order: 1 | -1 = 1): T[] {
    return arr.sort((a, b) => {
      const valueA = a[key];
      const valueB = b[key];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueA.localeCompare(valueB) * order;
      }

      if (valueA === null || valueA === undefined) {
        return 1 * order;
      }
      if (valueB === null || valueB === undefined) {
        return -1 * order;
      }

      if (typeof valueA === 'boolean' && typeof valueB === 'boolean') {
        return ((valueA ? 1 : -1) * order - (valueB ? 1 : -1)) * order;
      }

      return ((valueA as number) - (valueB as number)) * order;
    });
  }
}
