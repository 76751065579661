import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';

import { buttonProcessStateEnum } from '../settings.enum';

enum mobileheaderTitle {
  'company-profile' = 'SETTINGS.COMPANY_PROFILE',
  groups = 'SETTINGS.GROUPS',
  'language-and-formats' = 'SETTINGS.LANGUAGE_AND_FORMATS',
  notifications = 'SETTINGS.NOTIFICATIONS',
  'roles-and-permissions' = 'SETTINGS.ROLES_AND_PERMISSIONS',
  profile = 'SETTINGS.PROFILE',
  settings = '',
}

@Component({
  selector: 'astus-settings-button-layout',
  templateUrl: './settings-button-layout.component.html',
  styleUrls: ['./settings-button-layout.component.scss'],
})
export class SettingsButtonLayoutComponent implements OnChanges, OnInit {
  mobileHeader = mobileheaderTitle;
  @Input()
  processState: buttonProcessStateEnum = buttonProcessStateEnum.READY;

  @Input() disabled = true;
  @Input() enableCancel = false; // optional way to let cancel button be enabled with the submit button being disabled

  saveButtonLabel = '';

  saveButtonSave$ = this.translateService.stream('SETTINGS.BUTTON_LAYOUT.SAVE');
  saveButtonSave = '';
  saveButtonSaved$ = this.translateService.stream(
    'SETTINGS.BUTTON_LAYOUT.SAVE_CONFIRM'
  );
  saveButtonSaved = '';
  saveButtonRetry$ = this.translateService.stream(
    'SETTINGS.BUTTON_LAYOUT.RETRY'
  );
  saveButtonRetry = '';

  @Output()
  saveEmitter = new EventEmitter();

  @Output()
  cancelEmitter = new EventEmitter();

  @Output()
  resetProcessState = new EventEmitter();

  constructor(private translateService: TranslateService) {}

  ngOnChanges(): void {
    this.setSaveButtonLabel();
  }
  ngOnInit(): void {
    combineLatest([
      this.saveButtonSave$,
      this.saveButtonSaved$,
      this.saveButtonRetry$,
    ]).subscribe(([save, saved, retry]) => {
      this.saveButtonSave = save;
      this.saveButtonSaved = saved;
      this.saveButtonRetry = retry;

      this.setSaveButtonLabel();
    });
  }

  setSaveButtonLabel() {
    if (this.processState === buttonProcessStateEnum.SUCCESS) {
      this.saveButtonLabel = this.saveButtonSaved;
      setTimeout(() => {
        this.resetProcessState.emit();
      }, 3000);
    } else if (this.processState === buttonProcessStateEnum.ERROR) {
      this.saveButtonLabel = this.saveButtonRetry;
    } else {
      this.saveButtonLabel = this.saveButtonSave;
    }
  }

  blurButton() {
    const button = document.activeElement as HTMLElement;
    if (button) {
      button.blur();
    }
  }
}
