import { Component, Input } from '@angular/core';
import { IconAtomType } from '@common/ng-design-system';

@Component({
  selector: 'astus-map-list-empty-state',
  templateUrl: './map-list-empty-state.component.html',
  styleUrls: ['./map-list-empty-state.component.scss'],
})
export class MapListEmptyStateComponent {
  @Input()
  errorMessage?: string;

  @Input()
  errorDescription?: string;

  @Input()
  icon: IconAtomType | undefined = undefined;
}
