<ad-card
  *ngIf="user"
  (hoveredCardChange)="hoveredEvent.emit(user)"
  [moreOptions]="moreOptions"
  (popoverOptionSelect)="handleMoreOptionClick($event)"
  class="card-content"
  (clickCardEvent)="cardClick.emit({ data: user })"
  (assignEvent)="assignEvent.emit(user)"
  [title]="user.title || ''"
  [titleSmall]="user.title2 || ''"
  [subtitle]="user.subtitle || ''"
  [subtitleSmall]="user.subtitle2 || ''"
  [hideAvatars]="true"
  [infoItems]="[
    { status: statusColor, text: statusText },
    { icon: 'time', text: user.textInfo || '' }
  ]"
  [ctaButtonText]="!hideAssignButton ? ('CARDS.ASSIGN_BTN' | translate) : ''"
  [tooltipPosition]="isFirst ? 'bottom' : 'top'"
  [isSelected]="isSelected"
></ad-card>
