import { Location } from '@angular/common';
import { Injectable } from '@angular/core';

import { Feature, GeoJsonProperties, Geometry, Point } from 'geojson';

import * as mapboxgl from 'mapbox-gl';

import { Observable, combineLatest } from 'rxjs';

import {
  CircuitBounceRouteType,
  IMapboxDataset,
  IMapboxDatasetFeatures,
  IMapboxDirectionLocalization,
  IMapboxDirectionProfiles,
  IMapboxFeatureType,
  IMapboxGeometry,
  IMapboxLayoutProperties,
  IMapboxPaintProperties,
  IMapboxRoute,
  MapViewArea,
} from './mapbox.interface';

import { MapboxService } from './mapbox.service';

// Status colors
export const STATUS_ASSIGNED = 'assigned';
export const STATUS_UNASSIGNED = 'unassigned';

// Reusable ids for the map elements
// Vehicle
export const VEHICLE_BASE_STRING = 'vehicle-';
export const RADAR_VEHICLE_BASE_STRING = 'radar-vehicle-';
export const POPOVER_VEHICLE_BASE_STRING = 'vehicle-popover-';
export const MARKER_VEHICLE_BASE_STRING = 'vehicle-marker-';
export const CARD_VEHICLE_BASE_STRING = 'card-vehicle-';

// Circuit
export const MARKER_BUILDING_BASE_STRING = 'building-marker-';
export const MARKER_START_PIN_BASE_STRING = 'start-pin-marker-';
export const POPOVER_CIRCUIT_BASE_STRING = 'circuit-popover-';
export const CARD_CIRCUITS_BASE_STRING = 'card-circuits-';

// Map circuit colors
export const SELECTED_CIRCUIT_COLOR_LINE = '#33AB72';
export const SELECTED_CIRCUIT_COLOR_STROKE = '#007748';
export const UNSELECTED_CIRCUIT_COLOR_LINE = '#ABB1B5';
export const UNSELECTED_CIRCUIT_COLOR_STROKE = '#333E48';
export const CIRCUIT_TRANSIT_STROKE = '#53616E';

// Map cluster colors
export const ASSIGNED_CLUSTER_COLOR = '#00964F';
export const UNASSIGNED_CLUSTER_COLOR = '#79838D';
export const CLUSTER_TEXT_COLOR = '#FFFFFF';

// Map vehicle colors
export const ON_DUTY_VEHICLE_COLOR = '#33AB72';
export const MAINTENANCE_VEHICLE_COLOR = '#ABB1B5';
export const OUT_OF_SERVICE_VEHICLE_COLOR = '#EF4444';
export const OFF_DUTY_VEHICLE_COLOR = '#F1AE01';

// Map traffic color
export const LOW_TRAFFIC_COLOR = '#33AB72';
export const MODERATE_TRAFFIC_COLOR = '#FBBF24';
export const HEAVY_TRAFFIC_COLOR = '#F87171';
export const SEVERE_TRAFFIC_COLOR = '#F87171';
export const UNKNOWN_TRAFFIC_COLOR = '#000000';

// Map params
export const MAP_ZOOM_PARAM = 'zoom';
export const MAP_LAT_PARAM = 'lat';
export const MAP_LNG_PARAM = 'lng';

@Injectable({
  providedIn: 'root',
})
export class MapboxHelper {
  constructor(
    private mapboxService: MapboxService,
    private location: Location
  ) {}

  /**
   * Generate and sets a polyline on the map
   *
   * @param map - Mapbox map object
   * @param coordinates - Array of coordinates, up to 100 per request
   * @param id - The string to use as a layerId
   * @param profile - The type of directions profile to be used to calculate de path
   * @param boundWhenComplete - If you want the map to set the map's bound to update with the new route
   */
  getRoute(
    map: mapboxgl.Map,
    coordinates: IMapboxDirectionLocalization[],
    id = 'route',
    profile: IMapboxDirectionProfiles = 'driving',
    boundWhenComplete = true
  ) {
    this.mapboxService
      .getDirections(profile, coordinates)
      .subscribe((response) => {
        this.addMarkers(map, coordinates);
        this.generateRoute(map, response.routes[0], id);
        // this.generateInstructions(response.routes[0]);

        if (boundWhenComplete) {
          this.boundMap(map, response.routes[0].geometry);
        }
      });
  }

  /**
   * Takes the data from the Route API and add a line ti the map
   *
   * @param map - Mapbox map object
   * @param route - Raw data supplied by the Route API
   * @returns An ID of the route
   */
  generateRoute(map: mapboxgl.Map, route: IMapboxRoute, id = 'route'): string {
    const currRoute = route.geometry.coordinates;
    const geojson: Feature<Geometry, GeoJsonProperties> = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: currRoute,
      },
    };

    // Checks if a route with matching ID is on the map already
    if (map.getSource('route')) {
      (map.getSource('route') as mapboxgl.GeoJSONSource).setData(geojson);
    }
    // otherwise, we'll make a new request
    else {
      map.addLayer({
        id: id,
        type: 'line',
        source: {
          type: 'geojson',
          data: geojson,
        },
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': UNSELECTED_CIRCUIT_COLOR_LINE,
          'line-width': 5,
        },
      });
    }

    return id;
  }

  /**
   * Add an array of markers to the map
   *
   * @param map - Mapbox map object
   * @param coordinatesList - Array of markers
   * @returns An array of marker IDs
   */
  addMarkers(
    map: mapboxgl.Map,
    coordinatesList: IMapboxDirectionLocalization[]
  ): string[] {
    const ids: string[] = [];

    for (const coor of coordinatesList) {
      const currId = 'point-' + coor.lng + '-' + coor.lat;
      map.addLayer({
        id: currId,
        type: 'circle',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'Point',
                  coordinates: [coor.lng, coor.lat],
                },
              },
            ],
          },
        },
        paint: {
          'circle-radius': 7,
          'circle-color': '#79838D',
          'circle-stroke-color': UNSELECTED_CIRCUIT_COLOR_STROKE,
          'circle-stroke-width': 1,
        },
      });

      ids.push(currId);
    }

    return ids;
  }

  /**
   * Calculated the bounding box the be used with multiple objects and applies the new bounding
   *
   * @param map - Mapbox map object
   * @param geometry - Array of objects to take into account
   */
  boundMap(map: mapboxgl.Map, geometry: IMapboxGeometry): void {
    // Geographic coordinates of the LineString
    const coordinates = geometry.coordinates;

    // Create a 'LngLatBounds' with both corners at the first coordinate.
    const bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]);

    // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
    for (const coord of coordinates) {
      bounds.extend(coord as unknown as mapboxgl.LngLatLike);
    }

    map.fitBounds(bounds, {
      padding: 250,
    });
  }

  /**
   * Helps fetching and formatting a Mapbox Studio dataset made on the website
   * https://account.mapbox.com/
   *
   * @param map - Mapbox map object
   * @param datasetsId - ID given by Mapbox Studio
   * @param boundWhenComplete - If you want the map to set the map's bound to update with the new route
   */
  getDatasets(
    map: mapboxgl.Map,
    datasetsId: string
  ): Observable<[IMapboxDataset, IMapboxDatasetFeatures]> {
    return combineLatest(
      this.mapboxService.getDatasets(datasetsId),
      this.mapboxService.getDatasetsFeatures(datasetsId)
    );
  }

  /**
   *
   * @param map - Mapbox map object
   * @param boundWhenComplete - If you want the map to set the map's bound to update with the new route
   * @param datasets - Dataset data supplied by the Dataset API
   * @param features - Dataset features supplied by the Dataset API with the feature parameter
   * @returns A string array of all the layer IDs added to the map
   */
  generateDatasets(
    map: mapboxgl.Map,
    boundWhenComplete = false,
    datasets: IMapboxDataset,
    features: IMapboxDatasetFeatures
  ): string[] {
    const ids: string[] = [];

    if (boundWhenComplete) {
      map.fitBounds(datasets.bounds, {
        padding: 250,
      });
    }

    const orderedLayers: Feature<Geometry, GeoJsonProperties>[] = [
      ...features.features.filter(
        (feature: Feature<Geometry, GeoJsonProperties>) =>
          feature.geometry.type === 'Polygon'
      ),
      ...features.features.filter(
        (feature: Feature<Geometry, GeoJsonProperties>) =>
          feature.geometry.type !== 'Polygon'
      ),
    ];

    for (const feature of orderedLayers) {
      const currId = feature.id ? feature.id.toString() : '';
      const type =
        IMapboxFeatureType[
          feature.geometry.type as keyof typeof IMapboxFeatureType
        ];
      let paint: IMapboxPaintProperties = {};
      let layout: IMapboxLayoutProperties = {};

      if (type === IMapboxFeatureType.Point) {
        paint = {
          'circle-radius': 7,
          'circle-color': '#79838D',
          'circle-stroke-color': UNSELECTED_CIRCUIT_COLOR_STROKE,
          'circle-stroke-width': 1,
        };
      } else if (type === IMapboxFeatureType.Polygon) {
        paint = {
          'fill-outline-color': SELECTED_CIRCUIT_COLOR_STROKE,
          'fill-color': SELECTED_CIRCUIT_COLOR_LINE,
          'fill-opacity': 0.15,
        };
      } else if (type === IMapboxFeatureType.LineString) {
        layout = {
          'line-join': 'round',
          'line-cap': 'round',
        };
        paint = {
          'line-color': UNSELECTED_CIRCUIT_COLOR_LINE,
          'line-width': 5,
        };
      }
      map.addLayer({
        id: currId,
        type,
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [feature],
          },
        },
        layout,
        paint,
      });

      ids.push(currId);

      if (type === IMapboxFeatureType.Point) {
        const popup = new mapboxgl.Popup({ anchor: 'right' });

        popup
          .setLngLat({
            lon: (feature.geometry as Point).coordinates[0],
            lat: (feature.geometry as Point).coordinates[1],
          })
          .setText(
            'Current Coordinates: ' + (feature.geometry as Point).coordinates
          )
          .addTo(map);
      }
    }
    return ids;
  }

  /**
   * A simple helper function to properly remove a layer from a map
   *
   * @param map - Mapbox map object
   * @param layers - Array of all the layer IDs to be removed from a map
   */
  removeLayers(map: mapboxgl.Map, layers: string[]): void {
    for (const layer of layers) {
      if (map.getLayer(layer)) {
        map.removeLayer(layer);
      }
    }
  }

  /**
   * A simple helper function to fit the map to the bounds of a route in a circuit
   * and zoom in with the south-west and north-east corners
   * @param mapboxMap - The mapbox map object
   * @param circuitId - The circuit id of the route on the map
   * @param circuitBounceRoute - The circuit bounce route
   * @returns void
   */
  mapFitBounds(
    mapboxMap: mapboxgl.Map,
    circuitId: string | null,
    circuitBounceRoute: CircuitBounceRouteType[]
  ): void {
    const boundingBox = this.calculateBoundingBox(
      circuitBounceRoute.find((c) => c.circuitId === circuitId)?.coordinates
    );
    if (boundingBox) {
      const [southwest, northeast] = boundingBox;
      mapboxMap.fitBounds(
        [
          new mapboxgl.LngLat(southwest[0], southwest[1]),
          new mapboxgl.LngLat(northeast[0], northeast[1]),
        ],
        {
          padding: 50,
          maxZoom: 16,
          duration: 1000,
        }
      );
    }
  }

  /**
   * Calculate the bounding box of a set of coordinates
   * and return the corners (southwestern and northeastern corners)
   * to be used in the map's fitBounds method to fit the map to the bounding box
   * @param coordinates - The coordinates to calculate the bounding box
   * @returns The corners of the bounding box [southwestern, northeastern]
   */
  public calculateBoundingBox(
    coordinates: [number, number][] | undefined
  ): [number[], number[]] | null {
    if (!coordinates) {
      // Empty array, no coordinates to calculate bounding box
      return null;
    }

    let minLng = Infinity;
    let maxLng = -Infinity;
    let minLat = Infinity;
    let maxLat = -Infinity;

    // Iterate through the coordinates to find bounding box corners
    for (const [lng, lat] of coordinates) {
      minLng = Math.min(minLng, lng);
      maxLng = Math.max(maxLng, lng);
      minLat = Math.min(minLat, lat);
      maxLat = Math.max(maxLat, lat);
    }

    const southwestern = [minLng, minLat];
    const northeastern = [maxLng, maxLat];

    return [southwestern, northeastern];
  }

  /**
   * Hide the circuit selected/hovered on the map
   * and update the styles of the circuit on the map
   * and the circuit popover element
   * @param mapboxMap - The mapbox map object
   * @param circuitId - The circuit id of the route on the map
   * @returns void
   */
  public hideCircuitMap(
    mapboxMap: mapboxgl.Map | null,
    circuitId: string | null
  ) {
    // Hide previous circuit selected/hovered
    const circuitPopoverToHide = document.getElementById(
      `${POPOVER_CIRCUIT_BASE_STRING}${circuitId}`
    );
    const currCircuitIdToHide = `circuit-${circuitId}`;

    if (circuitPopoverToHide && mapboxMap) {
      circuitPopoverToHide?.style.setProperty('opacity', '0');
      circuitPopoverToHide?.setAttribute('clicked', 'false');
      circuitPopoverToHide?.setAttribute('hovered', 'false');
      mapboxMap.getCanvas().style.cursor = ''; // Reset cursor on leave
      this.updateCircuitStyles(
        mapboxMap,
        currCircuitIdToHide,
        UNSELECTED_CIRCUIT_COLOR_LINE,
        UNSELECTED_CIRCUIT_COLOR_STROKE
      );
    }
  }

  /**
   * Show the circuit selected/hovered on the map
   * and update the styles of the circuit on the map
   * and the circuit popover element
   * @param mapboxMap - The mapbox map object
   * @param circuitId - The circuit id of the route on the map
   * @returns void
   */
  public showCircuitMap(
    mapboxMap: mapboxgl.Map | null,
    circuitId: string | null
  ) {
    // Show the circuit selected/hovered
    const circuitPopoverToShow = document.getElementById(
      `${POPOVER_CIRCUIT_BASE_STRING}${circuitId}`
    );
    const currCircuitIdToShow = `circuit-${circuitId}`;

    if (circuitPopoverToShow && mapboxMap) {
      circuitPopoverToShow?.style.setProperty('opacity', '1');
      circuitPopoverToShow?.setAttribute('clicked', 'true');
      circuitPopoverToShow?.setAttribute('hovered', 'false');
      mapboxMap.getCanvas().style.cursor = 'pointer'; // Change cursor on hover
      this.updateCircuitStyles(
        mapboxMap,
        currCircuitIdToShow,
        SELECTED_CIRCUIT_COLOR_LINE,
        SELECTED_CIRCUIT_COLOR_STROKE
      );
    }
  }

  /**
   * @description Update the circuit styles on the map
   * when the user click on the circuit on the map
   * or click outside the circuit container element
   * @param { string } circuitId - The circuit id of the route on the map
   * @param { string } lineColor - The line color of the route on the map
   * @param { string } strokeColor - The stroke color of the route on the map
   * @returns { void }
   */
  public updateCircuitStyles(
    mapboxMap: mapboxgl.Map | null,
    circuitId: string,
    lineColor: string,
    strokeColor: string
  ): void {
    if (mapboxMap?.getLayer(circuitId)) {
      mapboxMap.setPaintProperty(circuitId, 'line-color', lineColor);
      mapboxMap.setPaintProperty(
        `${circuitId}-stroke`,
        'line-color',
        strokeColor
      );
    }
  }

  /**
   * @description Change the URL with the current zoom and lat/lng of the map with the current routes selected
   * @param { mapboxgl.Map } mapboxMap - The mapbox map object
   * @returns { void }
   */
  replaceURLWithZoomLatLng(mapboxMap: mapboxgl.Map | null): void {
    const actualZoom = mapboxMap ? mapboxMap?.getZoom() : 0;
    const formattedZoom = parseFloat(actualZoom.toFixed(2));
    const latLng = mapboxMap?.getCenter();
    const url = this.location.path().split('?')[0];

    history.replaceState(
      {},
      '',
      `${url}?${MAP_ZOOM_PARAM}=${formattedZoom}&${MAP_LAT_PARAM}=${latLng?.lat}&${MAP_LNG_PARAM}=${latLng?.lng}`
    );
  }

  /**
   * @description Zoom in the map with the current URL params
   * if any exists on the URL query params.
   * Zoom, Latitude and Longitude are the only params that are going to be used to zoom in the map
   * @param { mapboxgl.Map } mapboxMap - The mapbox map object
   * @param { string | null } id - The id in the URL on the map
   * @returns { void }
   */
  zoomInMapWithUrlParams(
    mapboxMap: mapboxgl.Map | null,
    id: string | null
  ): void {
    const urlParams = new URLSearchParams(window.location.search);
    const zoom = urlParams.get(MAP_ZOOM_PARAM);
    const lat = urlParams.get(MAP_LAT_PARAM);
    const lng = urlParams.get(MAP_LNG_PARAM);

    if (mapboxMap && zoom && lat && lng && !id) {
      mapboxMap?.flyTo({
        center: [parseFloat(lng), parseFloat(lat)],
        zoom: parseFloat(zoom),
        essential: true,
      });
    }
  }

  /**
   * @description Get the bearing between two points in degrees (0 - 360)
   * it's used to rotate the vehicle marker in the direction of the next stop location on the route
   * and simulate the vehicle moving on the route
   * @param { IMapboxDirectionLocalization } currentLocation
   * @param { IMapboxDirectionLocalization } nextStopLocation
   * @returns { number } bearing
   */
  getBearing(
    currentLocation: IMapboxDirectionLocalization,
    nextStopLocation: IMapboxDirectionLocalization
  ): number {
    const latOrigin = currentLocation.lat * (Math.PI / 180);
    const lngOrigin = currentLocation.lng * (Math.PI / 180);
    const latDestination = nextStopLocation.lat * (Math.PI / 180);
    const lngDestination = nextStopLocation.lng * (Math.PI / 180);

    const y = Math.sin(lngDestination - lngOrigin) * Math.cos(latDestination);
    const x =
      Math.cos(latOrigin) * Math.sin(latDestination) -
      Math.sin(latOrigin) *
        Math.cos(latDestination) *
        Math.cos(lngDestination - lngOrigin);

    let bearing = Math.atan2(y, x);
    bearing = (bearing * 180) / Math.PI;
    bearing = (bearing + 360) % 360;

    return bearing;
  }

  /**
   * @description Get the map area bounds of the current viewport of the map displayed on the screen
   * @param { mapboxgl.Map | null } map - The mapbox map object
   * @returns { MapViewArea | undefined } MapViewArea
   */
  getMapAreaBounds(map: mapboxgl.Map | null): MapViewArea | undefined {
    // Get the current bounds of the map viewport
    const bounds = map?.getBounds();

    if (bounds) {
      // Extract the coordinates of the viewport corners
      const topLeft = { lat: bounds.getWest(), lng: bounds.getNorth() };
      const topRight = { lat: bounds.getEast(), lng: bounds.getNorth() };
      const bottomRight = { lat: bounds.getEast(), lng: bounds.getSouth() };
      const bottomLeft = { lat: bounds.getWest(), lng: bounds.getSouth() };
      return {
        topLeft,
        topRight,
        bottomRight,
        bottomLeft,
      };
    } else {
      return undefined;
    }
  }

  /**
   * @description Check if a point is inside the bounding box of the map area displayed on the screen
   * @param { { lng: number; lat: number } } point - The point to check if it's inside the bounding box
   * @param { mapboxgl.Map | null } map - The mapbox map object
   * @returns { boolean } isPointInsideBoundingMapArea - True if the point is inside the bounding box, otherwise false
   */
  isPointInsideBoundingMapArea(
    point: { lng: number; lat: number },
    map: mapboxgl.Map | null
  ): boolean {
    const { lng, lat } = point;
    const mapViewArea = this.getViewCoordinates(map);

    // Check if the point is within the bounding box
    if (mapViewArea) {
      return (
        lng >= mapViewArea.topLeft.lng &&
        lng <= mapViewArea.bottomRight.lng &&
        lat >= mapViewArea.bottomLeft.lat &&
        lat <= mapViewArea.topLeft.lat
      );
    } else {
      return false;
    }
  }

  /**
   * @description Get the coordinates of the corners of the map area displayed on the screen
   * @param { mapboxgl.Map | null } map - The mapbox map object
   * @returns { MapViewArea } MapViewArea
   * @throws { Error } Error - If the map object is not defined
   */
  getViewCoordinates(map: mapboxgl.Map | null): {
    topLeft: mapboxgl.LngLat;
    topRight: mapboxgl.LngLat;
    bottomRight: mapboxgl.LngLat;
    bottomLeft: mapboxgl.LngLat;
  } {
    if (!map) {
      throw new Error('Map object is not defined');
    }

    // Get the map canvas element
    const canvas = map.getCanvas();

    // Get the width and height of the canvas
    const width = canvas.clientWidth;
    const height = canvas.clientHeight;

    // Use unproject method to convert pixel coordinates to geographic coordinates
    const topLeft = map.unproject([0, 0]);
    const topRight = map.unproject([width, 0]);
    const bottomRight = map.unproject([width, height]);
    const bottomLeft = map.unproject([0, height]);

    // Return the view coordinates as an object
    return { topLeft, topRight, bottomRight, bottomLeft };
  }
}
