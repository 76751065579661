import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, combineLatest, map, Observable, of } from 'rxjs';

import { PermissionKeysType } from '../settings/store/settings';
import { getUserPermissions, permissionsInterface } from '../store';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  /**
   * Retrieves the permission based on the provided key.
   * @param key - The key to identify the permission.
   * @returns An Observable that emits a boolean value indicating whether the user has the permission.
   */
  getPermission$(key: PermissionKeysType): Observable<boolean> {
    return this.store
      .select(getUserPermissions)
      .pipe(
        map((permissions: permissionsInterface) =>
          this.hasPermissionsBasedOnAKey(permissions, key)
        )
      );
  }

  /**
   * Checks if the given permissions object has the specified key.
   *
   * @param permissions - The permissions object to check.
   * @param key - The key to search for in the permissions object.
   * @returns A boolean indicating whether the permissions object has the specified key.
   */
  private hasPermissionsBasedOnAKey(
    permissions: permissionsInterface,
    key: PermissionKeysType
  ): boolean {
    const keys = key.split('.');
    const permission = this.searchRecursivelyForAllKeysIntoPermissions(
      permissions,
      keys
    );

    return typeof permission === 'boolean' && permission;
  }

  /**
   * Recursively searches for all keys in the permissions object.
   *
   * @param permissions - The permissions object to search.
   * @param keys - An array of keys to search for.
   * @param index - The current index in the keys array (default: 0).
   * @returns The permissions object or null if the keys are not found.
   */
  private searchRecursivelyForAllKeysIntoPermissions(
    permissions: Partial<permissionsInterface> | boolean | undefined,
    keys: string[],
    index = 0
  ): Partial<permissionsInterface> | boolean | undefined {
    if (index >= keys.length || permissions == null) {
      return permissions;
    }

    if (typeof permissions === 'object' && permissions !== null) {
      const keysPermissions = keys[index] as keyof permissionsInterface;
      return this.searchRecursivelyForAllKeysIntoPermissions(
        permissions[keysPermissions] as Partial<permissionsInterface> | boolean,
        keys,
        index + 1
      );
    }

    return undefined;
  }

  /**
   * Combines two observables and returns an observable that emits a boolean value.
   * The emitted value is true if both the firstObservable and secondObservable emit true, otherwise false.
   *
   * @param observables - The observables to combine.
   * @returns An observable that emits a boolean value indicating if both observables emit true.
   */
  combineAndReturnBooleanObservable$(
    ...observables: Observable<boolean>[]
  ): Observable<boolean> {
    return combineLatest(observables).pipe(
      map((values) => {
        return values.every((value) => typeof value === 'boolean' && value);
      }),
      catchError(() => of(false))
    );
  }

  constructor(private store: Store) {}
}
