import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  Output,
} from '@angular/core';

import classNames from 'classnames';

import { IconAtomGlyphType } from '../../atoms';
import { ColorType } from '../../common-ng-design-system.colors';

import { ChipClickEvent } from './chip.interface';

@Component({
  selector: 'ad-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
})
export class ChipMolecule implements OnChanges {
  @Input()
  selected = false;

  @Input()
  id = 'default-chip-id';

  @Input()
  disabled = false;

  @Input()
  leadingIcon?: IconAtomGlyphType;

  @Input()
  trailingIcon?: IconAtomGlyphType;

  @Input()
  color: ColorType = 'primary';

  @Output() trailingIconEvent = new EventEmitter<ChipClickEvent>();
  @Output() selectChipEvent = new EventEmitter<ChipClickEvent>();

  focused = false;
  pressed = false;
  @HostBinding('class') class = '';

  @HostListener('click', ['$event']) onClick() {
    this.selectChipEvent.emit({ id: this.id });
  }

  @HostListener('focus', ['$event']) onFocus() {
    this.focused = true;
  }

  @HostListener('blur', ['$event']) onBlur() {
    this.focused = false;
  }

  @HostListener('mousedown', ['$event']) onMouseDown() {
    this.pressed = true;
  }

  @HostListener('mouseup', ['$event']) onMouseUp() {
    this.pressed = false;
  }

  ngOnChanges() {
    this.class = classNames('color-' + this.color, {
      'is-disabled': this.disabled,
      'is-selected': this.selected,
    });
  }

  trailingClick(event: Event, id: string) {
    event.stopPropagation();
    this.trailingIconEvent.emit({
      id,
    });
  }
}
