import { Component, Input, OnInit } from '@angular/core';
import classNames from 'classnames';

import { ColorType } from '../../../common-ng-design-system.colors';

@Component({
  selector: 'ad-progress-bar-navigation',
  templateUrl: './progress-bar-navigation.component.html',
  styleUrls: ['./progress-bar-navigation.component.scss'],
})
export class ProgressBarMolecule implements OnInit {
  @Input()
  color: ColorType = 'primary';

  @Input()
  percentage = 0;

  progressBarWidth = { width: '0' };

  ngOnInit() {
    this.progressBarAnimate();
  }

  /**
   * @description
   * Set the container color based on the color input value
   * @returns string
   */
  public get containerClasses() {
    return classNames('progress-bar-container', this.color);
  }

  /**
   * @description
   * Set the navigation color based on the color input value
   * @returns string
   */
  public get navigationClasses() {
    return classNames('navigation-container', this.color);
  }

  /**
   * @description
   * Set the percentage bar color based on the color input value
   * @returns string
   */
  public get progressBarClasses() {
    return classNames('progress-bar-wrapper', this.color);
  }

  /**
   * @description
   * Animate the percentage bar width based on the percentage input value
   * @returns void
   */
  progressBarAnimate() {
    const barWidth = `${this.percentage}%`;

    setTimeout(() => {
      this.progressBarWidth = { width: barWidth };
    });
  }

  /**
   * @description
   * Cap the percentage value to 100 or 0
   * @returns number
   */
  public get progressCap() {
    if (this.percentage >= 100) return 100;
    if (this.percentage <= 0) return 0;
    return this.percentage;
  }
}
