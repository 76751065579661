<div
  class="snackbar-container text-p3"
  [class]="getLineClasses()"
  [ngClass]="{ timed: kind === 'timed' }"
>
  <div class="message" [ngClass]="{ 'single-line': singleLine }">
    <ng-content select="[message]"></ng-content>
  </div>

  <div
    class="action-container text-caption--semibold"
    id="actions"
    [class]="getActionClasses()"
  >
    <ng-content select="[action]"></ng-content>
  </div>
</div>
