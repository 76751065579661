<div *ngIf="isShown" class="preview-layer-container">
  <astus-map-layer-selector-button
    imageSrc="/assets/images/map/traffic.png"
    [textValue]="'MAP.LAYERS.TRAFFIC' | translate"
    [isSelected]="isLayerTraffic"
    (isClicked)="setTrafficLayer.emit()"
  ></astus-map-layer-selector-button>
  <astus-map-layer-selector-button
    imageSrc="/assets/images/map/bike.png"
    [textValue]="'MAP.LAYERS.BIKE' | translate"
    [isSelected]="isLayerBike"
    (isClicked)="setBikeLayer.emit()"
  ></astus-map-layer-selector-button>
  <astus-map-layer-selector-button
    [textValue]="'MAP.LAYERS.MORE' | translate"
    (isClicked)="moreClicked.emit()"
    class="preview-layer-more-button"
    ><svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2279 20.095C12.5057 20.6567 11.4943 20.6567 10.7721 20.095L4.0287 14.8501C3.5096 14.4464 3.5146 13.6602 4.03879 13.263C4.39939 12.9899 4.89845 12.9927 5.25594 13.2699L12 18.5L18.7441 13.2699C19.1016 12.9927 19.6006 12.9899 19.9612 13.263C20.4854 13.6602 20.4904 14.4464 19.9713 14.8501L13.2279 20.095ZM12.6139 15.5225C12.2528 15.8034 11.7472 15.8034 11.3861 15.5225L4.01488 9.78935C3.50013 9.38899 3.50014 8.61101 4.01488 8.21065L11.3861 2.47751C11.7472 2.19665 12.2528 2.19665 12.6139 2.47751L19.9851 8.21065C20.4999 8.61101 20.4999 9.38899 19.9851 9.78935L12.6139 15.5225ZM12 13.45L17.75 9L12 4.55L6.25 9L12 13.45Z"
        fill="#333E48"
      />
    </svg>
  </astus-map-layer-selector-button>
</div>
