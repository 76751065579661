import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';

import { environment } from '../../environments/environment';
import { appRoutesEnum } from '../app-routing.module';

export const canActivateEnvironment: CanActivateFn = (
  route: ActivatedRouteSnapshot
) => {
  return inject(EnvironmentGuard).canActivate(route);
};

@Injectable()
export class EnvironmentGuard {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const environmentName: string[] = route.data['environment'];
    const result = environmentName.includes(environment.name);

    if (!result) {
      this.router.navigate([`/${appRoutesEnum.NOT_FOUND}`]);
    }

    return result;
  }
}
