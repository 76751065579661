import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { popoverOptionType, statusColorEnum } from '@common/ng-design-system';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import {
  controlPanelCardAllDataType,
  controlPanelCardDataStopsInterface,
} from '../../../map-control-panel/map-control-panel.component.d';
import { controlPanelStopStatusEnum } from '../../../map-control-panel/map-control-panel.enum';

@Component({
  selector: 'astus-stop-monitoring-card',
  templateUrl: './stop-monitoring-card.component.html',
  styleUrls: ['./stop-monitoring-card.component.scss'],
})
export class StopMonitoringCardComponent implements OnInit, OnDestroy {
  @Input()
  isLoading = false;

  // Info card stuff
  @Input() title = '';
  @Input() titleSmall = '';
  @Input() subtitle = '';
  @Input() subtitleSmall = '';
  @Input() infoSchedule = '';
  @Input() infoUser = '';

  @Input() stopStatusState: controlPanelStopStatusEnum | undefined =
    controlPanelStopStatusEnum.ON_TIME;
  @Input() stopStatusDuration: string | undefined;
  @Input() stopStatusTime: string | undefined;
  @Input() stopStudents: string[] | undefined;
  @Input() stopNotes: string | undefined;

  @Output()
  backClickEvent = new EventEmitter<
    controlPanelCardDataStopsInterface | undefined
  >();

  @Output()
  moreOptionsEvent = new EventEmitter<boolean>();

  @Input()
  moreOptions: popoverOptionType[] = [];

  @Input()
  previousData: controlPanelCardAllDataType | undefined = undefined;

  showMoreOptions = false;

  translateAndSub: Subscription = new Subscription();
  andValue = '';

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.translateAndSub = this.translateService
      .stream('CARDS.STOP_INFOBOX.STUDENTS_AND')
      .subscribe((res: string) => {
        this.andValue = res;
      });
  }

  ngOnDestroy(): void {
    this.translateAndSub.unsubscribe();
  }

  /**
   * @description
   * On click of the back button, emit the event to the parent component.
   * @returns {void}
   */
  onBackClick(): void {
    this.backClickEvent.emit(this.previousData);
  }

  /**
   * @description
   * Select more options in the card.
   */
  optionSelected() {
    // TODO Add logic to change the display in the infobox to edit
  }

  /**
   * @description
   * On click of the more options, emit the event to the parent component.
   * @returns {void}
   */
  moreOptionsClick(): void {
    this.moreOptionsEvent.emit();
  }

  get statusColor(): statusColorEnum {
    if (this.stopStatusState === controlPanelStopStatusEnum.DONE) {
      return statusColorEnum.SUCCESS;
    } else {
      return statusColorEnum.NEUTRAL;
    }
  }

  get studentsFormatted(): string {
    if (this.stopStudents) {
      let formattedStudents = '';

      if (this.stopStudents?.length === 1) {
        formattedStudents = this.stopStudents[0];
      } else {
        for (let i = 0; i < this.stopStudents.length; i++) {
          if (i === this.stopStudents.length - 2) {
            formattedStudents += `${this.stopStudents[i]} ${this.andValue} `;
          } else if (i === this.stopStudents.length - 1) {
            formattedStudents += `${this.stopStudents[i]}`;
          } else {
            formattedStudents += `${this.stopStudents[i]}, `;
          }
        }
      }

      return formattedStudents;
    } else {
      return '';
    }
  }
}
