import { AfterViewInit, Directive, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { isString } from 'lodash';

@Directive({
  selector: '[astusPhoneFormat]',
})
export class PhoneFormatDirective implements OnInit, AfterViewInit {
  constructor(private control: NgControl) {}

  ngAfterViewInit(): void {
    this.control.control?.setValue(
      this.formatStringAsPhone(this.control.control?.value),
      { emitEvent: false }
    );
  }

  ngOnInit(): void {
    this.control.control?.valueChanges.subscribe((value) => {
      if (value.length < 14) {
        const formattedValue = this.formatStringAsPhone(value);
        if (formattedValue) {
          this.control.control?.setValue(formattedValue, {
            emitEvent: false,
          });
        }
      }
    });
  }

  /**
   * Format a string as a phone number
   * @param {string | string[] | null} value The value to format
   * @returns The formatted value
   **/
  formatStringAsPhone(value: string | string[] | null) {
    let phoneNumbers: string[];

    if (!value) return '';

    // If value is a string, split it by comma
    if (isString(value)) {
      phoneNumbers = value.split(',');
    } else {
      phoneNumbers = value;
    }

    const formattedNumbers = phoneNumbers.map((phoneNumber) => {
      const numericValue = phoneNumber.replace(/\D/g, '').slice(0, 10);
      let formattedValue = '';

      if (numericValue.length > 0) {
        formattedValue += `(${numericValue.slice(0, 3)}`;
      }

      if (numericValue.length > 3) {
        formattedValue += `) ${numericValue.slice(3, 6)}`;
      }

      if (numericValue.length > 6) {
        formattedValue += `-${numericValue.slice(6, 10)}`;
      }

      return formattedValue;
    });

    return formattedNumbers.join(',');
  }
}
