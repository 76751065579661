<dialog
  #upload_drawer
  class="upload-options-drawer"
  cdkDrag
  (cdkDragEnded)="onDragEnd()"
  [cdkDragConstrainPosition]="constrainPosition"
  cdkDragLockAxis="y"
>
  <!-- Grabber container -->
  <div class="grabber-container" #grabber>
    <div class="grabber"></div>
  </div>

  <!-- Upload item container -->
  <div class="upload-option-item-container">
    <div class="upload-option-item text-p1" (click)="onImportFileClick()">
      <div class="icon-container">
        <ad-icon
          glyph="upload-filled"
          statusIcon
          class="import-icon"
          [color]="'primary-400'"
        ></ad-icon>
      </div>

      {{ 'UPLOAD_GENERAL.IMPORT_FILE' | translate }}

      <!--
        (click)="$event.stopPropagation()"  
          =>  is used to avoid closing the dialog when the file app open
       -->
      <input
        id="file-upload"
        class="hidden"
        type="file"
        [accept]="fileTypes.join(', ')"
        multiple
        #fileInput
        (change)="fileUpload()"
        (click)="$event.stopPropagation()"
      />
    </div>

    <div class="upload-option-item text-p1" (click)="onCreateButtonClick()">
      <div class="icon-container">
        <ad-icon
          [customSize]="20"
          class="conversion-icon"
          glyph="conversion_path"
          statusIcon
          [color]="'primary-400'"
        ></ad-icon>
      </div>

      <span *ngIf="resourceSelected === 'circuits'">
        {{ 'UPLOAD_WIDGET_CIRCUITS.BUTTON_TEXT' | translate }}
      </span>

      <span *ngIf="resourceSelected === 'vehicles'">
        {{ 'UPLOAD_WIDGET_VEHICLES.BUTTON_TEXT' | translate }}
      </span>

      <span *ngIf="resourceSelected === 'users'">
        {{ 'UPLOAD_WIDGET_USERS.BUTTON_TEXT' | translate }}
      </span>
    </div>
  </div>
</dialog>
