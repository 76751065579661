<form
  id="drop-zone"
  class="upload-form"
  (dragover)="onDragOver($event)"
  (dragleave)="removeDragClass()"
  (dragend)="removeDragClass()"
  (drop)="dropFiles($event)"
>
  <ad-icon
    *ngIf="fontIcon"
    [glyph]="fontIcon"
    color="primary-400"
    class="font-icon"
  ></ad-icon>
  <p *ngIf="label" class="text-p1--semibold optional-label">
    {{ label }}
  </p>
  <label for="file-upload" class="text-p3 file-upload-label">
    {{ linkText }}
  </label>
  <input
    id="file-upload"
    class="hidden"
    type="file"
    [accept]="fileTypes.join(', ')"
    multiple
    #fileInput
    (change)="fileUpload()"
  />
  <button
    *ngIf="buttonText"
    class="upload-button"
    astus-design-button
    color="neutral"
    kind="outlined"
    size="xs"
    (click)="onButtonClick($event)"
  >
    {{ buttonText }}
  </button>
  <p *ngIf="footerText || footerTextColored">
    <a
      class="footer-anchor text-caption"
      (click)="downloadLinkEvent.emit($event)"
    >
      {{ footerText }}
      <span class="text-caption--semibold">
        {{ footerTextColored }}
      </span>
      <ad-icon glyph="download" color="primary-400"></ad-icon>
    </a>
  </p>
</form>
