import { Component } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'astus-warranty',
  templateUrl: './warranty.component.html',
  styleUrls: ['./warranty.component.scss'],
})
export class WarrantyComponent {
  equipmentWarrantyMarkdown =
    'assets/markdown/fr/equipment-equipment-warranty.md';
  constructor(private translate: TranslateService) {
    this.equipmentWarrantyMarkdown = `assets/markdown/${this.translate.currentLang}/equipment-warranty.md`;
  }
}
