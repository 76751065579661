import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DropdownItemInterface } from '@common/ng-design-system';

import { HoursFormatPipe } from '../../pipes/hours-format/hours-format.pipe';

@Component({
  selector: 'astus-date-and-time-fields',
  templateUrl: './date-and-time-fields.component.html',
  styleUrls: ['./date-and-time-fields.component.scss'],
})
export class DateAndTimeFieldsComponent implements OnInit {
  @Input() label = '';
  @Input() date: Date = new Date();
  @Input() time = '12:00';

  showCalendar = false;
  hideCalendar = true;
  timeArray: DropdownItemInterface[] = [];

  @Output() dateChange = new EventEmitter<Date>();
  @Output() timeChange = new EventEmitter<string | number>();

  constructor(
    private hoursFormatPipe: HoursFormatPipe,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.timeArray = this.generateTimeArray();
  }

  /**
   * @description Toggle the calendar visibility
   */
  toggleCalendar() {
    this.showCalendar = !this.showCalendar;
  }

  closeCalendar() {
    this.showCalendar = false;
  }

  /**
   * @description Emit the date value when the date input changes
   */
  handleDateSelect(date: Date | Date[]) {
    let singleDate: Date;

    if (Array.isArray(date)) {
      singleDate = date[0];
    } else {
      singleDate = date;
    }

    this.date = singleDate;
    this.dateChange.emit(singleDate);
    this.hideCalendar = false;
  }

  /**
   * Checks if the calendar should be hidden from the component
   * @param {boolean} event
   */
  handleHideCalendar(event: boolean) {
    this.hideCalendar = event;
  }

  /**
   * @description Close the calendar when clicking outside the component
   * @param {MouseEvent} event
   */
  @HostListener('document:click', ['$event'])
  onClickOutsideComponent(event: MouseEvent) {
    // setTimeout is needed to ensure that the click event is not triggered before the calendar event is received
    setTimeout(() => {
      const clickedOutsideComponent = !this.elementRef.nativeElement.contains(
        event.target
      );
      if (clickedOutsideComponent && this.hideCalendar) {
        this.showCalendar = false;
      }
    });
  }

  /**
   * @description Generate the time array by 15 minutes intervals
   * @returns {DropdownItemInterface[]} Array of time values
   */
  generateTimeArray(): DropdownItemInterface[] {
    const newArray: DropdownItemInterface[] = [];
    for (let i = 0; i < 24; i++) {
      const hour = i;

      for (let j = 0; j < 60; j += 15) {
        const minute = j;
        const value = `${hour < 10 ? `0${hour}` : hour}:${
          minute < 10 ? `0${minute}` : minute
        }`;
        const formattedTime = this.hoursFormatPipe.transform(value);

        newArray.push({
          label: formattedTime,
          value,
        });
      }
    }

    return newArray;
  }

  /**
   * @description Emit the time value when the time input changes
   */
  onTimeChange(e: DropdownItemInterface | null) {
    if (e) {
      this.timeChange.emit(e.value);
    }
  }
}
