<div class="popover-container">
  <ng-content></ng-content>
  <div [class]="containerClasses">
    <button
      class="text-p3 popover-button"
      *ngFor="let option of options; let last = last"
      (mousedown)="clickSelectItem($event, option)"
    >
      {{ option.value }}
    </button>
  </div>
</div>
