<div *ngIf="isShown" class="selector-layer-container">
  <div class="text-p2--semibold selector-layer-header-detail">
    <span>{{ 'MAP.LAYERS.TITLE_DETAIL' | translate }}</span>
    <button (click)="closeClicked.emit()">
      <ad-icon glyph="close" color="shades-100"></ad-icon>
    </button>
  </div>
  <div class="selector-layer-detail-container">
    <astus-map-layer-selector-button
      imageSrc="/assets/images/map/traffic.png"
      [textValue]="'MAP.LAYERS.TRAFFIC' | translate"
      [isSelected]="isLayerTraffic"
      (isClicked)="setTrafficLayer.emit()"
    ></astus-map-layer-selector-button>
    <astus-map-layer-selector-button
      imageSrc="/assets/images/map/bike.png"
      [textValue]="'MAP.LAYERS.BIKE' | translate"
      [isSelected]="isLayerBike"
      (isClicked)="setBikeLayer.emit()"
    ></astus-map-layer-selector-button>
  </div>
  <div class="text-p2 selector-layer-header-type">
    {{ 'MAP.LAYERS.TITLE_TYPE' | translate }}
  </div>
  <div class="selector-layer-detail-container">
    <astus-map-layer-selector-button
      imageSrc="/assets/images/map/default.png"
      [textValue]="'MAP.LAYERS.DEFAULT' | translate"
      [isSelected]="isLayerDefault"
      (isClicked)="setDefaultLayer.emit()"
    ></astus-map-layer-selector-button>
    <astus-map-layer-selector-button
      imageSrc="/assets/images/map/satellite.png"
      [textValue]="'MAP.LAYERS.SATELLITE' | translate"
      [isSelected]="isLayerSatellite"
      (isClicked)="setSatelliteLayer.emit()"
    ></astus-map-layer-selector-button>
    <astus-map-layer-selector-button
      imageSrc="/assets/images/map/outdoors.png"
      [textValue]="'MAP.LAYERS.OUTDOORS' | translate"
      [isSelected]="isLayerOutdoors"
      (isClicked)="setOutdoorsLayer.emit()"
    ></astus-map-layer-selector-button>
  </div>
</div>
