import { permissionsInterface } from './global';

export const permissionsMock: permissionsInterface = {
  general: {
    companyProfileAccess: true,
    companyProfileEdit: true,
    languageAndFormatsAccess: true,
    languageAndFormatsEdit: true,
    myProfilAccess: true,
    myProfilEdit: true,
  },
  administration: {
    rolesAndPermissionsAccess: true,
    rolesAndPermissionsCreateEdit: true,
    rolesAndPermissionsDelete: true,
  },
};
