<ad-card
  *ngIf="circuit"
  (hoveredCardChange)="hoveredEvent.emit(circuit)"
  [moreOptions]="moreOptions"
  (popoverOptionSelect)="handleMoreOptionClick($event)"
  class="card-content"
  (clickCardEvent)="cardClick.emit({ data: circuit })"
  (assignEvent)="assignEvent.emit(circuit)"
  [title]="circuit.title || ''"
  [titleSmall]="circuit.title2 || ''"
  [subtitle]="circuit.subtitle || ''"
  [subtitleSmall]="circuit.subtitle2 || ''"
  [infoItems]="[
    { icon: 'users-filled', text: circuit.textInfo || '' },
    { icon: 'route', text: circuit.textInfo2 || '' }
  ]"
  [firstAvatarText]="formatVehicleAvatarTextValue(circuit.userName || '')"
  [firstAvatarTooltip]="
    formatVehicleAvatarIconTooltipValue(circuit.userName || '')
  "
  [firstAvatarFallbackGlyph]="'profile-filled'"
  [secondAvatarText]="formatVehicleName(circuit.vehicleName || '')"
  [secondAvatarTooltip]="circuit.vehicleName || ''"
  [secondAvatarFallbackGlyph]="'bus-filled'"
  [ctaButtonText]="!hideAssignButton ? ('CARDS.ASSIGN_BTN' | translate) : ''"
  [tooltipPosition]="isFirst ? 'bottom' : 'top'"
  [isSelected]="isSelected"
></ad-card>
