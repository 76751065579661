import { createFeatureSelector, createSelector } from '@ngrx/store';

import { appRoutesEnum } from '../../app-routing.module';
import { AgendaItemElement } from '../agenda/supervision-tabs/supervision-tabs.component';

import { supervisionRoutesEnum } from '../map-routing.module';
import { buttonTabEnum } from '../map-view-tabs-button/map-view-tabs-button.component';

import { mapReducerInterface } from './map.d';
import { mapFeatureKey } from './map.reducer';

export const mapFeature =
  createFeatureSelector<mapReducerInterface>(mapFeatureKey);

export const selectStopsFeature = createSelector(mapFeature, (mapStops) => ({
  ...mapStops.stopCoordinated,
}));
export const selectCircuitsFeature = createSelector(
  mapFeature,
  (mapCircuits) => ({ ...mapCircuits.circuits })
);

export const selectVehiclesFeature = createSelector(
  mapFeature,
  (mapVehicles) => ({ ...mapVehicles.vehicles })
);

export const selectUsersFeature = createSelector(mapFeature, (mapUsers) => ({
  ...mapUsers.users,
}));
export const selectAgendasItemsFeature = createSelector(mapFeature, (map) =>
  map.vehicles.data.map((vehicle) => {
    const agendaItem: AgendaItemElement = {
      title: vehicle.name || '',
      status: vehicle.state || 'offDuty',
    };
    return agendaItem;
  })
);

export const selectSupervisionModeSelected = createSelector(
  mapFeature,
  (map) => map.supervisionModeSelected
);

export const selectSupervisionUrl = (
  urlToDisable: supervisionRoutesEnum[] = []
) =>
  createSelector(selectSupervisionModeSelected, (mode) => {
    if (
      buttonTabEnum.AGENDA === mode &&
      !urlToDisable.includes(supervisionRoutesEnum.AGENDA)
    ) {
      return `/${appRoutesEnum.SUPERVISION}/${supervisionRoutesEnum.AGENDA}`;
    } else {
      return `/${appRoutesEnum.SUPERVISION}/${supervisionRoutesEnum.MAP}`;
    }
  });

export const selectSupervisionSegmentUrl = createSelector(
  mapFeature,
  (map) => map.supervisionSegmentUrl
);

export const selectVehiclePosition = (id: number) =>
  createSelector(mapFeature, (map) => map.vehiclePositions[id]);

export const selectVehiclesPositions = createSelector(
  mapFeature,
  (map) => map.vehiclesPositions
);
