import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'astus-settings-not-found',
  templateUrl: './settings-not-found.component.html',
  styleUrls: ['./settings-not-found.component.scss'],
})
export class SettingsNotFoundComponent implements OnInit {
  images = [
    '../../../../../assets/images/dog_1.svg',
    '../../../../../assets/images/plug.svg',
  ];

  backgroundImage = '';
  ngOnInit() {
    const index = Math.floor(Math.random() * this.images.length);
    this.backgroundImage = this.images[index];
  }
}
