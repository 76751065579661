import {
  vehicleTableInterface,
  vehicleTypeEnum,
} from '@common/ng-design-system';
import {
  UpsertVehicleDto,
  VehicleDto,
  VehicleModelDto,
} from '@fms/ng-fms-api-client';

/**
 * Determines if the given object is a vehicle from a read DTO
 * and not from a create or update DTO.
 *
 * @param vehicle - The object to check.
 * @returns A boolean value indicating whether the object is a vehicle.
 */
function isVehicle(
  vehicle: VehicleDto | UpsertVehicleDto
): vehicle is VehicleDto {
  const omittedBaseDtoIdFields: Array<keyof VehicleDto> = [
    'id',
    'created',
    'modified',
  ];
  return omittedBaseDtoIdFields.every((param) => param in vehicle);
}

/**
 * The default value used when a valid ID is not available.
 *
 * Note: -1 is not a valid ID, so this value is used to handle such cases.
 */
export const DEFAULTVALUE = '-1';

/**
 * Parses the data of a vehicle object and returns a vehicleTableInterface object for the view
 * @param vehicle - The vehicle object to parse.
 * @returns The parsed vehicleTableInterface object.
 */
export function parseDataVehicle(
  vehicle: VehicleDto | UpsertVehicleDto
): vehicleTableInterface {
  const vehicleTable = {
    name: vehicle.name,
    vin: vehicle.vin,
    update: '', // default value can be changed
    plateNumber: vehicle?.immatriculation?.plateNumber || '',
    module: vehicle?.iotDevice?.serialNumber || '',
    country: vehicle?.immatriculation?.country || '',
    province: vehicle?.immatriculation?.province || '',
    timezone: vehicle?.immatriculation?.timezone?.toString() || '',
    imei: vehicle?.iotDevice?.imei.toString() || '',
    softwareVersion: vehicle?.iotDevice?.softwareVersion || '',
    lastConnection: vehicle?.iotDevice?.lastConnected || '',
    tagType: vehicle?.iotDevice?.type || '',
    vehicleId: vehicle?.iotDevice?.vehicleId || DEFAULTVALUE,
    modelId: vehicle.modelId || DEFAULTVALUE,
    brand: vehicle?.model?.brand || '',
    model: vehicle?.model?.name || '',
    year: vehicle?.model?.year.toString() || '',
    vehicleType: (vehicle?.model?._class?.name || '') as vehicleTypeEnum,
    fuelType: (vehicle?.model?.fuelType || '') as VehicleModelDto.FuelTypeEnum,
    groupName: vehicle?.group?.name || '',
    serialNumber: vehicle.iotDevice?.serialNumber || '',
    homePortId: vehicle?.homePortId || DEFAULTVALUE,
    homePort: vehicle?.homePort?.name || '',
    carrierId: vehicle?.carrierId || DEFAULTVALUE,
    carrier: vehicle?.carrier?.name || '',
    iotDeviceId: vehicle?.iotDeviceId || DEFAULTVALUE,
  };

  if (isVehicle(vehicle)) {
    const idAndUpdate = {
      id: vehicle.id,
      update: vehicle.modified,
    };

    return { ...vehicleTable, ...idAndUpdate };
  }

  return vehicleTable;
}
