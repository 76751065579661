<div class="circuit-monitoring" [ngClass]="{ 'is-skeleton': isLoading }">
  <div class="header">
    <ad-card-info
      tooltipPosition="left"
      class="header-ad-card-info"
      [isSkeleton]="isLoading"
      [title]="title || ''"
      [titleSmall]="titleSmall || ''"
      [subtitle]="subtitle || ''"
      [subtitleSmall]="subtitleSmall || ''"
      [infoItems]="[
        { icon: 'users-filled', text: infoUser || '' },
        { icon: 'route', text: infoRoute || '' }
      ]"
      [firstAvatarText]="
        mapListCircuitCardComponent.formatVehicleAvatarTextValue(userName || '')
      "
      [firstAvatarTooltip]="
        mapListCircuitCardComponent.formatVehicleAvatarIconTooltipValue(
          userName || ''
        )
      "
      [firstAvatarFallbackGlyph]="'profile-filled'"
      [secondAvatarText]="
        mapListCircuitCardComponent.formatVehicleName(vehicleName || '')
      "
      [secondAvatarTooltip]="vehicleName || ''"
      [secondAvatarFallbackGlyph]="'bus-filled'"
    ></ad-card-info>
    <div *ngIf="moreOptions.length" class="header-more-info" moreInfo>
      <div class="header-more-info-container">
        <ad-popover
          [(display)]="showMoreOptions"
          [options]="moreOptions"
          class="header-more-info-popover"
          (selectOption)="handleMoreOptionClick($event)"
          position="bottom"
        >
          <button
            (click)="onMoreOptionsClick()"
            class="header-more-info-popover-button"
          >
            <ad-icon
              [color]="isLoading ? 'neutral-200' : 'neutral-500'"
              glyph="more-vertical"
              class="more-options-icon"
              [ngClass]="isLoading ? 'is-loading' : 'is-not-loading'"
              svgClass="header-ad-icon-info-svg"
            ></ad-icon>
          </button>
        </ad-popover>
      </div>
    </div>
  </div>
  <ng-container *ngIf="isLoading; else infoContainer">
    <div class="info-container-skeleton">
      <div class="container-info-container-skeleton-header">
        <div class="container-info-container-skeleton-header-1"></div>
        <div class="container-info-container-skeleton-header-2"></div>
        <div class="container-info-container-skeleton-header-3"></div>
        <div class="container-info-container-skeleton-header-4"></div>
      </div>
      <!-- Info container -->
      <div *ngFor="let _ of [1, 2, 3]" class="container-info-content-skeleton">
        <div class="container-info-content-start-skeleton">
          <!-- First text info -->
          <div class="container-info-content-start-skeleton-first-text"></div>
          <div class="container-info-content-start-skeleton-first-text-content">
            <!-- Second text info -->
            <div
              class="container-info-content-start-skeleton-second-text"
            ></div>
            <!-- Third text info -->
            <div class="container-info-content-start-skeleton-third-text">
              <div
                class="container-info-content-start-skeleton-third-text-1"
              ></div>
              <div
                class="container-info-content-start-skeleton-third-text-2"
              ></div>
              <div
                class="container-info-content-start-skeleton-third-text-3"
              ></div>
              <div
                class="container-info-content-start-skeleton-third-text-4"
              ></div>
              <div
                class="container-info-content-start-skeleton-third-text-5"
              ></div>
            </div>
          </div>
        </div>
        <div class="container-info-content-end-skeleton" *ngIf="_ !== 3">
          <div class="container-info-content-end-skeleton-1"></div>
          <div class="container-info-content-end-skeleton-2"></div>
          <div class="container-info-content-end-skeleton-3"></div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #infoContainer>
    <div class="container-info-container">
      <div
        *ngIf="progressPercentage"
        class="container-info-container-progress-bar-container"
      >
        <ad-progress-bar-navigation
          class="container-info-container-progress-bar"
          [percentage]="progressPercentage"
          [color]="progressType"
        ></ad-progress-bar-navigation>
        <span class="container-info-container-progress-bar-time text-caption">{{
          progressTime
        }}</span>
      </div>
      <div *ngFor="let route of routes">
        <div
          class="container-info-container-card-content"
          (click)="selectRoute(route)"
        >
          <ad-icon
            leftIcon
            glyph="route"
            class="container-info-container-card-content-left-icon"
            color="neutral-700"
            svgClass="container-info-container-card-content-icons-svg"
          ></ad-icon>
          <ad-card-info
            class="container-info-container-card-info"
            [title]="route.title || ''"
            [titleSmall]="route.title2 || ''"
            [subtitle]="route.subtitle || ''"
            [infoItems]="[
              { icon: 'users-filled', text: route.textInfo || '' },
              { icon: 'location-filled', text: route.textInfo2 || '' }
            ]"
            [hideAvatars]="true"
          ></ad-card-info>
        </div>
        <div
          class="container-info-container-route-time-distance-container text-footer"
          *ngIf="route?.routeTime || route?.routeDistance"
        >
          <span>{{ route?.routeTime }}</span>
          <div
            *ngIf="route?.routeTime && route?.routeDistance"
            class="container-info-container-route-distance"
          ></div>
          <span>{{ route?.routeDistance }}</span>
        </div>
      </div>
    </div>
  </ng-template>
</div>
