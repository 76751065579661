<span class="leading-icon">
  <ad-icon *ngIf="leadingIcon" [glyph]="leadingIcon"></ad-icon>
</span>
<p class="text-p3">
  <ng-content></ng-content>
</p>
<span class="trailing-icon">
  <ad-icon
    *ngIf="trailingIcon"
    (click)="trailingClick($event, id)"
    [glyph]="trailingIcon"
  ></ad-icon>
</span>
