/**
 * Get the current hours in the format HH:MM
 * @returns {string} with the current hours
 * @example getCurrentHours() => '12:00'
 */
export function getCurrentHours(): string {
  const now = new Date();
  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}
