<label class="ctn">
  <div class="ctn-inner">
    <ad-icon glyph="search"></ad-icon>
    <div class="content">
      <input
        type="text"
        class="search-input"
        (input)="search($event)"
        [placeholder]="placeholder"
        [value]="value"
        #searchInput
      />
    </div>
    <button (click)="clearSearch()">
      <ad-icon glyph="close"></ad-icon>
    </button>
  </div>
</label>
