import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';

import {
  cardInfoItemInterface,
  popoverOptionType,
  statusColorEnum,
} from '@common/ng-design-system';
import { VehicleStateType } from '@fms/ng-fms-api-client';
import { TranslateService } from '@ngx-translate/core';
import { Subject, combineLatest, takeUntil } from 'rxjs';

import { appRoutesEnum, resourceTabType } from '../../../../app-routing.module';
import { MapListVehicleCardComponent } from '../../../map-list/map-list-vehicle-card/map-list-vehicle-card.component';

@Component({
  selector: 'astus-vehicle-monitoring-card',
  templateUrl: './vehicle-monitoring-card.component.html',
  styleUrls: ['./vehicle-monitoring-card.component.scss'],
})
export class VehicleMonitoringCardComponent implements OnInit, OnDestroy {
  @Input() isLoading = false;

  // Info card stuff
  @Input() id: string | number | undefined;
  @Input() title = '';
  @Input() vehicleStatus: VehicleStateType = VehicleStateType.OnDuty;
  @Input() infoSeat = '';
  @Input() infoFuel = '';

  @Input() infoItems: cardInfoItemInterface[] = [];

  @Input() route: string | undefined;

  // Info group
  @Input() circuits: string | undefined;
  @Input() preference: string | undefined;
  @Input() type: string | undefined;

  // Detail group
  @Input() odometer: string | undefined;
  @Input() odometerReadTime: string | undefined;
  @Input() vin: string | undefined;
  @Input() plaque: string | undefined;
  @Input() model: string | undefined;
  @Input() year: string | undefined;
  @Input() fuel: string | undefined;
  @Input() transporter: string | undefined;
  @Input() homeport: string | undefined;
  @Input() groups: string | undefined;

  // Telematic module
  @Input() serialNumber: string | undefined;
  @Input() telematicType: string | undefined;
  @Input() softwareVersion: string | undefined;
  @Input() lastConnection: string | undefined;

  // Maintenance schedule
  @Input() checkUp: string | undefined;
  @Input() checkUpDate: string | undefined;
  @Input() changeOil: string | undefined;
  @Input() changeOilDate: string | undefined;

  @Output()
  backClickEvent = new EventEmitter<boolean>();

  @Output()
  moreOptionsEvent = new EventEmitter<boolean>();

  moreOptions: popoverOptionType[] = [];
  moreOptionEditTag = 'edit-vehicle';

  showMoreOptions = false;
  statusColorEnum = statusColorEnum;
  statusTranslations: { [key: string]: string } = {};

  destroy$ = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    public mapListVehicleCardComponent: MapListVehicleCardComponent,
    private router: Router
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.translateService.stream('CARDS.STATUS.MAINTENANCE'),
      this.translateService.stream('CARDS.STATUS.OFF_DUTY'),
      this.translateService.stream('CARDS.STATUS.ON_DUTY'),
      this.translateService.stream('CARDS.MODIFY_VEHICLE'),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([maintenance, offDuty, onDuty, modifyVehicle]) => {
        (this.statusTranslations[VehicleStateType.Maintenance] = maintenance),
          (this.statusTranslations[VehicleStateType.OffDuty] = offDuty),
          (this.statusTranslations[VehicleStateType.OnDuty] = onDuty),
          (this.statusTranslations[VehicleStateType.OutOfService] = offDuty);

        if (this.id !== undefined) {
          this.moreOptions = [
            {
              id: `${this.moreOptionEditTag}-${this.id}`,
              value: modifyVehicle,
            },
          ];
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get statusColor(): statusColorEnum {
    return this.mapListVehicleCardComponent.getStatusColor(this.vehicleStatus);
  }

  get statusText(): string {
    return this.statusTranslations[this.vehicleStatus ?? ''] ?? '';
  }

  /**
   * @description
   * On click of the more options, emit the event to the parent component.
   * @returns {void}
   */
  onMoreOptionsClick(): void {
    this.showMoreOptions = !this.showMoreOptions;
    this.moreOptionsEvent.emit();
  }

  /**
   * @description Handles the popover option click
   * Checks for the "Modify vehicle" option and navigates to the vehicle edit form in Resources
   * @param {popoverOptionType} option
   */
  handleMoreOptionClick(option: popoverOptionType) {
    if (
      option.id?.indexOf(this.moreOptionEditTag) !== -1 &&
      this.id !== undefined
    ) {
      const url = `${appRoutesEnum.RESOURCES}/${resourceTabType.VEHICLES}/${this.id}`;
      this.router.navigate([url]);
    }
  }
}
