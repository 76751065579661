import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';

import { getUserUnitSystem, unitSystemEnum } from '../../store';

@Pipe({
  name: 'distanceFormat',
  standalone: true,
})
export class DistancePipe implements PipeTransform {
  unitSystem: unitSystemEnum = unitSystemEnum.METRIC;

  constructor(private store: Store) {
    store.select(getUserUnitSystem).subscribe((unitSystem) => {
      this.unitSystem = unitSystem;
    });
  }

  /**
   * @description
   * Transform a distance from metric to imperial or viceversa
   * Can be used as a pipe or as a function
   * {{ 100 | distanceFormat }} => 100km
   * {{ 100 | distanceFormat: true }} => 100
   * {{ 100.343434343434 | distanceFormat: true: 6 }} => 100.343434
   * {{ 100 | distanceFormat: true: 2: 'imperial' }} => 62.14
   *
   * In TS : this.distancePipe.transform(myData.distance)
   *
   * @param {string | number} value
   * @param {boolean} hideUnits
   * @param {number} float number of decimals to show
   * @param {unitSystemEnum} forceUnit
   *
   * @returns {string} theformatted distance
   */
  transform(
    value: string | number,
    hideUnits = false,
    float = 2,
    forceUnit: unitSystemEnum | null = null
  ): string {
    const unitSystemToUse = forceUnit || this.unitSystem;
    const floatToUse = float > 100 ? 100 : float;
    const convertedValue =
      typeof value === 'string' ? parseFloat(value) : value;
    const newValue = (
      unitSystemToUse === unitSystemEnum.METRIC
        ? convertedValue
        : convertedValue * 0.621371
    ).toFixed(floatToUse);
    const unitToShow = unitSystemToUse === unitSystemEnum.METRIC ? 'km' : 'mi';

    return (
      +parseFloat(newValue).toFixed(floatToUse) +
      (!hideUnits ? `${unitToShow}` : '')
    );
  }
}
