<img src="{{ backgroundImage }}" />
<h3 class="text-h3--semibold">
  {{ 'SETTINGS.NOT_FOUND.TITLE' | translate }}
</h3>
<p class="text-p1">
  {{ 'SETTINGS.NOT_FOUND.DESCRIPTION' | translate }}
</p>
<p class="text-p1">
  {{ 'SETTINGS.NOT_FOUND.END_DESCRIPTION' | translate }}
</p>
