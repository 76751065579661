import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  cardInfoItemInterface,
  popoverOptionType,
  statusColorEnum,
} from '@common/ng-design-system';
import { TranslateService } from '@ngx-translate/core';
import { Subject, combineLatest, takeUntil } from 'rxjs';

import { appRoutesEnum, resourceTabType } from '../../../../app-routing.module';
import { controlPanelUsersStatusEnum } from '../../../map-control-panel/map-control-panel.enum';
import { MapListVehicleCardComponent } from '../../../map-list/map-list-vehicle-card/map-list-vehicle-card.component';

@Component({
  selector: 'astus-user-monitoring-card',
  templateUrl: './user-monitoring-card.component.html',
  styleUrls: ['./user-monitoring-card.component.scss'],
})
export class UserMonitoringCardComponent implements OnInit, OnDestroy {
  @Input() isLoading = false;

  // Info card stuff
  @Input() id: string | number | undefined;
  @Input() title = '';
  @Input() userStatus: controlPanelUsersStatusEnum =
    controlPanelUsersStatusEnum.ON_WAY;
  @Input() infoService = '';

  @Input() infoItems: cardInfoItemInterface[] = [];

  @Input() phone: string | undefined;
  @Input() email: string | undefined;

  // Information
  @Input() circuits: string | undefined;
  @Input() preference: string | undefined;

  // Details
  @Input() singleJob: string | undefined;
  @Input() expirationSingleJob: string | undefined;
  @Input() licenseClass: string | undefined;
  @Input() permit: string | undefined;
  @Input() expirationPermit: string | undefined;
  @Input() transporter: string | undefined;
  @Input() homeport: string | undefined;
  @Input() groups: string | undefined;

  moreOptions: popoverOptionType[] = [];
  moreOptionEditTag = 'edit-user';

  @Output() moreOptionsEvent = new EventEmitter<boolean>();

  showMoreOptions = false;
  statusColorEnum = statusColorEnum;
  statusTranslations: { [key: string]: string } = {};

  destroy$ = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    public mapListVehicleCardComponent: MapListVehicleCardComponent,
    private router: Router
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.translateService.stream('CARDS.STATUS.ON_DUTY'),
      this.translateService.stream('CARDS.STATUS.OFF_DUTY'),
      this.translateService.stream('CARDS.MODIFY_USER'),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([onDuty, offDuty, modifyUser]) => {
        (this.statusTranslations[controlPanelUsersStatusEnum.ON_WAY] = onDuty),
          (this.statusTranslations[controlPanelUsersStatusEnum.ON_DUTY] =
            onDuty),
          (this.statusTranslations[controlPanelUsersStatusEnum.OFF_DUTY] =
            offDuty);

        if (this.id !== undefined) {
          this.moreOptions = [
            {
              id: `${this.moreOptionEditTag}-${this.id}`,
              value: modifyUser,
            },
          ];
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get statusColor(): statusColorEnum {
    return this.getStatusColor(this.userStatus);
  }

  public getStatusColor(status?: controlPanelUsersStatusEnum): statusColorEnum {
    if (status === controlPanelUsersStatusEnum.OFF_DUTY) {
      return statusColorEnum.NEUTRAL;
    } else {
      return statusColorEnum.SUCCESS;
    }
  }

  get statusText(): string {
    return this.statusTranslations[this.userStatus ?? ''] ?? '';
  }

  /**
   * @description
   * On click of the more options, emit the event to the parent component.
   * @returns {void}
   */
  onMoreOptionsClick(): void {
    this.showMoreOptions = !this.showMoreOptions;
    this.moreOptionsEvent.emit();
  }

  /**
   * @description Handles the popover option click
   * Checks for the "Modify user" option and navigates to the user edit form in Resources
   * @param {popoverOptionType} option
   */
  handleMoreOptionClick(option: popoverOptionType) {
    if (
      option.id?.indexOf(this.moreOptionEditTag) !== -1 &&
      this.id !== undefined
    ) {
      const url = `${appRoutesEnum.RESOURCES}/${resourceTabType.DRIVERS}/${this.id}`;
      this.router.navigate([url]);
    }
  }
}
