<div class="main-container">
  <div
    class="notification-container"
    #notificationContainer
    *ngIf="!showStaticContainer && !showOpenCloseAllContainerDelayed"
    [@snackarAnimation]="unresolvedSnackbars.length"
  >
    <div
      class="snackbar-container"
      *ngFor="let snackbar of unresolvedSnackbars; index as i"
    >
      <div class="snackbar-container__inner" #snackbarInner>
        <div class="snackbar-container__inner-small">
          <ad-snackbar
            [actionType]="snackbar.actionType"
            [kind]="snackbar.kind"
            [singleLine]="snackbar.singleLine"
            *ngIf="!snackbar.hide && !snackbar.resolved"
          >
            <p class="message" message>
              <span class="text-p3--semibold">{{
                snackbar.content.title
              }}</span>
              | {{ snackbar.content.message }}
            </p>
            <ad-icon
              action
              *ngIf="snackbar.content.url"
              class="icon"
              glyph="open_new"
              (click)="onOpenSnackbar(i)"
            ></ad-icon>
            <ad-icon
              action
              class="icon"
              glyph="close"
              (click)="clearNotification(i)"
            ></ad-icon>
          </ad-snackbar>
        </div>
      </div>
    </div>
    <ad-icon
      class="cta cta--snooze"
      [ngClass]="{ show: showSnoozeButton }"
      glyph="chevron_down"
      (click)="closeNotificationContainer()"
    ></ad-icon>
  </div>

  <div
    class="notification-container"
    #staticNotificationContainer
    *ngIf="showStaticContainer && !showOpenCloseAllContainerDelayed"
  >
    <div
      class="snackbar-container"
      *ngFor="let snackbar of snackbars; index as i"
      [@openAllAnimation]
    >
      <div class="snackbar-container__inner" #snackbarInner>
        <div class="snackbar-container__inner-small">
          <ad-snackbar
            [actionType]="snackbar.actionType"
            [kind]="snackbar.kind"
            [singleLine]="snackbar.singleLine"
            *ngIf="!snackbar.resolved"
          >
            <p class="message" message>
              <span class="text-p3--semibold">{{
                snackbar.content.title
              }}</span>
              | {{ snackbar.content.message }}
            </p>
            <ad-icon
              action
              *ngIf="snackbar.content.url"
              class="icon"
              glyph="open_new"
              (click)="onOpenSnackbar(i)"
            ></ad-icon>
            <ad-icon
              action
              class="icon"
              glyph="close"
              (click)="clearNotification(i)"
            ></ad-icon>
          </ad-snackbar>
        </div>
      </div>
    </div>

    <ad-icon
      class="cta cta--snooze"
      [ngClass]="{
        show: showStaticContainer && !showOpenCloseAllContainerDelayed
      }"
      glyph="chevron_down"
      (click)="closeStaticContainer()"
    ></ad-icon>
  </div>

  <div
    class="snackbar-container"
    #openCloseAll
    *ngIf="showOpenCloseAllContainerDelayed"
    @openAllAnimation
  >
    <div class="snackbar-container__inner">
      <div class="snackbar-container__inner-small">
        <div class="cta-container">
          <ad-icon
            class="cta cta--open"
            glyph="chevron_up"
            (click)="openAll()"
            *ngIf="showOpenCloseAllContainerDelayed"
          ></ad-icon>
        </div>
        <ad-snackbar actionType="simple" kind="system">
          <p class="message" message>
            {{ 'SNACKBAR.AWAITING_ALERTS.PART_1' | translate
            }}<span
              class="text-p3--semibold"
              >{{ 'SNACKBAR.AWAITING_ALERTS.PART_2' | translate : { count: getNumberOfUnresolvedSnackbars(), } }}</span
            >
          </p>
          <button
            [ngStyle]="{ 'background-color': 'rgb(var(--color-neutral-700))' }"
            action
            astus-design-button
            (click)="onCloseAll()"
          >
            <span class="text-caption--semibold">{{
              'SNACKBAR.CLOSE_ALL' | translate
            }}</span>
          </button>
        </ad-snackbar>
      </div>
    </div>
  </div>
</div>
