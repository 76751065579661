import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import classNames from 'classnames';

@Component({
  selector: 'ad-card-minimal',
  templateUrl: './card-minimal.component.html',
  styleUrls: ['./card-minimal.component.scss'],
})
export class CardMinimalOrganism implements OnChanges {
  @Input() title = '';
  @Input() name = '';
  @Input() hideClosedButton = false;

  @Output() clickCloseEvent = new EventEmitter();

  formattedName = '';

  ngOnChanges(): void {
    this.formatName();
  }

  @HostBinding() class = classNames(
    this.hideClosedButton && 'hide-closed-button'
  );

  /**
   * @description
   * Use to format the name to display it in the avatar.
   * It takes the first letter of the first two groups of string, generally the first and last name
   * @returns {void}
   */
  formatName(): void {
    if (this.name) {
      const driverName = this.name.split(' ');
      this.formattedName =
        driverName[0].charAt(0) +
        (driverName.length > 1 ? driverName.at(-1)?.charAt(0) : '');
    }
  }
}
