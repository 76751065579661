import { createFeatureSelector, createSelector } from '@ngrx/store';

import { globalReducerInterface } from './global';
import { globalStateKey } from './global.reducer';

export const globalState =
  createFeatureSelector<globalReducerInterface>(globalStateKey);

export const getUserData = createSelector(globalState, (state) => state.user);

export const getDateFormatData = createSelector(
  getUserData,
  (state) => state.dateFormat
);

export const getUserUnitSystem = createSelector(
  getUserData,
  (state) => state.unitSystem
);

export const getUserDurationFormat = createSelector(
  getUserData,
  (state) => state.durationFormat
);

export const getUserHoursFormat = createSelector(
  getUserData,
  (state) => state.hoursFormat
);

export const getUserLanguage = createSelector(getUserData, (state) => ({
  language: state.language,
}));

export const getUserLanguageAndFormats = createSelector(
  getUserData,
  (state) => ({
    language: state.language,
    unitSystem: state.unitSystem,
    dateFormat: state.dateFormat,
    durationFormat: state.durationFormat,
    hoursFormat: state.hoursFormat,
  })
);

export const showMenuFeature = createSelector(
  globalState,
  (state) => state.menu.showMenu
);

export const getDateFeature = createSelector(
  globalState,
  (state) => state.date
);

export const getTimeLineStateFeature = createSelector(
  globalState,
  (state) => state.timeline
);

export const getNotifications = createSelector(globalState, (state) => {
  return state.notifications;
});

export const getUserNotificationsSettings = createSelector(
  getUserData,
  (userData) => {
    return userData.notificationsSettings;
  }
);

export const getUserPermissions = createSelector(getUserData, (userData) => {
  return userData.permissions;
});

export const getUserResourcesVehiclesDataTableColumns = createSelector(
  getUserData,
  (userData) => {
    return userData.resourcesVehicleColumns;
  }
);
export const getReportsVehicles = createSelector(
  globalState,
  (state) => state.reports.vehicles
);

export const getReportVehicleById = (id: string | number) =>
  createSelector(globalState, (state) =>
    state.reports.vehicles.find((vehicle) => vehicle.id === id)
  );
