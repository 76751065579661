import { notificationTypeEnum } from '../components/menu/notification-center/notification-center.enums';

import { notificationInterface } from './global';

export const sortNotifications = (
  notifications: notificationInterface[]
): notificationInterface[] => {
  const tempNotificationData: notificationInterface[] = [];
  let lastDate: string | null = null;

  for (const notification of notifications) {
    const notificationDate = notification.createdDate;

    if (lastDate !== notificationDate) {
      const separator: notificationInterface = {
        type: notificationTypeEnum.SEPARATOR,
        unread: false,
        circuit: null,
        route: null,
        message: null,
        asset: null,
        assetNum: null,
        sender: null,
        createdDate: notificationCreatedDateFormat(notificationDate),
        createdTime: '',
        id: lastDate ? 1 : 0,
      };

      lastDate = notificationDate;

      tempNotificationData.push(separator);
    }

    tempNotificationData.push(notification);
  }

  return tempNotificationData.sort(
    (a, b) =>
      new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
  );
};

const notificationCreatedDateFormat = (date: string): string => {
  const parsedInputDate = new Date(date + 'T00:00:00');
  const currentDate = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  if (parsedInputDate.toDateString() === currentDate.toDateString()) {
    return 'NOTIFICATION_CENTER.TODAY';
  }

  if (parsedInputDate.toDateString() === yesterday.toDateString()) {
    return 'NOTIFICATION_CENTER.YESTERDAY';
  }

  return parsedInputDate.toLocaleDateString('fr-ca', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
};
