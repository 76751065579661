import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DropdownItemInterface } from '@common/ng-design-system';

import { HoursFormatPipe } from '../../pipes/hours-format/hours-format.pipe';

@Component({
  selector: 'astus-hour-picker',
  templateUrl: './hour-picker.component.html',
  styleUrls: ['./hour-picker.component.scss'],
})
export class HourPickerComponent implements OnInit, OnChanges {
  // Component state
  firstHourPickerShown = false;
  secondHourPickerShown = false;

  // Component data
  firstHoursForPicker: DropdownItemInterface[] = [];
  secondHoursForPicker: DropdownItemInterface[] = [];

  // Component inputs
  @Input() firstHourSelected = '00:00';
  @Input() secondHourSelected = '00:00';
  @Input() isLiveMode = false;

  // Component outputs
  @Output() firstHourSelectedChange: EventEmitter<string> = new EventEmitter();
  @Output() secondHourSelectedChange: EventEmitter<string> = new EventEmitter();

  // View child
  @ViewChild('firstHourPicker')
  firstHourPicker!: ElementRef<HTMLDivElement>;
  @ViewChild('secondHourPicker')
  secondHourPicker!: ElementRef<HTMLDivElement>;

  constructor(private hoursFormatPipe: HoursFormatPipe) {}

  ngOnInit(): void {
    // set the first and second hours for the pickers at the component initialization
    this.firstHoursForPicker = this.generateTimeArray();
    this.secondHoursForPicker = this.generateTimeArrayForSecondPicker();
  }

  ngOnChanges(): void {
    // set the second picker hours based on new changes according the first picker
    this.secondHoursForPicker = this.generateTimeArrayForSecondPicker();
  }

  /**
   * Handle the click on the first hour picker
   */
  onFirstHourPickerClick(): void {
    this.firstHourPickerShown = !this.firstHourPickerShown;
    this.secondHourPickerShown = false;
  }

  /**
   * Handle the click on the second hour picker
   */
  onSecondHourPickerClick(): void {
    this.secondHourPickerShown = !this.secondHourPickerShown;
    this.firstHourPickerShown = false;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (
      !this.firstHourPicker?.nativeElement.contains(event.target as Node) &&
      !this.secondHourPicker?.nativeElement.contains(event.target as Node)
    ) {
      this.firstHourPickerShown = false;
      this.secondHourPickerShown = false;
    }
  }

  /**
   * Generate the time array for the pickers
   * The time array should have the values from `00:00` to `23:59`
   * The time array should have the values in 15 minutes intervals
   * @returns {DropdownItemInterface[]} The time array for the pickers
   */
  generateTimeArray(): DropdownItemInterface[] {
    const newArray: DropdownItemInterface[] = [];
    for (let i = 0; i < 24; i++) {
      const hour = i;

      for (let j = 0; j < 60; j += 15) {
        const minute = j;
        const value = `${hour < 10 ? `0${hour}` : hour}:${
          minute < 10 ? `0${minute}` : minute
        }`;
        const formattedTime = this.hoursFormatPipe.transform(value);

        newArray.push({
          label: formattedTime,
          value,
        });
      }
    }

    // adding the `custom value` for the last value
    // we want to match the design a value for `23:59`
    const lastValue = '23:59';
    const lastFormattedTime = this.hoursFormatPipe.transform(lastValue);

    newArray.push({
      label: lastFormattedTime,
      value: lastValue,
    });

    return newArray;
  }

  /**
   * Generate the time array for the second picker based on the first picker selected value
   * The second picker should have the values greater than the first picker selected value
   * The second picker should have disabled values less than the first picker selected value
   * @returns {DropdownItemInterface[]} The time array for the second picker
   */
  generateTimeArrayForSecondPicker(): DropdownItemInterface[] {
    const timeArray = this.generateTimeArray();

    const firstHourIndex = timeArray.findIndex(
      (time) => time.value === this.firstHourSelected
    );
    const secondHourIndex = timeArray.findIndex(
      (time) => time.value === this.secondHourSelected
    );

    if (firstHourIndex >= secondHourIndex) {
      this.secondHourSelected =
        firstHourIndex !== timeArray.length - 1
          ? (timeArray[firstHourIndex + 1].value as string) // assume is string, because the null is impossible, the dropdown is always filled with string values
          : (timeArray[timeArray.length - 1].value as string); // assume is string, because the null is impossible, the dropdown is always filled with string values
    }

    return timeArray.map((time, index) => ({
      ...time,
      disabled: index <= firstHourIndex,
    }));
  }

  /**
   * Handle the selected value from the correct picker
   * @param DropdownItemInterface value The selected value
   */
  onPickerItemClick(value: DropdownItemInterface): void {
    if (this.firstHourPickerShown) {
      this.firstHourSelected = value.value as string;
      this.firstHourSelectedChange.emit(this.firstHourSelected);
    } else if (this.secondHourPickerShown) {
      this.secondHourSelected = value.value as string;
      this.secondHourSelectedChange.emit(this.secondHourSelected);
    }

    this.closeAllPickers();
  }

  /**
   * Close all pickers
   */
  private closeAllPickers(): void {
    this.firstHourPickerShown = false;
    this.secondHourPickerShown = false;
  }
}
