import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[adProgressBarNavigation]',
})
export class ProgressBarNavigationDirective implements OnInit {
  @Input() adNavigationProgress?: number;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.updateNavigationProgress();
  }

  /**
   * @description
   * Set the position for the navigation progress based on the progress input value
   * @private
   */
  private updateNavigationProgress() {
    const component = this.el.nativeElement;
    const navigationProgressLeft = this.adNavigationProgress + '%';

    setTimeout(() => {
      this.renderer.setStyle(component, 'left', navigationProgressLeft);
    });
  }
}
