import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { of } from 'rxjs';

import {
  CONFIGMapBox,
  IMapboxConfig,
  IMapboxDataset,
  IMapboxDatasetFeatures,
  IMapboxDirectionLocalization,
  IMapboxDirectionProfiles,
  IMapboxDirectionsResponse,
} from './mapbox.interface';

@Injectable({
  providedIn: 'root',
})
export class MapboxService {
  constructor(
    private http: HttpClient,
    @Inject(CONFIGMapBox) private config: IMapboxConfig
  ) {}

  /**
   *  Fetches the direction or route
   *
   * @param profile - Profile of directional usage
   * @param locations - Array of coordinated
   * @returns An observable with the Direction API response
   */
  getDirections(
    profile: IMapboxDirectionProfiles,
    locations: IMapboxDirectionLocalization[]
  ) {
    let formattedLocations = '';

    // TODO: find better solution when lontitude and latitude are 0
    if (
      locations.some((location) => location.lat === 0 || location.lng === 0)
    ) {
      return of();
    }

    locations.forEach((location) => {
      formattedLocations += location.lng + ',' + location.lat + ';';
    });

    formattedLocations = formattedLocations.substring(
      0,
      formattedLocations.length - 1
    );

    return this.http.get<IMapboxDirectionsResponse>(
      `https://api.mapbox.com/directions/v5/mapbox/${profile}/${formattedLocations}?steps=true&geometries=geojson&access_token=${this.config.accessToken}&overview=full`
    );
  }

  /**
   * Fetches the datasets with the Datasets API.
   * Returns a summary of the dataset and its content. To be used with getDatasetsFeatures to get all the features' data in the datasets.
   * The datasetId needs to be from a dataset associated with the same account as the username and accessToken
   *
   * @param datasetId - The dataset ID
   * @returns An observable with the Datasets API response
   */
  getDatasets(datasetId: string) {
    return this.http.get<IMapboxDataset>(
      `https://api.mapbox.com/datasets/v1/${this.config.username}/${datasetId}/?access_token=${this.config.accessToken}`
    );
  }

  /**
   * Fetches the features and their data from a dataset
   * The datasetId needs to be from a dataset associated with the same account as the username and accessToken
   *
   * @param datasetId - The dataset ID
   * @returns An observable with the Datasets API response
   */
  getDatasetsFeatures(datasetId: string) {
    return this.http.get<IMapboxDatasetFeatures>(
      `https://api.mapbox.com/datasets/v1/${this.config.username}/${datasetId}/features/?access_token=${this.config.accessToken}`
    );
  }
}
