import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'ad-modal',
  templateUrl: './modal.component.html',

  styleUrls: ['./modal.component.scss'],
})
export class ModalAtom implements OnChanges, AfterViewInit {
  @Input() isOpen = false;

  @Input() closeOnOutsideClick = true;

  @Output() outsideModalClick: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('ad_modal')
  dialog!: ElementRef<HTMLDialogElement>;

  topContent!: ElementRef;

  middleContent!: ElementRef;

  bottomContent!: ElementRef;

  isAnimated = false;

  ngOnChanges(): void {
    if (this.isOpen) {
      this.openModal();
    }

    // Check if the modal is open and if the dialog is defined
    if (!this.isOpen && this.dialog) {
      this.closeModal();
    }
  }

  ngAfterViewInit(): void {
    if (this.isOpen) {
      this.openModal();
    }
  }

  /**
   * Close the modal.
   */
  closeModal() {
    this.isAnimated = true;
    this.dialog.nativeElement.classList.add('hide');

    setTimeout(() => {
      this.dialog.nativeElement.classList.remove('hide');
      this.dialog.nativeElement.close();
      this.isAnimated = false;
    }, 250);
  }

  /**
   * Open the modal.
   */
  private openModal() {
    this.isAnimated = true;
    this.dialog?.nativeElement.showModal();

    setTimeout(() => {
      this.isAnimated = false;
    }, 500);
  }

  /**
   * Handle the click event on the cancel button.
   */
  onOutsideModalClick() {
    if (!this.isAnimated) {
      if (this.closeOnOutsideClick) {
        this.closeModal();
      }
      this.outsideModalClick.emit();
    }
  }

  /**
   * Handle the click event on the modal or outside.
   * @param {MouseEvent} event Where the user clicked.
   */
  @HostListener('click', ['$event'])
  onModalClick(event: MouseEvent) {
    const { clientX, clientY } = event;
    const { left, right, top, bottom } =
      this.dialog.nativeElement.getBoundingClientRect();

    // if the user clicked outside the modal
    if (
      clientX < left ||
      clientX > right ||
      clientY < top ||
      clientY > bottom
    ) {
      // trigger a cancel button click
      this.onOutsideModalClick();
    }
  }
}
