import { createFeatureSelector, createSelector } from '@ngrx/store';

import { settingsReducerInterface } from './settings';
import { settingsFeatureKey } from './settings.reducer';

export const settingsFeature =
  createFeatureSelector<settingsReducerInterface>(settingsFeatureKey);

export const selectGroupsFeature = createSelector(
  settingsFeature,
  (settings) => settings.groupsList
);

export const selectRolesFeature = createSelector(settingsFeature, (settings) =>
  [...settings.rolesList].sort((a, b) => a.title.localeCompare(b.title))
);

export const selectSingleRoleFeature = (id: number) =>
  createSelector(settingsFeature, (settings: settingsReducerInterface) =>
    settings.rolesList.find((role) => role.id === id)
  );

export const selectProfileFeature = createSelector(
  settingsFeature,
  (settings) => settings.profile
);
