<div>
  <ad-avatar-text
    *ngIf="name && name.length"
    [text]="formattedName"
    [charToShow]="2"
    size="xs"
    backgroundColor="primary-400"
  ></ad-avatar-text>
  <ng-content></ng-content>
</div>

<div class="text-p3--semibold title-container">
  {{ title }}
</div>

<button
  class="close-button"
  *ngIf="!hideClosedButton"
  (click)="clickCloseEvent.emit()"
>
  <ad-icon glyph="close" color="neutral-500"></ad-icon>
</button>
