import { Injectable } from '@angular/core';
import H from '@here/maps-api-for-javascript';

import {
  IHERECoordinates,
  IHERERouteResponse,
  IHERERouteSection,
  IHERERoutingModes,
  IHERETransportModes,
} from './here.interface';

import { HEREService } from './here.service';

@Injectable({
  providedIn: 'root',
})
export class HEREHelper {
  constructor(private hereService: HEREService) {}

  /**
   * Add a array of areas to a map.
   *
   * @param map - The map object
   * @param polylineCoords - The IHERECoordinates[] array
   * @returns Array of all the area/polygon objects.
   */
  formatAndAddArea(map: H.Map, polylineCoords: IHERECoordinates[][]) {
    const areaObjects = [];

    for (const hereArea of polylineCoords) {
      const area = this.addArea(map, this.formatCoordinates(hereArea));
      areaObjects.push(area);
    }

    return areaObjects;
  }

  /**
   * Remaps the coordinates array for the HERE coordinartes format to have a [lat, lng, lat, lng, lat, lng, ...]
   *
   * @param polylineCoords - The IHERECoordinates array
   * @returns The compiled array
   */
  formatCoordinates(polylineCoords: IHERECoordinates[]) {
    const formattedCoordinates: number[] = [];
    for (const coor of polylineCoords) {
      if (coor.lat && coor.lng) {
        formattedCoordinates.push(coor.lat);
        formattedCoordinates.push(coor.lng);
      }
    }

    return formattedCoordinates;
  }

  /**
   * Adds an area or a polygon on the map with formatted coordinates
   *
   * @param map - The map object
   * @param polylineCoords - The formatted array of coordinates [lat, lng, lat, lng, lat, lng, ...]
   * @returns An object with the added ares/polygon.
   * @returns Null if there is no map initiated
   */
  addArea(map: H.Map, polylineCoords: number[]): H.map.Polygon | null {
    // Create a polygon using the coordinates
    const polygon = H.geo.LineString.fromLatLngArray(polylineCoords);
    const polyline = new H.map.Polygon(polygon, {
      style: { lineWidth: 4 },
      data: null,
    });

    if (map) {
      // Add the group to the map
      map.addObject(polyline);

      return polyline;
    }

    return null;
  }

  /**
   * Adds a route to the map.
   *
   * @param map - The map object
   * @param origin - The origin waypoint coordinates
   * @param destination - The destination waypoint coordinates
   * @param intermediates - An array for the intermediate waypoints between the origin and the destination
   * @param router - The HERE router (platform.getRoutingService)
   * @param addMarkers - Boolean to add or not the route's markers
   * @param routingMode - Type of routing
   * @param transportMode - Type of transportation mode
   * @param departureTime - Departure time to calculate traffic with a specific time of "any" to not have live-traffic calculated
   */
  addRoute(
    map: H.Map,
    origin: IHERECoordinates,
    destination: IHERECoordinates,
    intermediates: IHERECoordinates[],
    router: H.service.RoutingService | H.service.RoutingService8,
    addMarkers = true,
    routingMode: IHERERoutingModes = 'fast',
    transportMode: IHERETransportModes = 'car',
    departureTime = 'any'
  ): void {
    if (router) {
      router.calculateRoute(
        {
          routingMode,
          transportMode,
          departureTime,
          via: new H.service.Url.MultiValueQueryParameter(
            intermediates.map((item) => item.lat + ',' + item.lng)
          ),
          // The start point of the route:
          origin: origin.lat + ',' + origin.lng,
          // The end point of the route:
          destination: destination.lat + ',' + destination.lng,
          // Include the route shape in the response
          return: 'polyline',
        },
        (result: object) => {
          this.addRouteResult(result as IHERERouteResponse, map, addMarkers);
        },
        function () {
          // console.log('[HERE Route error]', error);
        }
      );
    }
  }

  /**
   * Adds the route to the map with the Routing API's response
   *
   * @param result - Routing API's response
   * @param map - The map object
   * @param addMarkers - Boolean to show markers on the map
   */
  addRouteResult(
    result: IHERERouteResponse,
    map: H.Map,
    addMarkers = true
  ): void {
    const boundingBoxes: H.map.Polyline[] = [];

    if (result?.routes.length) {
      result.routes[0].sections.forEach((section: IHERERouteSection) => {
        const objectsToAdd = [];

        // Create a linestring to use as a point source for the route line
        const linestring = H.geo.LineString.fromFlexiblePolyline(
          section.polyline
        );

        // Create a polyline to display the route:
        const routeLine = new H.map.Polyline(linestring, {
          style: { strokeColor: 'blue', lineWidth: 3 },
          data: null,
        });
        objectsToAdd.push(routeLine);
        boundingBoxes.push(routeLine);

        if (addMarkers) {
          const startMarker = new H.map.Marker(
            section.departure.place.location
          );
          objectsToAdd.push(startMarker);

          const endMarker = new H.map.Marker(section.arrival.place.location);
          objectsToAdd.push(endMarker);
        }

        if (map) {
          // Add the route polyline and the two markers to the map:
          map.addObjects(objectsToAdd);
        }
      });

      if (map) {
        let top = boundingBoxes[0].getBoundingBox()?.getTop() ?? 0;
        let bottom = boundingBoxes[0].getBoundingBox()?.getBottom() ?? 0;
        let left = boundingBoxes[0].getBoundingBox()?.getLeft() ?? 0;
        let right = boundingBoxes[0].getBoundingBox()?.getRight() ?? 0;

        boundingBoxes.forEach((section: H.map.Polyline) => {
          const boundingBox = section.getBoundingBox();
          if (boundingBox) {
            top = Math.max(top, boundingBox.getTop());
            bottom = Math.min(bottom, boundingBox.getBottom());
            left = Math.min(left, boundingBox.getLeft());
            right = Math.max(right, boundingBox.getRight());
          }
        });

        // Set the map's viewport to make the whole route visible:
        map.getViewModel().setLookAtData({
          bounds: new H.geo.Rect(top, left, bottom, right),
        });
      }
    }
  }

  /**
   * Adds a marker to the map
   *
   * @param map - The map object
   * @param position - The coordinates to use
   * @returns The marker object
   */
  addMarker(
    map: H.Map,
    position: { lat: number; lng: number }
  ): H.map.Marker | null {
    if (map) {
      const marker = new H.map.Marker(position);
      map.addObject(marker);

      return marker;
    }

    return null;
  }

  /**
   * Removes a marker from the map
   *
   * @param map - The map object
   * @param marker - The marker object to be removed from the mop
   */

  removeMarker(map: H.Map, marker: H.map.Marker[]) {
    if (map && marker) {
      map.removeObjects(marker);
    }
  }
}
