import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'astus-settings-header',
  templateUrl: './settings-header.component.html',
  styleUrls: ['./settings-header.component.scss'],
})
export class SettingsHeaderComponent {
  isMobile = window.innerWidth < 600;

  onIconClick() {
    window.history.back();
  }

  @HostListener('window:resize')
  onResize() {
    this.isMobile = window.innerWidth < 600;
  }
}
