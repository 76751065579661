<astus-drawer (closeDrawerEvent)="onCancelClick()">
  <div class="hour-picker-container" drawerContent>
    <div class="drawer-hour-picker-container">
      <!-- hours start / end buttons -->
      <div class="hour-picker-range-selector-button-container">
        <span
          class="item text-p3"
          [ngClass]="{ selected: startHoursIsSelected }"
          (click)="changeStartHoursTab(true)"
        >
          {{ 'HOUR_PICKER.DATE_RANGE.START' | translate }}
        </span>
        <span
          class="item text-p3"
          [ngClass]="{ selected: !startHoursIsSelected }"
          (click)="changeStartHoursTab(false)"
        >
          {{ 'HOUR_PICKER.DATE_RANGE.END' | translate }}
        </span>
      </div>

      <!-- hours selector (element used to scroll into the list of hours) -->
      <div class="hour-picker-hours-selector-container">
        <!-- should be the selector for hour -->
        <div class="hours-selector">
          <span
            class="previous-hours"
            (click)="
              onHoursChange(false, hoursForPicker[hoursPicker.previousIndex])
            "
          >
            {{ hoursForPicker[hoursPicker.previousIndex].value }}
          </span>
          <span>{{ hoursForPicker[hoursPicker.selectedIndex].value }}</span>
          <span
            class="next-hours"
            (click)="onHoursChange(true, hoursForPicker[hoursPicker.nextIndex])"
          >
            {{ hoursForPicker[hoursPicker.nextIndex].value }}
          </span>
        </div>

        <!-- should be the separator `:` -->
        <div class="separator">:</div>

        <!-- should be the selector for minutes -->
        <div class="minutes-selector">
          <span
            class="previous-minutes"
            (click)="
              onMinutesChange(
                false,
                minutesForPicker[minutesPicker.previousIndex]
              )
            "
          >
            {{ minutesForPicker[minutesPicker.previousIndex].value }}
          </span>
          <span>{{ minutesForPicker[minutesPicker.selectedIndex].value }}</span>
          <span
            class="next-minutes"
            (click)="
              onMinutesChange(true, minutesForPicker[minutesPicker.nextIndex])
            "
          >
            {{ minutesForPicker[minutesPicker.nextIndex].value }}
          </span>
        </div>
      </div>

      <!-- cta for the selector (cancel / confirm) -->
      <div class="hour-picker-cta-container">
        <div class="cta-button text-p2" (click)="onCancelClick()">
          {{ 'HOUR_PICKER.CTA.CANCEL' | translate }}
        </div>
        <div class="cta-button text-p2--semibold" (click)="onConfirmClick()">
          {{ 'HOUR_PICKER.CTA.CONFIRM' | translate }}
        </div>
      </div>
    </div>
  </div>
</astus-drawer>
