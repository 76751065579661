<div class="label-container">
  <span *ngIf="label.length > 0" class="text-caption--semibold">{{
    label + (required ? ' *' : '')
  }}</span>
</div>
<div class="container">
  <div>
    <div
      #inputRolesPermissions
      class="resource-role-permission-container"
      (click)="openRolesPermissionsOverlay()"
      [ngClass]="{
        'field-error': errors,
        'disabled': disabled,
      }"
    >
      <div class="roles-permissions-container">
        <!-- render chips list if roles and permissions selected -->
        <div
          #chipsContainer
          class="chips-container"
          *ngIf="rolesPermissionsSelectedList.length"
          [ngClass]="{
            'disabled': disabled,
          }"
        >
          <ad-chip
            *ngFor="let item of rolesPermissionsSelectedList"
            color="primary"
            [selected]="true"
            [id]="item.id.toString()"
          >
            {{ item.name }}
          </ad-chip>
        </div>

        <!-- render placeholder if no roles and permission selected -->
        <div
          *ngIf="!rolesPermissionsSelectedList.length"
          class="placeholder text-p3"
        >
          {{ placeholder }}
        </div>
      </div>
      <div class="caret-container">
        <span
          class="caret"
          [ngClass]="{
        'overlay-open': overlayShown,
        'overlay-close': !overlayShown,
      }"
        >
          <ad-icon
            [glyph]="'caret_down'"
            size="small"
            [color]="'neutral-700'"
          ></ad-icon>
        </span>
      </div>
    </div>
    <div
      *ngIf="errorMessage && objectKeys(errorMessage).length > 0"
      class="error-message"
      [ngClass]="{ 'error-message--show': errors }"
    >
      <div class="error-message__inner">
        <div
          *ngFor="let error of errorMessage | keyvalue"
          class="error-message__item"
          [ngClass]="{
            'error-message__item--show': errors?.hasOwnProperty(error.key)
          }"
        >
          <span class="error-message__item-inner text-caption">
            {{ error.value }}
          </span>
        </div>
      </div>
    </div>

    <div
      #overlayRolesPermissions
      class="overlay-menu"
      [ngClass]="{
        'overlay-menu--open': overlayShown,
        'overlay-menu--close': !overlayShown,
      }"
    >
      <div class="overlay-menu-content">
        <ng-container *ngFor="let item of rolesPermissionsList; let i = index">
          <div class="overlay-menu-item">
            <ad-checkbox
              (click)="onRolesPermissionItemClicked(item)"
              [id]="item.id.toString()"
              [selected]="selectedRoleNames.includes(item.name)"
              [text]="item.name"
            >
            </ad-checkbox>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
