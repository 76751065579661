import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

// Mapbox
import { Router } from '@angular/router';
import {
  ASSIGNED_CLUSTER_COLOR,
  CARD_CIRCUITS_BASE_STRING,
  CARD_VEHICLE_BASE_STRING,
  CIRCUIT_TRANSIT_STROKE,
  CLUSTER_TEXT_COLOR,
  CONFIGMapBox,
  CircuitBounceRouteType,
  HEAVY_TRAFFIC_COLOR,
  IMapboxConfig,
  IMapboxDirectionLocalization,
  IMapboxDirectionsResponse,
  LOW_TRAFFIC_COLOR,
  MAINTENANCE_VEHICLE_COLOR,
  MARKER_BUILDING_BASE_STRING,
  MARKER_START_PIN_BASE_STRING,
  MARKER_VEHICLE_BASE_STRING,
  MODERATE_TRAFFIC_COLOR,
  MapboxHelper,
  MapboxService,
  OFF_DUTY_VEHICLE_COLOR,
  ON_DUTY_VEHICLE_COLOR,
  OUT_OF_SERVICE_VEHICLE_COLOR,
  POPOVER_CIRCUIT_BASE_STRING,
  POPOVER_VEHICLE_BASE_STRING,
  RADAR_VEHICLE_BASE_STRING,
  SELECTED_CIRCUIT_COLOR_LINE,
  SELECTED_CIRCUIT_COLOR_STROKE,
  SEVERE_TRAFFIC_COLOR,
  STATUS_ASSIGNED,
  STATUS_UNASSIGNED,
  UNASSIGNED_CLUSTER_COLOR,
  UNKNOWN_TRAFFIC_COLOR,
  UNSELECTED_CIRCUIT_COLOR_LINE,
  UNSELECTED_CIRCUIT_COLOR_STROKE,
  VEHICLE_BASE_STRING,
} from '@fms/maps/ng-mapbox';

import {
  CircuitView,
  VehicleStateType,
  VehicleView,
  VehiclesService,
  VssSignalView,
} from '@fms/ng-fms-api-client';
import { Store } from '@ngrx/store';

import mapboxgl from 'mapbox-gl';

import { Observable, Subscription, combineLatest, take } from 'rxjs';

import { appRoutesEnum } from '../../app-routing.module';
import { supervisionInfoboxRoutesEnum } from '../map-routing.module';
import { MapTabValueEnum } from '../map-tab.enum';
import { mapAPIActions } from '../store/map.action';
import {
  selectCircuitsFeature,
  selectVehiclesFeature,
  selectVehiclesPositions,
} from '../store/map.selector';

@Component({
  selector: 'astus-map-canvas',
  templateUrl: './map-canvas.component.html',
  styleUrls: ['./map-canvas.component.scss'],
})
export class MapCanvasComponent
  implements AfterViewInit, OnDestroy, OnChanges, OnInit
{
  readonly TIMEOUT_DURATION = 10000;

  @Input() vehicleToDisplay: VehicleView | undefined;

  @Input() vehicleMarkerSelected = false;

  @Input() circuitToDisplay: CircuitView | undefined;

  @Input() circuitMarkerSelected = false;

  @Input() id: string | null = null;

  @Input() tab = MapTabValueEnum.CIRCUITS;

  @Input() mapboxMap: mapboxgl.Map | null;

  circuitsSubscription: Subscription = new Subscription();
  vehiclesSubscription: Subscription = new Subscription();
  vehiclePositionSubscription: Subscription = new Subscription();
  reccurentVehiclesPositionsSubscription: Subscription = new Subscription();
  circuitsItems: CircuitView[] = [];
  vehiclesItems: VehicleView[] = [];

  mapboxVehicleMarkersOnMap: string[] = [];
  mapboxDatasetOnMap: string[] = [];
  mapboxRoutesOnMap: string[] = [];
  isMapboxLoaded = false;
  isVehiclesLoaded = false;
  isStopsLoaded = false;
  mapCircuitsIds: string[] = [];
  mapMarkersArray: mapboxgl.Marker[] = [];

  isLayerDefault = true;
  isLayerSatellite = false;
  isLayerTraffic = false;
  isLayerBike = false;
  isLayerOutdoors = false;
  showLoading = true;

  isResetDisabled = true;
  currentView: 'clusters' | 'routes' | 'none' = 'none';

  initialZoomLevel = 9;
  zoomLevelThreshold = 11;
  zoomInLevelMarker = 14;
  enableFlyZoom = true;

  vehicleHovered = false;

  circuitId: string | null = null;
  circuitBounceRoute: CircuitBounceRouteType[] = [];

  private intervalIds: number[] = [];

  @ViewChild('map') mapDiv?: ElementRef;

  @Output() vehicleToDisplayChange = new EventEmitter<
    VehicleView | undefined
  >();
  @Output() circuitToDisplayChange = new EventEmitter<
    CircuitView | undefined
  >();
  @Output() vehicleMarkerSelectedChange = new EventEmitter<boolean>();
  @Output() circuitMarkerSelectedChange = new EventEmitter<boolean>();
  @Output() idChange = new EventEmitter<string | null>();

  @Output() tabChange = new EventEmitter<MapTabValueEnum>();

  @Output() mapboxMapChange = new EventEmitter<mapboxgl.Map | null>();

  @Output() vehiclesOnMap = new EventEmitter<string[]>();

  constructor(
    @Inject(CONFIGMapBox) private mapboxConfig: IMapboxConfig,
    private store: Store,
    private mapboxService: MapboxService,
    private containerRef: ElementRef<HTMLDivElement>,
    private mapboxHelper: MapboxHelper,
    private router: Router,
    private vehiclesService: VehiclesService
  ) {
    this.mapboxMap = null;
  }

  ngOnInit(): void {
    if (this.tab === MapTabValueEnum.CIRCUITS && this.id) {
      this.circuitId = this.id;
    }
  }

  ngAfterViewInit() {
    this.initMapBox();

    this.circuitsSubscription = this.store
      .select(selectCircuitsFeature)
      .subscribe((circuits) => {
        this.circuitsItems = circuits.data;

        this.vehiclesSubscription = this.store
          .select(selectVehiclesFeature)
          .subscribe((vehicles) => {
            this.vehiclesItems = vehicles.data;

            if (this.vehiclesItems.length && this.circuitsItems.length) {
              this.handleMapLayout();
            }
          });
      });

    this.mapboxMap?.on('style.load', () => {
      this.isMapboxLoaded = true;
      this.handleMapLayout();
      this.mapboxMapChange.emit(this.mapboxMap);
    });
  }

  ngOnChanges(): void {
    if (this.vehicleToDisplay) {
      this.handleVehicleDisplay();
    } else {
      // Hide previous vehicle selected/hovered
      this.getAllVehiclePopovers().forEach((vehiclePopoverElement) => {
        vehiclePopoverElement?.style.setProperty('opacity', '0');
        vehiclePopoverElement.setAttribute('clicked', 'false');
        vehiclePopoverElement.setAttribute('hovered', 'false');
      });

      this.handleCircuitDisplay();
    }
  }

  ngOnDestroy(): void {
    this.intervalIds.forEach((id) => window.clearInterval(id));
    this.circuitsSubscription.unsubscribe();
    this.vehiclesSubscription.unsubscribe();
    this.vehiclePositionSubscription.unsubscribe();
    this.reccurentVehiclesPositionsSubscription.unsubscribe();
    this.mapboxMap?.remove();
    this.mapboxMap = null;
  }

  initMapBox() {
    this.mapboxMap = new mapboxgl.Map({
      accessToken: this.mapboxConfig.accessToken,
      container: this.mapDiv?.nativeElement ?? 'map',
      style: this.mapboxConfig.mapDefaultStyle,
      center: [-73.569807, 45.503182],
      zoom: this.initialZoomLevel,
    });

    if (this.mapboxMap) {
      this.mapboxMap.on('load', () => {
        this.store.dispatch(mapAPIActions.stopsLoad());
        this.mapboxHelper.zoomInMapWithUrlParams(this.mapboxMap, this.id);
      });
      this.mapboxMap.on('style.load', () => {
        if (this.isMapboxLoaded) {
          this.handleMapLayout();
        }
      });
      this.mapboxMap.on('moveend', () => {
        this.mapboxHelper.replaceURLWithZoomLatLng(this.mapboxMap);
      });
      this.mapboxMap.on('zoom', () => {
        this.handleResetDisableChange();
      });
      this.mapboxMap.on('zoomend', () => {
        this.handleMapLayout();
      });

      this.mapboxMap.on('render', () => {
        // I need to use render to resize the map dynamically
        // Mapbox has some issues with the resize method
        // so when the map is rendered, we need to resize map, otherwise the map has a 300px height by default
        // https://github.com/mapbox/mapbox-gl-js/issues/3265#issuecomment-480477378
        this.mapboxMap?.resize();
      });
    }
  }

  /**
   * @description
   * Change the tab based on the event value and navigate to the new route based on the tab value.
   * If the tab value is circuits, then navigate to /map/circuits.
   * If the tab value is vehicles, then navigate to /map/vehicles.
   * If the tab value is drivers, then navigate to /map/drivers.
   * @param {string | number | null} id - The id value
   * @returns {void}
   */
  changeRoute(id?: string | number | null, route?: MapTabValueEnum): void {
    let url = `/${appRoutesEnum.SUPERVISION}/${MapTabValueEnum[
      this.tab
    ].toLocaleLowerCase()}`;

    if (id !== null && id !== undefined && route === MapTabValueEnum.VEHICLES) {
      this.id = id.toString();
      url = `/${appRoutesEnum.SUPERVISION}/${supervisionInfoboxRoutesEnum.VEHICLE}/${id}`;
    } else if (
      id !== null &&
      id !== undefined &&
      route === MapTabValueEnum.CIRCUITS
    ) {
      this.circuitId = id.toString();
      url = `/${appRoutesEnum.SUPERVISION}/${supervisionInfoboxRoutesEnum.CIRCUIT}/${id}`;
    } else if (id === null) {
      this.id = null;
      this.circuitId = null;
    }

    this.idChange.emit(this.id);
    this.router.navigateByUrl(url);
  }

  handleMapLayout() {
    if (this.mapboxMap) {
      const zoom = this.mapboxMap.getZoom();

      if (zoom > this.zoomLevelThreshold && this.currentView === 'clusters') {
        this.initRoutes();
        this.isVehiclesLoaded && this.handleDisplayVehicleMarker(true);
        this.isStopsLoaded && this.handleDisplayStopsMarker(true);
      } else if (
        zoom <= this.zoomLevelThreshold &&
        this.currentView === 'routes'
      ) {
        this.handleDisplayVehicleMarker(false);
        this.handleDisplayStopsMarker(false);
        this.initClusterPoints();
      } else if (this.currentView === 'none') {
        if (this.initialZoomLevel <= this.zoomLevelThreshold) {
          this.isVehiclesLoaded || this.initVehiclesInMap(),
            this.handleDisplayVehicleMarker(false),
            this.initClusterPoints();

          if (this.id && this.tab === MapTabValueEnum.CIRCUITS) {
            this.initRoutes();
          }
        } else {
          this.initRoutes();
        }
      } else if (zoom <= this.zoomLevelThreshold) {
        this.initClusterPoints();
        this.handleDisplayVehicleMarker(false);
        this.handleDisplayStopsMarker(false);
      }
    }
  }

  /**
   * Handle the events to display the vehicle popover when the user click on the vehicle card in the list
   * or the user click on the vehicle marker on the map
   * and close it when the user click outside the vehicle container element
   * or when the user click on another tab
   * or the user click another vehicle or vehicle marker
   * @returns { void }
   */
  handleVehicleDisplay(): void {
    this.getAllVehiclePopovers().forEach((vehiclePopoverElement) => {
      if (
        vehiclePopoverElement.id !==
        `${POPOVER_VEHICLE_BASE_STRING}${this.vehicleToDisplay?.id}`
      ) {
        vehiclePopoverElement?.style.setProperty('opacity', '0');
        vehiclePopoverElement.setAttribute('clicked', 'false');
        vehiclePopoverElement.setAttribute('hovered', 'false');
        vehiclePopoverElement.parentElement?.style.setProperty('z-index', '5');
      } else {
        vehiclePopoverElement?.style.setProperty('opacity', '1');
        vehiclePopoverElement.setAttribute('clicked', 'true');
        vehiclePopoverElement.parentElement?.style.setProperty('z-index', '6');
      }
    });

    // set the vehicle marker to the center of the map when the user click on the vehicle marker in the list
    // or the user click the card vehicle
    const markerVehicle = this.mapMarkersArray.find(
      (marker) =>
        marker.getElement()?.id ===
        `${MARKER_VEHICLE_BASE_STRING}${this.vehicleToDisplay?.id}`
    );

    if (markerVehicle && this.tab === MapTabValueEnum.VEHICLES) {
      // set zoom in the map box with an animation of 1000ms
      // set the center of the map box with an animation of 1000ms
      // set the vehicle marker selected to true
      this.mapboxMap?.flyTo({
        zoom: this.zoomInLevelMarker,
        center: markerVehicle.getLngLat(),
        essential: true,
        duration: 1000,
      });
    }
  }

  /**
   * Handle the events to display the circuit popover when the user click on the circuit card in the list
   * or the user click on the circuit marker on the map
   * and close it when the user click outside the circuit container element
   * or when the user click on another tab
   * or the user click another circuit or vehicle marker
   * @returns { void }
   */
  handleCircuitDisplay(): void {
    if (
      (this.circuitToDisplay &&
        this.circuitId !== this.circuitToDisplay.id.toString()) ||
      this.tab !== MapTabValueEnum.CIRCUITS
    ) {
      // Hide previous circuit selected/hovered
      this.mapboxHelper.hideCircuitMap(this.mapboxMap, this.circuitId);

      // Show new circuit selected/hovered
      if (this.circuitToDisplay) {
        const zoom = this.mapboxMap?.getZoom();
        this.circuitId = this.circuitToDisplay.id.toString();
        const currCircuitIdToShow = `circuit-${this.circuitId}`;

        if (currCircuitIdToShow) {
          if (zoom && zoom <= this.zoomLevelThreshold) {
            this.initRoutes();
          }

          setTimeout(() => {
            if (this.mapboxMap) {
              this.handleDisplayVehicleMarker(true);
              this.handleDisplayStopsMarker(true);
              this.mapboxHelper.showCircuitMap(this.mapboxMap, this.circuitId);
              this.mapboxHelper.mapFitBounds(
                this.mapboxMap,
                this.circuitId,
                this.circuitBounceRoute
              );
            }
          }, 300);
        }
      }
    } else if (!this.circuitToDisplay && this.circuitId) {
      this.mapboxHelper.hideCircuitMap(this.mapboxMap, this.circuitId);
    } else {
      this.mapboxHelper.showCircuitMap(this.mapboxMap, this.circuitId);
    }
  }

  /**
   * @description Handle to display the vehicle marker on the map when the user zoom in the map
   * or zoom out the map depending on the zoom level threshold
   * @param { boolean } isDisplay - If the vehicle marker is displayed or not
   * @returns { void }
   */
  handleDisplayVehicleMarker(isDisplay: boolean): void {
    const displayValue = isDisplay ? 'block' : 'none';
    const vehicleMarkers = document.querySelectorAll(
      `[id^="${MARKER_VEHICLE_BASE_STRING}"]`
    );

    vehicleMarkers.forEach((vehicleMarker: Element) => {
      (vehicleMarker as HTMLElement).style.display = displayValue;

      if (
        vehicleMarker.id === `${MARKER_VEHICLE_BASE_STRING}${this.id}` &&
        this.tab === MapTabValueEnum.VEHICLES
      ) {
        // set the vehicle marker to the center of the map when the user click on the vehicle marker in the list
        // or the user click the card vehicle
        const markerVehicle = this.mapMarkersArray.find(
          (marker) =>
            marker.getElement()?.id ===
            `${MARKER_VEHICLE_BASE_STRING}${this.id}`
        );

        if (markerVehicle) {
          // set zoom in the map box with an animation of 1000ms
          // set the center of the map box with an animation of 1000ms
          // set the vehicle marker selected to true
          this.mapboxMap?.flyTo({
            center: markerVehicle.getLngLat(),
            essential: true,
            duration: 1000,
          });
          this.vehicleMarkerSelected = true;
        }

        const vehiclePopoverElements = Array.from(this.getAllVehiclePopovers());
        const vehiclePopover = vehiclePopoverElements.find(
          (vehiclePopoverElement: { id: string }) =>
            vehiclePopoverElement.id ===
            `${POPOVER_VEHICLE_BASE_STRING}${this.id}`
        );

        vehiclePopover?.style.setProperty('opacity', '1');
        vehiclePopover?.setAttribute('clicked', 'true');
      }
    });
  }

  /**
   * @description Handle to display the stop marker on the map when the user zoom in the map
   * or zoom out the map depending on the zoom level threshold
   * @param { boolean } isDisplay - If the stop marker is displayed or not
   * @returns { void }
   */
  handleDisplayStopsMarker(isDisplay: boolean): void {
    const displayValue = isDisplay ? 'block' : 'none';
    const buildingMarkers: NodeListOf<HTMLElement> = document.querySelectorAll(
      `[id^="${MARKER_BUILDING_BASE_STRING}"]`
    );

    const startPinMarkers: NodeListOf<HTMLElement> = document.querySelectorAll(
      `[id^="${MARKER_START_PIN_BASE_STRING}"]`
    );

    buildingMarkers.forEach((buildingMarker) => {
      buildingMarker.style.display = displayValue;
    });
    startPinMarkers.forEach((startPinMarker) => {
      startPinMarker.style.display = displayValue;
    });
  }

  initVehiclesInMap() {
    if (this.mapboxMap && this.vehiclesItems.length) {
      // On click outside the vehicle container in the map, close the popover vehicle
      const mapCanvasElement =
        this.containerRef.nativeElement.getElementsByClassName(
          'mapboxgl-map'
        )[0];

      (mapCanvasElement as HTMLElement).onclick = (e) => {
        this.enableFlyZoom = true;
        const targetElement = e.target as HTMLElement;

        if (!targetElement.id) {
          this.getAllVehiclePopovers().forEach((vehiclePopoverElement) => {
            vehiclePopoverElement?.style.setProperty('opacity', '0');
            vehiclePopoverElement.setAttribute('clicked', 'false');
            vehiclePopoverElement.setAttribute('hovered', 'false');

            this.vehicleMarkerSelected = false;
            this.vehicleMarkerSelectedChange.emit(this.vehicleMarkerSelected);

            this.vehicleToDisplay = undefined;
            this.vehicleToDisplayChange.emit(this.vehicleToDisplay);
          });
        }
      };

      const vehicleIds = this.vehiclesItems.map((vehicle) =>
        vehicle.id.toString()
      );
      // dispatch the action to load the vehicles positions
      this.store.dispatch(
        mapAPIActions.vehiclesPositionsLoad({ ids: vehicleIds })
      );
      this.store
        .select(selectVehiclesPositions)
        .pipe(take(2))
        .subscribe((vehiclesPositions) => {
          if (vehiclesPositions.length) {
            // fror each vehicle position, initiate the vehicle position on the map
            vehiclesPositions.forEach((vehiclePosition) => {
              const vehicle = this.vehiclesItems.find(
                (vehicleItem) => vehicleItem.id === vehiclePosition.id
              );
              if (vehicle) {
                this.initiateVehiclePosition(
                  vehicle,
                  vehiclePosition.vssSignals
                );
              }
            });
          }
        });

      this.isVehiclesLoaded = true;
    }
    this.updateVehiclesOnMap();
  }

  initiateVehiclePosition(vehicle: VehicleView, signals: VssSignalView[]) {
    const lat =
      signals.find(
        (signal) => signal.name === 'Vehicle.CurrentLocation.Latitude'
      )?.value || '0';
    const lng =
      signals.find(
        (signal) => signal.name === 'Vehicle.CurrentLocation.Longitude'
      )?.value || '0';

    const heading =
      signals.find(
        (signal) => signal.name === 'Vehicle.CurrentLocation.Heading'
      )?.value || '0';

    this.setVehicleMarker(
      {
        lng: parseFloat(lng),
        lat: parseFloat(lat),
      },
      +heading,
      vehicle
    );
  }

  /**
   * @description Get all the circuit popover elements on the map
   * to be able to close them when the user click outside the circuit container element
   * or the user click on another tab
   * or the user click another circuit or vehicle marker
   * @returns { NodeListOf<HTMLElement> } circuitPopoverElement - The circuit popover element
   */
  getAllCircuitPopovers(): NodeListOf<HTMLElement> {
    return document.querySelectorAll(`[id^="${POPOVER_CIRCUIT_BASE_STRING}"]`);
  }

  /**
   * TODO click event on the circuit container element
   * @description Handle the events to display the circuit popover when the user click on the circuit on the map
   * and close it when the user click outside the circuit container element
   * or when the user click on the circuit again.
   * The circuit popover is displayed when the user hover the circuit container element on the map
   * and hide it when the user leave the circuit container element.
   * @param { HTMLDivElement } circuitContainerElement - The circuit container element on the map
   * @param { CircuitViewView } circuit - The circuit data
   * @param { string } currCircuitId - The circuit id of the route on the map
   * @returns { void }
   */
  handleEventsDisplayCircuitPopover(
    circuitContainerElement: HTMLDivElement,
    circuit: CircuitView,
    currCircuitId: string
  ): void {
    // Set up the hover effect
    this.mapboxMap?.on('mouseenter', currCircuitId, () => {
      const circuitPopover = document.getElementById(
        `${POPOVER_CIRCUIT_BASE_STRING}${circuit.id}`
      );

      if (
        circuitPopover?.getAttribute('hovered') !== 'true' &&
        circuitPopover?.getAttribute('clicked') !== 'true' &&
        !this.vehicleHovered
      ) {
        circuitPopover?.style.setProperty('opacity', '1');
        circuitPopover?.setAttribute('hovered', 'true');
        circuitContainerElement.style.setProperty('z-index', '10');

        if (this.mapboxMap) {
          this.mapboxMap.getCanvas().style.cursor = 'pointer'; // Change cursor on hover
          this.mapboxHelper.updateCircuitStyles(
            this.mapboxMap,
            currCircuitId,
            SELECTED_CIRCUIT_COLOR_LINE,
            SELECTED_CIRCUIT_COLOR_STROKE
          );
        }
      }

      if (this.tab === MapTabValueEnum.CIRCUITS && !this.vehicleHovered) {
        const circuitCard = document.getElementById(
          `${CARD_CIRCUITS_BASE_STRING}${circuit.id}`
        );
        const circuitMoreInfoCard = circuitCard?.firstElementChild?.children[1];

        circuitCard?.classList.add('is-hovered-selected');
        circuitMoreInfoCard?.classList.add('is-hovered');
      }
    });

    // Set up the leave hover effect
    this.mapboxMap?.on('mouseleave', currCircuitId, () => {
      const circuitPopover = document.getElementById(
        `${POPOVER_CIRCUIT_BASE_STRING}${circuit.id}`
      );

      if (
        circuitPopover?.getAttribute('clicked') !== 'true' &&
        circuitPopover?.getAttribute('hovered') === 'true'
      ) {
        circuitPopover?.style.setProperty('opacity', '0');
        circuitPopover?.setAttribute('hovered', 'false');
        circuitContainerElement.style.setProperty('z-index', '2');

        if (this.mapboxMap) {
          this.mapboxMap.getCanvas().style.cursor = ''; // Reset cursor on leave
          this.mapboxHelper.updateCircuitStyles(
            this.mapboxMap,
            currCircuitId,
            UNSELECTED_CIRCUIT_COLOR_LINE,
            UNSELECTED_CIRCUIT_COLOR_STROKE
          );
        }

        if (this.tab === MapTabValueEnum.CIRCUITS && !this.vehicleHovered) {
          const circuitCard = document.getElementById(
            `${CARD_CIRCUITS_BASE_STRING}${circuit.id}`
          );
          const circuitMoreInfoCard =
            circuitCard?.firstElementChild?.children[1];

          circuitCard?.classList.remove('is-hovered-selected');
          circuitMoreInfoCard?.classList.remove('is-hovered');
        }
      }
    });

    // Your existing click event handler
    this.mapboxMap?.on('click', (e) => {
      const features = this.mapboxMap?.queryRenderedFeatures(e.point);
      const isClickInsideCircuit = features?.some(
        (feature) => feature.layer.id === currCircuitId
      );

      // Check if all features are not included in the vehicle or circuit layers
      if (
        features?.every(
          (feature) =>
            !feature.layer ||
            (!feature.layer.id.includes('vehicle') &&
              !feature.layer.id.includes('circuit'))
        )
      ) {
        this.resetMapState();
        this.handleOutsideClick(
          circuit,
          currCircuitId,
          circuitContainerElement
        );
      } else if (
        isClickInsideCircuit &&
        this.mapboxMap &&
        !this.vehicleHovered
      ) {
        this.handleCircuitClick(
          currCircuitId,
          circuit,
          circuitContainerElement
        );
      } else if (this.mapboxMap) {
        this.handleOutsideClick(
          circuit,
          currCircuitId,
          circuitContainerElement
        );
      }
    });
  }

  /**
   * @description Reset the map state
   * when the user click outside the circuit container element
   * or the user click on another tab
   * or the user click another circuit or vehicle marker
   * @returns { void }
   */
  resetMapState(): void {
    this.circuitToDisplay = undefined;
    this.circuitToDisplayChange.emit(this.circuitToDisplay);
    this.vehicleToDisplay = undefined;
    this.vehicleToDisplayChange.emit(this.vehicleToDisplay);
    this.changeRoute(null);
  }

  /**
   * @description Handle the circuit click event to display the circuit popover
   * when the user click on the circuit on the map
   * and close it when the user click outside the circuit container element
   * or when the user click on the circuit again.
   * @param { string } currCircuitId - The circuit id of the route on the map
   * @param { CircuitView } circuit - The circuit data
   * @param { HTMLElement | null } circuitContainerElement - The circuit container element on the map
   * @returns { void }
   */
  handleCircuitClick(
    currCircuitId: string,
    circuit: CircuitView,
    circuitContainerElement: HTMLElement | null
  ): void {
    this.tab = MapTabValueEnum.CIRCUITS;
    this.tabChange.emit(MapTabValueEnum.CIRCUITS);
    this.id = null;

    if (this.mapboxMap) {
      this.mapboxMap.getCanvas().style.cursor = 'pointer'; // Change cursor on hover
    }

    setTimeout(() => {
      if (this.mapboxMap) {
        this.handleDisplayVehicleMarker(true);
        this.handleDisplayStopsMarker(true);
        this.mapboxHelper.showCircuitMap(this.mapboxMap, this.circuitId);
        this.mapboxHelper.mapFitBounds(
          this.mapboxMap,
          this.circuitId,
          this.circuitBounceRoute
        );
      }
    }, 300);

    this.mapboxHelper.updateCircuitStyles(
      this.mapboxMap,
      currCircuitId,
      SELECTED_CIRCUIT_COLOR_LINE,
      SELECTED_CIRCUIT_COLOR_STROKE
    );

    const circuitPopover = document.getElementById(
      `${POPOVER_CIRCUIT_BASE_STRING}${circuit.id}`
    );

    if (circuitPopover?.getAttribute('clicked') !== 'true') {
      this.showCircuitPopover(circuit, circuitPopover, circuitContainerElement);
      this.circuitId = circuit.id.toString();
    }
  }

  /**
   * @description Handle the events to close the circuit popover
   * when the user click outside the circuit container element
   * or the user click on another tab
   * or the user click another circuit or vehicle marker
   * @param { CircuitView } circuit - The circuit data
   * @param { string } currCircuitId - The circuit id of the route on the map
   * @param { HTMLElement | null } circuitContainerElement - The circuit container element on the map
   * @returns { void }
   */
  handleOutsideClick(
    circuit: CircuitView,
    currCircuitId: string,
    circuitContainerElement: HTMLElement | null
  ): void {
    this.getAllCircuitPopovers().forEach((circuitPopoverElement) => {
      if (
        circuitPopoverElement.id ===
        `${POPOVER_CIRCUIT_BASE_STRING}${circuit.id}`
      ) {
        if (circuitPopoverElement?.getAttribute('clicked') === 'true') {
          this.hideCircuitPopover(
            circuitPopoverElement,
            circuitContainerElement
          );
          this.circuitId = null;
        }
      }
    });

    if (this.mapboxMap) {
      this.mapboxMap.getCanvas().style.cursor = ''; // Reset cursor on leave
    }
    this.mapboxHelper.updateCircuitStyles(
      this.mapboxMap,
      currCircuitId,
      UNSELECTED_CIRCUIT_COLOR_LINE,
      UNSELECTED_CIRCUIT_COLOR_STROKE
    );
  }

  /**
   * @description Show the circuit popover
   * when the user click on the circuit on the map
   * and the vehicle is not hovered
   * then set the circuit to display and emit the circuit to display change event
   * and change the route to /map/circuit/:id
   * @param { CircuitView } circuit - The circuit data
   * @param { HTMLElement | null } circuitPopover - The circuit popover element
   * @param { HTMLElement | null } circuitContainerElement - The circuit container element on the map
   * @returns { void }
   */
  showCircuitPopover(
    circuit: CircuitView,
    circuitPopover: HTMLElement | null,
    circuitContainerElement: HTMLElement | null
  ): void {
    circuitPopover?.style.setProperty('opacity', '1');
    circuitPopover?.setAttribute('clicked', 'true');
    circuitPopover?.setAttribute('hovered', 'false');
    circuitContainerElement?.style.setProperty('z-index', '3');

    if (!this.vehicleHovered) {
      this.circuitToDisplay = circuit;
      this.circuitToDisplayChange.emit(this.circuitToDisplay);
      this.circuitMarkerSelected = true;
      this.circuitMarkerSelectedChange.emit(this.circuitMarkerSelected);
      this.vehicleToDisplay = undefined;
      this.vehicleToDisplayChange.emit(this.vehicleToDisplay);
      this.vehicleMarkerSelected = false;
      this.vehicleMarkerSelectedChange.emit(this.vehicleMarkerSelected);
      this.changeRoute(circuit.id, MapTabValueEnum.CIRCUITS);
    }
  }

  /**
   * @description Hide the circuit popover
   * when the user click outside the circuit container element
   * or the user click on another tab
   * or the user click another circuit or vehicle marker
   * @param { HTMLElement } circuitPopoverElement - The circuit popover element
   * @param { HTMLElement | null } circuitContainerElement - The circuit container element on the map
   * @returns { void }
   */
  hideCircuitPopover(
    circuitPopoverElement: HTMLElement,
    circuitContainerElement: HTMLElement | null
  ): void {
    circuitPopoverElement.style.setProperty('opacity', '0');
    circuitPopoverElement.setAttribute('clicked', 'false');
    circuitPopoverElement.setAttribute('hovered', 'false');
    circuitContainerElement?.style.setProperty('z-index', '2');
  }

  /**
   * @description Set the circuit popover styles
   * and return the circuit popover element to be added to the circuit container element on the map.
   * The circuit popover is the element that will be displayed
   * when the user click on the circuit marker on the map
   * and it will display the circuit name
   * @param { CircuitView } circuit - The circuit data
   * @returns { Node } circuitPopoverElement - The circuit popover element
   */
  setCircuitPopoverStyles(circuit: CircuitView): Node {
    const circuitPopoverElement = document.createElement('div');
    circuitPopoverElement.id = `${POPOVER_CIRCUIT_BASE_STRING}${circuit.id}`;
    circuitPopoverElement.style.display = 'flex';
    circuitPopoverElement.style.height = 'max-content';
    circuitPopoverElement.style.left = '50%';
    circuitPopoverElement.style.maxWidth = '150px';
    circuitPopoverElement.style.opacity = '0';
    circuitPopoverElement.style.pointerEvents = 'none';
    circuitPopoverElement.style.position = 'absolute';
    circuitPopoverElement.style.top = 'calc(50% - 20px)';
    circuitPopoverElement.style.transform = 'translate(-50%, -100%)';
    circuitPopoverElement.style.width = 'max-content';
    circuitPopoverElement.style.willChange = 'transform';
    circuitPopoverElement.style.transition = 'opacity 0.3s ease-in-out';
    circuitPopoverElement.innerHTML = `
      <div style='background-color: ${UNSELECTED_CIRCUIT_COLOR_STROKE}; box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15); border-radius: 4px; padding: 4px 8px; display: flex'>
        <div style='font-weight: 600; font-size: 12px; line-height: 16px; color: ${CLUSTER_TEXT_COLOR};
            display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden;
            text-overflow: ellipsis;max-width: 150px; overflow-wrap: break-word;'>
          ${circuit.name}
        </div>
      <div style='width: 8px; height: 8px; position: absolute; background-color: ${UNSELECTED_CIRCUIT_COLOR_STROKE}; top: 100%; left: 50%; transform: translate(-50%, -50%) rotate(45deg)'></div>
    `;

    return circuitPopoverElement;
  }

  /**
   * @description Get all the vehicle popover elements on the map
   * to be able to close them when the user click outside the vehicle container element
   * or the user click on another tab
   * or the user click another vehicle marker
   * @returns { NodeListOf<HTMLElement> } vehiclePopoverElement - The vehicle popover element
   */
  getAllVehiclePopovers(): NodeListOf<HTMLElement> {
    return document.querySelectorAll(`[id^="${POPOVER_VEHICLE_BASE_STRING}"]`);
  }

  /**
   * @description Set the vehicle marker color on the map depending on the vehicle status
   * (onDuty, maintenance, outOfService, offDuty)
   * and if the radar is true, set it with the gradient color
   * @param { VehicleStateType } status - The vehicle status
   * @param { boolean } radar - If the radar is true, set the vehicle marker with the gradient color
   * @returns { string } color - The vehicle marker color
   * @throws { Error } Invalid status - If the status is not onDuty, maintenance, outOfService or offDuty
   */
  setStatusVehicleMarker(
    status: VehicleStateType | 'offDuty', // TODO remove the offDuty when the data is available, now it's a fake status and the color is not available in Figma
    radar: boolean
  ): string {
    const colors = {
      onDuty: radar
        ? 'conic-gradient(from 307deg at 50% 50%, rgba(102, 192, 149, 0.20) 0deg, rgba(102, 192, 149, 0.00) 114.37500357627869deg, rgba(102, 192, 149, 0.00) 360deg)'
        : ON_DUTY_VEHICLE_COLOR,
      maintenance: radar
        ? 'conic-gradient(from 307deg at 50% 50%, rgba(171, 177, 181, 0.20) 0deg, rgba(171, 177, 181, 0.00) 114.37500357627869deg, rgba(171, 177, 181, 0.00) 360deg)'
        : MAINTENANCE_VEHICLE_COLOR,
      outOfService: radar
        ? 'conic-gradient(from 307deg at 50% 50%, rgba(248, 113, 113, 0.20) 0deg, rgba(248, 113, 113, 0.00) 114.37500357627869deg, rgba(248, 113, 113, 0.00) 360deg)'
        : OUT_OF_SERVICE_VEHICLE_COLOR,

      // TODO add the offDuty color when the data is available in the BE, now it's a fake status and the color is not available in Figma
      offDuty: radar
        ? 'conic-gradient(from 307deg at 50% 50%, rgba(251, 191, 36, 0.20) 0deg, rgba(251, 191, 36, 0.00) 114.37500357627869deg, rgba(251, 191, 36, 0.00) 360deg)'
        : OFF_DUTY_VEHICLE_COLOR,
    };

    if (status in colors) {
      return colors[status];
    }

    throw new Error(`Invalid status: ${status}`);
  }

  /**
   * @description Set the vehicle popover styles
   * and return the vehicle popover element to be added to the vehicle container element on the map.
   * The vehicle popover is the element that will be displayed
   * when the user click on the vehicle marker on the map
   * and it will display the vehicle name
   * @param { VehicleView } vehicle - The vehicle data
   * @returns { Node } vehiclePopoverElement - The vehicle popover element
   */
  setVehiclePopoverStyles(vehicle: VehicleView): Node {
    const vehiclePopoverElement = document.createElement('div');
    vehiclePopoverElement.id = `${POPOVER_VEHICLE_BASE_STRING}${vehicle.id}`;
    vehiclePopoverElement.style.display = 'flex';
    vehiclePopoverElement.style.height = 'max-content';
    vehiclePopoverElement.style.left = '-50%';
    vehiclePopoverElement.style.maxWidth = '150px';
    vehiclePopoverElement.style.opacity = '0';
    vehiclePopoverElement.style.pointerEvents = 'none';
    vehiclePopoverElement.style.position = 'absolute';
    vehiclePopoverElement.style.top = 'calc(50% - 20px)';
    vehiclePopoverElement.style.transform = 'translate(-35%, -100%)';
    vehiclePopoverElement.style.width = 'max-content';
    vehiclePopoverElement.style.willChange = 'transform';
    vehiclePopoverElement.style.transition = 'opacity 0.3s ease-in-out';
    vehiclePopoverElement.innerHTML = `
    <div style='background-color: ${UNSELECTED_CIRCUIT_COLOR_STROKE}; box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15); border-radius: 4px; padding: 4px 8px; display: flex'>
      <div style='font-weight: 600; font-size: 12px; line-height: 16px; color: ${CLUSTER_TEXT_COLOR};
          display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden;
          text-overflow: ellipsis;max-width: 150px; overflow-wrap: break-word;'>
        ${vehicle.name}
      </div>
    <div style='width: 8px; height: 8px; position: absolute; background-color: ${UNSELECTED_CIRCUIT_COLOR_STROKE}; top: 100%; left: 50%; transform: translate(-50%, -50%) rotate(45deg)'></div>
  `;

    return vehiclePopoverElement;
  }

  /**
   * @description Handle the events to display the vehicle popover when the user click on the vehicle marker on the map
   * and close it when the user click outside the vehicle container element
   * or when the user click on the vehicle marker again.
   * The vehicle popover is displayed when the user hover the vehicle container element on the map
   * and hide it when the user leave the vehicle container element.
   * @param { HTMLDivElement } vehicleContainerElement - The vehicle container element on the map
   * @param { VehicleView } vehicle - The vehicle data
   * @returns { void }
   */
  handleEventsDisplayVehiclePopover(
    vehicleContainerElement: HTMLDivElement,
    vehicle: VehicleView
  ): void {
    vehicleContainerElement.addEventListener('mouseleave', () => {
      this.vehicleHovered = false;
      const vehiclePopover = document.getElementById(
        `${POPOVER_VEHICLE_BASE_STRING}${vehicle.id}`
      );

      if (
        vehiclePopover?.getAttribute('clicked') !== 'true' &&
        vehiclePopover?.getAttribute('hovered') === 'true'
      ) {
        vehiclePopover?.style.setProperty('opacity', '0');
        vehiclePopover?.setAttribute('hovered', 'false');
        vehicleContainerElement.style.setProperty('z-index', '5');
      }

      if (
        vehicle.id !== this.vehicleToDisplay?.id &&
        this.tab === MapTabValueEnum.VEHICLES
      ) {
        const vehicleCard = document.getElementById(
          `${CARD_VEHICLE_BASE_STRING}${vehicle.id}`
        );
        const vehicleMoreInfoCard = vehicleCard?.firstElementChild?.children[1];

        vehicleCard?.classList.remove('is-hovered-selected');
        vehicleMoreInfoCard?.classList.remove('is-hovered');
      }
    });

    vehicleContainerElement.addEventListener('mouseenter', () => {
      this.vehicleHovered = true;
      const vehiclePopover = document.getElementById(
        `${POPOVER_VEHICLE_BASE_STRING}${vehicle.id}`
      );

      if (
        vehiclePopover?.getAttribute('hovered') !== 'true' &&
        vehiclePopover?.getAttribute('clicked') !== 'true'
      ) {
        vehiclePopover?.style.setProperty('opacity', '1');
        vehiclePopover?.setAttribute('hovered', 'true');
        vehicleContainerElement.style.setProperty('z-index', '10');
      }

      if (
        vehicle.id !== this.vehicleToDisplay?.id &&
        this.tab === MapTabValueEnum.VEHICLES
      ) {
        const vehicleCard = document.getElementById(
          `${CARD_VEHICLE_BASE_STRING}${vehicle.id}`
        );
        const vehicleMoreInfoCard = vehicleCard?.firstElementChild?.children[1];
        vehicleCard?.classList.add('is-hovered-selected');
        vehicleMoreInfoCard?.classList.add('is-hovered');
      }
    });

    vehicleContainerElement.onclick = () => {
      this.getAllVehiclePopovers().forEach((vehiclePopoverElement) => {
        if (
          vehicleContainerElement.id !==
          `${POPOVER_VEHICLE_BASE_STRING}${vehicle.id}`
        ) {
          vehiclePopoverElement?.style.setProperty('opacity', '0');
          vehiclePopoverElement.setAttribute('clicked', 'false');
          vehiclePopoverElement.setAttribute('hovered', 'false');
          vehicleContainerElement.style.setProperty('z-index', '5');
        }
      });

      const vehiclePopover = document.getElementById(
        `${POPOVER_VEHICLE_BASE_STRING}${vehicle.id}`
      );

      if (vehiclePopover?.getAttribute('clicked') !== 'true') {
        vehiclePopover?.style.setProperty('opacity', '1');
        vehiclePopover?.setAttribute('clicked', 'true');
        vehicleContainerElement.style.setProperty('z-index', '6');

        this.vehicleMarkerSelected = true;
        this.vehicleToDisplay = vehicle;
        this.circuitMarkerSelected = false;
        this.circuitMarkerSelectedChange.emit(this.circuitMarkerSelected);
        this.circuitId = null;
      }

      this.vehicleMarkerSelectedChange.emit(this.vehicleMarkerSelected);
      this.vehicleToDisplayChange.emit(this.vehicleToDisplay);
      this.changeRoute(vehicle.id, MapTabValueEnum.VEHICLES);
    };
  }

  /**
   * @description Set the vehicle marker on the map at the current location
   * and rotate it in the direction of the next stop location on the route for the first time for each vehicle
   * and set the radar vehicle styles (the radar is the circle around the vehicle marker)
   * and set the svg navigation styles (the svg navigation is the arrow inside the vehicle marker)
   * @param { Node } vehicleContainerElement - The vehicle container element on the map
   * @param { VehicleView } vehicle - The vehicle data
   * @param { number } heading - The heading of the vehicle
   * @returns { void }
   */
  setRadarVehicleStyles(
    vehicleContainerElement: Node,
    vehicle: VehicleView,
    heading: number
  ): void {
    const radarElement = document.createElement('div');
    radarElement.style.background = this.setStatusVehicleMarker(
      vehicle.state || 'offDuty',
      true
    );
    radarElement.id = `${RADAR_VEHICLE_BASE_STRING}${vehicle.id}`;
    radarElement.style.borderRadius = '100px';
    radarElement.style.height = '0px';
    radarElement.style.left = '50%';
    radarElement.style.pointerEvents = 'none';
    radarElement.style.position = 'absolute';
    radarElement.style.top = '50%';
    radarElement.style.transform = `translate(-50%, -50%) rotate(${heading}deg)`;

    radarElement.style.width = '64px';

    const vehicleElement = document.createElement('div');
    vehicleElement.style.alignItems = 'center';
    vehicleElement.style.backgroundColor = this.setStatusVehicleMarker(
      vehicle.state || 'offDuty',
      false
    );
    vehicleElement.id = `${VEHICLE_BASE_STRING}${vehicle.id}`;
    vehicleElement.style.border = '2px solid white';
    vehicleElement.style.borderRadius = '100%';
    vehicleElement.style.boxShadow = '0px 4px 8px 3px rgba(0, 0, 0, .15)';
    vehicleElement.style.display = 'flex';
    vehicleElement.style.height = '20px';
    vehicleElement.style.justifyContent = 'center';
    vehicleElement.style.left = '50%';
    vehicleElement.style.paddingBottom = '1px';
    vehicleElement.style.position = 'absolute';
    vehicleElement.style.top = '50%';
    vehicleElement.style.transform = 'translate(-50%, -50%)';
    vehicleElement.style.width = '20px';

    const svgNavigation = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'svg'
    );
    svgNavigation.setAttribute('fill', 'none');
    svgNavigation.setAttribute('height', '12');
    svgNavigation.setAttribute('viewBox', '0 0 12 12');
    svgNavigation.setAttribute('width', '12');
    svgNavigation.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path.setAttribute(
      'd',
      'M2.82264 10.3617C2.62778 10.4452 2.4017 10.4017 2.25179 10.2518C2.1001 10.1001 2.05749 9.87064 2.14462 9.67461L5.54309 2.02804C5.71905 1.63213 6.28095 1.63213 6.45691 2.02804L9.85538 9.67461C9.94251 9.87064 9.8999 10.1001 9.74821 10.2518C9.5983 10.4017 9.37222 10.4452 9.17736 10.3617L6 9L2.82264 10.3617Z'
    );
    path.setAttribute('fill', 'white');

    // Append the svg to the vehicle element and the vehicle element to the radar element and the radar element to the vehicle container element
    svgNavigation.appendChild(path);
    vehicleElement.appendChild(svgNavigation);
    radarElement.appendChild(vehicleElement);
    vehicleContainerElement.appendChild(radarElement);
  }

  /**
   * Set the vehicle marker on the map at the current location
   * and rotate it in the direction of the next stop location on the route
   * for the first time for each vehicle
   * @param {IMapboxDirectionLocalization} currentLocation
   * @param {IMapboxDirectionLocalization} nextStopLocation
   * @param { VehicleView } vehicle
   * @returns { void }
   */
  setVehicleMarker(
    currentLocation: IMapboxDirectionLocalization,
    heading: number,
    vehicle: VehicleView
  ): void {
    const vehicleContainerElement = document.createElement('div');
    vehicleContainerElement.id = `${MARKER_VEHICLE_BASE_STRING}${vehicle.id}`;
    vehicleContainerElement.style.borderRadius = '100%';
    vehicleContainerElement.style.cursor = 'pointer';
    vehicleContainerElement.style.display = 'flex';
    vehicleContainerElement.style.height = '20px';
    vehicleContainerElement.style.width = '20px';
    vehicleContainerElement.style.zIndex = '4';

    // Add the vehicle container element to the map
    this.setRadarVehicleStyles(vehicleContainerElement, vehicle, heading);

    // Add the vehicle popover to the vehicle container element
    vehicleContainerElement.appendChild(this.setVehiclePopoverStyles(vehicle));
    this.handleEventsDisplayVehiclePopover(vehicleContainerElement, vehicle);

    if (this.mapboxMap) {
      const startMarker = new mapboxgl.Marker(vehicleContainerElement)
        .setLngLat([currentLocation.lng, currentLocation.lat])
        .addTo(this.mapboxMap);

      // push the marker to the array to be able to remove it later
      this.mapMarkersArray.push(startMarker);
      this.mapboxVehicleMarkersOnMap.push(
        `${MARKER_VEHICLE_BASE_STRING}${vehicle.id}`
      );
    }
  }

  /**
   * @description Update the vehicles position and the heading on the map
   */
  updateVehiclesOnMap() {
    // make a set interval to get the vehicles positions every 15 seconds
    const updateIntervalId = window.setInterval(() => {
      // dispatch the action to get the vehicles postions
      this.store.dispatch(
        mapAPIActions.vehiclesPositionsLoad({
          ids: this.vehiclesItems.map((vehicle) => vehicle.id.toString()),
        })
      );

      // subscribe to the vehicles positions

      this.store
        .select(selectVehiclesPositions)
        .pipe(take(1))
        .subscribe((vehiclesPositions) => {
          if (vehiclesPositions) {
            // for each vehicle position
            vehiclesPositions.forEach((vehiclePosition) => {
              const lat =
                vehiclePosition.vssSignals.find(
                  (signal) => signal.name === 'Vehicle.CurrentLocation.Latitude'
                )?.value || '0';
              const lng =
                vehiclePosition.vssSignals.find(
                  (signal) =>
                    signal.name === 'Vehicle.CurrentLocation.Longitude'
                )?.value || '0';
              const heading =
                vehiclePosition.vssSignals.find(
                  (signal) => signal.name === 'Vehicle.CurrentLocation.Heading'
                )?.value || 0;
              const currentLocation = {
                lng: parseFloat(lng),
                lat: parseFloat(lat),
              };
              if (currentLocation.lng && currentLocation.lat) {
                const vehicleMarker = this.mapMarkersArray.find(
                  (marker) =>
                    marker.getElement().id ===
                    `${MARKER_VEHICLE_BASE_STRING}${vehiclePosition.id}`
                );
                if (vehicleMarker) {
                  vehicleMarker.setLngLat([
                    currentLocation.lng,
                    currentLocation.lat,
                  ]);
                  const radarElement = document.getElementById(
                    `${RADAR_VEHICLE_BASE_STRING}${vehiclePosition.id}`
                  );
                  if (radarElement) {
                    radarElement.style.transform = `translate(-50%, -50%) rotate(${heading}deg)`;
                  }
                }

                this.moveVehicleMarker();
                this.checkVehiclesOnMap();
              }
            });
          }
        });
    }, this.TIMEOUT_DURATION);
    this.intervalIds.push(updateIntervalId);
  }

  /**
   * @description Fetch the vehicles on the map and emits the array
   */
  checkVehiclesOnMap() {
    const vehiclesOnMap: string[] = [];

    if (this.mapboxMap && this.isMapboxLoaded) {
      this.mapMarkersArray
        .filter((marker) => {
          const markerId = marker.getElement()?.id;
          return markerId && markerId.includes(MARKER_VEHICLE_BASE_STRING);
        })
        .forEach((marker) => {
          const getVehicleId = marker.getElement()?.id.split('-')[2];
          const vehicleIsInMapView =
            this.mapboxHelper.isPointInsideBoundingMapArea(
              marker.getLngLat(),
              this.mapboxMap
            );

          if (vehicleIsInMapView) {
            vehiclesOnMap.push(getVehicleId);
          }
        });
      this.vehiclesOnMap.emit(vehiclesOnMap);
    }
  }

  /**
   * @description Move the vehicle marker on the map at the current location if the vehicle is selected
   * and the tab is vehicles and the zoom level is greater than the zoom level threshold
   * and it's not moved the map from the user interaction
   * @returns { void }
   */
  moveVehicleMarker(): void {
    if (this.vehicleToDisplay && this.tab === MapTabValueEnum.VEHICLES) {
      const vehicleMarkerSelected = this.mapMarkersArray.find(
        (marker) =>
          marker.getElement()?.id ===
          `${MARKER_VEHICLE_BASE_STRING}${this.vehicleToDisplay?.id}`
      );

      if (vehicleMarkerSelected) {
        const lngLat = vehicleMarkerSelected.getLngLat();
        const options: mapboxgl.AnimationOptions & {
          center: mapboxgl.LngLat;
          zoom?: number;
        } = {
          center: lngLat,
          zoom:
            this.enableFlyZoom &&
            this.mapboxMap?.getZoom() &&
            this.mapboxMap.getZoom() + 0.5 > this.zoomInLevelMarker
              ? this.zoomInLevelMarker
              : this.mapboxMap?.getZoom() && this.mapboxMap.getZoom(),
          duration: 1000,
        };

        if (
          this.mapboxMap &&
          lngLat.lat.toFixed(2) === this.mapboxMap.getCenter().lat.toFixed(2) &&
          lngLat.lng.toFixed(2) === this.mapboxMap.getCenter().lng.toFixed(2) &&
          this.mapboxMap.getZoom() + 0.5 > this.zoomInLevelMarker
        ) {
          this.mapboxMap?.easeTo(options);
        }
        this.enableFlyZoom = false;
      }
    }
  }

  initClusterPoints() {
    if (this.mapboxMap && this.isMapboxLoaded) {
      this.removeClusterPoints();
      this.removeRoutes();
      this.currentView = 'clusters';

      // Fetching the data and adding it as a source to the map
      this.mapboxMap.addSource('circuit', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: this.circuitsItems.map((circuit) => {
            const firstStop = circuit.routes[0].stops[0];
            let status = STATUS_ASSIGNED;

            if (
              circuit.vehicleAssociated === null ||
              circuit.userAssociated === null
            ) {
              status = STATUS_UNASSIGNED;
            }

            return {
              type: 'Feature',
              properties: {
                status,
                circuitId: circuit.id,
              },
              geometry: {
                type: 'Point',
                coordinates: [firstStop.longitude, firstStop.latitude],
              },
            };
          }),
        },
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50,
        // Setting the cluster properties if the cluster is unassigned in the points
        // if the vehicle or the driver is not associated in the circuit data
        // then the cluster is unassigned even if the status is assigned
        clusterProperties: {
          isUnassigned: [
            'any',
            ['==', ['get', 'status'], STATUS_UNASSIGNED],
            'false',
          ],
        },
      });

      // Adding the cluster layer to the map
      this.mapboxMap?.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'circuit',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'case',
            ['all', ['get', 'isUnassigned']],
            UNASSIGNED_CLUSTER_COLOR,
            ASSIGNED_CLUSTER_COLOR,
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            18,
            15,
            18,
            100,
            20,
          ],
          'circle-stroke-color': [
            'case',
            ['all', ['get', 'isUnassigned']],
            UNASSIGNED_CLUSTER_COLOR,
            ASSIGNED_CLUSTER_COLOR,
          ],
          'circle-stroke-width': [
            'step',
            ['get', 'point_count'],
            18,
            15,
            18,
            100,
            20,
          ],
          'circle-stroke-opacity': 0.2,
        },
      });

      this.mapboxMap.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'circuit',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': [
            'Poppins SemiBold',
            'DIN Offc Pro Medium',
            'Arial Unicode MS Bold',
          ],
          'text-size': 20,
        },
        paint: {
          'text-color': CLUSTER_TEXT_COLOR,
        },
      });

      // For individual points, with only 1 point. So, faking a cluster.
      this.mapboxMap.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'circuit',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': [
            'match',
            ['get', 'status'],
            STATUS_UNASSIGNED,
            UNASSIGNED_CLUSTER_COLOR,
            ASSIGNED_CLUSTER_COLOR,
          ],
          'circle-radius': 18,
          'circle-stroke-width': 18,
          'circle-stroke-color': [
            'match',
            ['get', 'status'],
            STATUS_UNASSIGNED,
            UNASSIGNED_CLUSTER_COLOR,
            ASSIGNED_CLUSTER_COLOR,
          ],
          'circle-stroke-opacity': 0.2,
        },
      });

      this.mapboxMap.addLayer({
        id: 'unclustered-count',
        type: 'symbol',
        source: 'circuit',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'text-field': '1',
          'text-font': [
            'Poppins SemiBold',
            'DIN Offc Pro Medium',
            'Arial Unicode MS Bold',
          ],
          'text-size': 20,
        },
        paint: {
          'text-color': CLUSTER_TEXT_COLOR,
        },
      });

      this.mapboxMap.setLayoutProperty('clusters', 'visibility', 'visible');
      this.showLoading = false;
    }
  }

  /**
   * @description Init routes on the map
   * and set the route on the map with the stops and building markers
   * and set the circuit popover on the first stop location on the route
   * and init the events to display the circuit popover when the user hover the circuit container element on the map
   * and close it when the user leave the circuit container element
   * @returns { void }
   */
  initRoutes(): void {
    if (this.mapboxMap && this.isMapboxLoaded && this.circuitsItems.length) {
      this.removeClusterPoints();
      this.removeRoutes();
      this.currentView = 'routes';

      this.circuitsItems.forEach((circuit, index) => {
        const currCircuitId = `circuit-${circuit.id}`;
        this.mapCircuitsIds.push(currCircuitId);
        let routesDirections: Observable<IMapboxDirectionsResponse>[] = [];
        let routesTransitDirections: Observable<IMapboxDirectionsResponse>[] =
          [];

        for (let i = 0; i < circuit.routes.length; i++) {
          routesDirections = routesDirections.concat(
            this.mapboxService.getDirections(
              'driving',
              circuit.routes[i].stops.map((stop) => ({
                lat: stop.latitude,
                lng: stop.longitude,
              }))
            )
          );

          if (i < circuit.routes.length - 1) {
            const origin = {
              lat: circuit.routes[i + 1].stops[0].latitude,
              lng: circuit.routes[i + 1].stops[0].longitude,
            };
            const destination = {
              lat: circuit.routes[i].stops[circuit.routes[i].stops.length - 1]
                .latitude,
              lng: circuit.routes[i].stops[circuit.routes[i].stops.length - 1]
                .longitude,
            };

            routesTransitDirections = routesTransitDirections.concat(
              this.mapboxService.getDirections('driving', [destination, origin])
            );
          }
        }
        combineLatest(routesDirections).subscribe((response) => {
          if (response[0].routes[0])
            this.circuitBounceRoute.push({
              circuitId: circuit.id.toString(),
              coordinates: response[0].routes[0].geometry.coordinates,
            });

          this.mapboxMap?.addSource(currCircuitId, {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: response
                .filter((route) => route.routes[0] !== undefined)
                .map((route) => ({
                  type: 'Feature',
                  properties: {},
                  geometry: {
                    type: 'LineString',
                    coordinates: route.routes[0].geometry.coordinates,
                  },
                })),
            },
          });

          this.mapboxMap?.addLayer({
            id: `${currCircuitId}-stroke`,
            type: 'line',

            source: currCircuitId,
            layout: {
              'line-cap': 'round',
              'line-join': 'round',
            },
            paint: {
              'line-color':
                circuit.id === this.circuitToDisplay?.id ||
                ((this.id?.toString() === circuit.id.toString() ||
                  circuit.id.toString() === this.circuitId) &&
                  this.tab === MapTabValueEnum.CIRCUITS)
                  ? SELECTED_CIRCUIT_COLOR_STROKE
                  : UNSELECTED_CIRCUIT_COLOR_STROKE,
              'line-width': 8,
            },
          });

          this.mapboxMap?.addLayer({
            id: currCircuitId,
            type: 'line',
            source: currCircuitId,
            layout: {
              'line-cap': 'round',
              'line-join': 'round',
            },
            paint: {
              'line-color':
                circuit.id === this.circuitToDisplay?.id ||
                ((this.id?.toString() === circuit.id.toString() ||
                  circuit.id.toString() === this.circuitId) &&
                  this.tab === MapTabValueEnum.CIRCUITS)
                  ? SELECTED_CIRCUIT_COLOR_LINE
                  : UNSELECTED_CIRCUIT_COLOR_LINE,
              'line-width': 6,
            },
          });

          if (this.mapboxMap && !this.isStopsLoaded) {
            circuit.routes.forEach((route, routeIndex) => {
              const buildingElement = document.createElement('div');
              buildingElement.style.backgroundImage =
                'url(/assets/images/map/city-icon.png)';
              buildingElement.style.width = '24px';
              buildingElement.style.height = '24px';
              buildingElement.style.backgroundSize = 'cover';
              buildingElement.id = `${MARKER_BUILDING_BASE_STRING}${route.id}`;

              const circuitPinElement = document.createElement('div');
              circuitPinElement.style.cursor = 'pointer';
              circuitPinElement.style.backgroundImage =
                'url(/assets/images/map/start-pin.png)';
              circuitPinElement.style.width = '16px';
              circuitPinElement.style.height = '16px';
              circuitPinElement.style.backgroundSize = 'cover';

              if (routeIndex === 0) {
                circuitPinElement.id = `${MARKER_START_PIN_BASE_STRING}${circuit.id}`;
                // Add the circuit popover to the circuit container element
                circuitPinElement.appendChild(
                  this.setCircuitPopoverStyles(circuit)
                );
                this.handleEventsDisplayCircuitPopover(
                  circuitPinElement,
                  circuit,
                  currCircuitId
                );
              } else {
                circuitPinElement.id = `${MARKER_START_PIN_BASE_STRING}${route.id}`;
              }

              const startMarker = new mapboxgl.Marker(circuitPinElement)
                .setLngLat([route.stops[0].longitude, route.stops[0].latitude])
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                .addTo(this.mapboxMap!);

              const endMarker = new mapboxgl.Marker(buildingElement)
                .setLngLat([
                  route.stops[route.stops.length - 1].longitude,
                  route.stops[route.stops.length - 1].latitude,
                ])
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                .addTo(this.mapboxMap!);

              this.mapMarkersArray.push(startMarker);
              this.mapMarkersArray.push(endMarker);

              if (index === this.circuitsItems.length - 1) {
                this.isStopsLoaded = true;
                if (this.id && this.tab === MapTabValueEnum.CIRCUITS) {
                  setTimeout(() => {
                    if (this.mapboxMap) {
                      this.handleDisplayVehicleMarker(true);
                      this.handleDisplayStopsMarker(true);
                      this.mapboxHelper.showCircuitMap(this.mapboxMap, this.id);
                      this.mapboxHelper.mapFitBounds(
                        this.mapboxMap,
                        this.id,
                        this.circuitBounceRoute
                      );
                    }
                  }, 300);
                }
              }
            });
          }
        });

        combineLatest(routesTransitDirections).subscribe((response) => {
          this.mapboxMap?.addSource(`${currCircuitId}-transit`, {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: response.map((route) => ({
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'LineString',
                  coordinates: route.routes[0].geometry.coordinates,
                },
              })),
            },
          });

          this.mapboxMap?.addLayer({
            id: `${currCircuitId}-transit-stroke`,
            type: 'line',
            source: `${currCircuitId}-transit`,
            layout: {
              'line-cap': 'round',
              'line-join': 'round',
            },
            paint: {
              'line-color': CIRCUIT_TRANSIT_STROKE,
              'line-width': 3,
              'line-dasharray': [0, 2],
            },
          });
        });
      });

      this.showLoading = false;
    }
  }

  removeRoutes() {
    if (this.mapboxMap) {
      for (const id of this.mapCircuitsIds) {
        if (this.mapboxMap.getLayer(`${id}-stroke`)) {
          this.mapboxMap.removeLayer(`${id}-stroke`);
        }
        if (this.mapboxMap.getLayer(`${id}`)) {
          this.mapboxMap.removeLayer(`${id}`);
        }
        if (this.mapboxMap.getSource(`${id}`)) {
          this.mapboxMap.removeSource(`${id}`);
        }
        if (this.mapboxMap.getLayer(`${id}-transit-stroke`)) {
          this.mapboxMap.removeLayer(`${id}-transit-stroke`);
        }
        if (this.mapboxMap.getSource(`${id}-transit`)) {
          this.mapboxMap.removeSource(`${id}-transit`);
        }
        this.circuitBounceRoute = [];
      }
    }
  }

  removeClusterPoints() {
    if (this.mapboxMap) {
      if (this.mapboxMap.getLayer('clusters')) {
        this.mapboxMap.removeLayer('clusters');
      }
      if (this.mapboxMap.getLayer('cluster-count')) {
        this.mapboxMap.removeLayer('cluster-count');
      }
      if (this.mapboxMap.getLayer('unclustered-point')) {
        this.mapboxMap.removeLayer('unclustered-point');
      }
      if (this.mapboxMap.getLayer('unclustered-count')) {
        this.mapboxMap.removeLayer('unclustered-count');
      }
      if (this.mapboxMap.getSource('circuit')) {
        this.mapboxMap.removeSource('circuit');
      }
    }
  }

  handleSetDefaultLayer() {
    if (this.mapboxMap && !this.isLayerDefault) {
      this.mapboxMap.setStyle(this.mapboxConfig.mapDefaultStyle);

      this.isLayerDefault = true;
      this.isLayerSatellite = false;
      this.isLayerTraffic = false;
      this.isLayerBike = false;
      this.isLayerOutdoors = false;

      this.currentView = 'none';
      this.handleMapLayout();
    }
  }

  handleSetSatelliteLayer() {
    if (this.mapboxMap && !this.isLayerSatellite) {
      this.mapboxMap.setStyle(this.mapboxConfig.mapSatelliteStyle);

      this.isLayerDefault = false;
      this.isLayerSatellite = true;
      this.isLayerTraffic = false;
      this.isLayerBike = false;
      this.isLayerOutdoors = false;

      this.currentView = 'none';
      this.handleMapLayout();
    }
  }

  handleSetOutdoorsLayer() {
    if (this.mapboxMap && !this.isLayerOutdoors) {
      this.mapboxMap.setStyle(this.mapboxConfig.mapOutdoorsStyle);

      this.isLayerDefault = false;
      this.isLayerSatellite = false;
      this.isLayerTraffic = false;
      this.isLayerBike = false;
      this.isLayerOutdoors = true;

      this.currentView = 'none';
      this.handleMapLayout();
    }
  }

  handleSetTrafficLayer() {
    if (this.mapboxMap) {
      if (!this.isLayerTraffic) {
        this.removeBikeLayer();
        this.isLayerBike = false;

        this.mapboxMap.addSource('traffic', {
          type: 'vector',
          url: this.mapboxConfig.mapTrafficStyle,
        });

        this.mapboxMap.addLayer({
          id: 'traffic-layer',
          type: 'line',
          source: 'traffic',
          'source-layer': 'traffic',
          paint: {
            'line-color': [
              'case',
              ['==', 'low', ['get', 'congestion']],
              LOW_TRAFFIC_COLOR,
              ['==', 'moderate', ['get', 'congestion']],
              MODERATE_TRAFFIC_COLOR,
              ['==', 'heavy', ['get', 'congestion']],
              HEAVY_TRAFFIC_COLOR,
              ['==', 'severe', ['get', 'congestion']],
              SEVERE_TRAFFIC_COLOR,
              UNKNOWN_TRAFFIC_COLOR,
            ],
            'line-width': 2,
          },
        });

        this.isLayerTraffic = true;
      } else if (this.mapboxMap.getLayer('traffic-layer')) {
        this.removeTrafficLayer();

        this.isLayerTraffic = false;
      }
    }
  }

  removeTrafficLayer() {
    if (this.mapboxMap && this.mapboxMap.getLayer('traffic-layer')) {
      this.mapboxMap.removeLayer('traffic-layer');
      this.mapboxMap.removeSource('traffic');
    }
  }

  handleSetBikeLayer() {
    if (this.mapboxMap) {
      if (!this.isLayerBike) {
        this.removeTrafficLayer();
        this.isLayerTraffic = false;

        this.mapboxMap.addSource('bike-paths', {
          type: 'vector',
          url: this.mapboxConfig.mapBikeStyle,
        });

        this.mapboxMap.addLayer({
          id: 'bike-paths-layer',
          type: 'line',
          source: 'bike-paths',
          'source-layer': 'road',
          filter: ['==', 'bike_lane', 'yes'],
          paint: {
            'line-color': UNASSIGNED_CLUSTER_COLOR, // Customize the color of the bike paths
            'line-width': 1,
          },
        });

        this.isLayerBike = true;
      } else if (this.mapboxMap.getLayer('bike-paths-layer')) {
        this.removeBikeLayer();
        this.isLayerBike = false;
      }
    }
  }

  removeBikeLayer() {
    if (this.mapboxMap && this.mapboxMap.getLayer('bike-paths-layer')) {
      this.mapboxMap.removeLayer('bike-paths-layer');
      this.mapboxMap.removeSource('bike-paths');
    }
  }

  handleZoomIn() {
    if (this.mapboxMap) {
      this.mapboxMap.zoomIn();
    }
  }

  handleZoomOut() {
    if (this.mapboxMap) {
      this.mapboxMap.zoomOut();
    }
  }

  handleReset() {
    // Hide previous circuit selected/hovered
    this.mapboxHelper.hideCircuitMap(this.mapboxMap, this.circuitId);
    this.id = null;
    this.circuitId = null;

    this.vehicleMarkerSelected = false;
    this.vehicleMarkerSelectedChange.emit(this.vehicleMarkerSelected);
    this.circuitMarkerSelected = false;
    this.circuitMarkerSelectedChange.emit(this.circuitMarkerSelected);

    this.vehicleToDisplay = undefined;
    this.vehicleToDisplayChange.emit(this.vehicleToDisplay);
    this.circuitToDisplay = undefined;
    this.circuitToDisplayChange.emit(this.circuitToDisplay);

    this.changeRoute(null);

    if (this.mapboxMap) {
      this.handleSetDefaultLayer();
      this.mapboxMap?.flyTo({
        zoom: this.initialZoomLevel,
        center: [-73.569807, 45.503182],
        essential: true,
        duration: 1000,
      });
    }
  }

  handleResetDisableChange() {
    if (this.mapboxMap) {
      if (
        this.mapboxMap.getZoom() >= this.initialZoomLevel - 0.5 &&
        this.mapboxMap.getZoom() <= this.initialZoomLevel + 0.5
      ) {
        this.isResetDisabled = true;
      } else {
        this.isResetDisabled = false;
      }
    }
  }
}
