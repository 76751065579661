import { Injectable } from '@angular/core';
import {
  CircuitsService,
  StopsService,
  VehiclesService,
  DriversService as UsersService,
} from '@fms/ng-fms-api-client';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, exhaustMap, map, mergeMap, of } from 'rxjs';

import { mapAPIActions } from './map.action';

@Injectable()
export class MapEffects {
  readonly getStops$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mapAPIActions.stopsLoad),
      exhaustMap(() =>
        // TODO: Need to check if backend in the future will have the findAll stops method
        this.stopsService.stopsControllerFindAll().pipe(
          map((stops) => ({
            type: mapAPIActions.stopsLoaded.type,
            stopCoordinatedViewList: stops,
          })),
          catchError((error) =>
            of({
              type: mapAPIActions.stopsError.type,
              error: {
                message: 'Error loading stops',
                error,
              },
            })
          )
        )
      )
    )
  );

  readonly getCircuits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mapAPIActions.circuitsLoad),
      exhaustMap(() =>
        // In progress: Replace with the function by the backend devince find all circuits
        // TODO: implement infinite loading
        this.circuitsService
          .circuitsControllerFindAllCircuits(true, 0, 1000)
          .pipe(
            map((circuits) => ({
              type: mapAPIActions.circuitsLoaded.type,
              circuitsPanelList: circuits.items,
            })),
            catchError((error) =>
              of({
                type: mapAPIActions.circuitsError.type,
                error: {
                  message: 'Error loading circuits',
                  error,
                },
              })
            )
          )
      )
    )
  );

  readonly getVehicles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mapAPIActions.vehiclesLoad),
      exhaustMap(() =>
        this.vehiclesService
          .vehiclesControllerFindAllVehiclesWithIotDevice()
          .pipe(
            map((vehicles) => ({
              type: mapAPIActions.vehiclesLoaded.type,
              vehiclesPanelList: vehicles,
            })),
            catchError((error) =>
              of({
                type: mapAPIActions.vehiclesError.type,
                error: {
                  message: 'Error loading panel vehicles',
                  error,
                },
              })
            )
          )
      )
    )
  );

  readonly getVehiclePosition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mapAPIActions.vehiclePositionLoad),
      mergeMap(({ id }) =>
        this.vehiclesService.vehiclesControllerFindVehiclePosition(id).pipe(
          map((vehiclePosition) => ({
            type: mapAPIActions.vehiclePositionLoaded.type,
            vehiclePosition,
          })),
          catchError((error) =>
            of({
              type: mapAPIActions.vehiclePositionError.type,
              error: {
                message: 'Error loading vehicle position',
                error,
              },
            })
          )
        )
      )
    )
  );

  readonly getVehiclesPositions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mapAPIActions.vehiclesPositionsLoad),
      mergeMap(({ ids }) =>
        this.vehiclesService.vehiclesControllerGetVehiclesPositions(ids).pipe(
          map((vehiclesPositions) => ({
            type: mapAPIActions.vehiclesPositionsLoaded.type,
            vehiclesPositions,
          })),
          catchError((error) =>
            of({
              type: mapAPIActions.vehiclesPositionsError.type,
              error: {
                message: 'Error loading vehicles positions',
                error,
              },
            })
          )
        )
      )
    )
  );

  readonly getUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mapAPIActions.usersLoad),
      exhaustMap(() =>
        this.usersService.driversControllerFindAll().pipe(
          map((users) => ({
            type: mapAPIActions.usersLoaded.type,
            usersPanelList: users,
          })),
          catchError((error) =>
            of({
              type: mapAPIActions.usersError.type,
              error: {
                message: 'Error loading users',
                error,
              },
            })
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private stopsService: StopsService,
    private circuitsService: CircuitsService,
    private vehiclesService: VehiclesService,
    private usersService: UsersService
  ) {}
}
