/// <reference types="@types/googlemaps" />
import { Injectable } from '@angular/core';

import {
  IGoogleMapsArea,
  IGoogleMapsAreaStyles,
  IGoogleMapsCoordinates,
  IGoogleMapsLocation,
} from './google-maps.interface';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsHelper {
  /**
   * Manages all the waypoints of a route
   *
   * @param map - Google Maps map object
   * @param positionOrigin - Coordinates for the start/origin waypoint
   * @param positionOriginLabel - Adds a label to the origin marker
   * @param positionDestination - Coordinates for the end/destination waypoint
   * @param positionDestinationLabel - Adds a label to the destination marker
   * @param intersections - Array of all intermidate waypoints, between the origin and the destination
   * @param intersectionsLabel - Adds a label to all intermediate waypoints markers
   * @returns An array of all added marker objects
   */
  addRouteMarkers(
    map: google.maps.Map,
    positionOrigin?: IGoogleMapsCoordinates,
    positionDestination?: IGoogleMapsCoordinates,
    intersections?: IGoogleMapsCoordinates[],
    addIntersections = true,
    positionOriginLabel = 'Origin',
    positionDestinationLabel = 'Destination',
    intersectionsLabel = 'stop'
  ): google.maps.Marker[] {
    const markers: google.maps.Marker[] = [];

    if (positionOrigin) {
      markers.push(this.addMarker(map, positionOrigin, positionOriginLabel));
    }

    if (positionDestination && positionOrigin !== positionDestination) {
      markers.push(
        this.addMarker(map, positionDestination, positionDestinationLabel)
      );
    }

    if (addIntersections && intersections) {
      for (const pos of intersections) {
        markers.push(
          this.addMarker(
            map,
            {
              lat: pos.lat,
              lng: pos.lng,
            },
            intersectionsLabel
          )
        );
      }
    }

    return markers;
  }

  /**
   *
   * @param map - Google Maps map object
   * @param position - Map coordinates
   * @param label - The label
   * @returns The marker object
   */
  addMarker(
    map: google.maps.Map,
    position: IGoogleMapsCoordinates,
    label?: string
  ): google.maps.Marker {
    const defaultMarkerIcon = {
      path: 'M10.9997 14.5001C11.8667 14.5001 12.6213 14.1855 13.2366 13.5703C13.8518 12.9551 14.1663 12.2004 14.1663 11.3334C14.1663 10.4664 13.8518 9.71177 13.2366 9.09653C12.6213 8.48129 11.8667 8.16675 10.9997 8.16675C10.1327 8.16675 9.37803 8.48129 8.76279 9.09653C8.14754 9.71177 7.83301 10.4664 7.83301 11.3334C7.83301 12.2004 8.14754 12.9551 8.76279 13.5703C9.37803 14.1855 10.1327 14.5001 10.9997 14.5001ZM10.9997 26.6753C7.60452 23.7536 5.07616 21.0515 3.39713 18.5699C1.66393 16.0083 0.833008 13.6887 0.833008 11.6001C0.833008 8.38445 1.86193 5.87017 3.88866 4.00096C5.94419 2.10519 8.30766 1.16675 10.9997 1.16675C13.6917 1.16675 16.0552 2.10519 18.1107 4.00096C20.1374 5.87017 21.1663 8.38445 21.1663 11.6001C21.1663 13.6887 20.3354 16.0083 18.6022 18.5699C16.9232 21.0515 14.3948 23.7536 10.9997 26.6753Z',
      fillColor: '#333E48',
      fillOpacity: 1,
      stroke: '#1F252B',
      strokeWeight: 1,
      size: new google.maps.Size(22, 26),
      anchor: new google.maps.Point(12, 26),
    };

    const marker = new google.maps.Marker({
      position,
      map: map,
      label,
      icon: defaultMarkerIcon,
    });

    return marker;
  }

  /**
   * Generate areas and show it on a map
   *
   * @param map - Google Maps map object
   * @param areasArray - Array of areas
   * @returns An array of all added polygon objects
   */
  addAreas(
    map: google.maps.Map,
    areasArray?: IGoogleMapsArea[]
  ): google.maps.Polygon[] {
    const areas: google.maps.Polygon[] = [];

    if (areasArray) {
      for (const area of areasArray) {
        const innerAreas: IGoogleMapsCoordinates[][] =
          area.innerPaths && area.innerPaths?.length ? area.innerPaths : [];

        areas.push(
          this.generatePolygon(map, area.styles, area.paths, [...innerAreas])
        );
      }
    }

    return areas;
  }

  /**
   * Adds a polyline, or a route, to a map
   *
   * @param map - Google Maps map object
   * @param path - A polyline
   * @returns A polyline object
   */
  addPolyline(
    map: google.maps.Map,
    path: google.maps.LatLng[]
  ): google.maps.Polyline {
    const route = new google.maps.Polyline({
      path,
      strokeColor: '#FF0000',
      strokeOpacity: 1.0,
      strokeWeight: 3,
    });
    route.setMap(map);

    return route;
  }

  /**
   * Used to format time based on the return value from the Direction API
   *
   * @param inputString - Time number by the API
   * @returns A string of a formatted time
   */
  convertTime(inputString: string): string | null {
    const numericString = inputString.replace(/s$/, '');
    const numericValue = Number(numericString);

    if (isNaN(numericValue)) {
      return null;
    }

    const hours = Math.floor(numericValue / 3600);
    const minutes = Math.floor((numericValue % 3600) / 60);
    const seconds = numericValue % 60;

    const parts = [];

    if (hours > 0) {
      parts.push(`${hours}h`);
    }

    if (minutes > 0) {
      parts.push(`${minutes}m`);
    }

    if (seconds > 0) {
      parts.push(`${seconds}s`);
    }

    return parts.join(' ');
  }

  /**
   * Unset all the objects from a map
   *
   * @param objects - Array of all the Google Maps object to go through
   * @returns An empty array
   */
  deleteObjectsFromMap(
    objects:
      | google.maps.Marker[]
      | google.maps.Polygon[]
      | google.maps.Polyline[]
  ): Array<google.maps.Marker | google.maps.Polygon | google.maps.Polyline> {
    for (const object of objects) {
      object.setMap(null);
    }

    return [];
  }

  /**
   * Takes IGoogleMapsCoordinates and converts it in a IGoogleMapsLocation format
   *
   * @param coordinates - An object with coordinate
   * @returns An object with more parameters for places
   */
  formatLatLng(coordinates: IGoogleMapsCoordinates): IGoogleMapsLocation {
    const { lat, lng } = coordinates;
    return {
      location: {
        latLng: {
          latitude: lat,
          longitude: lng,
        },
      },
    };
  }

  /**
   * A helper function to help process the distance
   *
   * @param distance - The distance in meters
   * @returns A formated object to show with kilometers ou meters
   */
  formatDistance(distance: number): { label: string; value: number } {
    let value = distance;
    let label = 'meters';

    if (distance >= 1000) {
      value = distance / 1000;
      label = value === 1 ? 'kilometer' : 'kilometers';
    } else if (distance === 1) {
      label = 'meter';
    }

    return { label, value };
  }

  /**
   * Generate a polygon to be used with the map
   *
   * @remaks
   * The removeCoordinates, or inner arrays, needs to be in reverse motion from the coordinates array (clockwise vs counter clockwise)
   *
   * @param map - Google Maps map object
   * @param style - Defines the style of the polygon
   * @param coordinates - Array of all the points
   * @param removeCoordinates - Array of arrays to add inner polygons
   * @returns A Polygon object
   */
  generatePolygon(
    map: google.maps.Map,
    style: IGoogleMapsAreaStyles,
    coordinates: IGoogleMapsCoordinates[],
    removeCoordinates: IGoogleMapsCoordinates[][]
  ): google.maps.Polygon {
    const allPolygon: IGoogleMapsCoordinates[][] = [coordinates];

    // Note: the inner areas needs to be in REVERSE MOTION from the outer area
    for (const removePolygon of removeCoordinates) {
      allPolygon.push(removePolygon);
    }

    const area = new google.maps.Polygon({
      paths: allPolygon,
      ...style,
    });

    area.setMap(map);
    return area;
  }
}
