<div class="ctn">
  <div class="text-p3--semibold title">
    {{ 'SETTINGS_ROLES-AND-PERMISSIONS.ROLES.TITLE' | translate }}
  </div>

  <div class="list">
    <astus-settings-roles-card
      *ngFor="let role of rolesList"
      [id]="role.id"
      [title]="role.title"
      [description]="role.description"
      [assocUsers]="role.assocUsers"
    ></astus-settings-roles-card>
  </div>

  <button
    astus-design-button
    kind="text"
    color="primary"
    routerLink="create"
    class="add-btn"
    *ngIf="isCreateOrEdit"
  >
    <ad-icon glyph="plus"></ad-icon>
    <span class="text-p3--semibold">{{
      'SETTINGS_ROLES-AND-PERMISSIONS.ROLES.ADD' | translate
    }}</span>
  </button>
</div>
