<button
  [ngClass]="
    isOpen === undefined
      ? 'initial-state'
      : !isOpen
      ? 'is-not-opened'
      : 'is-opened'
  "
  class="button"
  (click)="handleInfoboxButtonClick()"
>
  <ad-icon
    glyph="close"
    [ngClass]="isOpen ? 'is-opened' : 'is-not-opened'"
    class="button-close"
    color="neutral-700"
  ></ad-icon>
  <ad-icon
    glyph="info-i"
    class="button-info"
    [ngClass]="!isOpen ? 'is-not-opened' : 'is-opened'"
    color="neutral-700"
  ></ad-icon>
</button>
<!-- The empty div is used to have the toggle button shadow behind the panel -->

<div
  [ngClass]="!isOpen ? 'is-not-opened' : 'is-opened'"
  class="background-button"
></div>
<div
  [ngClass]="isOpen === undefined || !isOpen ? 'is-not-opened' : 'is-opened'"
  class="box"
>
  <astus-vehicle-monitoring-card
    *ngIf="data && data.cardType === 'vehicle'"
    [id]="data.id"
    [title]="data.title || ''"
    [vehicleStatus]="data.vehicleStatus || VehicleStateType.OffDuty"
    [infoSeat]="data.textInfo || ''"
    [infoFuel]="data.textInfo2 || ''"
    [route]="data.monitoringVehicle?.route"
    [circuits]="data.monitoringVehicle?.vehicleInformation?.circuits"
    [preference]="data.monitoringVehicle?.vehicleInformation?.preference"
    [type]="data.monitoringVehicle?.vehicleInformation?.type"
    [odometer]="data.monitoringVehicle?.vehicleDetails?.odometer"
    [odometerReadTime]="
      data.monitoringVehicle?.vehicleDetails?.odometerReadTime
    "
    [vin]="data.monitoringVehicle?.vehicleDetails?.vin"
    [plaque]="data.monitoringVehicle?.vehicleDetails?.plaque"
    [model]="data.monitoringVehicle?.vehicleDetails?.model"
    [year]="data.monitoringVehicle?.vehicleDetails?.year"
    [fuel]="data.monitoringVehicle?.vehicleDetails?.fuel"
    [transporter]="data.monitoringVehicle?.vehicleDetails?.transporter"
    [homeport]="data.monitoringVehicle?.vehicleDetails?.homeport"
    [groups]="data.monitoringVehicle?.vehicleDetails?.groups"
    [serialNumber]="
      data.monitoringVehicle?.vehicleTelematicsModule?.serialNumber
    "
    [telematicType]="data.monitoringVehicle?.vehicleTelematicsModule?.type"
    [softwareVersion]="
      data.monitoringVehicle?.vehicleTelematicsModule?.softwareVersion
    "
    [lastConnection]="
      data.monitoringVehicle?.vehicleTelematicsModule?.lastConnection
    "
    [checkUp]="data.monitoringVehicle?.vehicleMaintenanceSchedule?.checkUp"
    [checkUpDate]="
      data.monitoringVehicle?.vehicleMaintenanceSchedule?.checkUpDate
    "
    [changeOil]="data.monitoringVehicle?.vehicleMaintenanceSchedule?.changeOil"
    [changeOilDate]="
      data.monitoringVehicle?.vehicleMaintenanceSchedule?.changeOilDate
    "
  ></astus-vehicle-monitoring-card>

  <astus-user-monitoring-card
    *ngIf="data && data.cardType === 'user'"
    [id]="data.id"
    [title]="data.title || ''"
    [userStatus]="data.userStatus || controlPanelUsersStatusEnum.ON_WAY"
    [infoService]="data.textInfo || ''"
    [phone]="data.monitoringUser?.phone"
    [email]="data.monitoringUser?.email"
    [circuits]="data.monitoringUser?.userInformation?.circuits"
    [preference]="data.monitoringUser?.userInformation?.preference"
    [singleJob]="data.monitoringUser?.userDetails?.singleJob"
    [expirationSingleJob]="
      data.monitoringUser?.userDetails?.expirationSingleJob
    "
    [licenseClass]="data.monitoringUser?.userDetails?.licenseClass"
    [permit]="data.monitoringUser?.userDetails?.permit"
    [expirationPermit]="data.monitoringUser?.userDetails?.expirationPermit"
    [transporter]="data.monitoringUser?.userDetails?.transporter"
    [homeport]="data.monitoringUser?.userDetails?.homeport"
    [groups]="data.monitoringUser?.userDetails?.groups"
  ></astus-user-monitoring-card>

  <astus-circuit-monitoring-card
    *ngIf="data && data.cardType === 'circuits'"
    class="monitoring-card"
    (routeSelectClickEvent)="selectRoute($event)"
    [id]="data.id"
    [title]="data.title || ''"
    [titleSmall]="data.title2 || ''"
    [subtitle]="data.subtitle || ''"
    [subtitleSmall]="data.subtitle2 || ''"
    [infoUser]="data.textInfo || ''"
    [infoRoute]="data.textInfo2 || ''"
    [userName]="data.userName || ''"
    [vehicleName]="data.vehicleName || ''"
    [routes]="data.routes || []"
  ></astus-circuit-monitoring-card>

  <astus-route-monitoring-card
    *ngIf="data && data.cardType === 'routes'"
    class="monitoring-card"
    (stopSelectClickEvent)="selectStop($event)"
    (backClickEvent)="goBackToCircuit(previousCircuitData)"
    [previousData]="previousCircuitData"
    [title]="data.title || ''"
    [titleSmall]="data.title2 || ''"
    [subtitle]="data.subtitle || ''"
    [subtitleSmall]="data.subtitle2 || ''"
    [infoUser]="data.textInfo || ''"
    [infoLocation]="data.textInfo2 || ''"
    [stops]="data.stops || []"
  ></astus-route-monitoring-card>

  <astus-stop-monitoring-card
    *ngIf="data && data.cardType === 'stops'"
    class="monitoring-card"
    (backClickEvent)="goBackToRoute(previousRouteData)"
    [previousData]="previousRouteData"
    [title]="data.title || ''"
    [titleSmall]="data.title2 || ''"
    [subtitle]="data.subtitle || ''"
    [subtitleSmall]="data.subtitle2 || ''"
    [infoSchedule]="data.textInfo || ''"
    [infoUser]="data.textInfo2 || ''"
    [stopStatusState]="data.stopStatus"
    [stopStatusDuration]="data.stopTime"
    [stopStatusTime]="data.stopDuration"
    [stopStudents]="data.stopStudents"
    [stopNotes]="data.stopNotes"
  ></astus-stop-monitoring-card>
</div>
