import { tableDriverData } from '@common/ng-design-system';
import { createReducer, on } from '@ngrx/store';

import { resourceReducerInterface } from './resource';
import { resourceActions } from './resource.action';

export const resourceFeatureKey = 'resourceFeatureKey';

export const initialState: resourceReducerInterface = {
  vehicles: {
    infobox: {
      vehicle: null,
      vehicleFormDropdowns: {
        vehicleType: [],
        fuelType: [],
        carrierType: [],
        homePortType: [],
        iotDeviceType: [],
        vehicleGroupType: [],
      },
    },
    list: [],
    loaded: false,
    error: null,
  },
  users: {
    infobox: {
      user: null,
    },
    list: [...tableDriverData],
    loaded: false,
  },
  circuits: {
    list: [],
    // TODO: implement auto pagination
    pagination: {
      page: 1,
      pageSize: 1000,
      hasNextPage: true,
      totalCount: 0,
    },
    loaded: false,
    error: null,
  },
  isInfoBoxOpen: false,
};

export const resourceReducer = createReducer<resourceReducerInterface>(
  initialState,
  on(resourceActions.vehiclesLoaded, (_state, { vehicles }) => ({
    ..._state,
    vehicles: {
      ..._state.vehicles,
      list: vehicles,
      loaded: true,
    },
  })),
  on(resourceActions.vehiclesError, (_state, { error }) => ({
    ..._state,
    vehicles: {
      ..._state.vehicles,
      loaded: false,
      error: error,
    },
  })),
  on(resourceActions.vehicleClearError, (_state) => ({
    ..._state,
    vehicles: {
      ..._state.vehicles,
      error: null,
    },
  })),
  on(
    resourceActions.findAllInfoboxVehicleLoaded,
    (_state, { vehicleFormDropdowns }) => ({
      ..._state,
      vehicles: {
        ..._state.vehicles,
        infobox: {
          ..._state.vehicles.infobox,
          vehicleFormDropdowns,
        },
      },
    })
  ),
  on(resourceActions.updateVehicleInfobox, (_state, { vehicle }) => ({
    ..._state,
    vehicles: {
      ..._state.vehicles,
      infobox: {
        ..._state.vehicles.infobox,
        vehicle,
      },
    },
  })),

  on(resourceActions.resetVehicleInfobox, (_state) => ({
    ..._state,
    vehicles: {
      ..._state.vehicles,
      infobox: {
        ..._state.vehicles.infobox,
        vehicle: null,
      },
    },
  })),
  on(resourceActions.createUser, (_state, { user }) => ({
    ..._state,
    users: {
      ..._state.users,
      list: [..._state.users.list, user],
    },
  })),

  on(resourceActions.updateUserInfobox, (_state, { user }) => ({
    ..._state,
    users: {
      ..._state.users,
      infobox: {
        user,
      },
    },
  })),

  on(resourceActions.resetUserInfobox, (_state) => ({
    ..._state,
    users: {
      ..._state.users,
      infobox: {
        user: null,
      },
    },
  })),

  on(resourceActions.updateUserInformation, (_state, { user }) => ({
    ..._state,
    users: {
      ..._state.users,
      list: _state.users.list.map((v) => (v.id === user.id ? user : v)),
      infobox: {
        user: null,
      },
    },
  })),
  on(resourceActions.disableUser, (_state, { user }) => ({
    ..._state,
    users: {
      ..._state.users,
      list: _state.users.list.map((v) =>
        v.id === user.id ? { ...v, isDisabled: true } : v
      ),
    },
  })),
  on(resourceActions.openInfoboxPanel, (_state) => ({
    ..._state,
    isInfoBoxOpen: true,
  })),
  on(resourceActions.closeInfoboxPanel, (_state) => ({
    ..._state,
    isInfoBoxOpen: false,
  })),

  // Circuit actions
  on(resourceActions.circuitsLoaded, (_state, { circuits }) => ({
    ..._state,
    circuits: {
      ..._state.circuits,
      list: [...circuits],
      loaded: true,
    },
  })),

  on(resourceActions.circuitsError, (_state, { error }) => ({
    ..._state,
    circuits: {
      ..._state.circuits,
      loaded: false,
      error,
    },
  })),

  on(resourceActions.resetCircuits, (_state) => ({
    ..._state,
    circuits: {
      ...initialState.circuits,
    },
  })),

  on(resourceActions.circuitClearError, (_state) => ({
    ..._state,
    circuits: {
      ..._state.circuits,
      error: null,
    },
  }))
);
