import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { colors } from '../../common-ng-design-system.colors';

import { AvatarIconSizingType } from './avatar-icon.enum';

@Component({
  selector: 'ad-avatar-icon',
  template: '<ng-content></ng-content>',
  styleUrls: ['./avatar-icon.component.scss'],
})
export class AvatarIconAtom implements OnInit {
  @Input()
  size: AvatarIconSizingType = 'xl';

  @Input()
  backgroundColor?: keyof typeof colors = 'primary-100';

  @Output()
  byClick = new EventEmitter<Event>();

  @HostBinding('style') style = `background-color: rgb(var(--color-${
    this.backgroundColor ? this.backgroundColor : 'primary-100'
  }));`;

  @HostBinding('class') class = `size-${this.size}`;

  @HostListener('click', ['$event']) onClick(event: Event) {
    this.byClick.emit(event);
  }

  ngOnInit(): void {
    this.style = `background-color: rgb(var(--color-${
      this.backgroundColor ? this.backgroundColor : 'primary-100'
    }));`;
    this.class = `size-${this.size}`;
  }
}
