import { Injectable } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { LaunchDarklyService } from '@common/ts-feature-flag';
import { Store } from '@ngrx/store';

import { environment } from '../../environments/environment';
import { dateFormatEnum } from '../pipes/dateformat/dateformat.enum';
import { durationEnum } from '../pipes/duration/duration.enum';
import { hoursFormatEnum } from '../pipes/hours-format/hours-format.enum';
import { tableVehicleHeaders } from '../resource/table-headers';
import { unitSystemEnum, userActions, userLanguageEnum } from '../store';
import { notificationsSettingsMock } from '../store/notificationsSettings.mock';
import { permissionsMock } from '../store/permissions.mock';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  private initializationPromise: Promise<void>;
  alreadyInitialized = false;

  constructor(
    private authService: AuthService,
    private store: Store,
    private launchDarklyService: LaunchDarklyService
  ) {
    this.initializationPromise = new Promise((resolve) => resolve());
  }

  initializeApp(): Promise<void> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      if (!this.alreadyInitialized) {
        this.alreadyInitialized = true;

        await this.authService.user$.subscribe((user) => {
          // This is a bit of a code gymnastic to get the id and fleetOwnerId
          // from the user object because the properties are in an object with a
          // hardcoded URL as a key.

          const userObj = user as User;
          // If you get an error like "Cannot convert undefined or null to object"
          // on the line below, it means that the user object is not defined.
          // So the page is probably not protected by the AuthGuard.
          const objectWithHttpKey = Object.keys(userObj).find((key) =>
            key.includes('http')
          );
          let employee = {
            id: undefined,
            fleetOwnerId: undefined,
          };

          if (objectWithHttpKey) {
            const httpPropValue = userObj[objectWithHttpKey];
            employee = {
              id: httpPropValue['id'],
              fleetOwnerId: httpPropValue['fleetOwnerId'],
            };
          }
          this.store.dispatch(
            // TODO: Change the language, unit, dateFormat and timeFormat to be dynamic
            // when the controller is ready
            userActions.saveUserData({
              user: {
                id: user?.sub ?? null,
                email: user?.email ?? null,
                familyName: user?.family_name ?? null,
                givenName: user?.given_name ?? null,
                name: user?.name ?? null,
                nickname: user?.nickname ?? null,
                picture: user?.picture ?? null,
                fleetOwnerId: employee.fleetOwnerId ?? null,
                dateFormat: dateFormatEnum['DD/MM/YYYY'],
                durationFormat: durationEnum['JJ hh:mm'],
                hoursFormat: hoursFormatEnum.TWENTY_FOUR,
                unitSystem: user?.['unitSystem'] ?? unitSystemEnum.METRIC,
                language: userLanguageEnum.FR,
                notificationsSettings:
                  user?.['notificationsSettings'] ?? notificationsSettingsMock,
                permissions: user?.['permissions'] ?? permissionsMock,
                // TODO: Remove this when the backend is ready
                resourcesVehicleColumns: [
                  ...JSON.parse(JSON.stringify(tableVehicleHeaders)),
                ].map((header) => {
                  return { dataKey: header.dataKey, selected: true };
                }),
              },
            })
          );

          const tenant = window.location.hostname.split('.').shift();

          const ld$ = this.launchDarklyService.initLaunchDarklyWithResolve({
            kind: 'user',
            key: `user-${environment.launchDarkly.clientContext}-${
              user?.sub ?? null
            }`,
            name: user?.name ?? '',
            email: user?.email ?? '',
            groups: [this.launchDarklyService.getClientContext(tenant)],
          });

          ld$.then(() => {
            resolve();
          });
        });
      } else {
        resolve();
      }
    });
  }

  getInitializationPromise(): Promise<void> {
    return this.initializationPromise;
  }
}
