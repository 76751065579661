<button class="selector-layer-button-container" (click)="isClicked.emit()">
  <div
    [style]="'background-image: url(' + imageSrc + ')'"
    class="selector-layer-button-icon-container"
    [ngClass]="{ 'is-selected': isSelected, iconExtraClass }"
  >
    <ng-content></ng-content>
  </div>
  <span class="text-caption selector-layer-button-text-value">{{
    textValue
  }}</span>
</button>
