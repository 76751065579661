import {
  Component,
  ContentChildren,
  ElementRef,
  Input,
  QueryList,
} from '@angular/core';

import { IconAtom } from '../../atoms';
import { ButtonMolecule } from '../button/button.component';

import { SnackbarActionType, SnackbarKindType } from './snackbar.enum';

@Component({
  selector: 'ad-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarMolecule {
  @Input() actionType?: SnackbarActionType;
  @Input() kind: SnackbarKindType = 'system';
  @Input() singleLine = true;

  @ContentChildren(IconAtom, { read: ElementRef })
  contentSnackbarActionIcon: QueryList<ElementRef<HTMLElement>> =
    new QueryList();

  @ContentChildren(ButtonMolecule, { read: ElementRef })
  contentButtonMolecule: QueryList<ElementRef<HTMLElement>> = new QueryList();

  @ContentChildren('message', { read: ElementRef })
  contentMessage: QueryList<ElementRef<HTMLElement>> = new QueryList();

  getMessagesContainerClasses() {
    let lineMessages = '';
    if (this.actionType === 'long') {
      lineMessages =
        this.contentMessage.length > 1 ? 'multiple-message' : 'long-action';
    }

    return ['message', lineMessages].join(' ');
  }

  getLineClasses() {
    let lineClass = this.singleLine ? 'single-line' : 'multiple-lines';

    if (this.actionType === 'simple') {
      lineClass += this.contentSnackbarActionIcon.length
        ? '--action--icon'
        : '--action';
    } else if (this.actionType === 'long') {
      lineClass += '--long-action';
    } else if (this.contentSnackbarActionIcon.length > 0) {
      lineClass += this.contentButtonMolecule.length
        ? '--multiple-actions mixed'
        : '--multiple-actions';
    } else {
      lineClass += '--no-action';
    }

    return lineClass;
  }

  getActionClasses() {
    return this.contentSnackbarActionIcon.length &&
      this.contentButtonMolecule.length === 0
      ? 'action-group-container'
      : '';
  }
}
