import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  Output,
} from '@angular/core';

import { colors } from '../../common-ng-design-system.colors';

import { AvatarTextSizingType } from './avatar-text.enum';

@Component({
  selector: 'ad-avatar-text',
  template: '{{ textToShow }}',
  styleUrls: ['./avatar-text.component.scss'],
})
export class AvatarTextAtom implements OnChanges {
  @Input({ required: true })
  size: AvatarTextSizingType = 'xl';

  @Input()
  backgroundColor?: keyof typeof colors = 'primary-100';

  @Input({ required: true })
  text?: string;
  textToShow = this.text;

  @Input()
  textColor?: keyof typeof colors = 'shades-0';

  @Input()
  charToShow?: number = 2;

  @Output()
  byClick = new EventEmitter<Event>();

  @HostBinding('style') style = `background-color: rgb(var(--color-${
    this.backgroundColor ? this.backgroundColor : 'primary-100'
  })); color: rgb(var(--color-${
    this.textColor ? this.textColor : 'shades-0'
  }));`;

  @HostBinding('class') class = `size-${this.size} text-h6`;

  @HostListener('click', ['$event']) onClick(event: Event) {
    this.byClick.emit(event);
  }

  ngOnChanges() {
    this.charToShow = this.charToShow === undefined ? 2 : this.charToShow;

    if (this.text && this.text.length !== 0) {
      this.textToShow = this.text.substring(0, this.charToShow);
    }

    let longerText = false;
    if (
      this.charToShow &&
      this.charToShow > 2 &&
      this.textToShow &&
      this.textToShow.length > 2
    ) {
      longerText = true;
    }

    const textClass = {
      xs: longerText ? 'text-finePrint' : 'text-footer',
      s: longerText ? 'text-footer' : 'text-p2',
      m: longerText ? 'text-caption' : 'text-subheader',
      l: longerText ? 'text-p2' : 'text-subheader',
      xl: 'text-h6',
    };

    this.style = `background-color: rgb(var(--color-${
      this.backgroundColor ? this.backgroundColor : 'primary-100'
    }));
    color: rgb(var(--color-${this.textColor ? this.textColor : 'shades-0'}));`;
    this.class = `size-${this.size} ${textClass[this.size]}`;
  }
}
