<astus-settings-button-layout
  (saveEmitter)="saveForm()"
  (cancelEmitter)="resetForm()"
  [processState]="processState"
  (resetProcessState)="resetForm()"
  [enableCancel]="form.invalid"
  [disabled]="
    form.invalid ||
    (!formChanged && form.pristine && processState === buttonEnums.READY)
  "
>
  <div class="groups-container">
    <span class="header-section">
      <h2 class="text-p3--semibold">
        {{ 'SETTINGS_GROUPS.TITLE' | translate }}
      </h2>
      <p class="text-p3">
        {{ activeGroups.length }} {{ 'SETTINGS_GROUPS.ACTIVE' | translate }}
      </p>
    </span>

    <form
      cdkDropList
      (cdkDropListDropped)="drop($event)"
      class="draggable-container"
      [formGroup]="form"
    >
      <ng-container formArrayName="lockedGroups">
        <ng-container
          *ngFor="let group of lockedFormControls.controls; let i = index"
        >
          <ng-container [formGroupName]="i">
            <div class="draggable-item" cdkDrag [cdkDragDisabled]="true">
              <ad-switch
                *ngIf="group.value.locked"
                [disabled]="true"
                iconGlyph="lock"
                [checked]="group.value.active"
                size="sm"
              ></ad-switch>

              <ad-input
                class="draggable-input"
                formControlName="group"
                ngClass="is-disabled"
              ></ad-input>

              <p class="draggable-text">
                {{ 'SETTINGS_GROUPS.ASSIGNED' | translate }}
              </p>

              <div class="draggable-input">
                <ad-dropdown
                  [disabled]="true"
                  [placeholder]="createAssigneeText(i, true)"
                >
                  <div class="assignees-dropdown-container">
                    <ad-checkbox
                      [text]="'SETTINGS_GROUPS.GROUPS.CIRCUITS' | translate"
                      [selected]="
                        group.value.assignees.includes(
                          settingsGroupAssignEnum.CIRCUITS
                        )
                      "
                      (checkboxEvent)="
                        assigneeChanged(
                          i,
                          $event,
                          settingsGroupAssignEnum.CIRCUITS
                        )
                      "
                    ></ad-checkbox>
                    <ad-checkbox
                      [text]="'SETTINGS_GROUPS.GROUPS.VEHICLES' | translate"
                      [selected]="
                        group.value.assignees.includes(
                          settingsGroupAssignEnum.VEHICLES
                        )
                      "
                      (checkboxEvent)="
                        assigneeChanged(
                          i,
                          $event,
                          settingsGroupAssignEnum.VEHICLES
                        )
                      "
                    ></ad-checkbox>
                    <ad-checkbox
                      [text]="'SETTINGS_GROUPS.GROUPS.USERS' | translate"
                      [selected]="
                        group.value.assignees.includes(
                          settingsGroupAssignEnum.USERS
                        )
                      "
                      (checkboxEvent)="
                        assigneeChanged(
                          i,
                          $event,
                          settingsGroupAssignEnum.USERS
                        )
                      "
                    ></ad-checkbox>
                    <ad-checkbox
                      [text]="'SETTINGS_GROUPS.GROUPS.EQUIPMENTS' | translate"
                      [selected]="
                        group.value.assignees.includes(
                          settingsGroupAssignEnum.EQUIPMENTS
                        )
                      "
                      (checkboxEvent)="
                        assigneeChanged(
                          i,
                          $event,
                          settingsGroupAssignEnum.EQUIPMENTS
                        )
                      "
                    ></ad-checkbox>
                  </div>
                </ad-dropdown>
              </div>
              <p class="draggable-text">
                {{ 'SETTINGS_GROUPS.SHARED' | translate }}
              </p>
              <ad-dropdown
                [items]="dropdownYesNo"
                [value]="group.value.sharable"
                formControlName="sharable"
              ></ad-dropdown>

              <ad-icon
                class="drag-handle-disabled"
                glyph="drag-handle"
              ></ad-icon>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </form>

    <form
      cdkDropList
      (cdkDropListDropped)="drop($event)"
      class="draggable-container"
      [formGroup]="form"
    >
      <ng-container formArrayName="groups">
        <ng-container
          *ngFor="let group of formControls.controls; let i = index"
        >
          <ng-container [formGroupName]="i">
            <div
              class="draggable-item"
              cdkDrag
              [cdkDragDisabled]="
                (!group.value.active && !group.value.locked) || group.disabled
              "
            >
              <ad-switch
                *ngIf="group.value.locked"
                [disabled]="true"
                iconGlyph="lock"
                [checked]="group.value.active"
                size="sm"
              ></ad-switch>

              <ad-switch
                *ngIf="!group?.value.locked"
                [disabled]="
                  processState === buttonEnums.PROCESSING ||
                  processState === buttonEnums.SUCCESS
                    ? true
                    : false
                "
                [checked]="group?.value.active"
                size="sm"
                (checkedChange)="activeToggle(i, $event)"
              ></ad-switch>
              <ad-input
                class="draggable-input"
                placeholder="{{ 'SETTINGS_GROUPS.GROUP_NAME' | translate }}"
                formControlName="group"
                [errors]="group.get('group')?.errors!"
              ></ad-input>

              <p class="draggable-text">
                {{ 'SETTINGS_GROUPS.ASSIGNED' | translate }}
              </p>

              <div class="draggable-input">
                <ad-dropdown
                  [disabled]="
                    group.value.locked ||
                    processState === buttonEnums.PROCESSING
                  "
                  [placeholder]="createAssigneeText(i)"
                >
                  <div class="assignees-dropdown-container">
                    <ad-checkbox
                      *ngIf="resourcesCircuitFlagValue$ | async"
                      [text]="'SETTINGS_GROUPS.GROUPS.CIRCUITS' | translate"
                      [selected]="
                        group.value.assignees.includes(
                          settingsGroupAssignEnum.CIRCUITS
                        )
                      "
                      [disabled]="
                        group.value.assignees.length === 2 &&
                        group.value.assignees.includes(
                          settingsGroupAssignEnum.CIRCUITS
                        )
                      "
                      (checkboxEvent)="
                        assigneeChanged(
                          i,
                          $event,
                          settingsGroupAssignEnum.CIRCUITS
                        )
                      "
                    ></ad-checkbox>
                    <ad-checkbox
                      *ngIf="resourcesVehiclesFlagValue$ | async"
                      [text]="'SETTINGS_GROUPS.GROUPS.VEHICLES' | translate"
                      [selected]="
                        group.value.assignees.includes(
                          settingsGroupAssignEnum.VEHICLES
                        )
                      "
                      [disabled]="
                        group.value.assignees.length === 2 &&
                        group.value.assignees.includes(
                          settingsGroupAssignEnum.VEHICLES
                        )
                      "
                      (checkboxEvent)="
                        assigneeChanged(
                          i,
                          $event,
                          settingsGroupAssignEnum.VEHICLES
                        )
                      "
                    ></ad-checkbox>
                    <ad-checkbox
                      *ngIf="resourcesUsersFlagValue$ | async"
                      [text]="'SETTINGS_GROUPS.GROUPS.USERS' | translate"
                      [selected]="
                        group.value.assignees.includes(
                          settingsGroupAssignEnum.USERS
                        )
                      "
                      [disabled]="
                        group.value.assignees.length === 2 &&
                        group.value.assignees.includes(
                          settingsGroupAssignEnum.USERS
                        )
                      "
                      (checkboxEvent)="
                        assigneeChanged(
                          i,
                          $event,
                          settingsGroupAssignEnum.USERS
                        )
                      "
                    ></ad-checkbox>
                    <ad-checkbox
                      *ngIf="resourcesEquipmentFlagValue$ | async"
                      [text]="'SETTINGS_GROUPS.GROUPS.EQUIPMENTS' | translate"
                      [selected]="
                        group.value.assignees.includes(
                          settingsGroupAssignEnum.EQUIPMENTS
                        )
                      "
                      [disabled]="
                        group.value.assignees.length === 2 &&
                        group.value.assignees.includes(
                          settingsGroupAssignEnum.EQUIPMENTS
                        )
                      "
                      (checkboxEvent)="
                        assigneeChanged(
                          i,
                          $event,
                          settingsGroupAssignEnum.EQUIPMENTS
                        )
                      "
                    ></ad-checkbox>
                  </div>
                </ad-dropdown>
              </div>
              <p class="draggable-text">
                {{ 'SETTINGS_GROUPS.SHARED' | translate }}
              </p>
              <ad-dropdown
                [items]="dropdownYesNo"
                [value]="group.value.sharable"
                formControlName="sharable"
              ></ad-dropdown>

              <button
                type="button"
                class="drag-handle"
                [disabled]="
                  group.value.locked || processState === buttonEnums.PROCESSING
                "
              >
                <ad-icon
                  *ngIf="group.value.active || group.value.locked"
                  cdkDragHandle
                  glyph="drag-handle"
                ></ad-icon>
                <ad-icon
                  class="remove-icon"
                  (click)="
                    processState === buttonEnums.PROCESSING ||
                    processState === buttonEnums.SUCCESS
                      ? null
                      : openRemoveGroupModal(i, group.value.group)
                  "
                  *ngIf="!group.value.active && !group.value.locked"
                  glyph="trash"
                ></ad-icon>
              </button>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </form>
    <span class="header-section" #scrollTarget>
      <button
        [disabled]="
          processState === buttonEnums.PROCESSING ||
          processState === buttonEnums.SUCCESS
        "
        (click)="addGroup()"
        astus-design-button
        kind="text"
        size="sm"
      >
        + {{ 'SETTINGS_GROUPS.ADD' | translate }}
      </button>
    </span>
  </div>
</astus-settings-button-layout>

<ad-modal
  [isOpen]="modalHandler.open"
  [closeOnOutsideClick]="false"
  (outsideModalClick)="closeRemoveGroupModal()"
>
  <div class="modal-container" middle>
    <ad-icon glyph="alert" color="warning-400" class="modal-icon"></ad-icon>
    <div class="modal-text">
      <p class="modal-title text-p1--semibold">
        {{ 'SETTINGS_GROUPS.MODAL.TITLE' | translate }}
      </p>

      <p class="modal-description text-p3">
        {{ 'SETTINGS_GROUPS.MODAL.SUBTITLE_START' | translate }} 7
        {{ 'SETTINGS_GROUPS.MODAL.SUBTITLE_END' | translate }}
      </p>
    </div>

    <div class="modal-bottom">
      <button
        astus-design-button
        class="cancel-button"
        kind="outlined"
        size="sm"
        (click)="closeRemoveGroupModal()"
      >
        {{ 'SETTINGS_GROUPS.MODAL.CANCEL' | translate }}
      </button>
      <button
        astus-design-button
        class="delete-button"
        kind="filled"
        size="sm"
        (click)="removeGroup()"
      >
        {{ 'SETTINGS_GROUPS.MODAL.DELETE' | translate }}
      </button>
    </div>
  </div>
</ad-modal>
