import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  /**
   * @description
   * Format the time to display it in the card
   * @param {string} time
   * @returns {string} the formatted time
   * @example
   * formatTime('08:00:00') // 08:00
   * formatTime('14:00:00') // 14:00
   * formatTime('00:00:00') // 00:00
   * formatTime('12:00:00') // 12:00
   */
  formatTime(time: string): string {
    return `${time.split(':')[0]}:${time.split(':')[1]}`;
  }

  /**
   * @description
   * Convert minutes to HH:MM format
   * @param {number} minutes
   * @returns {string} the formatted time
   * @example
   * minutesToHHMM(60) // 1h
   * minutesToHHMM(120) // 2h
   * minutesToHHMM(30) // 30min
   * minutesToHHMM(0) // 0min
   */
  minutesToHHMM(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const hoursString = hours.toString();
    const minutesString = remainingMinutes.toString().padStart(2, '0');

    if (minutesString === '00') {
      return `${hoursString}h`;
    }

    const hoursToDisplay =
      remainingMinutes < 60 && hours < 1
        ? `${minutesString}min`
        : minutesString === '00'
        ? `${hoursString}h`
        : `(${hoursString}h${minutesString})`;
    return hoursToDisplay;
  }

  /**
   * @description
   * Convert 24hr time to AM/PM format
   * @param {string} time24hr
   * @returns {string} the formatted time
   * @example
   * convertToAMPM('08:00') // 8:00 AM
   * convertToAMPM('14:00') // 2:00 PM
   * convertToAMPM('00:00') // 12:00 AM
   * convertToAMPM('12:00') // 12:00 PM
   * convertToAMPM('23:59') // 11:59 PM
   */
  convertToAMPM(time24hr: string): string {
    const [hours, minutes] = time24hr.split(':').map(Number);
    const isAM = hours < 12;
    const period = isAM ? 'am' : 'pm';
    const formattedHours = isAM ? hours : hours - 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}${period}`;
  }

  /**
   * @description
   * Get the difference between two dates in minutes
   * @param {string} startingTime
   * @param {string} endingTime
   * @returns {number} the difference in minutes
   * @example
   * getMinutesDifference('08:00:00', '08:30:00') // 30
   * getMinutesDifference('08:00:00', '09:00:00') // 60
   * getMinutesDifference('08:00:00', '08:00:00') // 0
   * getMinutesDifference('08:00:00', '07:00:00') // 60
   */
  getMinutesDifference(startingTime: string, endingTime: string): string {
    // Create Luxon DateTime objects from the times
    const dateTime1 = DateTime.fromFormat(startingTime, 'HH:mm:ss');
    const dateTime2 = DateTime.fromFormat(endingTime, 'HH:mm:ss');
    let diffInMinutes = 0;

    // Calculate the difference in minutes
    if (dateTime1 && dateTime2) {
      diffInMinutes = Math.abs(dateTime2.diff(dateTime1, 'minutes').minutes);
    }

    return this.minutesToHHMM(diffInMinutes);
  }

  /**
   * @description
   * Format the date to a UTC valid format
   * This function is provisory until the API returns the date in UTC
   * @param {string} date
   * @returns {string} the formatted date in UTC
   */
  provisoryFormatDate(date: string): string {
    // Split the date and time components
    const [dateComponent, timeComponent] = date.split(', ');

    // Parse the date component
    const [month, day, year] = dateComponent.split('/');
    const parsedMonth = parseInt(month);
    const parsedDay = parseInt(day);
    const parsedYear = parseInt(year);

    // Parse the time component
    const [hour, minutes, seconds] = timeComponent.split(':');
    let parsedHour = parseInt(hour);
    const parsedMinutes = parseInt(minutes);
    const parsedSeconds = parseInt(seconds);

    // Adjust hour for AM/PM indicator
    if (timeComponent.includes('p.m.')) {
      parsedHour += 12;
    }

    // Create a Date object
    const validDate = new Date(
      parsedYear,
      parsedMonth - 1,
      parsedDay,
      parsedHour,
      parsedMinutes,
      parsedSeconds
    ).toString();

    return validDate;
  }
}
