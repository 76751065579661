<ng-content></ng-content>
<div
  [class]="'tooltip-container'"
  [ngClass]="{ 'tooltip-container--interactive': haveInteraction }"
>
  <div [class]="'tooltip-content text-caption--semibold'">
    <ng-content select="[icon]"></ng-content>
    <div *ngIf="text">
      {{ text }}
    </div>
  </div>
</div>
