import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'astus-licence-agreement',
  templateUrl: './license-agreement.component.html',
  styleUrls: ['./license-agreement.component.scss'],
})
export class LicenseAgreementComponent {
  licenseAgreementMarkdown = 'assets/markdown/fr/license-agreement.md';
  constructor(private translate: TranslateService) {
    this.licenseAgreementMarkdown = `assets/markdown/${this.translate.currentLang}/license-agreement.md`;
  }
}
