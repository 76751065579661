<div class="header-control-container">
  <div class="tabs" *ngIf="showTabs">
    <button
      *ngIf="circuitsFlag$ | async"
      type="button"
      class="tabs-button"
      [ngClass]="{ 'tabs-button--active': tab === tabValue.CIRCUITS }"
      (click)="tabClick(tabValue.CIRCUITS)"
    >
      {{ 'CONTROL_PANEL.TAB.CIRCUIT' | translate }}
    </button>
    <button
      *ngIf="vehiclesFlag$ | async"
      type="button"
      class="tabs-button"
      [ngClass]="{ 'tabs-button--active': tab === tabValue.VEHICLES }"
      (click)="tabClick(tabValue.VEHICLES)"
    >
      {{ 'CONTROL_PANEL.TAB.VEHICLE' | translate }}
    </button>
    <button
      *ngIf="chauffeursFlag$ | async"
      type="button"
      class="tabs-button"
      [ngClass]="{ 'tabs-button--active': tab === tabValue.USERS }"
      (click)="tabClick(tabValue.USERS)"
    >
      {{ 'CONTROL_PANEL.TAB.DRIVER' | translate }}
    </button>
  </div>
  <div class="search-icon-container">
    <ad-search
      [ngClass]="{ 'is-loading': !this.searchBarEnabled }"
      [placeholder]="'CARDS.SEARCH_INPUT.PLACEHOLDER' | translate"
      [(value)]="searchValue"
      [emptySearchTitle]="'CONTROL_PANEL.SEARCH_BAR.INVALID_SEARCH' | translate"
      [emptySearchFirstText]="
        'CONTROL_PANEL.SEARCH_BAR.REQUEST_FOR' | translate
      "
      [emptySearchSecondText]="
        'CONTROL_PANEL.SEARCH_BAR.IS_INVALID_TRY_AGAIN' | translate
      "
      trailingIcon="close"
      [(searchHistory)]="searchHistory"
      [searchResult]="searchResult"
      (searchSelectionClick)="handleSearchValueChange($event, false)"
      (valueChange)="handleSearchValueChange($event, true)"
      (searchResetClick)="handleSearchValueChange($event, false, true)"
      [disabledEnterEvent]="true"
      size="S"
    >
    </ad-search>
    <astus-vehicle-sort-filter
      *ngIf="tab === tabValue.VEHICLES && (this.vehiclesFlag$ | async)"
      (valuesEmitter)="handleVehiclesSortFilterValues($event)"
    ></astus-vehicle-sort-filter>
  </div>
</div>
<div
  class="map-list-container"
  *ngIf="tab === tabValue.CIRCUITS && (this.circuitsFlag$ | async)"
>
  <astus-map-list
    [mapboxMap]="mapboxMap"
    [isLoading]="!circuitsLoaded"
    [(cardSelected)]="cardSelection"
    [circuits]="circuits"
    (cardClick)="handleCardClick($event, tabValue.CIRCUITS)"
    (cardAssignClick)="handleAssignClick($event, tabValue.CIRCUITS)"
    [hideAssignButton]="!showAssociation"
  ></astus-map-list>
</div>

<div
  class="map-list-container"
  *ngIf="tab === tabValue.VEHICLES && (this.vehiclesFlag$ | async)"
>
  <astus-map-list
    [isLoading]="!vehiclesLoaded"
    [(cardSelected)]="cardSelection"
    [vehicles]="vehiclesFiltered"
    (cardClick)="handleCardClick($event, tabValue.VEHICLES)"
    (cardAssignClick)="handleAssignClick($event, tabValue.VEHICLES)"
    [vehiclesOnMap]="vehiclesOnMap"
    [hideAssignButton]="!showAssociation"
  ></astus-map-list>
</div>

<div
  class="map-list-container"
  *ngIf="tab === tabValue.USERS && (this.chauffeursFlag$ | async)"
>
  <astus-map-list
    [isLoading]="!usersLoaded"
    [(cardSelected)]="cardSelection"
    [users]="users"
    (cardClick)="handleCardClick($event, tabValue.USERS)"
    (cardAssignClick)="handleAssignClick($event, tabValue.USERS)"
    [hideAssignButton]="!showAssociation"
  ></astus-map-list>
</div>

<astus-map-info-panel
  [ngClass]="{ 'is-opened': isOpen }"
  *ngIf="superVisionModeSelected === 'map'"
  #infoPanel
  [(isOpen)]="isOpen"
  [data]="infoPanelData"
  [category]="infoPanelCategory"
  [previousCircuitData]="previousCircuitData"
  [previousRouteData]="previousRouteData"
></astus-map-info-panel>

<astus-map-association-modal
  *ngIf="showAssociation"
  [(isShown)]="isAssociationModalShown"
  [(data)]="associationModalData"
  (dataChange)="handleAssociationModalDataChange($event)"
  (isShownChange)="closeAssociationModal()"
></astus-map-association-modal>
