/* eslint-disable @nx/enforce-module-boundaries */
import { CdkDrag } from '@angular/cdk/drag-drop';

import { DatePipe } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpBackend,
  HttpClientModule,
} from '@angular/common/http';

import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { CommonNgDesignSystemModule } from '@common/ng-design-system';
import { LaunchDarklyModule } from '@common/ts-feature-flag';
import { HEREModule } from '@fms/maps/ng-here';
import { ApiModule, BASE_PATH } from '@fms/ng-fms-api-client';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

import languaguesImport from '../assets/languages-import';
import { environment } from '../environments/environment';

import { AlertSystemComponent } from './alert-system/alert-system.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DateAndTimeFieldsComponent } from './components/date-and-time-fields/date-and-time-fields.component';
import { DrawerComponent } from './components/drawer/drawer.component';
import { NotFoundComponent } from './components/errors/not-found/not-found.component';
import { HeaderComponent } from './components/header/header.component';
import { ResourcesHeaderComponent } from './components/header/resources-header/resources-header.component';
import { SettingsHeaderComponent } from './components/header/settings-header/settings-header.component';
import { SupervisionHeaderComponent } from './components/header/supervision-header/supervision-header.component';
import { TimelineButtonComponent } from './components/header/supervision-header/timeline-button/timeline-button.component';
import { TermsConditionsHeaderComponent } from './components/header/terms-and-conditions-header/terms-conditions-header.component';
import { HourPickerComponent } from './components/hour-picker/hour-picker.component';
import { HourPickerMobileComponent } from './components/hour-picker-mobile/hour-picker-mobile.component';
import { InfoboxDrawerComponent } from './components/infobox-drawer/infobox-drawer.component';
import { AssistancePageComponent } from './components/menu/assistance-page/assistance-page.component';
import { MenuNavbarComponent } from './components/menu/menu-navbar/menu-navbar.component';
import { MenuOverlayComponent } from './components/menu/menu-overlay/menu-overlay.component';
import { MenuComponent } from './components/menu/menu.component';
import { NotificationCenterComponent } from './components/menu/notification-center/notification-center.component';
import { PageLoaderComponent } from './components/pageLoader/page-loader.component';

import { SearchInputComponent } from './components/search-input/search-input.component';
import { TermsConditionsModule } from './components/terms-conditions/terms-conditions.module';
import { CustomRouteReuseStrategy } from './custom-route-reuse-strategy';
import { EnvironmentGuard } from './guards/environment.guard';
import { LaunchDarklyGuard } from './guards/launch-darkly.guard';
import { PermissionsGuard } from './guards/permissions.guard';

import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { MapEffects } from './map/store/map.effect';
import { mapFeatureReducer, mapFeatureKey } from './map/store/map.reducer';
import { SupervisionModule } from './map/supervision.module';
import { AlertSnackbarComponent } from './notifications/alert-snackbar/alert-snackbar.component';
import { DateFormatPipe } from './pipes/dateformat/dateformat.pipe';
import { DistancePipe } from './pipes/distance/distance.pipe';
import { DurationPipe } from './pipes/duration/duration.pipe';
import { HoursFormatPipe } from './pipes/hours-format/hours-format.pipe';
import { TruncatePipe } from './pipes/truncate-text/truncate-text.pipe';
import { ReportsComponent } from './reports/reports.component';
import { reportFeatureKey, reportReducer } from './reports/store';
import { InfoboxResourceComponent } from './resource/infobox-resource/infobox-resource.component';
import { UserFormComponent } from './resource/infobox-resource/user-form/user-form.component';
import { VehicleFormComponent } from './resource/infobox-resource/vehicle-form/vehicle-form.component';
import { ResourceGroupInputComponent } from './resource/resource-group-input/resource-group-input.component';
import { ResourceRolePermissionModule } from './resource/resource-role-permission-input/resource-role-permission.module';
import { ResourceComponent } from './resource/resource.component';
import { resourceFeatureKey, resourceReducer } from './resource/store';
import { ResourceEffects } from './resource/store/resource.effect';
import { uploadFeatureKey, uploadReducer } from './resource/upload/store';
import { UploadOptionsDrawerComponent } from './resource/upload/upload-options-drawer/upload-options-drawer.component';
import { UploadComponent } from './resource/upload/upload.component';
import { VehicleListColumnsComponent } from './resource/vehicle-list-columns/vehicle-list-columns.component';
import { AppInitializerService } from './services/appInitializer.service';
import { FaviconService } from './services/favicon.service';
import { LocaleService } from './services/locale.service';
import {
  settingsFeatureKey,
  settingsFeatureReducer,
} from './settings/store/settings.reducer';
import { globalReducer, globalStateKey } from './store';
import { GlobalEffects } from './store/global.effect';
import { TestContentComponent } from './test-content/test-content.component';

export function HttpLoaderFactory(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, languaguesImport);
}

export function initializeApp(appInitializerService: AppInitializerService) {
  return () => appInitializerService.getInitializationPromise();
}

@NgModule({
  declarations: [
    AppComponent,
    TestContentComponent,
    PageLoaderComponent,
    NotFoundComponent,
    ResourceComponent,
    SupervisionHeaderComponent,
    ReportsComponent,
    MenuComponent,
    MenuOverlayComponent,
    MenuNavbarComponent,
    UploadComponent,
    AssistancePageComponent,
    AlertSystemComponent,
    TermsConditionsHeaderComponent,
    HeaderComponent,
    NotificationCenterComponent,
    VehicleFormComponent,
    InfoboxResourceComponent,
    ResourceGroupInputComponent,
    AlertSnackbarComponent,
    TruncatePipe,
    UserFormComponent,
    SettingsHeaderComponent,
    ResourcesHeaderComponent,
    SearchInputComponent,
    DateAndTimeFieldsComponent,
    UploadOptionsDrawerComponent,
    InfoboxDrawerComponent,
    VehicleListColumnsComponent,
    HourPickerMobileComponent,
    DrawerComponent,
    HourPickerComponent,
    TimelineButtonComponent,
    DateAndTimeFieldsComponent,
  ],
  imports: [
    ResourceRolePermissionModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TermsConditionsModule,
    AppRoutingModule,
    AuthModule.forRoot({
      ...environment.auth0,
      httpInterceptor: {
        allowedList: [`${environment.api.serverUrl}/*`],
      },
    }),
    ApiModule,
    CommonNgDesignSystemModule,
    HEREModule.forRoot(environment.here),
    SupervisionModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    StoreModule.forRoot({
      [mapFeatureKey]: mapFeatureReducer,
      [globalStateKey]: globalReducer,
      [uploadFeatureKey]: uploadReducer,
      [settingsFeatureKey]: settingsFeatureReducer,
      [resourceFeatureKey]: resourceReducer,
      [reportFeatureKey]: reportReducer,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      trace: true,
    }),
    EffectsModule.forRoot([GlobalEffects, MapEffects, ResourceEffects]),
    LaunchDarklyModule.forRoot(environment.launchDarkly),
    ReactiveFormsModule,
    DatePipe,
    DateFormatPipe,
    DistancePipe,
    HoursFormatPipe,
    DurationPipe,
    CdkDrag,
  ],
  providers: [
    {
      provide: BASE_PATH,
      useValue: environment.api.serverUrl,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    AppInitializerService,
    {
      provide: 'APP_INITIALIZER',
      useFactory: initializeApp,
      deps: [AppInitializerService],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      deps: [LocaleService],
      useFactory: (localeService: LocaleService) => localeService.currentLocale,
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
    LaunchDarklyGuard,
    PermissionsGuard,
    EnvironmentGuard,
    FaviconService, // TODO: Implement a service to change the favicon in the future
  ],
  bootstrap: [AppComponent],
  exports: [AssistancePageComponent, NotificationCenterComponent],
})
export class AppModule {}
