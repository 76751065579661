import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { CONFIG } from './launch-darkly.constants';
import { LaunchDarklyConfigInterface } from './launch-darkly.interface';
import { LaunchDarklyService } from './launch-darkly.service';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [],
  exports: [],
})
export class LaunchDarklyModule {
  static forRoot(
    config: LaunchDarklyConfigInterface
  ): ModuleWithProviders<LaunchDarklyModule> {
    return {
      ngModule: LaunchDarklyModule,
      providers: [{ provide: CONFIG, useValue: config }, LaunchDarklyService],
    };
  }
}
