import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import classNames from 'classnames';

import { popoverOptionType } from './popover.molecule.d';

@Component({
  selector: 'ad-popover',
  templateUrl: './popover.molecule.html',
  styleUrls: ['./popover.molecule.scss'],
})
export class PopoverMolecule {
  @Input() options: Array<popoverOptionType> = [];

  @Input()
  position: 'top' | 'bottom' | 'left' | 'right' = 'top';

  @Input()
  class = '';

  @Input()
  display = false;

  @Output() selectOption = new EventEmitter<popoverOptionType>();

  @Output() displayChange = new EventEmitter<boolean>();

  /**
   * Returns a class string for the content element.
   * @return {string} The class string.
   */
  public get contentClasses(): string {
    return classNames('flex justify-center');
  }

  /**
   * Returns a class string for the popover element container.
   * @return {string} The class string.
   */
  public get containerClasses(): string {
    return classNames(
      'popover-container button-container',
      this.class,
      this.position === 'top' && 'button-top',
      this.position === 'bottom' && 'button-bottom',
      this.position === 'right' && 'button-right',
      this.position === 'left' && 'button-left',
      !this.display && 'button-hide'
    );
  }

  /**
   * @description
   * Click select item to select the option and emit the event.
   * @param {Event} e
   * @param {popoverOptionType} option
   */
  clickSelectItem(e: Event, option: popoverOptionType) {
    e.preventDefault();
    e.stopPropagation();
    this.selectOption.emit(option);
    this.displayChange.emit(this.display);
  }

  /**
   * Set the focused state to false when the popover loses focus
   */
  @HostListener('focusout')
  onBlur() {
    this.display = false;
    this.displayChange.emit(this.display);
  }
}
