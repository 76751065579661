import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';

import { errorType } from '@common/ng-design-system';
import { RoleView } from '@fms/ng-fms-api-client';

@Component({
  selector: 'astus-resource-role-permission',
  templateUrl: './resource-role-permission.component.html',
  styleUrls: ['./resource-role-permission.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ResourceRolePermissionComponent),
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ResourceRolePermissionComponent),
      multi: true,
    },
  ],
})
export class ResourceRolePermissionComponent
  implements AfterViewChecked, ControlValueAccessor, Validator
{
  objectKeys = Object.keys;
  // refs
  @ViewChild('overlayRolesPermissions')
  overlayRolesPermissionsRef!: ElementRef<HTMLDivElement>;

  @ViewChild('inputRolesPermissions')
  inputRolesPermissionsRef!: ElementRef<HTMLDivElement>;

  @ViewChild('chipsContainer', { read: ElementRef<HTMLDivElement> })
  chipsContainerRef!: ElementRef<HTMLDivElement>;

  // Template datas
  public overlayShown = false;
  public rolesPermissionsSelectedList: RoleView[] = [];

  // Inputs
  @Input() rolesPermissionsList: RoleView[] = [];

  @Input() label = '';

  @Input() required = false;

  @Input()
  placeholder = '';

  @Input() disabled = false;

  // This here accepts an object of errors to be able to show different or multiple messages
  // Reactive form errors can be passes like so :

  @Input()
  errors: errorType | ValidationErrors | null = null;

  // The messages are also an object and the keys need to match those of "errors"
  @Input()
  errorMessage: errorType | null = null;

  // internal state
  public userHasUpdateChipsList = false;

  // private state
  private touchedValue = false;
  selectedRoleNames: string[] = [];

  constructor(private ref: ChangeDetectorRef) {}

  ngAfterViewChecked(): void {
    // auto scroll to the right end of the chips list when a new chip is added
    if (this.chipsContainerRef && this.userHasUpdateChipsList) {
      this.userHasUpdateChipsList = false;
      this.chipsContainerRef.nativeElement.scrollLeft =
        this.chipsContainerRef.nativeElement.scrollWidth;
    }
  }

  /**
   * This method is called when the user clicks on the input
   * It will show the overlay
   */
  openRolesPermissionsOverlay() {
    // The overlays should have by default 2 elements given by astus backend :
    // - `Gestionnaire`
    // - `Utilisateur de base`
    this.overlayShown = !this.overlayShown;
  }

  /**
   * This method is called when a role is clicked in the overlay
   * It will add the role to the selected list if it's not already in it
   * or remove it from the list if it's already in it
   * @param {RoleView} rolePermission - The role that was clicked
   */
  onRolesPermissionItemClicked(rolePermission: RoleView) {
    this.userHasUpdateChipsList = true;
    // If the role is already selected, remove it from the list
    if (
      this.rolesPermissionsSelectedList.find(
        (role) => role.id === rolePermission.id
      )
    ) {
      this.rolesPermissionsSelectedList =
        this.rolesPermissionsSelectedList.filter(
          (role) => role.id !== rolePermission.id
        );
    } else {
      this.rolesPermissionsSelectedList = [
        ...this.rolesPermissionsSelectedList,
        rolePermission,
      ];
    }

    this.onChange(this.rolesPermissionsSelectedList);
    this.markAsTouched();
    this.ref.detectChanges();
  }

  /**
   * This method is called when the user clicks outside the overlay
   * @param {Event} event - The click event
   */
  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (
      !this.overlayRolesPermissionsRef.nativeElement.contains(
        event.target as Node
      ) &&
      !this.inputRolesPermissionsRef.nativeElement.contains(
        event.target as Node
      )
    ) {
      this.overlayShown = false;
    }
  }

  // Had put a default empty function here, it was causing a lint error
  // eslint-disable-next-line
  onChange = (_inputValue: RoleView[]) => {};

  // Had put a default empty function here, it was causing a lint error
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  writeValue(currValue: RoleView[]) {
    this.rolesPermissionsSelectedList = currValue ?? [];
    // this is the string array that keeps track of the selected roles, just the names
    this.selectedRoleNames = currValue?.map((role) => role.name) ?? [];
    this.ref.detectChanges();
  }

  // I need to use "any" here because the Angular function literally uses "any" as the type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  // I need to use "any" here because the Angular function literally uses "any" as the type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touchedValue) {
      this.onTouched();
      this.touchedValue = true;
    }
    this.ref.detectChanges();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDisabledState(disabledValue: boolean) {
    // for now not used, need to be implemented if needed
    this.ref.detectChanges();
  }

  /**
   * Validates the form control's value.
   * @param {AbstractControl} control The abstract control representing the form control.
   * @returns {ValidationErrors | null} A validation error object if the validation fails, otherwise null.
   */
  validate(control: AbstractControl): ValidationErrors | null {
    const value: RoleView[] = control.value;

    if (!value || value.length === 0) {
      return { required: true };
    }

    return null;
  }
}
