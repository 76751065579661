// @TODO remove when BE is ready

import { permissionsMock } from '../store/permissions.mock';

import { settingsGroupAssignEnum } from './settings-groups/settings-groups.enum';
import { settingsReducerInterface } from './store/settings';

export const settingsMock: settingsReducerInterface = {
  // to be changed roleList should be of type RoleView[] since
  // since the rolesList that is returned from the api call is of type RoleView[]
  rolesList: [
    {
      id: 3,
      title: 'Démo',
      description: "Permet de tester les rôles et permissions de l'application",
      assocUsers: ['Jane Doe', 'John Doe'],
      assocGroups: [1, 2, 3, 4],
      assocOrgs: [1, 2, 3, 4, 5, 6],
      permissions: permissionsMock,
    },
    {
      id: 2,
      title: 'Éditeur',
      description: "Éditeur de l'application",
      assocUsers: [
        'Jane Doe',
        'John Doe',
        'Jacob Keys',
        'Miranda Keyes',
        'Avery Johnson',
        'Marcus Fenix',
        'Dominic Santiago',
        'Augustus Cole',
        'Damon Baird',
        'Anya Stroud',
        'Samantha Byrne',
        'Jace Stratton',
        'Fahz Chutani',
        'JD Fenix',
        'Kait Diaz',
      ],
      assocGroups: [1, 2, 3, 4],
      assocOrgs: [1, 2, 3, 4, 5, 6],
      permissions: {
        general: {
          companyProfileAccess: false,
          companyProfileEdit: true,
          languageAndFormatsAccess: false,
          languageAndFormatsEdit: false,
          myProfilAccess: false,
          myProfilEdit: false,
        },
        administration: {
          rolesAndPermissionsAccess: false,
          rolesAndPermissionsCreateEdit: false,
          rolesAndPermissionsDelete: false,
        },
      },
    },
    {
      id: 1,
      title: 'Administrateur',
      description: "Administrateur de l'application",
      assocUsers: [],
      assocGroups: [1, 2, 3, 4],
      assocOrgs: [1, 2, 3, 4, 5, 6],
      permissions: {
        general: {
          companyProfileAccess: false,
          companyProfileEdit: false,
          languageAndFormatsAccess: false,
          languageAndFormatsEdit: false,
          myProfilAccess: false,
          myProfilEdit: false,
        },
        administration: {
          rolesAndPermissionsAccess: false,
          rolesAndPermissionsCreateEdit: false,
          rolesAndPermissionsDelete: false,
        },
      },
    },
  ],

  profile: {
    firstName: 'John',
    lastName: 'Doe',
    email: 'john.doe@astus.com',
    phone: '(514) 123-4567',
    roles: [
      {
        timestamp: '2024-02-12T16:43:50.316Z',
        id: 1,
        reportsAndAnalyticsRolePermissions: {
          ACCESS_TO_REPORTS_PAGE: false,
          EDIT: false,
          CREATE_AND_EDIT: false,
          ACCESS_TO_ANALYTICS_PAGE: false,
          DELETE: false,
          CREATE: false,
          EXPORT: false,
        },
        viewsRolePermissions: {
          SEE_VEHICLES_POSITION: true,
          SEE_CIRCUITS: true,
          SEE_ALERTS: true,
          SEE_INFOBOXES: true,
          ACCESS_TO_AGENDA: true,
          ACCESS_TO_MAP: true,
          SEE_USERS_POSITION: true,
          MANAGE_INFOBOXES: true,
          SEE_STOPS: true,
          SEE_ROUTES: true,
        },
        associationsRolePermissions: {
          CREATE: false,
          DELETE: false,
          VIEW: false,
          EDIT: false,
        },
        timelineRolePermissions: {
          ACCESS_TO_REAL_TIME: false,
          ACCESS_TO_HISTORY: false,
          ACCESS_TO_PLANNING: false,
        },
        name: 'conducteur',
        description: 'Opère et navigue avec des véhicules assignés.',
        administrationPermissions: {
          CREATE_AND_EDIT: false,
          ACCESS_ROLES_AND_PERMISSIONS_PAGE: false,
          CREATE_AND_EDIT_FIELDS: false,
          ACCESS_GROUPS_PAGE: false,
          DELETE_GROUPS: false,
          DELETE_HOMEPORT: false,
          DELETE: false,
          ACCESS_EVENTS_AND_ALERTS_PAGE: false,
          ACCESS_HOMEPORT_PAGE: false,
          DELETE_ROLES: false,
        },
        permissions: [
          {
            id: 1,
            name: 'Conducteur',
          },
        ],
        resourcesRolePermissions: {
          DELETE_CIRCUITS: false,
          SEE_CIRCUITS_INFOS: true,
          SEE_VEHICLES_INFOS: true,
          CREATE_AND_EDIT_PERSONALIZED_VIEWS: true,
          CREATE_AND_EDIT_SHARED: true,
          SEE_USERS_INFOS: true,
          CREATE_AND_EDIT_USERS: false,
          DELETE_USERS: false,
          CREATE_AND_EDIT_CIRCUITS: true,
          DELETE_PERSONALIZED_VIEWS: true,
          ACCESS_TO_RESOURCES_PAGE: true,
          DELETE_VEHICLES: false,
          DELETE_SHARED: true,
          CREATE_AND_EDIT_VEHICLES: false,
        },
        generalPermissions: {
          EDIT_FIELDS: false,
          EDIT_WEAK_ALERT_NOTIFICATIONS: true,
          EDIT_SCHEDULE_NOTIFICATIONS: true,
          ACCESS_MY_PROFILE_PAGE: true,
          EDIT_SYSTEM_ALERT_NOTIFICATIONS: true,
          ACCESS_LANGUAGE_AND_FORMATS_PAGE: true,
          EDIT_SEVERE_ALERT_NOTIFICATIONS: true,
          ACCESS_NOTIFICATIONS_PAGE: true,
          EDIT_MEDIUM_ALERT_NOTIFICATIONS: true,
          ACCESS_SETTINGS_COMPANY_PROFILE_PAGE: true,
          EDIT_INACTIVE_STATUS: true,
        },
      },
    ],
  },

  groupsList: [
    {
      id: '1',
      active: false,
      locked: true,
      group: 'Groupe A',
      assignees: [settingsGroupAssignEnum.EQUIPMENTS],
      sharable: 1,
    },

    {
      id: '2',
      active: true,
      locked: true,
      group: 'Groupe B',
      assignees: [
        settingsGroupAssignEnum.USERS,
        settingsGroupAssignEnum.EQUIPMENTS,
      ],
      sharable: 0,
    },

    {
      id: '3',
      active: true,
      locked: false,
      group: 'Groupe C',
      assignees: [
        settingsGroupAssignEnum.VEHICLES,
        settingsGroupAssignEnum.USERS,
        settingsGroupAssignEnum.EQUIPMENTS,
      ],
      sharable: 1,
    },

    {
      id: '4',
      active: false,
      locked: false,
      group: 'Groupe D',
      assignees: [],
      sharable: 0,
    },
  ],

  mockRolesList: [
    {
      administrationPermissions: {
        DELETE_HOMEPORT: false,
        CREATE_AND_EDIT: false,
        DELETE_ROLES: false,
        ACCESS_HOMEPORT_PAGE: false,
        DELETE: false,
        ACCESS_ROLES_AND_PERMISSIONS_PAGE: false,
        ACCESS_EVENTS_AND_ALERTS_PAGE: false,
        DELETE_GROUPS: false,
        ACCESS_GROUPS_PAGE: false,
        CREATE_AND_EDIT_FIELDS: false,
      },
      name: 'Administrateur',
      resourcesRolePermissions: {
        DELETE_VEHICLES: false,
        DELETE_USERS: false,
        ACCESS_TO_RESOURCES_PAGE: false,
        SEE_CIRCUITS_INFOS: false,
        CREATE_AND_EDIT_USERS: false,
        SEE_USERS_INFOS: false,
        CREATE_AND_EDIT_SHARED: false,
        CREATE_AND_EDIT_PERSONALIZED_VIEWS: false,
        DELETE_PERSONALIZED_VIEWS: false,
        CREATE_AND_EDIT_CIRCUITS: false,
        CREATE_AND_EDIT_VEHICLES: false,
        DELETE_CIRCUITS: false,
        SEE_VEHICLES_INFOS: false,
        DELETE_SHARED: false,
      },
      description: "Gère, configure et sécurise l'ensemble du système.",
      reportsAndAnalyticsRolePermissions: {
        ACCESS_TO_REPORTS_PAGE: false,
        EDIT: false,
        CREATE_AND_EDIT: false,
        ACCESS_TO_ANALYTICS_PAGE: false,
        DELETE: false,
        CREATE: false,
        EXPORT: false,
      },
      permissions: [
        {
          name: 'Admin',
          id: 0,
        },
        {
          id: 1,
          name: 'Conducteur',
        },
        {
          name: 'Responsable entretien',
          id: 2,
        },
        {
          id: 3,
          name: 'Service client',
        },
        {
          id: 4,
          name: 'Autiteur externe',
        },
      ],
      id: 0,
      viewsRolePermissions: {
        SEE_ALERTS: false,
        SEE_STOPS: false,
        ACCESS_TO_MAP: false,
        MANAGE_INFOBOXES: false,
        SEE_USERS_POSITION: false,
        SEE_INFOBOXES: false,
        SEE_ROUTES: false,
        ACCESS_TO_AGENDA: false,
        SEE_CIRCUITS: false,
        SEE_VEHICLES_POSITION: false,
      },
      timestamp: '2024-02-14T16:14:54.894Z',
      associationsRolePermissions: {
        CREATE: false,
        DELETE: false,
        VIEW: false,
        EDIT: false,
      },
      generalPermissions: {
        EDIT_SCHEDULE_NOTIFICATIONS: false,
        ACCESS_MY_PROFILE_PAGE: false,
        EDIT_FIELDS: false,
        EDIT_SYSTEM_ALERT_NOTIFICATIONS: false,
        ACCESS_LANGUAGE_AND_FORMATS_PAGE: false,
        ACCESS_SETTINGS_COMPANY_PROFILE_PAGE: false,
        EDIT_INACTIVE_STATUS: false,
        ACCESS_NOTIFICATIONS_PAGE: false,
        EDIT_WEAK_ALERT_NOTIFICATIONS: false,
        EDIT_SEVERE_ALERT_NOTIFICATIONS: true,
        EDIT_MEDIUM_ALERT_NOTIFICATIONS: false,
      },
      timelineRolePermissions: {
        ACCESS_TO_PLANNING: false,
        ACCESS_TO_HISTORY: false,
        ACCESS_TO_REAL_TIME: false,
      },
    },
    {
      permissions: [
        {
          name: 'Conducteur',
          id: 1,
        },
      ],
      reportsAndAnalyticsRolePermissions: {
        ACCESS_TO_REPORTS_PAGE: false,
        EDIT: false,
        CREATE_AND_EDIT: false,
        ACCESS_TO_ANALYTICS_PAGE: false,
        DELETE: false,
        CREATE: false,
        EXPORT: false,
      },
      description: 'Opère et navigue avec des véhicules assignés.',
      timelineRolePermissions: {
        ACCESS_TO_HISTORY: false,
        ACCESS_TO_REAL_TIME: false,
        ACCESS_TO_PLANNING: false,
      },
      name: 'Conducteur',
      administrationPermissions: {
        DELETE_HOMEPORT: false,
        CREATE_AND_EDIT: false,
        CREATE_AND_EDIT_FIELDS: false,
        ACCESS_EVENTS_AND_ALERTS_PAGE: false,
        DELETE_ROLES: false,
        ACCESS_GROUPS_PAGE: false,
        ACCESS_ROLES_AND_PERMISSIONS_PAGE: false,
        DELETE: false,
        DELETE_GROUPS: false,
        ACCESS_HOMEPORT_PAGE: false,
      },
      id: 1,
      resourcesRolePermissions: {
        ACCESS_TO_RESOURCES_PAGE: true,
        DELETE_PERSONALIZED_VIEWS: true,
        CREATE_AND_EDIT_VEHICLES: false,
        CREATE_AND_EDIT_USERS: false,
        DELETE_VEHICLES: false,
        SEE_CIRCUITS_INFOS: true,
        DELETE_USERS: false,
        CREATE_AND_EDIT_SHARED: true,
        DELETE_CIRCUITS: false,
        CREATE_AND_EDIT_CIRCUITS: true,
        SEE_VEHICLES_INFOS: true,
        DELETE_SHARED: true,
        CREATE_AND_EDIT_PERSONALIZED_VIEWS: true,
        SEE_USERS_INFOS: true,
      },
      viewsRolePermissions: {
        SEE_INFOBOXES: true,
        MANAGE_INFOBOXES: true,
        SEE_ALERTS: true,
        SEE_STOPS: true,
        ACCESS_TO_AGENDA: true,
        SEE_CIRCUITS: true,
        SEE_USERS_POSITION: true,
        SEE_ROUTES: true,
        ACCESS_TO_MAP: true,
        SEE_VEHICLES_POSITION: true,
      },
      timestamp: '2024-02-12T16:43:50.316Z',
      associationsRolePermissions: {
        CREATE: false,
        DELETE: false,
        VIEW: false,
        EDIT: false,
      },
      generalPermissions: {
        EDIT_SEVERE_ALERT_NOTIFICATIONS: true,
        ACCESS_MY_PROFILE_PAGE: true,
        EDIT_FIELDS: true,
        EDIT_SCHEDULE_NOTIFICATIONS: true,
        EDIT_WEAK_ALERT_NOTIFICATIONS: true,
        ACCESS_LANGUAGE_AND_FORMATS_PAGE: true,
        EDIT_SYSTEM_ALERT_NOTIFICATIONS: true,
        EDIT_MEDIUM_ALERT_NOTIFICATIONS: true,
        ACCESS_SETTINGS_COMPANY_PROFILE_PAGE: true,
        ACCESS_NOTIFICATIONS_PAGE: true,
        EDIT_INACTIVE_STATUS: true,
      },
    },
    {
      associationsRolePermissions: {
        CREATE: false,
        DELETE: false,
        VIEW: false,
        EDIT: false,
      },
      timestamp: '2024-02-12T16:43:50.505Z',
      name: 'Responsable entretien',
      resourcesRolePermissions: {
        ACCESS_TO_RESOURCES_PAGE: true,
        SEE_CIRCUITS_INFOS: true,
        CREATE_AND_EDIT_USERS: false,
        DELETE_PERSONALIZED_VIEWS: true,
        DELETE_VEHICLES: false,
        CREATE_AND_EDIT_PERSONALIZED_VIEWS: true,
        SEE_VEHICLES_INFOS: true,
        CREATE_AND_EDIT_SHARED: true,
        DELETE_USERS: false,
        CREATE_AND_EDIT_CIRCUITS: true,
        CREATE_AND_EDIT_VEHICLES: false,
        SEE_USERS_INFOS: true,
        DELETE_CIRCUITS: false,
        DELETE_SHARED: true,
      },
      description: 'Assure la maintenance et réparation des véhicules.',
      generalPermissions: {
        ACCESS_LANGUAGE_AND_FORMATS_PAGE: true,
        EDIT_FIELDS: true,
        EDIT_SYSTEM_ALERT_NOTIFICATIONS: true,
        EDIT_SCHEDULE_NOTIFICATIONS: true,
        EDIT_INACTIVE_STATUS: true,
        EDIT_SEVERE_ALERT_NOTIFICATIONS: true,
        ACCESS_MY_PROFILE_PAGE: true,
        ACCESS_NOTIFICATIONS_PAGE: true,
        ACCESS_SETTINGS_COMPANY_PROFILE_PAGE: true,
        EDIT_MEDIUM_ALERT_NOTIFICATIONS: true,
        EDIT_WEAK_ALERT_NOTIFICATIONS: true,
      },
      timelineRolePermissions: {
        ACCESS_TO_REAL_TIME: false,
        ACCESS_TO_HISTORY: false,
        ACCESS_TO_PLANNING: false,
      },
      reportsAndAnalyticsRolePermissions: {
        ACCESS_TO_REPORTS_PAGE: false,
        EDIT: false,
        CREATE_AND_EDIT: false,
        ACCESS_TO_ANALYTICS_PAGE: false,
        DELETE: false,
        CREATE: false,
        EXPORT: false,
      },
      administrationPermissions: {
        CREATE_AND_EDIT: false,
        ACCESS_ROLES_AND_PERMISSIONS_PAGE: false,
        CREATE_AND_EDIT_FIELDS: false,
        ACCESS_HOMEPORT_PAGE: false,
        DELETE_ROLES: false,
        DELETE_HOMEPORT: false,
        ACCESS_GROUPS_PAGE: false,
        ACCESS_EVENTS_AND_ALERTS_PAGE: false,
        DELETE: false,
        DELETE_GROUPS: false,
      },
      viewsRolePermissions: {
        ACCESS_TO_AGENDA: true,
        SEE_STOPS: true,
        SEE_VEHICLES_POSITION: true,
        SEE_USERS_POSITION: true,
        MANAGE_INFOBOXES: true,
        ACCESS_TO_MAP: true,
        SEE_CIRCUITS: true,
        SEE_INFOBOXES: true,
        SEE_ALERTS: true,
        SEE_ROUTES: true,
      },
      id: 2,
      permissions: [
        {
          id: 2,
          name: 'Responsable entretien',
        },
      ],
    },
    {
      administrationPermissions: {
        ACCESS_HOMEPORT_PAGE: false,
        ACCESS_GROUPS_PAGE: false,
        DELETE_ROLES: false,
        ACCESS_EVENTS_AND_ALERTS_PAGE: false,
        DELETE: false,
        CREATE_AND_EDIT_FIELDS: false,
        ACCESS_ROLES_AND_PERMISSIONS_PAGE: false,
        CREATE_AND_EDIT: false,
        DELETE_GROUPS: false,
        DELETE_HOMEPORT: false,
      },
      resourcesRolePermissions: {
        DELETE_CIRCUITS: false,
        DELETE_SHARED: false,
        SEE_CIRCUITS_INFOS: false,
        CREATE_AND_EDIT_USERS: false,
        DELETE_PERSONALIZED_VIEWS: false,
        DELETE_VEHICLES: false,
        CREATE_AND_EDIT_VEHICLES: false,
        CREATE_AND_EDIT_PERSONALIZED_VIEWS: false,
        CREATE_AND_EDIT_SHARED: false,
        SEE_VEHICLES_INFOS: false,
        DELETE_USERS: false,
        ACCESS_TO_RESOURCES_PAGE: false,
        CREATE_AND_EDIT_CIRCUITS: false,
        SEE_USERS_INFOS: false,
      },
      id: 3,
      timestamp: '2024-02-12T16:43:50.609Z',
      permissions: [
        {
          id: 3,
          name: 'Service client',
        },
      ],
      generalPermissions: {
        EDIT_SYSTEM_ALERT_NOTIFICATIONS: false,
        EDIT_SCHEDULE_NOTIFICATIONS: false,
        ACCESS_NOTIFICATIONS_PAGE: false,
        EDIT_INACTIVE_STATUS: false,
        EDIT_MEDIUM_ALERT_NOTIFICATIONS: false,
        ACCESS_SETTINGS_COMPANY_PROFILE_PAGE: false,
        EDIT_SEVERE_ALERT_NOTIFICATIONS: false,
        ACCESS_LANGUAGE_AND_FORMATS_PAGE: false,
        ACCESS_MY_PROFILE_PAGE: false,
        EDIT_WEAK_ALERT_NOTIFICATIONS: false,
        EDIT_FIELDS: false,
      },
      timelineRolePermissions: {
        ACCESS_TO_PLANNING: false,
        ACCESS_TO_REAL_TIME: false,
        ACCESS_TO_HISTORY: false,
      },
      viewsRolePermissions: {
        SEE_VEHICLES_POSITION: false,
        SEE_USERS_POSITION: false,
        SEE_INFOBOXES: false,
        ACCESS_TO_AGENDA: false,
        SEE_STOPS: false,
        SEE_CIRCUITS: false,
        ACCESS_TO_MAP: false,
        SEE_ROUTES: false,
        SEE_ALERTS: false,
        MANAGE_INFOBOXES: false,
      },
      reportsAndAnalyticsRolePermissions: {
        ACCESS_TO_REPORTS_PAGE: false,
        EDIT: false,
        CREATE_AND_EDIT: false,
        ACCESS_TO_ANALYTICS_PAGE: false,
        DELETE: false,
        CREATE: false,
        EXPORT: false,
      },
      name: 'Service client',
      description: 'Assistance et support aux clients.',
      associationsRolePermissions: {
        CREATE: false,
        DELETE: false,
        VIEW: false,
        EDIT: false,
      },
    },
    {
      administrationPermissions: {
        CREATE_AND_EDIT_FIELDS: false,
        ACCESS_GROUPS_PAGE: false,
        ACCESS_EVENTS_AND_ALERTS_PAGE: false,
        CREATE_AND_EDIT: false,
        DELETE_HOMEPORT: false,
        DELETE_ROLES: false,
        ACCESS_HOMEPORT_PAGE: false,
        DELETE_GROUPS: false,
        ACCESS_ROLES_AND_PERMISSIONS_PAGE: false,
        DELETE: false,
      },
      permissions: [
        {
          name: 'Autiteur externe',
          id: 4,
        },
      ],
      timelineRolePermissions: {
        ACCESS_TO_HISTORY: false,
        ACCESS_TO_REAL_TIME: false,
        ACCESS_TO_PLANNING: false,
      },
      description: 'Évalue et rapporte sur la conformité du système.',
      associationsRolePermissions: {
        CREATE: false,
        DELETE: false,
        VIEW: false,
        EDIT: false,
      },
      id: 4,
      viewsRolePermissions: {
        MANAGE_INFOBOXES: false,
        SEE_ROUTES: false,
        SEE_VEHICLES_POSITION: false,
        ACCESS_TO_MAP: false,
        SEE_STOPS: false,
        SEE_USERS_POSITION: false,
        SEE_ALERTS: false,
        SEE_INFOBOXES: false,
        SEE_CIRCUITS: false,
        ACCESS_TO_AGENDA: false,
      },
      generalPermissions: {
        EDIT_SEVERE_ALERT_NOTIFICATIONS: false,
        ACCESS_LANGUAGE_AND_FORMATS_PAGE: false,
        EDIT_MEDIUM_ALERT_NOTIFICATIONS: false,
        EDIT_SYSTEM_ALERT_NOTIFICATIONS: false,
        EDIT_FIELDS: false,
        EDIT_INACTIVE_STATUS: false,
        ACCESS_MY_PROFILE_PAGE: false,
        EDIT_WEAK_ALERT_NOTIFICATIONS: false,
        ACCESS_NOTIFICATIONS_PAGE: false,
        ACCESS_SETTINGS_COMPANY_PROFILE_PAGE: false,
        EDIT_SCHEDULE_NOTIFICATIONS: false,
      },
      timestamp: '2024-02-12T16:43:50.730Z',
      name: 'Autiteur externe',
      resourcesRolePermissions: {
        DELETE_USERS: false,
        SEE_VEHICLES_INFOS: false,
        DELETE_CIRCUITS: false,
        CREATE_AND_EDIT_CIRCUITS: false,
        DELETE_SHARED: false,
        SEE_USERS_INFOS: false,
        CREATE_AND_EDIT_SHARED: false,
        ACCESS_TO_RESOURCES_PAGE: false,
        SEE_CIRCUITS_INFOS: false,
        DELETE_PERSONALIZED_VIEWS: false,
        CREATE_AND_EDIT_VEHICLES: false,
        DELETE_VEHICLES: false,
        CREATE_AND_EDIT_PERSONALIZED_VIEWS: false,
        CREATE_AND_EDIT_USERS: false,
      },
      reportsAndAnalyticsRolePermissions: {
        ACCESS_TO_REPORTS_PAGE: false,
        EDIT: false,
        CREATE_AND_EDIT: false,
        ACCESS_TO_ANALYTICS_PAGE: false,
        DELETE: false,
        CREATE: false,
        EXPORT: false,
      },
    },
    {
      administrationPermissions: {
        ACCESS_HOMEPORT_PAGE: false,
        CREATE_AND_EDIT_FIELDS: false,
        DELETE_ROLES: false,
        ACCESS_EVENTS_AND_ALERTS_PAGE: false,
        DELETE_HOMEPORT: false,
        CREATE_AND_EDIT: false,
        DELETE_GROUPS: false,
        DELETE: false,
        ACCESS_GROUPS_PAGE: false,
        ACCESS_ROLES_AND_PERMISSIONS_PAGE: false,
      },
      associationsRolePermissions: {
        CREATE: false,
        DELETE: false,
        VIEW: false,
        EDIT: false,
      },
      timestamp: '2024-02-12T16:43:50.799Z',
      id: 5,
      description: 'Supervise et coordonne les opérations de flotte.',
      timelineRolePermissions: {
        ACCESS_TO_REAL_TIME: false,
        ACCESS_TO_HISTORY: false,
        ACCESS_TO_PLANNING: false,
      },
      reportsAndAnalyticsRolePermissions: {
        ACCESS_TO_REPORTS_PAGE: false,
        EDIT: false,
        CREATE_AND_EDIT: false,
        ACCESS_TO_ANALYTICS_PAGE: false,
        DELETE: false,
        CREATE: false,
        EXPORT: false,
      },
      name: 'Gestionnaire de flotte',
      resourcesRolePermissions: {
        CREATE_AND_EDIT_CIRCUITS: false,
        SEE_CIRCUITS_INFOS: false,
        CREATE_AND_EDIT_SHARED: false,
        DELETE_VEHICLES: false,
        SEE_VEHICLES_INFOS: false,
        SEE_USERS_INFOS: false,
        DELETE_SHARED: false,
        ACCESS_TO_RESOURCES_PAGE: false,
        CREATE_AND_EDIT_PERSONALIZED_VIEWS: false,
        DELETE_CIRCUITS: false,
        DELETE_USERS: false,
        DELETE_PERSONALIZED_VIEWS: false,
        CREATE_AND_EDIT_USERS: false,
        CREATE_AND_EDIT_VEHICLES: false,
      },
      generalPermissions: {
        EDIT_SYSTEM_ALERT_NOTIFICATIONS: false,
        ACCESS_NOTIFICATIONS_PAGE: false,
        ACCESS_MY_PROFILE_PAGE: false,
        EDIT_FIELDS: false,
        EDIT_INACTIVE_STATUS: false,
        EDIT_WEAK_ALERT_NOTIFICATIONS: false,
        EDIT_SEVERE_ALERT_NOTIFICATIONS: false,
        EDIT_SCHEDULE_NOTIFICATIONS: false,
        EDIT_MEDIUM_ALERT_NOTIFICATIONS: false,
        ACCESS_SETTINGS_COMPANY_PROFILE_PAGE: false,
        ACCESS_LANGUAGE_AND_FORMATS_PAGE: false,
      },
      permissions: [
        {
          id: 1,
          name: 'Conducteur',
        },
        {
          id: 2,
          name: 'Responsable entretien',
        },
        {
          name: 'Service client',
          id: 3,
        },
        {
          name: 'Autiteur externe',
          id: 4,
        },
        {
          name: 'Gestionnaire de flotte',
          id: 5,
        },
      ],
      viewsRolePermissions: {
        SEE_CIRCUITS: false,
        SEE_INFOBOXES: false,
        MANAGE_INFOBOXES: false,
        SEE_ROUTES: false,
        ACCESS_TO_AGENDA: false,
        ACCESS_TO_MAP: false,
        SEE_USERS_POSITION: false,
        SEE_ALERTS: false,
        SEE_VEHICLES_POSITION: false,
        SEE_STOPS: false,
      },
    },
    {
      timelineRolePermissions: {
        ACCESS_TO_HISTORY: false,
        ACCESS_TO_REAL_TIME: false,
        ACCESS_TO_PLANNING: false,
      },
      description: 'Planifie et organise les itinéraires de transport.',
      timestamp: '2024-02-12T16:43:50.869Z',
      reportsAndAnalyticsRolePermissions: {
        ACCESS_TO_REPORTS_PAGE: false,
        EDIT: false,
        CREATE_AND_EDIT: false,
        ACCESS_TO_ANALYTICS_PAGE: false,
        DELETE: false,
        CREATE: false,
        EXPORT: false,
      },
      id: 6,
      resourcesRolePermissions: {
        CREATE_AND_EDIT_SHARED: false,
        CREATE_AND_EDIT_PERSONALIZED_VIEWS: false,
        CREATE_AND_EDIT_VEHICLES: false,
        SEE_CIRCUITS_INFOS: false,
        DELETE_SHARED: false,
        SEE_VEHICLES_INFOS: false,
        DELETE_USERS: false,
        ACCESS_TO_RESOURCES_PAGE: false,
        CREATE_AND_EDIT_USERS: false,
        CREATE_AND_EDIT_CIRCUITS: false,
        DELETE_PERSONALIZED_VIEWS: false,
        SEE_USERS_INFOS: false,
        DELETE_CIRCUITS: false,
        DELETE_VEHICLES: false,
      },
      associationsRolePermissions: {
        CREATE: false,
        DELETE: false,
        VIEW: false,
        EDIT: false,
      },
      name: 'Répartiteur',
      administrationPermissions: {
        DELETE_GROUPS: false,
        ACCESS_HOMEPORT_PAGE: false,
        CREATE_AND_EDIT_FIELDS: false,
        DELETE: false,
        DELETE_HOMEPORT: false,
        ACCESS_GROUPS_PAGE: false,
        ACCESS_EVENTS_AND_ALERTS_PAGE: false,
        ACCESS_ROLES_AND_PERMISSIONS_PAGE: false,
        DELETE_ROLES: false,
        CREATE_AND_EDIT: false,
      },
      generalPermissions: {
        EDIT_INACTIVE_STATUS: false,
        EDIT_WEAK_ALERT_NOTIFICATIONS: false,
        ACCESS_LANGUAGE_AND_FORMATS_PAGE: false,
        ACCESS_NOTIFICATIONS_PAGE: false,
        EDIT_SCHEDULE_NOTIFICATIONS: false,
        EDIT_FIELDS: false,
        EDIT_SYSTEM_ALERT_NOTIFICATIONS: false,
        EDIT_SEVERE_ALERT_NOTIFICATIONS: false,
        EDIT_MEDIUM_ALERT_NOTIFICATIONS: false,
        ACCESS_MY_PROFILE_PAGE: false,
        ACCESS_SETTINGS_COMPANY_PROFILE_PAGE: false,
      },
      permissions: [
        {
          name: 'Conducteur',
          id: 1,
        },
        {
          id: 6,
          name: 'Répartiteur',
        },
      ],
      viewsRolePermissions: {
        SEE_CIRCUITS: false,
        SEE_USERS_POSITION: false,
        SEE_INFOBOXES: false,
        SEE_STOPS: false,
        SEE_ALERTS: false,
        ACCESS_TO_MAP: false,
        MANAGE_INFOBOXES: false,
        SEE_ROUTES: false,
        ACCESS_TO_AGENDA: false,
        SEE_VEHICLES_POSITION: false,
      },
    },
    {
      name: 'Responsable des finances',
      id: 7,
      viewsRolePermissions: {
        SEE_USERS_POSITION: false,
        SEE_CIRCUITS: false,
        MANAGE_INFOBOXES: false,
        SEE_ROUTES: false,
        ACCESS_TO_AGENDA: false,
        SEE_INFOBOXES: false,
        ACCESS_TO_MAP: false,
        SEE_ALERTS: false,
        SEE_VEHICLES_POSITION: false,
        SEE_STOPS: false,
      },
      reportsAndAnalyticsRolePermissions: {
        ACCESS_TO_REPORTS_PAGE: false,
        EDIT: false,
        CREATE_AND_EDIT: false,
        ACCESS_TO_ANALYTICS_PAGE: false,
        DELETE: false,
        CREATE: false,
        EXPORT: false,
      },
      description: 'Gère la santé financiere de la flotte de véhicules.',
      timestamp: '2024-02-12T16:43:50.965Z',
      associationsRolePermissions: {
        CREATE: false,
        DELETE: false,
        VIEW: false,
        EDIT: false,
      },
      timelineRolePermissions: {
        ACCESS_TO_REAL_TIME: false,
        ACCESS_TO_HISTORY: false,
        ACCESS_TO_PLANNING: false,
      },
      administrationPermissions: {
        DELETE_GROUPS: false,
        DELETE: false,
        DELETE_HOMEPORT: false,
        ACCESS_EVENTS_AND_ALERTS_PAGE: false,
        ACCESS_HOMEPORT_PAGE: false,
        CREATE_AND_EDIT: false,
        ACCESS_ROLES_AND_PERMISSIONS_PAGE: false,
        DELETE_ROLES: false,
        ACCESS_GROUPS_PAGE: false,
        CREATE_AND_EDIT_FIELDS: false,
      },
      generalPermissions: {
        ACCESS_SETTINGS_COMPANY_PROFILE_PAGE: false,
        EDIT_WEAK_ALERT_NOTIFICATIONS: false,
        ACCESS_NOTIFICATIONS_PAGE: false,
        EDIT_SCHEDULE_NOTIFICATIONS: false,
        EDIT_INACTIVE_STATUS: false,
        EDIT_SEVERE_ALERT_NOTIFICATIONS: false,
        EDIT_MEDIUM_ALERT_NOTIFICATIONS: false,
        EDIT_SYSTEM_ALERT_NOTIFICATIONS: false,
        EDIT_FIELDS: false,
        ACCESS_LANGUAGE_AND_FORMATS_PAGE: false,
        ACCESS_MY_PROFILE_PAGE: false,
      },
      permissions: [
        {
          name: 'Responsable des finances',
          id: 7,
        },
      ],
      resourcesRolePermissions: {
        DELETE_PERSONALIZED_VIEWS: false,
        SEE_USERS_INFOS: false,
        CREATE_AND_EDIT_USERS: false,
        CREATE_AND_EDIT_SHARED: false,
        ACCESS_TO_RESOURCES_PAGE: false,
        CREATE_AND_EDIT_VEHICLES: false,
        DELETE_VEHICLES: false,
        DELETE_CIRCUITS: false,
        SEE_CIRCUITS_INFOS: false,
        DELETE_USERS: false,
        CREATE_AND_EDIT_PERSONALIZED_VIEWS: false,
        SEE_VEHICLES_INFOS: false,
        DELETE_SHARED: false,
        CREATE_AND_EDIT_CIRCUITS: false,
      },
    },
    {
      timelineRolePermissions: {
        ACCESS_TO_PLANNING: false,
        ACCESS_TO_REAL_TIME: false,
        ACCESS_TO_HISTORY: false,
      },
      associationsRolePermissions: {
        CREATE: false,
        DELETE: false,
        VIEW: false,
        EDIT: false,
      },
      description: 'Veille à la sécurité des opérations.',
      resourcesRolePermissions: {
        DELETE_USERS: false,
        SEE_USERS_INFOS: false,
        CREATE_AND_EDIT_PERSONALIZED_VIEWS: false,
        DELETE_PERSONALIZED_VIEWS: false,
        CREATE_AND_EDIT_USERS: false,
        CREATE_AND_EDIT_VEHICLES: false,
        SEE_VEHICLES_INFOS: false,
        ACCESS_TO_RESOURCES_PAGE: false,
        DELETE_VEHICLES: false,
        DELETE_SHARED: false,
        DELETE_CIRCUITS: false,
        CREATE_AND_EDIT_SHARED: false,
        SEE_CIRCUITS_INFOS: false,
        CREATE_AND_EDIT_CIRCUITS: false,
      },
      reportsAndAnalyticsRolePermissions: {
        ACCESS_TO_REPORTS_PAGE: false,
        EDIT: false,
        CREATE_AND_EDIT: false,
        ACCESS_TO_ANALYTICS_PAGE: false,
        DELETE: false,
        CREATE: false,
        EXPORT: false,
      },
      id: 8,
      administrationPermissions: {
        ACCESS_GROUPS_PAGE: false,
        CREATE_AND_EDIT: false,
        DELETE_HOMEPORT: false,
        DELETE: false,
        DELETE_GROUPS: false,
        ACCESS_EVENTS_AND_ALERTS_PAGE: false,
        DELETE_ROLES: false,
        ACCESS_ROLES_AND_PERMISSIONS_PAGE: false,
        ACCESS_HOMEPORT_PAGE: false,
        CREATE_AND_EDIT_FIELDS: false,
      },
      viewsRolePermissions: {
        SEE_CIRCUITS: false,
        SEE_USERS_POSITION: false,
        ACCESS_TO_MAP: false,
        ACCESS_TO_AGENDA: false,
        SEE_INFOBOXES: false,
        SEE_VEHICLES_POSITION: false,
        SEE_ALERTS: false,
        MANAGE_INFOBOXES: false,
        SEE_ROUTES: false,
        SEE_STOPS: false,
      },
      name: 'Responsable de la sécurité',
      permissions: [
        {
          name: 'Responsable de la sécurité',
          id: 8,
        },
      ],
      timestamp: '2024-02-12T16:43:51.019Z',
      generalPermissions: {
        EDIT_INACTIVE_STATUS: false,
        EDIT_SYSTEM_ALERT_NOTIFICATIONS: false,
        ACCESS_NOTIFICATIONS_PAGE: false,
        ACCESS_SETTINGS_COMPANY_PROFILE_PAGE: false,
        EDIT_WEAK_ALERT_NOTIFICATIONS: false,
        EDIT_FIELDS: false,
        EDIT_SEVERE_ALERT_NOTIFICATIONS: false,
        ACCESS_LANGUAGE_AND_FORMATS_PAGE: false,
        EDIT_SCHEDULE_NOTIFICATIONS: false,
        EDIT_MEDIUM_ALERT_NOTIFICATIONS: false,
        ACCESS_MY_PROFILE_PAGE: false,
      },
    },
    {
      timestamp: '2024-02-12T16:43:51.144Z',
      name: 'Commission scolaire',
      viewsRolePermissions: {
        SEE_ALERTS: false,
        ACCESS_TO_MAP: false,
        SEE_USERS_POSITION: false,
        SEE_INFOBOXES: false,
        SEE_STOPS: false,
        SEE_VEHICLES_POSITION: false,
        SEE_CIRCUITS: false,
        MANAGE_INFOBOXES: false,
        ACCESS_TO_AGENDA: false,
        SEE_ROUTES: false,
      },
      id: 9,
      description: 'Gère les transports scolaires.',
      reportsAndAnalyticsRolePermissions: {
        ACCESS_TO_REPORTS_PAGE: false,
        EDIT: false,
        CREATE_AND_EDIT: false,
        ACCESS_TO_ANALYTICS_PAGE: false,
        DELETE: false,
        CREATE: false,
        EXPORT: false,
      },
      associationsRolePermissions: {
        CREATE: false,
        DELETE: false,
        VIEW: false,
        EDIT: false,
      },
      timelineRolePermissions: {
        ACCESS_TO_PLANNING: false,
        ACCESS_TO_REAL_TIME: false,
        ACCESS_TO_HISTORY: false,
      },
      generalPermissions: {
        ACCESS_SETTINGS_COMPANY_PROFILE_PAGE: false,
        EDIT_SEVERE_ALERT_NOTIFICATIONS: false,
        EDIT_WEAK_ALERT_NOTIFICATIONS: false,
        EDIT_SCHEDULE_NOTIFICATIONS: false,
        ACCESS_NOTIFICATIONS_PAGE: false,
        EDIT_SYSTEM_ALERT_NOTIFICATIONS: false,
        EDIT_INACTIVE_STATUS: false,
        ACCESS_MY_PROFILE_PAGE: false,
        EDIT_MEDIUM_ALERT_NOTIFICATIONS: false,
        EDIT_FIELDS: false,
        ACCESS_LANGUAGE_AND_FORMATS_PAGE: false,
      },
      permissions: [
        {
          name: 'Commission scolaire',
          id: 9,
        },
      ],
      administrationPermissions: {
        ACCESS_EVENTS_AND_ALERTS_PAGE: false,
        CREATE_AND_EDIT_FIELDS: false,
        DELETE_ROLES: false,
        ACCESS_GROUPS_PAGE: false,
        CREATE_AND_EDIT: false,
        DELETE_HOMEPORT: false,
        ACCESS_HOMEPORT_PAGE: false,
        DELETE: false,
        ACCESS_ROLES_AND_PERMISSIONS_PAGE: false,
        DELETE_GROUPS: false,
      },
      resourcesRolePermissions: {
        SEE_VEHICLES_INFOS: false,
        CREATE_AND_EDIT_PERSONALIZED_VIEWS: false,
        DELETE_PERSONALIZED_VIEWS: false,
        DELETE_USERS: false,
        CREATE_AND_EDIT_SHARED: false,
        CREATE_AND_EDIT_USERS: false,
        CREATE_AND_EDIT_VEHICLES: false,
        DELETE_SHARED: false,
        ACCESS_TO_RESOURCES_PAGE: false,
        DELETE_VEHICLES: false,
        DELETE_CIRCUITS: false,
        SEE_USERS_INFOS: false,
        SEE_CIRCUITS_INFOS: false,
        CREATE_AND_EDIT_CIRCUITS: false,
      },
    },
  ],
};
