import { FormGroup } from '@angular/forms';

import { vehicleSortFilterStatusEnum } from '../vehicle-sort-filter.enum';

export type objetToCompare<T> = { [key: string]: T };

/**
 * @description Parses a string value to a boolean.
 * @param value - The string value to be parsed.
 * @returns The boolean representation of the string value.
 */
export const parseStringToBoolean = (value: string): boolean => {
  return value === 'true';
};

/**
 * @description Checks if a string value represents a boolean.
 * @param value - The string value to check.
 * @returns True if the value is 'true' or 'false', false otherwise.
 */
export const isBooleanFromString = (value: string): boolean => {
  return value === 'true' || value === 'false';
};

export function isValidKeyFrom<T extends string>(
  field: string,
  form: FormGroup
): field is T {
  return field in form.value;
}

/**
 * @description Checks if a value is present in an enumeration.
 * @param value - The value to check.
 * @param enumeration - The enumeration to check against.
 * @returns A boolean indicating whether the value is present in the enumeration.
 */
export const isValueInEnum = (
  value: string,
  enumeration: typeof vehicleSortFilterStatusEnum
): value is vehicleSortFilterStatusEnum => {
  return Object.values(enumeration).includes(
    value as vehicleSortFilterStatusEnum
  );
};

/**
 * @description Compares two objects and returns the keys and values that are different.
 * @param obj1 - The first object to compare.
 * @param obj2 - The second object to compare.
 * @returns An object containing the keys and values that are different between the two objects.
 */
export function findDifferentKeys<T>(
  obj1: objetToCompare<T>,
  obj2: objetToCompare<T>
): objetToCompare<T> {
  const returnValue: objetToCompare<T> = {};
  for (const key in obj1) {
    if (obj1[key] !== obj2[key]) {
      returnValue[key] = obj2[key];
    }
  }

  return returnValue;
}
