<div
  [class]="'container size-' + size + ' color-' + color"
  [ngClass]="{
    'is-selected': selected,
    'is-indeterminate': indeterminate,
    'is-disabled': disabled
  }"
  *ngIf="type === 'square'"
>
  <input
    #checkboxRef
    [id]="'ad-checkbox-component-' + id"
    type="checkbox"
    [value]="value"
    [disabled]="disabled"
    [indeterminate]="indeterminate"
    (click)="onInputChange()"
    [name]="id"
    [checked]="selected"
  />
  <ad-icon
    *ngIf="selected && !indeterminate"
    class="icon"
    glyph="check"
    color="shades-0"
  ></ad-icon>
  <ad-icon
    *ngIf="indeterminate && !selected"
    class="icon"
    glyph="minus"
    color="shades-0"
  ></ad-icon>
</div>
<input
  [id]="'ad-checkbox-component-' + id"
  *ngIf="type === 'circle'"
  type="radio"
  [value]="value"
  [class]="'circle size-' + size + ' color-' + color"
  [disabled]="disabled"
  [attr.checked]="selected"
  [name]="name"
  (click)="onInputChange()"
/>
<label
  *ngIf="text.length"
  (click)="handleInputChange()"
  [ngClass]="{
    'text-caption': size === 'sm',
    'text-p3': size === 'md',
    'is-disabled': disabled
  }"
>
  {{ text }}
</label>

<div (click)="handleInputChange()" class="content-projected-label">
  <ng-content select="[customCheckBoxText]"> </ng-content>
</div>
