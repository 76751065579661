import { Component, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';

import { menuActions, showMenuFeature } from '../../../store/';

@Component({
  selector: 'astus-menu-overlay',
  templateUrl: './menu-overlay.component.html',
  styleUrls: ['./menu-overlay.component.scss'],
})
export class MenuOverlayComponent {
  showMenu!: boolean;
  constructor(private store: Store) {
    this.store.select(showMenuFeature).subscribe((showMenu) => {
      this.showMenu = showMenu;
    });
  }

  closeMenu() {
    this.store.dispatch(menuActions.closeMenu());
  }

  @HostListener('click')
  onclick() {
    this.closeMenu();
  }
}
