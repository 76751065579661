<button
  class="create-vehicle-button"
  astus-design-button
  kind="filled"
  color="primary"
  size="sm"
  [disabled]="isInfoBoxOpen || isEditMode"
  (click)="openInfobox()"
>
  {{ buttonTranslationKey | translate }}
</button>

<!-- Input not visible to handle the text/csv file import -->
<input
  type="file"
  #fileInput
  class="input-file"
  (change)="onCSVFileChange($event)"
  accept="text/csv"
/>
