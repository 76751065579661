import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

import { DropdownItemInterface } from '@common/ng-design-system';
import { LaunchDarklyService } from '@common/ts-feature-flag';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';

import { buttonProcessStateEnum } from '../settings.enum';
import { settingsGroupInterface } from '../store/settings';
import { settingsAPIActions } from '../store/settings.action';
import { selectGroupsFeature } from '../store/settings.selector';

import { settingsGroupAssignEnum } from './settings-groups.enum';

@Component({
  selector: 'astus-settings-groups',
  templateUrl: './settings-groups.component.html',
  styleUrls: ['./settings-groups.component.scss'],
})
export class SettingsGroupsComponent implements OnInit {
  settingsGroupAssignEnum = settingsGroupAssignEnum;
  form: FormGroup = this.fb.group({
    groups: this.fb.array([]),
    lockedGroups: this.fb.array([]),
  });
  formDefaults: FormGroup = this.fb.group({
    groups: this.fb.array([]),
    lockedGroups: this.fb.array([]),
  });
  buttonEnums = buttonProcessStateEnum;
  processState: buttonProcessStateEnum = this.buttonEnums.READY;
  formChanged = false;
  activeGroups: settingsGroupInterface[] = [];
  groups: settingsGroupInterface[] = [];
  translations: { [key: string]: string } = {};

  modalHandler = {
    open: false,
    group: '',
    index: 0,
  };

  resourcesCircuitFlagValue$ = this.launchDarklyService.getFlagValue$(
    'Ressources_Circuits'
  );

  resourcesVehiclesFlagValue$ = this.launchDarklyService.getFlagValue$(
    'Ressources_Vehicules'
  );

  resourcesUsersFlagValue$ = this.launchDarklyService.getFlagValue$(
    'Ressources_Utilisateurs'
  );

  resourcesEquipmentFlagValue$ = this.launchDarklyService.getFlagValue$(
    'Astus_FMS_Ressources_Equipment'
  );

  resourcesShown: { [key: string]: boolean } = {};

  dropdownGroups: DropdownItemInterface[] = [
    { value: '1', label: settingsGroupAssignEnum.CIRCUITS },
    { value: '2', label: settingsGroupAssignEnum.VEHICLES },
    { value: '3', label: settingsGroupAssignEnum.USERS },
    { value: '4', label: settingsGroupAssignEnum.EQUIPMENTS },
  ];

  dropdownGroupsFiltered: DropdownItemInterface[] = [];

  dropdownYesNo: DropdownItemInterface[] = [
    { value: 1, label: this.translations['yes'] },
    { value: 0, label: this.translations['no'] },
  ];

  @ViewChild('scrollTarget') private addGroupContainer!: ElementRef;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private store: Store,
    private launchDarklyService: LaunchDarklyService
  ) {}

  ngOnInit(): void {
    this.initializeGroupsForm();
    this.setupFormChangeHandler();
    this.initShownResources();
    this.initTranslations();
  }

  /**
   * Getters for the "groups" form controls
   * @returns FormArray
   */
  get formControls() {
    return this.form.get('groups') as FormArray;
  }

  /**
   * Getters for the "groups" form controls
   * @returns FormArray
   */
  get formDefaultsControls() {
    return this.formDefaults.get('groups') as FormArray;
  }

  /**
   * Getters for the "lockedGroups" form controls
   * @returns FormArray
   */
  get lockedFormControls() {
    return this.form.get('lockedGroups') as FormArray;
  }

  /**
   * Getters for the "groups" form controls
   * @returns FormArray
   */
  get lockedFormDefaultsControls() {
    return this.formDefaults.get('lockedGroups') as FormArray;
  }

  /**
   * Fetches the flag values for the resources and sets the shown resources accordingly
   * @returns void
   */
  initShownResources(): void {
    combineLatest([
      this.resourcesCircuitFlagValue$,
      this.resourcesVehiclesFlagValue$,
      this.resourcesUsersFlagValue$,
      this.resourcesEquipmentFlagValue$,
    ]).subscribe((values) => {
      this.resourcesShown = {
        [settingsGroupAssignEnum.CIRCUITS]: values[0],
        [settingsGroupAssignEnum.VEHICLES]: values[1],
        [settingsGroupAssignEnum.USERS]: values[2],
        [settingsGroupAssignEnum.EQUIPMENTS]: values[3],
      };
      this.filterDropdownGroups();
    });
  }

  /**
   * Filters the dropdown groups based on the shown resources (feature flags)
   * @returns void
   */
  filterDropdownGroups(): void {
    this.dropdownGroupsFiltered = this.dropdownGroups.filter((item) => {
      let result = false;

      if (item.label === settingsGroupAssignEnum.CIRCUITS) {
        result = this.resourcesShown[settingsGroupAssignEnum.CIRCUITS];
      } else if (item.label === settingsGroupAssignEnum.VEHICLES) {
        result = this.resourcesShown[settingsGroupAssignEnum.VEHICLES];
      } else if (item.label === settingsGroupAssignEnum.USERS) {
        result = this.resourcesShown[settingsGroupAssignEnum.USERS];
      } else if (item.label === settingsGroupAssignEnum.EQUIPMENTS) {
        result = this.resourcesShown[settingsGroupAssignEnum.EQUIPMENTS];
      }

      return result;
    });
  }

  /**
   * Initializes the translations for the groups
   * @returns void
   */
  initTranslations(): void {
    combineLatest([
      this.translate.stream('SETTINGS_GROUPS.GROUPS.CIRCUITS'),
      this.translate.stream('SETTINGS_GROUPS.GROUPS.VEHICLES'),
      this.translate.stream('SETTINGS_GROUPS.GROUPS.USERS'),
      this.translate.stream('SETTINGS_GROUPS.GROUPS.EQUIPMENTS'),
      this.translate.stream('SETTINGS_GROUPS.ALL'),
      this.translate.stream('SETTINGS_GROUPS.YES'),
      this.translate.stream('SETTINGS_GROUPS.NO'),
    ]).subscribe((translations) => {
      this.translations = {
        [settingsGroupAssignEnum.CIRCUITS]: translations[0],
        [settingsGroupAssignEnum.VEHICLES]: translations[1],
        [settingsGroupAssignEnum.USERS]: translations[2],
        [settingsGroupAssignEnum.EQUIPMENTS]: translations[3],
        all: translations[4],
        yes: translations[5],
        no: translations[6],
      };

      this.initializeYesNoDropdown();
    });
  }

  initializeYesNoDropdown(): void {
    this.dropdownYesNo = [
      { value: 1, label: this.translations['yes'] },
      { value: 0, label: this.translations['no'] },
    ];
  }

  /**
   * Initializes the groups form with resets and data from the store
   * @returns void
   */
  initializeGroupsForm(): void {
    this.form.reset();
    this.formDefaults.reset();

    this.store.select(selectGroupsFeature).subscribe((groups) => {
      this.groups = [...groups];
      this.initGroupsHandlers();
      this.setupFormChangeHandler();
    });
  }

  /**
   * Initializes the form controls for the groups
   * @returns void
   */
  initGroupsHandlers(): void {
    this.lockedFormControls.clear();
    this.lockedFormDefaultsControls.clear();
    this.formControls.clear();
    this.formDefaultsControls.clear();

    this.groups.forEach((group) => {
      if (
        group.active &&
        !this.activeGroups.some((activeGroup) => activeGroup.id === group.id)
      ) {
        this.activeGroups.push(group);
      }

      const control = {
        id: [group.id],
        active: [group.active],
        locked: [group.locked],
        group: [
          { value: group.group, disabled: group.locked },
          Validators.required,
        ],
        // Sorting assignees allows for easier comparison later, and keeps consistent labels on the dropdown placeholder.
        assignees:
          group.assignees.length === 0
            ? this.fb.array(Object.values(settingsGroupAssignEnum).sort())
            : this.fb.array([...group.assignees].sort()),
        sharable: [{ value: group.sharable, disabled: group.locked }],
      };

      if (group.locked) {
        this.lockedFormControls.push(this.fb.group(control));
        this.lockedFormDefaultsControls.push(this.fb.group(control));
      } else {
        this.formControls.push(this.fb.group(control));
        this.formDefaultsControls.push(this.fb.group(control));
      }
    });
  }

  /**
   * Sets up the form change handler to check for changes in the form
   * @returns void
   */
  setupFormChangeHandler(): void {
    this.form.valueChanges.subscribe(() => {
      this.checkFormChanges();
    });
  }

  /**
   * Checks for changes in the form and marks the form as dirty if changes are found
   * It compares the initial form values with the current form values
   * @param forceDirty - A boolean indicating whether to force the form to be marked as dirty.
   * @returns void
   */
  checkFormChanges(forceDirty?: boolean): void {
    if (this.form.value.groups) {
      this.formChanged = this.form.value.groups.some(
        (value: object, index: number) =>
          this.subgroupChanged(value as FormGroup, index)
      );
    }

    this.formChanged || forceDirty
      ? this.form.markAsDirty()
      : this.form.markAsPristine();
  }

  /**
   * Checks if a subgroup has changed
   * @param changes FormGroup
   * @param key number
   * @returns boolean
   */
  subgroupChanged(changes: FormGroup, key: number): boolean {
    const initial = JSON.stringify(this.formDefaults.value.groups[key]);
    const current = JSON.stringify(changes);
    return initial !== current;
  }

  /**
   * Toggles the active state of a group
   * @param index number
   * @param active boolean
   */
  activeToggle(index: number, active: boolean): void {
    const activeControl = this.formControls.controls[index].get('active');
    activeControl?.setValue(active);
    this.activeGroups = this.activeGroupsCount;
  }

  /**
   * Returns the number of active groups in both the locked and unlocked form controls
   * @returns number
   */
  get activeGroupsCount(): settingsGroupInterface[] {
    const activeGroups: settingsGroupInterface[] = [];

    this.formControls.controls.forEach((control) => {
      if (control.get('active')?.value) activeGroups.push(control.value);
    });

    this.lockedFormControls.controls.forEach((control) => {
      if (control.get('active')?.value) activeGroups.push(control.value);
    });

    return activeGroups;
  }

  /**
   * Manages the resources toggles
   * @param index number
   * @param checked boolean
   * @param value string
   * @returns void
   */
  assigneeChanged(index: number, checked: boolean, value: string) {
    const assigneesControl = this.formControls.controls[index].get('assignees');

    if (!assigneesControl) {
      return;
    }

    const assignees = assigneesControl.value;

    if (checked && !assignees.includes(value)) {
      assignees.push(value);
    }

    if (!checked && assignees.includes(value)) {
      const indexToRemove = assignees.indexOf(value);
      assignees.splice(indexToRemove, 1);
    }

    assignees.sort();
    this.checkFormChanges();
  }

  /**
   * Process and creates the text to be shown in the dropdown placeholder
   * Checks the value, compares it the the flags values and returns the translated text
   * @param index number
   * @param locked boolean
   * @returns string
   */
  createAssigneeText(index: number, locked = false): string {
    const assignees: string[] = locked
      ? this.lockedFormControls.controls[index].get('assignees')?.value
      : this.formControls.controls[index].get('assignees')?.value;

    const filteredAssignees = assignees.filter((assignee: string) => {
      if (assignee === settingsGroupAssignEnum.CIRCUITS) {
        return this.resourcesShown[settingsGroupAssignEnum.CIRCUITS];
      } else if (assignee === settingsGroupAssignEnum.VEHICLES) {
        return this.resourcesShown[settingsGroupAssignEnum.VEHICLES];
      } else if (assignee === settingsGroupAssignEnum.USERS) {
        return this.resourcesShown[settingsGroupAssignEnum.USERS];
      } else if (assignee === settingsGroupAssignEnum.EQUIPMENTS) {
        return this.resourcesShown[settingsGroupAssignEnum.EQUIPMENTS];
      } else {
        return false;
      }
    });

    const translatedAssignees = filteredAssignees.map((assignee: string) => {
      let translation = '';

      if (assignee === settingsGroupAssignEnum.CIRCUITS) {
        translation = this.translations[settingsGroupAssignEnum.CIRCUITS];
      } else if (assignee === settingsGroupAssignEnum.VEHICLES) {
        translation = this.translations[settingsGroupAssignEnum.VEHICLES];
      } else if (assignee === settingsGroupAssignEnum.USERS) {
        translation = this.translations[settingsGroupAssignEnum.USERS];
      } else if (assignee === settingsGroupAssignEnum.EQUIPMENTS) {
        translation = this.translations[settingsGroupAssignEnum.EQUIPMENTS];
      }

      return translation;
    });

    if (filteredAssignees.length === this.dropdownGroupsFiltered.length) {
      return this.translations['all'];
    }
    if (filteredAssignees.length > 0) {
      return translatedAssignees.join(', ');
    }
    return '';
  }

  /**
   * Handles the "drag and drop" drop event of the groups
   * @param event CdkDragDrop<string[]>
   * @returns void
   */
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.formControls.value,
      event.previousIndex,
      event.currentIndex
    );

    moveItemInArray(
      this.formControls.controls,
      event.previousIndex,
      event.currentIndex
    );

    this.checkFormChanges();
  }

  /**
   * Adds a new group to the form with default values
   * @returns void
   */
  addGroup() {
    this.formControls.push(
      this.fb.group({
        id: [''],
        active: [false],
        locked: [false],
        group: ['', Validators.required],
        assignees: this.fb.array(Object.values(settingsGroupAssignEnum).sort()),
        sharable: [1],
      })
    );

    setTimeout(() => {
      this.addGroupContainer.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }, 0);

    this.checkFormChanges();
  }

  /**
   * Removes a group from the form
   * @returns void
   */
  removeGroup() {
    const index = this.modalHandler.index;
    const forceDirty = true;
    this.formControls.removeAt(index);
    this.closeRemoveGroupModal();
    this.checkFormChanges(forceDirty);
  }

  /**
   * Opens the modal to remove a specific group
   * @param index number
   * @param name string
   * @returns void
   */
  openRemoveGroupModal(index: number, name: string) {
    this.modalHandler = {
      open: true,
      group: name,
      index: index,
    };
  }

  /**
   * Closes the modal to remove a specific group
   * @returns void
   */
  closeRemoveGroupModal() {
    this.modalHandler = {
      open: false,
      group: '',
      index: 0,
    };
  }

  /**
   * Saves the form and updates the store with the new values
   * Also fakes a timeout to simulate the API call
   * @returns void
   */
  saveForm(): FormGroup | void {
    const testTimeout = 3000;
    const testError = false;

    this.processState = this.buttonEnums.PROCESSING;

    // Should not be possible to submit when form is invalid but just in case
    if (this.form.invalid) {
      this.processState = this.buttonEnums.ERROR;
      return;
    }

    const saveData: settingsGroupInterface[] = [];

    this.lockedFormControls.controls.forEach((control) => {
      saveData.push(control.getRawValue());
    });
    this.formControls.controls.forEach((control) => {
      saveData.push(control.value);
    });
    this.form.disable();

    this.store.dispatch(
      settingsAPIActions.groupsUpdate({ groupsList: saveData })
    );

    setTimeout(() => {
      if (testError) {
        this.processState = this.buttonEnums.ERROR;
        return;
      }

      try {
        this.groups = saveData;
      } catch (err) {
        this.processState = this.buttonEnums.ERROR;
      }

      this.processState = this.buttonEnums.SUCCESS;

      return saveData;
    }, testTimeout);
  }

  /**
   * Resets the form to the initial values
   * @returns void
   */
  resetForm() {
    this.formControls.clear();
    this.lockedFormControls.clear();
    this.formDefaultsControls.clear();
    this.lockedFormDefaultsControls.clear();
    this.activeGroups = [];
    this.formChanged = false;
    this.processState = this.buttonEnums.READY;
    this.initializeGroupsForm();
    this.checkFormChanges();
    this.form.enable();
  }
}
