<div [ngClass]="containerClasses">
  <span [ngClass]="progressBarClasses" [ngStyle]="progressBarWidth"> </span>
  <span
    [ngClass]="navigationClasses"
    adProgressBarNavigation
    [adNavigationProgress]="progressCap"
  >
    <ad-icon
      class="navigation-icon"
      glyph="navigation-filled"
      color="shades-0"
    ></ad-icon>
  </span>
</div>
