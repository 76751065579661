import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';

@Component({
  selector: 'ad-weekday-picker',
  templateUrl: './weekday-picker.component.html',
  styleUrls: ['./weekday-picker.component.scss'],
})
export class WeekdayPickerMolecule implements OnChanges {
  @Input()
  daysValue: string[] = [];
  formattedDays: string[] = this.formatDays(this.daysValue);

  @Input()
  selectedDays: number[] = [];

  @Output()
  selectedDaysChange = new EventEmitter<number[]>();

  ngOnChanges(): void {
    this.formattedDays = this.formatDays(this.daysValue);
  }

  /**
   * @param index
   * @returns void
   * @description
   * This function is used to toggle the selected day
   * */
  toggleDay(index: number) {
    if (this.selectedDays.includes(index)) {
      this.selectedDays = this.selectedDays.filter((day) => day !== index);
    } else {
      this.selectedDays = [...this.selectedDays, index];
    }

    this.selectedDaysChange.emit(this.selectedDays);
  }

  /**
   * @param days
   * @returns string[]
   * @description
   * This function is used to format the days
   * */
  formatDays(days: string[]): string[] {
    return days.map((day) => day.slice(0, 1));
  }

  /**
   * @param index
   * @returns number
   * @description
   * This function is used to track the index of the selected day
   * */
  trackByFn(index: number): number {
    return index;
  }
}
