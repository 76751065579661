import { Component, Input } from '@angular/core';
import { VehicleStateType } from '@fms/ng-fms-api-client';

export type AgendaItemElement = {
  title: string;
  status: VehicleStateType;
};

@Component({
  selector: 'astus-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss'],
})
export class AgendaComponent {
  @Input() agendaItemsElement!: AgendaItemElement[];
}
