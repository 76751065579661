<div class="vehicle-sort-filter-container">
  <div class="filter-fab-container">
    <button
      ad-fab
      class="vehicle-sort-filter-button"
      kind="tonal"
      glyph="sort"
      color="primary"
      size="sm"
      (click)="openFilters()"
    ></button>
    <ad-badge
      *ngIf="filterCount > 0"
      [digit]="filterCount"
      class="vehicle-sort-filter-badge"
    ></ad-badge>
  </div>

  <dialog
    class="overlay-container"
    *ngIf="showFilters"
    [ngClass]="{ 'is-open': showFilters }"
    @dropdownAnimation
  >
    <header class="header-container">
      <div class="title--semibold">
        {{ 'FILTER_HEADER.TITLE' | translate }}
      </div>
      <div class="title--regular">
        {{ 'FILTER_HEADER.SUBTITLE' | translate }}
      </div>
    </header>
    <div class="content-container">
      <fieldset [formGroup]="filterForm">
        <legend class="divider text-caption--semibold">
          {{ 'DIVIDER.SORT_BY' | translate }}
        </legend>
        <div class="content">
          <div class="sorting-container">
            <ad-radio
              [text]="'SORT_BY.ASCENDANT' | translate"
              formControlName="severity"
              [value]="vehicleSortFilterStatusEnum.SEVERITYHIGHTOLOW"
              [selectedValue]="filterForm.controls['severity'].value"
            ></ad-radio>
            <ad-radio
              [text]="'SORT_BY.DESCENDENT' | translate"
              formControlName="severity"
              [value]="vehicleSortFilterStatusEnum.SEVERITYLOWTOHIGH"
              [selectedValue]="filterForm.controls['severity'].value"
            ></ad-radio>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <legend class="divider text-caption--semibold">
          {{ 'DIVIDER.FILTER_BY' | translate }}
        </legend>

        <div class="filters">
          <astus-multi-select-dropdown
            [title]="'LABEL.STATUS.TITLE' | translate"
            [list]="statusList"
            [formGroup]="statusForm"
          ></astus-multi-select-dropdown>
          <astus-multi-select-dropdown
            [title]="'LABEL.ALERT_TYPE.TITLE' | translate"
            [list]="alertTypeList"
            [formGroup]="alertTypeForm"
          ></astus-multi-select-dropdown>
          <astus-multi-select-dropdown
            [title]="'LABEL.GROUP.TITLE' | translate"
            [list]="GroupList"
            [formGroup]="groupForm"
          ></astus-multi-select-dropdown>
          <astus-multi-select-dropdown
            [title]="'LABEL.TYPE.TITLE' | translate"
            [list]="typeList"
            [formGroup]="typeForm"
          ></astus-multi-select-dropdown>
        </div>
      </fieldset>
    </div>
    <ad-separator class="separator" borderColor="neutral-200"></ad-separator>
    <footer class="footer-container">
      <button
        class="cta"
        astus-design-button
        kind="outlined"
        color="neutral"
        [disabled]="filterCount === 0"
        size="xs"
        (click)="resetValues()"
      >
        {{ 'CTA.RESET' | translate }}
      </button>
      <button
        class="cta"
        astus-design-button
        kind="filled"
        color="primary"
        size="xs"
        (click)="onApplyFilter()"
      >
        {{ 'CTA.APPLY' | translate }}
      </button>
    </footer>
  </dialog>
</div>
