import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ColorType,
  popoverOptionType,
  progressInfoStatusEnum,
} from '@common/ng-design-system';

import {
  controlPanelCardAllDataType,
  controlPanelCardDataRoutesInterface,
  controlPanelCardDataStopsInterface,
} from '../../../map-control-panel/map-control-panel.component.d';

@Component({
  selector: 'astus-route-monitoring-card',
  templateUrl: './route-monitoring-card.component.html',
  styleUrls: ['./route-monitoring-card.component.scss'],
})
export class RouteMonitoringCardComponent {
  @Input() previousData?: controlPanelCardAllDataType;
  @Input() isLoading = false;

  // Info card stuff
  @Input() title = '';
  @Input() titleSmall = '';
  @Input() subtitle = '';
  @Input() subtitleSmall = '';
  @Input() infoUser = '';
  @Input() infoLocation = '';

  @Input() progressPercentage: number | undefined;
  @Input() progressTime = '';
  @Input() progressStatus: progressInfoStatusEnum = progressInfoStatusEnum.LOW;

  @Input() stops: controlPanelCardDataStopsInterface[] = [];

  @Input() moreOptions: popoverOptionType[] = [];

  @Output()
  backClickEvent = new EventEmitter<controlPanelCardDataRoutesInterface>();

  @Output()
  moreOptionsEvent = new EventEmitter<boolean>();

  @Output()
  stopSelectClickEvent = new EventEmitter<controlPanelCardDataStopsInterface>();

  showMoreOptions = false;

  /**
   * @description
   * Color for the progress bar based on the data provided.
   * Progress status is used to determine the color.
   * @returns ColorType
   */
  public get progressType(): ColorType {
    switch (this.progressStatus) {
      case 'low':
      case 'medium':
        return 'warning';
      case 'high':
        return 'danger';
      default:
        return 'primary';
    }
  }

  /**
   * @description
   * On click of the back button, emit the event to the parent component.
   * @returns {void}
   */
  onBackClick(): void {
    this.backClickEvent.emit(this.previousData);
  }

  /**
   * @description
   * Select more options in the card.
   */
  optionSelected() {
    // TODO Add logic to change the display in the infobox to edit
  }

  /**
   * @description
   * On click select stop, emit the event to the parent component.
   * @param {controlPanelCardDataStopsInterface} stop
   * @returns {void}
   */
  selectStop(stop: controlPanelCardDataStopsInterface): void {
    this.stopSelectClickEvent.emit(stop);
  }

  /**
   * @description
   * On click of the more options, emit the event to the parent component.
   * @returns {void}
   */
  moreOptionsClick(): void {
    this.moreOptionsEvent.emit();
  }
}
