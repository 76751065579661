import { alertInterface } from '@common/ng-design-system';
import { VehicleView } from '@fms/ng-fms-api-client';
import { createActionGroup, props, emptyProps } from '@ngrx/store';

import { snackbarInterface } from '../notifications/alert-snackbar/snackbar';

import {
  userData,
  notificationInterface,
  notificationSettingsInterface,
  permissionsInterface,
  globalReducerErrorType,
} from './global';

export const menuActions = createActionGroup({
  source: 'Menu',
  events: {
    'Close menu': emptyProps(),
    'Toggle menu': props<{ showMenu: boolean }>(),
  },
});

export const dateActions = createActionGroup({
  source: 'Date',
  events: {
    'Set date': props<{ date: Date | Date[] }>(),
    'Set hours timeline first picker': props<{ firstHourSelection: string }>(),
    'Set hours timeline second picker': props<{
      secondHourSelection: string;
    }>(),
    'Set timeline live status': props<{
      timelineLiveStatus: boolean;
    }>(),
  },
});

export const userActions = createActionGroup({
  source: 'Menu',
  events: {
    'Save user data': props<{ user: userData }>(),
    'Save user language and formats': props<{
      languageAndFormats: Pick<
        userData,
        | 'language'
        | 'unitSystem'
        | 'dateFormat'
        | 'durationFormat'
        | 'hoursFormat'
      >;
    }>(),
    'Save user notifications settings': props<{
      notificationsSettings: notificationSettingsInterface;
    }>(),
    'Save user permissions': props<{
      permissions: permissionsInterface;
    }>(),
    'Save user columns': props<{
      resourcesVehicleColumns: { dataKey: string; selected: boolean }[];
    }>(),
  },
});

export const alertActions = createActionGroup({
  source: 'Notification',
  events: {
    'Show new alert': props<{ alert: alertInterface }>(),
    'Show new snackbar': props<{ snack: snackbarInterface }>(),
  },
});

export const notificationActions = createActionGroup({
  source: 'Notification',
  events: {
    'Mark all as read': emptyProps(),
    'Get notifications': props<{ notifications: notificationInterface[] }>(),
  },
});

export const reportsActions = createActionGroup({
  source: 'Reports',
  events: {
    'Get vehicles load': emptyProps(),
    'Get vehicles loaded': props<{ vehicles: VehicleView[] }>(),
    'Get vehicles error': props<globalReducerErrorType>(),
  },
});
