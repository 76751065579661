import { CountryAndSubdivision } from '../models/user-form-country-and-subdivision.models';

export const ActiveCountriesAndSubdivisions: CountryAndSubdivision[] = [
  {
    country: { alpha: 'CA' },
    subdivision: { code: 'CA-QC' },
    default: true,
  },
  {
    country: { alpha: 'US' },
    subdivision: { code: 'US-NY' },
  },
];
