import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

import { IconAtomGlyphType } from '../../atoms';
import { tooltipKindType } from '../tooltip/tooltip';

import { cardInfoItemInterface } from './card-info';

@Component({
  selector: 'ad-card-info',
  templateUrl: './card-info.component.html',
  styleUrls: ['./card-info.component.scss'],
})
export class CardInfoMolecule {
  @Input()
  isSkeleton = false;

  @Input() hideAvatars = false;
  @Input() firstAvatarText = '';
  @Input() firstAvatarTooltip = this.firstAvatarText;
  @Input() firstAvatarFallbackGlyph: IconAtomGlyphType = 'profile';
  @Input() secondAvatarText = '';
  @Input() secondAvatarTooltip = this.secondAvatarText;
  @Input() secondAvatarFallbackGlyph: IconAtomGlyphType = 'profile';

  @Input() tooltipPosition: tooltipKindType = 'top';

  @Input() title = '';
  @Input() titleSmall = '';
  @Input() subtitle = '';
  @Input() subtitleSmall = '';

  @Input() infoItems: cardInfoItemInterface[] = [];

  @Output() clickCardEvent = new EventEmitter();

  @HostListener('click')
  onClickCard() {
    this.clickCardEvent.emit();
  }
}
