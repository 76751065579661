import { Component, Input } from '@angular/core';

@Component({
  selector: 'astus-settings-roles-card',
  templateUrl: './settings-roles-card.component.html',
  styleUrls: ['./settings-roles-card.component.scss'],
})
export class SettingsRolesCardComponent {
  @Input() id: number | undefined = undefined;

  @Input() title = '';
  @Input() description = '';
  @Input() assocUsers: string[] = [];
}
