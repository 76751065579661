import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ColorType } from '../../common-ng-design-system.colors';

@Component({
  selector: 'ad-radio',
  template: `
    <label
      [ngClass]="{
        'text-caption': size === 'sm',
        'text-p3': size === 'md',
        'is-disabled': disabled
      }"
    >
      <input
        [id]="name"
        type="radio"
        [value]="value"
        [class]="'size-' + size + ' color-' + color"
        [ngClass]="{ 'is-selected': selected }"
        (input)="onInputChange($event)"
        [disabled]="disabled"
        [attr.checked]="selected ? 'checked' : null"
        [name]="name"
      />
      <span>{{ text }}</span>
    </label>
  `,
  styleUrls: ['./radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: RadioMolecule,
    },
  ],
})
export class RadioMolecule implements ControlValueAccessor, OnChanges {
  @Input()
  text = 'A single-select checkbox';

  @Input()
  disabled = false;

  @Input()
  selected = false;

  @Input()
  name = 'radio';

  @Input()
  value = 'value';

  @Input()
  selectedValue: string | null = null;

  @Input()
  size = 'md';

  @Input()
  color: ColorType = 'primary';

  @Output() radioEvent = new EventEmitter<boolean>();

  // Had put a default empty function here, it was causing a lint error
  // eslint-disable-next-line
  onChange = (inputValue: string) => {};

  // Had put a default empty function here, it was causing a lint error
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  touchedValue = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedValue']) {
      this.writeValue(changes['selectedValue'].currentValue);
    }
  }

  /**
   * This function is called when the user changes the value of the input.
   * It emits the new value of the input.
   * @param event The change event.
   */
  onInputChange(event: Event): void {
    this.selected = (event.target as HTMLInputElement).checked;
    this.radioEvent.emit(this.selected);
    this.onChange(this.value);
    this.markAsTouched();
  }

  writeValue(currValue: string) {
    this.selected = currValue === this.value;
  }

  // I need to use "any" here because the Angular function literally uses "any" as the type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  // I need to use "any" here because the Angular function literally uses "any" as the type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touchedValue) {
      this.onTouched();
      this.touchedValue = true;
    }
  }

  setDisabledState(disabledValue: boolean) {
    this.disabled = disabledValue;
  }
}
