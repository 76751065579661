import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'astus-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent implements OnInit {
  @Input() placeholder = '';

  @Input() value: string | null = null;

  @Output() searchEmitter = new EventEmitter<string>();

  @ViewChild('searchInput') searchInput: ElementRef = new ElementRef(null);

  @HostBinding('style.--search-input-width') width =
    'calc(' + this.placeholder.length + 'ch + 24px + 5px)';

  ngOnInit(): void {
    this.width = 'calc(' + this.placeholder.length + 'ch + 24px + 5px)';
  }

  search(e: Event) {
    this.searchEmitter.emit((e.target as HTMLInputElement).value);
  }

  clearSearch() {
    this.searchInput.nativeElement.value = '';
    this.searchInput.nativeElement.focus();
    this.searchEmitter.emit('');
  }
}
