import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { DateFiltersType, IconAtomGlyphType } from '@common/ng-design-system';
import { DropdownItemInterface } from '@common/ng-design-system';
import { CircuitsService } from '@fms/ng-fms-api-client';
import { Store } from '@ngrx/store';

import { snackbarContentInterface } from '../notifications/alert-snackbar/snackbar';
import { dateFormatEnum } from '../pipes/dateformat/dateformat.enum';
import { alertActions, unitSystemEnum } from '../store';

@Component({
  selector: 'astus-test-content',
  templateUrl: './test-content.component.html',
  styleUrls: ['./test-content.component.scss'],
})
export class TestContentComponent {
  dateFormatEnum = dateFormatEnum;
  unitSystemEnum = unitSystemEnum;
  testForm = this.fb.group({
    testInput: [
      { value: '', disabled: false },
      [Validators.required, Validators.minLength(1)],
    ],
    testRadio: [{ value: '111', disabled: false }],
    testDropdown: [{ value: null, disabled: false }, [Validators.required]],
  });

  testDropdownValues: DropdownItemInterface[] = [
    { value: '1', label: 'test 1' },
    { value: '2', label: 'test 2' },
    { value: '3', label: 'test 3' },
    { value: '4', label: 'test 4' },
    { value: '5', label: 'test 5' },
    { value: '6', label: 'test 6' },
    { value: '7', label: 'test 7' },
    { value: '8', label: 'test 8' },
    { value: '9', label: 'test 9' },
    { value: '10', label: 'test 10' },
    { value: '11', label: 'test 11' },
    { value: '12', label: 'test 12' },
    { value: '13', label: 'test 13' },
    { value: '14', label: 'test 14' },
    { value: '15', label: 'test 15' },
  ];

  constructor(
    private circuitsService: CircuitsService,
    private auth: AuthService,
    @Inject(DOCUMENT) private doc: Document,
    private router: Router,
    private fb: FormBuilder,
    private store: Store
  ) {}

  date = new Date();

  dateRangeFilter = [
    { range: 'today', label: "Aujourd'hui" },
    { range: 'week', label: 'Semaine dernière' },
    { range: 'month', label: 'Mois dernier' },
    { range: 'year', label: 'Année dernière' },
  ] as DateFiltersType[];

  getNotFoundError() {
    this.router.navigate(['/not-found']);
  }

  tokenInterceptor() {
    this.circuitsService.circuitsControllerFindAll().subscribe(() => {
      ('');
    });
  }

  goToMap() {
    this.router.navigate(['/supervision']);
  }

  handleLogout(): void {
    this.auth.logout({
      logoutParams: {
        returnTo: this.doc.location.origin,
      },
    });
  }

  testAlert(): void {
    const testMessages = [
      'Test alert',
      'This is a test alert',
      'This is a test alert with a very long message yes yes indeed',
      '1, 2, 1, 2, patate pouelle',
    ];

    const testIcons: IconAtomGlyphType[] = ['info', 'alert', 'bus', 'time'];

    this.store.dispatch(
      alertActions.showNewAlert({
        alert: {
          title: testMessages[Math.floor(Math.random() * testMessages.length)],
          icon: testIcons[Math.floor(Math.random() * testIcons.length)],
          colorType: 'success',
        },
      })
    );
  }

  testSnackbar(): void {
    const testSnackbarContent: snackbarContentInterface[] = [
      {
        title: 'Circuit 00',
        message: 'Traffic dense.Retard 15min',
        url: 'map/circuits/0',
      },
      {
        title: 'Ciruit 00',
        message: 'Bris de service majeur.Autobus 45',
      },
      {
        title: 'Circuit 00',
        message: 'Bris de service mineur. Autobus 23',
        url: 'map/circuits/0',
      },
    ];

    this.store.dispatch(
      alertActions.showNewSnackbar({
        snack: {
          content:
            testSnackbarContent[
              Math.floor(Math.random() * testSnackbarContent.length)
            ],
          kind: 'system',
          actionType: 'simple',
          singleLine: true,
        },
      })
    );
  }
}
