import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'astus-map-ui',
  templateUrl: './map-ui.component.html',
  styleUrls: ['./map-ui.component.scss'],
})
export class MapUiComponent {
  @Input() isLayerDefault = true;
  @Input() isLayerSatellite = false;
  @Input() isLayerTraffic = false;
  @Input() isLayerBike = false;
  @Input() isLayerOutdoors = false;
  @Input() isResetDisabled = false;

  @Output() zoomIn = new EventEmitter();
  @Output() zoomOut = new EventEmitter();
  @Output() resetClick = new EventEmitter();

  @Output() setDefaultLayer = new EventEmitter();
  @Output() setSatelliteLayer = new EventEmitter();
  @Output() setTrafficLayer = new EventEmitter();
  @Output() setBikeLayer = new EventEmitter();
  @Output() setOutdoorsLayer = new EventEmitter();

  isLayerPreviewShown = false;
  isLayerSelectorShown = false;

  isMobile = window.innerWidth < 600;

  constructor(private el: ElementRef) {}

  handleShowLayerPreview() {
    if (!this.isLayerPreviewShown && !this.isLayerSelectorShown) {
      this.isLayerPreviewShown = true;
    } else {
      this.isLayerPreviewShown = false;
      this.isLayerSelectorShown = false;
    }
  }

  handleShowLayerSelector() {
    this.isLayerPreviewShown = false;
    this.isLayerSelectorShown = true;
  }

  closeLayorSelector() {
    this.isLayerPreviewShown = false;
    this.isLayerSelectorShown = false;
  }

  handleZoomIn() {
    this.zoomIn.emit();
  }

  handleZoomOut() {
    this.zoomOut.emit();
  }

  handleReset() {
    this.resetClick.emit();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobile = window.innerWidth < 600;
    const uiContainerStyle = window.getComputedStyle(this.el.nativeElement);
    const uiContainerHeight = uiContainerStyle.getPropertyValue('height');
    this.el.nativeElement.style.setProperty(
      '--ui-container-height',
      uiContainerHeight
    );
  }
}
