import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChipClickEvent } from '@common/ng-design-system';

export type ResourceGroup = {
  id: string;
  name: string;
};
@Component({
  selector: 'astus-resource-group-input',
  templateUrl: './resource-group-input.component.html',
  styleUrls: ['./resource-group-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ResourceGroupInputComponent),
    },
  ],
})
export class ResourceGroupInputComponent implements ControlValueAccessor {
  @Input()
  public label = '';

  @Input()
  public placeholder = '';

  @ViewChild('groupInput')
  groupInput!: ElementRef<HTMLInputElement>;

  public resourceGroup: ResourceGroup[] = [];

  private chipIterator = 0;

  private touchedValue = false;

  constructor(private ref: ChangeDetectorRef) {}

  onChipRemove(event: ChipClickEvent): void {
    this.resourceGroup = this.resourceGroup.filter(
      (group) => group.id !== event.id
    );

    this.onChange(this.resourceGroup);
    this.markAsTouched();
    this.ref.detectChanges();
  }

  onKeyPress(event: KeyboardEvent | Event): void {
    const { value } = this.groupInput.nativeElement;
    // handle empty search value
    if (value.length > 0 && value.trim() !== '') {
      event.preventDefault();
      this.resourceGroup.push({
        id: `${this.chipIterator}`,
        name: value,
      });
      this.chipIterator++;

      this.onChange(this.resourceGroup);
      this.markAsTouched();
      this.ref.detectChanges();

      this.groupInput.nativeElement.value = '';
    }
  }

  // Had put a default empty function here, it was causing a lint error
  // eslint-disable-next-line
  onChange = (_inputValue: ResourceGroup[]) => {};

  // Had put a default empty function here, it was causing a lint error
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  writeValue(currValue: ResourceGroup[]) {
    this.resourceGroup = currValue;
    this.ref.detectChanges();
  }

  // I need to use "any" here because the Angular function literally uses "any" as the type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  // I need to use "any" here because the Angular function literally uses "any" as the type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touchedValue) {
      this.onTouched();
      this.touchedValue = true;
    }
    this.ref.detectChanges();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDisabledState(disabledValue: boolean) {
    // for now not used, need to be implemented if needed
    this.ref.detectChanges();
  }
}
