import { userTableInterface } from '@common/ng-design-system';
import {
  CircuitView,
  UpsertVehicleDto,
  InfoboxVehicleView,
  VehicleDto,
} from '@fms/ng-fms-api-client';

import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { listPagination, resourcesErrorState } from './resource';

export const resourceActions = createActionGroup({
  source: 'Resource',
  events: {
    // Vehicle actions
    'Get all vehicles': emptyProps,
    'Vehicles loaded': props<{ vehicles: VehicleDto[] }>(),
    'Vehicles error': props<{
      error: {
        message: string;
        error: unknown;
      } | null;
    }>(),
    'Vehicle clear error': emptyProps,
    'Create vehicle': props<{
      vehicle: UpsertVehicleDto;
    }>(),
    'Find all infobox vehicle load': props<{ id?: string }>(),
    'Find all infobox vehicle loaded': props<{
      vehicleFormDropdowns: InfoboxVehicleView;
    }>(),
    'Update vehicle infobox': props<{
      vehicle: UpsertVehicleDto;
    }>(),
    'Reset vehicle infobox': emptyProps,
    'Update vehicle': props<{ id: string; vehicle: UpsertVehicleDto }>(),

    // User actions
    'Update user infobox': props<{
      user: userTableInterface;
    }>(),
    'Create user': props<{
      user: userTableInterface;
    }>(),
    'Reset user infobox': emptyProps,
    'Update user information': props<{
      user: userTableInterface;
    }>(),
    'Disable user': props<{
      user: userTableInterface;
    }>(),
    'Open infobox panel': emptyProps,
    'Close infobox panel': emptyProps,

    // Circuit actions
    'Get all circuits': emptyProps,
    'Circuits loaded': props<{
      circuits: CircuitView[];
    }>(),
    'Circuits error': props<{
      error: resourcesErrorState;
    }>(),
    'Import geobus circuit': props<{
      filename: string;
      base64Data: string;
    }>(),
    'Circuit clear error': emptyProps,
    'Reset circuits': emptyProps,
  },
});
