<div class="live-container">
  <!-- Live button in no mobile mode -->
  <button
    astus-design-button
    kind="text"
    color="resolve"
    theme="light"
    class="live-item"
    [ngClass]="{
      selected: timelineLiveStatus
    }"
    (click)="onTimelineStatusChange(true)"
    *ngIf="!isMobile"
  >
    <ad-icon glyph="play_realtime" *ngIf="!timelineLiveStatus"></ad-icon>

    <!-- overloading the default width because the viewbox of the pulse icon is 16px not 24px -->
    <ad-icon
      glyph="pulse"
      *ngIf="timelineLiveStatus"
      [defaultWidth]="16"
    ></ad-icon>

    <span class="live-item-text">
      {{ 'HEADER_TIMELINE.LIVE' | translate }}
    </span>
  </button>

  <!-- Personalized time range in no mobile mode -->
  <button
    astus-design-button
    kind="text"
    color="resolve"
    theme="light"
    class="live-item"
    [ngClass]="{
      selected: !timelineLiveStatus
    }"
    (click)="onTimelineStatusChange(false)"
    *ngIf="!isMobile"
  >
    <ad-icon glyph="time-range"></ad-icon>

    <span class="live-item-text">
      {{ 'HEADER_TIMELINE.CUSTOM' | translate }}
    </span>
  </button>

  <!-- live item in mobile mode -->
  <div
    class="live-item"
    (click)="onTimelineStatusChange(timelineLiveStatus ? false : true)"
    [ngClass]="{
      selected: timelineLiveStatus
    }"
    *ngIf="isMobile"
  >
    <ad-icon glyph="play_realtime"></ad-icon>
  </div>
</div>
