import { Injectable, Inject } from '@angular/core';
import { ReportsService, ReportView } from '@fms/ng-fms-api-client';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

import { reportActions } from './report.action';

@Injectable()
export class ReportEffects {
  constructor(
    private actions$: Actions,
    @Inject(ReportsService) private reportsService: ReportsService
  ) {}

  downloadReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reportActions.reportDownload),
      switchMap((action) => {
        return this.reportsService
          .reportsControllerDownloadReport(Number(action.id))
          .pipe(
            map((response: Blob) => {
              this.createAndDownloadLinkForCSVReport(
                response,
                Number(action.id)
              );

              return reportActions.reportDownloadSuccess({
                id: action.id,
                response,
              });
            }),
            catchError((error) =>
              of(reportActions.reportDownloadFailure({ error }))
            )
          );
      })
    )
  );

  /**
   * Cette fonction crée un lien de téléchargement pour un rapport CSV et déclenche le téléchargement.
   * Elle prend en entrée un objet Blob représentant le contenu du rapport et un identifiant numérique.
   * Elle crée une URL temporaire pour le Blob, crée un élément <a> pour le téléchargement,
   * déclenche un clic sur cet élément pour démarrer le téléchargement, puis nettoie l'URL temporaire.
   * @param response
   * @param id
   */
  private createAndDownloadLinkForCSVReport(response: Blob, id: number): void {
    const url = window.URL.createObjectURL(response);
    const a = document.createElement('a');
    a.href = url;
    a.download = `report_${id}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  generateReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reportActions.reportGenerate),
      switchMap((action) => {
        return this.reportsService
          .reportsControllerGenerateReport(action.payload)
          .pipe(
            map((response: ReportView) =>
              reportActions.reportGenerateSuccess({ response })
            ),
            catchError((error) =>
              of(reportActions.reportGenerateFailure({ error }))
            )
          );
      })
    )
  );

  listingReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reportActions.reportListing),
      switchMap(() => {
        return this.reportsService.reportsControllerGetReports().pipe(
          map((response: ReportView[]) => {
            return reportActions.reportListingSuccess({ response });
          }),
          catchError((error) =>
            of(reportActions.reportListingFailure({ error }))
          )
        );
      })
    )
  );
}
