import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { GoogleMapsHelper } from './google-maps.helper';
import { CONFIGGoogleMaps, IGoogleMapsConfig } from './google-maps.interface';
import { GoogleMapsService } from './google-maps.service';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [],
  exports: [],
})
export class GoogleMapsModule {
  static forRoot(
    config: IGoogleMapsConfig
  ): ModuleWithProviders<GoogleMapsModule> {
    return {
      ngModule: GoogleMapsModule,
      providers: [
        { provide: CONFIGGoogleMaps, useValue: config },
        GoogleMapsService,
        GoogleMapsHelper,
      ],
    };
  }
}
