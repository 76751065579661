import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { canActivateFlag } from '../guards/launch-darkly.guard';

import { canActivatePermissions } from '../guards/permissions.guard';

import { SettingsCompanyProfileComponent } from './settings-company-profile/settings-company-profile.component';
import { SettingsGroupsComponent } from './settings-groups/settings-groups.component';
import { SettingsLanguageAndFormatsComponent } from './settings-language-and-formats/settings-language-and-formats.component';
import { SettingsNotFoundComponent } from './settings-not-found/settings-not-found.component';
import { SettingsNotificationsComponent } from './settings-notifications/settings-notifications.component';
import { SettingsPermissionsComponent } from './settings-permissions/settings-permissions.component';
import { SettingsProfileComponent } from './settings-profile/settings-profile.component';
import { SettingsRolesComponent } from './settings-roles/settings-roles.component';

export enum settingsRoutesEnum {
  PROFILE = 'profile',
  LANGUAGE_AND_FORMATS = 'language-and-formats',
  COMPANY_PROFILE = 'company-profile',
  GROUPS = 'groups',
  ROLES = 'roles',
  NOT_FOUND = 'not-found',
  NOTIFICATIONS = 'notifications',
}

const routes: Routes = [
  {
    path: '',
    children: [],
    data: { 'settings-title': 'MENU.LABELS.SETTINGS' },
  },
  {
    path: settingsRoutesEnum.PROFILE,
    component: SettingsProfileComponent,
    canActivate: [canActivateFlag, canActivatePermissions],
    data: {
      canActivateFlagId: 'Reglages_MonProfil',
      permissions: 'general.myProfilAccess',
      'settings-title': 'SETTINGS.NAV.PROFILE_LINK',
    },
  },
  {
    path: settingsRoutesEnum.LANGUAGE_AND_FORMATS,
    component: SettingsLanguageAndFormatsComponent,
    canActivate: [canActivateFlag, canActivatePermissions],
    data: {
      canActivateFlagId: 'Reglages_LanguesEtFormats',
      permissions: 'general.languageAndFormatsAccess',
      'settings-title': 'SETTINGS.NAV.LANGUAGE_AND_FORMATS_LINK',
    },
  },
  {
    path: settingsRoutesEnum.COMPANY_PROFILE,
    component: SettingsCompanyProfileComponent,
    canActivate: [canActivateFlag, canActivatePermissions],
    data: {
      canActivateFlagId: 'Reglages_ProfilEntreprise',
      permissions: 'general.companyProfileAccess',
      'settings-title': 'SETTINGS.NAV.COMPANY_PROFILE',
    },
  },
  {
    path: settingsRoutesEnum.GROUPS,
    component: SettingsGroupsComponent,
    canActivate: [canActivateFlag],
    data: {
      canActivateFlagId: 'Reglages_Groupes',
      'settings-title': 'SETTINGS.NAV.GROUPS',
    },
  },
  {
    path: settingsRoutesEnum.NOTIFICATIONS,
    component: SettingsNotificationsComponent,
    canActivate: [canActivateFlag],
    data: {
      canActivateFlagId: 'Reglages_Notifications',
      'settings-title': 'SETTINGS.NAV.NOTIFICATIONS',
    },
  },
  {
    path: settingsRoutesEnum.ROLES,
    canActivate: [canActivateFlag, canActivatePermissions],
    data: {
      permissions: 'administration.rolesAndPermissionsAccess',
      canActivateFlagId: 'Reglages_RolesEtPermissions',
    },
    children: [
      {
        path: '',
        component: SettingsRolesComponent,
        data: { 'settings-title': 'SETTINGS.NAV.ROLES_AND_PERMISSIONS' },
      },
      {
        path: ':id',
        component: SettingsPermissionsComponent,
        data: { 'settings-title': 'SETTINGS.NAV.ROLES_AND_PERMISSIONS' },
      },
    ],
  },
  {
    path: settingsRoutesEnum.NOT_FOUND,
    component: SettingsNotFoundComponent,
  },
  {
    path: '**',
    component: SettingsNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
