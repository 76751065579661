import {
  CircuitView,
  UserViewInterface,
  StopCoordinatesView,
  VehicleView,
  VehiclePositionView,
} from '@fms/ng-fms-api-client';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { buttonTabEnum } from '../map-view-tabs-button/map-view-tabs-button.component';

import { mapReducerErrorType } from './map';

export const mapAPIActions = createActionGroup({
  source: 'Map API',
  events: {
    'Stops load': emptyProps(),
    'Stops loaded': props<{ stopCoordinatedViewList: StopCoordinatesView[] }>(),
    'Stops error': props<mapReducerErrorType>(),
    'Circuits load': emptyProps(),
    'Circuits loaded': props<{ circuitsPanelList: CircuitView[] }>(),
    'Circuits error': props<mapReducerErrorType>(),
    'Vehicles load': emptyProps(),
    'Vehicles loaded': props<{ vehiclesPanelList: VehicleView[] }>(),
    'Vehicles error': props<mapReducerErrorType>(),
    'Vehicle position load': props<{ id: number }>(),
    'Vehicle position loaded': props<{
      vehiclePosition: VehiclePositionView;
    }>(),
    'Vehicle position error': props<mapReducerErrorType>(),
    'Vehicles positions load': props<{ ids: string[] }>(),
    'Vehicles positions loaded': props<{
      vehiclesPositions: VehiclePositionView[];
    }>(),
    'Vehicles positions error': props<mapReducerErrorType>(),
    'Users load': emptyProps(),
    'Users loaded': props<{ usersPanelList: UserViewInterface[] }>(),
    'Users error': props<mapReducerErrorType>(),
    'Supervision mode selected': props<{
      supervisionModeSelected: buttonTabEnum;
    }>(),
    'Supervision segment url': props<{ supervisionSegmentUrl: string }>(),
  },
});
