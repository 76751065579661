<div class="button-tab-container">
  <button class="button-container-left">
    <div
      class="left-button-container text-p3"
      [ngClass]="{
        'active-tab': selectTab === buttonTabEnum.MAP
      }"
      (click)="onClickTab(buttonTabEnum.MAP)"
    >
      <span>{{ 'BUTTON_TAB.MAP' | translate }}</span>
    </div>
  </button>

  <button class="button-container-right">
    <div
      class="right-button-container text-p3"
      [ngClass]="{
        'active-tab': selectTab === buttonTabEnum.AGENDA
      }"
      (click)="onClickTab(buttonTabEnum.AGENDA)"
    >
      <span>{{ 'BUTTON_TAB.AGENDA' | translate }}</span>
    </div>
  </button>
</div>
