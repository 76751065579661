import {
  Component,
  HostBinding,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';

import { ColorType } from '../../common-ng-design-system.colors';

import { tooltipKindType } from './tooltip';

@Component({
  selector: 'ad-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipMolecule implements OnInit {
  @Input()
  text?: string;

  @Input()
  color: ColorType = 'primary';

  @Input()
  position: tooltipKindType = 'top';

  @Input()
  interactWithTooltip = false;

  @HostBinding('class') classList =
    'position-' + this.position + ' color-' + this.color;
  haveInteraction = false;
  interactionTimeout: ReturnType<typeof setTimeout> = setTimeout(() => '', 10);

  @HostListener('mouseenter', ['$event']) onEnter() {
    this.addInteratctionClass(true);
  }

  @HostListener('mouseleave', ['$event']) onLeave() {
    this.addInteratctionClass(false);
  }

  ngOnInit(): void {
    this.classList = 'position-' + this.position + ' color-' + this.color;
  }

  /**
   * Added this function because it wasn't working in CSS
   * It delays the mouse leave interaction with the tooltip so you can go
   * from the content to the tooltip without it disappearing
   * @param toAdd: boolean
   */
  addInteratctionClass(toAdd: boolean) {
    if (this.interactWithTooltip) {
      if (toAdd) {
        this.haveInteraction = true;
        clearTimeout(this.interactionTimeout);
      } else {
        this.interactionTimeout = setTimeout(() => {
          this.haveInteraction = false;
        }, 200);
      }
    }
  }
}
