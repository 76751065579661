import {
  DropdownItemInterface,
  userTableInterface as adsUserTableInterface,
} from '@common/ng-design-system';
import {
  CarrierTypeDto,
  HomePortTypeDto,
  IotDeviceTypeDto,
  VehicleModelDto,
  VehicleTypeDto,
} from '@fms/ng-fms-api-client';
import { iso31661, iso31662 } from 'iso-3166';
import { isEqual } from 'lodash';

import { timezoneList } from '../../store';
import { UserFormDropdownSection } from '../models/user-form-dropdown.models';
import { ResourceUserAndDriverModel } from '../models/user-forms-models';

const ALPHA_CODE_2 = ['CA', 'US'];

/**
 *  Get country list that are only in the ALPHA_CODE_2 const as dropdown item
 * @returns
 */
export function getValidCountryListAsDropdownItem(): DropdownItemInterface[] {
  return iso31661
    .filter((country) => {
      return ALPHA_CODE_2.includes(country.alpha2);
    })
    .map((country) => {
      return {
        value: country.alpha2,
        label: country.name,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
}

/**
 * get the province list as dropdown item based on the country
 * @param country
 * @returns province list as dropdown item
 */
export function getProvinceListAsDropdownItem(
  country?: string | null
): DropdownItemInterface[] {
  if (!country) {
    return [];
  }
  return iso31662
    .filter((province) => {
      return province.parent === country;
    })
    .map((province) => {
      return {
        value: province.code,
        label: province.name,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
}

export function getSpecificProvinceListAsDropdownItem(defaultValue?: {
  country?: { alpha?: string };
  subdivision?: { code?: string };
}): UserFormDropdownSection {
  const subdivisions = defaultValue?.country?.alpha
    ? iso31662.filter(
        (subdivision) => subdivision.parent === defaultValue?.country?.alpha
      )
    : iso31662;
  const items: DropdownItemInterface[] = subdivisions
    .map((province) => {
      return {
        value: province.code,
        label: province.name,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  let placeholder;
  if (defaultValue?.subdivision?.code) {
    placeholder = items.find(
      (item) => item.value === defaultValue?.subdivision?.code
    );
  } else {
    placeholder = items[0];
  }
  return { items, placeholder: placeholder?.label ?? null };
}

export function getCountriesAndProvinces(
  defaultCountriesAndSubdivisions: {
    country: { alpha: string };
    subdivision: { code?: string };
    default?: boolean;
  }[]
): {
  countries: UserFormDropdownSection;
  subdivisions: UserFormDropdownSection;
} {
  const countriesAlphas = defaultCountriesAndSubdivisions.map(
    (v) => v.country.alpha
  );
  const defaultCountryAndSubdivision = defaultCountriesAndSubdivisions.find(
    (v) => v.default
  );
  let countryPlaceholder;
  const countriesItems: DropdownItemInterface[] = iso31661
    .map((country) => {
      return {
        value: country.alpha2,
        label: country.name,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label))
    .filter((country) => countriesAlphas.includes(country.value));
  if (defaultCountryAndSubdivision) {
    countryPlaceholder = countriesItems.find(
      (country) => country.value === defaultCountryAndSubdivision.country.alpha
    );
  }
  const subdivisions = getSpecificProvinceListAsDropdownItem(
    defaultCountryAndSubdivision
  );
  const countries = {
    items: countriesItems,
    placeholder: countryPlaceholder?.label ?? null,
  };
  return { countries, subdivisions };
}

export function getVehicleTypeListAsDropdownItem(
  vehicleType: VehicleTypeDto[]
): DropdownItemInterface[] {
  return vehicleType.map((type) => {
    return {
      // TODO: this is a temporary work around type.id is a uuid and not convertible to number, we shoould change the dropdownIterface to accept string
      id: +type.id,
      value: type.name,
      label: type.name,
    };
  });
}

export function getFuelTypeListAsDropdownItem(
  fuelType: VehicleModelDto.FuelTypeEnum[]
): DropdownItemInterface[] {
  return fuelType.map((type) => {
    return {
      value: type,
      label: type,
    };
  });
}

export function getCarrierTypeListAsDropdownItem(
  carrierType: CarrierTypeDto[]
): DropdownItemInterface[] {
  return carrierType.map((type) => {
    return {
      // TODO: this is a temporary work around type.id is a uuid and not convertible to number, we shoould change the dropdownIterface to accept string
      id: +type.id,
      value: type.name,
      label: type.name,
    };
  });
}

export function getHomePortTypeListAsDropdownItem(
  homePortType: HomePortTypeDto[]
): DropdownItemInterface[] {
  return homePortType.map((type) => {
    return {
      // TODO: this is a temporary work around type.id is a uuid and not convertible to number, we shoould change the dropdownIterface to accept string
      id: +type.id,
      value: type.name,
      label: type.name,
    };
  });
}

export function getTagSerialNumberListAsDropdownItem(
  tagSerialNumber: IotDeviceTypeDto[]
): DropdownItemInterface[] {
  return tagSerialNumber.map((type) => {
    return {
      // TODO: this is a temporary work around type.id is a uuid and not convertible to number, we shoould change the dropdownIterface to accept string
      id: +type.id,
      value: type.serialNumber,
      label: type.serialNumber,
    };
  });
}

export function getModuleTypeListAsDropdownItem(
  moduleType: IotDeviceTypeDto[]
): DropdownItemInterface[] {
  return moduleType
    .filter((type) => !!type.moduleType)
    .map((type) => {
      return {
        // TODO: this is a temporary work around type.id is a uuid and not convertible to number, we shoould change the dropdownIterface to accept string
        id: +type.id,
        value: type.moduleType || '',
        label: type.moduleType || '',
      };
    });
}

export function getImeiListAsDropdownItem(
  imei: IotDeviceTypeDto[]
): DropdownItemInterface[] {
  return imei.map((type) => {
    return {
      // TODO: this is a temporary work around type.id is a uuid and not convertible to number, we shoould change the dropdownIterface to accept string
      id: +type.id,
      value: type.imei,
      label: type.imei.toString(),
    };
  });
}

export function getTimezoneListAsDropdownItem(translatedTimeZone: {
  [key: string]: string;
}): DropdownItemInterface[] {
  // get timezones key to convert it easily
  // this list should contains : `UTC-12:00`
  const timezonesKey = timezoneList;

  return timezonesKey.map((timezone) => {
    return {
      value: timezone,
      label: `${translatedTimeZone[timezone]} (${timezone})`,
    };
  });
}

/**
 * Returns the offset of the current timezone as UTC±hh:mm
 */
export function getTimezoneOffset() {
  const offset = new Date().getTimezoneOffset();
  const offsetHours = Math.abs(Math.floor(offset / 60));
  const offsetMinutes = Math.abs(offset % 60);
  const sign = offset >= 0 ? '-' : '+';

  const formattedOffsetHours = offsetHours.toString().padStart(2, '0');
  const formattedOffsetMinutes = offsetMinutes.toString().padStart(2, '0');

  return `UTC${sign}${formattedOffsetHours}:${formattedOffsetMinutes}`;
}

export function removeUndefinedFields<T extends Record<string, unknown>>(
  obj: T
): T {
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== undefined && obj[key] !== null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      acc[key] = obj[key];
    }
    return acc;
  }, {} as T);
}

export function formatUserFields(
  value: ResourceUserAndDriverModel,
  forceDriverInfo = false
): Partial<adsUserTableInterface> {
  let consistentFields = removeUndefinedFields(value);
  if (
    (consistentFields.isDriver || forceDriverInfo) &&
    consistentFields.driverInfo
  ) {
    consistentFields = { ...consistentFields, ...consistentFields.driverInfo };
  }
  delete consistentFields.driverInfo;
  const name =
    (consistentFields.firstName ?? ' - ') +
    ' ' +
    (consistentFields.lastName ?? '');
  const rolesPermissions = value.rolesPermissions
    ? [...value.rolesPermissions.map((role) => role.name)]
    : [];
  return {
    ...consistentFields,
    rolesPermissions,
    ...(name ? { name } : {}),
  } as unknown as Partial<adsUserTableInterface>;
}

type ObjectDifference = {
  [key: string]:
    | { obj1: unknown; obj2: unknown }
    | { obj1: unknown; obj2: null }
    | { obj1: null; obj2: unknown };
};

/**
 * Get the difference between two objects
 * @param obj1
 * @param obj2
 * @returns {ObjectDifference[]}
 */
// TODO: global helper move somewhere else
export function getObjectDiff(
  obj1: { [key: string]: unknown },
  obj2: { [key: string]: unknown }
): ObjectDifference[] {
  const diff: ObjectDifference[] = Object.keys(obj1).reduce(
    (result: ObjectDifference[], key) => {
      if (!(key in obj2)) {
        result.push({
          [key]: {
            obj1: obj1[key],
            obj2: null,
          },
        });
      } else if (!isEqual(obj1[key], obj2[key])) {
        result.push({
          [key]: {
            obj1: obj1[key],
            obj2: obj2[key],
          },
        });
      }
      return result;
    },
    []
  );

  Object.keys(obj2).forEach((key) => {
    if (!(key in obj1)) {
      diff.push({
        [key]: {
          obj1: null,
          obj2: obj2[key],
        },
      });
    }
  });

  return diff;
}
