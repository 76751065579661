/**
 * FMS API
 * In charge of providing data from the legacy Astus FMS API to the different FMS apps
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type VehicleStateType =
  | 'onDuty'
  | 'maintenance'
  | 'outOfService'
  | 'offDuty';

export const VehicleStateType = {
  OnDuty: 'onDuty' as VehicleStateType,
  Maintenance: 'maintenance' as VehicleStateType,
  OutOfService: 'outOfService' as VehicleStateType,
  OffDuty: 'offDuty' as VehicleStateType,
};
