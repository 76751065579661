import { Component, EventEmitter, Input, Output } from '@angular/core';

import { controlPanelModalAssociationType } from '../map-control-panel/map-control-panel.component.d';

enum assignmentValue {
  circuits = 0,
  vehicles = 1,
  drivers = 2,
}

@Component({
  selector: 'astus-map-association-modal',
  templateUrl: './map-association-modal.component.html',
  styleUrls: ['./map-association-modal.component.scss'],
})
export class MapAssociationModalComponent {
  @Input() isShown = false;

  @Input() data: controlPanelModalAssociationType = {
    circuit: '',
    vehicle: '',
    user: '',
  };

  @Output() isShownChange = new EventEmitter<boolean>();
  @Output() dataChange = new EventEmitter<controlPanelModalAssociationType>();

  assignmentValue = assignmentValue; // Exporting the enum to the template
  completedAssignments = false;

  /**
   * Close the modal and emit the new value of isShown to the parent component.
   * This will close the modal
   * @returns {void}
   */
  close(): void {
    this.isShown = false;
    this.isShownChange.emit(this.isShown);
  }

  /**
   * Remove the assignment from the modal
   * and emit the new value of data to the parent component.
   * This will close the modal if there is no more assignment
   * or if the circuit is removed.
   * @param {assignmentValue} assignment - The assignment to remove
   * @returns {void}
   */
  removeAssignment(assignment: assignmentValue): void {
    if (assignment === assignmentValue.circuits) {
      this.data.circuit = '';
    } else if (assignment === assignmentValue.vehicles) {
      this.data.vehicle = '';
    } else if (assignment === assignmentValue.drivers) {
      this.data.user = '';
    }

    this.dataChange.emit(this.data);

    if (!this.data.circuit && !this.data.vehicle && !this.data.user) {
      this.close();
    }
  }

  /**
   * Check if all the assignment are completed or not
   * To enable the button to save the modal
   * @returns {boolean} - True if all the assignment are completed
   */
  checkAllAssignmentCompleted(): boolean {
    return !this.data.circuit || !this.data.vehicle || !this.data.user;
  }

  /**
   * Save the assignment and emit the new value of data to the parent component.
   * This will close the modal and show a message to the user to confirm the assignment.
   * After 3 seconds, the message will disappear and the modal will close.
   * @returns {void}
   */
  assign(): void {
    this.completedAssignments = true;
    this.dataChange.emit(this.data);

    setTimeout(() => {
      this.close();
      this.dataChange.emit({
        circuit: '',
        vehicle: '',
        user: '',
      });
      this.completedAssignments = false;
    }, 3000);
  }

  /**
   * @description
   * Use to format the text to display in the avatar.
   * It takes the first letter of the first two groups of string, generally the first and last name
   * @param {string} text - The text to format
   * @returns {string} - The formatted text
   */
  formatText(text: string): string {
    const driverName = text.split(' ');
    return (
      driverName[0].charAt(0) +
      (driverName.length > 1 ? driverName.at(-1)?.charAt(0) : '')
    );
  }
}
