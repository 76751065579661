export default [
  { prefix: './assets/i18n/', suffix: '.json' },
  { prefix: './assets/i18n/control-panel/', suffix: '.json' },
  { prefix: './assets/i18n/map/', suffix: '.json' },
  { prefix: './assets/i18n/assistance-page/', suffix: '.json' },
  { prefix: './assets/i18n/cards/', suffix: '.json' },
  { prefix: './assets/i18n/not-found/', suffix: '.json' },
  { prefix: './assets/i18n/notification-center/', suffix: '.json' },
  { prefix: './assets/i18n/resources/', suffix: '.json' },
  { prefix: './assets/i18n/resources/resources-upload/', suffix: '.json' },
  { prefix: './assets/i18n/resources/resources-widget/', suffix: '.json' },
  {
    prefix: './assets/i18n/resources/resources-infobox/forms/',
    suffix: '.json',
  },
  {
    prefix: './assets/i18n/resources/resources-infobox/infobox/',
    suffix: '.json',
  },
  {
    prefix: './assets/i18n/resources/resources-table/table-headers/',
    suffix: '.json',
  },
  {
    prefix: './assets/i18n/resources/resources-table/table-empty-state/',
    suffix: '.json',
  },
  {
    prefix: './assets/i18n/resources/resources-search-bar/',
    suffix: '.json',
  },
  {
    prefix: './assets/i18n/resources/resources-filters/',
    suffix: '.json',
  },
  { prefix: './assets/i18n/menu/', suffix: '.json' },
  { prefix: './assets/i18n/primeng/', suffix: '.json' },
  { prefix: './assets/i18n/settings/', suffix: '.json' },
  { prefix: './assets/i18n/settings/language-and-formats/', suffix: '.json' },
  { prefix: './assets/i18n/settings/my-profile/', suffix: '.json' },
  { prefix: './assets/i18n/settings/groups/', suffix: '.json' },
  { prefix: './assets/i18n/settings/my-profile/', suffix: '.json' },
  { prefix: './assets/i18n/settings/roles-and-permissions/', suffix: '.json' },
  { prefix: './assets/i18n/settings/notifications/', suffix: '.json' },
  { prefix: './assets/i18n/terms-conditions/', suffix: '.json' },
  { prefix: './assets/i18n/alerts/snackbar/', suffix: '.json' },
  { prefix: './assets/i18n/vehicle-sort-filter/', suffix: '.json' },
  { prefix: './assets/i18n/reports/', suffix: '.json' },
  { prefix: './assets/i18n/hour-picker/', suffix: '.json' },
];
