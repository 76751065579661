import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { DropdownItemInterface } from '@common/ng-design-system';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { Subscription } from 'rxjs';

import { dateFormatEnum } from '../../pipes/dateformat/dateformat.enum';
import { durationEnum } from '../../pipes/duration/duration.enum';
import { hoursFormatEnum } from '../../pipes/hours-format/hours-format.enum';

import { PermissionsService } from '../../services/permissions.service';
import {
  getUserLanguageAndFormats,
  unitSystemEnum,
  userActions,
  userLanguageEnum,
} from '../../store';

import { buttonProcessStateEnum } from '../settings.enum';

@Component({
  selector: 'astus-settings-language-and-formats',
  templateUrl: './settings-language-and-formats.component.html',
  styleUrls: ['./settings-language-and-formats.component.scss'],
})
export class SettingsLanguageAndFormatsComponent implements OnInit, OnDestroy {
  formGroup = this.fb.group({
    language: [''],
    unitSystem: [''],
    dateFormat: [''],
    durationFormat: [''],
    hoursFormat: [''],
  });

  languageItems: DropdownItemInterface[] = [];
  unitsItems: DropdownItemInterface[] = [];
  dateFormatItems: DropdownItemInterface[] = [];
  durationFormatItems: DropdownItemInterface[] = [];
  hoursFormatItems: DropdownItemInterface[] = [];

  initialLanguage = '';
  initialUnits = '';
  initialDateFormat = '';
  initialDurationFormat = '';
  initialHoursFormat = '';
  formChanged = false;
  buttonEnums = buttonProcessStateEnum;
  processState: buttonProcessStateEnum = this.buttonEnums.READY;

  canEditLanguageAndFormatPage = false;
  permissionEditLanguageAndFormatPage$: Subscription;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private translateService: TranslateService,
    private permissionsService: PermissionsService
  ) {
    this.permissionEditLanguageAndFormatPage$ = this.permissionsService
      .getPermission$('general.languageAndFormatsEdit')
      .subscribe((value: boolean) => {
        this.canEditLanguageAndFormatPage = value;
      });

    this.formGroup = this.fb.group({
      language: this.createFormControl(false, Validators.required),
      unitSystem: this.createFormControl(false, Validators.required),
      dateFormat: this.createFormControl(false, Validators.required),
      durationFormat: this.createFormControl(false, Validators.required),
      hoursFormat: this.createFormControl(false, Validators.required),
    });
  }

  /**
   * Initializes the component and subscribes to the user language and formats changes.
   * Sets the initial values for language, units, and date format.
   * Updates the language, units, and date format items based on translation service.
   * Tracks changes in the form group values.
   */
  ngOnInit(): void {
    this.store.select(getUserLanguageAndFormats).subscribe((user) => {
      this.formGroup.patchValue(user);
      this.initialLanguage = user.language;
      this.initialUnits = user.unitSystem;
      this.initialDateFormat = user.dateFormat;
      this.initialDurationFormat = user.durationFormat;
      this.initialHoursFormat = user.hoursFormat;
    });

    this.translateService
      .stream('SETTINGS_LANGUAGE_AND_FORMATS.LANGUAGE')
      .subscribe((res: { [key: string]: string }) => {
        this.languageItems = [
          ...Object.values(userLanguageEnum).map((language) => ({
            value: language,
            label: this.formatValue(language, res),
          })),
        ];
      });

    this.translateService
      .stream('SETTINGS_LANGUAGE_AND_FORMATS.UNIT')
      .subscribe((res: { [key: string]: string }) => {
        this.unitsItems = Object.values(unitSystemEnum).map((unit) => ({
          value: unit,
          label: this.formatValue(unit, res),
        }));
      });

    this.translateService
      .stream('SETTINGS_LANGUAGE_AND_FORMATS.DATE_FORMAT')
      .subscribe((res: { [key: string]: string }) => {
        this.dateFormatItems = Object.values(dateFormatEnum).map(
          (dateFormat) => ({
            value: dateFormat,
            label: this.formatValue(dateFormat, res),
          })
        );
      });

    this.translateService
      .stream('SETTINGS_LANGUAGE_AND_FORMATS.DURATION')
      .subscribe((res: { [key: string]: string }) => {
        this.durationFormatItems = Object.values(durationEnum).map(
          (durationFormat) => ({
            value: durationFormat,
            label: this.formatValue(durationFormat, res),
          })
        );
      });

    this.translateService
      .stream('SETTINGS_LANGUAGE_AND_FORMATS.HOURS')
      .subscribe((res: { [key: string]: string }) => {
        this.hoursFormatItems = Object.values(hoursFormatEnum).map(
          (hoursFormat) => ({
            value: hoursFormat,
            label: this.formatValue(hoursFormat, res),
          })
        );
      });

    this.formGroup.valueChanges.subscribe((change) => {
      if (
        change.language !== this.initialLanguage ||
        change.unitSystem !== this.initialUnits ||
        change.dateFormat !== this.initialDateFormat
      ) {
        this.formChanged = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.permissionEditLanguageAndFormatPage$?.unsubscribe();
  }

  /**
   * Creates a new instance of FormControl with the specified disabled state and validators.
   * If the user has permission to edit the language and format page, the form control is enabled.
   * Otherwise, the form control is disabled.
   * @param disabled - A boolean indicating whether the form control should be disabled.
   * @param validators - The validators to be applied to the form control.
   * @returns A new instance of FormControl.
   */
  private createFormControl(
    disabled: boolean,
    validators: ValidatorFn | ValidatorFn[] | null
  ): FormControl {
    const isDisabled = this.canEditLanguageAndFormatPage ? disabled : true;
    return new FormControl({ value: '', disabled: isDisabled }, validators);
  }

  /**
   * Formats the given value using the provided translations.
   * If a translation for the value is found, it is returned. Otherwise, the original value is returned.
   *
   * @param value - The value to be formatted.
   * @param translations - An object containing translations for different values.
   * @returns The formatted value.
   */
  formatValue(value: string, translations: { [key: string]: string }) {
    return translations[value] || value;
  }

  /**
   * Saves the user's language and format settings.
   * Dispatches an action to save the user's language and format settings.
   * Sets the process state to processing while the action is being dispatched.
   * Sets the process state to success once the action has been dispatched.
   * @returns void
   */
  saveForm() {
    this.processState = buttonProcessStateEnum.PROCESSING;
    this.store.dispatch(
      userActions.saveUserLanguageAndFormats({
        languageAndFormats: {
          language: this.formGroup.value.language as userLanguageEnum,
          unitSystem: this.formGroup.value.unitSystem as unitSystemEnum,
          dateFormat: this.formGroup.value.dateFormat as dateFormatEnum,
          durationFormat: this.formGroup.value.durationFormat as durationEnum,
          hoursFormat: this.formGroup.value.hoursFormat as hoursFormatEnum,
        },
      })
    );
    this.processState = buttonProcessStateEnum.SUCCESS;
    this.formChanged = false;
  }

  /**
   * Resets the form to its initial values.
   * Sets the form group values to the initial language, units, and date format.
   * Sets the process state to ready.
   * Marks the form group as pristine.
   * Sets the form changed flag to false.
   * @returns void
   */
  resetForm(): void {
    this.formGroup.patchValue({
      language: this.initialLanguage,
      unitSystem: this.initialUnits,
      dateFormat: this.initialDateFormat,
      durationFormat: this.initialDurationFormat,
      hoursFormat: this.initialHoursFormat,
    });
    this.processState = buttonProcessStateEnum.READY;
    this.formGroup.markAsPristine();
    this.formChanged = false;
  }
}
