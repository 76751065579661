import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'astus-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
})
export class DrawerComponent implements AfterViewInit, OnDestroy {
  GRABBER_HEIGHT = 36;
  // View child elements
  @ViewChild('drawerContent') drawerContent!: ElementRef<HTMLDivElement>;
  // elementRef and not ElementRef<HTMLDivElement>,
  // because showPopover and hidePopover are not defined on HTMLDivElement
  // probably should update typescript version to 5.2
  // => https://stackoverflow.com/a/77231013
  @ViewChild('drawer', { static: true })
  drawer!: ElementRef;
  @ViewChild('grabber') grabber!: ElementRef<HTMLDivElement>;

  @Output() closeDrawerEvent = new EventEmitter<void>();

  // Component state
  divStyle: { [key: string]: string } = {};
  dragPosition = { x: 0, y: 0 };

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.drawer?.nativeElement.showPopover();
    this.autoCalculateTheDrawerMaxPosition();
    // disable the body scroll when the drawer is open
    // i cant handle in other way, because the drawer is a top layer element
    // the app overload the body scroll
    this.renderer.setStyle(document.body, 'overflow', 'hidden');

    // add event listener to the drawer to handle the outside click event
    this.drawer?.nativeElement.addEventListener(
      'beforetoggle',
      // passing any here, because angular does not have the `ToggleEvent` type
      // https://developer.mozilla.org/en-US/docs/Web/API/ToggleEvent
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (event: any) => {
        event.stopPropagation();

        // if the drawer is closed, emit the close drawer event
        if (event.newState === 'closed') {
          this.closeDrawerEvent.emit();
          this.closeDrawer();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.closeDrawer();
  }

  /**
   * This function is used to calculate the maximum position of the drawer
   * based on the screen height and the drawer height
   */
  autoCalculateTheDrawerMaxPosition(): void {
    // get the height of the drawer
    const drawerHeight = this.drawer.nativeElement.offsetHeight;

    // get the screen height
    const screenHeight = window.innerHeight;

    // get the height between the drawer and the top of the screen
    const drawerContentHeight = screenHeight - drawerHeight;

    this.divStyle = {
      ...this.divStyle,
      top: `${drawerContentHeight}px`, // should be the distance between the drawer and the top of the screen
      // To drag the component to the bottom, we need to apply the `boundary` height twice
      // the size of the drawer minus the grabber height to keep the grabber visible
      height: `${drawerHeight * 2 - this.GRABBER_HEIGHT}px`,
    };
  }

  /**
   * This function is used to handle the drag event
   */
  onDragEnd() {
    if (this.isElementTouchingBottom()) {
      this.closeDrawerEvent.emit();
      this.closeDrawer();
    }
  }

  /**
   * This function is used to close the drawer
   */
  closeDrawer() {
    this.renderer.removeStyle(document.body, 'overflow');
    this.drawer?.nativeElement?.hidePopover();
  }

  onOutsideDrawerClick() {
    this.closeDrawerEvent.emit();
    this.closeDrawer();
  }

  /**
   * This function is used to determine if the draggable element (grabber) is touching the bottom of the screen
   * @returns {boolean} true if the element is touching the bottom of the screen, false otherwise
   */
  isElementTouchingBottom(): boolean {
    const grabberElement = this.grabber.nativeElement;
    const drawerElement = this.drawer.nativeElement;

    const grabberRect = grabberElement.getBoundingClientRect();
    const drawerRect = drawerElement.getBoundingClientRect();
    const viewportHeight =
      window.innerHeight || document.documentElement.clientHeight;

    // Calculate the position of the grabber element relative to the viewport
    const grabberBottom = grabberRect.top + grabberRect.height;

    // Ensure the grabber is inside the dialog
    if (grabberBottom > drawerRect.top && grabberBottom < drawerRect.bottom) {
      return grabberBottom >= viewportHeight;
    }

    return false;
  }
}
