/**
 * FMS API
 * In charge of providing data from the legacy Astus FMS API to the different FMS apps
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type VehicleGroupType = 'South-East' | 'North-West' | 'East' | 'West';

export const VehicleGroupType = {
  SouthEast: 'South-East' as VehicleGroupType,
  NorthWest: 'North-West' as VehicleGroupType,
  East: 'East' as VehicleGroupType,
  West: 'West' as VehicleGroupType,
};
