import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@auth0/auth0-angular';

import { TermsConditionsHeaderComponent } from '../header/terms-and-conditions-header/terms-conditions-header.component';

import { LicenseAgreementComponent } from './license-agreement/license-agreement.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { WarrantyComponent } from './warranty/warranty.component';

const termsConditionsRoutes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'equipment-warranty',
        data: { 'terms-conditions': 'PAGE_HEADER.WARRANTY' },
        component: WarrantyComponent,
      },
      {
        path: 'privacy-policy',
        data: { 'terms-conditions': 'PAGE_HEADER.PRIVACY' },
        component: PrivacyPolicyComponent,
      },
      {
        path: 'license-agreement',
        data: { 'terms-conditions': 'PAGE_HEADER.LICENSE' },
        component: LicenseAgreementComponent,
      },
      {
        outlet: 'header-top',
        path: '',
        component: TermsConditionsHeaderComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(termsConditionsRoutes)],
  exports: [RouterModule],
})
export class TermsConditionsRoutingModule {}
