import { HttpEventType, HttpStatusCode } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UploadsService } from '@fms/ng-fms-api-client';
import { Store } from '@ngrx/store';
import { Subscription, catchError, finalize, of, tap } from 'rxjs';

import { resourceDataType } from '../table-empty-state.helpers';

import { addFileName, resetUpload, setUploadProgress } from './store';
import { UploadStateInterface } from './store/upload';

@Component({
  selector: 'astus-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent {
  @ViewChild('scrollBar') private myScrollContainer!: ElementRef;
  @Input() selectedTab: resourceDataType = 'circuits';
  @Input() errorMessage = '';
  @Input() progressMessage = '';

  // Handling mobile view dynamically according the screen size
  // because of the aside aspect of the resource component, its breakpoint should be 800px
  // TODO eventually this logic will be moved to the store and the mobile component should be handled within the resouces component
  @Input()
  isMobile = window.innerWidth < 800;

  uploadDrawerOpen = false;

  @Output() createResourcesButtonClick = new EventEmitter<void>();

  fileNames: string[] = [];
  uploads: {
    fileName: string;
    progress: number;
    completed: boolean;
    error: boolean;
  }[] = [];
  uploadSubscriptions: { fileName: string; subscription: Subscription }[] = [];

  httpsFileUploadSuccessCodes = [
    HttpStatusCode.Ok,
    HttpStatusCode.Created,
    HttpStatusCode.Accepted,
  ];

  acceptedFiles = ['.csv'];

  constructor(
    private uploadService: UploadsService,
    private store: Store<UploadStateInterface>
  ) {}

  /**
   * @description
   * This function is called when the user selects files to upload.
   * It iterates over the files and starts the upload process for
   * each file.
   * @param event The event that contains the files to upload
   */
  async onFileSelected(event: Event | DragEvent): Promise<void> {
    const files =
      event instanceof DragEvent
        ? event.dataTransfer?.files
        : (event.target as HTMLInputElement).files;

    if (!files) {
      return;
    }

    Array.from(files).forEach((file) => {
      if (
        !this.acceptedFiles.some((fileType) => file.name.includes(fileType))
      ) {
        return;
      }

      const fileName = file.name;
      this.updateFileName(fileName);

      if (!this.uploads.some((u) => u.fileName === fileName && !u.error)) {
        this.startUpload(fileName, file);
      }
    });
  }

  /**
   * @description
   * This function adds the file name to the list of file names.
   * @param fileName The name of the file to add to the list of file names
   */
  private updateFileName(fileName: string): void {
    this.fileNames.push(fileName);
    this.store.dispatch(addFileName({ fileName: fileName }));
  }

  /**
   * @description
   * This function starts the upload process for a file.
   * @param fileName The name of the file to upload
   * @param file The file to upload
   */
  startUpload(fileName: string, file: File): void {
    const upload = {
      fileName: fileName,
      progress: 0,
      completed: false,
      error: false,
    };
    this.uploads.push(upload);

    const upload$ = this.uploadService
      .uploadsControllerUploadSingleFile(file, 'events', true)
      .pipe(
        finalize(() => {
          this.resetUpload(fileName);
        }),
        tap((event) => {
          if (event.type === HttpEventType.UploadProgress) {
            if (event?.loaded && event?.total) {
              const progress = Math.round((100 * event.loaded) / event.total);
              this.updateUploadProgress(fileName, progress);

              this.myScrollContainer.nativeElement.scrollTo({
                top: this.myScrollContainer.nativeElement.scrollHeight,
                behavior: 'smooth',
              });
            }
          }
        }),
        catchError((error) => {
          console.error('Error during file upload for', fileName, error);
          upload.error = true;
          // Return an observable with an error to propagate it further if needed
          return of(error);
        })
      );

    const uploadSub = upload$.subscribe();
    this.setUploadSubscription(fileName, uploadSub);
  }

  onButtonClick(event: Event) {
    event.preventDefault();

    if (!this.isMobile) {
      this.createResourcesButtonClick.emit();
    } else {
      this.uploadDrawerOpen = true;
    }
  }

  onCreateResourcesButtonClick() {
    this.createResourcesButtonClick.emit();
  }

  /**
   * @description
   * This function sets the upload subscription for a file.
   * @param fileName The name of the file to set the upload subscription for
   * @param uploadSub The upload subscription to set
   */
  private setUploadSubscription(
    fileName: string,
    uploadSub: Subscription
  ): void {
    this.uploadSubscriptions.push({
      fileName: fileName,
      subscription: uploadSub,
    });
  }

  /**
   * @description
   * This function resets the upload for a file.
   * @param fileName The name of the file to reset the upload for
   */
  resetUpload(fileName: string): void {
    const upload = this.uploads.find((upload) => upload.fileName === fileName);

    if (upload) {
      upload.completed = true;
      this.removeUploadSubscription(fileName);
      this.store.dispatch(resetUpload({ fileName }));
    }
  }

  /**
   * @description
   * This function removes the upload subscription for a file.
   * @param fileName The name of the file to remove the upload subscription for
   */
  private removeUploadSubscription(fileName: string): void {
    const uploadSub = this.uploadSubscriptions.find(
      (u) => u.fileName === fileName
    );

    if (uploadSub) {
      uploadSub.subscription?.unsubscribe();
      this.uploadSubscriptions.splice(
        this.uploadSubscriptions.indexOf(uploadSub),
        1
      );
    }
  }

  /**
   * @description
   * This function updates the upload progress for a file.
   * @param fileName The name of the file to update the upload progress for
   * @param progress The progress to update the upload progress to
   */
  private updateUploadProgress(fileName: string, progress: number): void {
    const reversedUploads = [...this.uploads].reverse(); // Create a reversed copy of uploads
    const upload = reversedUploads.find((u) => u.fileName === fileName);

    if (upload) {
      upload.progress = progress;

      this.store.dispatch(
        setUploadProgress({ fileName: fileName, progress: progress })
      );
    }
  }

  /**
   * @description
   * This function removes a file from the list of files to upload.
   * @param index The index of the file to remove
   */
  removeFile(index: number): void {
    if (index >= 0 && index < this.uploads.length) {
      const fileName = this.uploads[index].fileName;
      this.removeUploadSubscription(fileName);
      this.uploads.splice(index, 1);
    }
  }

  uploadMobileDrawerClosed(): void {
    this.uploadDrawerOpen = false;
  }
}
