import { Component, Input } from '@angular/core';

import { IconAtomType } from '../../atoms';

@Component({
  selector: 'ad-table-empty-state',
  template: `
    <div class="empty-state-container">
      <div class="empty-state-info-container">
        <ad-icon *ngIf="icon" [glyph]="icon" [color]="'primary-400'"></ad-icon>
        <ng-content></ng-content>
        <div class="empty-state-error-text-container">
          <p class="error-message text-subHeader">
            {{ errorMessage }}
          </p>
          <p class="error-description text-subHeader">
            {{ errorDescription }}
          </p>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./table-empty-state.component.scss'],
})
export class TableEmptyStateOrganism {
  @Input()
  errorMessage?: string;

  @Input()
  errorDescription?: string;

  @Input()
  icon: IconAtomType | undefined = undefined;
}
