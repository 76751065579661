import { Injectable, OnDestroy, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { LaunchDarklyService } from '@common/ts-feature-flag';
import { Subscription } from 'rxjs';

import { AppInitializerService } from '../services/appInitializer.service';

/**
 * Used in the CanActive properties of Routes object for routing modules.
 * Checks if the specified feature flag is enabled or if the array of feature flags are enabled.
 * If one or more flag is not enabled, it navigates to the '/not-found' route.
 * @param route - The activated route snapshot.
 * @returns A promise that resolves to a boolean indicating if the flag is enabled.
 */
export const canActivateFlag: CanActivateFn = (
  route: ActivatedRouteSnapshot
) => {
  return inject(LaunchDarklyGuard).canActivate(route);
};

@Injectable()
export class LaunchDarklyGuard implements OnDestroy {
  private flagSubscription: Subscription[] = [];

  constructor(
    private launchDarklyService: LaunchDarklyService,
    private router: Router,
    private appInitializerService: AppInitializerService
  ) {}

  /**
   * Checks if the specified feature flag is enabled.
   * If the flag is not enabled, it navigates to the '/not-found' route.
   * @param route - The activated route snapshot.
   * @returns A promise that resolves to a boolean indicating if the flag is enabled.
   */
  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const flagId = route.data['canActivateFlagId'];
    const defaultValue = route.data['canActivateFlagDefaultValue'] ?? false;
    let value = false;

    return this.appInitializerService.initializeApp().then(() => {
      if (typeof flagId === 'string') {
        value = this.launchDarklyService.getFlagValue(flagId, defaultValue);
      } else if (Array.isArray(flagId)) {
        for (const flagValue of flagId) {
          value = this.launchDarklyService.getFlagValue(
            flagValue,
            defaultValue
          );
        }
      }

      if (typeof flagId === 'string') {
        value = this.launchDarklyService.getFlagValue(flagId, defaultValue);

        this.flagSubscription.push(
          this.launchDarklyService
            .getFlagValue$(flagId, defaultValue)
            .subscribe((newValue: boolean) => {
              if (!newValue) {
                this.redirectToNotFound();
              }
            })
        );
      } else if (Array.isArray(flagId)) {
        for (const flagValue of flagId) {
          value = this.launchDarklyService.getFlagValue(
            flagValue,
            defaultValue
          );

          this.flagSubscription.push(
            this.launchDarklyService
              .getFlagValue$(flagValue, defaultValue)
              .subscribe((newValue: boolean) => {
                if (!newValue) {
                  this.redirectToNotFound();
                }
              })
          );
        }
      }

      if (!value) {
        this.redirectToNotFound();
      }

      return value;
    });
  }

  redirectToNotFound() {
    this.router.navigate(['/not-found']);
  }

  ngOnDestroy(): void {
    this.flagSubscription.forEach((subscription) => subscription.unsubscribe());
  }
}
