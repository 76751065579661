<astus-settings-button-layout
  (saveEmitter)="saveForm()"
  (cancelEmitter)="openConfirmationModal()"
  [processState]="processState"
  [enableCancel]="formGroup.invalid"
  [disabled]="!formChanged || formGroup.invalid"
>
  <form class="inputs-wrapper" [formGroup]="formGroup">
    <h1 class="text-p3--semibold">
      {{ 'MY_PROFILE.PROFILE_TITLE' | translate }}
    </h1>
    <div class="input-list">
      <ad-input
        [label]="'MY_PROFILE.PROFILE_FIRST_NAME' | translate"
        formControlName="firstName"
        [required]="true"
        [errors]="formGroup.get('firstName')?.errors ?? null"
        [errorMessage]="formGroup.controls.firstName.errors?.['required'] ?
          { firstName: 'MY_PROFILE.ERRORS.REQUIRED' | translate }
          : null"
      ></ad-input>
      <ad-input
        [label]="'MY_PROFILE.PROFILE_LAST_NAME' | translate"
        formControlName="lastName"
        [errors]="formGroup.get('lastName')?.errors ?? null"
        [errorMessage]="formGroup.controls.lastName.errors?.['required'] ?
          { lastName: 'MY_PROFILE.ERRORS.REQUIRED' | translate }
          : null"
        [required]="true"
      ></ad-input>
      <ad-input
        astusPhoneFormat
        [label]="'MY_PROFILE.PROFILE_PHONE' | translate"
        formControlName="phone"
        [errors]="formGroup.get('phone')?.errors ?? null"
        [errorMessage]="formGroup.controls.phone.errors?.['required'] ?
          { phone: 'MY_PROFILE.ERRORS.REQUIRED' | translate }
          : formGroup.controls.phone.errors?.['invalidPhones'] ?
          { phone: 'MY_PROFILE.ERRORS.INVALID_FORMAT' | translate }
          : null"
        [required]="true"
      >
      </ad-input>
      <ad-input
        [label]="'MY_PROFILE.PROFILE_EMAIL' | translate"
        [type]="'email'"
        formControlName="email"
        [errors]="formGroup.get('email')?.errors ?? null"
        [errorMessage]="formGroup.controls.email.errors?.['required'] ?
          { email: 'MY_PROFILE.ERRORS.REQUIRED' | translate }
          : formGroup.controls.email.errors?.['email'] ?
          { email: 'MY_PROFILE.ERRORS.INVALID_FORMAT' | translate }
          : null"
        [required]="true"
      ></ad-input>
      <astus-resource-role-permission
        [rolesPermissionsList]="profileDropDowns.roles"
        formControlName="roles"
        [label]="'MY_PROFILE.PROFILE_ROLE' | translate"
        [required]="true"
        [placeholder]="'MY_PROFILE.PROFILE_ROLE' | translate"
        [errors]="formGroup.get('roles')?.errors ?? null"
        [errorMessage]="formGroup.controls.roles.errors?.['required'] ?
          { roles: 'MY_PROFILE.ERRORS.ROLES_REQUIRED' | translate }
          : null"
        [disabled]="!canEditMyProfilPage || !isEditRoleAllowed"
      >
      </astus-resource-role-permission>
    </div>
  </form>
  <ad-modal
    class="modal-cancel"
    [isOpen]="openCancelConfirmationModal"
    (outsideModalClick)="closeCancelModal()"
  >
    <div top class="information-container">
      <div>
        <ad-icon
          [glyph]="'alert'"
          class="icon"
          [color]="'warning-400'"
        ></ad-icon>
      </div>
      <span class="text-p1--semibold title-text">
        {{ 'MY_PROFILE.CANCEL_SETTING_MODAL.TITLE' | translate }}
      </span>
      <span class="text-p3 subtitle-text">
        {{ 'MY_PROFILE.CANCEL_SETTING_MODAL.SUBTITLE' | translate }}
      </span>
    </div>
    <div middle class="button-container">
      <button
        astus-design-button
        [size]="'sm'"
        kind="outlined"
        color="neutral"
        class="button cancel-button"
        (click)="closeCancelModal()"
      >
        {{ 'MY_PROFILE.CANCEL_SETTING_MODAL.CANCEL_BUTTON' | translate }}
      </button>
      <button
        astus-design-button
        [size]="'sm'"
        kind="filled"
        color="success"
        class="button confirm-button"
        (click)="resetForm()"
      >
        {{ 'MY_PROFILE.CANCEL_SETTING_MODAL.CONFIRM_BUTTON' | translate }}
      </button>
    </div>
  </ad-modal>
</astus-settings-button-layout>
