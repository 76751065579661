import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';

import { getDateFormatData } from '../../store';

import { dateFormatEnum } from './dateformat.enum';

const LEADING_NUMBER = '0';
@Pipe({
  name: 'dateFormat',
  standalone: true,
})
export class DateFormatPipe implements PipeTransform {
  dateFormat = dateFormatEnum['DD/MM/YYYY'];

  constructor(private store: Store) {
    this.store.select(getDateFormatData).subscribe((dateFormat) => {
      this.dateFormat = dateFormat;
    });
  }

  transform(
    value: string | number | Date | null,
    forceFormat: dateFormatEnum | null = null
  ): string {
    if (!value) {
      return '';
    }

    const date = new Date(value);
    const offsetInMinutes = date.getTimezoneOffset();
    const compensatedDate = new Date(
      date.getTime() + offsetInMinutes * 60 * 1000
    );

    const dateFormatToUse = forceFormat ?? this.dateFormat;

    if (isNaN(date.getDate())) {
      return 'INVALID DATE FORMAT';
    }

    // Get the year, month and day from the date
    const yearFromDate = compensatedDate.getFullYear();
    const monthFromDate = compensatedDate.getMonth() + 1; // +1 because getMonth() returns 0-11, so we need to add 1 to get 1-12
    const dayFromDate = compensatedDate.getDate();

    // Add leading zero if the month or day is less than 10
    // to avoid displaying dates like 1/1/2021, but 01/01/2021
    // We add the leading zero by concatenating the month or day with a leading zero
    // and then we slice the last two characters of the string
    // so if the month is 1, we concatenate it with a leading zero to get 01 => and get 01
    // so if the month is 10, we concatenate it with a leading zero to get 010 => and get 10
    const monthWithLeadingZero = (LEADING_NUMBER + monthFromDate).slice(-2);
    const dayWithLeadingZero = (LEADING_NUMBER + dayFromDate).slice(-2);

    if (dateFormatToUse === dateFormatEnum['YYYY/MM/DD']) {
      return `${yearFromDate}/${monthWithLeadingZero}/${dayWithLeadingZero}`;
    } else if (dateFormatToUse === dateFormatEnum['MM/DD/YYYY']) {
      return `${monthWithLeadingZero}/${dayWithLeadingZero}/${yearFromDate}`;
    } else {
      return `${dayWithLeadingZero}/${monthWithLeadingZero}/${yearFromDate}`;
    }
  }
}
