<astus-settings-button-layout
  [processState]="processState"
  (saveEmitter)="saveForm()"
  (cancelEmitter)="resetForm()"
  (resetProcessState)="
    processState = this.buttonEnums.READY; formGroup.markAsPristine()
  "
  [disabled]="
    !formChanged && formGroup.pristine && processState === buttonEnums.READY
  "
>
  <form [formGroup]="formGroup">
    <div class="section">
      <div class="title text-p3--semibold">
        {{ 'SETTINGS_LANGUAGE_AND_FORMATS.LANGUAGE_TITLE' | translate }}
      </div>
      <div class="dropdown">
        <ad-dropdown
          [items]="languageItems"
          formControlName="language"
        ></ad-dropdown>
      </div>
    </div>
    <div class="section">
      <div class="title text-p3--semibold">
        {{ 'SETTINGS_LANGUAGE_AND_FORMATS.FORMATS_TITLE' | translate }}
      </div>
      <div class="dropdown">
        <ad-dropdown
          [items]="unitsItems"
          [label]="'SETTINGS_LANGUAGE_AND_FORMATS.UNITS_LABEL' | translate"
          formControlName="unitSystem"
        ></ad-dropdown>
      </div>
      <div class="dropdown">
        <ad-dropdown
          [items]="dateFormatItems"
          [label]="
            'SETTINGS_LANGUAGE_AND_FORMATS.DATE_FORMAT_LABEL' | translate
          "
          formControlName="dateFormat"
        ></ad-dropdown>
      </div>
      <div class="dropdown">
        <ad-dropdown
          [items]="durationFormatItems"
          [label]="'SETTINGS_LANGUAGE_AND_FORMATS.DURATION_LABEL' | translate"
          formControlName="durationFormat"
        ></ad-dropdown>
      </div>
      <div class="dropdown">
        <ad-dropdown
          [items]="hoursFormatItems"
          [label]="'SETTINGS_LANGUAGE_AND_FORMATS.HOURS_LABEL' | translate"
          formControlName="hoursFormat"
        ></ad-dropdown>
      </div>
    </div>
  </form>
</astus-settings-button-layout>
