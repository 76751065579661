<main class="main-container">
  <section
    [ngClass]="{
      'small-container': !displayInfobox,
      'small-container--infobox': displayInfobox
    }"
  >
    <div class="widget-container">
      <ad-tab-widget>
        <ad-widget
          *ngIf="showCircuits$ | async"
          (widgetClick)="onChangeTab('circuits')"
          [label]="'RESOURCES_WIDGET.CIRCUITS' | translate"
          [info]="1200"
          status="{{
            'RESOURCES_WIDGET.PROBLEMS' | translate : circuitsProblemsData
          }}"
          [selected]="selectedTab === 'circuits'"
        >
          <ad-icon
            class="widget-icon"
            glyph="alert"
            statusIcon
            [color]="selectedTab === 'circuits' ? 'primary-200' : 'neutral-400'"
          ></ad-icon>
        </ad-widget>
        <ad-widget
          (widgetClick)="onChangeTab('vehicles')"
          [label]="'RESOURCES_WIDGET.VEHICLES' | translate"
          [info]="120"
          status="{{
            'RESOURCES_WIDGET.PROBLEMS' | translate : vehiclesProblemsData
          }}"
          [selected]="selectedTab === 'vehicles'"
        >
          <ad-icon
            class="widget-icon"
            glyph="alert"
            statusIcon
            [color]="selectedTab === 'vehicles' ? 'primary-200' : 'neutral-400'"
          ></ad-icon>
        </ad-widget>
        <ad-widget
          (widgetClick)="onChangeTab('users')"
          [label]="'RESOURCES_WIDGET.USERS' | translate"
          [info]="94"
          status="{{
            'RESOURCES_WIDGET.PROBLEMS' | translate : driversProblemsData
          }}"
          [selected]="selectedTab === 'users'"
        >
          <ad-icon
            class="widget-icon"
            glyph="alert"
            statusIcon
            [color]="selectedTab === 'users' ? 'primary-200' : 'neutral-400'"
          ></ad-icon>
        </ad-widget>
      </ad-tab-widget>
    </div>
    <div class="search-bar">
      <!-- Search bar -->
      <astus-search-input
        [placeholder]="'RESOURCES_SEARCH-BAR.SEARCH.PLACEHOLDER' | translate"
        (searchEmitter)="searchHandler($event)"
        [value]="searchValue"
      ></astus-search-input>
      <astus-vehicle-list-columns *ngIf="selectedTab === 'vehicles'">
      </astus-vehicle-list-columns>
      <!-- Share icon -->
      <div class="share-icon-container">
        <ad-icon
          glyph="share"
          class="share-icon"
          (click)="onClickCopyAlert()"
        ></ad-icon>
      </div>
    </div>

    <ad-table
      data-test-ressources-table
      *ngIf="data.length > 0"
      [data]="data"
      [headers]="headers"
      [stickyColumns]="fixedColumns"
      [sorting]="true"
      [forceActiveRow]="currentIndexRow"
      (tableRowSelection)="onTableRowClick($event)"
    ></ad-table>
    <div class="empty-table" *ngIf="data.length === 0">
      <ad-table-empty-state
        [icon]="iconGlyph(selectedTab)"
        [errorMessage]="errorMessage(selectedTab, translate)"
        [errorDescription]="errorDescription(selectedTab, translate)"
      >
      </ad-table-empty-state>
    </div>
  </section>
  <aside [class]="showInfoboxClass + ' aside-container'">
    <astus-upload
      *ngIf="!displayInfobox && isMobile"
      [isMobile]="isMobile"
      [selectedTab]="selectedTab"
      [errorMessage]="widgetErrorMessage"
      [progressMessage]="widgetProgressMessage"
      (createResourcesButtonClick)="onCreateResourcesClick()"
    ></astus-upload>

    <!-- DESKTOP: Infobox and Forms -->
    <astus-infobox
      *ngIf="displayInfobox && !isMobile"
      (cancelClick)="onInfoboxCancelClick()"
      (saveClick)="onInfoboxSaveClick()"
      [isEditing]="isEditMode"
      [infoboxType]="selectedTab"
      [form]="selectedTab === 'vehicles' ? vehicleForm : userForm"
    >
      <!-- Vehicle form -->
      <div infoBoxForm *ngIf="selectedTab === 'vehicles'">
        <astus-vehicle-form
          [data]="vehicleDataToEdit"
          (vehicleFormEmitter)="onVehicleFormChange($event)"
          [isEditing]="isEditMode"
        >
        </astus-vehicle-form>
      </div>

      <!-- User form -->
      <div infoBoxForm *ngIf="selectedTab === 'users'">
        <astus-user-form
          (userFormEmitter)="onUserFormChange($event)"
          [userFormValues]="userFormToEdit"
        ></astus-user-form>
      </div>
    </astus-infobox>

    <!-- MOBILE: Infobox and Forms within the Mobiile Drawer -->
    <astus-infobox-drawer *ngIf="displayInfobox && isMobile">
      <div drawerContent>
        <!-- TODO: Handling the isPristine / isEditing for userForm in another PR -->
        <!-- => https://myastus.atlassian.net/browse/HRZN-33 -->
        <astus-infobox
          (cancelClick)="onInfoboxCancelClick()"
          (saveClick)="onInfoboxSaveClick()"
          [isEditing]="isEditMode"
          [infoboxType]="selectedTab"
          [form]="selectedTab === 'vehicles' ? vehicleForm : userForm"
        >
          <!-- Vehicle form -->
          <div infoBoxForm *ngIf="selectedTab === 'vehicles'">
            <astus-vehicle-form
              [data]="vehicleDataToEdit"
              (vehicleFormEmitter)="onVehicleFormChange($event)"
              [isEditing]="isEditMode"
            >
            </astus-vehicle-form>
          </div>

          <!-- User form -->
          <div infoBoxForm *ngIf="selectedTab === 'users'">
            <astus-user-form
              (userFormEmitter)="onUserFormChange($event)"
              [userFormValues]="userFormToEdit"
            ></astus-user-form>
          </div>
        </astus-infobox>
      </div>
    </astus-infobox-drawer>
  </aside>
</main>
