import { CommonModule } from '@angular/common';
import { HttpBackend } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonNgDesignSystemModule } from '@common/ng-design-system';
import { GoogleMapsModule } from '@fms/maps/ng-google-maps';
import { MapboxModule } from '@fms/maps/ng-mapbox';
import { TranslateModule } from '@ngx-translate/core';

import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

import languaguesImport from '../../assets/languages-import';
import { environment } from '../../environments/environment';

import { MultiSelectDropdownComponent } from '../components/vehicle-sort-filter/multi-select-dropdown/multi-select-dropdown.component';
import { VehicleSortFilterComponent } from '../components/vehicle-sort-filter/vehicle-sort-filter.component';
import { DateFormatPipe } from '../pipes/dateformat/dateformat.pipe';
import { DistancePipe } from '../pipes/distance/distance.pipe';
import { DurationPipe } from '../pipes/duration/duration.pipe';
import { HoursFormatPipe } from '../pipes/hours-format/hours-format.pipe';

import { AgendaCardComponent } from './agenda/agenda-card/agenda-card.component';
import { AgendaItemHeaderComponent } from './agenda/agenda-item-header/agenda-item-header.component';
import { AgendaComponent } from './agenda/agenda.component';
import { SupervisionTabsComponent } from './agenda/supervision-tabs/supervision-tabs.component';

import { MapAssociationModalComponent } from './map-association-modal/map-association-modal.component';
import { MapCanvasComponent } from './map-canvas/map-canvas.component';
import { MapControlPanelComponent } from './map-control-panel/map-control-panel.component';
import { MapInfoPanelComponent } from './map-info-panel/map-info-panel.component';
import { CircuitMonitoringCardComponent } from './map-info-panel/monitoring-card/circuit-monitoring-card/circuit-monitoring-card.component';
import { RouteMonitoringCardComponent } from './map-info-panel/monitoring-card/route-monitoring-card/route-monitoring-card.component';
import { StopMonitoringCardComponent } from './map-info-panel/monitoring-card/stop-monitoring-card/stop-monitoring-card.component';
import { UserMonitoringCardComponent } from './map-info-panel/monitoring-card/user-monitoring-card/user-monitoring-card.component';
import { VehicleMonitoringCardComponent } from './map-info-panel/monitoring-card/vehicle-monitoring-card/vehicle-monitoring-card.component';
import { MapLayerPreviewComponent } from './map-layer-preview/map-layer-preview.component';
import { MapLayerSelectorComponent } from './map-layer-selector/map-layer-selector.component';
import { MapLayerSelectorButtonComponent } from './map-layer-selector-button/map-layer-selector-button.component';
import { MapListCircuitCardComponent } from './map-list/map-list-circuit-card/map-list-circuit-card.component';
import { MapListEmptyStateComponent } from './map-list/map-list-empty-state/map-list-empty-state.component';
import { MapListUserCardComponent } from './map-list/map-list-user-card/map-list-user-card.component';
import { MapListVehicleCardComponent } from './map-list/map-list-vehicle-card/map-list-vehicle-card.component';
import { MapListComponent } from './map-list/map-list.component';
import { MapRoutingModule } from './map-routing.module';
import { MapUiComponent } from './map-ui/map-ui.component';
import { MapViewTabsButtonComponent } from './map-view-tabs-button/map-view-tabs-button.component';
import { MapComponent } from './supervision.component';

export function HttpLoaderFactory(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, languaguesImport);
}

@NgModule({
  imports: [
    CommonModule,
    CommonNgDesignSystemModule,
    MapboxModule.forRoot(environment.mapbox),
    GoogleMapsModule.forRoot(environment.googleMaps),
    TranslateModule,
    DateFormatPipe,
    DurationPipe,
    HoursFormatPipe,
    MapRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    MapComponent,
    MapCanvasComponent,
    MapListComponent,
    MapControlPanelComponent,
    MapUiComponent,
    MapLayerSelectorComponent,
    MapLayerSelectorButtonComponent,
    MapLayerPreviewComponent,
    MapInfoPanelComponent,
    MapAssociationModalComponent,
    SupervisionTabsComponent,
    AgendaItemHeaderComponent,
    MapViewTabsButtonComponent,
    AgendaCardComponent,
    VehicleSortFilterComponent,
    MultiSelectDropdownComponent,
    AgendaComponent,
    VehicleMonitoringCardComponent,
    UserMonitoringCardComponent,
    StopMonitoringCardComponent,
    RouteMonitoringCardComponent,
    CircuitMonitoringCardComponent,
    MapListVehicleCardComponent,
    MapListCircuitCardComponent,
    MapListUserCardComponent,
    MapListEmptyStateComponent,
  ],
  providers: [
    DateFormatPipe,
    DistancePipe,
    DurationPipe,
    HoursFormatPipe,
    MapListVehicleCardComponent,
    MapListCircuitCardComponent,
  ],
  bootstrap: [MapComponent],
  exports: [
    AgendaCardComponent,
    VehicleSortFilterComponent,
    MultiSelectDropdownComponent,
  ],
})
export class SupervisionModule {}
