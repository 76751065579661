import { tableHeadersType } from '@common/ng-design-system';

import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subscription } from 'rxjs';

const circuitTranslationKeys = [
  'TABLE_HEADERS.CIRCUIT_HEADERS.UPDATE',
  'TABLE_HEADERS.CIRCUIT_HEADERS.TIME',
  'TABLE_HEADERS.CIRCUIT_HEADERS.NAME',
  'TABLE_HEADERS.CIRCUIT_HEADERS.STUDENTS',
  'TABLE_HEADERS.CIRCUIT_HEADERS.TYPE',
  'TABLE_HEADERS.CIRCUIT_HEADERS.SCHOOL_BOARD',
  'TABLE_HEADERS.CIRCUIT_HEADERS.CLIENT',
  'TABLE_HEADERS.CIRCUIT_HEADERS.STOPS',
  'TABLE_HEADERS.CIRCUIT_HEADERS.ROUTES',
  'TABLE_HEADERS.CIRCUIT_HEADERS.VEHICLE',
  'TABLE_HEADERS.CIRCUIT_HEADERS.USER',
  'TABLE_HEADERS.CIRCUIT_HEADERS.TRANSPORTER',
  'TABLE_HEADERS.CIRCUIT_HEADERS.LOCATION',
];

const vehicleTranslationKeys = [
  'TABLE_HEADERS.VEHICLE_HEADERS.UPDATE',
  'TABLE_HEADERS.VEHICLE_HEADERS.NAME',
  'TABLE_HEADERS.VEHICLE_HEADERS.VEHICLE_TYPE',
  'TABLE_HEADERS.VEHICLE_HEADERS.SEATS',
  'TABLE_HEADERS.VEHICLE_HEADERS.DISTANCE_REMAINING',
  'TABLE_HEADERS.VEHICLE_HEADERS.FUEL_TYPE',
  'TABLE_HEADERS.VEHICLE_HEADERS.LAST_POSITION',
  'TABLE_HEADERS.VEHICLE_HEADERS.CIRCUITS',
  'TABLE_HEADERS.VEHICLE_HEADERS.ODOMETER',
  'TABLE_HEADERS.VEHICLE_HEADERS.VIN',
  'TABLE_HEADERS.VEHICLE_HEADERS.PLATE_NUMBER',
  'TABLE_HEADERS.VEHICLE_HEADERS.MODEL',
  'TABLE_HEADERS.VEHICLE_HEADERS.YEAR',
  'TABLE_HEADERS.VEHICLE_HEADERS.TRANSPORTER',
  'TABLE_HEADERS.VEHICLE_HEADERS.FLEET',
  'TABLE_HEADERS.VEHICLE_HEADERS.MODULE',
  'TABLE_HEADERS.VEHICLE_HEADERS.SOFTWARE_VERSION',
  'TABLE_HEADERS.VEHICLE_HEADERS.TAG_TYPE',
  'TABLE_HEADERS.VEHICLE_HEADERS.LAST_CONNECTION',
  'TABLE_HEADERS.VEHICLE_HEADERS.MAINTENANCE',
  'TABLE_HEADERS.VEHICLE_HEADERS.COUNTRY',
];

const driverTranslationKeys = [
  'TABLE_HEADERS.USER_HEADERS.UPDATE',
  'TABLE_HEADERS.USER_HEADERS.NAME',
  'TABLE_HEADERS.USER_HEADERS.HOS',
  'TABLE_HEADERS.USER_HEADERS.TELEPHONE',
  'TABLE_HEADERS.USER_HEADERS.EMAIL',
  'TABLE_HEADERS.USER_HEADERS.CIRCUITS',
  'TABLE_HEADERS.USER_HEADERS.VEHICLE_PREF',
  'TABLE_HEADERS.USER_HEADERS.EMPLOYEE_NO',
  'TABLE_HEADERS.USER_HEADERS.EMPLOYEE_EXP',
  'TABLE_HEADERS.USER_HEADERS.PERMIT_CLASS',
  'TABLE_HEADERS.USER_HEADERS.PERMIT',
  'TABLE_HEADERS.USER_HEADERS.PERMIT_EXP',
  'TABLE_HEADERS.USER_HEADERS.TRANSPORTER',
];

const horizonTableReportTranslationKeys = [
  'TABLE_HEADERS.HORIZON_REPORTS_HEADERS.CREATION_DATE',
  'TABLE_HEADERS.HORIZON_REPORTS_HEADERS.REPORT_NAME',
  'TABLE_HEADERS.HORIZON_REPORTS_HEADERS.RESOURCE',
  'TABLE_HEADERS.HORIZON_REPORTS_HEADERS.DATE',
  'TABLE_HEADERS.HORIZON_REPORTS_HEADERS.CREATED_BY',
  'TABLE_HEADERS.HORIZON_REPORTS_HEADERS.STATUS',
  'TABLE_HEADERS.HORIZON_REPORTS_HEADERS.EXPORT',
];

export const getCircuitHeaderTranslations = (
  translate: TranslateService
): Subscription => {
  return combineLatest(
    circuitTranslationKeys.map((text) => translate.stream(text))
  ).subscribe((res) =>
    res.forEach((text, index) => {
      tableCircuitHeaders[index].label = text;
    })
  );
};

export const getDriverHeaderTranslations = (
  translate: TranslateService
): Subscription => {
  return combineLatest(
    driverTranslationKeys.map((text) => translate.stream(text))
  ).subscribe((res) =>
    res.forEach((text, index) => {
      tableDriverHeaders[index].label = text;
    })
  );
};

export const getVehicleHeaderTranslations = (translate: TranslateService) => {
  return combineLatest(
    vehicleTranslationKeys.map((text) => translate.stream(text))
  );
};

export const getHorizonTableReportHeaderTranslations = (
  translate: TranslateService
): Subscription => {
  return combineLatest(
    horizonTableReportTranslationKeys.map((text) => translate.stream(text))
  ).subscribe((res) =>
    res.forEach((text, index) => {
      horizonTableReportHeaders[index].label = text;
    })
  );
};

export const getHeaderTranslations = (translate: TranslateService): void => {
  getCircuitHeaderTranslations(translate);
  getDriverHeaderTranslations(translate);
  getHorizonTableReportHeaderTranslations(translate);
};

export const tableCircuitHeaders: tableHeadersType = [
  {
    label: '',
    dataKey: 'timeLeft',
  },
  {
    label: '',
    dataKey: 'time',
  },
  {
    label: '',
    dataKey: 'name',
  },
  {
    label: '',
    dataKey: 'studentsTotal',
  },
  {
    label: '',
    dataKey: 'type',
  },
  {
    label: '',
    dataKey: 'schoolBoard',
  },
  {
    label: '',
    dataKey: 'client',
  },
  {
    label: '',
    dataKey: 'stops',
  },
  {
    label: '',
    dataKey: 'routes',
  },
  {
    label: '',
    dataKey: 'vehicle',
  },
  {
    label: '',
    dataKey: 'driver',
  },
  {
    label: '',
    dataKey: 'transporter',
  },
  {
    label: '',
    dataKey: 'location',
  },
];

export const tableVehicleHeaders: tableHeadersType = [
  {
    label: '',
    dataKey: 'update',
  },
  {
    label: '',
    dataKey: 'name',
  },
  {
    label: '',
    dataKey: 'vehicleType',
  },
  {
    label: '',
    dataKey: 'seats',
  },
  {
    label: '',
    dataKey: 'distanceRemaining',
  },
  {
    label: '',
    dataKey: 'fuelType',
  },
  {
    label: '',
    dataKey: 'lastPosition',
  },
  {
    label: '',
    dataKey: 'circuits',
  },
  {
    label: '',
    dataKey: 'odometer',
  },
  {
    label: '',
    dataKey: 'vin',
  },
  {
    label: '',
    dataKey: 'plateNumber',
  },
  {
    label: '',
    dataKey: 'model',
  },
  {
    label: '',
    dataKey: 'year',
  },
  {
    label: '',
    dataKey: 'transporter',
  },
  {
    label: '',
    dataKey: 'fleet',
  },
  {
    label: '',
    dataKey: 'module',
  },
  {
    label: '',
    dataKey: 'softwareVersion',
  },
  {
    label: '',
    dataKey: 'tagType',
  },
  {
    label: '',
    dataKey: 'lastConnection',
  },
  {
    label: '',
    dataKey: 'maintenance',
  },
  {
    label: '',
    dataKey: 'country',
  },
];

export const tableDriverHeaders: tableHeadersType = [
  {
    label: '',
    dataKey: 'update',
  },
  {
    label: '',
    dataKey: 'name',
  },
  {
    label: '',
    dataKey: 'hos',
  },
  {
    label: '',
    dataKey: 'phone',
  },
  {
    label: '',
    dataKey: 'email',
  },
  {
    label: '',
    dataKey: 'circuits',
  },
  {
    label: '',
    dataKey: 'vehiclePref',
  },
  {
    label: '',
    dataKey: 'employeeNo',
  },
  {
    label: '',
    dataKey: 'employeeExp',
  },
  {
    label: '',
    dataKey: 'licenseClass',
  },
  {
    label: '',
    dataKey: 'licenseNumber',
  },
  {
    label: '',
    dataKey: 'permitExp',
  },
  {
    label: '',
    dataKey: 'transporter',
  },
];

export const horizonTableReportHeaders: tableHeadersType = [
  {
    label: '',
    dataKey: 'creationDate',
  },
  {
    label: '',
    dataKey: 'name',
  },
  {
    label: '',
    dataKey: 'resource',
  },
  {
    label: '',
    dataKey: 'date',
  },
  {
    label: '',
    dataKey: 'createdBy',
  },
  {
    label: '',
    dataKey: 'status',
  },
  {
    label: '',
    dataKey: 'export',
  },
];
