<div class="container">
  <ad-icon
    *ngIf="!isLiveMode"
    glyph="time-range"
    class="hour-picker-icon"
    [color]="'primary-400'"
  ></ad-icon>

  <ad-icon
    *ngIf="isLiveMode"
    glyph="play_realtime"
    class="hour-picker-icon"
    [color]="'primary-400'"
  ></ad-icon>

  <div class="hour-picker-container">
    <!-- First hours picker -->
    <div class="hour-picker" #firstHourPicker>
      <div
        class="text-p3 date-item-container"
        (click)="!isLiveMode && onFirstHourPickerClick()"
        [ngClass]="{
          selected: firstHourPickerShown && !isLiveMode,
          disable: isLiveMode
        }"
      >
        <span
          class="date-item"
          [ngClass]="{
            selected: firstHourPickerShown && !isLiveMode,
            disable: isLiveMode
          }"
        >
          {{ firstHourSelected | hoursFormat }}
        </span>
      </div>
      <ng-container
        *ngTemplateOutlet="
          buttonOverlay;
          context: {
            overlaysItems: firstHoursForPicker,
            overlayIsOpen: firstHourPickerShown
          }
        "
      ></ng-container>
    </div>

    <!-- Second hour picker -->
    <ng-container *ngIf="!isLiveMode">
      <!-- Separator -->
      <span class="text-p2"> - </span>

      <div class="hour-picker" #secondHourPicker>
        <div
          class="text-p3 date-item-container"
          (click)="onSecondHourPickerClick()"
          [ngClass]="{
            selected: secondHourPickerShown
          }"
        >
          <span
            class="date-item"
            [ngClass]="{
              selected: secondHourPickerShown
            }"
          >
            {{ secondHourSelected | hoursFormat }}
          </span>
        </div>
        <ng-container
          *ngTemplateOutlet="
            buttonOverlay;
            context: {
              overlaysItems: secondHoursForPicker,
              overlayIsOpen: secondHourPickerShown
            }
          "
        ></ng-container>
      </div>
    </ng-container>
  </div>
</div>

<!-- hours picker overlay dropdown -->
<ng-template
  #buttonOverlay
  let-overlaysItems="overlaysItems"
  let-overlayIsOpen="overlayIsOpen"
>
  <!-- button menu overlay -->
  <div
    class="overlay-menu"
    [ngClass]="{
      'overlay-menu--open': overlayIsOpen,
      'overlay-menu--close': !overlayIsOpen
    }"
  >
    <div class="overlay-menu-content">
      <ng-container *ngFor="let item of overlaysItems; let i = index">
        <button
          class="overlay-menu-item text-p3"
          (click)="onPickerItemClick(item)"
          [ngClass]="{
            'item-selected': firstHourPickerShown
              ? item.value === firstHourSelected
              : item.value === secondHourSelected,
            'item-disabled': item.disabled
          }"
        >
          <span class="clamp">{{ item.label }}</span>
        </button>
      </ng-container>
    </div>
  </div>
</ng-template>
