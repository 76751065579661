import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VehicleStateType } from '@fms/ng-fms-api-client';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { resourceTabType } from '../../app-routing.module';
import { DateFormatPipe } from '../../pipes/dateformat/dateformat.pipe';
import {
  controlPanelCardAllDataType,
  controlPanelCardDataRoutesInterface,
  controlPanelCardDataStopsInterface,
  controlPanelCardUsersInterface,
  controlPanelCircuitsCardInterface,
  controlPanelUserDetailsType,
} from '../map-control-panel/map-control-panel.component.d';
import { controlPanelUsersStatusEnum } from '../map-control-panel/map-control-panel.enum';
import { selectSupervisionUrl } from '../store';

@Component({
  selector: 'astus-map-info-panel',
  templateUrl: './map-info-panel.component.html',
  styleUrls: ['./map-info-panel.component.scss'],
})
export class MapInfoPanelComponent implements OnInit, OnDestroy, OnChanges {
  @Input() data: controlPanelCardAllDataType | undefined = undefined;

  @Input()
  previousData?: controlPanelCardAllDataType;

  @Input() previousCircuitData: controlPanelCircuitsCardInterface | undefined =
    undefined;

  @Input() previousRouteData: controlPanelCardDataRoutesInterface | undefined =
    undefined;

  @Input()
  category = '';

  @Input()
  isOpen?: boolean;

  @Output() isOpenChange = new EventEmitter<boolean>();

  @Output() previousRouteDataChanged =
    new EventEmitter<controlPanelCardDataRoutesInterface>();

  @Output() previousCircuitDataChanged =
    new EventEmitter<controlPanelCircuitsCardInterface>();

  newData = false;
  circuitData?: controlPanelCircuitsCardInterface;
  routeData?: controlPanelCardDataRoutesInterface;

  moreOptions$ = new Subscription();

  container!: HTMLDivElement;

  translateSubscription: Subscription = new Subscription();
  VehicleStateType = VehicleStateType;
  controlPanelUsersStatusEnum = controlPanelUsersStatusEnum;

  supervisionSelectedUrl: string | null = null;

  storeSubscription: Subscription[] = [];

  constructor(
    private containerRef: ElementRef<HTMLDivElement>,
    private dateFormatPipe: DateFormatPipe,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private router: Router
  ) {}

  ngOnInit(): void {
    // Get the url of the supervision mode precalculated
    this.storeSubscription = [
      ...this.storeSubscription,
      this.store.select(selectSupervisionUrl()).subscribe((url) => {
        this.supervisionSelectedUrl = url;
      }),
    ];
  }

  ngOnChanges(): void {
    this.container = this.containerRef.nativeElement;

    const observer = new MutationObserver(() => {
      const boxContainer = this.container.getElementsByClassName(
        'box'
      )[0] as HTMLDivElement;
      const infoContainer = boxContainer?.children[0]
        ?.firstChild as HTMLDivElement;

      if (infoContainer) {
        boxContainer.style.overflow = 'hidden';
        boxContainer.style.maxHeight = 100 + '%';

        setTimeout(() => {
          boxContainer.style.overflow = 'visible';
        }, 300);
      }
    });

    // Observe changes in the container's content
    observer.observe(this.container, { childList: true, subtree: true });

    if (this.data?.cardType === 'user') {
      this.data = this.formatDriverData(this.data);
    }
  }

  ngOnDestroy(): void {
    this.moreOptions$.unsubscribe();
    this.translateSubscription.unsubscribe();
    this.storeSubscription.forEach((subscription) =>
      subscription.unsubscribe()
    );
  }

  formatDriverData(
    driver: controlPanelCardUsersInterface
  ): controlPanelCardUsersInterface {
    const userDetails = {
      ...driver.monitoringUser?.userDetails,
    } as controlPanelUserDetailsType;

    return {
      ...driver,
      monitoringUser: {
        ...driver.monitoringUser,
        userDetails,
      },
    };
  }

  /**
   * @description
   * Handle the click event on the info box button.
   * @returns {void}
   */
  handleInfoboxButtonClick(): void {
    this.isOpen = !this.isOpen;
    this.isOpenChange.emit(this.isOpen);
  }

  /**
   * @description
   * Handle the click event to go back to the previous circuit card.
   * If there is previous data, it will display it in the card info box and update the url.
   * If not, it will display the circuit data.
   * @param {CardTypes} previousCircuitData - Previous data to display in the card info box when going back.
   * @returns {void}
   */
  goBackToCircuit(
    previousCircuitData?: controlPanelCircuitsCardInterface
  ): void {
    if (previousCircuitData) {
      this.router.navigateByUrl(
        `${this.supervisionSelectedUrl}/${resourceTabType.CIRCUITS}/${
          this.circuitData
            ? this.circuitData.id
            : previousCircuitData.id?.toString()
        }`
      );
      this.data = previousCircuitData;
    } else {
      this.router.navigateByUrl(
        `${this.supervisionSelectedUrl}/${
          resourceTabType.CIRCUITS
        }/${this.circuitData?.id?.toString()}`
      );
      this.data = JSON.parse(JSON.stringify(this.circuitData));
    }
  }

  /**
   * @description
   * Handle the click event to go back to the previous route card.
   * If there is previous data, it will display it in the card info box and update the url.
   * If not, it will display the route data.
   * @param {controlPanelCardDataRoutesInterface} previousRouteData - Previous data to display in the card info box when going back.
   * @returns {void}
   */
  goBackToRoute(previousRouteData?: controlPanelCardDataRoutesInterface): void {
    if (previousRouteData) {
      const idCircuit = this.activatedRoute.snapshot.paramMap.get('id');

      this.router.navigateByUrl(
        `${this.supervisionSelectedUrl}/${resourceTabType.CIRCUITS}/${
          this.circuitData ? this.circuitData.id : idCircuit
        }/${resourceTabType.ROUTES}/${previousRouteData.id?.toString()}`
      );
      this.data = previousRouteData;
    } else {
      this.data = JSON.parse(JSON.stringify(this.routeData));
    }
  }

  /**
   * @description
   * Handle the click event to select a route.
   * It will display the route data in the card info box and update the url.
   * @param {controlPanelCardDataRoutesInterface} route
   * @returns {void}
   */
  selectRoute(route: controlPanelCardDataRoutesInterface): void {
    this.circuitData = JSON.parse(JSON.stringify(this.data));
    this.previousCircuitData = this.data;
    this.previousCircuitDataChanged.emit(this.previousCircuitData);

    this.router.navigateByUrl(
      `${this.supervisionSelectedUrl}/${resourceTabType.CIRCUITS}/${this.circuitData?.id}/${resourceTabType.ROUTES}/${route.id}`
    );
    // TODO Add logic to change the display in the infobox to edit the route
    this.data = route as controlPanelCardDataRoutesInterface;
  }

  /**
   * @description
   * Handle the click event to select a stop.
   * @param {controlPanelCardDataStopsInterface} stop
   * @returns {void}
   */
  selectStop(stop: controlPanelCardDataStopsInterface): void {
    const idCircuit = this.circuitData
      ? this.circuitData.id
      : this.activatedRoute.snapshot.paramMap.get('id');

    this.routeData = JSON.parse(JSON.stringify(this.data));
    this.previousRouteData = this.data;
    this.previousRouteDataChanged.emit(this.previousRouteData);

    this.router.navigateByUrl(
      `${this.supervisionSelectedUrl}/${resourceTabType.CIRCUITS}/${idCircuit}/${resourceTabType.ROUTES}/${this.data?.id}/${resourceTabType.STOPS}/${stop.id}`
    );

    // TODO Add logic to change the display in the infobox to edit the stop
    this.data = stop as controlPanelCardDataStopsInterface;
  }
}
