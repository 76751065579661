<fieldset>
  <legend class="text-caption--semibold">{{ label }}</legend>

  <div class="input-ctn input-date">
    <button type="button" (click)="toggleCalendar()">
      {{ date | dateFormat }}
    </button>
    <div *ngIf="showCalendar">
      <ad-calendar
        [date]="date"
        [selectionMode]="'single'"
        [dateRangeFilters]="[]"
        (dateSelect)="handleDateSelect($event)"
        (hideCalendar)="handleHideCalendar($event)"
      ></ad-calendar>
    </div>
  </div>
  <div class="input-ctn input-time">
    <ad-dropdown
      [items]="timeArray"
      [value]="time"
      (dropdownElementChange)="onTimeChange($event)"
      (click)="closeCalendar()"
    ></ad-dropdown>
  </div>
</fieldset>
