import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'astus-infobox-drawer',
  templateUrl: './infobox-drawer.component.html',
  styleUrls: ['./infobox-drawer.component.scss'],
})
export class InfoboxDrawerComponent {
  @ViewChild('drawer') drawer!: ElementRef<HTMLDivElement>;

  // projected content
  drawerContent!: ElementRef;

  // component state
  dragPosition = { x: 0, y: 0 };
}
