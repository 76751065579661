import { TranslateService } from '@ngx-translate/core';

export type resourceDataType = 'circuits' | 'vehicles' | 'users';

export const emptyStateMessage = (
  dataType: resourceDataType,
  translate: TranslateService
) => {
  let message;
  switch (dataType) {
    case 'circuits':
      translate
        .stream('TABLE_EMPTY_STATE.CIRCUITS.MESSAGE')
        .subscribe((res) => (message = res));
      return message;
    case 'vehicles':
      translate
        .stream('TABLE_EMPTY_STATE.VEHICLES.MESSAGE')
        .subscribe((res) => (message = res));
      return message;
    case 'users':
      translate
        .stream('TABLE_EMPTY_STATE.USERS.MESSAGE')
        .subscribe((res) => (message = res));
      return message;
  }
};

export const emptyStateDescription = (
  dataType: resourceDataType,
  translate: TranslateService
) => {
  let description;
  switch (dataType) {
    case 'circuits':
      translate
        .stream('TABLE_EMPTY_STATE.CIRCUITS.DESCRIPTION')
        .subscribe((res) => (description = res));
      return description;
    case 'vehicles':
      translate
        .stream('TABLE_EMPTY_STATE.VEHICLES.DESCRIPTION')
        .subscribe((res) => (description = res));
      return description;
    case 'users':
      translate
        .stream('TABLE_EMPTY_STATE.USERS.DESCRIPTION')
        .subscribe((res) => (description = res));
      return description;
  }
};

export const emptyStateIcon = (dataType: resourceDataType) => {
  switch (dataType) {
    case 'circuits':
      return 'circuit';
    case 'vehicles':
      return 'bus';
    case 'users':
      return 'profile';
  }
};
