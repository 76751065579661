<div
  *ngFor="let alert of alerts; trackBy: alertId"
  class="alert-container"
  [ngClass]="{ 'alert-container--closing': alert.hide }"
>
  <div class="alert-container__inner">
    <div class="alert-container__inner-small">
      <ad-alert
        [id]="alert.id"
        [title]="alert.title"
        [color]="alert.colorType"
        [icon]="alert.icon"
        [showCloseButton]="true"
        (closeEvent)="onCloseAlert(alert.id)"
      ></ad-alert>
    </div>
  </div>
</div>
