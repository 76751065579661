<div class="map-container">
  <div class="tab-container">
    <astus-supervision-tabs
      *ngIf="agendaFlagValue$ | async"
      class="supervision-tabs-container"
      (supervisionTabSelect)="onSupervisionTabSelect($event)"
      [buttonTabSelected]="supervisionModeSelected"
    ></astus-supervision-tabs>

    <astus-agenda
      *ngIf="agendaFlagValue$ | async"
      [agendaItemsElement]="agendasItems"
      class="agenda-container"
      [ngClass]="{
        'inactive-tab': supervisionModeSelected !== buttonTabs.AGENDA,
        'active-tab': supervisionModeSelected === buttonTabs.AGENDA,
      }"
    ></astus-agenda>

    <astus-map-canvas
      [ngClass]="{
        'inactive-tab': supervisionModeSelected !== buttonTabs.MAP,
        'active-tab': supervisionModeSelected === buttonTabs.MAP,
      }"
      [(vehicleToDisplay)]="vehicleToDisplay"
      [(circuitToDisplay)]="circuitToDisplay"
      [mapboxMap]="mapboxMap"
      (mapboxMapChange)="mapboxMapChanges($event)"
      [(tab)]="tab"
      [id]="id"
      [(vehicleMarkerSelected)]="vehicleMarkerSelected"
      [(circuitMarkerSelected)]="circuitMarkerSelected"
      (vehicleMarkerSelectedChange)="vehicleMarkerSelectedChange.emit($event)"
      (circuitMarkerSelectedChange)="circuitMarkerSelectedChange.emit($event)"
      class="map-canvas"
      (vehicleToDisplayChange)="vehicleToDisplayChanges($event)"
      (circuitToDisplayChange)="circuitToDisplayChanges($event)"
      (idChange)="idChanges($event)"
      (vehiclesOnMap)="changeVehiclesOnMap($event)"
    ></astus-map-canvas>
  </div>
  <astus-map-control-panel
    [mapboxMap]="mapboxMap"
    [(vehicleToDisplay)]="vehicleToDisplay"
    [(circuitToDisplay)]="circuitToDisplay"
    [(vehicleMarkerSelected)]="vehicleMarkerSelected"
    (vehicleMarkerSelectedChange)="vehicleMarkerSelectedChange.emit($event)"
    [(circuitMarkerSelected)]="circuitMarkerSelected"
    (circuitMarkerSelectedChange)="circuitMarkerSelectedChange.emit($event)"
    [(tab)]="tab"
    [(id)]="id"
    (tabChange)="changeRoute($event)"
    [vehiclesOnMap]="vehiclesOnMap"
    class="map-control-panel"
  ></astus-map-control-panel>
</div>
