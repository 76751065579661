import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LaunchDarklyService } from '@common/ts-feature-flag';
import { CircuitView, VehicleView } from '@fms/ng-fms-api-client';

import { Store } from '@ngrx/store';
import {
  combineLatest,
  filter,
  startWith,
  Subject,
  Subscription,
  takeUntil,
} from 'rxjs';

import { appRoutesEnum, resourceTabType } from '../app-routing.module';

import { AgendaItemElement } from './agenda/supervision-tabs/supervision-tabs.component';

import { supervisionRoutesEnum } from './map-routing.module';
import { MapTabValueEnum } from './map-tab.enum';
import { buttonTabEnum } from './map-view-tabs-button/map-view-tabs-button.component';
import {
  mapAPIActions,
  selectAgendasItemsFeature,
  selectSupervisionModeSelected,
  selectSupervisionSegmentUrl,
  selectSupervisionUrl,
} from './store';

@Component({
  selector: 'astus-map',
  templateUrl: './supervision.component.html',
  styleUrls: ['./supervision.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
  @Input() vehicleToDisplay: VehicleView | undefined;
  @Input() circuitToDisplay: CircuitView | undefined;
  @Input() vehicleMarkerSelected = false;
  @Input() circuitMarkerSelected = false;
  @Input() id: string | null = null;
  @Input() vehiclesOnMap: string[] = [];

  @Input() tab: MapTabValueEnum = MapTabValueEnum.CIRCUITS;
  @Input() mapboxMap: mapboxgl.Map | null = null;

  @Output() vehicleToDisplayChange = new EventEmitter<VehicleView>();
  @Output() circuitToDisplayChange = new EventEmitter<CircuitView>();
  @Output() vehicleMarkerSelectedChange = new EventEmitter<boolean>();
  @Output() circuitMarkerSelectedChange = new EventEmitter<boolean>();
  @Output() idChange = new EventEmitter<string | null>();

  @Output() tabChange = new EventEmitter<MapTabValueEnum>();
  @Output() mapboxMapChange = new EventEmitter<mapboxgl.Map | null>();

  cardTextTranslations: string[] = [];

  agendasItems: AgendaItemElement[] = [];
  agendaFlagValue$ = this.launchDarklyService.getFlagValue$('agenda');
  agendaFlagValueSub: Subscription = new Subscription();

  buttonTabs = buttonTabEnum;
  supervisionModeSelected: buttonTabEnum | null = null;
  supervisionUrl: string | null = null;

  circuitsFlag$ = this.launchDarklyService.getFlagValue$('Map_Circuits');
  circuitsFlagSub = new Subscription();
  circuitsFlagValue = false;
  vehiclesFlag$ = this.launchDarklyService.getFlagValue$('Map_Vehicules');
  vehiclesFlagSub = new Subscription();
  vehiclesFlagValue = false;
  chauffeursFlag$ = this.launchDarklyService.getFlagValue$('Map_Chauffeurs');
  chauffeursFlagSub = new Subscription();
  chauffeursFlagValue = false;

  storeSubscription: Subscription[] = [];

  supervisionSegmentUrl: string | null = null;

  destroy$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private launchDarklyService: LaunchDarklyService,
    private store: Store,
    private el: ElementRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(this.router)
      )
      // the event value should be an instance of NavigationEnd
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((event: any) => {
        const segmentUrl = this.getSegmentUrl(event.url || '');
        if (segmentUrl) {
          this.store.dispatch(
            mapAPIActions.supervisionSegmentUrl({
              supervisionSegmentUrl: segmentUrl,
            })
          );
        }
      });

    // Get and preformat the data from the store as agendaItems
    this.storeSubscription = [
      ...this.storeSubscription,
      this.store.select(selectAgendasItemsFeature).subscribe((agendasItems) => {
        this.agendasItems = agendasItems;
      }),
    ];

    // Get the supervision mode selected from the store
    this.storeSubscription = [
      ...this.storeSubscription,
      this.store.select(selectSupervisionModeSelected).subscribe((mode) => {
        this.supervisionModeSelected = mode;
      }),
    ];

    this.store.select(selectSupervisionSegmentUrl).subscribe((url) => {
      this.supervisionSegmentUrl = url;
    });

    this.agendaFlagValueSub = this.agendaFlagValue$.subscribe(
      (agendaFlagValue) => {
        const urlToDisable = agendaFlagValue
          ? []
          : [supervisionRoutesEnum.AGENDA];

        this.storeSubscription = [
          ...this.storeSubscription,
          this.store
            .select(selectSupervisionUrl(urlToDisable))
            .subscribe((url) => {
              this.supervisionUrl = url;
            }),
        ];

        this.onSupervisionTabSelect(
          agendaFlagValue
            ? this.supervisionModeSelected || buttonTabEnum.MAP
            : buttonTabEnum.MAP
        );
      }
    );

    this.initFlags();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.storeSubscription.forEach((subscription) =>
      subscription.unsubscribe()
    );
    this.agendaFlagValueSub.unsubscribe();
  }

  /**
   * @description
   * Change the tab based on the event value and navigate to the new route based on the tab value.
   * If the tab value is circuits, then navigate to /map/circuits.
   * If the tab value is vehicles, then navigate to /map/vehicles.
   * If the tab value is drivers, then navigate to /map/drivers.
   * @param {MapTabValueEnum} event - The tab value to navigate to.
   * @returns {void}
   */
  changeRoute(event: MapTabValueEnum): void {
    let resourceSelected = '';
    if (event === MapTabValueEnum.CIRCUITS) {
      resourceSelected = resourceTabType.CIRCUITS;
    }

    if (event === MapTabValueEnum.VEHICLES) {
      resourceSelected = resourceTabType.VEHICLES;
    }

    if (event === MapTabValueEnum.USERS) {
      resourceSelected = resourceTabType.DRIVERS;
    }

    const url = `${this.supervisionUrl}/${resourceSelected}`;
    this.router.navigateByUrl(url);
  }

  initFlags(): void {
    combineLatest([
      this.circuitsFlag$,
      this.vehiclesFlag$,
      this.chauffeursFlag$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([circuits, vehicles, chauffeurs]) => {
        this.circuitsFlagValue = circuits;
        this.vehiclesFlagValue = vehicles;
        this.chauffeursFlagValue = chauffeurs;
        this.initTab();
      });
  }

  /**
   * @description
   * Init the tab based on the url param
   * Set the search history based on the tab
   * If there is no tab param, set the default tab to 0
   * @returns {void}
   */
  initTab(): void {
    const tab = this.activatedRoute.snapshot.paramMap.get('tab');
    this.id = this.activatedRoute.snapshot.paramMap.get('id');

    const tabMappings: { [key: string]: MapTabValueEnum } = {
      circuit: MapTabValueEnum.CIRCUITS,
      circuits: MapTabValueEnum.CIRCUITS,
      vehicle: MapTabValueEnum.VEHICLES,
      vehicles: MapTabValueEnum.VEHICLES,
      user: MapTabValueEnum.USERS,
      users: MapTabValueEnum.USERS,
    };

    if (tab && tabMappings[tab] !== undefined && tabMappings[tab] !== null) {
      this.tab = tabMappings[tab];
    } else {
      let defaultTab = MapTabValueEnum.VEHICLES;
      let defaultURL = appRoutesEnum.VEHICLES;

      if (this.circuitsFlagValue) {
        defaultTab = MapTabValueEnum.CIRCUITS;
        defaultURL = appRoutesEnum.CIRCUITS;
      } else if (
        this.chauffeursFlagValue &&
        !this.vehiclesFlagValue &&
        !this.circuitsFlagValue
      ) {
        defaultTab = MapTabValueEnum.USERS;
        defaultURL = appRoutesEnum.USERS;
      }

      this.tab = defaultTab;
      this.router.navigateByUrl(`${this.supervisionUrl}/${defaultURL}`);
    }
  }

  /**
   * @description
   * On change the vehicle to display, emit the new vehicle to display and change the tab to VEHICLES if the vehicle is defined.
   * Otherwise, the map is reset and change the tab to VEHICLES. Emit undefined to the vehicle to display. This will hide the vehicle details.
   * @param {VehicleView | undefined} vehicle - The vehicle to display.
   * @returns {void}
   */
  vehicleToDisplayChanges(vehicle: VehicleView | undefined): void {
    if (vehicle) {
      this.vehicleToDisplayChange.emit(vehicle);
      this.tab = MapTabValueEnum.VEHICLES;
    } else {
      this.vehicleToDisplayChange.emit(undefined);
    }
  }

  /**
   * @description
   * On change the circuit to display, emit the new circuit to display and change the tab to CIRCUITS if the circuit is defined.
   * Otherwise, the map is reset and change the tab to CIRCUITS. Emit undefined to the circuit to display. This will hide the circuit details.
   * @param {CircuitView | undefined} circuit - The circuit to display.
   * @returns {void}
   */
  circuitToDisplayChanges(circuit: CircuitView | undefined): void {
    if (circuit) {
      this.circuitToDisplayChange.emit(circuit);
      this.tab = MapTabValueEnum.CIRCUITS;
    } else {
      this.circuitToDisplayChange.emit(undefined);
    }
  }

  /**
   * @description
   * On change the id, emit the new id.
   * This happens when the user clicks on a vehicle in the list
   * or on the map.
   * @param {string | null} id - The id of the vehicle.
   * @returns {void}
   */
  idChanges(id: string | null): void {
    this.id = id;
    this.idChange.emit(id);
  }

  /**
   * @description
   * On change the mapbox map, emit the new mapbox map. This happens when the map is loaded.
   * @param {mapboxgl.Map | null} map - The mapbox map.
   * @returns {void}
   */
  mapboxMapChanges(map: mapboxgl.Map | null): void {
    this.mapboxMap = map;
    this.mapboxMapChange.emit(map);
  }

  changeVehiclesOnMap(vehiclesIds: string[]): void {
    this.vehiclesOnMap = vehiclesIds;
  }

  onSupervisionTabSelect(tabSelected: buttonTabEnum) {
    const currTabSelected =
      !this.launchDarklyService.getFlagValue$('agenda') &&
      tabSelected === buttonTabEnum.AGENDA
        ? buttonTabEnum.MAP
        : tabSelected;

    // dispatch the new tab selected
    this.store.dispatch(
      mapAPIActions.supervisionModeSelected({
        supervisionModeSelected: currTabSelected,
      })
    );

    this.router.navigate([
      `${this.supervisionUrl}/${this.supervisionSegmentUrl}`,
    ]);
  }

  getSegmentUrl(pathUrl: string): string {
    const regex = /(?:[^/]+\/){2}(.*)/;

    if (!pathUrl.length) {
      return '';
    }

    const regexedUrl = pathUrl.match(regex);

    if (regexedUrl) {
      return regexedUrl[1];
    }

    return '';
  }
}
