import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'astus-map-layer-preview',
  templateUrl: './map-layer-preview.component.html',
  styleUrls: ['./map-layer-preview.component.scss'],
})
export class MapLayerPreviewComponent {
  @Input() isShown = false;
  @Input() isLayerTraffic = false;
  @Input() isLayerBike = false;

  @Output() setTrafficLayer = new EventEmitter();
  @Output() setBikeLayer = new EventEmitter();
  @Output() moreClicked = new EventEmitter();
}
