import { Component } from '@angular/core';

@Component({
  selector: 'astus-terms-conditions-header',
  templateUrl: './terms-conditions-header.component.html',
  styleUrls: ['./terms-conditions-header.component.scss'],
})
export class TermsConditionsHeaderComponent {
  onIconClick() {
    window.history.back();
  }
}
