import { GenerateReportDto, ReportView } from '@fms/ng-fms-api-client';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import {
  ReportDownloadError,
  ReportGenerateError,
  ReportListingError,
} from './report';

export const reportActions = createActionGroup({
  source: 'Reports API',
  events: {
    'report listing': emptyProps(),
    'report listing success': props<{ response: ReportView[] }>(),
    'report listing failure': props<{ error: ReportListingError }>(),
    'report download': props<{ id: string }>(),
    'report download success': props<{ id: string; response: Blob }>(),
    'report download failure': props<{ error: ReportDownloadError }>(),
    'report generate': props<{ payload: GenerateReportDto }>(),
    'report generate success': props<{ response: ReportView }>(),
    'report generate failure': props<{ error: ReportGenerateError }>(),
  },
});
