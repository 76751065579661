import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  private locale = 'fr';

  get currentLocale() {
    return this.locale;
  }

  changeLocale(locale: string) {
    this.locale = locale;
  }
}
