import {
  animate,
  keyframes,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const snackbarAnimations = [
  trigger('snackarAnimation', [
    transition('* => *', [
      query(':enter', style({ opacity: 0 }), { optional: true }),
      query(
        ':enter',
        stagger('300ms', [
          animate(
            '.5s ease-in',
            keyframes([
              style({ opacity: 0, transform: 'translateY(-50%)', offset: 0 }),
              style({
                opacity: 0.5,
                transform: 'translateY(-10px) scale(1.1)',
                offset: 0.3,
              }),
              style({ opacity: 1, transform: 'translateY(0)', offset: 1 }),
            ])
          ),
        ]),
        { optional: true }
      ),
      query(
        ':leave',
        stagger('300ms', [
          animate(
            '500ms ease-out',
            keyframes([
              style({ opacity: 1, transform: 'scale(1.1)', offset: 0 }),
              style({ opacity: 0.5, transform: 'scale(.5)', offset: 0.3 }),
              style({ opacity: 0, transform: 'scale(0)', offset: 1 }),
            ])
          ),
        ]),
        { optional: true }
      ),
    ]),
  ]),
  trigger('openAllAnimation', [
    transition(':enter', [
      style({ opacity: 0, transform: 'scale(0.5)' }),
      animate('.5s ease-out', style({ opacity: 1, transform: 'scale(1)' })),
    ]),
  ]),
];
