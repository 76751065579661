<div class="datepicker-container">
  <button (click)="decrementDate()" class="chevron-buttons">
    <ad-icon glyph="chevron_left" color="neutral-700"></ad-icon>
  </button>
  <button (click)="toggleCalendar()" class="datepicker-button">
    <span *ngIf="!isDateRange"
      >{{ singleDate | date : 'd MMMM y' : '' : currentLanguage }}
    </span>
    <span *ngIf="isDateRange">
      {{ dateRange[0] | date : 'M/d/yy' }}
      -
      {{ dateRange[1] | date : 'M/d/yy' }}
    </span>
  </button>
  <button (click)="incrementDate()" class="chevron-buttons">
    <ad-icon glyph="chevron_right" color="neutral-700"></ad-icon>
  </button>
</div>
<div class="calendar-wrapper" *ngIf="showCalendar">
  <ad-calendar
    [date]="date"
    [selectionMode]="isDateRange ? 'range' : 'single'"
    (dateSelect)="handleDateSelect($event)"
    (hideCalendar)="handleHideCalendar($event)"
    [dateRangeFilters]="dateRangeFilters"
  ></ad-calendar>
</div>
