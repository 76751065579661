/**
 * Compares two dates and returns whether they are the same.
 *
 * @param {Date} date1 - The first date to be compared.
 * @param {Date} date2 - The second date to be compared.
 * @returns {boolean} Whether the dates are the same, or not.
 */
export const compareDates = (date1: Date, date2: Date): boolean => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const incrementDate = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
};

export const decrementDate = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
};

/**
 * Compares two dates and returns whether they are the same.
 *
 * @param {Date[]} dateRange1 - The first date range to be compared.
 * @param {Date[]} dateRange2 - The second date range to be compared.
 * @returns {boolean} Whether the date ranges are the same, or not.
 */
export const compareDateRanges = (
  dateRange1: Date[],
  dateRange2: Date[]
): boolean => {
  return (
    compareDates(dateRange1[0], dateRange2[0]) &&
    compareDates(dateRange1[1], dateRange2[1])
  );
};

export const previousWeek = (): Date[] => {
  const today = new Date();
  const day = today.getDay();
  const startDate = new Date(today);
  startDate.setDate(today.getDate() - day - 7);
  const endDate = new Date(today);
  endDate.setDate(today.getDate() - day - 1);
  return [startDate, endDate];
};

export const previousMonth = (): Date[] => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const startDate = new Date(year, month - 1, 1);
  const endDate = new Date(year, month, 0);
  return [startDate, endDate];
};

export const previousYear = (): Date[] => {
  const today = new Date();
  const year = today.getFullYear();
  const startDate = new Date(year - 1, 0, 1);
  const endDate = new Date(year - 1, 11, 31);
  return [startDate, endDate];
};
