<div class="map-list-ctn"></div>

<!-- Circuits -->
<div class="map-list" *ngIf="circuits">
  <!-- Non Assignés -->
  <div class="circuits-non-assigned-title" *ngIf="circuits.nonAssigned.length">
    <p class="section-title text-caption">
      {{ 'CARDS.CIRCUITS_MENU.CARD.NON_ASSIGNED' | translate }}
      ({{ circuits.nonAssigned.length }})
    </p>
    <div class="section-title-divider"></div>
    <button
      class="section-title-toggle col-start-1 transition"
      (click)="circuitsNonAssignedListToggle()"
    >
      <span
        class="section-title-show-list-container"
        [ngClass]="{
          'hidden-list': !circuitsIsNonAssignedListShown
        }"
      >
        <ad-icon
          glyph="chevron_down"
          color="neutral-500"
          svgClass="section-title-show-list-container-icon"
        ></ad-icon>
      </span>
    </button>
  </div>
  <div
    class="section-list-container circuits-non-assigned-list"
    [ngClass]="{
      'show-list': circuitsIsNonAssignedListShown,
      'hide-list': !circuitsIsNonAssignedListShown
    }"
  >
    <div class="section-list-content">
      <ng-container *ngIf="!circuits.nonAssigned.length && isLoading">
        <div class="section-list-skeleton" *ngFor="let _ of [1, 2, 3, 4, 5, 6]">
          <ad-card [isSkeleton]="true"></ad-card>
        </div>
      </ng-container>
      <div
        class="section-list-data-content"
        *ngFor="let circuitNonAssigned of circuits.nonAssigned; let i = index"
      >
        <astus-map-list-circuit-card
          [circuit]="circuitNonAssigned"
          [isFirst]="i === 0"
          (cardClick)="cardClick.emit({ data: circuitNonAssigned })"
          (assignEvent)="assignCard(circuitNonAssigned)"
          (hoveredEvent)="hoveredCardChanges($event, circuitNonAssigned)"
          [isSelected]="cardSelected === circuitNonAssigned"
        ></astus-map-list-circuit-card>
      </div>
    </div>
  </div>
  <!-- Assignés -->
  <div
    class="all-assigned"
    [ngClass]="{ 'has-non-assign': circuits.nonAssigned.length }"
    *ngIf="circuits.assigned.length"
  >
    <p class="section-title text-caption">
      {{ 'CARDS.CIRCUITS_MENU.CARD.ASSIGNED' | translate }}
      ({{ circuits.assigned.length }})
    </p>
    <div class="section-title-divider"></div>
    <button class="section-title-toggle" (click)="circuitsAssignedListToggle()">
      <span
        class="section-title-show-list-container"
        [ngClass]="{
          'hidden-list': !circuitsIsAssignedListShown
        }"
      >
        <ad-icon
          glyph="chevron_down"
          color="neutral-500"
          svgClass="section-title-show-list-container-icon"
        ></ad-icon>
      </span>
    </button>
  </div>
  <div
    class="section-list-container"
    [ngClass]="{
      'show-list': circuitsIsAssignedListShown,
      'hide-list': !circuitsIsAssignedListShown
    }"
  >
    <div class="section-list-content">
      <ng-container *ngIf="!circuits.assigned.length && isLoading">
        <div class="section-list-skeleton" *ngFor="let _ of [1, 2, 3, 4, 5, 6]">
          <ad-card [isSkeleton]="true"></ad-card>
        </div>
      </ng-container>
      <div
        class="section-list-data-content"
        *ngFor="let circuitAssigned of circuits.assigned; let i = index"
      >
        <!-- // @TODO: Add the selected state and data  -->
        <astus-map-list-circuit-card
          [circuit]="circuitAssigned"
          [isFirst]="i === 0"
          (hoveredEvent)="hoveredCardChanges($event, circuitAssigned)"
          [isSelected]="cardSelected === circuitAssigned"
        ></astus-map-list-circuit-card>
      </div>
    </div>
  </div>
</div>

<!-- Vehicles -->
<div class="map-list" *ngIf="vehicles">
  <div
    class="vehicles-title"
    *ngIf="vehiclesFilteredOnMap && vehiclesFilteredOnMap.length"
  >
    <p class="section-title text-caption">
      {{ 'CARDS.VEHICLES_MENU.ON_MAP' | translate }} ({{
        vehiclesFilteredOnMap.length
      }})
    </p>
    <div class="section-title-divider"></div>
    <button
      class="section-title-toggle"
      (click)="vehiclesSuggestedListToggle()"
    >
      <span
        class="section-title-show-list-container"
        [ngClass]="{
          'hidden-list': !vehiclesOnMapListShown
        }"
      >
        <ad-icon
          glyph="chevron_down"
          color="neutral-500"
          svgClass="section-title-show-list-container-icon"
        ></ad-icon>
      </span>
    </button>
  </div>
  <div
    class="section-list-container"
    [ngClass]="{
      'show-list': vehiclesOnMapListShown,
      'hide-list': !vehiclesOnMapListShown
    }"
    *ngIf="vehiclesFilteredOnMap && vehiclesFilteredOnMap.length"
  >
    <div class="section-list-content">
      <ng-container *ngIf="!vehicles.length && isLoading">
        <div class="section-list-skeleton" *ngFor="let _ of [1, 2, 3, 4, 5, 6]">
          <ad-card [isSkeleton]="true"></ad-card>
        </div>
      </ng-container>
      <div
        class="section-list-data-content"
        *ngFor="
          let vehicle of vehiclesFilteredOnMap;
          let i = index;
          trackBy: vehicleTrackByFn
        "
      >
        <astus-map-list-vehicle-card
          [vehicle]="vehicle"
          [isFirst]="i === 0"
          (cardClick)="cardClick.emit({ data: vehicle })"
          (assignEvent)="assignCard(vehicle)"
          (hoveredEvent)="hoveredCardChanges($event, vehicle)"
          [isSelected]="cardSelected === vehicle"
          [hideAssignButton]="hideAssignButton"
        ></astus-map-list-vehicle-card>
      </div>
    </div>
  </div>
  <div class="all-assigned" *ngIf="vehiclesFilteredOutsideMap?.length">
    <p class="section-title text-caption">
      {{ 'CARDS.VEHICLES_MENU.OUTSIDE_MAP' | translate }} ({{
        vehiclesFilteredOutsideMap?.length
      }})
    </p>
    <div class="section-title-divider"></div>
    <button class="section-title-toggle" (click)="vehiclesAllListToggle()">
      <span
        class="section-title-show-list-container"
        [ngClass]="{
          'hidden-list': !vehiclesOutsideMapListShown
        }"
      >
        <ad-icon
          glyph="chevron_down"
          color="neutral-500"
          svgClass="section-title-show-list-container-icon"
        ></ad-icon>
      </span>
    </button>
  </div>
  <div
    class="section-list-container"
    [ngClass]="{
      'show-list': vehiclesOutsideMapListShown,
      'hide-list': !vehiclesOutsideMapListShown
    }"
  >
    <div class="section-list-content">
      <ng-container *ngIf="!vehiclesFilteredOutsideMap?.length && isLoading">
        <div class="section-list-skeleton" *ngFor="let _ of [1, 2, 3, 4, 5, 6]">
          <ad-card [isSkeleton]="true"></ad-card>
        </div>
      </ng-container>
      <div
        class="section-list-data-content"
        *ngFor="
          let vehicle of vehiclesFilteredOutsideMap;
          let i = index;
          trackBy: vehicleTrackByFn
        "
      >
        <astus-map-list-vehicle-card
          [vehicle]="vehicle"
          [isFirst]="i === 0"
          (cardClick)="cardClick.emit({ data: vehicle })"
          (assignEvent)="assignCard(vehicle)"
          (hoveredEvent)="hoveredCardChanges($event, vehicle)"
          [isSelected]="cardSelected === vehicle"
          [hideAssignButton]="hideAssignButton"
        ></astus-map-list-vehicle-card>
      </div>
    </div>
  </div>
</div>

<!-- Users -->
<div class="map-list" *ngIf="users">
  <div class="users-title" *ngIf="usersSuggested && usersSuggested.length">
    <p class="section-title text-caption">
      {{ 'CARDS.USERS_MENU.SUGGESTED' | translate }}
      ({{ usersSuggested.length }})
    </p>
    <div class="section-title-divider"></div>
    <button class="section-title-toggle" (click)="usersSuggestedListToggle()">
      <span
        class="section-title-show-list-container"
        [ngClass]="{
          'hidden-list': !usersSuggestedListShown
        }"
      >
        <ad-icon
          glyph="chevron_down"
          color="neutral-500"
          svgClass="section-title-show-list-container-icon"
        ></ad-icon>
      </span>
    </button>
  </div>
  <div
    class="section-list-container"
    [ngClass]="{
      'show-list': usersSuggestedListShown,
      'hide-list': !usersSuggestedListShown
    }"
  >
    <div class="section-list-content">
      <ng-container *ngIf="!users.length && isLoading">
        <div class="section-list-skeleton" *ngFor="let _ of [1, 2, 3, 4, 5, 6]">
          <ad-card [isSkeleton]="true"></ad-card>
        </div>
      </ng-container>
      <div
        class="section-list-data-content"
        *ngFor="let user of usersSuggested; let i = index"
      >
        <astus-map-list-user-card
          [user]="user"
          [isFirst]="i === 0"
          (cardClick)="cardClick.emit({ data: user })"
          (assignEvent)="assignCard(user)"
          (hoveredEvent)="hoveredCardChanges($event, user)"
          [isSelected]="cardSelected === user"
        ></astus-map-list-user-card>
      </div>
    </div>
  </div>
  <div class="all-assigned has-non-assign" *ngIf="users.length">
    <p class="section-title text-caption">
      {{ 'CARDS.USERS_MENU.ALL' | translate }}
      ({{ users.length }})
    </p>
    <div class="section-title-divider"></div>
    <button class="section-title-toggle" (click)="usersAllListToggle()">
      <span
        class="section-title-show-list-container"
        [ngClass]="{
          'hidden-list': !usersAllListShown
        }"
      >
        <ad-icon
          glyph="chevron_down"
          color="neutral-500"
          svgClass="section-title-show-list-container-icon"
        ></ad-icon>
      </span>
    </button>
  </div>
  <div
    class="section-list-container"
    [ngClass]="{
      'show-list': usersAllListShown,
      'hide-list': !usersAllListShown
    }"
  >
    <div class="section-list-content">
      <ng-container *ngIf="!users.length && isLoading">
        <div class="section-list-skeleton" *ngFor="let _ of [1, 2, 3, 4, 5, 6]">
          <ad-card [isSkeleton]="true"></ad-card>
        </div>
      </ng-container>
      <div
        class="section-list-data-content"
        *ngFor="let user of users; let i = index"
      >
        <astus-map-list-user-card
          [user]="user"
          [isFirst]="i === 0"
          (cardClick)="cardClick.emit({ data: user })"
          (assignEvent)="assignCard(user)"
          (hoveredEvent)="hoveredCardChanges($event, user)"
          [isSelected]="cardSelected === user"
        ></astus-map-list-user-card>
      </div>
    </div>
  </div>
</div>

<astus-map-list-empty-state
  *ngIf="
    !isLoading &&
    circuits &&
    !circuits.assigned.length &&
    !circuits.nonAssigned.length
  "
  [icon]="'circuit'"
  [errorMessage]="'CONTROL_PANEL.EMPTY_STATE.CIRCUITS.MESSAGE' | translate"
  [errorDescription]="
    'CONTROL_PANEL.EMPTY_STATE.CIRCUITS.DESCRIPTION' | translate
  "
></astus-map-list-empty-state>

<astus-map-list-empty-state
  *ngIf="
    !isLoading &&
    vehicles &&
    !(vehiclesFilteredOnMap && vehiclesFilteredOnMap.length) &&
    !vehiclesFilteredOutsideMap?.length
  "
  [icon]="'bus'"
  [errorMessage]="'CONTROL_PANEL.EMPTY_STATE.VEHICLES.MESSAGE' | translate"
  [errorDescription]="
    'CONTROL_PANEL.EMPTY_STATE.VEHICLES.DESCRIPTION' | translate
  "
></astus-map-list-empty-state>

<astus-map-list-empty-state
  *ngIf="
    !isLoading &&
    users &&
    !(usersSuggested && usersSuggested.length) &&
    !users.length
  "
  [icon]="'profile'"
  [errorMessage]="'CONTROL_PANEL.EMPTY_STATE.USERS.MESSAGE' | translate"
  [errorDescription]="'CONTROL_PANEL.EMPTY_STATE.USERS.DESCRIPTION' | translate"
></astus-map-list-empty-state>
