/**
 * FMS API
 * In charge of providing data from the legacy Astus FMS API to the different FMS apps
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type ReportStatus = 'failed' | 'success' | 'inProgress';

export const ReportStatus = {
  Failed: 'failed' as ReportStatus,
  Success: 'success' as ReportStatus,
  InProgress: 'inProgress' as ReportStatus,
};
