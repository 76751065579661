import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DropdownItemInterface } from '@common/ng-design-system';
import {
  tablePropsInterface,
  tableRowIconType,
} from '@common/ng-design-system';
import {
  GenerateReportDto,
  ReportStatus,
  ReportView,
} from '@fms/ng-fms-api-client';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { Subject, Subscription, combineLatest, takeUntil } from 'rxjs';

import {
  calculateRemainingTime,
  formatTime,
  isSameDay,
} from '../../app/helpers/date.helper';
import { DateFormatPipe } from '../pipes/dateformat/dateformat.pipe';
import { HoursFormatPipe } from '../pipes/hours-format/hours-format.pipe';
import { horizonTableReportHeaders } from '../resource/table-headers';

import { getHeaderTranslations } from '../resource/table-headers';
import { getReportsVehicles, reportsActions } from '../store';

import { reportActions } from './store';

import { getReportListData } from './store/report.selector';

export interface ReportToDisplay {
  id?: string | number;
  creationDate: string;
  name?: string;
  date: string;
  resource?: string;
  createdBy: string;
  status: string;
  remainingTime?: {
    value?: number;
    unit?: string;
  };
  icon?: tableRowIconType;
}

@Component({
  selector: 'astus-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit, OnDestroy {
  private dataTransalationSubscription: Subscription = new Subscription();

  isGenerateModalOpen = false;
  hasChangedValues = false;
  isCancelModalOpen = false;
  rawValues = {};
  isAllVehiclesSelected = false;
  vehiclesLabelValue = '';
  vehiclesPlaceholderLabelValue = '';
  vehiclesAllLabelValue = '';

  reportsItems: DropdownItemInterface[] = [];
  vehiclesItems: DropdownItemInterface[] = [];

  startDate = new Date(new Date().setDate(new Date().getDate() - 1));
  startTime = '00:00';
  endDate = new Date();
  endTime = '00:00';

  generateReportForm = this.fb.group({
    reportType: [''],
    startDate: [this.startDate],
    startTime: [this.startTime],
    endDate: [this.endDate],
    endTime: [this.endTime],
    vehicles: this.fb.group({}),
  });

  destroy$ = new Subject();
  vehiclesSubscription = new Subscription();
  formSubscription = new Subscription();

  data: ReportToDisplay[] = [];
  headers = horizonTableReportHeaders;

  fixedColumns = 0;

  errorTitleMessage = '';
  errorDescriptionMessage = '';

  constructor(
    private translateService: TranslateService,
    private store: Store,
    private fb: FormBuilder,
    private translate: TranslateService,
    private hoursFormatPipe: HoursFormatPipe,
    private dateFormatePipe: DateFormatPipe
  ) {}

  ngOnInit(): void {
    this.store.dispatch(reportsActions.getVehiclesLoad());
    this.store.dispatch(reportActions.reportListing());

    combineLatest([
      this.translateService.stream(
        'REPORTS.GENERATE-MODAL.REPORTS-CHOICES.ROUTE'
      ),
      this.translateService.stream(
        'REPORTS.GENERATE-MODAL.REPORTS-CHOICES.MOVEMENTS'
      ),
      this.translateService.stream(
        'REPORTS.GENERATE-MODAL.REPORTS-CHOICES.ACTIVITY-HISTORY'
      ),
      this.translateService.stream(
        'REPORTS.GENERATE-MODAL.VEHICLES-PLACEHOLDER'
      ),
      this.translateService.stream('REPORTS.GENERATE-MODAL.VEHICLES-ALL'),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        ([
          route,
          movements,
          activityHistory,
          vehiclePlaceholder,
          vehicleAll,
        ]) => {
          this.reportsItems = [
            {
              value: GenerateReportDto.ReportTypeEnum.TripReport,
              label: route,
            },
            {
              value: GenerateReportDto.ReportTypeEnum.MovementIdleStopReport,
              label: movements,
            },
            {
              value: GenerateReportDto.ReportTypeEnum.AccessoryReport,
              label: activityHistory,
            },
          ];

          this.vehiclesPlaceholderLabelValue = vehiclePlaceholder;
          this.vehiclesAllLabelValue = vehicleAll;

          this.handleVehicleLabelChange();
        }
      );

    this.vehiclesSubscription = this.store
      .select(getReportsVehicles)
      .subscribe((vehicles) => {
        if (vehicles.length !== 0) {
          this.vehiclesItems = vehicles.map((vehicle) => ({
            value: vehicle.id,
            label: vehicle.name ? vehicle.name : '',
          }));

          vehicles.forEach((vehicle) => {
            (this.generateReportForm.get('vehicles') as FormGroup)?.addControl(
              vehicle.id.toString(),
              new FormControl(false),
              { emitEvent: false }
            );
          });

          this.rawValues = this.generateReportForm.getRawValue();
        }
      });

    this.formSubscription = this.generateReportForm.valueChanges.subscribe(
      (value) => {
        this.hasChangedValues =
          JSON.stringify(value) !== JSON.stringify(this.rawValues);

        this.isAllVehiclesSelected = !Object.values(
          this.generateReportForm.get('vehicles')?.getRawValue()
        ).some((vehicle) => !vehicle);

        this.handleVehicleLabelChange();
      }
    );

    getHeaderTranslations(this.translate);
    this.getReportsAndTranslation();
    this.errorMessage();
    this.errorDescription();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();

    this.vehiclesSubscription.unsubscribe();
    this.formSubscription.unsubscribe();
    this.dataTransalationSubscription.unsubscribe();
  }

  /**
   * @description - Prevents the modal to close when clicking on the dropdown when it's
   * above the backdrop.
   * @param e Event
   */
  onVehicleDropdownClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();
  }

  /**
   * @description - This method toggles all the vehicles checkboxes
   */
  onVehicleAllToggle() {
    this.generateReportForm.get('vehicles')?.patchValue(
      this.vehiclesItems.reduce(
        (acc, vehicle) => ({
          ...acc,
          [vehicle.value]: !this.isAllVehiclesSelected,
        }),
        {}
      )
    );
  }

  /**
   * @description - This method handles the vehicle label change depending on the selected vehicles
   */
  handleVehicleLabelChange() {
    if (this.isAllVehiclesSelected) {
      this.vehiclesLabelValue = this.vehiclesAllLabelValue;
    } else {
      this.vehiclesLabelValue = this.vehiclesItems
        .filter(
          (vehicle) =>
            this.generateReportForm
              .get('vehicles')
              ?.get(vehicle.value.toString())?.value
        )
        .map((vehicle) => vehicle.label)
        .join(', ');
    }

    if (this.vehiclesLabelValue.length === 0) {
      this.vehiclesLabelValue = this.vehiclesPlaceholderLabelValue;
    }
  }

  /**
   * @description - This method is used to open the generate report modal
   */
  openGenerateModal() {
    this.isGenerateModalOpen = true;
  }

  /**
   * @description - Close the generate report modal and checks if a value
   * has changed to open the cancel modal
   */
  closeGenerateModal() {
    this.isGenerateModalOpen = false;
    if (this.hasChangedValues) {
      this.isCancelModalOpen = true;
    }
  }

  /**
   * @description - This method is used to generate the report
   */
  generateReport() {
    const startDate = this.getDateAndTime('start');
    const endDate = this.getDateAndTime('end');

    const formattedData: GenerateReportDto = {
      reportType: this.generateReportForm.get('reportType')
        ?.value as GenerateReportDto.ReportTypeEnum,
      startTime: startDate.toISOString(),
      endTime: endDate.toISOString(),
      vehicleIds: Object.keys(
        this.generateReportForm.get('vehicles')?.value || {}
      )
        .filter(
          (key) => this.generateReportForm.get('vehicles')?.get(key)?.value
        )
        .map(String),
    };

    this.store.dispatch(
      reportActions.reportGenerate({ payload: formattedData })
    );
    this.isGenerateModalOpen = false;
  }

  /**
   * @description - This method is used to get the date and time from the form
   * @param dateType string 'start' | 'end'
   * @returns Date
   */
  private getDateAndTime(dateType: 'start' | 'end'): Date {
    const dateString =
      this.generateReportForm.get(`${dateType}Date`)?.value || '';
    const timeString =
      this.generateReportForm.get(`${dateType}Time`)?.value || '00:00';
    const [hours, minutes] = timeString.split(':').map(Number);
    const date = new Date(dateString);

    date.setHours(hours, minutes, 0, 0);

    return date;
  }

  /**
   * @description - This method is used to handle the date/time change
   * @param value string
   * @param formOption string
   */
  onDateTimeValueChange(value: Date | string | number, formOption: string) {
    this.generateReportForm.patchValue({ [formOption]: value });
  }

  /**
   * @description Open the cancel modal
   */
  goBackFromCancelModal() {
    this.isGenerateModalOpen = true;
    this.isCancelModalOpen = false;
  }

  /**
   * @description Confirm the cancel action and resets the form
   */
  confirmCancel() {
    this.isCancelModalOpen = false;

    this.generateReportForm.patchValue(this.rawValues);
  }

  getReportsAndTranslation() {
    this.store.select(getReportListData).subscribe((reports) => {
      this.store.select(getReportsVehicles).subscribe(() => {
        // dataToView is the prepared data to display in the table
        const dataToView = this.prepareData(reports);
        this.translateData(dataToView);
      });
    });
  }

  translateData(dataToView: ReportToDisplay[]) {
    this.dataTransalationSubscription = combineLatest([
      this.translate.stream('GENERAL.STATUS.IN_PROGRESS'),
      this.translate.stream('GENERAL.STATUS.READY'),
      this.translate.stream('GENERAL.STATUS.ERROR'),
      this.translate.stream('GENERAL.UNITS.MONTHS'),
      this.translate.stream('GENERAL.UNITS.DAYS'),
      this.translate.stream('REPORTS.GENERATE-MODAL.REPORTS-CHOICES.ROUTE'),
      this.translate.stream('REPORTS.GENERATE-MODAL.REPORTS-CHOICES.MOVEMENTS'),
      this.translate.stream(
        'REPORTS.GENERATE-MODAL.REPORTS-CHOICES.ACTIVITY-HISTORY'
      ),
    ]).subscribe((reportsAndTranslations) => {
      // translate the data(reports) to be displayed in the table
      this.data = dataToView.map((report) => {
        return {
          ...report,
          status:
            report.status === 'error'
              ? reportsAndTranslations[0]
              : report.status === 'success'
              ? reportsAndTranslations[1]
              : reportsAndTranslations[2],
          remainingTime: {
            value: report.remainingTime?.value,
            unit:
              report.remainingTime?.unit === 'months'
                ? reportsAndTranslations[3]
                : reportsAndTranslations[4],
          },
          name:
            report.name === GenerateReportDto.ReportTypeEnum.TripReport
              ? reportsAndTranslations[5]
              : report.name ===
                GenerateReportDto.ReportTypeEnum.MovementIdleStopReport
              ? reportsAndTranslations[6]
              : report.name === GenerateReportDto.ReportTypeEnum.AccessoryReport
              ? reportsAndTranslations[7]
              : report.name,
        };
      });
    });
  }

  /**
   *
   * @param reports
   * @returns ReportToDisplay[]
   * @description Prepare data that comes from the backend to display in the table
   */
  prepareData(reports: ReportView[]): ReportToDisplay[] {
    const reportData: ReportToDisplay[] = reports.map((report) => {
      return {
        id: report.Id,
        creationDate: this.dateFormatePipe.transform(report.Created),
        name: report.RequestData.ReportType,
        date: this.prepareTableDates(
          report.RequestData.startTime,
          report.RequestData.endTime
        ),
        resource: this.prepareVehicleNames(report.RequestData.vehicleIds || []),
        createdBy: report.IssuerUserName,
        status: report.Status,
        remainingTime: calculateRemainingTime(report.Created),
        icon: {
          icon: 'download',
          color: 'primary-400',
          name: 'export',
          isPossibleToDisable: true,
          isDisabled:
            report.Status === ReportStatus.Failed ||
            report.Status === ReportStatus.InProgress,
        },
        url: report.ReportURL,
      };
    });

    return reportData;
  }

  /**
   *
   * @param vehicleIds
   * @returns string
   * @description Prepare the vehicle(resources) names to display in the table
   */
  prepareVehicleNames(vehicleIds: string[]): string {
    let vehicleNames = '';
    if (vehicleIds.length === 0) return vehicleNames;

    this.store.select(getReportsVehicles).subscribe((vehicles) => {
      const selectedVehicles = vehicles.filter((vehicle) =>
        vehicleIds.includes(vehicle.id)
      );
      const selectedNames = selectedVehicles.map((vehicle) => vehicle.name);

      if (selectedNames.length > 2) {
        vehicleNames = `${selectedNames[0]}, ${selectedNames[1]} (+${
          selectedNames.length - 2
        })`;
      } else {
        vehicleNames = selectedNames.join(', ');
      }
    });

    return vehicleNames;
  }

  /**
   *
   * @param startDateAndTime start date
   * @param endDateAndTime end date
   * @returns the string to display in the table in the date column of the report
   * @description Prepare the date to display in the table in the date column of the report. when the range is the same day, it will display the date and the time, otherwise, it will display the range of dates
   */
  prepareTableDates(startDateAndTime: string, endDateAndTime: string): string {
    const date1Object = new Date(startDateAndTime);
    const date2Object = new Date(endDateAndTime);

    const isFullDay =
      date1Object.getHours() === 0 &&
      date1Object.getMinutes() === 0 &&
      date2Object.getHours() === 23 &&
      date2Object.getMinutes() >= 59;

    if (isSameDay(date1Object, date2Object)) {
      if (isFullDay) {
        return this.dateFormatePipe.transform(startDateAndTime);
      }
      // case when same day but not full day
      return `${this.dateFormatePipe.transform(startDateAndTime)} (${formatTime(
        date1Object,
        this.hoursFormatPipe
      )} - ${formatTime(date2Object, this.hoursFormatPipe)})`;
    }
    // case when different days
    if (isFullDay) {
      return `${this.dateFormatePipe.transform(
        startDateAndTime
      )} - ${this.dateFormatePipe.transform(endDateAndTime)}`;
    }

    return `${this.dateFormatePipe.transform(
      startDateAndTime
    )} - ${this.dateFormatePipe.transform(endDateAndTime)} (${formatTime(
      date1Object,
      this.hoursFormatPipe
    )} - ${formatTime(date2Object, this.hoursFormatPipe)})`;
  }

  /**
   * prepare the error title message to display in the table empty state
   */
  errorMessage() {
    this.translate
      .stream('TABLE_EMPTY_STATE.REPORTS.MESSAGE')
      .subscribe((res) => {
        this.errorTitleMessage = res;
      });
  }

  /**
   * prepare the error description message to display in the table empty state
   */
  errorDescription() {
    this.translate
      .stream('TABLE_EMPTY_STATE.REPORTS.DESCRIPTION')
      .subscribe((res) => {
        this.errorDescriptionMessage = res;
      });
  }

  onExportIconClick(clickedElement: tablePropsInterface) {
    this.store.dispatch(
      reportActions.reportDownload({ id: clickedElement.id })
    );
  }
}
