import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
  ViewEncapsulation,
} from '@angular/core';

import classNames from 'classnames';

import { IconAtomType } from '../../atoms';
import { ColorType, colors } from '../../common-ng-design-system.colors';
import { RippleEffectDirective } from '../../directives/ripple/ripple.directive';
import { fabGroupedOrientationType } from '../fab-grouped/fab-grouped';

import { FabKindType, FabSizeType, FabStateType } from './fab.enum';

@Component({
  selector: 'button[ad-fab]', // eslint-disable-line @angular-eslint/component-selector
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ad-icon *ngIf="glyph" [glyph]="glyph" [color]="iconColor"></ad-icon>
    <ng-content></ng-content>
  `,
  styleUrls: ['./fab.component.scss'],
  encapsulation: ViewEncapsulation.None,
  hostDirectives: [
    {
      directive: RippleEffectDirective,
    },
  ],
})
export class FabMolecule {
  @Input()
  kind: FabKindType = 'filled';

  @Input()
  color: ColorType = 'primary';

  @Input()
  glyph: IconAtomType | undefined = 'analytics';

  @Input()
  size: FabSizeType = 'xl';

  @Input()
  @HostBinding('disabled')
  disabled = false;

  @Input()
  forceIconColor: keyof typeof colors | null = null;

  @Input()
  grouped: 'none' | 'item' | 'first' = 'none';

  @Input()
  groupedOrientation: fabGroupedOrientationType = 'vertical';

  private pressed = false;

  public get iconColor(): keyof typeof colors {
    const type: FabStateType = `${this.kind}-${this.color}${
      this.disabled ? '-disabled' : this.pressed ? '-pressed' : ''
    }`;

    if (this.forceIconColor !== null) {
      return classNames(this.forceIconColor) as keyof typeof colors;
    } else {
      return classNames(
        type.includes('filled') && {
          'shades-0': !type.includes('disabled'),
          'neutral-400': type.includes('disabled'),
        },
        type.includes('tonal') && {
          'neutral-700': !type.includes('disabled'),
          'neutral-300': type.includes('disabled'),
        }
      ) as keyof typeof colors;
    }
  }

  @HostBinding('style.--rippleColor')
  private get rippleColor() {
    switch (this.kind) {
      case 'filled':
        return 'var(--color-shades-0)';
      case 'tonal':
        switch (this.color) {
          case 'primary':
          case 'success':
          case 'warning':
          case 'danger':
            return `var(--color-${this.color}-500)`;
          case 'neutral':
            return `var(--color-${this.color}-900)`;
        }
    }
  }

  @HostBinding('class')
  public get classes() {
    const type: FabStateType = `${this.kind}-${this.color}${
      this.disabled ? '-disabled' : ''
    }${this.pressed ? '-pressed' : ''}`;
    return classNames(
      `ad-fab size-${this.size} type-${type}`,
      this.grouped !== 'none' && `grouped-${this.grouped}`,
      this.grouped !== 'none' &&
        `grouped-orientation-${this.groupedOrientation}`
    );
  }
  /**
   * element events
   */
  @HostListener('mousedown')
  onMouseDown() {
    this.pressed = true;
  }

  @HostListener('mouseup')
  onMouseUp() {
    this.pressed = false;
  }
}
