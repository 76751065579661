import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';

import { IconAtomType } from '../../atoms';

export const optionSize = '40px';

// Reusable ids for the search elements
const SEARCH_LIST_OPTIONS_BASE_STRING = 'search-list-options';
const SEARCH_LIST_OPTION_BASE_STRING = 'search-list-option-';

// Reusable sizes for the search elements
const SEARCH_OPTION_SIZE = 40;

@Component({
  selector: 'ad-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchMolecule implements OnInit, OnChanges {
  @Input() searchHistory?: Array<string> = [];

  @Input() searchResult: Array<string> = [];

  @Input() size?: 'S' | 'M' = 'M';

  @Input() placeholder?: string = 'Search';

  @Input() trailingIcon?: IconAtomType;

  @Input() value?: string = '';

  @Input() searchSelection?: string = '';

  @Input() emptySearchTitle = '';

  @Input() emptySearchFirstText = '';

  @Input() emptySearchSecondText = '';

  @Input() disabledEnterEvent = false;

  @Output() valueChange = new EventEmitter<string>();

  @Output() searchHistoryChange = new EventEmitter<string[]>();

  @Output() trailingIconEvent = new EventEmitter();

  @Output() searchSelectionClick = new EventEmitter<string>();

  @Output() searchResetClick = new EventEmitter<string>();

  dropdownOpened = false;
  searchList: Array<string> = [];
  hoveredValue = '';
  hoveredIndex: null | number = null;

  // Reusable ids for the search elements
  SEARCH_LIST_OPTIONS_BASE_STRING = SEARCH_LIST_OPTIONS_BASE_STRING;
  SEARCH_LIST_OPTION_BASE_STRING = SEARCH_LIST_OPTION_BASE_STRING;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.searchList = this.searchHistory || [];
  }

  ngOnChanges(): void {
    if (this.searchHistory && !this.value) {
      this.searchList = this.searchHistory;
    } else if (this.value && this.searchHistory) {
      this.searchList = this.searchResult;
    } else {
      this.searchList = [];
    }

    if (this.searchList.length === 1) {
      this.hoveredValue = this.searchList[0];
    }
  }

  /**
   * @description
   * On input change, set value and emit event
   * if value is empty and has more than one whitespace, set value to empty
   * if value is not empty, filter search list
   * @param event: Event
   * @returns void
   */
  onInputChange(event: Event): void {
    this.dropdownOpened = true;

    const value = (event.target as HTMLInputElement).value;
    const hasMoreThanOneWhitespace = /\s{2,}/.test(value);
    const newValue = hasMoreThanOneWhitespace
      ? value.replace(/\s{2,}/g, ' ').trim()
      : value.trim();

    this.setValues(newValue);
  }

  /**
   * @description
   * Set value and emit event
   * @param value: unknown
   * @returns void
   */
  setValues(value: string): void {
    this.value = value;
    this.valueChange.emit(this.value);
  }

  /**
   * @description
   * Click search item to set value and emit event
   * @param option: DropdownItemInterface
   * @returns void
   */
  clickSelectItem(option: string): void {
    this.dropdownOpened = !this.dropdownOpened;
    this.searchSelection = option;
    this.searchSelectionClick.emit(this.searchSelection);
    this.setValues(option);
  }

  /**
   * @description
   * On click on trailing icon, clear value and emit event
   * @param event: Event
   * @returns void
   */
  trailingIconClick(event: Event): void {
    event.stopImmediatePropagation();
    // Clear value
    this.searchSelection = '';
    this.searchResetClick.emit(this.searchSelection);
    this.setValues('');
  }

  /**
   * @description
   * Set dropdownOpened to false when click on search input if value is empty
   * @returns void
   */
  handleSearchInputClick(): void {
    if (!this.value) {
      this.dropdownOpened = !this.dropdownOpened;
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      // The click is outside the component
      this.dropdownOpened = false;
    }
  }

  @HostListener('document:keyup.escape', ['$event'])
  onClickOnEscape() {
    this.dropdownOpened = false;
  }

  @HostListener('document:keyup.enter', ['$event'])
  onClickOnEnter() {
    this.dropdownOpened = false;
    if (!this.disabledEnterEvent) {
      // clear the list but keep value
      this.setValues(this.hoveredValue);
    }
  }

  @HostListener('document:keyup.arrowDown', ['$event'])
  onClickOnArrowDown() {
    this.hoveredIndex =
      this.hoveredIndex !== null &&
      this.hoveredIndex + 1 < this.searchList.length
        ? this.hoveredIndex + 1
        : 0;
    this.hoveredValue =
      this.hoveredIndex !== null ? this.searchList[this.hoveredIndex] : '';
    document
      .getElementsByClassName(SEARCH_LIST_OPTIONS_BASE_STRING)[0]
      .scrollTo({
        top: SEARCH_OPTION_SIZE * this.hoveredIndex,
        behavior: 'smooth',
      });
  }

  @HostListener('document:keyup.arrowUp', ['$event'])
  onClickOnArrowUp() {
    this.hoveredIndex =
      this.hoveredIndex !== null && this.hoveredIndex > 0
        ? this.hoveredIndex - 1
        : this.searchList.length - 1;
    this.hoveredValue =
      this.hoveredIndex !== null ? this.searchList[this.hoveredIndex] : '';
    document
      .getElementsByClassName(SEARCH_LIST_OPTIONS_BASE_STRING)[0]
      .scrollTo({
        top: SEARCH_OPTION_SIZE * this.hoveredIndex,
        behavior: 'smooth',
      });
  }
}
