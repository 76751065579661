import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { IconAtomGlyphType } from '../../atoms';
import { colors } from '../../common-ng-design-system.colors';

import { alertIdType, alertColorType, alertTitleType } from './alert';

@Component({
  selector: 'ad-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertMolecule implements OnInit {
  @Input()
  id?: alertIdType;

  @Input()
  icon?: IconAtomGlyphType = 'info';

  @Input()
  color: alertColorType = 'success';
  textColor: keyof typeof colors = 'success-700';
  iconsColor: keyof typeof colors = 'success-500';
  backgroundColor: keyof typeof colors = 'success-50';

  @Input()
  title?: alertTitleType;

  @Input()
  showCloseButton = false;

  @Output() closeEvent: EventEmitter<number> = new EventEmitter();

  @HostBinding('style.--ad-alert-text-color')
  textColorStyle = `var(--color-${this.textColor})`;

  @HostBinding('style.--ad-alert-background-color')
  backgroundColorStyle = `var(--color-${this.backgroundColor})`;

  ngOnInit(): void {
    this.setColors();
  }

  setColors(): void {
    const colorMappings: {
      [key: string]: {
        textColor: keyof typeof colors;
        iconsColor: keyof typeof colors;
        backgroundColor: keyof typeof colors;
      };
    } = {
      primary: {
        textColor: 'primary-700',
        iconsColor: 'primary-500',
        backgroundColor: 'primary-50',
      },
      neutral: {
        textColor: 'neutral-900',
        iconsColor: 'neutral-500',
        backgroundColor: 'neutral-50',
      },
      danger: {
        textColor: 'danger-700',
        iconsColor: 'danger-500',
        backgroundColor: 'danger-50',
      },
      warning: {
        textColor: 'warning-700',
        iconsColor: 'warning-500',
        backgroundColor: 'warning-50',
      },
      default: {
        textColor: 'success-700',
        iconsColor: 'success-500',
        backgroundColor: 'success-50',
      },
    };
    const colorMapping = colorMappings[this.color] || colorMappings['default'];
    this.textColor = colorMapping.textColor;
    this.iconsColor = colorMapping.iconsColor;
    this.backgroundColor = colorMapping.backgroundColor;
    this.textColorStyle = `var(--color-${this.textColor})`;
    this.backgroundColorStyle = `var(--color-${this.backgroundColor})`;
  }
}
