<div [ngClass]="{ 'is-skeleton': isLoading }">
  <div class="header">
    <ad-card-info
      tooltipPosition="left"
      class="header-card-info"
      [isSkeleton]="isLoading"
      [title]="title || ''"
      [hideAvatars]="true"
      [infoItems]="[
        { status: statusColor, text: statusText },
        { icon: 'schedule', text: infoService || '' }
      ]"
    ></ad-card-info>
    <div *ngIf="moreOptions.length" class="more-options-container" moreInfo>
      <div class="more-options-content">
        <ad-popover
          [(display)]="showMoreOptions"
          [options]="moreOptions"
          class="more-options-content-popover"
          (selectOption)="handleMoreOptionClick($event)"
          position="bottom"
        >
          <button
            (click)="onMoreOptionsClick()"
            class="more-options-content-popover-button"
          >
            <ad-icon
              [color]="isLoading ? 'neutral-200' : 'neutral-500'"
              glyph="more-vertical"
              [ngClass]="isLoading ? 'is-loading' : 'is-not-loading'"
              class="more-options-icon"
              svgClass="header-icons-svg"
            ></ad-icon>
          </button>
        </ad-popover>
      </div>
    </div>
  </div>
  <!-- Skeleton -->
  <ng-container *ngIf="isLoading; else infoContainer">
    <div class="more-info-content-skeleton">
      <!-- Header -->
      <div class="more-info-container-skeleton">
        <div class="more-info-container-skeleton-1"></div>
        <div class="more-info-container-skeleton-2"></div>
        <div class="more-info-container-skeleton-3"></div>
        <div class="more-info-container-skeleton-4"></div>
        <div class="more-info-container-skeleton-5"></div>
      </div>
      <!-- Info container -->
      <div *ngFor="let _ of [1, 2]" class="more-info-container-more-skeleton">
        <div class="more-info-container-skeleton-6"></div>
        <div class="more-content-skeleton">
          <!-- First text info -->
          <div class="more-content-first-text-skeleton">
            <div class="more-content-first-text-skeleton-1"></div>
            <div class="more-content-texts-skeleton-end">
              <div class="more-content-first-text-skeleton-2"></div>
              <div class="more-content-first-text-skeleton-3"></div>
            </div>
          </div>
          <!-- Second text info -->
          <div class="more-content-texts-skeleton">
            <div class="more-content-texts-skeleton-1"></div>
            <div class="more-content-texts-skeleton-end-2"></div>
          </div>
          <!-- Third text info -->
          <div class="more-content-texts-skeleton" *ngIf="_ !== 1">
            <div class="more-content-texts-skeleton-3"></div>
            <div class="more-content-texts-skeleton-end">
              <div class="more-content-texts-skeleton-end-4"></div>
              <div class="more-content-texts-skeleton-end-5"></div>
            </div>
          </div>
          <!-- Fourth text info -->
          <div class="more-content-texts-skeleton" *ngIf="_ !== 1">
            <div class="more-content-texts-skeleton-6"></div>
            <div class="more-content-texts-skeleton-end-7"></div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Infobox -->
  <ng-template #infoContainer>
    <div class="more-info-container">
      <div class="email-phone-container" *ngIf="phone || email">
        <!-- Phone info -->
        <div class="email-phone" *ngIf="phone">
          <a href="tel:{{ phone }}" class="email-phone-anchor">
            <ad-icon
              email
              glyph="phone"
              class="email-phone-container-icons"
              svgClass="email-phone-container-icons-svg"
            ></ad-icon>
            <span class="email-phone-container-text text-caption">{{
              phone
            }}</span>
          </a>
        </div>
        <!-- Email info -->
        <div *ngIf="email" class="email-phone">
          <a href="mailto:{{ email }}" class="email-phone-anchor">
            <ad-icon
              email
              glyph="email"
              class="email-phone-container-icons"
              svgClass="email-phone-container-icons-svg"
            ></ad-icon>
            <span class="email-phone-container-text text-caption">{{
              email
            }}</span>
          </a>
        </div>
      </div>
      <div *ngIf="circuits || preference">
        <div class="driver-information-title text-footer">
          {{
            'CARDS.MONITORING_CARD.USER_MONITORING.INFORMATION'
              | translate
              | uppercase
          }}
        </div>
        <div class="driver-information-container">
          <div *ngIf="circuits" class="driver-information-content">
            <div class="driver-information-circuit-preference text-caption">
              {{ 'CARDS.MONITORING_CARD.USER_MONITORING.CIRCUITS' | translate }}
            </div>
            <div class="driver-information-circuit-text text-caption">
              {{ circuits }}
            </div>
          </div>
          <div *ngIf="preference" class="driver-information-content">
            <div class="driver-information-circuit-preference text-caption">
              {{
                'CARDS.MONITORING_CARD.USER_MONITORING.PREFERENCE' | translate
              }}
            </div>
            <div class="driver-information-preference-text text-caption">
              {{ preference }}
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          singleJob ||
          expirationSingleJob ||
          licenseClass ||
          permit ||
          expirationPermit ||
          transporter ||
          homeport ||
          groups
        "
      >
        <div class="driver-information-details-text text-footer">
          {{
            'CARDS.MONITORING_CARD.USER_MONITORING.DETAILS'
              | translate
              | uppercase
          }}
        </div>
        <div class="driver-information-container">
          <div *ngIf="singleJob" class="driver-information-content">
            <div class="driver-single-job-title text-caption">
              {{
                'CARDS.MONITORING_CARD.USER_MONITORING.SINGLE_JOB' | translate
              }}
            </div>
            <div class="driver-single-job-permit-container">
              <div class="driver-single-job-text text-caption">
                {{ singleJob }}
              </div>
              <div
                class="text-caption driver-single-job-permit-exp-text"
                *ngIf="expirationSingleJob"
              >
                <span
                  >{{
                    'CARDS.MONITORING_CARD.USER_MONITORING.EXP' | translate
                  }}.&nbsp;</span
                >
                {{ expirationSingleJob | dateFormat }}
              </div>
            </div>
          </div>
          <div *ngIf="licenseClass" class="driver-information-content">
            <div class="driver-license-permit-transport-text text-caption">
              {{
                'CARDS.MONITORING_CARD.USER_MONITORING.LICENSE_CLASS'
                  | translate
              }}
            </div>
            <div class="driver-license-text text-caption">
              {{ licenseClass }}
            </div>
          </div>
          <div *ngIf="permit" class="driver-information-content">
            <div class="driver-license-permit-transport-text text-caption">
              {{ 'CARDS.MONITORING_CARD.USER_MONITORING.PERMIT' | translate }}
            </div>
            <div class="driver-single-job-permit-container">
              <div class="driver-single-job-text text-caption">
                {{ permit }}
              </div>
              <div
                class="text-caption driver-single-job-permit-exp-text"
                *ngIf="expirationPermit"
              >
                <span
                  >{{
                    'CARDS.MONITORING_CARD.USER_MONITORING.EXP' | translate
                  }}.&nbsp;</span
                >
                {{ expirationPermit | dateFormat }}
              </div>
            </div>
          </div>
          <div *ngIf="transporter" class="driver-information-content">
            <div class="driver-license-permit-transport-text text-caption">
              {{
                'CARDS.MONITORING_CARD.USER_MONITORING.TRANSPORTER' | translate
              }}
            </div>
            <div class="driver-transporter-text text-caption">
              {{ transporter }}
            </div>
          </div>
          <div *ngIf="homeport" class="driver-information-content">
            <div class="driver-license-permit-transport-text text-caption">
              {{ 'CARDS.MONITORING_CARD.USER_MONITORING.HOMEPORT' | translate }}
            </div>
            <div class="driver-transporter-text text-caption">
              {{ homeport }}
            </div>
          </div>
          <div *ngIf="groups" class="driver-information-content">
            <div class="driver-license-permit-transport-text text-caption">
              {{ 'CARDS.MONITORING_CARD.USER_MONITORING.GROUPS' | translate }}
            </div>
            <div class="driver-transporter-text text-caption">
              {{ groups }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
