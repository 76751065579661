import {
  settingsNotificationsInactivityEnum,
  settingsNotificationsScheduleDaysEnum,
} from '../settings/settings-notifications/settings-notifications.component.enum';

import { notificationSettingsInterface } from './global';

export const notificationsSettingsMock: notificationSettingsInterface = {
  high: {
    snackbar: false,
    snackbarTime: 10,
    message: true,
    notifications: true,
    email: true,
    banner: true,
  },
  medium: {
    snackbar: false,
    snackbarTime: 50,
    message: true,
    notifications: true,
    email: true,
    banner: true,
  },
  low: {
    snackbar: true,
    snackbarTime: 30,
    message: false,
    notifications: true,
    email: true,
    banner: true,
  },
  system: {
    snackbar: true,
    snackbarTime: 90,
    message: true,
    notifications: false,
    email: false,
    banner: true,
  },
  schedule: {
    days: settingsNotificationsScheduleDaysEnum.EVERYDAY,
    timeStart: 4,
    timeEnd: 20,
    customWeekdays: [],
  },
  inactivity: settingsNotificationsInactivityEnum.DISABLED,
};
