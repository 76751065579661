<div class="card-info">
  <div class="card-info-container">
    <!-- Leading Icon/ Avatar -->
    <ng-container *ngIf="!hideAvatars">
      <ng-container *ngIf="isSkeleton; else avatarContainer">
        <div class="card-info-avatar-container">
          <ad-avatar-text
            size="xs"
            backgroundColor="neutral-200"
            [charToShow]="0"
            text=""
          ></ad-avatar-text>
          <ad-avatar-text
            size="xs"
            backgroundColor="neutral-200"
            [charToShow]="0"
            text=""
            class="avatar-skeleton"
          ></ad-avatar-text>
        </div>
      </ng-container>
      <ng-template #avatarContainer>
        <div class="card-info-avatar-container">
          <ad-map-control-tooltip
            *ngIf="firstAvatarText"
            [text]="firstAvatarTooltip"
            class="map-control-tooltip-positioning"
            position="top"
            color="neutral"
          >
            <ad-avatar-text
              size="xs"
              backgroundColor="primary-400"
              class="map-control-tooltip-positioning"
              [charToShow]="2"
              [text]="firstAvatarText"
            ></ad-avatar-text>
          </ad-map-control-tooltip>
          <ad-avatar-icon
            *ngIf="!firstAvatarText"
            size="xs"
            backgroundColor="neutral-200"
            class="card-info-avatar-icon"
          >
            <ad-icon
              icon
              [glyph]="firstAvatarFallbackGlyph"
              color="neutral-400"
            ></ad-icon>
          </ad-avatar-icon>
          <ad-map-control-tooltip
            *ngIf="secondAvatarText"
            [text]="secondAvatarTooltip"
            class="map-control-tooltip-positioning card-info-avatar"
            position="top"
            color="neutral"
          >
            <ad-avatar-text
              *ngIf="secondAvatarText"
              size="xs"
              backgroundColor="primary-400"
              [charToShow]="2"
              [text]="secondAvatarText"
            ></ad-avatar-text>
          </ad-map-control-tooltip>
          <ad-avatar-icon
            *ngIf="!secondAvatarText"
            size="xs"
            backgroundColor="neutral-200"
            class="card-info-avatar-icon"
          >
            <ad-icon
              [glyph]="secondAvatarFallbackGlyph"
              color="neutral-400"
            ></ad-icon>
          </ad-avatar-icon>
        </div>
      </ng-template>
    </ng-container>
    <!-- Information -->
    <div class="card-info-content-information">
      <div
        *ngIf="isSkeleton; else titleContainer"
        class="card-info-tooltip-skeleton"
      ></div>
      <ng-template #titleContainer>
        <ad-tooltip
          [text]="title + ' ' + titleSmall"
          [position]="tooltipPosition"
          [color]="'neutral'"
          class="card-info-tooltip"
        >
          <div
            *ngIf="title.length"
            class="card-info-tooltip-container hide-scrollbar text-p3"
          >
            {{ title }}
            <span
              class="card-info-second-title text-p3"
              *ngIf="titleSmall.length"
            >
              {{ titleSmall }}
            </span>
          </div>
        </ad-tooltip>
      </ng-template>
      <div
        class="card-info-subtitle-container hide-scrollbar text-caption"
        *ngIf="subtitle || isSkeleton"
      >
        <ng-container
          *ngIf="
            isSkeleton && subtitle && subtitle.length;
            else subtitleContainer
          "
        >
          <div class="card-info-subtitle-skeleton-container"></div>
        </ng-container>
        <ng-template #subtitleContainer>
          <span
            class="card-info-subtitle"
            *ngIf="subtitle && subtitle.length"
            [ngClass]="{ 'has-second-subtitle': subtitleSmall.length }"
          >
            {{ subtitle }}
          </span>
          <span
            *ngIf="subtitleSmall && subtitleSmall.length"
            class="card-info-second-subtitle text-caption"
          >
            {{ subtitleSmall }}
          </span>
        </ng-template>
      </div>
      <ng-container *ngIf="isSkeleton; else infoContainer">
        <div class="card-info-extra-info-container-skeleton">
          <div class="card-info-extra-info-container-skeleton-1"></div>
          <div class="card-info-extra-info-container-skeleton-2"></div>
          <div
            [ngClass]="isSkeleton ? 'is-skeleton' : 'is-not-skeleton'"
            class="card-info-divider"
          ></div>
          <div class="card-info-extra-info-container-skeleton-3"></div>
          <div class="card-info-extra-info-container-skeleton-4"></div>
        </div>
      </ng-container>
      <ng-template #infoContainer>
        <div
          class="card-info-container-data hide-scrollbar"
          *ngIf="infoItems && infoItems.length"
        >
          <div *ngFor="let item of infoItems" class="card-info-info-item">
            <ad-status-component
              *ngIf="item.status; else infoIcon"
              [color]="item.status"
              >{{ item.text }}</ad-status-component
            >

            <ng-template #infoIcon>
              <ad-icon *ngIf="item.icon" [glyph]="item.icon"></ad-icon>
              <p class="card-info-container-data-text text-footer">
                {{ item.text }}
              </p>
            </ng-template>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
