import { Component, EventEmitter, Input, Output } from '@angular/core';

export enum buttonTabEnum {
  MAP = 'map',
  AGENDA = 'agenda',
}
@Component({
  selector: 'astus-map-view-tabs-button',
  templateUrl: './map-view-tabs-button.component.html',
  styleUrls: ['./map-view-tabs-button.component.scss'],
})
export class MapViewTabsButtonComponent {
  buttonTabEnum = buttonTabEnum;

  @Input() selectTab: buttonTabEnum | null = buttonTabEnum.MAP;

  @Output() selectTabEvent: EventEmitter<buttonTabEnum> = new EventEmitter();

  onClickTab(tab: buttonTabEnum) {
    this.selectTab = tab;
    this.selectTabEvent.emit(this.selectTab);
  }
}
