import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';

import { IconAtomType } from '../../atoms';

@Component({
  selector: 'ad-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadOrganism {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @ViewChild('fileInput', { static: false })
  fileInput!: ElementRef<HTMLInputElement>;

  @Input()
  fontIcon?: IconAtomType;

  @Input()
  label?: string;

  @Input()
  linkText?: string;

  @Input()
  buttonText?: string;

  @Input()
  footerText?: string;

  @Input()
  footerTextColored?: string;

  @Input()
  footerIcon?: IconAtomType;

  @Input()
  fileTypes: string[] = [];

  formData = new FormData();

  @Output()
  uploadDropEvent = new EventEmitter<DragEvent>();

  @Output()
  uploadInputEvent = new EventEmitter<FormData>();

  @Output()
  buttonEvent = new EventEmitter<Event>();

  @Output()
  downloadLinkEvent = new EventEmitter<Event>();

  /**
   * @description
   * This function is called when the user clicks on the button.
   * It prevents the default behavior of the browser and emits an event.
   * @param event The event that contains the files to upload
   * @returns void
   */
  onButtonClick(event: Event) {
    this.buttonEvent.emit(event);
    event.preventDefault();
  }

  /**
   * @description
   * This function is called when the drag event is over the drop zone.
   * It prevents the default behavior of the browser and adds a class
   * to the drop zone to show the drag effect.
   * @param event The event that contains the files to upload
   * @returns void
   */
  onDragOver(event: Event) {
    event.preventDefault();
    const form = this.el.nativeElement.querySelector('#drop-zone');

    this.renderer.addClass(form, 'drag-over-effect');
  }

  /**
   * @description
   * This function to remove the drag effect from the drop zone.
   * @returns void
   */
  removeDragClass() {
    const form = this.el.nativeElement.querySelector('#drop-zone');
    this.renderer.removeClass(form, 'drag-over-effect');
  }

  /**
   * @description
   * This function is called when the user selects files to upload.
   * It iterates over the files and starts the upload process for
   * each file.
   * @param event The event that contains the files to upload
   * @returns void
   */
  fileUpload() {
    const fileList = this.fileInput.nativeElement.files;

    if (fileList) {
      Array.prototype.forEach.call(fileList, (file) => {
        this.formData.append('file', file, file.name);
      });
      this.uploadInputEvent.emit(this.formData);
    }
  }

  /**
   * @description
   * This function is called when the user drops files into the drop zone.
   * It prevents the default behavior of the browser and emits an event
   * containing the files to upload.
   * @param event The event that contains the files to upload
   * @returns void
   */
  dropFiles(event: DragEvent) {
    event.preventDefault();

    const form = this.el.nativeElement.querySelector('#drop-zone');
    this.renderer.removeClass(form, 'drag-over-effect');

    const fileList = event.dataTransfer?.files;
    if (fileList) {
      this.uploadDropEvent.emit(event);
    }
  }
}
