import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';

import { appRoutesEnum } from '../app-routing.module';
import { AppInitializerService } from '../services/appInitializer.service';
import { PermissionsService } from '../services/permissions.service';

/**
 * Used in the CanActive properties of Routes object for routing modules.
 * Checks if the specified feature flag is enabled or if the array of feature flags are enabled.
 * If one or more flag is not enabled, it navigates to the '/not-found' route.
 * @param route - The activated route snapshot.
 * @returns A promise that resolves to a boolean indicating if the flag is enabled.
 */
export const canActivatePermissions: CanActivateFn = (
  route: ActivatedRouteSnapshot
) => {
  return inject(PermissionsGuard).canActivate(route);
};

@Injectable()
export class PermissionsGuard {
  constructor(
    private permissionsService: PermissionsService,
    private router: Router,
    private appInitializerService: AppInitializerService
  ) {}

  /**
   * Checks if the specified feature flag is enabled.
   * If the flag is not enabled, it navigates to the '/not-found' route.
   * @param route - The activated route snapshot.
   * @returns A promise that resolves to a boolean indicating if the flag is enabled.
   */
  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const permissions = route.data['permissions'];
    let value = false;

    return this.appInitializerService.initializeApp().then(async () => {
      await this.permissionsService
        .getPermission$(permissions)
        .subscribe((newValue: boolean) => {
          value = newValue;
        });

      if (!value) {
        this.redirectToNotFound();
      }

      return value;
    });
  }

  redirectToNotFound() {
    this.router.navigate([`/${appRoutesEnum.NOT_FOUND}`]);
  }
}
