import {
  AfterContentChecked,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';

import { ColorType } from '../../common-ng-design-system.colors';

@Component({
  selector: 'ad-map-control-tooltip',
  templateUrl: './map-control-tooltip.component.html',
  styleUrls: ['./map-control-tooltip.component.scss'],
})
export class MapControlTooltipMolecule implements AfterContentChecked, OnInit {
  @Input()
  text?: string;

  @Input()
  color: ColorType = 'primary';

  @Input()
  position: 'top' | 'bottom' = 'top';

  @Input()
  class = '';

  @Input()
  avatar?: boolean;

  // Using the variable to control the display of the tooltip before everything is loaded
  loaded = false;

  ngOnInit(): void {
    this.classList = 'position-' + this.position + ' color-' + this.color;
  }

  ngAfterContentChecked() {
    // Using the variable to control the display of the tooltip before everything is loaded
    this.loaded = true;
  }

  @HostBinding('class') classList =
    'position-' + this.position + ' color-' + this.color;
}
