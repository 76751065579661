import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@auth0/auth0-angular';

import { NotFoundComponent } from './components/errors/not-found/not-found.component';

import { SupervisionHeaderComponent } from './components/header/supervision-header/supervision-header.component';

import { canActivateEnvironment } from './guards/environment.guard';
import { canActivateFlag } from './guards/launch-darkly.guard';
import { ReportsComponent } from './reports/reports.component';
import { ResourceComponent } from './resource/resource.component';
import { SettingsComponent } from './settings/settings.component';
import { TestContentComponent } from './test-content/test-content.component';

export enum appRoutesEnum {
  MAP = 'map',
  RESOURCES = 'resources',
  REPORTS = 'reports',
  SETTINGS = 'settings',
  TEST = 'test',
  NOT_FOUND = 'not-found',
  CIRCUITS = 'circuits',
  USERS = 'users',
  VEHICLES = 'vehicles',
  SUPERVISION = 'supervision',
}

export enum resourceTabType {
  CIRCUITS = 'circuits',
  DRIVERS = 'users',
  VEHICLES = 'vehicles',
  ROUTES = 'routes',
  STOPS = 'stops',
}

const routes: Routes = [
  {
    path: '',
    redirectTo: `/${appRoutesEnum.SUPERVISION}`,
    pathMatch: 'full',
    outlet: 'header-top',
  },
  {
    path: '',
    children: [
      {
        path: appRoutesEnum.SUPERVISION,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./map/supervision.module').then(
                (m) => m.SupervisionModule
              ),
          },
          {
            outlet: 'header-top',
            path: '',
            component: SupervisionHeaderComponent,
          },
        ],
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: '',
    children: [
      {
        path: appRoutesEnum.RESOURCES,
        data: { title: 'RESOURCES.TITLE' },
        children: [
          {
            path: '',
            redirectTo: appRoutesEnum.CIRCUITS,
            pathMatch: 'full',
          },
          {
            path: ':tab',
            data: {
              reuseComponent: true,
            },
            component: ResourceComponent,
          },
          {
            path: ':tab/:id',
            data: {
              reuseComponent: true,
            },
            component: ResourceComponent,
          },
        ],
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: '',
    children: [
      {
        path: appRoutesEnum.REPORTS,
        children: [
          {
            path: '',
            component: ReportsComponent,
            canActivate: [canActivateFlag],
            data: {
              canActivateFlagId: 'rapports',
            },
            loadChildren: () =>
              import('./reports/reports.module').then((m) => m.ReportsModule),
          },
          {
            outlet: 'header-top',
            path: '',
            component: SupervisionHeaderComponent,
          },
        ],
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: appRoutesEnum.SETTINGS,
        children: [
          {
            path: '',
            component: SettingsComponent,
            loadChildren: () =>
              import('./settings/settings.module').then(
                (m) => m.SettingsModule
              ),
          },
        ],
      },
    ],
  },

  { path: appRoutesEnum.NOT_FOUND, component: NotFoundComponent },
  {
    path: '',
    canActivate: [AuthGuard, canActivateEnvironment],
    data: { environment: ['local', 'dev', 'ephemeral'] },
    children: [
      {
        path: '',
        children: [
          {
            path: appRoutesEnum.TEST,
            component: TestContentComponent,
          },
        ],
      },
    ],
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
