import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { menuActions, showMenuFeature } from '../../store/';

@Component({
  selector: 'astus-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  isMenuShown = false;

  constructor(private elementRef: ElementRef, private store: Store) {}

  ngOnInit(): void {
    this.store.select(showMenuFeature).subscribe((showMenu) => {
      this.isMenuShown = showMenu;
    });
  }

  closeMenu() {
    if (this.isMenuShown) {
      this.store.dispatch(menuActions.closeMenu());
    }
  }

  @HostListener('document:mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    const menuElement = this.elementRef.nativeElement as HTMLElement;
    const menuButton = document.querySelector('.menu-button');
    if (
      !menuElement?.contains(targetElement) &&
      !menuButton?.contains(targetElement)
    ) {
      this.closeMenu();
    }
  }
}
