import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidatorFn,
  FormControl,
} from '@angular/forms';
import { DropdownItemInterface } from '@common/ng-design-system';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { Subscription } from 'rxjs';

import { PermissionsService } from '../../services/permissions.service';
import { alertActions, timezoneList } from '../../store';

import { buttonProcessStateEnum } from '../settings.enum';

@Component({
  selector: 'astus-settings-company-profile',
  templateUrl: './settings-company-profile.component.html',
  styleUrls: ['./settings-company-profile.component.scss'],
})
export class SettingsCompanyProfileComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store,
    private readonly translateService: TranslateService,
    private formBuilder: FormBuilder,
    private permissionsService: PermissionsService
  ) {
    this.permissionEditProfilePage$ = this.permissionsService
      .getPermission$('general.companyProfileEdit')
      .subscribe((value) => {
        this.canEditProfilePage = value;
      });

    this.formGroup = this.formBuilder.group({
      enterpriseName: this.createFormControl(true, Validators.required),
      enterpriseNumber: this.createFormControl(true, Validators.required),
      enterpriseAddress: this.createFormControl(
        !this.enterpriseProfile.isOwner,
        Validators.required
      ),
      enterpriseTerminalSelection: this.createFormControl(
        !this.enterpriseProfile.isOwner,
        Validators.required
      ),
      enterpriseTimezoneSelection: this.createFormControl(
        !this.enterpriseProfile.isOwner,
        Validators.required
      ),
      motherCompany: this.formBuilder.group({
        enterpriseName: this.createFormControl(true, Validators.required),
        enterpriseNumber: this.createFormControl(true, Validators.required),
        enterpriseAddress: this.createFormControl(true, Validators.required),
        enterpriseTerminalSelection: this.createFormControl(
          true,
          Validators.required
        ),
        enterpriseTimezoneSelection: this.createFormControl(
          true,
          Validators.required
        ),
      }),
    });
  }

  ngOnInit(): void {
    this.populateTimezones();
    this.setupFormChangeHandler();
  }

  ngOnDestroy(): void {
    this.permissionEditProfilePage$?.unsubscribe();
  }

  canEditProfilePage = false;
  permissionEditProfilePage$: Subscription;

  formChanged = false;
  timezoneList: string[] = timezoneList;
  timezones: DropdownItemInterface[] = [];
  formGroup: FormGroup;
  buttonEnums = buttonProcessStateEnum;
  processState: buttonProcessStateEnum = this.buttonEnums.READY;
  savedSuccess = false;
  savedFailure = false;

  // For testing / TO BE REMOVED WHEN API READY:
  forceSaveFailure = false;
  // TODO: Replace with terminals from API
  terminals: DropdownItemInterface[] = [
    { value: '1', label: 'Terminal 1' },
    { value: '2', label: 'Terminal 2' },
    { value: '3', label: 'Terminal 3' },
    { value: '4', label: 'Terminal 4' },
  ];
  // TODO: hook these up when API is ready
  // MOCK DATA
  @Input() enterpriseProfile = {
    // Change these for testing.
    // isOwner toggles read-only mode.
    // hasMotherCompany toggles the mother company section.
    isOwner: true,
    hasMotherCompany: true,

    generalInfo: {
      enterpriseTimezoneSelection: 'UTC+06:00',
      enterpriseName: 'Astus Financial',
      enterpriseNumber: '1234567890',
      enterpriseAddress: '1234 Main St, San Diego, CA 92101',
      enterpriseTerminalSelection: '1',
    },
    motherCompany: {
      enterpriseTimezoneSelection: 'UTC+06:00',
      enterpriseName: 'Astus Financial Mother',
      enterpriseNumber: '0987654321',
      enterpriseAddress: '1234 Extra Main St, San Diego, CA 92101',
      enterpriseTerminalSelection: '3',
    },
  };

  private createFormControl(
    disabled: boolean,
    validators: ValidatorFn | ValidatorFn[] | null
  ): FormControl {
    const isDisabled = this.canEditProfilePage ? disabled : true;
    return new FormControl({ value: '', disabled: isDisabled }, validators);
  }

  private populateTimezones(): void {
    timezoneList.forEach((timezone) => {
      this.translateService.stream(`TIMEZONES.${timezone}`).subscribe((res) => {
        this.timezones.push({
          value: timezone,
          label: res + ` (${timezone})`,
        });
        if (this.timezones.length === timezoneList.length) this.resetForm();
      });
    });
  }

  private setupFormChangeHandler(): void {
    this.formGroup.valueChanges.subscribe((change) => {
      const {
        enterpriseTimezoneSelection,
        enterpriseTerminalSelection,
        enterpriseAddress,
      } = change;
      const { generalInfo } = this.enterpriseProfile;

      if (
        enterpriseTimezoneSelection !==
          generalInfo.enterpriseTimezoneSelection ||
        enterpriseTerminalSelection !==
          generalInfo.enterpriseTerminalSelection ||
        enterpriseAddress !== generalInfo.enterpriseAddress
      ) {
        this.formChanged = true;
      }

      if (!this.formChanged) this.formGroup.markAsPristine();
    });
  }

  copyAlert(): void {
    this.store.dispatch(
      alertActions.showNewAlert({
        alert: {
          title: `${this.translateService.instant(
            'GENERAL.COPIED_TO_CLIPBOARD'
          )}`,
          colorType: 'success',
        },
      })
    );
  }

  resetForm(): void {
    const { generalInfo, motherCompany } = this.enterpriseProfile;

    this.formGroup.reset({
      enterpriseName: generalInfo.enterpriseName,
      enterpriseNumber: generalInfo.enterpriseNumber,
      enterpriseAddress: generalInfo.enterpriseAddress,
      enterpriseTerminalSelection: generalInfo.enterpriseTerminalSelection,
      enterpriseTimezoneSelection: generalInfo.enterpriseTimezoneSelection,
      motherCompany: {
        enterpriseName: motherCompany.enterpriseName,
        enterpriseNumber: motherCompany.enterpriseNumber,
        enterpriseAddress: motherCompany.enterpriseAddress,
        enterpriseTerminalSelection: motherCompany.enterpriseTerminalSelection,
        enterpriseTimezoneSelection: motherCompany.enterpriseTimezoneSelection,
      },
    });

    this.formGroup.markAsPristine();
    this.processState = this.buttonEnums.READY;
    this.savedFailure = false;
    this.savedSuccess = false;
    this.formChanged = false;
  }

  resetProcessState(): void {
    this.formGroup.markAsPristine();
    this.processState = this.buttonEnums.READY;
  }

  saveForm(): object | false {
    if (this.formGroup.invalid) return false;

    const disableFields = [
      'enterpriseAddress',
      'enterpriseTerminalSelection',
      'enterpriseTimezoneSelection',
    ];

    disableFields.forEach((field) => {
      this.formGroup.get(field)?.disable();
    });

    this.savedFailure = false;
    this.processState = this.buttonEnums.PROCESSING;

    // TODO: hook up to API
    setTimeout(() => {
      this.savedSuccess = !this.forceSaveFailure;
      this.savedFailure = this.forceSaveFailure;

      disableFields.forEach((field) => {
        this.formGroup.get(field)?.enable();
      });

      if (this.savedFailure) {
        this.processState = this.buttonEnums.ERROR;
        return false;
      }

      const {
        enterpriseName,
        enterpriseNumber,
        enterpriseAddress,
        enterpriseTerminalSelection,
        enterpriseTimezoneSelection,
      } = this.formGroup.controls;

      disableFields.forEach((field) => {
        this.formGroup.get(field)?.enable();
      });

      this.processState = this.buttonEnums.SUCCESS;
      this.formChanged = false;

      return {
        enterpriseName: enterpriseName.value,
        enterpriseNumber: enterpriseNumber.value,
        enterpriseAddress: enterpriseAddress.value,
        enterpriseTerminalSelection: enterpriseTerminalSelection.value,
        enterpriseTimezoneSelection: enterpriseTimezoneSelection.value,
      };
    }, 3000);

    return false;
  }
}
