import { Component, Input } from '@angular/core';
import { VehicleStateType } from '@fms/ng-fms-api-client';

@Component({
  selector: 'astus-agenda-item-header',
  templateUrl: './agenda-item-header.component.html',
  styleUrls: ['./agenda-item-header.component.scss'],
})
export class AgendaItemHeaderComponent {
  @Input() title!: string;
  @Input() status: VehicleStateType = VehicleStateType.OffDuty;

  vehicleState = VehicleStateType;

  statusColor = '';
}
