import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { multiSelectListItemInterface } from './multi-select-dropdown.interface';

@Component({
  selector: 'astus-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss'],
})
export class MultiSelectDropdownComponent implements OnInit, OnDestroy {
  allSelected = false;
  isIndeterminate = false;
  @Input() isOpen = true;
  @Input() title = '';
  @Input() list?: multiSelectListItemInterface[];
  @Input() formGroup: FormGroup = new FormGroup({});
  @Input() useCount = true;

  @Output() shareCheckedList = new EventEmitter();
  @Output() shareIndividualCheckedList = new EventEmitter();

  formGroupSubscription = new Subscription();
  selectedItems = 0;

  ngOnInit(): void {
    this.checkForNumberOfSelectedItems(this.formGroup.value);
    this.formGroupSubscription = this.formGroup.valueChanges.subscribe(
      (value) => {
        this.checkForNumberOfSelectedItems(value);
      }
    );
  }

  ngOnDestroy(): void {
    this.formGroupSubscription.unsubscribe();
  }

  /**
   * @description Determines the state for the main checkbox
   */
  checkForNumberOfSelectedItems(value: { [key: string]: boolean }) {
    const selectedItems = Object.values(value).filter((item: boolean) => item);
    this.allSelected = selectedItems.length === this.list?.length;
    this.isIndeterminate = selectedItems.length > 0 && !this.allSelected;
    this.selectedItems = selectedItems.length;
  }

  /**
   * @description Checks or unchecks all the items in the list based on the isChecked value
   * @param isChecked
   */
  selectAll(isChecked: boolean) {
    this.list?.forEach((item) => {
      this.formGroup.get(item.controlName)?.setValue(isChecked);
    });
  }
}
