import { createReducer, on } from '@ngrx/store';

import { settingsMock } from '../settings.mock';

import { settingsReducerInterface } from './settings';
import { settingsAPIActions } from './settings.action';

export const settingsFeatureKey = 'settingsFeature';

export const settingsInitialState: settingsReducerInterface = {
  ...settingsMock,
};

export const settingsFeatureReducer = createReducer<settingsReducerInterface>(
  settingsInitialState,
  on(settingsAPIActions.groupsLoaded, (_state, { groupsList }) => ({
    ..._state,
    groupsList,
  })),
  on(settingsAPIActions.groupsUpdate, (_state, { groupsList }) => ({
    ..._state,
    groupsList,
  })),
  on(settingsAPIActions.rolesLoaded, (_state, { rolesList }) => ({
    ..._state,
    rolesList,
  })),
  on(settingsAPIActions.rolesError, (_state, { error, message }) => ({
    ..._state,
    error: {
      error,
      message,
    },
  })),
  on(settingsAPIActions.rolesCreate, (_state, { role }) => ({
    ..._state,
    rolesList: [..._state.rolesList, role],
  })),
  on(settingsAPIActions.rolesEdit, (_state, { role }) => ({
    ..._state,
    rolesList: _state.rolesList.map((r) => (r.id === role.id ? role : r)),
  })),
  on(settingsAPIActions.roleDelete, (_state, { id }) => ({
    ..._state,
    rolesList: _state.rolesList.filter((role) => role.id !== id),
  })),
  on(settingsAPIActions.profileEdit, (_state, { profile }) => ({
    ..._state,
    profile,
  }))
);
