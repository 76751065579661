import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IconAtomType } from '../../atoms';
import { colors } from '../../common-ng-design-system.colors';

import { alertStatusEnum } from './alert-card.enum';

@Component({
  selector: 'ad-alert-card',
  templateUrl: './alert-card.component.html',
  styleUrls: ['./alert-card.component.scss'],
})
export class AlertCardOrganism {
  @Input() hovered = false;

  @Input() communicateText = '';

  @Input() resolveText = '';

  @Input() status: alertStatusEnum = alertStatusEnum.NEUTRAL;

  @Input() title = '';
  @Input() subtitle = '';
  @Input() description = '';
  @Input() icon: IconAtomType | undefined = 'alert';
  @Input() iconColor: keyof typeof colors = 'warning-500';

  /* =================================================      EVENTS     ================================================*/
  @Output() solveEvent = new EventEmitter<boolean>();
  @Output() communicateEvent = new EventEmitter<boolean>();
}
