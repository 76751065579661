<div class="empty-state-info-container">
  <ad-icon *ngIf="icon" [glyph]="icon" [color]="'primary-400'"></ad-icon>
  <ng-content></ng-content>
  <div class="empty-state-error-text-container">
    <p class="error-message text-subHeader">
      {{ errorMessage }}
    </p>
    <p class="error-description text-subHeader">
      {{ errorDescription }}
    </p>
  </div>
</div>
