<div class="modal-container" [ngClass]="{ open: isShown }">
  <div class="cards-container" [ngClass]="{ display: !completedAssignments }">
    <div class="cards-content">
      <div class="create-association-title text-p2">
        {{ 'CONTROL_PANEL.MODAL_ASSOCIATION.CREATE_ASSOCIATION' | translate }}
      </div>
      <div
        class="card-minimal-container"
        [ngClass]="{
          'is-assigned': data.circuit && !completedAssignments,
          'is-not-assigned': !data.circuit || completedAssignments
        }"
      >
        <div class="card-minimal-content">
          <ad-card-minimal
            class="card-minimal"
            [title]="data.circuit"
            [name]="data.circuit"
            (clickCloseEvent)="removeAssignment(assignmentValue.circuits)"
          ></ad-card-minimal>
        </div>
      </div>
      <div
        class="card-minimal-container"
        [ngClass]="{
          'is-assigned': data.vehicle && !completedAssignments,
          'is-not-assigned': !data.vehicle || completedAssignments
        }"
      >
        <div class="card-minimal-content vehicle-user-assigned">
          <ad-card-minimal
            class="card-minimal"
            [title]="data.vehicle"
            [name]="data.vehicle"
            (clickCloseEvent)="removeAssignment(assignmentValue.vehicles)"
          ></ad-card-minimal>
        </div>
      </div>
      <div
        class="card-minimal-container"
        [ngClass]="{
          'is-assigned': data.user && !completedAssignments,
          'is-not-assigned': !data.user || completedAssignments
        }"
      >
        <div class="card-minimal-content vehicle-user-assigned">
          <ad-card-minimal
            class="card-minimal"
            [title]="data.user"
            [name]="data.user"
            (clickCloseEvent)="removeAssignment(assignmentValue.drivers)"
          ></ad-card-minimal>
        </div>
      </div>
    </div>
  </div>
  <div class="buttons-container" [ngClass]="{ display: !completedAssignments }">
    <div class="buttons-content">
      <button
        astus-design-button
        size="sm"
        theme="dark"
        kind="outlined"
        color="neutral"
        class="cancel-button"
        (click)="close()"
      >
        {{ 'CONTROL_PANEL.MODAL_ASSOCIATION.CANCEL' | translate }}
      </button>
      <button
        class="save-button"
        astus-design-button
        theme="dark"
        size="sm"
        kind="filled"
        color="primary"
        (click)="assign()"
        [disabled]="checkAllAssignmentCompleted()"
      >
        {{ 'CONTROL_PANEL.MODAL_ASSOCIATION.SAVE' | translate }}
      </button>
    </div>
  </div>

  <div
    class="completed-container"
    [ngClass]="{ display: completedAssignments }"
  >
    <div class="completed-content">
      <div class="avatar-container">
        <ad-avatar-text
          *ngIf="data.user"
          size="xs"
          backgroundColor="primary-400"
          class="avatar-user"
          [charToShow]="2"
          [text]="formatText(data.user)"
        ></ad-avatar-text>
        <ad-avatar-text
          *ngIf="data.vehicle"
          size="xs"
          backgroundColor="primary-400"
          [charToShow]="2"
          [text]="formatText(data.vehicle)"
          class="avatar"
        ></ad-avatar-text>
      </div>
      <div class="completed-text text-subHeader">
        {{ data.circuit }}
        <br />
        {{
          'CONTROL_PANEL.MODAL_ASSOCIATION.ASSIGNED_SUCCESSFULLY' | translate
        }}
      </div>
    </div>
  </div>
</div>
