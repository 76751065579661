import { VehicleModelDto } from '@fms/ng-fms-api-client';

import { tablePropsInterface, tableHeaderInterface } from './table/table.d';

export const tableCircuitHeaders: tableHeaderInterface[] = [
  {
    label: 'Mise à jour',
    dataKey: 'timeLeft',
  },
  {
    label: 'Temps',
    dataKey: 'time',
  },
  {
    label: 'Nom',
    dataKey: 'name',
  },
  {
    label: 'Étudiants',
    dataKey: 'students',
  },
  {
    label: 'Type',
    dataKey: 'type',
  },
  {
    label: 'Commission scolaire',
    dataKey: 'schoolBoard',
  },
  {
    label: 'Client',
    dataKey: 'client',
  },
  {
    label: 'Arrêts',
    dataKey: 'stops',
  },
  {
    label: 'Routes',
    dataKey: 'routes',
  },
  {
    label: 'Véhicule',
    dataKey: 'vehicle',
  },
  {
    label: 'Conducteur',
    dataKey: 'driver',
  },
  {
    label: 'Transporteur',
    dataKey: 'transporter',
  },
  {
    label: 'Location',
    dataKey: 'location',
  },
];

export interface circuitTableInterface extends tablePropsInterface {
  id: number;
  timeLeft: [string, { isRed: boolean }];
  time?: string;
  name: string;
  students: number;
  vehicle?: string;
  driver: string;
  transporter: string;
  location: string;
}

export const tableCircuitData: circuitTableInterface[] = [
  {
    id: 1,
    timeLeft: ['Hier 12h32', { isRed: false }],
    time: undefined,
    name: '4700',
    students: 32,
    vehicle: 'Autobus 003',
    driver: 'Cadieux, Marc-André',
    transporter: 'Autocar Deslisle',
    location: 'Alama, QC',
  },
  {
    id: 2,
    timeLeft: ['Hier 12h32', { isRed: true }],
    time: 'PM',
    name: '7538',
    students: 32,
    type: 'Circuit Geobus',
    schoolBoard: 'CS Des Trois Lacs',
    client: 'École Du Geai Bleu',
    stops: 32,
    routes: 2,
    vehicle: 'Autobus 003',
    driver: 'Cadieux, Marc-André',
    transporter: 'Autocar Deslisle',
    location: 'Alama, QC',
  },
  {
    id: 3,
    timeLeft: ['Hier 12h32', { isRed: false }],
    time: 'AM',
    name: '4701',
    students: 28,
    type: 'Circuit Geobus',
    schoolBoard: 'CS Des Trois Lacs',
    client: 'École Du Papillon',
    stops: 30,
    routes: 3,
    vehicle: 'Autobus 004',
    driver: 'Tremblay, Sophie',
    transporter: 'Autocar Deslisle',
    location: 'Alama, QC',
  },
  {
    id: 4,
    timeLeft: ['Hier 12h32', { isRed: false }],
    time: 'AM',
    name: '4700',
    students: 32,
    type: 'Circuit Geobus',
    schoolBoard: 'CS Des Trois Lacs',
    client: 'École Du Renard',
    stops: 25,
    routes: 2,
    vehicle: 'Autobus 005',
    driver: 'Gagnon, Jean',
    transporter: 'Autocar Deslisle',
    location: 'Alama, QC',
  },
  {
    id: 5,
    timeLeft: ['Hier 12h32', { isRed: false }],
    time: 'AM',
    name: '4700',
    students: 32,
    type: 'Circuit Geobus',
    schoolBoard: 'CS Des Trois Lacs',
    client: 'École Du Hibou',
    stops: 28,
    routes: 3,
    vehicle: 'Autobus 006',
    driver: 'Lavoie, Marie',
    transporter: 'Autocar Deslisle',
    location: 'Alama, QC',
  },
  {
    id: 6,
    timeLeft: ['Hier 12h32', { isRed: false }],
    time: 'AM',
    name: '4704',
    students: 29999,
    type: 'Circuit Geobus',
    schoolBoard: 'CS Des Trois Lacs',
    client: 'École Du Lion',
    stops: 27,
    routes: 2,
    vehicle: 'Autobus 007',
    driver: 'Leclerc, Pierre',
    transporter: 'Autocar Deslisle',
    location: 'Alama, QC',
  },
  {
    id: 7,
    timeLeft: ['Hier 12h32', { isRed: false }],
    time: 'AM',
    name: '4700',
    students: 32,
    type: 'Circuit Geobus',
    schoolBoard: 'CS Des Trois Lacs',
    client: 'École Du Tigre',
    stops: 31,
    routes: 3,
    vehicle: 'Autobus 008',
    driver: 'Roy, Isabelle',
    transporter: 'Autocar Deslisle',
    location: 'Alama, QC',
  },
  {
    id: 8,
    timeLeft: ['Hier 12h32', { isRed: false }],
    time: 'AM',
    name: '4700',
    students: 32,
    type: 'Circuit Geobus',
    schoolBoard: 'CS Des Trois Lacs',
    client: 'École Du Papillon',
    stops: 29,
    routes: 2,
    vehicle: 'Autobus 009',
    driver: 'Lemieux, Martin',
    transporter: 'Autocar Deslisle',
    location: 'Alama, QC',
  },
  {
    id: 9,
    timeLeft: ['Hier 12h32', { isRed: false }],
    time: 'AM',
    name: '4707',
    students: 27,
    type: 'Circuit Geobus',
    schoolBoard: 'CS Des Trois Lacs',
    client: 'École Du Renard',
    stops: 26,
    routes: 3,
    vehicle: 'Autobus 010',
    driver: 'Gagné, Nathalie',
    transporter: 'Autocar Deslisle',
    location: 'Alama, QC',
  },
  {
    id: 10,
    timeLeft: ['Hier 12h32', { isRed: false }],
    time: 'AM',
    name: '4700',
    students: 32,
    type: 'Circuit Geobus',
    schoolBoard: 'CS Des Trois Lacs',
    client: 'École Du Hibou',
    stops: 30,
    routes: 2,
    vehicle: 'Autobus 011',
    driver: 'Lavoie, Marie',
    transporter: 'Autocar Deslisle',
    location: 'Alama, QC',
  },
  {
    id: 11,
    timeLeft: ['Hier 12h32', { isRed: false }],
    time: 'AM',
    name: '4709',
    students: 32,
    type: 'Circuit Geobus',
    schoolBoard: 'CS Des Trois Lacs',
    client: 'École Du Geai Bleu',
    stops: 32,
    routes: 2,
    vehicle: 'Autobus 003',
    driver: 'Cadieux, Marc-André',
    transporter: 'Autocar Deslisle',
    location: 'Alama, QC',
  },
  {
    id: 12,
    timeLeft: ['Hier 12h32', { isRed: false }],
    time: 'AM',
    name: '4700',
    students: 32,
    type: 'Circuit Geobus',
    schoolBoard: 'CS Des Trois Lacs',
    client: 'École Du Geai Bleu',
    stops: 32,
    routes: 2,
    vehicle: 'Autobus 003',
    driver: 'Cadieux, Marc-André',
    transporter: 'Autocar Deslisle',
    location: 'Alama, QC',
  },
  {
    id: 13,
    timeLeft: ['Hier 12h32', { isRed: false }],
    time: 'AM',
    name: '4700',
    students: 32,
    type: 'Circuit Geobus',
    schoolBoard: 'CS Des Trois Lacs',
    client: 'École Du Geai Bleu',
    stops: 32,
    routes: 2,
    vehicle: 'Autobus 003',
    driver: 'Cadieux, Marc-André',
    transporter: 'Autocar Deslisle',
    location: 'Alama, QC',
  },
];

export const tableVehicleHeaders: tableHeaderInterface[] = [
  {
    label: 'Mise à jour',
    dataKey: 'update',
  },
  {
    label: 'Temps',
    dataKey: 'time',
  },
  {
    label: 'Nom',
    dataKey: 'name',
  },
  {
    label: 'Sièges',
    dataKey: 'seats',
  },
  {
    label: 'Distance restante',
    dataKey: 'distanceRemaining',
  },
  {
    label: 'Type carburant',
    dataKey: 'fuelType',
  },
  {
    label: 'Dernière position',
    dataKey: 'lastPosition',
  },
  {
    label: 'Circuits',
    dataKey: 'circuits',
  },
  {
    label: 'Odomètre',
    dataKey: 'odometer',
  },
  {
    label: 'VIN',
    dataKey: 'vin',
  },
  {
    label: 'Plaque',
    dataKey: 'plateNumber',
  },
  {
    label: 'Modèle',
    dataKey: 'model',
  },
  {
    label: 'Année',
    dataKey: 'year',
  },
  {
    label: 'Transporteur',
    dataKey: 'transporter',
  },
  {
    label: 'Flotte',
    dataKey: 'fleet',
  },
  {
    label: 'Module # de série',
    dataKey: 'module',
  },
  {
    label: 'Version logiciel',
    dataKey: 'softwareVersion',
  },
  {
    label: 'Type de module',
    dataKey: 'tagType',
  },
  {
    label: 'Dernière connexion',
    dataKey: 'lastConnection',
  },
  {
    label: 'Maintenance',
    dataKey: 'maintenance',
  },
  {
    label: 'Véhicule',
    dataKey: 'vehicleType',
  },
];

export enum vehicleTypeEnum {
  bus = 'Autobus',
  truck = 'Camion',
  car = 'Minibus',
}
export interface vehicleTableInterface extends tablePropsInterface {
  id?: string | number;
  name: string;
  vin: string;
  update: string;
  plateNumber: string;
  module: string;
  country: string;
  province: string;
  timezone: string;
  imei: string;
  softwareVersion: string;
  lastConnection: string;
  tagType: string;
  vehicleId: string;
  brand: string;
  modelId: string;
  model: string;
  year: string;
  vehicleType: vehicleTypeEnum | '';
  fuelType: VehicleModelDto.FuelTypeEnum;
  groupName: string;
  serialNumber: string;
  homePortId: string;
  homePort: string;
  carrierId: string;
  carrier: string;
  iotDeviceId: string;

  // Data not present in the API but present in figma
  time?: string;
  seats?: number;
  distanceRemaining?: string;
  lastPosition?: string;
  circuits?: string;
  odometer?: string;
  fleet?: string;
  maintenance?: string;
  maintenanceHours?: {
    start: string;
    end: string;
  };
  tagSerialNumber?: number;
  active?: string;
}

export const tableVehicleData = [
  {
    id: 1,
    update: 'Hier 12h32',
    time: 'AM',
    name: 'Autobus 003',
    seats: 50,
    distanceRemaining: '50',
    fuelType: 'Diesel',
    lastPosition: '123 R. Principale, Longueuil',
    circuits: '002, 4700, 1234, 3456',
    odometer: '14494',
    vin: 'A4HGCM82632A005243',
    plateNumber: '01B ACD',
    model: 'Bluebird',
    year: 2002,
    transporter: 'Autocar Jeannois',
    fleet: 'Flotte ABCD',
    module: 'T234-10993837',
    tagType: 'Module A',
    softwareVersion: 'V.0.2',
    lastConnection: '2021-10-10T22:24:00',
    maintenance: '2023-12-05T00:00:00',
    maintenanceHours: {
      start: '2023-12-05T22:00:00',
      end: '2023-12-05T23:30:00',
    },
    vehicleType: vehicleTypeEnum.bus,
    brand: 'Ford',
    homePort: 'Port d’attache A',
    timezone: 'UTC-05:00',
    country: 'CA',
    province: 'CA-QC',
    tagSerialNumber: 123456789,
    active: 'active',
  },
  {
    id: 2,
    update: 'Hier 14h20',
    name: 'Autobus 004',
    time: 'AM',
    seats: 50,
    distanceRemaining: '40',
    fuelType: 'Diesel',
    lastPosition: '456 R. Principale, Longueuil',
    circuits: '003, 4701, 5678, 7890',
    odometer: '15000',
    vin: '4HGCM82632A005244',
    plateNumber: '02B ACD',
    model: 'Bluebird',
    year: 2003,
    transporter: 'Autocar Jeannois',
    fleet: 'Flotte ABCD',
    module: 'T234-10993838',
    tagType: 'Module B',
    softwareVersion: 'V.0.3',
    lastConnection: '2021-10-10T22:30:00',
    maintenance: '2023-12-05T00:00:00',
    maintenanceHours: {
      start: '2023-12-05T22:00:00',
      end: '2023-12-05T23:30:00',
    },
    vehicleType: vehicleTypeEnum.truck,
    brand: 'Ford',
    homePort: 'Port d’attache A',
    timezone: 'UTC-05:00',
    country: 'CA',
    province: 'CA-QC',
    tagSerialNumber: 123456,
    active: 'active',
  },
  {
    id: 3,
    update: "Aujourd'hui 09h15",
    name: 'Autobus 005',
    time: 'AM',
    seats: 50,
    distanceRemaining: '30',
    fuelType: 'Diesel',
    lastPosition: '789 R. Principale, Longueuil',
    circuits: '004, 4702, 9012, 1234',
    odometer: '15500',
    vin: '4HGCM82632A005245',
    plateNumber: '03B ACD',
    model: 'Bluebird',
    year: 2004,
    transporter: 'Autocar Lauzon',
    fleet: 'Flotte ABCD',
    tagType: 'Module C',
    module: 'T234-10993839',
    softwareVersion: 'V.0.4',
    lastConnection: '2021-10-10T22:36:00',
    maintenance: '2023-12-05T00:00:00',
    maintenanceHours: {
      start: '2023-12-05T22:00:00',
      end: '2023-12-05T23:30:00',
    },
    vehicleType: vehicleTypeEnum.car,
    brand: 'Ford',
    homePort: 'Port d’attache B',
    timezone: 'UTC-05:00',
    country: 'CA',
    province: 'CA-QC',
    tagSerialNumber: 123456789,
    active: 'active',
  },
  {
    id: 4,
    update: 'Hier 16h45',
    name: 'Autobus 006',
    time: 'AM',
    seats: 50,
    distanceRemaining: '20',
    fuelType: 'Diesel',
    lastPosition: '987 R. Principale, Longueuil',
    circuits: '005, 4703, 2345, 6789',
    odometer: '16000',
    vin: '4HGCM82632A005246',
    plateNumber: '04B ACD',
    model: 'Bluebird',
    year: 2005,
    transporter: 'Autocar Jeannois',
    fleet: 'Flotte ABCD',
    module: 'T234-10993840',
    tagType: 'Module C',
    softwareVersion: 'V.0.5',
    lastConnection: '2021-10-10T22:42:00',
    maintenance: '2023-12-05T00:00:00',
    maintenanceHours: {
      start: '2023-12-05T22:00:00',
      end: '2023-12-05T23:30:00',
    },
    vehicleType: vehicleTypeEnum.bus,
    brand: 'Ford',
    homePort: 'Port d’attache B',
    timezone: 'UTC-05:00',
    country: 'CA',
    province: 'CA-QC',
    tagSerialNumber: 123456789,
    active: 'active',
  },
  {
    id: 5,
    update: "Aujourd'hui 07h55",
    name: 'Autobus 007',
    time: 'AM',
    seats: 50,
    distanceRemaining: '10',
    fuelType: 'Diesel',
    lastPosition: '654 R. Principale, Longueuil',
    circuits: '006, 4704, 3456, 7890',
    odometer: '16500',
    vin: '4HGCM82632A005247',
    plateNumber: '05B ACD',
    model: 'Bluebird',
    year: 2006,
    transporter: 'Autocar Lauzon',
    fleet: 'Flotte ABCD',
    module: 'T234-10993841',
    vehicleType: vehicleTypeEnum.bus,
    softwareVersion: 'V.0.6',
    lastConnection: '2021-10-10T22:48:00',
    maintenance: '2023-12-05T00:00:00',
    maintenanceHours: {
      start: '2023-12-05T22:00:00',
      end: '2023-12-05T23:30:00',
    },
    brand: 'Ford',
    homePort: 'Port d’attache A',
    timezone: 'UTC-05:00',
    country: 'CA',
    province: 'CA-QC',
    tagSerialNumber: 123456789,
    active: 'active',
    tagType: 'Module A',
  },
  {
    id: 6,
    update: 'Hier 13h10',
    name: 'Autobus 008',
    time: 'AM',
    seats: 50,
    distanceRemaining: '0',
    fuelType: 'Diesel',
    lastPosition: '321 R. Principale, Longueuil',
    circuits: '007, 4705, 4567, 9012',
    odometer: '17000',
    vin: '4HGCM82632A005248',
    plateNumber: '06B ACD',
    model: 'Bluebird',
    year: 2007,
    transporter: 'Autocar Lauzon',
    fleet: 'Flotte ABCD',
    module: 'T234-10993842',
    vehicleType: vehicleTypeEnum.bus,
    softwareVersion: 'V.0.7',
    lastConnection: '2021-10-10T22:54:00',
    maintenance: '2023-12-05T00:00:00',
    maintenanceHours: {
      start: '2023-12-05T22:00:00',
      end: '2023-12-05T23:30:00',
    },
    brand: 'Ford',
    homePort: 'Port d’attache A',
    timezone: 'UTC-05:00',
    country: 'CA',
    province: 'CA-QC',
    tagSerialNumber: 123456789,
    active: 'active',
    tagType: 'Module A',
  },
  {
    id: 7,
    update: 'Hier 15h40',
    name: 'Autobus 009',
    time: 'AM',
    seats: 50,
    distanceRemaining: '50',
    fuelType: 'Diesel',
    lastPosition: '987 R. Principale, Longueuil',
    circuits: '008, 4706, 5678, 1234',
    odometer: '17500',
    vin: '4HGCM82632A005249',
    plateNumber: '07B ACD',
    model: 'Bluebird',
    year: 2008,
    transporter: 'Autocar Jeannois',
    fleet: 'Flotte ABCD',
    module: 'T234-10993843',
    tagType: 'Module A',
    softwareVersion: 'V.0.8',
    lastConnection: '2021-10-10T23:00:00',
    maintenance: '2023-12-05T00:00:00',
    maintenanceHours: {
      start: '2023-12-05T22:00:00',
      end: '2023-12-05T23:30:00',
    },
    vehicleType: vehicleTypeEnum.bus,
    brand: 'Ford',
    homePort: 'Port d’attache A',
    timezone: 'UTC-05:00',
    country: 'CA',
    province: 'CA-QC',
    tagSerialNumber: 123456789,
    active: 'active',
  },
  {
    id: 8,
    update: "Aujourd'hui 08h45",
    name: 'Autobus 010',
    time: 'AM',
    seats: 50,
    distanceRemaining: '40',
    fuelType: 'Diesel',
    lastPosition: '654 R. Principale, Longueuil',
    circuits: '009, 4707, 9012, 3456',
    odometer: '18000',
    vin: '4HGCM82632A005250',
    plateNumber: '08B ACD',
    model: 'Bluebird',
    year: 2009,
    transporter: 'Autocar Lauzon',
    fleet: 'Flotte ABCD',
    module: 'T234-10993844',
    tagType: 'Module A',
    softwareVersion: 'V.0.9',
    lastConnection: '2021-10-10T23:06:00',
    maintenance: '2023-12-05T00:00:00',
    maintenanceHours: {
      start: '2023-12-05T22:00:00',
      end: '2023-12-05T23:30:00',
    },
    vehicleType: vehicleTypeEnum.bus,
    brand: 'Ford',
    homePort: 'Port d’attache A',
    timezone: 'UTC-05:00',
    country: 'CA',
    province: 'CA-QC',
    tagSerialNumber: 123456789,
    active: 'active',
  },
  {
    id: 9,
    update: 'Hier 17h30',
    name: 'Autobus 011',
    time: 'AM',
    seats: 50,
    distanceRemaining: '30',
    fuelType: 'Diesel',
    lastPosition: '321 R. Principale, Longueuil',
    circuits: '010, 4708, 2345, 6789',
    odometer: '18500',
    vin: '4HGCM82632A005251',
    plateNumber: '09B ACD',
    model: 'Bluebird',
    year: 2010,
    transporter: 'Autocar Lauzon',
    fleet: 'Flotte ABCD',
    module: 'T234-10993845',
    tagType: 'Module B',
    softwareVersion: 'V.1.0',
    lastConnection: '2021-10-10T23:12:00',
    maintenance: '2023-12-05T00:00:00',
    maintenanceHours: {
      start: '2023-12-05T22:00:00',
      end: '2023-12-05T23:30:00',
    },
    vehicleType: vehicleTypeEnum.bus,
    brand: 'Ford',
    homePort: 'Port d’attache A',
    timezone: 'UTC-05:00',
    country: 'CA',
    province: 'CA-QC',
    tagSerialNumber: 123456789,
    active: 'active',
  },
  {
    id: 10,
    update: "Aujourd'hui 06h50",
    name: 'Autobus 012',
    time: 'AM',
    seats: 50,
    distanceRemaining: '20',
    fuelType: 'Diesel',
    lastPosition: '987 R. Principale, Longueuil',
    circuits: '011, 4709, 3456, 7890',
    odometer: '19000',
    vin: '4HGCM82632A005252',
    plateNumber: '10B ACD',
    model: 'Bluebird',
    year: 2011,
    transporter: 'Autocar Lauzon',
    fleet: 'Flotte ABCD',
    module: 'T234-10993846',
    tagType: 'Module c',
    softwareVersion: 'V.1.1',
    lastConnection: '2021-10-10T23:18:00',
    maintenance: '2023-12-05T00:00:00',
    maintenanceHours: {
      start: '2023-12-05T22:00:00',
      end: '2023-12-05T23:30:00',
    },
    vehicleType: vehicleTypeEnum.bus,
    brand: 'Ford',
    homePort: "Port d'attache A",
    timezone: 'UTC-05:00',
    country: 'CA',
    province: 'CA-QC',
    tagSerialNumber: 123456789,
    active: 'active',
  },
];

export const tableDriverHeaders: tableHeaderInterface[] = [
  {
    label: 'Mise à jour',
    dataKey: 'update',
  },
  {
    label: 'Nom',
    dataKey: 'name',
  },
  {
    label: 'HOS',
    dataKey: 'hos',
  },
  {
    label: 'Téléphone',
    dataKey: 'phone',
  },
  {
    label: 'Courriel',
    dataKey: 'email',
  },
  {
    label: 'Circuits',
    dataKey: 'circuits',
  },
  {
    label: 'Préférence véhicule',
    dataKey: 'vehiclePref',
  },
  {
    label: 'Métier unique #',
    dataKey: 'employeeNo',
  },
  {
    label: 'Métier unique exp.',
    dataKey: 'employeeExp',
  },
  {
    label: 'Classe permis',
    dataKey: 'permitClass',
  },
  {
    label: 'Permis',
    dataKey: 'permit',
  },
  {
    label: 'Permis exp.',
    dataKey: 'permitExp',
  },
  {
    label: 'Transporteur',
    dataKey: 'transporter',
  },
];

export type userTableInterface = tablePropsInterface & {
  id: number | null | undefined;
  isDisabled: boolean;
  update: string;
  hos: string;
  phone: string;
  name: string;
  email: string;
  firstName: string;
  lastName: string;
  language?: string;
  circuits: string;
  vehiclePref: string;
  employeeNo: string;
  transporter: string;
  rolesPermissions: string[];
  isDriver?: boolean;
  licenseNumber?: string;
  licenseExpirationDate?: string;
  licenseIssuingCountry?: string;
  licenseIssuingSubDivision?: string;
  licenseClass?: string;
  singleJob?: string;
  expirationSingleJob?: string;
  homePort?: string;
  timezone?: string;
};

export const tableDriverData: userTableInterface[] = [
  {
    id: 1,
    isDisabled: false,
    update: 'Hier 12:32',
    name: 'Marc-André Cadieux',
    firstName: 'Marc-André',
    lastName: 'Cadieux',
    language: 'Français',
    isDriver: false,
    hos: '5h20',
    phone: '(111) 123-4567',
    email: 'm.cadieux@email.com',
    circuits: '002, 4700, 1234, 3456',
    vehiclePref: 'Autobus 003',
    employeeNo: 'JH00020003',
    expirationSingleJob: '10/01/2026',
    licenseClass: 'Classe 2',
    licenseNumber: 'L1531-171274-08',
    licenseExpirationDate: '10/01/2026',
    transporter: 'Autocar Deslisle',
    rolesPermissions: [],
  },
  {
    id: 2,
    isDisabled: false,
    update: "Aujourd'hui 09:15",
    name: 'Jean-Pierre Tremblay',
    firstName: 'Jean-Pierre',
    lastName: 'Tremblay',
    language: 'Français',
    isDriver: true,
    hos: '6h45',
    phone: '(222) 987-6543',
    email: 'jp.tremblay@email.com',
    circuits: '001, 1234, 5678',
    vehiclePref: 'Autobus 001',
    employeeNo: 'JH00020001',
    expirationSingleJob: '10/01/2025',
    licenseClass: 'Classe 1',
    licenseNumber: 'L1234-567890-12',
    licenseExpirationDate: '10/01/2025',
    transporter: 'Autocar Tremblay',
    rolesPermissions: [],
  },
  {
    id: 3,
    isDisabled: true,
    update: 'Hier 18:20',
    name: 'Sophie Lefebvre',
    firstName: 'Sophie',
    lastName: 'Lefebvre',
    language: 'Français',
    isDriver: true,
    hos: '7h30',
    phone: '(333) 555-7890',
    email: 's.lefebvre@email.com',
    circuits: '003, 6789, 9876',
    vehiclePref: 'Autobus 002',
    employeeNo: 'JH00020002',
    expirationSingleJob: '10/01/2024',
    licenseClass: 'Classe 3',
    licenseNumber: 'L9876-543210-34',
    licenseExpirationDate: '10/01/2024',
    transporter: 'Autocar Lefebvre',
    rolesPermissions: [],
  },
  {
    id: 4,
    isDisabled: false,
    update: "Aujourd'hui 14:45",
    name: 'David Gagnon',
    firstName: 'David',
    lastName: 'Gagnon',
    language: 'Français',
    isDriver: true,
    hos: '8h15',
    phone: '(444) 222-3333',
    email: 'd.gagnon@email.com',
    circuits: '004, 9876, 5432',
    vehiclePref: 'Autobus 003',
    employeeNo: 'JH00020003',
    expirationSingleJob: '10/01/2023',
    licenseClass: 'Classe 2',
    licenseNumber: 'L5432-109876-56',
    licenseExpirationDate: '10/01/2023',
    transporter: 'Autocar Gagnon',
    rolesPermissions: [],
  },
  {
    id: 5,
    isDisabled: false,
    update: 'Hier 09:30',
    name: 'Isabelle Martin',
    firstName: 'Isabelle',
    lastName: 'Martin',
    language: 'Français',
    isDriver: true,
    hos: '9h00',
    phone: '(555) 444-2222',
    email: 'i.martin@email.com',
    circuits: '005, 4321, 8765',
    vehiclePref: 'Autobus 004',
    employeeNo: 'JH00020004',
    expirationSingleJob: '10/01/2022',
    licenseClass: 'Classe 1',
    licenseNumber: 'L8765-432109-78',
    licenseExpirationDate: '10/01/2022',
    transporter: 'Autocar Martin',
    rolesPermissions: [],
  },
  {
    id: 6,
    isDisabled: true,
    update: "Aujourd'hui 16:00",
    name: 'Patrick Roy',
    firstName: 'Patrick',
    lastName: 'Roy',
    language: 'Français',
    isDriver: true,
    hos: '10h30',
    phone: '(666) 333-1111',
    email: 'p.roy@email.com',
    circuits: '006, 7654, 3210',
    vehiclePref: 'Autobus 005',
    employeeNo: 'JH00020005',
    expirationSingleJob: '10/01/2021',
    licenseClass: 'Classe 3',
    licenseNumber: 'L3210-987654-90',
    licenseExpirationDate: '10/01/2021',
    transporter: 'Autocar Roy',
    rolesPermissions: [],
  },
  {
    id: 7,
    isDisabled: false,
    update: 'Hier 11:45',
    name: 'Marie-Claude Lavoie',
    firstName: 'Marie-Claude',
    lastName: 'Lavoie',
    language: 'Français',
    isDriver: true,
    hos: '11h15',
    phone: '(777) 111-9999',
    email: 'mc.lavoie@email.com',
    circuits: '007, 2109, 8765',
    vehiclePref: 'Autobus 006',
    employeeNo: 'JH00020006',
    expirationSingleJob: '10/01/2020',
    licenseClass: 'Classe 2',
    licenseNumber: 'L8765-432109-78',
    licenseExpirationDate: '10/01/2020',
    transporter: 'Autocar Lavoie',
    rolesPermissions: [],
  },
  {
    id: 8,
    isDisabled: true,
    update: "Aujourd'hui 13:00",
    name: 'Éric Dubois',
    firstName: 'Éric',
    lastName: 'Dubois',
    language: 'Français',
    isDriver: true,
    hos: '12h45',
    phone: '(888) 999-7777',
    email: 'e.dubois@email.com',
    circuits: '008, 1098, 7654',
    vehiclePref: 'Autobus 007',
    employeeNo: 'JH00020007',
    expirationSingleJob: '10/01/2019',
    licenseClass: 'Classe 1',
    licenseNumber: 'L7654-321098-12',
    licenseExpirationDate: '10/01/2019',
    transporter: 'Autocar Dubois',
    rolesPermissions: [],
  },
  {
    id: 9,
    isDisabled: false,
    update: 'Hier 15:30',
    name: 'Nathalie Gagné',
    firstName: 'Nathalie',
    lastName: 'Gagné',
    language: 'Français',
    isDriver: true,
    hos: '13h30',
    phone: '(999) 777-5555',
    email: 'n.gagne@email.com',
    circuits: '009, 0987, 6543',
    vehiclePref: 'Autobus 008',
    employeeNo: 'JH00020008',
    expirationSingleJob: '10/01/2018',
    licenseClass: 'Classe 3',
    licenseNumber: 'L6543-210987-34',
    licenseExpirationDate: '10/01/2018',
    transporter: 'Autocar Gagné',
    rolesPermissions: [],
  },
  {
    id: 10,
    isDisabled: true,
    update: "Aujourd'hui 17:15",
    name: 'Sylvain Bélanger',
    firstName: 'Sylvain',
    lastName: 'Bélanger',
    language: 'Français',
    isDriver: true,
    hos: '14h45',
    phone: '(000) 888-6666',
    email: 's.belanger@email.com',
    circuits: '010, 8765, 4321',
    vehiclePref: 'Autobus 009',
    employeeNo: 'JH00020009',
    expirationSingleJob: '10/01/2017',
    licenseClass: 'Classe 2',
    licenseNumber: 'L4321-098765-56',
    licenseExpirationDate: '10/01/2017',
    transporter: 'Autocar Bélanger',
    rolesPermissions: [],
  },
];

export const tableReportsHeaders: tableHeaderInterface[] = [
  {
    label: 'Date',
    dataKey: 'update',
  },
  {
    label: 'Conducteur',
    dataKey: 'driver',
  },
  {
    label: 'Véhicule',
    dataKey: 'vehicle',
  },
  {
    label: 'Cycle',
    dataKey: 'cycle',
  },
  {
    label: 'Cycle (rest...)',
    dataKey: 'cycleRest',
  },
  {
    label: 'Zone',
    dataKey: 'zone',
  },
  {
    label: 'Repos',
    dataKey: 'repos',
  },
  {
    label: 'Couchette',
    dataKey: 'berth',
  },
  {
    label: 'En conduite',
    dataKey: 'driving',
  },
  {
    label: 'En service',
    dataKey: 'inService',
  },
  {
    label: 'Quart de tra...',
    dataKey: 'quarterOfWork',
  },
  {
    label: 'Certifié',
    dataKey: 'certified',
  },
  {
    label: 'Export',
    dataKey: 'export',
  },
  {
    label: 'Edit',
    dataKey: 'edit',
  },
];

export const tableReportsData = [
  {
    update: 'Hier 12:32',
    driver: 'Marc-André Cadieux',
    vehicle: 'Mazda 10',
    cycle: '1',
    cycleRest: '24h',
    zone: 'Longueuil Nord',
    repos: '8h',
    berth: '0h',
    driving: '6h',
    inService: '2h',
    quarterOfWork: '2h',
    certified: 'Oui',
  },
  {
    update: 'Hier 12:32',
    driver: 'Marc-André Apple',
    vehicle: 'Vw 10',
    cycle: '2',
    cycleRest: '24h',
    zone: 'Longueuil Sud',
    repos: '10h',
    berth: '0h',
    driving: '4h',
    inService: '2h',
    quarterOfWork: '2h',
    certified: 'Non',
  },
  {
    update: 'Hier 12:10',
    driver: 'Marc-Olivier Roy',
    vehicle: '',
    cycle: '1',
    cycleRest: '24h',
    zone: 'Longueuil Kappa',
    repos: '8h',
    berth: '0h',
    driving: '6h',
    inService: '2h',
    quarterOfWork: '2h',
    certified: 'Oui',
  },
  {
    update: 'Hier 13:32',
    driver: 'Léa Castonguay',
    vehicle: 'Fiat 10',
    cycle: '3',
    cycleRest: '22h',
    zone: 'Levis',
    repos: '8h',
    berth: '0h',
    driving: '3h',
    inService: '2h',
    quarterOfWork: '2h',
    certified: 'Non',
  },
  {
    update: 'Hier 14:32',
    driver: 'Jean-Philippe Roy',
    vehicle: 'Ferrari 2',
    cycle: '3',
    cycleRest: '22h',
    zone: 'Plessisville',
    repos: '9h',
    berth: '0h',
    driving: '3h',
    inService: '2h',
    quarterOfWork: '2h',
    certified: 'Oui',
  },
  {
    update: 'Hier 15:33',
    driver: 'Léa Castonguay',
    vehicle: 'Mercedes 19',
    cycle: '1',
    cycleRest: '20h',
    zone: 'Plaisance',
    repos: '',
    berth: '',
    driving: '10h',
    inService: '2h',
    quarterOfWork: '',
    certified: 'Non',
  },
];

export const horizonTableReportHeaders: tableHeaderInterface[] = [
  {
    label: 'Date de création',
    dataKey: 'creationDate',
  },
  {
    label: 'Nom du rapport',
    dataKey: 'name',
  },
  {
    label: 'Resource(s)',
    dataKey: 'resource',
  },
  {
    label: 'Date(s)',
    dataKey: 'date',
  },
  {
    label: 'Créé par',
    dataKey: 'createdBy',
  },
  {
    label: 'Statut',
    dataKey: 'status',
  },
  {
    label: 'Export',
    dataKey: 'export',
  },
];
