export enum FeatureGroup {
  SCOLAIRE = 'Scholar',
  HORIZON = 'Horizon',
}

export enum TenantsType {
  CLIENTSCOLAIRE = 'clientscolaire',
  CLIENTHORIZON = 'clienthorizon',
  VIDEOTRON = 'videotron',
  LOCALHOST = 'localhost',
}
