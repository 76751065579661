import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LaunchDarklyService } from '@common/ts-feature-flag';
import classNames from 'classnames';

import { PermissionsService } from '../services/permissions.service';

import { settingsRoutesEnum } from './settings-routing.module';

@Component({
  selector: 'astus-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  profilePageFlag$ =
    this.launchDarklyService.getFlagValue$('Reglages_MonProfil');
  languageAndFormatsPageFlag$ = this.launchDarklyService.getFlagValue$(
    'Reglages_LanguesEtFormats'
  );
  companyProfilePageFlag$ = this.launchDarklyService.getFlagValue$(
    'Reglages_ProfilEntreprise'
  );
  groupsPageFlag$ = this.launchDarklyService.getFlagValue$('Reglages_Groupes');
  notificationsPageFlag$ = this.launchDarklyService.getFlagValue$(
    'Reglages_Notifications'
  );
  rolesPermissionsPageFlag$ = this.launchDarklyService.getFlagValue$(
    'Reglages_RolesEtPermissions'
  );

  mobileHeaderLabel = '';

  routesEnum = settingsRoutesEnum;

  permissionProfilePage$ = this.permissionsService.getPermission$(
    'general.companyProfileAccess'
  );

  permissionAdministrationPage$ = this.permissionsService.getPermission$(
    'administration.rolesAndPermissionsAccess'
  );

  canAccessAdministrationPage$ =
    this.permissionsService.combineAndReturnBooleanObservable$(
      this.permissionAdministrationPage$,
      this.rolesPermissionsPageFlag$
    );

  canAccessProfilePage$ =
    this.permissionsService.combineAndReturnBooleanObservable$(
      this.permissionProfilePage$,
      this.companyProfilePageFlag$
    );

  // Language and formats
  languageAndFormatPage$ = this.permissionsService.getPermission$(
    'general.languageAndFormatsAccess'
  );
  canAccessLanguageAndFormatPage$ =
    this.permissionsService.combineAndReturnBooleanObservable$(
      this.languageAndFormatPage$,
      this.languageAndFormatsPageFlag$
    );

  // My profil page
  myProfilPage$ = this.permissionsService.getPermission$(
    'general.myProfilAccess'
  );
  canAccessMyProfilPage$ =
    this.permissionsService.combineAndReturnBooleanObservable$(
      this.myProfilPage$,
      this.profilePageFlag$
    );

  constructor(
    private launchDarklyService: LaunchDarklyService,
    private router: Router,
    private permissionsService: PermissionsService
  ) {}

  ngOnInit() {
    this.checkScreenSizeAndRedirect();
  }

  public get displaySetting() {
    return classNames({
      'is-open': this.router.url !== '/settings',
    });
  }

  checkScreenSizeAndRedirect() {
    if (window.innerWidth < 600) {
      this.router.navigate(['/settings', { outlets: { 'header-top': null } }]);
    } else {
      this.router.navigate([`/settings/${settingsRoutesEnum.COMPANY_PROFILE}`]);
    }
  }
}
