<div class="menu-assistance-page-container">
  <div class="menu-assistance-page-main">
    <div class="menu-assistance-page-option">
      <h3 class="text-p2">
        {{ 'ASSISTANCE.CONTACT' | translate }}
      </h3>
      <span>
        <ad-icon glyph="email" color="primary-200"></ad-icon>
        <a href="mailto:support@astus.com" class="text-p3--underline"
          >support@astus.com</a
        >
      </span>

      <span>
        <ad-icon glyph="phone" color="primary-200"></ad-icon>
        <a href="tel:18667027887" class="text-p3--underline"
          >1 (866) 702-7887</a
        >
      </span>
    </div>

    <div class="menu-assistance-page-option">
      <h3 class="text-p2">
        {{ 'ASSISTANCE.ADDRESS' | translate }}
      </h3>
      <p class="text-p3">
        101 Boulevard Roland-Therrien (suite 500)<br />
        Longueuil (Québec)<br />
        J4H 4B9, Canada
      </p>
    </div>

    <div class="menu-assistance-page-option">
      <h3 class="text-p2">
        {{ 'ASSISTANCE.TRAININGS' | translate }}
      </h3>
      <span>
        <ad-icon glyph="play_realtime" color="primary-200"></ad-icon>
        <a
          href="https://www.astus.tv"
          target="_blank"
          class="text-p3--underline"
          >Astus.tv</a
        >
      </span>
    </div>
  </div>
</div>
