import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { compareDates, decrementDate, incrementDate } from '../../helpers';
import { DateFiltersType } from '../calendar/calendar';

@Component({
  selector: 'ad-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerOrganism implements OnInit {
  constructor(private elementRef: ElementRef) {}

  today = new Date();

  @Input()
  date: Date | Date[] = new Date();

  @Input()
  currentLanguage = '';

  @Input()
  dateRangeFilters: DateFiltersType[] = [];

  @Output()
  dateSelected = new EventEmitter<Date | Date[]>();

  singleDate!: Date;
  dateRange!: Date[];

  isDateRange = Array.isArray(this.date);

  isToday = Array.isArray(this.date)
    ? false
    : compareDates(this.date, this.today);

  showCalendar = false;
  hideCalendar = true;

  ngOnInit(): void {
    // evaluate if date is a date range or a single date
    if (Array.isArray(this.date)) {
      this.dateRange = this.date;
      this.isDateRange = true;
    } else {
      this.singleDate = this.date;
      this.isDateRange = false;
    }
  }

  decrementDate() {
    if (Array.isArray(this.date)) {
      this.date = [decrementDate(this.date[0]), decrementDate(this.date[1])];
      this.dateRange = this.date;
    } else {
      this.date = decrementDate(this.date);
      this.singleDate = this.date;
      this.isToday = compareDates(this.date, this.today);
    }
    this.dateSelected.emit(this.date);
  }

  incrementDate() {
    if (Array.isArray(this.date)) {
      this.date = [incrementDate(this.date[0]), incrementDate(this.date[1])];
      this.dateRange = this.date;
    } else {
      this.date = incrementDate(this.date);
      this.singleDate = this.date;
      this.isToday = compareDates(this.date, this.today);
    }
    this.dateSelected.emit(this.date);
  }

  toggleCalendar() {
    this.showCalendar = !this.showCalendar;
  }

  handleDateSelectionMode(mode: 'single' | 'range') {
    if (mode === 'single') {
      this.isDateRange = false;
      this.date = this.singleDate;
    } else {
      this.isDateRange = true;
      this.dateRange = [];
      this.date = this.dateRange;
    }
  }

  handleDateSelect(date: Date | Date[]) {
    if (Array.isArray(date)) {
      this.isDateRange = true;
      this.date = date;
      this.dateRange = this.date;
      if (this.dateRange.length === 2) this.dateSelected.emit(this.dateRange);
    } else {
      this.isDateRange = false;
      this.date = date;
      this.singleDate = this.date;
      this.dateSelected.emit(this.singleDate);
      this.isToday = compareDates(this.date, this.today);
    }
  }

  handleHideCalendar(event: boolean) {
    this.hideCalendar = event;
  }

  @HostListener('document:click', ['$event'])
  onClickOutsideComponent(event: MouseEvent) {
    // setTimeout is needed to ensure that the click event is not triggered before the calendar event is received
    setTimeout(() => {
      const clickedOutsideComponent = !this.elementRef.nativeElement.contains(
        event.target
      );
      if (clickedOutsideComponent && this.hideCalendar) {
        this.showCalendar = false;
      }
    });
  }
}
