import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MarkdownModule } from 'ngx-markdown';

import { LicenseAgreementComponent } from './license-agreement/license-agreement.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsRoutingModule } from './terms-conditions-routing.module';
import { WarrantyComponent } from './warranty/warranty.component';

@NgModule({
  declarations: [
    LicenseAgreementComponent,
    PrivacyPolicyComponent,
    WarrantyComponent,
  ],
  imports: [
    CommonModule,
    TermsConditionsRoutingModule,
    MarkdownModule.forRoot(),
  ],
})
export class TermsConditionsModule {}
