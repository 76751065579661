import { animate, style, transition, trigger } from '@angular/animations';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import { IconAtomType } from '../../atoms';

@Component({
  selector: 'ad-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('dropdownAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-20px)' }),
        animate(
          '0.3s ease-out',
          style({ opacity: 1, transform: 'translateY(0)' })
        ),
      ]),
      transition(':leave', [
        animate(
          '0.3s ease-in',
          style({ opacity: 0, transform: 'translateY(-20px)' })
        ),
      ]),
    ]),
  ],
})
export class DialogOrganism {
  open = false;

  @Input() resetLabel = '';
  @Input() applyLabel = '';
  @Input() buttonResetDisabled = true;
  @Input() buttonApplyDisabled = true;
  @Input() icon: IconAtomType = 'eye_off';
  @Input() labelContent = '';
  @Input() headerTitle = '';

  @Output() applyFilters = new EventEmitter();
  @Output() resetFilters = new EventEmitter();
  @Output() undoChanges = new EventEmitter();

  constructor(private elementRef: ElementRef) {}

  openOrCloseDialog() {
    this.open = !this.open;
  }

  /**
   * @description - This method is used to handle the outside click of the dialog
   */
  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    if (
      !(this.elementRef.nativeElement as HTMLElement).contains(
        event.target as Node
      ) ||
      (!this.open &&
        this.elementRef.nativeElement
          .getElementsByClassName('filter-fab-container')[0]
          .contains(event.target as Node))
    ) {
      this.open = false;
      this.undoChanges.emit();
    }
  }

  onApply() {
    this.applyFilters.emit();
    this.open = false;
  }

  onReset() {
    this.resetFilters.emit();
    this.open = false;
  }
}
