import { InjectionToken } from '@angular/core';

export const CONFIGHERE = new InjectionToken<IHEREConfig>('here-config');

export interface IHEREConfig {
  apiKey: string;
  mapStyle: string;
  vectorStyle: string;
}

export interface IHERECoordinates {
  lat: number;
  lng: number;
}

export interface IHEREMapData {
  origin?: IHERECoordinates;
  destination?: IHERECoordinates;
  intermediates?: IHERECoordinates[];
  areas?: IHERECoordinates[][];
}

export type IHERERoutingModes = 'fast' | 'short';
export type IHERETransportModes =
  | 'car'
  | 'pedestrian'
  | 'bus'
  | 'taxi'
  | 'vehicle'
  | 'bicycle';

export interface IHERERouteResponse {
  notices: IHERERouteNotice[];
  routes: IHERERouteRoute[];
}

export interface IHERERouteNotice {
  title: string;
  code: string;
  severity: string;
}

export interface IHERERouteRoute {
  id: string;
  sections: Section[];
}

export interface Section {
  id: string;
  type: string;
  departure: IHERERouteArrival;
  arrival: IHERERouteArrival;
  polyline: string;
  transport: IHERERouteTransport;
}

export interface IHERERouteArrival {
  place: IHERERoutePlace;
}

export interface IHERERoutePlace {
  type: Type;
  location: IHERECoordinates;
  originalLocation: IHERECoordinates;
  waypoint?: number;
}

export enum Type {
  Place = 'place',
}

export interface IHERERouteTransport {
  mode: string;
}

export interface IHERERouteSection {
  id: string;
  type: string;
  departure: IHERERouteArrival;
  arrival: IHERERouteArrival;
  polyline: string;
  transport: IHERERouteTransport;
}
