<ng-container *ngIf="id !== undefined; else divContent">
  <a [routerLink]="id.toString()" class="card">
    <ng-container *ngTemplateOutlet="innerContent"></ng-container>
  </a>
</ng-container>

<ng-template #divContent>
  <div class="card">
    <ng-container *ngTemplateOutlet="innerContent"></ng-container>
  </div>
</ng-template>

<ng-template #innerContent>
  <div class="icon">
    <ad-icon glyph="users"></ad-icon>
  </div>
  <div class="content">
    <div class="title text-p3--semibold">{{ title }}</div>
    <div class="description text-caption">{{ description }}</div>
  </div>
  <div class="info">
    <astus-settings-users-list
      [assocUsers]="assocUsers"
    ></astus-settings-users-list>
  </div>
</ng-template>
