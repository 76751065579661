import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { HEREHelper } from './here.helper';
import { CONFIGHERE, IHEREConfig } from './here.interface';
import { HEREService } from './here.service';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [],
  exports: [],
})
export class HEREModule {
  static forRoot(config: IHEREConfig): ModuleWithProviders<HEREModule> {
    return {
      ngModule: HEREModule,
      providers: [
        { provide: CONFIGHERE, useValue: config },
        HEREService,
        HEREHelper,
      ],
    };
  }
}
