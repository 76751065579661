import { InjectionToken } from '@angular/core';

export const CONFIGGoogleMaps = new InjectionToken<IGoogleMapsConfig>(
  'google-maps-config'
);

export interface IGoogleMapsConfig {
  apiKey: string;
}

export interface IGoogleMapsArea {
  styles: IGoogleMapsAreaStyles;
  paths: IGoogleMapsCoordinates[];
  innerPaths?: IGoogleMapsCoordinates[][];
}

export interface IGoogleMapsAreaStyles {
  strokeColor?: string;
  strokeOpacity?: number;
  strokeWeight?: number;
  fillColor?: string;
  fillOpacity?: number;
}

export interface IGoogleMapsCoordinates {
  lat: number;
  lng: number;
}

export interface IGoogleMapsLocation {
  via?: boolean;
  sideOfRoad?: boolean;
  vehicleStopover?: boolean;
  location?: {
    latLng: { latitude: number; longitude: number };
  };
  placeId?: string;
  address?: string;
}

export interface IGoogleMapsMapData {
  origin?: IGoogleMapsCoordinates;
  destination?: IGoogleMapsCoordinates;
  areas?: IGoogleMapsArea[];
  intersections?: IGoogleMapsCoordinates[];
}

export interface IGoogleMapsStyle {
  featureType: string;
  elementType: string;
  stylers: IGoogleMapsStyler[];
}

export interface IGoogleMapsStyler {
  weight?: string;
  color?: string;
  visibility?: string;
  saturation?: number;
  lightness?: number;
}

// For the Direction API Response
export interface GoogleMapsDirectionsResponse {
  routes: GoogleMapsRoute[];
  status: string;
}

interface GoogleMapsRoute {
  summary: string;
  legs: GoogleMapsRouteLeg[];
  distanceMeters: number;
  duration: string;
  polyline: {
    encodedPolyline: string;
    points: string;
  };
}

interface GoogleMapsRouteLeg {
  distance: GoogleMapsRouteValue;
  duration: GoogleMapsRouteValue;
  start_location: GoogleMapsLatLng;
  end_location: GoogleMapsLatLng;
  steps: GoogleMapsRouteStep[];
}

interface GoogleMapsRouteValue {
  text: string;
  value: number;
}

interface GoogleMapsLatLng {
  lat: number;
  lng: number;
}

interface GoogleMapsRouteStep {
  distance: GoogleMapsRouteValue;
  duration: GoogleMapsRouteValue;
  start_location: GoogleMapsLatLng;
  end_location: GoogleMapsLatLng;
  instructions: string;
  maneuver: string;
}

export interface IGoogleMapsComputeRoutesRequestBody {
  origin: string | IGoogleMapsLocation;
  destination: string | IGoogleMapsLocation;
  intermediates?: IGoogleMapsLocation[];
  travelMode?:
    | 'TRAVEL_MODE_UNSPECIFIED'
    | 'DRIVE'
    | 'BICYCE'
    | 'WALK'
    | 'TWO_WHEELER';
  routingPreference?:
    | 'TRAFFIC_UNAWARE'
    | 'TRAFFIC_AWARE'
    | 'TRAFFIC_AWARE_OPTIMAL';
  polylineQuality?:
    | 'POLYLINE_QUALITY_UNSPECIFIED'
    | 'HIGH_QUALITY'
    | 'OVERVIEW';
  polylineEncoding?:
    | 'POLYLINE_ENCODING_UNSPECIFIED'
    | 'ENCODED_POLYLINE'
    | 'GEO_JSON_LINESTRING';
  departureTime?: string;
  computeAlternativeRoutes?: boolean;
  routeModifiers?: {
    avoidTolls?: boolean;
    avoidHighways?: boolean;
    avoidFerries?: boolean;
    avoidIndoor?: boolean;
    vehicleInfo?: {
      emissionType?:
        | 'VEHICLE_EMISSION_TYPE_UNSPECIFIED'
        | 'GASOLINE'
        | 'ELECTRIC'
        | 'HYBRID'
        | 'DIESEL';
    };
    tollPasses?: IGoogleMapsToolPass[];
  };
  languageCode?: string;
  regionCode?: string;
  units?: 'UNITS_UNSPECIFIED' | 'METRIC' | 'IMPERIAL';
  requestedReferenceRoutes?: (
    | 'REFERENCE_ROUTE_UNSPECIFIED'
    | 'FUEL_EFFICIENT'
  )[];
  extraComputations?: (
    | 'EXTRA_COMPUTATION_UNSPECIFIED'
    | 'TOLLS'
    | 'FUEL_CONSUMPTION'
    | 'TRAFFIC_ON_POLYLINE'
  )[];
}

type IGoogleMapsToolPass =
  | 'TOLL_PASS_UNSPECIFIED'
  | 'AU_ETOLL_TAG'
  | 'AU_EWAY_TAG'
  | 'AU_LINKT'
  | 'AR_TELEPASE'
  | 'BR_AUTO_EXPRESO'
  | 'BR_CONECTCAR'
  | 'BR_MOVE_MAIS'
  | 'BR_PASSA_RAPIDO'
  | 'BR_SEM_PARAR'
  | 'BR_TAGGY'
  | 'BR_VELOE'
  | 'CA_US_AKWASASNE_SEAWAY_CORPORATE_CARD'
  | 'CA_US_AKWASASNE_SEAWAY_TRANSIT_CARD'
  | 'CA_US_BLUE_WATER_EDGE_PASS'
  | 'CA_US_CONNEXION'
  | 'CA_US_NEXUS_CARD'
  | 'ID_E_TOLL'
  | 'IN_FASTAG'
  | 'IN_LOCAL_HP_PLATE_EXEMPT'
  | 'MX_IAVE'
  | 'MX_PASE'
  | 'MX_QUICKPASS'
  | 'MX_SISTEMA_TELEPEAJE_CHIHUAHUA'
  | 'MX_TAG_IAVE'
  | 'MX_TAG_TELEVIA'
  | 'MX_TELEVIA'
  | 'MX_VIAPASS'
  | 'US_AL_FREEDOM_PASS'
  | 'US_AK_ANTON_ANDERSON_TUNNEL_BOOK_OF_10_TICKETS'
  | 'US_CA_FASTRAK'
  | 'US_CA_FASTRAK_CAV_STICKER'
  | 'US_CO_EXPRESSTOLL'
  | 'US_CO_GO_PASS'
  | 'US_DE_EZPASSDE'
  | 'US_FL_BOB_SIKES_TOLL_BRIDGE_PASS'
  | 'US_FL_DUNES_COMMUNITY_DEVELOPMENT_DISTRICT_EXPRESSCARD'
  | 'US_FL_EPASS'
  | 'US_FL_GIBA_TOLL_PASS'
  | 'US_FL_LEEWAY'
  | 'US_FL_SUNPASS'
  | 'US_FL_SUNPASS_PRO'
  | 'US_IL_EZPASSIL'
  | 'US_IL_IPASS'
  | 'US_IN_EZPASSIN'
  | 'US_KS_BESTPASS_HORIZON'
  | 'US_KS_KTAG'
  | 'US_KS_NATIONALPASS'
  | 'US_KS_PREPASS_ELITEPASS'
  | 'US_KY_RIVERLINK'
  | 'US_LA_GEAUXPASS'
  | 'US_LA_TOLL_TAG'
  | 'US_MA_EZPASSMA'
  | 'US_MD_EZPASSMD'
  | 'US_ME_EZPASSME'
  | 'US_MI_AMBASSADOR_BRIDGE_PREMIER_COMMUTER_CARD'
  | 'US_MI_GROSSE_ILE_TOLL_BRIDGE_PASS_TAG'
  | 'US_MI_IQ_PROX_CARD'
  | 'US_MI_MACKINAC_BRIDGE_MAC_PASS'
  | 'US_MI_NEXPRESS_TOLL'
  | 'US_MN_EZPASSMN'
  | 'US_NC_EZPASSNC'
  | 'US_NC_PEACH_PASS'
  | 'US_NC_QUICK_PASS'
  | 'US_NH_EZPASSNH'
  | 'US_NJ_DOWNBEACH_EXPRESS_PASS'
  | 'US_NJ_EZPASSNJ'
  | 'US_NY_EXPRESSPASS'
  | 'US_NY_EZPASSNY'
  | 'US_OH_EZPASSOH'
  | 'US_PA_EZPASSPA'
  | 'US_RI_EZPASSRI'
  | 'US_SC_PALPASS'
  | 'US_TX_BANCPASS'
  | 'US_TX_DEL_RIO_PASS'
  | 'US_TX_EFAST_PASS'
  | 'US_TX_EAGLE_PASS_EXPRESS_CARD'
  | 'US_TX_EPTOLL'
  | 'US_TX_EZ_CROSS'
  | 'US_TX_EZTAG'
  | 'US_TX_LAREDO_TRADE_TAG'
  | 'US_TX_PLUSPASS'
  | 'US_TX_TOLLTAG'
  | 'US_TX_TXTAG'
  | 'US_TX_XPRESS_CARD'
  | 'US_UT_ADAMS_AVE_PARKWAY_EXPRESSCARD'
  | 'US_VA_EZPASSVA'
  | 'US_WA_BREEZEBY'
  | 'US_WA_GOOD_TO_GO'
  | 'US_WV_EZPASSWV'
  | 'US_WV_MEMORIAL_BRIDGE_TICKETS'
  | 'US_WV_NEWELL_TOLL_BRIDGE_TICKET';
