import { Injectable } from '@angular/core';
import { VehiclesService } from '@fms/ng-fms-api-client';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import {
  catchError,
  exhaustMap,
  filter,
  interval,
  map,
  of,
  switchMap,
  takeUntil,
} from 'rxjs';

import { getCurrentHours } from '../helpers/hour.helper';

import { dateActions, reportsActions } from './global.action';
import { getTimeLineStateFeature } from './global.selector';

@Injectable()
export class GlobalEffects {
  readonly getVehicles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reportsActions.getVehiclesLoad),
      exhaustMap(() =>
        this.vehiclesService.vehiclesControllerFindAll().pipe(
          map((vehicles) => ({
            type: reportsActions.getVehiclesLoaded.type,
            vehicles: vehicles,
          })),
          catchError((error) =>
            of({
              type: reportsActions.getVehiclesError.type,
              error: {
                message: 'Error loading vehicles',
                error,
              },
            })
          )
        )
      )
    )
  );

  /**
   * This effect is used to update the supervision timeline when this is in live mode.
   * It will update the first picker with the current hour.
   */
  readonly getLiveHoursFromCurrentTime$ = createEffect(() =>
    this.store.pipe(
      select(getTimeLineStateFeature),
      switchMap(() =>
        interval().pipe(
          map(() => {
            return dateActions.setHoursTimelineFirstPicker({
              firstHourSelection: getCurrentHours(),
            });
          }),
          takeUntil(
            this.store.pipe(
              select(getTimeLineStateFeature),
              filter((state) => !state.timeLineStatus) // Stop the interval when timeLineStatus becomes false
            )
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private vehiclesService: VehiclesService,
    private store: Store
  ) {}
}
