<img src="{{ backgroundImage }}" />
<h3 class="text-h3--semibold">
  {{ 'TEXT.TITLE' | translate }}
</h3>
<p class="text-p1">
  {{ 'TEXT.DESCRIPTION' | translate }}
</p>
<p class="text-p1">
  {{ 'TEXT.END_DESCRIPTION' | translate }}
</p>
<button
  class="mt-6"
  astus-design-button
  kind="filled"
  color="primary"
  size="md"
  (click)="goBack()"
>
  {{ 'CTA.GO_BACK' | translate }}
</button>
