import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { phoneFormValidator } from '@common/ng-design-system';
import { assert } from '@common/utils';
import { RoleView, RolesService } from '@fms/ng-fms-api-client';
import { Store } from '@ngrx/store';

import { Subscription, filter } from 'rxjs';

import { PermissionsService } from '../../services/permissions.service';
import { buttonProcessStateEnum } from '../settings.enum';
import { settingsProfileInterface } from '../store/settings';
import { settingsAPIActions } from '../store/settings.action';
import { selectProfileFeature } from '../store/settings.selector';

export type profileFormDropdowns = {
  roles: RoleView[];
};

@Component({
  selector: 'astus-settings-profile',
  templateUrl: './settings-profile.component.html',
  styleUrls: ['./settings-profile.component.scss'],
})
export class SettingsProfileComponent implements OnInit, OnDestroy {
  processState: buttonProcessStateEnum = buttonProcessStateEnum.READY;
  initialSettingsValues!: settingsProfileInterface;

  formGroup = this.fb.group({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [Validators.required, phoneFormValidator]),
    roles: new FormControl<RoleView[]>([], [Validators.required]),
  });

  formChanged = false;
  openCancelConfirmationModal = false;
  isEditRoleAllowed = false;

  profileFormStatusChangeSubscription: Subscription = new Subscription();
  profileFormValueChangeSubscription: Subscription = new Subscription();
  storeSubscription: Subscription = new Subscription();
  profileRolesSubscription = new Subscription();

  profileDropDowns: profileFormDropdowns = {
    roles: [],
  };

  canEditMyProfilPage = false;
  permissionEditMyProfilPage: Subscription = new Subscription();

  private resourceServiceSubscription = new Subscription();
  constructor(
    private fb: FormBuilder,
    private store: Store,
    private rolesService: RolesService,
    private permissionsService: PermissionsService
  ) {
    this.permissionEditMyProfilPage = this.permissionsService
      .getPermission$('general.myProfilEdit')
      .subscribe((value: boolean) => {
        this.canEditMyProfilPage = value;
      });

    this.formGroup = this.fb.group({
      firstName: this.createFormControl(false, [Validators.required]),
      lastName: this.createFormControl(false, [Validators.required]),
      email: this.createFormControl(false, [
        Validators.required,
        Validators.email,
      ]),
      phone: this.createFormControl(false, [
        Validators.required,
        phoneFormValidator,
      ]),
      roles: this.createFormControl(false, [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.storeSubscription = this.store
      .select(selectProfileFeature)
      .subscribe((profile) => {
        if (profile) {
          this.initialSettingsValues = profile;
          this.isEditRoleAllowed = this.isEditProfileRolesAllowed();
          this.initFormValues(profile);
          this.onValuesChange();
          this.getRoles();
        }
      });
  }

  private createFormControl(
    disabled: boolean,
    validators: ValidatorFn | ValidatorFn[] | null
  ): FormControl {
    const isDisabled = this.canEditMyProfilPage ? disabled : true;
    return new FormControl({ value: '', disabled: isDisabled }, validators);
  }

  getRoles() {
    this.profileRolesSubscription = this.rolesService
      .rolesControllerGetRoles()
      .subscribe((roles) => {
        this.profileDropDowns.roles = roles;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeForm();
    this.storeSubscription.unsubscribe();
    this.resourceServiceSubscription.unsubscribe();
    this.profileRolesSubscription.unsubscribe();
    this.permissionEditMyProfilPage.unsubscribe();
  }

  /**
   * Initializes the form values with the provided profile settings.
   * @param profileSettings - The profile settings to initialize the form with.
   */
  private initFormValues(profileSettings: settingsProfileInterface): void {
    this.formGroup.setValue({ ...profileSettings });
  }

  isEditProfileRolesAllowed() {
    const permissions = this.initialSettingsValues.roles;

    for (const permission of permissions) {
      if (
        permission.generalPermissions &&
        permission.generalPermissions['EDIT_FIELDS']
      ) {
        return true;
      }
    }
    return false;
  }
  /**
   * Subscribes to value changes in the form group and updates the formChanged property and processState accordingly.
   */
  private onValuesChange(): void {
    this.profileFormValueChangeSubscription = this.formGroup.valueChanges
      .pipe(
        filter(
          () =>
            this.processState === buttonProcessStateEnum.READY ||
            this.processState === buttonProcessStateEnum.SUCCESS
        )
      )
      .subscribe((change) => {
        const { firstName, lastName, email, phone, roles } = change;
        const {
          firstName: mockFirstName,
          lastName: mockLastName,
          email: mockEmail,
          phone: mockPhone,
          roles: mockRoles,
        } = this.initialSettingsValues;

        const formChanged =
          firstName !== mockFirstName ||
          lastName !== mockLastName ||
          email !== mockEmail ||
          phone !== mockPhone ||
          !this.isSameRoles(roles, mockRoles);

        this.formChanged = formChanged;

        if (formChanged) {
          this.processState = buttonProcessStateEnum.READY;
        }
      });
  }

  isSameRoles(
    currentRoles: RoleView[] | null | undefined,
    initialRoles: RoleView[]
  ): boolean {
    if (!currentRoles) {
      return false;
    }

    return (
      currentRoles.length === initialRoles.length &&
      currentRoles.every((role) => {
        return initialRoles.some((initialRole) => initialRole.id === role.id);
      })
    );
  }

  /**
   * Unsubscribes from form status and value change subscriptions.
   */
  unsubscribeForm(): void {
    this.profileFormStatusChangeSubscription.unsubscribe();
    this.profileFormValueChangeSubscription.unsubscribe();
  }

  /**
   * Closes the cancel confirmation modal.
   */
  closeCancelModal() {
    this.openCancelConfirmationModal = false;
  }

  /**
   * Opens the confirmation modal for canceling the profile settings.
   */
  openConfirmationModal() {
    this.openCancelConfirmationModal = true;
  }

  /**
   * Resets the form to its initial state.
   * - Closes the cancel modal.
   * - Resets the form group values to the initial settings values.
   * - Marks the form group as pristine.
   * - Sets the formChanged flag to false.
   */
  resetForm() {
    this.closeCancelModal();
    this.formGroup.reset({ ...this.initialSettingsValues });
    this.formGroup.markAsPristine();
    this.formChanged = false;
  }

  /**
   * Saves the form data and triggers an API call to edit the user's profile.
   */
  saveForm() {
    this.processState = buttonProcessStateEnum.PROCESSING;
    this.formChanged = false;
    this.formGroup.disable();

    // TODO: temporary setTimeout...
    // simulate call api for edit profile
    setTimeout(() => {
      this.processState = buttonProcessStateEnum.SUCCESS;
      const { firstName, lastName, email, phone, roles } = this.formGroup.value;
      assert(
        !!(firstName && lastName && email && phone && roles),
        'firstName, lastName, email, roles, and phone are required'
      );
      this.unsubscribeForm();
      this.store.dispatch(
        settingsAPIActions.profileEdit({
          profile: { firstName, lastName, email, phone, roles },
        })
      );
      this.resetForm();
      this.formGroup.enable();
    }, 3000);
  }
}
