<div class="picker">
  <button
    type="button"
    class="picker__day text-caption--semibold"
    *ngFor="let day of formattedDays; let i = index; trackBy: trackByFn"
    (click)="toggleDay(i)"
    [ngClass]="{
      'picker__day--selected': selectedDays.includes(i)
    }"
  >
    {{ day }}
  </button>
</div>
