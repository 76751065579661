import { HttpClient } from '@angular/common/http';

import { Inject, Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { CONFIGHERE, IHEREConfig } from './here.interface';

import { IHEREMapData } from './here.interface';

@Injectable({
  providedIn: 'root',
})
export class HEREService {
  constructor(
    private http: HttpClient,
    @Inject(CONFIGHERE) private config: IHEREConfig
  ) {}

  getMapData(): Observable<IHEREMapData> {
    return of({
      origin: {
        lat: 45.503182,
        lng: -73.569807,
      },
      destination: {
        lat: 45.42962259098798,
        lng: -73.66915802592594,
      },
      intermediates: [
        { lat: 45.4618470644374, lng: -73.56726105156538 },
        { lat: 45.44572185190076, lng: -73.57749120361632 },
        { lat: 45.4299066610985, lng: -73.6078963567557 },
        { lat: 45.42415718418892, lng: -73.64576014665174 },
      ],
      areas: [
        [
          { lat: 45.430510418490925, lng: -73.6650134027426 },
          { lat: 45.44587675825727, lng: -73.63046402393864 },
          { lat: 45.45494189005275, lng: -73.6187662027688 },
          { lat: 45.463051562448044, lng: -73.59700281454583 },
          { lat: 45.46906150819091, lng: -73.58380876043566 },
          { lat: 45.466295104818315, lng: -73.57959210396746 },
          { lat: 45.466676685769144, lng: -73.56272547809466 },
          { lat: 45.45675474157427, lng: -73.56313354162383 },
          { lat: 45.4248782276023, lng: -73.59700281454583 },
          { lat: 45.4147579643764, lng: -73.62121458394388 },
          { lat: 45.41590374560571, lng: -73.64161776040291 },
          { lat: 45.4246872960294, lng: -73.65930051333407 },
          { lat: 45.430510418490925, lng: -73.6650134027426 },
        ],
      ],
    });
  }
}
