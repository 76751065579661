import { HoursFormatPipe } from '../pipes/hours-format/hours-format.pipe';

const DAYS_MONTHS_BREAKPOINT = 30;
/**
 * @param date
 * @returns { value: number; unit: string}
 * @description Calculate the remaining time for the report to expire, if it's less than a month, it will return the remaining days, otherwise, it will return the remaining months
 */
export function calculateRemainingTime(date: string): {
  value: number;
  unit: string;
} {
  const currentDate = new Date();
  const sixMonthsForward = new Date(date);
  sixMonthsForward.setMonth(sixMonthsForward.getMonth() + 6);
  const expirationRemainingInTime =
    sixMonthsForward.getTime() - currentDate.getTime();
  // remainig date is the difference between the current date and the created date in months if less than a month in days
  const expirationRemainingInDays = Math.floor(
    expirationRemainingInTime / (1000 * 60 * 60 * 24)
  );

  if (expirationRemainingInDays < DAYS_MONTHS_BREAKPOINT) {
    // Return the difference in days if it's less than 30
    return { value: Math.floor(expirationRemainingInDays), unit: 'days' };
  } else {
    // Otherwise, return the difference in months
    const yearsDifference =
      sixMonthsForward.getFullYear() - currentDate.getFullYear();
    const monthsDifference =
      sixMonthsForward.getMonth() - currentDate.getMonth();

    const differenceInMonths = yearsDifference * 12 + monthsDifference;
    return { value: differenceInMonths, unit: 'months' };
  }
}

/**
 *
 * @param startDate start date
 * @param endDate end date
 * @returns true if the two dates are the same day
 */
export function isSameDay(startDate: Date, endDate: Date): boolean {
  return (
    startDate.getFullYear() === endDate.getFullYear() &&
    startDate.getMonth() === endDate.getMonth() &&
    startDate.getDate() === endDate.getDate()
  );
}

export function formatTime(
  date: Date,
  hoursFormatPipe: HoursFormatPipe
): string {
  const minutes =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

  return hoursFormatPipe.transform(`${date.getHours()}:${minutes}`);
}
