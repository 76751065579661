import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';

import { statusColorEnum } from '@common/ng-design-system';

import mapboxgl from 'mapbox-gl';
import { Subscription } from 'rxjs';

import { DistancePipe } from '../../pipes/distance/distance.pipe';
import {
  controlPanelCircuitsDataInterface,
  controlPanelCardDataVehiclesInterface,
  controlPanelCardUsersInterface,
  controlPanelCardAllDataType,
} from '../map-control-panel/map-control-panel.component.d';

// Reusable ids for the map elements
const POPOVER_CIRCUIT_BASE_STRING = 'circuit-popover-';
const MARKER_START_PIN_BASE_STRING = 'start-pin-marker-';
const VEHICLE_MARKER_POPOVER_STRING = 'vehicle-popover-';

@Component({
  selector: 'astus-map-list',
  templateUrl: './map-list.component.html',
  styleUrls: ['./map-list.component.scss'],
})
export class MapListComponent implements OnDestroy, OnChanges {
  translateSubscription: Subscription = new Subscription();
  storeTranslationsSubscription: Subscription = new Subscription();
  moreOptions$ = new Subscription();

  @Input()
  circuits?: controlPanelCircuitsDataInterface;

  @Input()
  vehicles?: controlPanelCardDataVehiclesInterface[];

  @Input()
  vehiclesOnMap: string[] = [];

  vehiclesFilteredOnMap?: controlPanelCardDataVehiclesInterface[] = [];
  vehiclesFilteredOutsideMap?: controlPanelCardDataVehiclesInterface[] =
    this.vehicles;

  @Input()
  users?: controlPanelCardUsersInterface[];
  usersSuggested?: controlPanelCardUsersInterface[] = this.users;

  @Input()
  cardSelected: controlPanelCardAllDataType | undefined;

  @Input()
  isLoading = true;

  @Input()
  hideAssignButton = false;

  cardTextTranslations = {};

  @Input() mapboxMap: mapboxgl.Map | null = null;

  @Output() cardClick = new EventEmitter();
  @Output() cardAssignClick = new EventEmitter();
  @Output() cardSelectedChange =
    new EventEmitter<controlPanelCardAllDataType>();

  circuitsIsNonAssignedListShown = true;
  circuitsIsAssignedListShown = true;
  vehiclesOnMapListShown = true;
  vehiclesOutsideMapListShown = true;
  usersSuggestedListShown = true;
  usersAllListShown = true;
  statusColorEnum = statusColorEnum;

  constructor(public distancePipe: DistancePipe) {}

  ngOnChanges(): void {
    this.handleChangingData();
  }

  ngOnDestroy(): void {
    this.translateSubscription.unsubscribe();
    this.moreOptions$.unsubscribe();
    this.storeTranslationsSubscription.unsubscribe();
  }

  /**
   * @description
   * Handle the translation changes for the card.
   * This is used to update the card data.
   * @returns void
   * @private
   */
  handleChangingData(): void {
    if (this.vehicles?.length) {
      this.vehiclesFilteredOnMap = [
        ...this.vehicles.filter(
          (vehicle) =>
            vehicle.id !== undefined &&
            this.vehiclesOnMap.includes(vehicle.id?.toString())
        ),
      ];
      this.vehiclesFilteredOutsideMap = [
        ...this.vehicles.filter(
          (vehicle) =>
            !(
              vehicle.id !== undefined &&
              this.vehiclesOnMap.includes(vehicle.id?.toString())
            )
        ),
      ];
    } else {
      this.vehiclesFilteredOnMap = [];
      this.vehiclesFilteredOutsideMap = this.vehicles;
    }

    if (this.users?.length) {
      // TODO: This will be changed with the assigned task. For now, we are using the userStatus property.
      this.usersSuggested = this.users.filter(
        (driver) => driver.userStatus !== 'on-duty'
      );
    } else {
      this.usersSuggested = this.users;
    }
  }

  /**
   * @description
   * Assignation of the card.
   * This will emit the card data to the parent component.
   * The parent component will handle the assignation.
   * @param {controlPanelCardAllDataType} card - The card to assign
   * @returns void
   */
  assignCard(card: controlPanelCardAllDataType): void {
    this.cardSelected = undefined;
    this.cardAssignClick.emit({ data: card });
  }

  /**
   * @description
   * On hover of the card, set the hovered property to true. This will change the style of the map circuit. Also, it will show the popover.
   * On leave of the card, set the hovered property to false. This will change the style of the map circuit. Also, it will hide the popover.
   * @param {boolean} hovered - The hovered property
   * @param {controlPanelCardAllDataType} hoveredCard - The card hovered
   * @returns void
   */
  hoveredCardChanges(
    hovered: boolean,
    hoveredCard: controlPanelCardAllDataType
  ): void {
    if (hoveredCard.cardType === 'circuits') {
      this.handleHoveredCircuit(hoveredCard, hovered);
    } else if (hoveredCard.cardType === 'vehicle') {
      this.handleHoveredVehicle(hovered, hoveredCard);
    }
  }

  /**
   * @description
   * Handle the hovered circuit. This will change the style of the circuit on the map.
   * Also, it will show the popover.
   * If the circuit is clicked, it will not hide the popover.
   * @param {controlPanelCardAllDataType} circuit - The circuit hovered
   * @param {boolean} hovered - The hovered property
   * @returns void
   */
  handleHoveredCircuit(
    circuit: controlPanelCardAllDataType,
    hovered: boolean
  ): void {
    const currCircuitId = `circuit-${circuit.id}`;
    const circuitPopover = document.getElementById(
      `${POPOVER_CIRCUIT_BASE_STRING}${circuit.id}`
    );
    const circuitContainerElement = document.getElementById(
      `${MARKER_START_PIN_BASE_STRING}${circuit.id}`
    );

    if (
      this.mapboxMap &&
      circuitPopover &&
      circuitContainerElement &&
      this.mapboxMap?.getLayer(currCircuitId)
    ) {
      if (hovered) {
        if (
          circuitPopover?.getAttribute('hovered') !== 'true' &&
          circuitPopover?.getAttribute('clicked') !== 'true'
        ) {
          this.mapboxMap.setPaintProperty(
            currCircuitId,
            'line-color',
            '#33AB72'
          ); // Change line color on hover
          this.mapboxMap.setPaintProperty(
            `${currCircuitId}-stroke`,
            'line-color',
            '#007748'
          ); // Change line stroke color on hover

          circuitPopover?.style.setProperty('opacity', '1');
          circuitPopover?.setAttribute('hovered', 'true');
          circuitContainerElement?.style.setProperty('z-index', '6');
        }
      } else {
        if (
          circuitPopover?.getAttribute('clicked') !== 'true' &&
          circuitPopover?.getAttribute('hovered') === 'true'
        ) {
          this.mapboxMap.setPaintProperty(
            currCircuitId,
            'line-color',
            '#ABB1B5'
          ); // Reset line color on leave
          this.mapboxMap.setPaintProperty(
            `${currCircuitId}-stroke`,
            'line-color',
            '#333E48'
          ); // Reset line stroke color on leave

          circuitPopover?.style.setProperty('opacity', '0');
          circuitPopover?.setAttribute('hovered', 'false');
          circuitContainerElement?.style.setProperty('z-index', '2');
        }
      }
    }
  }

  /**
   * @description
   * Handle the hovered vehicle. This will change the style of the vehicle on the map.
   * Also, it will show the popover.
   * If the vehicle is clicked, it will not hide the popover.
   * @param {controlPanelCardAllDataType} vehicle - The vehicle hovered
   * @param {boolean} hovered - The hovered property
   * @returns void
   */
  handleHoveredVehicle(
    hovered: boolean,
    vehicle: controlPanelCardAllDataType
  ): void {
    const vehicleHovered = document.getElementById(
      `${VEHICLE_MARKER_POPOVER_STRING}${vehicle.id}`
    );

    if (vehicleHovered) {
      if (hovered) {
        vehicleHovered.style.setProperty('opacity', '1');
        vehicleHovered.setAttribute('hovered', 'true');
        vehicleHovered.parentElement?.style.setProperty('z-index', '5');
      } else if (this.cardSelected?.id !== vehicle.id) {
        vehicleHovered.style.setProperty('opacity', '0');
        vehicleHovered.setAttribute('hovered', 'false');
        vehicleHovered.parentElement?.style.setProperty('z-index', '2');
      }
    }
  }

  /**
   * @description
   * Toggle circuits list assigned.
   * @returns void
   */
  circuitsAssignedListToggle(): void {
    this.circuitsIsAssignedListShown = !this.circuitsIsAssignedListShown;
  }

  /**
   * @description
   * Toggle circuits list non assigned.
   * @returns void
   */
  circuitsNonAssignedListToggle(): void {
    this.circuitsIsNonAssignedListShown = !this.circuitsIsNonAssignedListShown;
  }

  /**
   * @description
   * Toggle vehicles suggested list suggested.
   * @returns void
   */
  vehiclesSuggestedListToggle(): void {
    this.vehiclesOnMapListShown = !this.vehiclesOnMapListShown;
  }

  /**
   * @description
   * Toggle vehicles list all.
   * @returns void
   */
  vehiclesAllListToggle(): void {
    this.vehiclesOutsideMapListShown = !this.vehiclesOutsideMapListShown;
  }

  /**
   * @description
   * Toggle users suggested list suggested.
   * @returns void
   */
  usersSuggestedListToggle(): void {
    this.usersSuggestedListShown = !this.usersSuggestedListShown;
  }

  /**
   * @description
   * Toggle users list all.
   * @returns void
   */
  usersAllListToggle(): void {
    this.usersAllListShown = !this.usersAllListShown;
  }

  vehicleTrackByFn(
    index: number,
    vehicle: controlPanelCardDataVehiclesInterface
  ): string {
    return vehicle.id?.toString() ?? index.toString();
  }
}
