<div class="agenda-items-container">
  <div class="scroll-container">
    <astus-agenda-item-header
      *ngFor="let agendaItem of agendaItemsElement"
      [title]="agendaItem.title"
      [status]="agendaItem.status"
    ></astus-agenda-item-header>
  </div>
</div>
<!-- Add agenda item card here -->
