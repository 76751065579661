import { createReducer, on } from '@ngrx/store';

import { getCurrentHours } from '../helpers/hour.helper';
import { dateFormatEnum } from '../pipes/dateformat/dateformat.enum';
import { durationEnum } from '../pipes/duration/duration.enum';
import { hoursFormatEnum } from '../pipes/hours-format/hours-format.enum';

import { globalReducerInterface } from './global';
import {
  menuActions,
  userActions,
  notificationActions,
  dateActions,
  reportsActions,
} from './global.action';
import { unitSystemEnum, userLanguageEnum } from './global.enum';
import { sortNotifications } from './global.helper';
import { notificationsSettingsMock } from './notificationsSettings.mock';
import { permissionsMock } from './permissions.mock';

export const globalStateKey = 'globalState';

export const initialState: globalReducerInterface = {
  user: {
    email: null,
    familyName: null,
    givenName: null,
    name: null,
    nickname: null,
    picture: null,
    id: null,
    fleetOwnerId: null,
    dateFormat: dateFormatEnum['DD/MM/YYYY'],
    unitSystem: unitSystemEnum.METRIC,
    durationFormat: durationEnum['JJ hh:mm'],
    hoursFormat: hoursFormatEnum.TWELVE,
    language: userLanguageEnum.FR,
    notificationsSettings: notificationsSettingsMock,
    permissions: permissionsMock,
    resourcesVehicleColumns: [],
  },
  menu: {
    showMenu: false,
  },
  date: new Date(),
  notifications: [],
  timeline: {
    hourPickerOpenedInStartDate: false,
    hoursPickersState: {
      firstHourSelection: '00:00',
      secondHourSelection: '23:59',
    },
    timeLineStatus: false,
  },
  reports: {
    vehicles: [],
  },
};

export const globalReducer = createReducer<globalReducerInterface>(
  initialState,
  on(userActions.saveUserData, (_state, { user }) => {
    return {
      ..._state,
      user,
    };
  }),
  on(
    userActions.saveUserLanguageAndFormats,
    (_state, { languageAndFormats }) => {
      return {
        ..._state,
        user: {
          ..._state.user,
          ...languageAndFormats,
        },
      };
    }
  ),
  on(
    userActions.saveUserNotificationsSettings,
    (_state, { notificationsSettings }) => {
      return {
        ..._state,
        user: {
          ..._state.user,
          notificationsSettings,
        },
      };
    }
  ),
  on(userActions.saveUserPermissions, (_state, { permissions }) => {
    return {
      ..._state,
      user: {
        ..._state.user,
        permissions,
      },
    };
  }),
  on(userActions.saveUserColumns, (_state, { resourcesVehicleColumns }) => {
    return {
      ..._state,
      user: {
        ..._state.user,
        resourcesVehicleColumns,
      },
    };
  }),
  on(menuActions.closeMenu, (_state) => {
    return {
      ..._state,
      menu: {
        showMenu: false,
      },
    };
  }),
  on(menuActions.toggleMenu, (_state, { showMenu }) => {
    return {
      ..._state,
      menu: { showMenu: !showMenu },
    };
  }),
  on(dateActions.setDate, (_state, { date }) => {
    return {
      ..._state,
      date,
    };
  }),
  on(
    dateActions.setHoursTimelineFirstPicker,
    (_state, { firstHourSelection }) => {
      return {
        ..._state,
        timeline: {
          ..._state.timeline,
          hoursPickersState: {
            ..._state.timeline.hoursPickersState,
            firstHourSelection,
          },
        },
      };
    }
  ),
  on(
    dateActions.setHoursTimelineSecondPicker,
    (_state, { secondHourSelection }) => {
      return {
        ..._state,
        timeline: {
          ..._state.timeline,
          hoursPickersState: {
            ..._state.timeline.hoursPickersState,
            secondHourSelection,
          },
        },
      };
    }
  ),
  on(dateActions.setTimelineLiveStatus, (_state, { timelineLiveStatus }) => {
    return {
      ..._state,
      timeline: {
        ..._state.timeline,
        // If the timeline is live, the first hour selection will be the current hour / minute
        hoursPickersState: {
          ..._state.timeline.hoursPickersState,
          firstHourSelection: timelineLiveStatus
            ? getCurrentHours()
            : initialState.timeline.hoursPickersState.firstHourSelection,
        },
        timeLineStatus: timelineLiveStatus,
      },
    };
  }),

  on(notificationActions.markAllAsRead, (_state) => {
    return {
      ..._state,
      notifications: _state.notifications.map((notification) => {
        return {
          ...notification,
          unread: false,
        };
      }),
    };
  }),
  on(notificationActions.getNotifications, (_state, { notifications }) => ({
    ..._state,
    notifications: sortNotifications(notifications),
  })),
  on(reportsActions.getVehiclesLoaded, (_state, { vehicles }) => {
    return {
      ..._state,
      reports: {
        ..._state.reports,
        vehicles,
      },
    };
  })
);
