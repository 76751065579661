import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
} from '@angular/core';

import { IconAtomGlyphType } from '../../atoms';
import { cardInfoItemInterface, tooltipKindType } from '../../molecules';
import { popoverOptionType } from '../../molecules/popover/popover.molecule.d';
import { alertStatusEnum } from '../alert-card/alert-card.enum';
import { progressInfoStatusEnum } from '../progress-info/progress-info.enum';

@Component({
  selector: 'ad-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardOrganism implements OnChanges {
  @Input() id = '';

  // Info card stuff
  @Input() hideAvatars = false;
  @Input() firstAvatarText = '';
  @Input() firstAvatarTooltip = this.firstAvatarText;
  @Input() firstAvatarFallbackGlyph: IconAtomGlyphType = 'profile';
  @Input() secondAvatarText = '';
  @Input() secondAvatarTooltip = this.secondAvatarText;
  @Input() secondAvatarFallbackGlyph: IconAtomGlyphType = 'profile';

  @Input() tooltipPosition: tooltipKindType = 'top';

  @Input() title = '';
  @Input() titleSmall = '';
  @Input() subtitle = '';
  @Input() subtitleSmall = '';

  @Input() infoItems: cardInfoItemInterface[] = [];

  // Overall card stuff
  @Input() isSkeleton = false;
  @Input() isSelected = false;
  @Input() hovered = false;
  @Input() ctaButtonText = '';

  // Progress stuff
  @Input() progressTime = '';
  @Input() progressPercent: number | undefined;
  @Input() progressStatus: progressInfoStatusEnum =
    progressInfoStatusEnum.NORMAL;

  // Alert stuff
  @Input() alertStatus: alertStatusEnum = alertStatusEnum.NEUTRAL;
  @Input() alertTitle = '';
  @Input() alertSubtitle = '';
  @Input() alertDescription = '';
  @Input() alertCommunicateText = '';
  @Input() alertResolveText = '';

  @Output() clickCardEvent = new EventEmitter<boolean>();
  @Output() assignEvent = new EventEmitter<boolean>();
  @Output() moreOptionsEvent = new EventEmitter<boolean>();
  @Output() hoveredCardChange = new EventEmitter<boolean>();

  @Output() popoverOptionSelect = new EventEmitter<popoverOptionType>();
  @Output() popoverDisplayChange = new EventEmitter<boolean>();

  showMoreOptions = false;

  @Input()
  moreOptions: popoverOptionType[] = [];

  ngOnChanges(): void {
    if (this.isSelected) {
      this.hovered = false;
    }
  }

  /**
   * @description
   * Click and select the card.
   * @param {CardTypes} data
   * @returns {void}
   */
  selectCard(): void {
    this.clickCardEvent.emit();
  }

  /**
   * @description
   * On click of the more options, emit the event to the parent component.
   * @returns {void}
   */
  onMoreOptionsClick(): void {
    this.showMoreOptions = !this.showMoreOptions;
    this.moreOptionsEvent.emit();
  }

  /**
   * @description
   * On click of the card, emit the event to the parent component.
   * @returns {void}
   */
  onAssignClick(): void {
    this.showMoreOptions = false;
    this.assignEvent.emit();
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    this.hovered = true;
    this.hoveredCardChange.emit(true);
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.hovered = false;
    this.hoveredCardChange.emit(false);
    this.showMoreOptions = false;
  }
}
