import { createAction, props } from '@ngrx/store';

export const addFileName = createAction(
  '[Upload] Add File Name',
  props<{ fileName: string }>()
);

export const setUploadProgress = createAction(
  '[Upload] Set Upload Progress',
  props<{ fileName: string; progress: number }>()
);

export const resetUpload = createAction(
  '[Upload] Reset Upload',
  props<{ fileName: string }>()
);
