import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { filter, map, Subscription } from 'rxjs';

import { getInfoboxState, resourceActions } from '../../../resource/store';

import { resourcesRouteEnum } from './resources-header.enum';

@Component({
  selector: 'astus-resources-header',
  templateUrl: './resources-header.component.html',
  styleUrls: ['./resources-header.component.scss'],
})
/**
 * Represents the header component for resources.
 * This component handles the button translation key, info box state, and route subscriptions.
 */
export class ResourcesHeaderComponent implements OnInit, OnDestroy {
  buttonTranslationKey = '';
  isInfoBoxOpen = false;
  isEditMode = false;
  isRouteCircuits = false; // TODO: DELETE THIS LINE WHEN CIRCUIT CREATION IS IMPLEMENTED AND THE IMPLEMNTATION IN HTML

  routeSubscription: Subscription = new Subscription();
  infoboxStateSubscription: Subscription = new Subscription();

  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  constructor(private router: Router, private store: Store) {}

  ngOnInit(): void {
    this.setRessourceButton(this.router.url);
    this.routeSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.router.url)
      )
      .subscribe((url: string) => {
        this.setRessourceButton(url);
      });

    this.infoboxStateSubscription = this.store
      .select(getInfoboxState)
      .subscribe((isInfoBoxOpen) => {
        this.isInfoBoxOpen = isInfoBoxOpen;
      });
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    this.infoboxStateSubscription.unsubscribe();
  }

  /**
   * Sets the resource button based on the provided URL.
   * @param url - The URL to determine the resource button.
   */
  private setRessourceButton(url: string): void {
    this.resetButtonStates();
    this.toggleEditModeDependingOnUrl(url);
    this.setButtonTranslationKey(url);

    // TODO: DELETE THIS BLOCK WHEN CIRCUIT CREATION IS IMPLEMENTED
    if (url.toString().includes(resourcesRouteEnum.CIRCUITS)) {
      this.isRouteCircuits = true;
    }
  }

  /**
   * Resets the button states.
   */
  private resetButtonStates(): void {
    this.isRouteCircuits = false; // TODO: delete this line when circuit creation is implemented
    this.isEditMode = false;
  }

  /**
   * Toggles the edit mode based on the provided URL.
   * @param url - The URL to determine the edit mode.
   */
  private toggleEditModeDependingOnUrl(url: string): void {
    Object.keys(resourcesRouteEnum).forEach((key) => {
      if (
        resourcesRouteEnum[key as keyof typeof resourcesRouteEnum] !==
          resourcesRouteEnum.RESOURCES &&
        url
          .toString()
          .includes(
            `${resourcesRouteEnum[key as keyof typeof resourcesRouteEnum]}/`
          )
      ) {
        this.isEditMode = true;
      }
    });
  }

  /**
   * Sets the button translation key based on the provided URL.
   * @param url - The URL to determine the button translation key.
   */
  private setButtonTranslationKey(url: string): void {
    this.buttonTranslationKey = 'UPLOAD_GENERAL.BUTTON_TEXT';

    Object.keys(resourcesRouteEnum).forEach((key) => {
      if (
        resourcesRouteEnum[key as keyof typeof resourcesRouteEnum] !==
          resourcesRouteEnum.RESOURCES &&
        url
          .toString()
          .includes(
            `${resourcesRouteEnum[key as keyof typeof resourcesRouteEnum]}`
          )
      ) {
        this.buttonTranslationKey = `UPLOAD_WIDGET_${key.toUpperCase()}.BUTTON_TEXT`;
      }
    });
  }

  /**
   * Opens the info box panel by dispatching the corresponding action.
   */
  openInfobox(): void {
    if (this.isRouteCircuits) {
      this.fileInput.nativeElement.click();
    } else {
      this.store.dispatch(resourceActions.openInfoboxPanel());
    }
  }

  onCSVFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    // check if the input has files
    if (!input.files?.length) {
      return;
    }
    // get the file inside the input
    // should only be one file
    const file = input.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const base64StringWithHeader = reader.result as string;
      const base64String = base64StringWithHeader.split(',')[1]; // remove the data:text/csv;base64, the backend only accept base64 string without header

      this.store.dispatch(
        resourceActions.importGeobusCircuit({
          filename: file.name,
          base64Data: base64String,
        })
      );
    };
    reader.readAsDataURL(file); // Converts the file to a Base64-encoded string
  }
}
