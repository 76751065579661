import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LaunchDarklyService } from '@common/ts-feature-flag';
import { RolesService } from '@fms/ng-fms-api-client';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { appRoutesEnum } from '../../app-routing.module';
import { PermissionsService } from '../../services/permissions.service';
import { permissionsInterface } from '../../store/global';
import { profileFormDropdowns } from '../settings-profile/settings-profile.component';
import { settingsRoutesEnum } from '../settings-routing.module';
import { buttonProcessStateEnum } from '../settings.enum';
import { settingsRolesInterface } from '../store/settings';
import { settingsAPIActions } from '../store/settings.action';
import { selectSingleRoleFeature } from '../store/settings.selector';

@Component({
  selector: 'astus-settings-permissions',
  templateUrl: './settings-permissions.component.html',
  styleUrls: ['./settings-permissions.component.scss'],
})
export class SettingsPermissionsComponent implements OnInit, OnDestroy {
  roleId: number | 'create' = 'create';
  accessOrgs: number[] = [];
  accessGroups: number[] = [];
  assocUsers: string[] = [];
  title = '';
  description = '';
  isCreate = false;
  processState = buttonProcessStateEnum.READY;
  processDisabled = true;
  openCancelConfirmationModal = false;
  rolesAndResourceDropDowns: profileFormDropdowns = {
    roles: [],
  };
  allDisabled = false;

  groupsFlag$ = this.launchDarklyService.getFlagValue$('Reglages_Groupes');

  singleRoleSelect = new Subscription();

  generalGroup = this.fb.group({
    companyProfileAccess: [false],
    companyProfileEdit: [{ value: false, disabled: true }],
    languageAndFormatsAccess: [false],
    languageAndFormatsEdit: [{ value: false, disabled: true }],
    myProfilAccess: [false],
    myProfilEdit: [{ value: false, disabled: true }],
  });

  administrationGroup = this.fb.group({
    rolesAndPermissionsAccess: [false],
    rolesAndPermissionsCreateEdit: [{ value: false, disabled: true }],
    rolesAndPermissionsDelete: [{ value: false, disabled: true }],
  });

  generalGroup$ = this.generalGroup.valueChanges;
  administrationGroup$ = this.administrationGroup.valueChanges;
  generalGroupSub = new Subscription();
  administrationGroupSub = new Subscription();
  private rolePermissionSubscription$ = new Subscription();
  permissionsServiceSubscription = new Subscription();
  rolesAndPermissionsAccessSubscription = new Subscription();
  rolesAndPermissionsCreateEditSubscription = new Subscription();

  get formGroupsToCheck() {
    return [this.generalGroup, this.administrationGroup];
  }

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private store: Store,
    private router: Router,
    private rolesService: RolesService,
    private launchDarklyService: LaunchDarklyService,
    private permissionsService: PermissionsService
  ) {}

  ngOnInit(): void {
    // here use groups instead of roles
    // for now we can use roles because it will follow the same pattern
    this.rolePermissionSubscription$ = this.rolesService
      .rolesControllerGetRoles()
      .subscribe((roles) => {
        this.rolesAndResourceDropDowns = {
          roles,
        };
      });

    this.checkUserPermissionsToModifyRoles();
    if (!this.allDisabled) {
      this.manageGeneralGroupCheckboxStates();
      this.manageAdministrationGroupCheckboxStates();
    }

    this.roleId = this.route.snapshot.paramMap.get('id')
      ? parseInt(this.route.snapshot.paramMap.get('id') as string)
      : 'create';

    if (typeof this.roleId === 'number' && isNaN(this.roleId)) {
      this.roleId = 'create';
    }

    if (this.roleId === 'create') {
      this.isCreate = true;
    } else if (this.roleId !== null) {
      this.singleRoleSelect = this.store
        .select(selectSingleRoleFeature(this.roleId))
        .subscribe((role) => {
          const permissions: permissionsInterface | undefined =
            role?.permissions;

          this.title = role?.title || '';
          this.description = role?.description || '';
          this.assocUsers = role?.assocUsers || [];
          this.accessGroups = role?.assocGroups || [];
          this.accessOrgs = role?.assocOrgs || [];

          if (permissions) {
            this.fillValues(this.generalGroup, permissions.general);
            this.fillValues(
              this.administrationGroup,
              permissions.administration
            );
          }
        });
    }

    this.generalGroupSub = this.generalGroup$.subscribe(() => {
      this.checkIfFormIsSubmitable();
    });
    this.administrationGroupSub = this.administrationGroup$.subscribe(() => {
      this.checkIfFormIsSubmitable();
    });
  }

  ngOnDestroy(): void {
    this.singleRoleSelect.unsubscribe();
    this.generalGroupSub.unsubscribe();
    this.administrationGroupSub.unsubscribe();
    this.rolePermissionSubscription$.unsubscribe();
    this.permissionsServiceSubscription.unsubscribe();
    this.rolesAndPermissionsAccessSubscription.unsubscribe();
    this.rolesAndPermissionsCreateEditSubscription.unsubscribe();
  }

  /**
   * Fill the form group with the values from the permissions object
   * @param formGroup
   * @param permissions
   * @returns void
   */
  fillValues(formGroup: FormGroup, permissions: { [key: string]: boolean }) {
    Object.keys(permissions).forEach((key) => {
      formGroup.get(key)?.setValue(permissions[key]);
    });
  }

  onSave() {
    if (this.roleId === 'create') {
      this.store.dispatch(
        settingsAPIActions.rolesCreate({ role: this.createRoleObject() })
      );
    } else if (this.roleId !== null) {
      this.store.dispatch(
        settingsAPIActions.rolesEdit({
          role: this.createRoleObject(this.roleId),
        })
      );
    }

    if (this.administrationGroup.get('rolesAndPermissionsAccess')?.value) {
      this.redirectToRoles();
    } else {
      this.redirectToLangueAndFormats();
    }
  }

  /**
   * Creates the role object
   * @param id
   * @returns settingsRolesInterface
   */
  createRoleObject(id?: number): settingsRolesInterface {
    const values: settingsRolesInterface = {
      title: this.title,
      description: this.description,
      assocGroups: this.accessGroups,
      assocOrgs: this.accessOrgs,
      assocUsers: this.assocUsers,
      permissions: {
        general: {
          companyProfileAccess:
            typeof this.generalGroup.value.companyProfileAccess === 'boolean'
              ? this.generalGroup.value.companyProfileAccess
              : false,
          companyProfileEdit:
            typeof this.generalGroup.value.companyProfileEdit === 'boolean'
              ? this.generalGroup.value.companyProfileEdit
              : false,
          languageAndFormatsAccess:
            typeof this.generalGroup.value.languageAndFormatsAccess ===
            'boolean'
              ? this.generalGroup.value.languageAndFormatsAccess
              : false,
          languageAndFormatsEdit:
            typeof this.generalGroup.value.languageAndFormatsEdit === 'boolean'
              ? this.generalGroup.value.languageAndFormatsEdit
              : false,
          myProfilAccess:
            typeof this.generalGroup.value.myProfilAccess === 'boolean'
              ? this.generalGroup.value.myProfilAccess
              : false,
          myProfilEdit:
            typeof this.generalGroup.value.myProfilEdit === 'boolean'
              ? this.generalGroup.value.myProfilEdit
              : false,
        },
        administration: {
          rolesAndPermissionsAccess:
            typeof this.administrationGroup.value.rolesAndPermissionsAccess ===
            'boolean'
              ? this.administrationGroup.value.rolesAndPermissionsAccess
              : false,
          rolesAndPermissionsCreateEdit:
            typeof this.administrationGroup.value
              .rolesAndPermissionsCreateEdit === 'boolean'
              ? this.administrationGroup.value.rolesAndPermissionsCreateEdit
              : false,
          rolesAndPermissionsDelete:
            typeof this.administrationGroup.value.rolesAndPermissionsDelete ===
            'boolean'
              ? this.administrationGroup.value.rolesAndPermissionsDelete
              : false,
        },
      },
    };

    if (id) {
      values.id = id;
    }

    return values;
  }

  /**
   * Opens the cancel modal if the form has values
   * And redirects to the roles view
   * @returns void
   */
  onCancel() {
    const formGroupsHasValue = this.checkIfFormGroupsHasValue();

    if (
      this.isCreate &&
      (this.title.length > 0 ||
        this.description.length > 0 ||
        formGroupsHasValue)
    ) {
      this.openCancelConfirmationModal = true;
    } else {
      this.redirectToRoles();
    }
  }

  /**
   * Closes the cancel confirmation modal
   * @returns void
   */
  closeCancelModal() {
    this.openCancelConfirmationModal = false;
  }

  /**
   * Go back to the roles list
   * @returns void
   */
  redirectToRoles() {
    this.router.navigate([
      `${appRoutesEnum.SETTINGS}/${settingsRoutesEnum.ROLES}`,
    ]);
  }

  /**
   * Redirects to the language and formats settings page.
   */
  redirectToLangueAndFormats() {
    this.router.navigate([
      `${appRoutesEnum.SETTINGS}/${settingsRoutesEnum.LANGUAGE_AND_FORMATS}`,
    ]);
  }

  /**
   * Gets the role title
   * @param value: string
   * @returns void
   */
  headerTitle(value: string) {
    this.title = value;
    this.checkIfFormIsSubmitable();
  }

  /**
   * Gets the role description
   * @param value: string
   * @returns void
   */
  headerDescription(value: string) {
    this.description = value;
    this.checkIfFormIsSubmitable();
  }

  /**
   * Checks if the minimum required values are met
   * 1: The title has a value
   * 2: At least one form group has a "true" value
   * @returns void
   */
  checkIfFormIsSubmitable() {
    const titleHasValue = this.title.length > 0;
    const formGroupsHasValue = this.checkIfFormGroupsHasValue();

    if (titleHasValue && formGroupsHasValue) {
      this.processDisabled = false;
    } else {
      this.processDisabled = true;
    }
  }

  /**
   * Checks if at least one form group has a "true" value
   * @returns boolean
   */
  checkIfFormGroupsHasValue() {
    let formGroupsHasValue = false;

    this.formGroupsToCheck.forEach((formGroup) => {
      if (formGroup.value) {
        const values = Object.values(formGroup.value);
        if (values.some((value) => value === true)) {
          formGroupsHasValue = true;
          return;
        }
      }
    });

    return formGroupsHasValue;
  }

  /**
   * Manages the general group checkbox states
   * If the companyProfileAccess is checked, the companyProfileEdit is enabled
   * If the companyProfileAccess is unchecked, the companyProfileEdit is disabled
   * @returns void
   */
  manageGeneralGroupCheckboxStates() {
    this.generalGroup
      .get('companyProfileAccess')
      ?.valueChanges.subscribe((checked) => {
        const companyProfileEditControl =
          this.generalGroup.get('companyProfileEdit');

        if (checked) {
          companyProfileEditControl?.enable();
        } else {
          companyProfileEditControl?.setValue(false);
          companyProfileEditControl?.disable();
        }
      });

    this.generalGroup
      .get('languageAndFormatsAccess')
      ?.valueChanges.subscribe((checked) => {
        const languageAndFormatsEditControl = this.generalGroup.get(
          'languageAndFormatsEdit'
        );

        if (checked) {
          languageAndFormatsEditControl?.enable();
        } else {
          languageAndFormatsEditControl?.setValue(false);
          languageAndFormatsEditControl?.disable();
        }
      });

    this.generalGroup
      .get('myProfilAccess')
      ?.valueChanges.subscribe((checked) => {
        const myProfilEditControl = this.generalGroup.get('myProfilEdit');

        if (checked) {
          myProfilEditControl?.enable();
        } else {
          myProfilEditControl?.setValue(false);
          myProfilEditControl?.disable();
        }
      });
  }

  /**
   * Manages the checkbox states for the administration group.
   * Enables or disables the 'rolesAndPermissionsCreateEdit' and 'rolesAndPermissionsDelete'
   * controls based on the value of 'rolesAndPermissionsAccess' checkbox.
   * Also updates the value of 'rolesAndPermissionsDelete' based on the value of 'rolesAndPermissionsCreateEdit'.
   */
  manageAdministrationGroupCheckboxStates() {
    const rolesAndPermissionsAccess = this.administrationGroup.get(
      'rolesAndPermissionsAccess'
    );
    const rolesAndPermissionsCreateEdit = this.administrationGroup.get(
      'rolesAndPermissionsCreateEdit'
    );
    const rolesAndPermissionsDelete = this.administrationGroup.get(
      'rolesAndPermissionsDelete'
    );

    if (
      !rolesAndPermissionsAccess ||
      !rolesAndPermissionsCreateEdit ||
      !rolesAndPermissionsDelete
    ) {
      return;
    }

    this.rolesAndPermissionsAccessSubscription =
      rolesAndPermissionsAccess.valueChanges.subscribe((checked) => {
        if (checked) {
          rolesAndPermissionsCreateEdit.enable({ emitEvent: false });
        } else {
          rolesAndPermissionsCreateEdit.setValue(false);
          rolesAndPermissionsDelete.setValue(false);
          rolesAndPermissionsCreateEdit.disable({ emitEvent: false });
          rolesAndPermissionsDelete.disable({ emitEvent: false });
        }
      });

    this.rolesAndPermissionsCreateEditSubscription =
      rolesAndPermissionsCreateEdit.valueChanges.subscribe((checked) => {
        if (checked) {
          rolesAndPermissionsDelete.enable({ emitEvent: false });
        } else {
          rolesAndPermissionsDelete.disable({ emitEvent: false });
          rolesAndPermissionsDelete.setValue(false);
        }
      });
  }

  /**
   * Checks if the user has permissions to modify roles and disables the form groups if not.
   */
  checkUserPermissionsToModifyRoles() {
    this.permissionsServiceSubscription = this.permissionsService
      .getPermission$('administration.rolesAndPermissionsCreateEdit')
      .subscribe((hasPermission) => {
        if (!hasPermission) {
          this.formGroupsToCheck.forEach((formGroup) => {
            formGroup.disable();
          });
          this.allDisabled = true;
        }
      });
  }
}
