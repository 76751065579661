import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VehicleStateType } from '@fms/ng-fms-api-client';

import { buttonTabEnum } from '../../map-view-tabs-button/map-view-tabs-button.component';

export type AgendaItemElement = {
  title: string;
  status: VehicleStateType;
};

@Component({
  selector: 'astus-supervision-tabs',
  templateUrl: './supervision-tabs.component.html',
  styleUrls: ['./supervision-tabs.component.scss'],
})
export class SupervisionTabsComponent {
  buttonTabs = buttonTabEnum;

  @Input() buttonTabSelected: buttonTabEnum | null = buttonTabEnum.MAP;

  @Output() supervisionTabSelect = new EventEmitter<buttonTabEnum>();

  onTabSelect(tab: buttonTabEnum) {
    this.buttonTabSelected = tab;
    this.supervisionTabSelect.emit(tab);
  }
}
