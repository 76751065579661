import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { LaunchDarklyService } from '@common/ts-feature-flag';

import { assert } from '@common/utils';
import {
  CircuitView,
  DestinationsView,
  RouteView,
  StopView,
  VehicleAlertType,
  VehicleGroupType,
  VehicleStateType,
  VehicleType,
  VehicleView,
  UserViewInterface,
} from '@fms/ng-fms-api-client';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { DateTime } from 'luxon';
import { Subject, Subscription, combineLatest, takeUntil } from 'rxjs';

import { resourceTabType } from '../../app-routing.module';
import { vehicleSortFilterValues } from '../../components/vehicle-sort-filter/vehicle-sort-filter';
import { vehicleSortFilterStatusEnum } from '../../components/vehicle-sort-filter/vehicle-sort-filter.enum';
import { DistancePipe } from '../../pipes/distance/distance.pipe';
import { DurationPipe } from '../../pipes/duration/duration.pipe';
import { HoursFormatPipe } from '../../pipes/hours-format/hours-format.pipe';
import { DateService } from '../../services/date.service';
import { LocalService } from '../../services/local-storage.service';
import { SEARCHBAR } from '../constant';
import { MapInfoPanelComponent } from '../map-info-panel/map-info-panel.component';
import { MapTabValueEnum } from '../map-tab.enum';
import { buttonTabEnum } from '../map-view-tabs-button/map-view-tabs-button.component';
import {
  mapAPIActions,
  selectCircuitsFeature,
  selectUsersFeature,
  selectSupervisionModeSelected,
  selectSupervisionUrl,
  selectVehiclesFeature,
} from '../store';

import {
  controlPanelCardAllDataType,
  controlPanelCardDataRoutesInterface,
  controlPanelCardDataStopsInterface,
  controlPanelCircuitsCardInterface,
  controlPanelCircuitsDataInterface,
  controlPanelCardDataUsersInterface,
  controlPanelCardDataVehiclesInterface,
  controlPanelCardDefaultDataType,
  controlPanelModalAssociationType,
  controlPanelCardUsersInterface,
} from './map-control-panel.component.d';
import {
  controlPanelCircuitStatus,
  controlPanelRouteStatusEnum,
  controlPanelStopStatusEnum,
  controlPanelUsersStatusEnum,
} from './map-control-panel.enum';

// Reusable ids for the map control list
const MAP_LIST_CONTAINER_BASE_STRING = 'map-list-container';
const CARD_CIRCUIT_BASE_STRING = 'card-circuits';
const CARD_VEHICLE_BASE_STRING = 'card-vehicle';
const CARD_USER_BASE_STRING = 'card-user';

// Padding for the modal container in the map control panel
const PADDING_MODAL_CONTAINER = 16;

@Component({
  selector: 'astus-map-control-panel',
  templateUrl: './map-control-panel.component.html',
  styleUrls: ['./map-control-panel.component.scss'],
})
export class MapControlPanelComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('infoPanel') infoPanel: MapInfoPanelComponent | null = null;

  @Input() vehicleToDisplay: VehicleView | undefined;

  @Input() circuitToDisplay: CircuitView | undefined;

  @Input() vehicleMarkerSelected = false;

  @Input() circuitMarkerSelected = false;

  @Input() cardSelection?: controlPanelCardAllDataType;

  @Input() id: string | null = null;

  @Input() tab = MapTabValueEnum.CIRCUITS;

  @Input() previousCircuitData?: controlPanelCircuitsCardInterface;

  @Input() previousRouteData?: controlPanelCardDataRoutesInterface;

  @Input() mapboxMap: mapboxgl.Map | null = null;

  @Input() vehiclesOnMap: string[] = [];

  routeId: string | null = null;

  stopId: string | null = null;

  circuitsSubscription: Subscription = new Subscription();
  vehiclesSubscription: Subscription = new Subscription();
  usersSubscription: Subscription = new Subscription();

  circuits: controlPanelCircuitsDataInterface = {
    nonAssigned: [],
    assigned: [],
  };
  vehicles: controlPanelCardDataVehiclesInterface[] = [];
  vehiclesFiltered: controlPanelCardDataVehiclesInterface[] = [];
  users: controlPanelCardDataUsersInterface[] = [];

  circuitsFlag$ = this.launchDarklyService.getFlagValue$('Map_Circuits');
  circuitsFlagSub = new Subscription();
  circuitsFlagValue = false;
  vehiclesFlag$ = this.launchDarklyService.getFlagValue$('Map_Vehicules');
  vehiclesFlagSub = new Subscription();
  vehiclesFlagValue = false;
  chauffeursFlag$ = this.launchDarklyService.getFlagValue$('Map_Chauffeurs');
  chauffeursFlagSub = new Subscription();
  chauffeursFlagValue = false;

  circuitsBackup: controlPanelCircuitsDataInterface = {
    nonAssigned: [],
    assigned: [],
  };
  usersBackup: controlPanelCardDataUsersInterface[] = [];
  vehiclesBackup: controlPanelCardDataVehiclesInterface[] = [];

  isOpen?: boolean;
  showTabs = true;
  showAssociation = true;

  infoPanelData?: controlPanelCardAllDataType;
  infoPanelCategory = '';
  isAssociationModalShown = false;

  associationModalData = {
    circuit: '',
    vehicle: '',
    user: '',
  };

  storageSearchHistory: {
    circuits: Array<string>;
    vehicles: Array<string>;
    users: Array<string>;
  } = {
    circuits: [],
    vehicles: [],
    users: [],
  };

  searchValue: string =
    this.activatedRoute.snapshot.queryParams['search'] ?? '';
  searchHistory: Array<string> = [];
  searchResult: Array<string> = [];
  searchLocalStorage = 'historyMapControlSearch';

  filtersValues: vehicleSortFilterValues = {
    filter: {
      severity: vehicleSortFilterStatusEnum.SEVERITYHIGHTOLOW,
    },
    status: {
      [VehicleStateType.Maintenance]: false,
      [VehicleStateType.OffDuty]: false,
      [VehicleStateType.OnDuty]: false,
      [VehicleStateType.OutOfService]: false,
    },
    alertType: {
      [VehicleAlertType.Late]: false,
      [VehicleAlertType.Early]: false,
      [VehicleAlertType.ServiceBreak]: false,
      [VehicleAlertType.None]: false,
    },
    group: {
      [VehicleGroupType.East]: false,
      [VehicleGroupType.NorthWest]: false,
      [VehicleGroupType.SouthEast]: false,
      [VehicleGroupType.West]: false,
    },
    type: {
      [VehicleType.Autobus]: false,
      [VehicleType.AutobusAdapt]: false,
      [VehicleType.AutobusScolaire]: false,
      [VehicleType.Automobile]: false,
      [VehicleType.Camion]: false,
      [VehicleType.Camion18Roues]: false,
      [VehicleType.Camionette]: false,
      [VehicleType.Coach]: false,
      [VehicleType.Fourgonette]: false,
      [VehicleType.Minibus]: false,
    },
  };

  circuitsLoaded = false;
  usersLoaded = false;
  vehiclesLoaded = false;
  searchBarEnabled = false;

  container!: HTMLDivElement;
  tabValue = MapTabValueEnum; // Exporting the enum to the template
  tabToStart = MapTabValueEnum.CIRCUITS;

  @Output() vehicleToDisplayChange = new EventEmitter<VehicleView>();

  @Output() circuitToDisplayChange = new EventEmitter<CircuitView>();

  @Output() vehicleMarkerSelectedChange = new EventEmitter<boolean>();

  @Output() circuitMarkerSelectedChange = new EventEmitter<boolean>();

  @Output() cardSelectionChange =
    new EventEmitter<controlPanelCardAllDataType>();

  @Output() idChange = new EventEmitter<string | null>();

  @Output() tabChange = new EventEmitter<MapTabValueEnum>();

  @Output() previousCircuitDataChange = new EventEmitter<
    controlPanelCardAllDataType | undefined
  >();

  @Output() previousRouteDataChange = new EventEmitter<
    controlPanelCardAllDataType | undefined
  >();

  superVisionModeSelected: buttonTabEnum | null = null;

  superVisionUrl: string | null = null;

  destroy$ = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private store: Store,
    private dateService: DateService,
    private localStore: LocalService,
    private containerRef: ElementRef<HTMLDivElement>,
    private distancePipe: DistancePipe,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private durationPipe: DurationPipe,
    private hoursFormatPipe: HoursFormatPipe,
    private launchDarklyService: LaunchDarklyService
  ) {}

  ngOnInit(): void {
    this.initFlags();

    combineLatest([
      this.store.select(selectSupervisionUrl()),
      this.store.select(selectSupervisionModeSelected),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([url, mode]) => {
        this.superVisionUrl = url;
        this.superVisionModeSelected = mode;
        this.initContentSubscriptions();
      });

    this.container = this.containerRef.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.handleSearchHistory(this.tab);
    this.handleUserSelect();

    if (changes['vehicleMarkerSelected']) {
      this.handleVehicleMarkerSelect();
      this.handleCircuitMarkerSelect();
    }

    if (!this.vehicleToDisplay && !this.circuitToDisplay) {
      if (this.id === null) {
        this.cardSelection = undefined;
        this.cardSelectionChange.emit(this.cardSelection);

        this.previousCircuitData = undefined;
        this.previousCircuitDataChange.emit(this.previousCircuitData);
        this.previousRouteData = undefined;
        this.previousRouteDataChange.emit(this.previousRouteData);

        this.infoPanelData = undefined;
        this.isOpen = undefined;
      }
    }

    this.setSearchCircuitsResult();
    this.setFiltersVehiclesResult();
    this.setSearchUsersResult();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.circuitsSubscription.unsubscribe();
    this.vehiclesSubscription.unsubscribe();
    this.usersSubscription.unsubscribe();
  }

  initFlags(): void {
    combineLatest([
      this.circuitsFlag$,
      this.vehiclesFlag$,
      this.chauffeursFlag$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([circuits, vehicles, chauffeurs]) => {
        this.circuitsFlagValue = circuits;
        this.vehiclesFlagValue = vehicles;
        this.chauffeursFlagValue = chauffeurs;
        this.checkToShowTabs();
      });
  }

  checkToShowTabs(): void {
    this.showTabs =
      [
        this.circuitsFlagValue,
        this.vehiclesFlagValue,
        this.chauffeursFlagValue,
      ].filter((flag) => flag).length > 1;
  }

  checkToShowAssociation(): void {
    this.showAssociation =
      [
        this.circuitsFlagValue,
        this.vehiclesFlagValue,
        this.chauffeursFlagValue,
      ].filter((flag) => flag).length > 1;
  }

  setTabToStartValue(): void {
    if (this.circuitsFlagValue) {
      this.tabToStart = MapTabValueEnum.CIRCUITS;
    } else if (
      !this.circuitsFlagValue &&
      !this.vehiclesFlagValue &&
      this.chauffeursFlagValue
    ) {
      this.tabToStart = MapTabValueEnum.USERS;
    } else {
      this.tabToStart = MapTabValueEnum.VEHICLES;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    const { top } = this.container.getBoundingClientRect();
    this.container.style.setProperty('--map-control-panel-top', top + 'px');
  }

  /**
   * @description - Inits the content subscriptions after fetching required info in NgOnIi
   * @returns {void}
   * */
  initContentSubscriptions(): void {
    this.routeId = this.activatedRoute.snapshot.paramMap.get('routeId');
    this.stopId = this.activatedRoute.snapshot.paramMap.get('stopId');
    this.store.dispatch(mapAPIActions.circuitsLoad());

    this.circuitsSubscription = this.store
      .select(selectCircuitsFeature)
      .subscribe((circuits) => {
        this.dispatchCircuitGroup(circuits.data);
        if (this.searchValue) {
          this.handleSearchCircuitsValueChange(this.searchValue, true);
        }
        this.handleSearchHistory(this.tab);
        this.circuitsLoaded = circuits.loaded;
        this.updateSearchBarEnabled(MapTabValueEnum.CIRCUITS, circuits.loaded);
      });

    this.store.dispatch(mapAPIActions.vehiclesLoad());

    this.vehiclesSubscription = this.store
      .select(selectVehiclesFeature)
      .subscribe((vehicles) => {
        // Format the vehicles to display them in the card
        this.formatVehiclesCards(vehicles.data);
        if (this.searchValue) {
          this.handleSearchVehiclesValueChange(this.searchValue, true);
        }
        this.handleSearchHistory(this.tab);

        this.vehiclesLoaded = vehicles.loaded;

        this.updateSearchBarEnabled(MapTabValueEnum.VEHICLES, vehicles.loaded);
        this.vehiclesFiltered = this.handleVehiclesListWithFilters();
      });

    this.store.dispatch(mapAPIActions.usersLoad());

    this.usersSubscription = this.store
      .select(selectUsersFeature)
      .subscribe((users) => {
        this.formatUsersCards(users.data);
        if (this.searchValue) {
          this.handleSearchUsersValueChange(this.searchValue, true);
        }
        this.handleSearchHistory(this.tab);
        this.usersLoaded = users.loaded;
        this.updateSearchBarEnabled(MapTabValueEnum.USERS, users.loaded);
      });
  }

  /**
   * @description
   * Handle the vehicle marker selection and set the vehicles from the backup if the vehicle marker is selected
   * and get the vehicle selected from the vehicles
   * and set the search value to empty
   * and open the info panel with the card selected
   * @returns {void}
   * */
  handleVehicleMarkerSelect(): void {
    if (this.tab === MapTabValueEnum.VEHICLES) {
      this.handleScrollVehicleList();

      if (this.vehicleMarkerSelected) {
        this.vehicles = JSON.parse(JSON.stringify(this.vehiclesBackup));

        const vehicleSelected = this.vehicles.filter(
          (vehicle: controlPanelCardDataVehiclesInterface) =>
            vehicle.id === this.vehicleToDisplay?.id
        )[0];

        if (
          !this.infoPanelData ||
          (this.vehicleToDisplay &&
            this.vehicleToDisplay.id === vehicleSelected.id &&
            this.vehicleMarkerSelected)
        ) {
          this.searchValue = '';
          this.handleCardClick({ data: vehicleSelected });
        }
      }
    }
  }

  /**
   * @description
   * Handle the circuit marker selection and set the circuits from the backup if the circuit marker is selected
   * and get the circuit selected from the circuits
   * and set the search value to empty
   * and open the info panel with the card selected
   * @returns {void}
   * */
  handleCircuitMarkerSelect(): void {
    if (this.tab === MapTabValueEnum.CIRCUITS) {
      this.handleScrollCircularList();

      if (this.circuitMarkerSelected) {
        this.circuits = JSON.parse(JSON.stringify(this.circuitsBackup));

        if (this.circuitToDisplay) {
          let circuitSelected = this.circuits.assigned.filter(
            (circuit: controlPanelCircuitsCardInterface) =>
              circuit.id === this.circuitToDisplay?.id
          )[0];

          if (!circuitSelected) {
            circuitSelected = this.circuits.nonAssigned.filter(
              (circuit: controlPanelCircuitsCardInterface) =>
                circuit.id === this.circuitToDisplay?.id
            )[0];
          }

          if (
            !this.infoPanelData ||
            (this.circuitToDisplay &&
              this.circuitToDisplay.id === circuitSelected.id &&
              this.circuitMarkerSelected)
          ) {
            this.searchValue = '';
            this.handleCardClick({ data: circuitSelected });
          }
        }
      }
    }
  }

  /**
   * @description Enable or disable the search bar based on whether the data loading is completed
   * @param {MapTabValueEnum} tab current tab selected
   * @param {boolean} loaded data loaded or not
   * @returns {void}
   */
  updateSearchBarEnabled(tab: MapTabValueEnum, loaded: boolean) {
    if (this.tab === tab) {
      this.searchBarEnabled = loaded;
    }
  }

  /**
   * @description
   * Handle the user selected from the users
   * and handle the scroll to move the list to the selected user
   * @returns {void}
   * */
  handleUserSelect(): void {
    if (this.tab === MapTabValueEnum.USERS) {
      this.handleScrollUserList();
    }
  }

  /**
   * @description Handle the scroll to move the list to the selected vehicle
   * when the search value is empty or undefined
   * and there is a selected vehicle on the Map list
   * or the vehicle marker is selected
   * @returns {void}
   */
  handleScrollVehicleList(): void {
    setTimeout(() => {
      const vehicleSelectedIndex = this.vehicles.findIndex(
        (vehicle) =>
          vehicle.id?.toString() === this.cardSelection?.id?.toString()
      );

      const cardVehicle = document.getElementById(
        `${CARD_VEHICLE_BASE_STRING}-${this.cardSelection?.id}`
      );
      if (cardVehicle) {
        document
          .getElementsByClassName(MAP_LIST_CONTAINER_BASE_STRING)[0]
          ?.scrollTo({
            top: cardVehicle.scrollHeight * vehicleSelectedIndex,
            behavior: 'smooth',
          });
      }
    }, 200);
  }

  /**
   * @description Handle the scroll to move the list to the selected circuit
   * when the search value is empty or undefined
   * and there is a selected circuit on the Map list
   * or the circuit marker is selected
   * @returns {void}
   */
  handleScrollCircularList(): void {
    setTimeout(() => {
      const cardId =
        this.circuitToDisplay?.id.toString() ??
        this.cardSelection?.id?.toString() ??
        this.id?.toString() ??
        '';
      const circuitSelectedAssignedIndex = this.circuits.assigned.findIndex(
        (circuit) => circuit.id?.toString() === cardId
      );
      const circuitSelectedNonAssignedIndex =
        this.circuits.nonAssigned.findIndex(
          (circuit) => circuit.id?.toString() === cardId
        );

      const cardCircuit = document.getElementById(
        `${CARD_CIRCUIT_BASE_STRING}-${cardId}`
      );

      if (cardCircuit) {
        const circuitNonAssignedListHeight = document.getElementsByClassName(
          'circuits-non-assigned-list'
        )[0].scrollHeight;

        document
          .getElementsByClassName(MAP_LIST_CONTAINER_BASE_STRING)[0]
          ?.scrollTo({
            top:
              cardCircuit.scrollHeight *
              (circuitSelectedAssignedIndex > -1
                ? circuitSelectedAssignedIndex + circuitNonAssignedListHeight
                : circuitSelectedNonAssignedIndex),
            behavior: 'smooth',
          });
      }
    }, 200);
  }

  /**
   * @description Handle the scroll to move the list to the selected user
   * when the search value is empty or undefined
   * and there is a selected user on the Map list
   * or the user marker is selected
   * @returns {void}
   */
  handleScrollUserList(): void {
    setTimeout(() => {
      const userSelectedIndex = this.users.findIndex(
        (user) => user.id?.toString() === this.cardSelection?.id?.toString()
      );

      const cardUser = document.getElementById(
        `${CARD_USER_BASE_STRING}-${this.cardSelection?.id}`
      );
      if (cardUser) {
        document
          .getElementsByClassName(MAP_LIST_CONTAINER_BASE_STRING)[0]
          ?.scrollTo({
            top: cardUser.scrollHeight * userSelectedIndex,
            behavior: 'smooth',
          });
      }
    }, 200);
  }

  /**
   * @description
   * Set the search value to empty if the tab is users
   * and the vehicle marker is not selected
   * and the info panel is not open
   * and the search value is empty or undefined
   * @returns {void}
   * */
  setSearchValueUser(): void {
    if (
      !this.vehicleMarkerSelected &&
      this.tab === MapTabValueEnum.USERS &&
      !this.isOpen
    ) {
      this.searchValue = '';
    }

    if (!this.searchValue) {
      if (this.tab === MapTabValueEnum.CIRCUITS) {
        this.circuits = JSON.parse(JSON.stringify(this.circuitsBackup));
      } else if (this.tab === MapTabValueEnum.USERS) {
        this.users = JSON.parse(JSON.stringify(this.usersBackup));
      }
    }
  }

  /**
   * @description
   * Set the filter values to be used when formatting the cards
   * @param {vehicleSortFilterValues} event the filter values
   * @returns {void}
   * */
  handleVehiclesSortFilterValues(event: vehicleSortFilterValues): void {
    this.filtersValues = event;

    this.setFiltersVehiclesResult(false);

    this.vehiclesFiltered = this.handleVehiclesListWithFilters();
  }

  /**
   * @description
   * On click on the VEHICLE MARKER, the search result should be empty and
   * generate the search result based on the search value in the vehicles from the backup
   * scroll to the selected vehicle when the reset button is clicked
   * @param {string} value the search value
   * @param {boolean} reset if the search value should be reset with the reset button x
   * @returns {void}
   * */
  setFiltersVehiclesResult(reset?: boolean): void {
    if (this.tab === MapTabValueEnum.VEHICLES) {
      this.searchResult = [];

      this.vehicles.forEach((vehicle) => {
        if (
          vehicle.title &&
          !this.searchResult.includes(vehicle.title.replace(/\s/g, ''))
        ) {
          this.searchResult.push(vehicle.title);
        }
      });

      // Handle the scroll to the selected vehicle when the reset button is clicked
      if (reset && !this.searchValue?.length) {
        this.handleScrollVehicleList();
      }
    }
  }

  /**
   * @description
   * Checks the advanced filters from vehicles and returns the filtered vehicles
   * @returns {controlPanelCardDataVehiclesInterface[]} the filtered vehicles
   */
  handleVehiclesListWithFilters(): controlPanelCardDataVehiclesInterface[] {
    return this.vehicles.filter((vehicle) => {
      const { alertType, group, status, type } = this.filtersValues;
      const hasAlertTypeFilter = Object.values(alertType).some(
        (value) => value
      );
      const hasGroupFilter = Object.values(group).some((value) => value);
      const hasStatusFilter = Object.values(status).some((value) => value);
      const hasTypeFilter = Object.values(type).some((value) => value);

      if (
        hasAlertTypeFilter ||
        hasGroupFilter ||
        hasStatusFilter ||
        hasTypeFilter
      ) {
        let hasAlertType = false;
        let hasGroup = false;
        let hasStatus = false;
        let hasType = false;

        hasAlertType = Object.entries(alertType).some(
          ([key, value]) => value && vehicle.alertType === key
        );
        hasGroup = Object.entries(group).some(
          ([key, value]) => value && vehicle.vehicleGroupType === key
        );
        hasStatus = Object.entries(status).some(
          ([key, value]) => value && vehicle.vehicleStatus === key
        );
        hasType = Object.entries(type).some(
          ([key, value]) => value && vehicle.vehicleType === key
        );

        if (hasAlertType || hasGroup || hasStatus || hasType) {
          return true;
        }

        return false;
      } else {
        return true;
      }
    });
  }

  /**
   * @description
   * Generate the search result based on the search value in the circuits from the backup
   * scroll to the selected circuit when the reset button is clicked
   * @param {string} value the search value
   * @param {boolean} reset if the search value should be reset with the reset button x
   * @returns {void}
   * */
  setSearchCircuitsResult(value?: string, reset?: boolean): void {
    if (this.tab === MapTabValueEnum.CIRCUITS) {
      this.searchResult = [];

      this.circuits.assigned.forEach((circuit) => {
        if (
          circuit.title &&
          !this.searchResult.includes(circuit.title.replace(/\s/g, ''))
        ) {
          this.searchResult.push(circuit.title);
        }
      });
      this.circuits.nonAssigned.forEach((circuit) => {
        if (
          circuit.title &&
          !this.searchResult.includes(circuit.title.replace(/\s/g, ''))
        ) {
          this.searchResult.push(circuit.title);
        }
      });

      // Handle the scroll to the selected circuit when the reset button is clicked
      if (reset && !value?.length) {
        this.handleScrollCircularList();
      }
    }
  }

  /**
   * @description
   * Generate the search result based on the search value in the users from the backup
   * scroll to the selected user when the reset button is clicked
   * @param {string} value the search value
   * @param {boolean} reset if the search value should be reset with the reset button x
   * @returns {void}
   * */
  setSearchUsersResult(value?: string, reset?: boolean): void {
    if (this.tab === MapTabValueEnum.USERS) {
      this.searchResult = [];

      this.users.forEach((user) => {
        if (
          user.title &&
          !this.searchResult.includes(user.title.replace(/\s/g, ''))
        ) {
          this.searchResult.push(user.title);
        }
      });

      // Handle the scroll to the selected user when the reset button is clicked
      if (reset && !value?.length) {
        this.handleScrollUserList();
      }
    }
  }

  /**
   * @description
   * Handle the search history based on the tab value passed in parameter
   * Get the search history from the local storage
   * Set the search history based on the tab
   * Set the loading to true if there is no data in the tab selected
   * @param {MapTabValueEnum} tab the tab value
   * @returns {void}
   */
  handleSearchHistory(tab: MapTabValueEnum): void {
    const historyMapControlSearch = this.localStore.get(
      this.searchLocalStorage
    );

    if (historyMapControlSearch) {
      this.storageSearchHistory = JSON.parse(historyMapControlSearch);
    }

    // Set the search history based on the tab. Circuits tab is the default tab.
    switch (tab) {
      case MapTabValueEnum.CIRCUITS:
        this.searchHistory = this.storageSearchHistory.circuits || [];
        break;
      case MapTabValueEnum.VEHICLES:
        this.searchHistory = this.storageSearchHistory.vehicles || [];
        break;
      case MapTabValueEnum.USERS:
        this.searchHistory = this.storageSearchHistory.users || [];
        break;
      default:
        break;
    }
  }

  /**
   * @description
   * Applies a filter based on the second parameter and returns the result
   * @param {controlPanelCardAllDataType | controlPanelCardDataUsersInterface | controlPanelCardDataVehiclesInterface} item the element to filtering
   * @param {string} search the value to match
   */
  applyFilterBySearch(
    item: controlPanelCardAllDataType,
    search: string
  ): controlPanelCardAllDataType;
  applyFilterBySearch(
    item: (
      | controlPanelCardDataUsersInterface
      | controlPanelCardDataVehiclesInterface
    )[],
    search: string
  ): (
    | controlPanelCardDataUsersInterface
    | controlPanelCardDataVehiclesInterface
  )[];
  applyFilterBySearch(
    items:
      | controlPanelCardAllDataType
      | (
          | controlPanelCardDataUsersInterface
          | controlPanelCardDataVehiclesInterface
        )[],
    search: string
  ):
    | controlPanelCardAllDataType
    | (
        | controlPanelCardDataUsersInterface
        | controlPanelCardDataVehiclesInterface
      )[] {
    const filter = (e: controlPanelCardDefaultDataType) =>
      e.title
        ?.trim()
        .toLocaleLowerCase()
        .includes(search.trim().toLocaleLowerCase());

    if (Array.isArray(items)) {
      return (items as controlPanelCardDefaultDataType[]).filter(filter);
    }

    return items;
  }

  /**
   * @description
   * Handle the search value change for the circuits object for "assigned" and "nonAssigned"
   * Calls the applyCircuitsFiltersBySearch function to filter the circuits
   * @param {controlPanelCircuitsDataInterface} items the circuits data object
   * @param {string} search the search value
   */
  applyCircuitsFiltersBySearch(
    items: controlPanelCircuitsDataInterface,
    search: string
  ): controlPanelCircuitsDataInterface {
    return {
      nonAssigned: this.applyFilterBySearch(items.nonAssigned, search),
      assigned: this.applyFilterBySearch(items.assigned, search),
    };
  }

  /**
   * @description
   * Dispatch the circuits to the assigned and non assigned group
   * Format the circuits to display them in the card
   * Set the circuits backup to be able to filter the circuits and keep the original data
   * Set the tab based on the data passed in parameter to show the correct tab
   * Set the info panel data based on the data passed in URL parameter to show the correct data
   * Set the card selection based on the data passed in URL parameter to show the correct data
   * @param {CircuitView[]} circuits the circuits to dispatch
   * @returns {void}
   */
  dispatchCircuitGroup(circuits: CircuitView[]): void {
    if (circuits.length) {
      const circuitsNonAssigned = circuits.filter(
        (circuit) => !(circuit.userAssociated && circuit.vehicleAssociated)
      );

      const circuitsAssigned = circuits.filter(
        (circuit) => circuit.userAssociated && circuit.vehicleAssociated
      );

      this.circuits.nonAssigned = circuitsNonAssigned.map(
        (circuit: CircuitView) => this.getFormattedCircuitCard(circuit)
      );

      this.circuits.assigned = circuitsAssigned.map((circuit: CircuitView) =>
        this.getFormattedCircuitCard(circuit)
      );

      this.circuits = { ...this.circuits };
      if (this.tab === MapTabValueEnum.CIRCUITS && this.id) {
        const circuitAssignedSelected = this.circuits.assigned.find(
          (circuit) => circuit.id?.toString() === this.id?.toString()
        );
        const circuitNonAssignedSelected = this.circuits.nonAssigned.find(
          (circuit) => circuit.id?.toString() === this.id?.toString()
        );

        if (circuitAssignedSelected || circuitNonAssignedSelected) {
          this.setRoutes(circuitAssignedSelected, circuitNonAssignedSelected);

          this.cardSelection = circuitAssignedSelected
            ? circuitAssignedSelected
            : circuitNonAssignedSelected;

          this.cardSelectionChange.emit(this.cardSelection);
          if (!this.infoPanelData) {
            this.router.navigateByUrl(
              `${this.superVisionUrl}/${resourceTabType.CIRCUITS}/${this.id}`
            );
            this.infoPanelData = this.cardSelection;
          }

          this.isOpen = true;
        }

        if (!this.cardSelection) {
          this.router.navigateByUrl(
            `${this.superVisionUrl}/${resourceTabType.CIRCUITS}`
          );
        }

        this.handleScrollCircularList();
      }
      this.circuitsBackup = JSON.parse(JSON.stringify(this.circuits));
    }
  }

  /**
   * @description
   * Set the routes based on the data passed in URL parameter to show the correct data in the info panel modal
   * and the card selection data
   * and the previous circuit data
   * and the previous route data
   * and set the stops based on the data passed in URL parameter if the route is selected in the info panel modal
   * @param {controlPanelCardAllDataType} circuitAssignedSelected the circuit assigned selected if there is one
   * @param {controlPanelCardAllDataType} circuitNonAssignedSelected the circuit non assigned selected if there is one
   * @returns {void}
   */
  setRoutes(
    circuitAssignedSelected: controlPanelCardAllDataType | undefined,
    circuitNonAssignedSelected: controlPanelCardAllDataType | undefined
  ): void {
    const route = this.activatedRoute.snapshot.paramMap.get('route');

    if (route && this.routeId) {
      if (circuitAssignedSelected && circuitAssignedSelected.routes) {
        this.infoPanelData = circuitAssignedSelected.routes.find(
          (route: controlPanelCardDataRoutesInterface) =>
            route.id?.toString() === this.routeId?.toString()
        );
      } else if (
        circuitNonAssignedSelected &&
        circuitNonAssignedSelected.routes
      ) {
        this.infoPanelData = circuitNonAssignedSelected.routes.find(
          (route: controlPanelCardDataRoutesInterface) =>
            route.id?.toString() === this.routeId?.toString()
        );
      }

      if (this.infoPanelData) {
        this.previousCircuitData = circuitAssignedSelected
          ? circuitAssignedSelected
          : circuitNonAssignedSelected;

        this.previousCircuitDataChange.emit(this.previousCircuitData);
        this.setStops(this.infoPanelData);
      }
    }
  }

  /**
   * @description
   * Set the stops based on the data passed in URL parameter
   * get the previous route data and set it and emit it
   * and set the info panel data based on the data passed in URL parameter to show the correct data
   * @param {controlPanelCardDataRoutesInterface} route the route selected
   * @returns {void}
   * */
  setStops(route: controlPanelCardDataRoutesInterface | undefined): void {
    const stop = this.activatedRoute.snapshot.paramMap.get('stop');

    if (stop && this.stopId) {
      this.infoPanelData = route?.stops?.find(
        (stop) => stop.id?.toString() === this.stopId?.toString()
      );

      if (this.infoPanelData) {
        this.previousRouteData = route;
        this.previousRouteDataChange.emit(this.previousRouteData);
      }
    }
  }

  /**
   * @description
   * Format the users to display them in the card
   * @param {userViewInterface[]} users
   * @returns {void}
   */
  formatUsersCards(users: UserViewInterface[]): void {
    if (users.length) {
      this.users = [];

      this.users = users.map((user: UserViewInterface) => {
        const circuitNames = user.circuitAssociated?.map(
          (circuit: { name: string }) => circuit.name
        );

        const userName = user.vehicleAssociated?.map(
          (user: { name: string }) => user.name
        );

        if (
          (!this.searchResult.includes(user.firstName || '') ||
            !this.searchResult.includes(user.lastName || '')) &&
          this.tab === MapTabValueEnum.USERS
        ) {
          this.searchResult.push(`${user.firstName} ${user.lastName}`);
        }

        const driverCardConvertedWithDriverView: controlPanelCardUsersInterface =
          {
            id: user.id,
            cardType: 'user',
            userStatus:
              user.dutyTime && user.dutyTime > 0
                ? controlPanelUsersStatusEnum.ON_WAY
                : controlPanelUsersStatusEnum.OFF_DUTY,
            title: `${user.firstName} ${user.lastName}`,
            isAssigned: !!user.circuitAssociated && !!user.vehicleAssociated,
            userName: user.firstName + ' ' + user.lastName,
            textInfo: this.translateService.instant(
              'CARDS.USERS_MENU.TIME_IN_SERVICE',
              {
                time: this.durationPipe.transform(user.dutyTime || 0),
              }
            ),
            monitoringUser: {
              email: user.mails ? user.mails.join(', ') : '',
              phone: user.phones ? user.phones.join(', ') : '',
              userInformation: {
                circuits: circuitNames ? circuitNames.join(' ') : '',
                preference: userName ? userName.join(' ') : '',
                type: '', // TODO: fill this type thing
              },
              userDetails: {
                singleJob: user.singleJob,
                expirationSingleJob: user.professionLicenceExpiration
                  ? user.professionLicenceExpiration.split(',')[0]
                  : '',
                licenseClass: user.licenceNumber ? user.licenceNumber : '',
                permit: user.permissions ? user.permissions.permission : '',
                expirationPermit: user.permissions
                  ? user.permissions.permissionExp.split(',')[0]
                  : '',
                transporter: user.transporter || '',
                homeport: user.homePort,
                groups: user.group,
              },
            },
          };

        return driverCardConvertedWithDriverView;
      });

      if (this.tab === MapTabValueEnum.USERS && this.id) {
        this.cardSelection = this.users.find(
          (user) => user.id?.toString() === this.id?.toString()
        );

        if (!this.cardSelection) {
          this.router.navigateByUrl(
            `${this.superVisionUrl}/${resourceTabType.DRIVERS}/`
          );
        } else {
          this.cardSelectionChange.emit(this.cardSelection);
          this.infoPanelData = this.cardSelection;
          this.isOpen = true;
        }

        this.handleScrollUserList();
      }

      this.usersBackup = JSON.parse(JSON.stringify(this.users));
    }
  }

  /**
   * @description
   * Format the vehicles to display them in the card
   * @param {VehicleView[]} vehicles
   * @returns {void}
   */
  formatVehiclesCards(vehicles: VehicleView[]): void {
    if (vehicles.length) {
      this.vehicles = vehicles.map((vehicle: VehicleView) => {
        let dateInHours = '';
        let fuel = '';

        // TODO: this is provisory until the API returns the odometer read time in UTC
        dateInHours = this.durationPipe.transform(
          new Date(
            this.dateService.provisoryFormatDate('6/4/2023, 7:14:04AM') // TODO: this is provisory until the API returns the date in UTC
          ).getMinutes()
        );

        this.translateService
          .stream('CARDS.MONITORING_CARD.VEHICLE_MONITORING.GASOLINE', {
            // TODO: this is provisory until the API returns the fuel level
            fuelLevel: this.distancePipe.transform(0),
          })
          .subscribe((res: string) => {
            return (fuel = res);
          });

        if (
          !this.searchResult.includes(vehicle.name || '') &&
          this.tab === MapTabValueEnum.VEHICLES
        ) {
          this.searchResult.push(vehicle.name || '');
        }

        if (
          this.id &&
          this.id === vehicle.id?.toString() &&
          this.tab === MapTabValueEnum.VEHICLES
        ) {
          this.vehicleToDisplay = vehicle;
          this.vehicleToDisplayChange.emit(this.vehicleToDisplay);
        }
        assert(!!vehicle.id, 'Vehicle ID missing');
        const vehicleCardConvertedWithVehicleView: controlPanelCardDataVehiclesInterface =
          {
            id: vehicle.id,
            cardType: 'vehicle',
            title: vehicle.name || '',
            textInfo: vehicle.passengersCapacity?.toString() || '',
            textInfo2: fuel,
            vehicleStatus: vehicle.state,
            alertType: vehicle.alertType,
            vehicleGroupType: vehicle.vehicleGroupType,
            vehicleType: vehicle.vehicleType || '',
            monitoringVehicle: {
              route: vehicle.currentLocation,
              vehicleInformation: {
                circuits:
                  vehicle.circuits?.map((circuit) => circuit.name).join(' ') ||
                  '',
                preference: `${vehicle.preferenceUser?.firstName} ${vehicle.preferenceUser?.lastName}`,
                type: vehicle.vehicleType || '',
              },
              vehicleDetails: {
                odometer: vehicle.odometer?.kilometer.toString() || '',
                odometerReadTime: dateInHours,
                vin: vehicle.VIN || '',
                plaque: vehicle.plateNumber || '',
                model: vehicle.model || '',
                year: vehicle.year?.toString() || '',
                fuel: vehicle.fuel || '',
                transporter: vehicle.transporter || '',
                fleet: vehicle.fleet || '',
                groups: vehicle.group || '',
                homeport: vehicle.homePort || '',
              },
              vehicleTelematicsModule: {
                serialNumber: vehicle.telematicsInfo?.serialNumber || '',
                type: vehicle.telematicsInfo?.type || '',
                softwareVersion: vehicle.telematicsInfo?.softwareVersion || '',
                lastConnection: vehicle.telematicsInfo?.connectionDate || '',
              },
              vehicleMaintenanceSchedule: {
                checkUpDate: vehicle.maintenanceDate || '',
                checkUp: vehicle.maintenanceDate || '',
                changeOilDate: vehicle.oilChangeDate || '',
                changeOil: vehicle.oilChangeDate || '',
              },
            },
          };

        return vehicleCardConvertedWithVehicleView;
      });

      if (this.tab === MapTabValueEnum.VEHICLES && this.id) {
        this.cardSelection = this.vehicles.find(
          (vehicle) => vehicle.id?.toString() === this.id?.toString()
        );
        this.vehicleMarkerSelected = true;
        this.vehicleMarkerSelectedChange.emit(this.vehicleMarkerSelected);

        if (!this.cardSelection) {
          this.router.navigateByUrl(
            `${this.superVisionUrl}/${resourceTabType.VEHICLES}`
          );
        } else {
          this.cardSelectionChange.emit(this.cardSelection);

          this.previousCircuitDataChange.emit(undefined);
          this.previousRouteDataChange.emit(undefined);

          this.infoPanelData = this.cardSelection;
          this.isOpen = true;
        }

        this.handleScrollVehicleList();
      }

      this.vehiclesBackup = JSON.parse(JSON.stringify(this.vehicles));
    }
  }

  /**
   * @description
   * Format the circuit to display it in the card
   * Add the circuit name in the search result if it wasn't there and if it's not empty
   * @param {CircuitView} circuit
   * @returns {CardDataCircuitInterface} the formatted circuit
   */
  getFormattedCircuitCard(
    circuit: CircuitView
  ): controlPanelCircuitsCardInterface {
    // @TODO: add the condition for the other status (SERVICE_INTERRUPTION, TIME_DELAY)
    const circuitStatus: controlPanelCircuitStatus = circuit.userAssociated
      ? controlPanelCircuitStatus.ON_TIME
      : controlPanelCircuitStatus.NO_USER;

    let routesInfo = '';
    this.translateService
      .stream('CARDS.CIRCUITS_MENU.CARD.ROUTE_INFO', {
        count: circuit.routesCount,
        plural: circuit.routesCount > 1 ? 's' : '',
      })
      .subscribe((res: string) => {
        routesInfo = res;
      });

    let studentsInfo = '';
    this.translateService
      .stream('CARDS.CIRCUITS_MENU.CARD.STUDENTS_INFO', {
        count: circuit.studentsTotal,
        plural: circuit.studentsTotal > 1 ? 's' : '',
      })
      .subscribe((res: string) => {
        studentsInfo = res;
      });

    const params: controlPanelCircuitsCardInterface = {
      id: circuit.id,
      cardType: 'circuits',
      title: circuit.name,
      isAssigned: !!(circuit.userAssociated && circuit.vehicleAssociated),
      circuitStatus,
      subtitle:
        this.hoursFormatPipe.transform(
          this.dateService.formatTime(circuit.startingTime)
        ) +
        (circuit.startingTime && circuit.endingTime ? ' - ' : '') +
        this.hoursFormatPipe.transform(
          this.dateService.formatTime(circuit.endingTime)
        ),
      subtitle2: `(${this.durationPipe.transform(circuit.expectedDuration)})`,
      textInfo: studentsInfo,
      textInfo2: routesInfo,
      routes: this.getFormattedRoutesCard(circuit.routes),
    };

    if (circuit.userAssociated) {
      params.userName =
        circuit.userAssociated.firstName +
        ' ' +
        circuit.userAssociated.lastName;
    }

    if (circuit.vehicleAssociated) {
      params.vehicleName = circuit.vehicleAssociated?.name;
    }

    if (
      !this.searchResult.includes(circuit.name) &&
      this.tab === MapTabValueEnum.CIRCUITS
    ) {
      this.searchResult.push(circuit.name);
    }
    return params;
  }

  /**
   * @description
   * Format the routes to display it in the card
   * @param {RouteView} routes
   * @returns {controlPanelCardDataRoutesInterface[]} the formatted routes
   */
  getFormattedRoutesCard(
    routes: RouteView[]
  ): controlPanelCardDataRoutesInterface[] {
    const formattedRoutes: controlPanelCardDataRoutesInterface[] = [];

    routes.forEach((route, index) => {
      const params: controlPanelCardDataRoutesInterface = {
        id: route.id,
        cardType: 'routes',
        title: `${this.hoursFormatPipe.transform(
          this.dateService.formatTime(route.startingTime)
        )} - ${this.hoursFormatPipe.transform(
          this.dateService.formatTime(route.endingTime)
        )}`,
        title2: route.destination,
        subtitle: route.name,
        routeStatus: controlPanelRouteStatusEnum['ON_TIME'],
        textInfo: route.seatingCapacity.toString(),
        textInfo2: `${route.completedStops}/${route.stopsCount}`,
        routeDistance:
          index < routes.length - 1
            ? `${this.distancePipe.transform(route.distance)}`
            : undefined,
        routeTime:
          index < routes.length - 1 ? `${route.timeToTravel}min` : undefined,
        isAssigned: true,
        stops: this.getFormattedStopsCard(route.stops, route.destinationsInfo),
      };

      formattedRoutes.push(params);
    });

    return formattedRoutes;
  }

  /**
   * @description
   * Format the stops to display it in the card
   * @param {StopView} stops
   * @returns {controlPanelCardDataStopsInterface[]} the formatted stops
   */
  getFormattedStopsCard(
    stops: StopView[],
    stopsInfo: DestinationsView
  ): controlPanelCardDataStopsInterface[] {
    const formattedStops: controlPanelCardDataStopsInterface[] = [];

    stops.forEach((stop, index) => {
      // TODO remove this format when the API returns the correct Date format data
      const stopTimeClear = stop.stopTime
        .replaceAll('.', '')
        .replace(/\u202f/g, ' ');
      const stopTimeFormatted = DateTime.fromFormat(
        stopTimeClear,
        'M/d/yyyy, h:mm:ss a'
      ).toFormat('h:mm');

      const params: controlPanelCardDataStopsInterface = {
        id: stop.id,
        cardType: 'stops',
        title: stop.name,
        subtitle: stop.destination,
        stopStatus:
          stop.stopStatus === 'completed'
            ? controlPanelStopStatusEnum.DONE
            : controlPanelStopStatusEnum.ON_TIME,
        textInfo: this.hoursFormatPipe.transform(
          this.dateService.formatTime(stop.expectedArrivalTime)
        ),
        textInfo2: stop.numberOfPassengerIn.toString(),
        stopTime: this.hoursFormatPipe.transform(stopTimeFormatted),
        stopDistance:
          index >= stops.length - 1
            ? undefined
            : `${this.distancePipe.transform(stopsInfo.distance)}`,
        stopStudents: stop.nameOfPassengersIn,
        stopNotes: stop.note || undefined,
        stopDuration: `${stop.stopDuration}s`,
        isAssigned: true,
      };
      formattedStops.push(params);
    });

    return formattedStops;
  }

  /**
   * @description
   * Handle the card click and set the info panel data
   * and open the info panel modal
   * and set the tab based on the data passed in parameter to show the correct tab
   * and change the route based on the tab
   * @param {CardAllDataType} event
   * @returns {void}
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleCardClick(event: { data: any }, tab?: MapTabValueEnum): void {
    const sameCard = this.infoPanelData?.id === event.data.id;
    this.infoPanelCategory = this.getCurrInfoPanelCategory();
    this.infoPanelData = event.data;
    this.isOpen = true;
    this.cardSelection = event.data;

    if (tab !== undefined) {
      this.id = event.data.id;
      this.idChange.emit(this.id);
    }

    if (
      this.vehicleToDisplay?.id !== event.data.id &&
      tab === MapTabValueEnum.VEHICLES
    ) {
      this.cardSelectionChange.emit(this.cardSelection);
      this.vehicleToDisplay = event.data;
      this.vehicleToDisplayChange.emit(this.vehicleToDisplay);
      this.router.navigateByUrl(
        `${this.superVisionUrl}/${resourceTabType.VEHICLES}/${event.data.id}`
      );
    } else if (
      this.circuitToDisplay?.id !== event.data.id &&
      tab === MapTabValueEnum.CIRCUITS
    ) {
      this.cardSelectionChange.emit(this.cardSelection);
      this.circuitToDisplay = event.data;
      this.circuitToDisplayChange.emit(this.circuitToDisplay);
      this.router.navigateByUrl(
        `${this.superVisionUrl}/${resourceTabType.CIRCUITS}/${event.data.id}`
      );
    } else if (tab === MapTabValueEnum.USERS && !sameCard) {
      this.router.navigateByUrl(
        `${this.superVisionUrl}/${resourceTabType.DRIVERS}/${event.data.id}`
      );
      this.vehicleToDisplay = undefined;
      this.vehicleToDisplayChange.emit(this.vehicleToDisplay);
      this.circuitToDisplay = undefined;
      this.circuitToDisplayChange.emit(this.circuitToDisplay);
    }
  }

  /**
   * @description
   * Handle the modal data change
   * and set the tab based on the data passed in parameter to show the correct tab
   * @param {controlPanelModalAssociationType} data the data passed from the modal
   * @returns {void}
   */
  handleAssociationModalDataChange(
    data: controlPanelModalAssociationType
  ): void {
    this.infoPanelData = undefined;
    this.isOpen = undefined;

    if (
      (!data.circuit && !data.user && !data.vehicle) ||
      (!data.circuit && (data.user || data.vehicle))
    ) {
      this.tab = this.tabToStart;
    } else if (!data.vehicle) {
      this.tab = MapTabValueEnum.VEHICLES;
    } else if (data.circuit && data.user && data.vehicle) {
      // Check if the data.circuit is in the assigned or non assigned list of circuits
      // and update the user and vehicle name
      const circuit = this.circuits.assigned.find(
        (circuit) => circuit.title === data.circuit
      );

      if (!circuit) {
        const circuitNonAssigned = this.circuits.nonAssigned.find(
          (circuit) => circuit.title === data.circuit
        );
        if (circuitNonAssigned) {
          circuitNonAssigned.userName = data.user;
          circuitNonAssigned.vehicleName = data.vehicle;

          // push the circuitNonAssigned in the circuits.assigned on the top of the list
          this.circuits.assigned.unshift(circuitNonAssigned);
          this.circuits.nonAssigned.splice(
            this.circuits.nonAssigned.indexOf(circuitNonAssigned),
            1
          );
        }
      } else {
        circuit.userName = data.user;
        circuit.vehicleName = data.vehicle;
      }

      this.circuitsBackup = JSON.parse(JSON.stringify(this.circuits));
      this.tab = MapTabValueEnum.CIRCUITS;
    }
  }

  /**
   * @description
   * Handle the assign click and show the association modal
   * and set the style of the list container to make sure the last item is visible
   * move the tab to the next tab
   * just to make sure the last item is visible when the modal is shown
   * @param {controlPanelCardAllDataType} event
   * @param {number} actualTab the actual tab
   * @returns {void}
   */
  handleAssignClick(
    event: { data: controlPanelCardAllDataType },
    actualTab: number
  ): void {
    this.isAssociationModalShown = true;
    this.isOpen = undefined;
    const assignedValue = event.data.title || '';

    this.vehicleToDisplayChange.emit(undefined);
    this.vehicleMarkerSelectedChange.emit(false);
    this.circuitToDisplayChange.emit(undefined);
    this.circuitMarkerSelectedChange.emit(false);

    switch (actualTab) {
      case MapTabValueEnum.CIRCUITS:
        this.setTabInitialValue(MapTabValueEnum.CIRCUITS);
        this.associationModalData.circuit = assignedValue;
        this.associationModalData.user = this.associationModalData.user
          ? this.associationModalData.user
          : event.data.userName
          ? event.data.userName
          : '';
        this.associationModalData.vehicle = this.associationModalData.vehicle
          ? this.associationModalData.vehicle
          : event.data.vehicleName
          ? event.data.vehicleName
          : '';
        this.tab = !this.associationModalData.vehicle
          ? MapTabValueEnum.VEHICLES
          : MapTabValueEnum.USERS;
        break;
      case MapTabValueEnum.VEHICLES:
        this.setTabInitialValue(MapTabValueEnum.VEHICLES);
        this.associationModalData.vehicle = assignedValue;
        this.tab = !this.associationModalData.circuit
          ? MapTabValueEnum.CIRCUITS
          : MapTabValueEnum.USERS;
        break;
      case MapTabValueEnum.USERS:
        this.setTabInitialValue(MapTabValueEnum.USERS);
        this.associationModalData.user = assignedValue;
        this.tab = !this.associationModalData.circuit
          ? MapTabValueEnum.CIRCUITS
          : !this.associationModalData.vehicle
          ? MapTabValueEnum.VEHICLES
          : MapTabValueEnum.USERS;
        break;
      default:
        break;
    }

    setTimeout(() => {
      const modalContainer = this.container.getElementsByClassName(
        'modal-container'
      )[0] as HTMLDivElement;
      const modalHeight = modalContainer.clientHeight;
      const listContainer = this.container.getElementsByClassName(
        'map-list-container'
      )[0] as HTMLDivElement;

      // Set the style of the list container of the padding bottom to the height of the modal container
      listContainer.style.paddingBottom = `${modalHeight + 16}px`; // 16px is the padding of the modal container
    }, 350); // 300ms is the time of the modal animation + 50ms to make sure the animation is done
  }

  /**
   * @description
   * Set the tab initial value based on the association modal data if there is no data in the modal
   * it means that the modal is not open yet and empty the association modal data
   * @param {number} MapTabValueEnum the tab value
   * @returns {void}
   */
  setTabInitialValue(MapTabValueEnum: number): void {
    if (
      !this.associationModalData.vehicle &&
      !this.associationModalData.user &&
      !this.associationModalData.circuit
    ) {
      this.tabToStart = MapTabValueEnum;
    }
  }

  /**
   * @description
   * Handle the click event and set the isAssociationModalShown to false to close the modal
   * and reset the style of the list container
   * @returns {void}
   */
  closeAssociationModal(): void {
    this.isAssociationModalShown = false;
    this.associationModalData = {
      circuit: '',
      vehicle: '',
      user: '',
    };
    this.tab = this.tabToStart;

    setTimeout(() => {
      const listContainer = this.container.getElementsByClassName(
        'map-list-container'
      )[0] as HTMLDivElement;

      listContainer.removeAttribute('style');
    }, 350); // 300ms is the time of the modal animation + 50ms to make sure the animation is done
  }

  /**
   * @description
   * Get the current category of the info panel
   * @returns {string} the current category
   */
  getCurrInfoPanelCategory(): string {
    if (this.circuits) {
      return 'circuits';
    } else if (this.vehicles) {
      return 'vehicles';
    } else if (this.users) {
      return 'users';
    } else {
      return '';
    }
  }

  /**
   * @description
   * Handle the search value change on click a selection or
   * on input change if the value is not empty
   * depending on the tab
   * @param {string} value the value to search
   * @param {boolean} input if the value is from the input or from the click on the selection
   * @param {boolean} reset if the reset is from the reset button click in the search bar
   * @returns {void}
   */
  handleSearchValueChange(
    value: string,
    input: boolean,
    reset?: boolean
  ): void {
    if (!reset && value) {
      this.searchValue = value;
      this.infoPanelData = undefined;
      this.isOpen = undefined;
      this.vehicleToDisplay = undefined;
      this.vehicleMarkerSelected = false;
      this.vehicleToDisplayChange.emit(this.vehicleToDisplay);
      this.vehicleMarkerSelectedChange.emit(this.vehicleMarkerSelected);
      this.circuitToDisplay = undefined;
      this.circuitMarkerSelected = false;
      this.circuitToDisplayChange.emit(this.circuitToDisplay);
      this.circuitMarkerSelectedChange.emit(this.circuitMarkerSelected);
      this.id = null;
      this.idChange.emit(this.id);
    }

    const search = value !== '' ? `?${SEARCHBAR}=${value}` : '';
    let url = '';
    if (this.tab === MapTabValueEnum.CIRCUITS) {
      url = `${this.superVisionUrl}/${resourceTabType.CIRCUITS}`;
      this.handleSearchCircuitsValueChange(value, input);
    } else if (this.tab === MapTabValueEnum.VEHICLES) {
      url = `${this.superVisionUrl}/${resourceTabType.VEHICLES}`;
      this.handleSearchVehiclesValueChange(value, input);
    } else if (this.tab === MapTabValueEnum.USERS) {
      url = `${this.superVisionUrl}/${resourceTabType.DRIVERS}`;
      this.handleSearchUsersValueChange(value, input);
    }

    this.router.navigateByUrl(`${url}${search}`);

    this.setSearchCircuitsResult(value, reset);
    this.setFiltersVehiclesResult(reset);
    this.setSearchUsersResult(value, reset);

    if (!reset && value) {
      this.cardSelection = undefined;
      this.cardSelectionChange.emit(this.cardSelection);
    }
  }

  /**
   * @description
   * Handle the search value change for circuits tab
   * add it in the history list if it wasn't there
   * generate the search result based on the search value in the circuits from the backup
   * copy the circuits backup and filter the circuits based on the search value
   * if there is more than 5 history, remove the last one on the list searched
   * @param {string} value the value to search in the circuits
   * @param {boolean} input if the value is from the input or from the click on the selection
   * @returns {void}
   */
  handleSearchCircuitsValueChange(value: string, input: boolean): void {
    this.circuits = JSON.parse(JSON.stringify(this.circuitsBackup));

    // If the value is not empty, then filter the circuits based on the value.
    if (value) {
      const { assigned, nonAssigned } = this.applyCircuitsFiltersBySearch(
        this.circuitsBackup,
        value
      );
      this.circuits.assigned = assigned;
      this.circuits.nonAssigned = nonAssigned;
    }

    // Add the value to the search history if it's not there.
    const { circuits } = this.storageSearchHistory;
    if (value && !input) {
      // If the value is not in the history, then add it to the history.
      if (!circuits.includes(value)) {
        // If the history has more than 5 items, then remove the last item.
        if (circuits.length > 4) {
          circuits.pop();
        }
      } else {
        // If the value is in the history, then remove it from the history.
        const index = circuits.indexOf(value);
        circuits.splice(index, 1);
      }

      // Add the value to the beginning of the history.
      circuits.unshift(value);
      // Save the history in the local storage.
      this.localStore.set(
        this.searchLocalStorage,
        JSON.stringify({
          ...this.storageSearchHistory,
          circuits,
        })
      );
    }
  }

  /**
   * @description
   * Handle the search value change for vehicle tab
   * add it in the history list if it wasn't there
   * copy the vehicles backup and filter the vehicles based on the search value
   * if there is more than 5 history, remove the last one on the list searched
   * @param {string} value the value to search in the vehicles
   * @param {boolean} input if the value is from the input or from the click on the selection
   * @returns {void}
   */
  handleSearchVehiclesValueChange(value: string, input: boolean): void {
    this.vehicles = JSON.parse(JSON.stringify(this.vehiclesBackup));

    // If the value is not empty, then filter the vehicles based on the value.
    if (value) {
      this.vehicles = this.applyFilterBySearch(this.vehiclesBackup, value);
    }

    const { vehicles } = this.storageSearchHistory;
    if (value && !input) {
      // If the value is not in the history, then add it to the history.
      if (!vehicles.includes(value)) {
        // If the history has more than 5 items, then remove the last item.
        if (vehicles.length > 4) {
          vehicles.pop();
        }
      } else {
        // If the value is in the history, then remove it from the history.
        const index = vehicles.indexOf(value);
        vehicles.splice(index, 1);
      }

      // Add the value to the beginning of the history.
      vehicles.unshift(value);
      // Save the history in the local storage.
      this.localStore.set(
        this.searchLocalStorage,
        JSON.stringify({
          ...this.storageSearchHistory,
          vehicles,
        })
      );
    }
  }

  /**
   * @description
   * Handle the search value change for user tab
   * add it in the history list if it wasn't there
   * generate the search result based on the search value in the users from the backup
   * copy the users backup and filter the users based on the search value
   * if there is more than 5 history, remove the last one on the list searched
   * @param {string} value the value to search in the users
   * @param {boolean} input if the value is from the input or from the click on the selection
   * @returns {void}
   */
  handleSearchUsersValueChange(value: string, input: boolean): void {
    this.users = JSON.parse(JSON.stringify(this.usersBackup));

    // If the value is not empty, then filter the users based on the value.
    if (value) {
      this.users = this.applyFilterBySearch(this.usersBackup, value);
    }

    const { users } = this.storageSearchHistory;
    if (value && !input) {
      // If the value is not in the history, then add it to the history.
      if (!users.includes(value)) {
        // If the history has more than 5 items, then remove the last item.
        if (users.length > 4) {
          users.pop();
        }
      } else {
        // If the value is in the history, then remove it from the history.
        const index = users.indexOf(value);
        users.splice(index, 1);
      }

      // Add the value to the beginning of the history.
      users.unshift(value);
      // Save the history in the local storage.
      this.localStore.set(
        this.searchLocalStorage,
        JSON.stringify({
          ...this.storageSearchHistory,
          users,
        })
      );
    }
  }

  /**
   * @description
   * Handle the tab click
   * Close the info panel and clear the info panel data
   * Clear the search value
   * Reset the search history based on the tab
   * Circuit tab: set the search history to the circuits search history
   * Vehicle tab: set the search history to the vehicles search history
   * User tab: set the search history to the users search history
   * @param {number} index the index of the tab
   * @returns {void}
   */
  tabClick(index: number): void {
    // Reset all data.
    this.idChange.emit(null);
    this.infoPanelData = undefined;
    this.isOpen = undefined;
    this.tab = index;
    this.searchValue = '';
    this.searchHistory = [];
    this.searchResult = [];

    let tabLoaded = false;

    if (
      !this.associationModalData.circuit &&
      !this.associationModalData.vehicle &&
      !this.associationModalData.user
    ) {
      this.isAssociationModalShown = false;
    }

    this.vehicleToDisplayChange.emit(undefined);
    this.vehicleMarkerSelectedChange.emit(false);
    this.circuitToDisplayChange.emit(undefined);
    this.circuitMarkerSelectedChange.emit(false);

    // If the tab is the circuits tab, then copy the circuits backup to the circuits variable.
    if (index === MapTabValueEnum.CIRCUITS) {
      this.circuits = JSON.parse(JSON.stringify(this.circuitsBackup));
      this.searchHistory = this.storageSearchHistory.circuits;

      // Add each circuit title to the search results.
      this.circuits.assigned.forEach((circuit) => {
        if (circuit.title && !this.searchResult.includes(circuit.title)) {
          this.searchResult.push(circuit.title);
        }
      });
      this.circuits.nonAssigned.forEach((circuit) => {
        if (circuit.title && !this.searchResult.includes(circuit.title)) {
          this.searchResult.push(circuit.title);
        }
      });
      tabLoaded = this.circuitsLoaded;

      this.tabChange.emit(index);
    } else if (index === MapTabValueEnum.VEHICLES) {
      // If the tab is the vehicles tab, then copy the vehicles backup to the vehicles variable.
      this.vehicles = JSON.parse(JSON.stringify(this.vehiclesBackup));
      this.searchHistory = this.storageSearchHistory.vehicles;

      // Add each vehicle title to the search results.
      this.vehicles.forEach((vehicle) => {
        if (vehicle.title && !this.searchResult.includes(vehicle.title)) {
          this.searchResult.push(vehicle.title);
        }
      });
      tabLoaded = this.vehiclesLoaded;
      this.tabChange.emit(index);
    } else if (index === MapTabValueEnum.USERS) {
      // If the tab is the users tab, then copy the users backup to the users variable.
      this.users = JSON.parse(JSON.stringify(this.usersBackup));
      this.searchHistory = this.storageSearchHistory.users;

      // Add each user title to the search results.
      this.users.forEach((user) => {
        if (user.title && !this.searchResult.includes(user.title)) {
          this.searchResult.push(user.title);
        }
      });
      tabLoaded = this.usersLoaded;
      this.tabChange.emit(index);
    }

    this.updateSearchBarEnabled(this.tab, tabLoaded);

    if (this.isAssociationModalShown) {
      setTimeout(() => {
        const modalContainer = this.container.getElementsByClassName(
          'modal-container'
        )[0] as HTMLDivElement;
        const modalHeight = modalContainer.clientHeight;
        const listContainer = this.container.getElementsByClassName(
          'map-list-container'
        )[0] as HTMLDivElement;

        // Set the style of the list container of the padding bottom to the height of the modal container
        if (listContainer) {
          listContainer.style.paddingBottom = `${
            modalHeight + PADDING_MODAL_CONTAINER
          }px`;
        }
      }, 350); // 350ms is the time of the modal animation + 50ms to make sure the animation is done
    }
  }
}
