<div [formGroup]="formGroup">
  <div class="header">
    <div class="info">
      <div class="all-selected">
        <ad-checkbox
          [text]="title"
          [selected]="allSelected"
          [indeterminate]="isIndeterminate"
          [disabled]="false"
          [size]="'md'"
          [color]="'primary'"
          [type]="'square'"
          (checkboxEvent)="selectAll($event)"
        ></ad-checkbox>
      </div>
      <span
        class="text-footer"
        *ngIf="!useCount"
        [ngClass]="{ '--count': !useCount }"
        >{{ selectedItems }} {{ 'FILTERS_COLUMNS.SELECTED' | translate }}</span
      >
    </div>
    <div class="actions">
      <button
        type="button"
        class="toggle-btn"
        [ngClass]="{ 'toggle-btn--is-open': isOpen }"
        (click)="this.isOpen = !this.isOpen"
      >
        <ad-icon glyph="chevron_down"></ad-icon>
      </button>
    </div>
  </div>
  <div class="body" [ngClass]="{ 'body--is-open': isOpen }">
    <div class="body-collapsible">
      <div class="body-content">
        <ad-checkbox
          *ngFor="let item of list"
          [text]="
            useCount
              ? (item.text | translate) +
                (item.count ? ' (' + item.count + ')' : ' (0)')
              : (item.text | translate)
          "
          [formControlName]="item.controlName"
          [size]="'md'"
          [color]="'primary'"
          [type]="'square'"
        ></ad-checkbox>
      </div>
    </div>
  </div>
</div>
