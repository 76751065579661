import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { Subscription } from 'rxjs';

import { PermissionsService } from '../../services/permissions.service';
import { settingsRolesInterface } from '../store/settings';
import { selectRolesFeature } from '../store/settings.selector';

@Component({
  selector: 'astus-settings-roles',
  templateUrl: './settings-roles.component.html',
  styleUrls: ['./settings-roles.component.scss'],
})
export class SettingsRolesComponent implements OnInit, OnDestroy {
  rolesList: settingsRolesInterface[] = [];
  permissionsServiceSubscription = new Subscription();
  storeSubscription = new Subscription();

  isCreateOrEdit = false;

  constructor(
    private store: Store,
    private permissionsService: PermissionsService
  ) {}

  ngOnInit() {
    this.storeSubscription = this.store
      .select(selectRolesFeature)
      .subscribe((state) => {
        this.rolesList = state;
      });

    this.permissionsServiceSubscription = this.permissionsService
      .getPermission$('administration.rolesAndPermissionsCreateEdit')
      .subscribe((value) => {
        this.isCreateOrEdit = value;
      });
  }

  ngOnDestroy() {
    this.storeSubscription.unsubscribe();
    this.permissionsServiceSubscription.unsubscribe();
  }
}
