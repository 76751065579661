import { Point, DragRef } from '@angular/cdk/drag-drop';
import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  HostListener,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

import { resourceDataType } from '../../table-empty-state.helpers';

@Component({
  selector: 'astus-upload-options-drawer',
  templateUrl: './upload-options-drawer.component.html',
  styleUrls: ['./upload-options-drawer.component.scss'],
})
export class UploadOptionsDrawerComponent implements AfterViewInit {
  // Input
  @Input() resourceSelected: resourceDataType | null = null;

  // Output
  @Output() drawerIsClosing = new EventEmitter<void>();
  @Output() createResource = new EventEmitter<void>();
  @Output()
  uploadInputEvent = new EventEmitter<FormData>();

  // ViewChild
  @ViewChild('upload_drawer')
  drawer!: ElementRef<HTMLDialogElement>;
  @ViewChild('grabber') grabber!: ElementRef<HTMLDivElement>;
  @ViewChild('fileInput', { static: false })
  fileInput!: ElementRef<HTMLInputElement>;

  // private state
  private DRAWER_MAX_SIZE = 188; // This size is defined by figma

  // public state
  public fileTypes: string[] = ['.csv'];

  ngAfterViewInit(): void {
    this.openDrawer();
  }

  /**
   * This function is used to open the dialog
   */
  public openDrawer() {
    this.drawer?.nativeElement.showModal();
  }

  /**
   * This function is used to close the drawer
   */
  closeDrawer() {
    this.drawer.nativeElement.classList.add('hide');

    setTimeout(() => {
      this.drawer.nativeElement.classList.remove('hide');
      this.drawer.nativeElement.close();
      this.drawerIsClosing.emit();
    }, 300);
  }

  /**
   * This function is used to handle the create button click event
   */
  onCreateButtonClick() {
    this.closeDrawer();
    this.createResource.emit();
  }

  /**
   * This function is used to handle the drag end event
   */
  onDragEnd() {
    if (this.isElementTouchingBottom()) {
      this.closeDrawer();
    }
  }

  /**
   * This function is used to determine if the draggable element (grabber) is touching the bottom of the screen
   * @returns {boolean} true if the element is touching the bottom of the screen, false otherwise
   */
  isElementTouchingBottom(): boolean {
    const grabberElement = this.grabber.nativeElement;
    const drawerElement = this.drawer.nativeElement;

    const grabberRect = grabberElement.getBoundingClientRect();
    const drawerRect = drawerElement.getBoundingClientRect();
    const viewportHeight =
      window.innerHeight || document.documentElement.clientHeight;

    // Calculate the position of the grabber element relative to the viewport
    const grabberBottom = grabberRect.top + grabberRect.height;

    // Ensure the grabber is inside the dialog
    if (grabberBottom > drawerRect.top && grabberBottom < drawerRect.bottom) {
      return grabberBottom >= viewportHeight;
    }

    return false;
  }

  @HostListener('click', ['$event'])
  onDrawerClick(event: MouseEvent) {
    const { clientX, clientY } = event;
    const { left, right, top, bottom } =
      this.drawer.nativeElement.getBoundingClientRect();

    // if the user clicked outside the drawer
    if (
      clientX < left ||
      clientX > right ||
      clientY < top ||
      clientY > bottom
    ) {
      this.onOutsideDrawerClick();
    }
  }

  /**
   * This function is used to close the drawer when the user clicks outside the drawer
   * Basically when the user clicks on the overlays
   */
  onOutsideDrawerClick() {
    this.closeDrawer();
  }

  /**
   * This function is used to handle the import file click event
   * It triggers the file input click event
   */
  onImportFileClick() {
    this.fileInput.nativeElement.click();
  }

  /**
   * @description
   * This function is called when the user selects files to upload.
   * It iterates over the files and starts the upload process for
   * each file.
   * @returns void (emits an uploadInputEvent to parent component)
   */
  fileUpload() {
    const files = new FormData();
    const fileList = this.fileInput.nativeElement.files;

    if (fileList) {
      Array.prototype.forEach.call(fileList, (file) => {
        files.append('file', file, file.name);
      });
      this.uploadInputEvent.emit(files);
    }
  }

  /**
   * This function is used to constrain the position of the draggable element
   * So that element should be blocked at the bottom of the screen
   * to reproduce the behavior of a mobile drawer
   * @param {Point} userPointerPosition the current user pointer position
   * @param {DragRef} dragRef the dragger reference
   * @param {DOMRect} _ the DOMRect of the element (NOT USED, but needed for the method signature)
   * @param {Point} pickupPositionInElement the position of the user start dragging the element
   * @returns {Point} the new position of the draggable element with the y position constrained
   */
  constrainPosition = (
    userPointerPosition: Point,
    dragRef: DragRef,
    _: DOMRect,
    pickupPositionInElement: Point
  ): Point => {
    const maxY = window.innerHeight - this.DRAWER_MAX_SIZE; // Maximum y position

    // Define new free drag position
    // with x without the pickup position
    // to avoid the element to jump on the x axis on user start dragging
    dragRef.setFreeDragPosition({
      x: -pickupPositionInElement.x,
      y: 0,
    });

    // Determine the max y-coordinate for the element
    if (userPointerPosition.y < maxY) {
      return { x: 0, y: maxY };
    }

    // Return the initial position
    return { x: 0, y: userPointerPosition.y };
  };
}
