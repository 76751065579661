import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { popoverOptionType, statusColorEnum } from '@common/ng-design-system';
import { TranslateService } from '@ngx-translate/core';

import { combineLatest } from 'rxjs';

import { appRoutesEnum, resourceTabType } from '../../../app-routing.module';
import { controlPanelCardDataUsersInterface } from '../../map-control-panel/map-control-panel.component.d';
import { controlPanelUsersStatusEnum } from '../../map-control-panel/map-control-panel.enum';

@Component({
  selector: 'astus-map-list-user-card',
  templateUrl: './map-list-user-card.component.html',
  styleUrls: ['./map-list-user-card.component.scss'],
})
export class MapListUserCardComponent implements OnInit {
  @Input() user: controlPanelCardDataUsersInterface | undefined = undefined;
  @Input() isFirst = false;
  @Input() isSelected = false;
  @Input() hideAssignButton = false;

  moreOptions: popoverOptionType[] = [];
  moreOptionEditTag = 'edit-user';

  @Output() cardClick = new EventEmitter();
  @Output() assignEvent = new EventEmitter();
  @Output() hoveredEvent = new EventEmitter();

  statusColorEnum = statusColorEnum;
  statusTranslations: { [key: string]: string } = {};

  constructor(
    private translateService: TranslateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.translateService.stream('CARDS.STATUS.ON_DUTY'),
      this.translateService.stream('CARDS.STATUS.OFF_DUTY'),
      this.translateService.stream('CARDS.MODIFY_USER'),
    ]).subscribe(([onDuty, offDuty, modifyUser]) => {
      (this.statusTranslations[controlPanelUsersStatusEnum.ON_WAY] = onDuty),
        (this.statusTranslations[controlPanelUsersStatusEnum.ON_DUTY] = onDuty),
        (this.statusTranslations[controlPanelUsersStatusEnum.OFF_DUTY] =
          offDuty);

      this.moreOptions = [
        {
          id: `${this.moreOptionEditTag}-${this.user?.id}`,
          value: modifyUser,
        },
      ];
    });
  }

  get statusColor(): statusColorEnum {
    if (this.user?.userStatus === controlPanelUsersStatusEnum.OFF_DUTY) {
      return statusColorEnum.NEUTRAL;
    } else if (this.user?.userStatus === controlPanelUsersStatusEnum.ON_DUTY) {
      return statusColorEnum.DANGER;
    } else {
      return statusColorEnum.SUCCESS;
    }
  }

  get statusText(): string {
    return this.statusTranslations[this.user?.userStatus ?? ''] ?? '';
  }

  /**
   * @description Handles the popover option click
   * Checks for the "Modify user" option and navigates to the user edit form in Resources
   * @param {popoverOptionType} option
   */
  handleMoreOptionClick(option: popoverOptionType) {
    if (option.id?.indexOf(this.moreOptionEditTag) !== -1) {
      const url = `${appRoutesEnum.RESOURCES}/${resourceTabType.DRIVERS}/${this.user?.id}`;
      this.router.navigate([url]);
    }
  }
}
