<div [ngClass]="{ 'is-skeleton': isLoading }">
  <div class="header">
    <ad-icon
      leftIcon
      glyph="chevron_left"
      [color]="isLoading ? 'neutral-200' : 'neutral-700'"
      class="go-back-icon"
      svgClass="ad-icon-svg"
      (click)="onBackClick()"
    ></ad-icon>
    <ad-card-info
      tooltipPosition="left"
      [isSkeleton]="isLoading"
      [title]="title || ''"
      [titleSmall]="titleSmall || ''"
      [subtitle]="subtitle || ''"
      [infoItems]="[
        { icon: 'schedule', text: infoSchedule || '' },
        { icon: 'users-filled', text: infoUser || '' }
      ]"
      [hideAvatars]="true"
    ></ad-card-info>
    <div *ngIf="moreOptions.length" class="header-more-info-popover" moreInfo>
      <div class="header-more-info-popover-container">
        <ad-popover
          [(display)]="showMoreOptions"
          [options]="moreOptions"
          class="header-more-info-popover-component"
          (selectOption)="optionSelected()"
          position="bottom"
        >
          <button
            (click)="showMoreOptions = !showMoreOptions"
            class="header-more-info-popover-container"
          >
            <ad-icon
              (click)="moreOptionsClick()"
              [color]="isLoading ? 'neutral-200' : 'neutral-500'"
              glyph="more-vertical"
              class="more-options-icon"
              [ngClass]="isLoading ? 'is-loading' : 'is-not-loading'"
              svgClass="ad-icon-svg"
            ></ad-icon>
          </button>
        </ad-popover>
      </div>
    </div>
  </div>
  <ng-container *ngIf="isLoading; else infoContainer">
    <div class="info-container-skeleton">
      <!-- Info container -->
      <div class="info-container-skeleton">
        <!-- First text info -->
        <div class="info-container-skeleton-1-container">
          <div class="info-container-skeleton-1"></div>
          <div class="info-container-skeleton-2"></div>
        </div>
        <!-- Second text info -->
        <div class="info-container-skeleton-2-container">
          <div class="info-container-skeleton-3"></div>
          <div class="info-container-skeleton-4"></div>
          <div class="info-container-skeleton-5"></div>
        </div>
        <!-- Third text info -->
        <div class="info-container-skeleton-3-container">
          <div class="info-container-skeleton-6"></div>
          <div class="info-container-skeleton-7"></div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #infoContainer>
    <div
      class="info-container"
      *ngIf="stopNotes || stopStatusDuration || stopStatusTime || stopStudents"
    >
      <div
        class="info-container__item"
        *ngIf="stopStatusDuration || stopStatusTime"
      >
        <div class="info-container__title text-footer">
          {{ 'CARDS.STOP_INFOBOX.STATUS' | translate | uppercase }}
        </div>
        <ad-status-component [color]="statusColor">
          <span *ngIf="stopStatusDuration">
            {{ 'CARDS.MADE_IN' | translate }}
            {{ stopStatusDuration }}
          </span>
          <span class="info-container__status-stop" *ngIf="stopStatusTime">
            {{ 'CARDS.STOP' | translate }}
            {{ stopStatusTime }}
          </span>
        </ad-status-component>
      </div>
      <div class="info-container__item" *ngIf="stopStudents">
        <div class="info-container__title text-footer">
          {{ 'CARDS.STOP_INFOBOX.STUDENTS' | translate | uppercase }}
        </div>
        <div class="text-caption">
          {{ studentsFormatted }}
        </div>
      </div>
      <div class="info-container__item" *ngIf="stopNotes">
        <div class="info-container__title text-footer">
          {{ 'CARDS.STOP_INFOBOX.NOTES' | translate | uppercase }}
        </div>
        <div class="text-caption">
          {{ stopNotes }}
        </div>
      </div>
    </div>
  </ng-template>
</div>
