import { createFeatureSelector, createSelector } from '@ngrx/store';

import { resourceReducerInterface } from './resource';
import { resourceFeatureKey } from './resource.reducer';

export const resourceState =
  createFeatureSelector<resourceReducerInterface>(resourceFeatureKey);

export const getVehicleInfoboxData = createSelector(
  resourceState,
  (state) => state.vehicles.infobox.vehicle
);

export const getVehicleListData = createSelector(
  resourceState,
  (state) => state.vehicles.list
);

export const getUserListData = createSelector(resourceState, (state) =>
  state.users.list.filter((u) => !u.isDisabled)
);

export const getUserInfoboxData = createSelector(
  resourceState,
  (state) => state.users.infobox.user
);

export const getVehicleById = (id: string | number) =>
  createSelector(resourceState, (state) =>
    state.vehicles.list.find((vehicle) => vehicle.id === id)
  );

export const getInfoboxState = createSelector(
  resourceState,
  (state) => state.isInfoBoxOpen
);

export const getVehicleError = createSelector(
  resourceState,
  (state) => state.vehicles.error
);

export const getCircuitListData = createSelector(
  resourceState,
  (state) => state.circuits.list
);

export const getCircuitPagination = createSelector(
  resourceState,
  (state) => state.circuits.pagination
);

export const getCircuitError = createSelector(
  resourceState,
  (state) => state.circuits.error
);

export const getVehicleFormDropdowns = createSelector(
  resourceState,
  (state) => state.vehicles.infobox.vehicleFormDropdowns
);
