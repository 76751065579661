<astus-settings-button-layout
  (saveEmitter)="saveForm()"
  (cancelEmitter)="resetForm()"
  [processState]="processState"
  (resetProcessState)="resetProcessState()"
  [enableCancel]="formGroup.invalid"
  [disabled]="
    formGroup.invalid ||
    (!formChanged && formGroup.pristine && processState === buttonEnums.READY)
  "
>
  <form class="inputs-wrapper" [formGroup]="formGroup">
    <h1 class="text-p3--semibold">
      {{ 'SETTINGS.ENTERPRISE_PROFILE.TITLE' | translate }}
    </h1>
    <div class="input-list">
      <ad-input
        class="input half"
        label="{{ 'SETTINGS.ENTERPRISE_PROFILE.NUMBER' | translate }}"
        [copyToClipboard]="true"
        (copyToClipboardEvent)="copyAlert()"
        [value]="enterpriseProfile.generalInfo.enterpriseNumber"
        formControlName="enterpriseNumber"
        [errors]="formGroup.get('enterpriseNumber')?.errors!"
      ></ad-input>
      <ad-input
        class="input half"
        label="{{ 'SETTINGS.ENTERPRISE_PROFILE.NAME' | translate }}"
        [value]="enterpriseProfile.generalInfo.enterpriseName"
        formControlName="enterpriseName"
        [errors]="formGroup.get('enterpriseName')?.errors!"
      ></ad-input>

      <ad-input
        class="input third"
        label="{{ 'SETTINGS.ENTERPRISE_PROFILE.ADDRESS' | translate }}"
        placeholder="1234 R. St-Jacques, Longueuil, Québec, Canada"
        [value]="enterpriseProfile.generalInfo.enterpriseAddress"
        formControlName="enterpriseAddress"
        [errors]="formGroup.get('enterpriseAddress')?.errors!"
      ></ad-input>

      <div class="input third">
        <ad-dropdown
          label="{{ 'SETTINGS.ENTERPRISE_PROFILE.TERMINAL' | translate }}"
          [items]="terminals"
          [value]="enterpriseProfile.generalInfo.enterpriseTerminalSelection"
          formControlName="enterpriseTerminalSelection"
          [error]="
            formGroup.get('enterpriseTerminalSelection')?.errors! ? true : false
          "
        >
        </ad-dropdown>
      </div>

      <div class="input third">
        <ad-dropdown
          label="{{ 'SETTINGS.ENTERPRISE_PROFILE.TIMEZONE' | translate }}"
          [required]="true"
          [items]="timezones"
          [value]="enterpriseProfile.generalInfo.enterpriseTimezoneSelection"
          formControlName="enterpriseTimezoneSelection"
          [error]="
            formGroup.get('enterpriseTimezoneSelection')?.errors! ? true : false
          "
        >
        </ad-dropdown>
      </div>
      <div></div>
    </div>

    <h1 class="text-p3--semibold" *ngIf="enterpriseProfile.hasMotherCompany">
      {{ 'SETTINGS.ENTERPRISE_PROFILE.MOTHER' | translate }}
    </h1>
    <div
      *ngIf="enterpriseProfile.hasMotherCompany"
      class="input-list"
      formGroupName="motherCompany"
    >
      <ad-input
        class="input full"
        label="{{ 'SETTINGS.ENTERPRISE_PROFILE.NAME' | translate }}"
        [value]="enterpriseProfile.motherCompany.enterpriseName"
        formControlName="enterpriseName"
      ></ad-input>

      <ad-input
        class="input third"
        label="{{ 'SETTINGS.ENTERPRISE_PROFILE.ADDRESS' | translate }}"
        [value]="enterpriseProfile.motherCompany.enterpriseAddress"
        formControlName="enterpriseAddress"
      ></ad-input>

      <div class="input third">
        <ad-dropdown
          label="{{ 'SETTINGS.ENTERPRISE_PROFILE.TERMINAL' | translate }}"
          [items]="terminals"
          [value]="enterpriseProfile.motherCompany.enterpriseTerminalSelection"
          formControlName="enterpriseTerminalSelection"
        >
        </ad-dropdown>
      </div>
      <div class="input third">
        <ad-dropdown
          label="{{ 'SETTINGS.ENTERPRISE_PROFILE.TIMEZONE' | translate }}"
          [items]="timezones"
          [value]="enterpriseProfile.motherCompany.enterpriseTimezoneSelection"
          formControlName="enterpriseTimezoneSelection"
        >
        </ad-dropdown>
      </div>
    </div>
  </form>
</astus-settings-button-layout>
