import { Component, Input } from '@angular/core';

import { ColorType } from '../../common-ng-design-system.colors';

import { progressInfoStatusEnum } from './progress-info.enum';

@Component({
  selector: 'ad-progress-info-component',
  templateUrl: './progress-info.component.html',
  styleUrls: ['./progress-info.component.scss'],
})
export class ProgressInfoOrganism {
  @Input() hovered = false;

  @Input() time = '';
  @Input() percentage = 0;
  @Input() status: progressInfoStatusEnum = progressInfoStatusEnum.NORMAL;

  /**
   * @description
   * Color for the progress bar based on the data provided.
   * Progress status is used to determine the color.
   * @returns ColorType
   */
  public get progressType(): ColorType {
    switch (this.status) {
      case progressInfoStatusEnum.LOW:
      case progressInfoStatusEnum.MEDIUM:
        return 'warning';
      case progressInfoStatusEnum.HIGH:
        return 'danger';
      default:
        return 'primary';
    }
  }
}
