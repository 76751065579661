import { createActionGroup, emptyProps, props } from '@ngrx/store';

import {
  settingsProfileInterface,
  settingsRolesInterface,
  settingsReducerErrorType,
  settingsGroupInterface,
} from './settings.d';

export const settingsAPIActions = createActionGroup({
  source: 'Settings API',
  events: {
    'roles load': emptyProps(),
    'roles loaded': props<{
      rolesList: settingsRolesInterface[];
    }>(),
    'roles error': props<settingsReducerErrorType>(),
    'roles create': props<{ role: settingsRolesInterface }>(),
    'roles edit': props<{ role: settingsRolesInterface }>(),
    'role delete': props<{ id: number }>(),
    'profile loaded': props<{ profile: settingsProfileInterface }>(),
    'profile edit': props<{ profile: settingsProfileInterface }>(),
    'groups load': emptyProps(),
    'groups loaded': props<{
      groupsList: settingsGroupInterface[];
    }>(),
    'groups update': props<{ groupsList: settingsGroupInterface[] }>(),
    'groups error': props<settingsReducerErrorType>(),
  },
});
