import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  userTableInterface,
  vehicleTableInterface,
} from '@common/ng-design-system';
import { Store } from '@ngrx/store';

import { Subscription } from 'rxjs';

import { parseDataVehicle } from '../helpers/parse-data.helper';
import { ResourceUserFormModel } from '../models/user-forms-models';

import {
  getUserInfoboxData,
  getVehicleInfoboxData,
  resourceActions,
} from '../store';

import { resourceDataType } from '../table-empty-state.helpers';

import { vehicleFormInterface } from './vehicle-form/vehicle-form.component';

export enum infoboxResourceModalEnum {
  CANCEL_CREATION = 'cancel-creation',
  CANCEL_EDIT = 'cancel-edit',
  DISABLE = 'disable',
}

export interface infoboxResourceModalStateInterface {
  open: boolean;
  modalType: infoboxResourceModalEnum | null;
}
@Component({
  selector: 'astus-infobox',
  templateUrl: './infobox-resource.component.html',
  styleUrls: ['./infobox-resource.component.scss'],
})
export class InfoboxResourceComponent implements OnInit, OnDestroy {
  @Input() infoboxType: resourceDataType = 'vehicles';

  @Input() isEditing = false;

  // TODO: handling the other form type
  @Input() form:
    | FormGroup<vehicleFormInterface>
    | FormGroup<ResourceUserFormModel>
    | null = null;

  @Output() cancelClick = new EventEmitter<void>();

  @Output() saveClick = new EventEmitter<void>();

  @ViewChild('more_action_button')
  public moreActionButtonRef!: ElementRef<HTMLDivElement>;

  public infoBoxForm!: ElementRef;

  public moreActionShown = false;

  subscriptions: Subscription[] = [];

  modalOpenedState: infoboxResourceModalStateInterface = {
    open: false,
    modalType: null,
  };

  vehicleInfoboxData: vehicleTableInterface | null = null;
  userInfoboxData: userTableInterface | null = null;

  constructor(private store: Store) {}

  ngOnInit() {
    // if the infobox type is vehicle
    // get vehicle data from the store
    if (this.infoboxType === 'vehicles') {
      const vehicleSubscription = this.store
        .select(getVehicleInfoboxData)
        .subscribe((data) => {
          this.vehicleInfoboxData = data ? parseDataVehicle(data) : null;
        });
      this.subscriptions.push(vehicleSubscription);
    }

    // if the infobox type is user
    if (this.infoboxType === 'users') {
      const userSubscription = this.store
        .select(getUserInfoboxData)
        .subscribe((data) => {
          this.userInfoboxData = data;
        });
      this.subscriptions.push(userSubscription);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * The function is called when user click on the more action button
   * basically the delete a resources overlay should appear
   */
  onMoreActionClick() {
    this.moreActionShown = !this.moreActionShown;
  }

  /**
   * The function is called when user click on the overlay
   * basically the delete a resources overlay
   */
  onOverlayClick() {
    this.moreActionShown = false;
  }

  /**
   * The function is called when user click on the infobox cancel button
   */
  onCancelClick() {
    // TODO: Add the reactive form value check
    if (this.isEditing && this.form?.pristine) {
      // do not show the cancel modal creation when no modification on edit mode
      this.onConfirmCancelModalCreation();
    } else {
      if (this.form) {
        this.modalOpenedState = {
          open: true,
          modalType: this.isEditing
            ? infoboxResourceModalEnum.CANCEL_EDIT
            : infoboxResourceModalEnum.CANCEL_CREATION,
        };
      } else {
        this.onConfirmCancelModalCreation();
      }
    }
  }

  onSaveClick() {
    this.resetResourceModalState();
    this.resetInfoboxData();
    this.saveClick.emit();
    this.isEditing = false;
  }

  /**
   * Cancel the cancel modal creation
   * Should close the modal and let user on the infobox
   */
  onCancelModalCreation() {
    // reset the modal state to default
    // and let user on the infobox
    this.resetResourceModalState();
  }

  /**
   * Confirm the cancel modal creation
   * Should close the modal and close infobox
   */
  onConfirmCancelModalCreation() {
    if (this.modalOpenedState.modalType === infoboxResourceModalEnum.DISABLE) {
      if (this.infoboxType === 'users' && this.userInfoboxData) {
        this.store.dispatch(
          resourceActions.disableUser({ user: this.userInfoboxData })
        );
      }
      // handle here the other resources to delete
    }

    // reset the modal state to default
    this.resetResourceModalState();
    this.resetInfoboxData();
    // user click and validate to cancel the data creation
    this.cancelClick.emit();
  }

  /**
   * Reset the modal state to default
   */
  resetResourceModalState() {
    this.modalOpenedState = {
      open: false,
      modalType: null,
    };
  }

  /**
   * Reset the infobox data to default
   */
  resetInfoboxData() {
    this.store.dispatch(resourceActions.resetVehicleInfobox());
    this.store.dispatch(resourceActions.resetUserInfobox());
  }

  /**
   * The function is called when user click outside the delete overlay
   * it should close the delete overlay if user click outside the overlay
   * @param {Event} event - The click event
   */
  @HostListener('document:click', ['$event'])
  onClickOutsideOverlay(event: Event) {
    if (
      !this.moreActionButtonRef.nativeElement.contains(event.target as Node)
    ) {
      this.moreActionShown = false;
    }
  }

  onDisableUserClick() {
    if (this.infoboxType === 'users' && this.userInfoboxData) {
      this.openDisableUserModal();
    }
  }

  openDisableUserModal() {
    this.modalOpenedState = {
      open: true,
      modalType: infoboxResourceModalEnum.DISABLE,
    };
  }
}
