/**
 * FMS API
 * In charge of providing data from the legacy Astus FMS API to the different FMS apps
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type VehicleAlertType = 'Late' | 'Early' | 'Service Break' | 'None';

export const VehicleAlertType = {
  Late: 'Late' as VehicleAlertType,
  Early: 'Early' as VehicleAlertType,
  ServiceBreak: 'Service Break' as VehicleAlertType,
  None: 'None' as VehicleAlertType,
};
