/**
 * FMS API
 * In charge of providing data from the legacy Astus FMS API to the different FMS apps
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface GenerateReportDto {
  reportType?: GenerateReportDto.ReportTypeEnum;
  reportFormat?: string;
  vehicleIds?: Array<string>;
  startTime: string;
  endTime: string;
}
export namespace GenerateReportDto {
  export type ReportTypeEnum =
    | 'TripReport'
    | 'MovementIdleStopReport'
    | 'AccessoryReport';
  export const ReportTypeEnum = {
    TripReport: 'TripReport' as ReportTypeEnum,
    MovementIdleStopReport: 'MovementIdleStopReport' as ReportTypeEnum,
    AccessoryReport: 'AccessoryReport' as ReportTypeEnum,
  };
}
