<div *ngIf="(isAuth0Loading$ | async) || !isAppLoaded; else initializersLoaded">
  <astus-page-loader></astus-page-loader>
</div>
<ng-template #initializersLoaded>
  <div class="main-container">
    <astus-header></astus-header>
    <div class="menu-container">
      <astus-menu></astus-menu>
      <router-outlet></router-outlet>
    </div>
  </div>
  <astus-alert-system></astus-alert-system>
  <astus-alert-snackbar></astus-alert-snackbar>
</ng-template>
