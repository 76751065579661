import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
  MAP_LAT_PARAM,
  MAP_LNG_PARAM,
  MAP_ZOOM_PARAM,
} from '@fms/maps/ng-mapbox';

import { NotFoundComponent } from '../components/errors/not-found/not-found.component';

import { MapComponent } from './supervision.component';

export enum supervisionRoutesEnum {
  MAP = 'carte',
  AGENDA = 'agenda',
}

export enum supervisionInfoboxRoutesEnum {
  VEHICLE = 'vehicle',
  CIRCUIT = 'circuit',
  STOP = 'stop',
}

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: MapComponent,
        data: {
          reuseComponent: true,
        },
      },
      {
        path: ':supervisionMode',
        component: MapComponent,
        data: {
          reuseComponent: true,
        },
      },
      {
        path: ':supervisionMode/:tab',
        component: MapComponent,
        data: {
          reuseComponent: true,
        },
      },
      {
        path: ':supervisionMode/:tab/:id',
        component: MapComponent,
        data: {
          reuseComponent: true,
        },
      },
      {
        path: ':supervisionMode/:tab/:id/:route/:routeId',
        component: MapComponent,
        data: {
          reuseComponent: true,
        },
      },
      {
        path: `:supervisionMode/:tab/:id/:route/:routeId?${MAP_ZOOM_PARAM}=:zoom&${MAP_LAT_PARAM}=:lat&${MAP_LNG_PARAM}=:lng`,
        component: MapComponent,
        data: {
          reuseComponent: true,
        },
      },
      {
        path: ':supervisionMode/:tab/:id/:route/:routeId/:stop/:stopId',
        component: MapComponent,
        data: {
          reuseComponent: true,
        },
      },
      {
        path: `:supervisionMode/:tab/:id/:route/:routeId/:stop/:stopId?${MAP_ZOOM_PARAM}=:zoom&${MAP_LAT_PARAM}=:lat&${MAP_LNG_PARAM}=:lng`,
        component: MapComponent,
        data: {
          reuseComponent: true,
        },
      },
      {
        path: `:supervisionMode/:tab/:id?${MAP_ZOOM_PARAM}=:zoom&${MAP_LAT_PARAM}=:lat&${MAP_LNG_PARAM}=:lng`,
        component: MapComponent,
      },
      { path: '**', component: NotFoundComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MapRoutingModule {}
