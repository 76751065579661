<div class="canvas-container">
  <div #map id="map" class="canvas-map"></div>
  <astus-map-ui
    [isLayerDefault]="isLayerDefault"
    [isLayerSatellite]="isLayerSatellite"
    [isLayerTraffic]="isLayerTraffic"
    [isLayerBike]="isLayerBike"
    [isLayerOutdoors]="isLayerOutdoors"
    [isResetDisabled]="isResetDisabled"
    (zoomIn)="handleZoomIn()"
    (zoomOut)="handleZoomOut()"
    (resetClick)="handleReset()"
    (setDefaultLayer)="handleSetDefaultLayer()"
    (setSatelliteLayer)="handleSetSatelliteLayer()"
    (setTrafficLayer)="handleSetTrafficLayer()"
    (setBikeLayer)="handleSetBikeLayer()"
    (setOutdoorsLayer)="handleSetOutdoorsLayer()"
  ></astus-map-ui>
  <ad-loading *ngIf="showLoading" class="canvas-loading">{{
    'MAP.UI.LOADING' | translate
  }}</ad-loading>
</div>
