import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'ad-tab-widget',
  template: `<div class="main-container">
    <div class="main-content">
      <ng-content></ng-content>
    </div>
  </div>`,
  styleUrls: ['./widget-tab.component.scss'],
})
export class WidgetTabOrganism {
  @HostBinding('class') class = 'widget-tab';
}
