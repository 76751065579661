import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

import {
  compareDateRanges,
  compareDates,
  previousMonth,
  previousWeek,
  previousYear,
} from '../../helpers';

import { SelectionModeLabelType } from './calendar.d';

export type DateRangeType = 'today' | 'week' | 'month' | 'year';

export type DateFiltersType = {
  range?: DateRangeType;
  label?: string;
};

@Component({
  selector: 'ad-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarOrganism {
  constructor(private elementRef: ElementRef) {}
  @Input()
  date: Date | Date[] = new Date();

  dateRange: Date[] = [];

  @Input()
  selectionMode?: 'single' | 'range' = 'single';

  @Input()
  selectionModeLabels: SelectionModeLabelType = {
    single: 'Single',
    range: 'Date Range',
  };

  @Input()
  showSelectionModeFilters = false;

  dateRangeFilterSelection?: DateRangeType;

  @Input()
  dateRangeFilters?: DateFiltersType[] = [
    { range: 'today', label: 'Today' },
    { range: 'week', label: 'Last Week' },
    { range: 'month', label: 'Last Month' },
    { range: 'year', label: 'Last Year' },
  ];

  @Output()
  dateSelect = new EventEmitter<Date | Date[]>();

  @Output()
  hideCalendar = new EventEmitter<boolean>();

  toggleSelectionMode(mode: 'single' | 'range'): void {
    this.selectionMode = mode;
  }

  dateFilter(dateRange?: DateRangeType): void {
    if (dateRange === 'today') {
      this.selectionMode = 'single';
      this.dateRangeFilterSelection = 'today';
      this.date = new Date();
      this.dateSelect.emit(this.date);
    } else if (dateRange === 'week') {
      this.selectionMode = 'range';
      this.dateRangeFilterSelection = 'week';
      this.date = previousWeek();
      this.dateRange = this.date;
      this.dateSelect.emit(this.date);
    } else if (dateRange === 'month') {
      this.selectionMode = 'range';
      this.dateRangeFilterSelection = 'month';
      this.date = previousMonth();
      this.dateRange = this.date;
      this.dateSelect.emit(this.date);
    } else if (dateRange === 'year') {
      this.selectionMode = 'range';
      this.dateRangeFilterSelection = 'year';
      this.date = previousYear();
      this.dateRange = this.date;
      this.dateSelect.emit(this.date);
    }
  }

  /**
   * Compares the date selected to the current date range selection, and returns whether the filter chip should be active.
   *
   * @param {DateRangeType} dateFilter - the date range currently applied to the calendar.
   * @returns {boolean} - whether the date selected falls within the current date range selection.
   */
  filterSelectionState(dateFilter?: DateRangeType): boolean {
    // check if date is a range or single date
    if (Array.isArray(this.date)) {
      // checks for each filter type
      if (dateFilter === 'week') {
        const week = previousWeek();

        return compareDateRanges(this.date, week);
      }

      if (dateFilter === 'month') {
        const month = previousMonth();
        return compareDateRanges(this.date, month);
      }

      if (dateFilter === 'year') {
        const year = previousYear();
        return compareDateRanges(this.date, year);
      }
    } else if (dateFilter === 'today') {
      // return whether the date selected is today
      return compareDates(new Date(this.date), new Date());
    }
    // If date is not an array and dateFilter is not 'today', return false
    return false;
  }

  /**
   * Handles date selection, and emits our event based on selection mode.
   *
   * @param {Date} date - the date selected.
   * @returns {void}
   */
  handleDateSelect(date: Date): void {
    if (this.selectionMode === 'single') {
      this.dateSelect.emit(date);
    } else if (this.selectionMode === 'range') {
      if (this.dateRange.length === 2) {
        this.dateRange = [];
      }
      this.dateRange.push(date);
      this.date = this.dateRange;
      this.dateSelect.emit(this.date);
    }
  }

  isClickInsidePrimeNgElements(
    event: MouseEvent,
    selectors: string[]
  ): boolean {
    return selectors.some(
      (selector) =>
        (event.target as Element).closest(selector) !== null ||
        (event.target as Element).classList.contains(selector.replace('.', ''))
    );
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    const isClickedInsideComponent = this.elementRef.nativeElement.contains(
      event.target
    );
    const selectors = [
      '.p-calendar',
      '.p-datepicker-header',
      '.p-datepicker-month',
      '.p-datepicker-year',
      '.p-monthpicker',
      '.p-yearpicker',
    ];

    if (
      !isClickedInsideComponent &&
      !this.isClickInsidePrimeNgElements(event, selectors) &&
      !(this.selectionMode === 'range' && this.dateRange.length < 2)
    ) {
      this.hideCalendar.emit(true);
    } else {
      this.hideCalendar.emit(false);
    }
  }
}
