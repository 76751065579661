import { Component } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'astus-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent {
  privacyPolicyMarkdownSrc = 'assets/markdown/fr/privacy-policy.md';

  constructor(private translate: TranslateService) {
    this.privacyPolicyMarkdownSrc = `assets/markdown/${this.translate.currentLang}/privacy-policy.md`;
  }
}
