<ad-dialog
  (resetFilters)="onReset()"
  (applyFilters)="onApply()"
  (undoChanges)="undoChanges()"
  applyLabel="{{ 'CTA.APPLY' | translate }}"
  resetLabel="{{ 'CTA.RESET' | translate }}"
  [buttonApplyDisabled]="buttonApplyDisabled"
  [buttonResetDisabled]="buttonResetDisabled"
  labelContent="{{ 'FILTERS_COLUMNS.HIDE' | translate }}"
  headerTitle="{{ 'FILTERS_COLUMNS.CHOOSE_COLUMNS' | translate }}"
>
  <ng-container ngProjectAs="content-filters">
    <fieldset>
      <legend class="divider text-caption--semibold">
        {{ 'FILTERS_COLUMNS.COLUMNS' | translate }}
      </legend>
      <div class="filters">
        <astus-multi-select-dropdown
          [list]="columns"
          title="{{ 'FILTERS_COLUMNS.ALL_COLUMNS' | translate }}"
          [formGroup]="formGroup"
          [useCount]="false"
        ></astus-multi-select-dropdown>
      </div>
    </fieldset>
  </ng-container>
</ad-dialog>
