<div class="test-container">
  <astus-agenda-card [date]="'12:00pm - 1:00pm'" [title]="'École du Sous-Bois'">
  </astus-agenda-card>

  <div class="redirect-container">
    <button astus-design-button (click)="handleLogout()">Log Out</button>
    <button astus-design-button kind="tonal" (click)="tokenInterceptor()">
      Token Interceptor
    </button>
    <button astus-design-button kind="outlined" (click)="getNotFoundError()">
      Not Found
    </button>
    <button astus-design-button kind="outlined" (click)="goToMap()">
      Map view
    </button>
    <button astus-design-button kind="outlined" (click)="testAlert()">
      Test alert
    </button>
    <button astus-design-button kind="tonal" (click)="testSnackbar()">
      Test snackbar
    </button>
    <astus-vehicle-sort-filter></astus-vehicle-sort-filter>
  </div>

  <div class="switch-container">
    <ad-switch
      class="hide"
      [disabled]="true"
      [checked]="true"
      iconGlyph="check"
    ></ad-switch>

    <ad-switch
      class="hide"
      [disabled]="false"
      [checked]="true"
      iconGlyph="check"
    ></ad-switch>

    <ad-switch
      class="hide"
      [disabled]="true"
      [checked]="true"
      iconGlyph="lock"
    ></ad-switch>

    <ad-switch
      class="hide"
      [disabled]="false"
      [checked]="true"
      iconGlyph="lock"
    ></ad-switch>

    <ad-switch class="hide" [disabled]="true" [checked]="true"></ad-switch>

    <ad-switch class="hide" [disabled]="false" [checked]="true"></ad-switch>
  </div>

  <div class="switch-container">
    <ad-switch
      [disabled]="true"
      [checked]="true"
      size="sm"
      iconGlyph="check"
    ></ad-switch>

    <ad-switch
      [disabled]="false"
      [checked]="true"
      size="sm"
      iconGlyph="check"
    ></ad-switch>

    <ad-switch
      [disabled]="true"
      [checked]="true"
      size="sm"
      iconGlyph="lock"
    ></ad-switch>

    <ad-switch
      [disabled]="false"
      [checked]="true"
      size="sm"
      iconGlyph="lock"
    ></ad-switch>

    <ad-switch [disabled]="true" [checked]="true" size="sm"></ad-switch>

    <ad-switch [disabled]="false" [checked]="true" size="sm"></ad-switch>
  </div>

  <ad-tab-widget>
    <ad-widget label="Circuits" [info]="2400"></ad-widget>
    <ad-widget label="Véhicules" [info]="120" status="1 problème"></ad-widget>
    <ad-widget
      label="Conducteurs"
      [info]="96"
      fontIcon="warning"
      status="2 problèmes"
    ></ad-widget>
  </ad-tab-widget>
  <p>A distance of {{ 100 | distanceFormat }}</p>
  <p>He made {{ '69' | distanceFormat : true }} whole kilometers!</p>
  <p>
    In metric it's
    {{ '42' | distanceFormat : false : 2 : unitSystemEnum.METRIC }} and in
    imperial it's
    {{ 42 | distanceFormat : false : 2 : unitSystemEnum.IMPERIAL }}
  </p>

  <div>
    <p>
      Let's show the date "2034-10-28" with default dateFormat:
      {{ '2034-10-28' | dateFormat }}
    </p>
    <p>
      Now let's force it with DD/MM/YYYY":
      {{ '2034-10-28' | dateFormat : dateFormatEnum['DD/MM/YYYY'] }}
      , also with "MM/DD/YYYY":
      {{ '2034-10-28' | dateFormat : dateFormatEnum['MM/DD/YYYY'] }}
      and finally "YYYY/MM/DD":
      {{ '2034-10-28' | dateFormat : dateFormatEnum['YYYY/MM/DD'] }}
    </p>
    <p>What about invalide date? {{ '28-10-2023' | dateFormat }}</p>

    <p>A distance of {{ 100 | distanceFormat }}</p>
    <p>He made {{ '69' | distanceFormat : true }} whole kilometers!</p>
    <p>
      In metric it's
      {{ '42' | distanceFormat : false : 2 : unitSystemEnum.METRIC }} and in
      imperial it's
      {{ 42 | distanceFormat : false : 2 : unitSystemEnum.IMPERIAL }}
    </p>
  </div>

  <form style="display: flex; flex-direction: column" [formGroup]="testForm">
    <ad-input
      label="Test Input"
      formControlName="testInput"
      [errors]="testForm.controls['testInput'].errors"
      [errorMessage]="{ required: 'This field is required' }"
      [value]="testForm.controls['testInput'].value"
    >
      <ad-tooltip
        leading-content
        [text]="'Test Tooltip'"
        position="right"
        color="neutral"
      >
        <ad-icon glyph="reset"></ad-icon>
      </ad-tooltip>
      <ad-icon trailing-content glyph="search"></ad-icon>
    </ad-input>
    {{ testForm.controls['testInput'].value }}
    <ad-radio
      value="111"
      text="text1"
      name="test-group"
      formControlName="testRadio"
      [selectedValue]="testForm.controls['testRadio'].value"
    ></ad-radio>
    <ad-radio
      value="value2"
      text="text2"
      name="test-group"
      formControlName="testRadio"
      [selectedValue]="testForm.controls['testRadio'].value"
    ></ad-radio>
    {{ testForm.controls['testRadio'].value }}
    <ad-dropdown
      [items]="testDropdownValues"
      placeholder="Select a value"
      errorMessage="ERR0R!"
      [error]="testForm.controls['testDropdown'].hasError('required')"
      formControlName="testDropdown"
    ></ad-dropdown>
    {{ testForm.controls['testDropdown'].value }}
  </form>

  <astus-date-and-time-fields [label]="'Start'"></astus-date-and-time-fields>

  <ad-calendar
    [date]="date"
    [selectionModeLabels]="{ single: 'Date unique', range: 'Période' }"
    [dateRangeFilters]="dateRangeFilter"
  ></ad-calendar>

  <br />
</div>
