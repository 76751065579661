import { createReducer, on } from '@ngrx/store';

import { UploadStateInterface } from './upload';
import { addFileName, setUploadProgress, resetUpload } from './upload.action';

export const uploadFeatureKey = 'uploadFeature';

const initialState: UploadStateInterface = {
  fileNames: [],
  uploads: [],
};

export const uploadReducer = createReducer<UploadStateInterface>(
  initialState,
  on(addFileName, (state, { fileName }) => {
    return { ...state, fileNames: [...state.fileNames, fileName] };
  }),
  on(setUploadProgress, (state, { fileName, progress }) => {
    const updatedUploads = state.uploads.map((upload) =>
      upload.filename === fileName ? { ...upload, progress } : upload
    );
    return { ...state, uploads: updatedUploads };
  }),
  on(resetUpload, (state, { fileName }) => {
    const updatedUploads = state.uploads.filter(
      (upload) => upload.filename !== fileName
    );
    return { ...state, uploads: updatedUploads };
  })
);
