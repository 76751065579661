<div class="group-input-container">
  <span class="text-caption--semibold label-text">{{ label }}</span>

  <div
    class="input-container"
    [ngClass]="{
    'has-chips': resourceGroup.length,
  }"
  >
    <div class="input-chips">
      <ad-chip
        *ngFor="let item of resourceGroup"
        color="primary"
        [id]="item.id"
        [selected]="true"
        [trailingIcon]="'close'"
        (trailingIconEvent)="onChipRemove($event)"
      >
        <span class="chip">
          {{ item.name | truncateText : '...' : 30 }}
        </span>
      </ad-chip>
      <input
        #groupInput
        [ngClass]="{ 'has-chips': resourceGroup.length }"
        class="text-p3"
        [placeholder]="resourceGroup.length === 0 ? label : ''"
        (keyup.enter)="onKeyPress($event)"
        (keydown.Tab)="onKeyPress($event)"
      />
    </div>
  </div>
</div>
