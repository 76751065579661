import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'astus-map-layer-selector-button',
  templateUrl: './map-layer-selector-button.component.html',
  styleUrls: ['./map-layer-selector-button.component.scss'],
})
export class MapLayerSelectorButtonComponent {
  @Input() imageSrc = '';
  @Input() textValue = '';
  @Input() isSelected = false;
  @Input() iconExtraClass = '';

  @Output() isClicked = new EventEmitter();
}
