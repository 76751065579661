<div class="astus-monitoring" [ngClass]="{ 'is-skeleton': isLoading }">
  <div class="header">
    <ad-icon
      leftIcon
      glyph="chevron_left"
      [color]="isLoading ? 'neutral-200' : 'neutral-700'"
      class="go-back-icon"
      svgClass="ad-icon-svg pointer"
      (click)="onBackClick()"
    ></ad-icon>
    <ad-card-info
      tooltipPosition="left"
      class="pe-3"
      [isSkeleton]="isLoading"
      [title]="title || ''"
      [titleSmall]="titleSmall || ''"
      [subtitle]="subtitle || ''"
      [infoItems]="[
        { icon: 'users-filled', text: infoUser || '' },
        { icon: 'location-filled', text: infoLocation || '' }
      ]"
      [hideAvatars]="true"
    >
    </ad-card-info>
    <div *ngIf="moreOptions.length" class="header-more-info-popover" moreInfo>
      <div class="header-more-info-popover-container">
        <ad-popover
          [(display)]="showMoreOptions"
          [options]="moreOptions"
          class="header-more-info-popover-component"
          (selectOption)="optionSelected()"
          position="bottom"
        >
          <button
            (click)="showMoreOptions = !showMoreOptions"
            class="header-more-info-popover-container"
          >
            <ad-icon
              (click)="moreOptionsClick()"
              [color]="isLoading ? 'neutral-200' : 'neutral-500'"
              class="more-options-icon"
              glyph="more-vertical"
              [ngClass]="isLoading ? 'is-loading' : 'is-not-loading'"
              svgClass="ad-icon-svg"
            ></ad-icon>
          </button>
        </ad-popover>
      </div>
    </div>
  </div>
  <ng-container *ngIf="isLoading; else infoContainer">
    <div class="info-container-skeleton">
      <!-- Header container -->
      <div class="info-container-skeleton-header">
        <div class="info-container-skeleton-header-1"></div>
        <div class="info-container-skeleton-header-2"></div>
        <div class="info-container-skeleton-header-3"></div>
        <div class="info-container-skeleton-header-4"></div>
      </div>
      <!-- Info container -->
      <div
        *ngFor="let _ of [1, 2, 3, 4, 5]; let last = last"
        class="info-container-skeleton-information"
      >
        <div class="info-container-skeleton-information-content">
          <div
            class="info-container-skeleton-information-content-header"
            [ngStyle]="{ 'z-index': _ + 1 }"
          >
            <ad-icon
              [ngStyle]="{ 'z-index': _ + 1 }"
              glyph="location-empty"
              stroke="neutral-400"
              [customSize]="20"
              color="neutral-300"
              class="info-container-icon"
              svgClass="ad-icon-svg"
            >
              <div
                iconContent
                [ngStyle]="{ 'z-index': _ + 1 }"
                class="ad-icon-content text-footer"
              ></div
            ></ad-icon>
            <div
              *ngIf="!last"
              class="ad-icon-last points"
              [ngStyle]="{ 'z-index': _ }"
            ></div>
          </div>
          <div class="skeleton-information-texts">
            <div class="skeleton-information-texts-container">
              <!-- First text info -->
              <div class="skeleton-information-texts-container-first"></div>
              <!-- Second text info -->
              <div class="skeleton-information-texts-container-second-content">
                <div
                  class="skeleton-information-texts-container-second-content-1"
                ></div>
                <div
                  class="skeleton-information-texts-container-second-content-2"
                ></div>
                <div
                  class="skeleton-information-texts-container-second-content-3"
                ></div>
                <div
                  class="skeleton-information-texts-container-second-content-4"
                ></div>
                <div
                  class="skeleton-information-texts-container-second-content-5"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="skeleton-information-texts-container-second-content-6"
          *ngIf="_ !== 5"
        >
          <div
            class="skeleton-information-texts-container-second-content-7"
          ></div>
          <div
            class="skeleton-information-texts-container-second-content-8"
          ></div>
          <div
            class="skeleton-information-texts-container-second-content-9"
          ></div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Infobox -->
  <ng-template #infoContainer>
    <div class="information-container">
      <div *ngIf="progressPercentage" class="information-container-progress">
        <ad-progress-bar-navigation
          class="progress-bar-navigation"
          [percentage]="progressPercentage"
          [color]="progressType"
        ></ad-progress-bar-navigation>
        <span class="information-container-progress-time text-caption">{{
          progressTime
        }}</span>
      </div>
      <div class="information-stops-container">
        <div
          *ngFor="let stop of stops; let i = index; let lastStop = last"
          class="information-stops-content"
        >
          <div
            class="information-stops"
            [ngClass]="{
              'information-stops-last': lastStop
            }"
            (click)="selectStop(stop)"
          >
            <div
              class="information-stops-status"
              [ngStyle]="{ 'z-index': i + 2 }"
            >
              <ad-icon
                [ngStyle]="{ 'z-index': i + 2 }"
                glyph="location-empty"
                [customSize]="20"
                [stroke]="
                  stop.stopStatus === 'done' ? 'success-600' : 'neutral-700'
                "
                [color]="
                  stop.stopStatus === 'done' ? 'success-400' : 'neutral-600'
                "
                class="info-container-icon"
                svgClass="ad-icon-svg"
              >
                <div
                  iconContent
                  [ngStyle]="{ 'z-index': i + 2 }"
                  class="information-stops-icon-content text-footer"
                >
                  {{ i + 1 }}
                </div></ad-icon
              >
              <div
                *ngIf="!lastStop"
                class="points information-stops-status-not-last"
                [ngStyle]="{ 'z-index': i + 1 }"
              ></div>
            </div>
            <ad-card-info
              class="information-stops-card-info"
              [title]="stop.title || ''"
              [titleSmall]="stop.title2 || ''"
              [subtitle]="stop.subtitle || ''"
              [infoItems]="[
                { icon: 'schedule', text: stop.textInfo || '' },
                { icon: 'users-filled', text: stop.textInfo2 || '' }
              ]"
              [hideAvatars]="true"
            ></ad-card-info>
          </div>
          <div
            class="information-stops-time-distance-container text-footer"
            *ngIf="(stop?.stopTime || stop?.stopDistance) && !lastStop"
          >
            <span class="information-stops-time">{{ stop?.stopTime }}</span>
            <div
              *ngIf="stop?.stopTime && stop?.stopDistance"
              class="information-stops-divider"
            ></div>
            <span class="information-stops-distance">{{
              stop?.stopDistance
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
