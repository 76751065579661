<!-- Drawer boundary-->
<div class="drag-boundary">
  <!-- Drawer -->
  <div
    class="drawer"
    #drawer
    cdkDrag
    [cdkDragFreeDragPosition]="dragPosition"
    cdkDragLockAxis="y"
    cdkDragBoundary=".drag-boundary"
  >
    <!-- Grabber -->
    <div class="grabber-container" cdkDragHandle>
      <div class="grabber"></div>
    </div>

    <!-- Drawer content -->
    <div
      class="drawer-content"
      (mousedown)="$event.stopPropagation()"
      (touchstart)="$event.stopPropagation()"
    >
      <ng-content select="[drawerContent]"></ng-content>
    </div>
  </div>
</div>
