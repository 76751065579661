import { animate, style, transition, trigger } from '@angular/animations';
import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  VehicleStateType,
  VehicleAlertType,
  VehicleGroupType,
  VehicleType,
  VehicleView,
} from '@fms/ng-fms-api-client';
import { Store } from '@ngrx/store';

import { Subscription, filter } from 'rxjs';

import { selectVehiclesFeature } from '../../map/store';

import {
  findDifferentKeys,
  isBooleanFromString,
  isValidKeyFrom,
  isValueInEnum,
  parseStringToBoolean,
} from './helper/vehicle-sort-filter.helper';
import { multiSelectListItemInterface } from './multi-select-dropdown/multi-select-dropdown.interface';
import {
  vehicleSortFilterValues,
  alertTypeForm,
  vehicleSortAllFields,
  filterForm,
  groupForm,
  statusForm,
  typeForm,
} from './vehicle-sort-filter';
import { vehicleSortFilterStatusEnum } from './vehicle-sort-filter.enum';

@Component({
  selector: 'astus-vehicle-sort-filter',
  templateUrl: './vehicle-sort-filter.component.html',
  styleUrls: ['./vehicle-sort-filter.component.scss'],
  animations: [
    trigger('dropdownAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-20px)' }),
        animate(
          '0.3s ease-out',
          style({ opacity: 1, transform: 'translateY(0)' })
        ),
      ]),
      transition(':leave', [
        animate(
          '0.3s ease-in',
          style({ opacity: 0, transform: 'translateY(-20px)' })
        ),
      ]),
    ]),
  ],
})
export class VehicleSortFilterComponent implements OnInit, OnDestroy {
  // Important: All these values should be false by default. If not, the onApplyFilter() method
  // will not catch the changes values that has "true" as default value whist building the query params.
  @Input() defaultValues: vehicleSortFilterValues = {
    filter: {
      severity: vehicleSortFilterStatusEnum.SEVERITYHIGHTOLOW,
    },
    status: {
      [VehicleStateType.Maintenance]: false,
      [VehicleStateType.OffDuty]: false,
      [VehicleStateType.OnDuty]: false,
      [VehicleStateType.OutOfService]: false,
    },
    alertType: {
      [VehicleAlertType.Late]: false,
      [VehicleAlertType.Early]: false,
      [VehicleAlertType.ServiceBreak]: false,
      [VehicleAlertType.None]: false,
    },
    group: {
      [VehicleGroupType.East]: false,
      [VehicleGroupType.NorthWest]: false,
      [VehicleGroupType.SouthEast]: false,
      [VehicleGroupType.West]: false,
    },
    type: {
      [VehicleType.Autobus]: false,
      [VehicleType.AutobusAdapt]: false,
      [VehicleType.AutobusScolaire]: false,
      [VehicleType.Automobile]: false,
      [VehicleType.Camion]: false,
      [VehicleType.Camion18Roues]: false,
      [VehicleType.Camionette]: false,
      [VehicleType.Coach]: false,
      [VehicleType.Fourgonette]: false,
      [VehicleType.Minibus]: false,
    },
  };

  @Output() valuesEmitter: EventEmitter<vehicleSortFilterValues> =
    new EventEmitter<vehicleSortFilterValues>();

  queryParams: { [key in keyof vehicleSortAllFields]?: string } =
    this.activatedRoute.snapshot.queryParams;

  snapshotValues: vehicleSortFilterValues = this.defaultValues;

  routerSubscription = new Subscription();

  showFilters = false;
  vehicleSortFilterStatusEnum = vehicleSortFilterStatusEnum;

  filterForm: FormGroup<filterForm> = this.fb.group({
    severity: [vehicleSortFilterStatusEnum.SEVERITYHIGHTOLOW],
  });

  statusForm = this.fb.group(this.defaultValues.status);

  alertTypeForm = this.fb.group(this.defaultValues.alertType);

  groupForm = this.fb.group(this.defaultValues.group);

  typeForm = this.fb.group(this.defaultValues.type);

  statusList: multiSelectListItemInterface[] = [
    {
      text: 'LABEL.STATUS.MAINTENANCE',
      controlName: VehicleStateType.Maintenance,
    },
    {
      text: 'LABEL.STATUS.OFF_DUTY',
      controlName: VehicleStateType.OffDuty,
    },
    {
      text: 'LABEL.STATUS.ON_DUTY',
      controlName: VehicleStateType.OnDuty,
    },
    {
      text: 'LABEL.STATUS.OUT_OF_SERVICE',
      controlName: VehicleStateType.OutOfService,
    },
  ];

  alertTypeList: multiSelectListItemInterface[] = [
    { text: 'LABEL.ALERT_TYPE.LATE', controlName: VehicleAlertType.Late },
    { text: 'LABEL.ALERT_TYPE.EARLY', controlName: VehicleAlertType.Early },
    {
      text: 'LABEL.ALERT_TYPE.SERVICE_BREAK',
      controlName: VehicleAlertType.ServiceBreak,
    },
    { text: 'LABEL.ALERT_TYPE.None', controlName: VehicleAlertType.None },
  ];

  GroupList: multiSelectListItemInterface[] = [
    { text: 'LABEL.GROUP.SOUTH_EAST', controlName: VehicleGroupType.SouthEast },
    { text: 'LABEL.GROUP.NORTH_WEST', controlName: VehicleGroupType.NorthWest },
    { text: 'LABEL.GROUP.EAST', controlName: VehicleGroupType.East },
    { text: 'LABEL.GROUP.WEST', controlName: VehicleGroupType.West },
  ];

  typeList: multiSelectListItemInterface[] = [
    { text: 'LABEL.TYPE.BUS', controlName: VehicleType.Autobus },
    { text: 'LABEL.TYPE.CAR', controlName: VehicleType.Automobile },
    { text: 'LABEL.TYPE.TRUCK', controlName: VehicleType.Camion },
    { text: 'LABEL.TYPE.VAN', controlName: VehicleType.Camionette },
    { text: 'LABEL.TYPE.18_WHEELER', controlName: VehicleType.Camion18Roues },
    { text: 'LABEL.TYPE.COACH', controlName: VehicleType.Coach },
    { text: 'LABEL.TYPE.MINIVAN', controlName: VehicleType.Fourgonette },
    { text: 'LABEL.TYPE.SCHOOL_BUS', controlName: VehicleType.AutobusScolaire },
    { text: 'LABEL.TYPE.ADAPTED_BUS', controlName: VehicleType.AutobusAdapt },
    { text: 'LABEL.TYPE.MINIBUS', controlName: VehicleType.Minibus },
  ];

  initialValueForms = {
    statusForm: { ...this.statusForm.value },
    alertTypeForm: { ...this.alertTypeForm.value },
    groupForm: { ...this.groupForm.value },
    typeForm: { ...this.typeForm.value },
    filterForm: { ...this.filterForm.value },
  };

  changedQueryParams: Partial<typeof this.initialValueForms> = {};

  filterFormSubscription = new Subscription();
  statusFormSubscription = new Subscription();
  alertTypeFormSubscription = new Subscription();
  groupFormSubscription = new Subscription();
  typeFormSubscription = new Subscription();

  vehiclesListSubscription = new Subscription();

  filterCount = 0;

  constructor(
    private elementRef: ElementRef,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.applyFiltersByUrl();

    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        // Update the query parameters when the route changes
        this.queryParams = this.activatedRoute.snapshot.queryParams;
      });

    this.statusFormSubscription = this.statusForm.valueChanges.subscribe(
      (value) => {
        this.formOnChanges(value, 'statusForm');
      }
    );
    this.alertTypeFormSubscription = this.alertTypeForm.valueChanges.subscribe(
      (value) => {
        this.formOnChanges(value, 'alertTypeForm');
      }
    );
    this.groupFormSubscription = this.groupForm.valueChanges.subscribe(
      (value) => {
        this.formOnChanges(value, 'groupForm');
      }
    );
    this.typeFormSubscription = this.typeForm.valueChanges.subscribe(
      (value) => {
        this.formOnChanges(value, 'typeForm');
      }
    );
    this.filterFormSubscription = this.filterForm.valueChanges.subscribe(
      (value) => {
        this.formOnChanges(value, 'filterForm');
      }
    );

    this.vehiclesListSubscription = this.store
      .select(selectVehiclesFeature)
      .subscribe((vehiclesRes) => {
        this.setFiltersCounters(vehiclesRes.data);
      });
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.statusFormSubscription.unsubscribe();
    this.alertTypeFormSubscription.unsubscribe();
    this.groupFormSubscription.unsubscribe();
    this.typeFormSubscription.unsubscribe();
    this.filterFormSubscription.unsubscribe();
  }

  /**
   * Handles the changes in the form values and updates the `changedQueryParams` object accordingly.
   *
   * @param value - The partial object containing the changed values of the form fields.
   * @param formName - The name of the form being changed.
   */
  formOnChanges = (
    value: Partial<{
      [key in keyof vehicleSortAllFields]:
        | boolean
        | vehicleSortFilterStatusEnum
        | null;
    }>,
    formName: keyof typeof this.initialValueForms
  ) => {
    this.changedQueryParams = {
      ...this.changedQueryParams,
      [formName]: findDifferentKeys(this.initialValueForms[formName], value),
    };
    this.countNumberOfActiveFilters();
  };

  /**
   * @description - This method is used to handle the outside click of the dialog
   */
  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    if (
      !(this.elementRef.nativeElement as HTMLElement).contains(
        event.target as Node
      ) &&
      this.showFilters
    ) {
      this.showFilters = false;
      this.applySnapshotFilters();
    }
  }

  /**
   * @description Sets the value of a form control based on the provided value.
   *
   * @param value - The value to set.
   * @param formControl - The form control to update.
   */
  private setFormValue(
    value: string,
    formControl: FormControl<boolean | vehicleSortFilterStatusEnum | null>
  ) {
    if (isBooleanFromString(value)) {
      formControl.setValue(parseStringToBoolean(value));
    } else if (isValueInEnum(value, vehicleSortFilterStatusEnum)) {
      formControl.setValue(value);
    }
  }

  /**
   * @description Applies the filters based on the query parameters in the URL.
   * This method retrieves the query parameters from the activated route snapshot
   * and updates the corresponding form controls based on the parameter values.
   */
  private applyFiltersByUrl() {
    for (const [key, value] of Object.entries(this.queryParams)) {
      if (isValidKeyFrom<keyof groupForm>(key, this.groupForm)) {
        const control = this.groupForm.controls[key];
        if (control) {
          this.setFormValue(
            value,
            control as FormControl<boolean | vehicleSortFilterStatusEnum | null>
          );
        }
      } else if (isValidKeyFrom<keyof typeForm>(key, this.typeForm)) {
        const control = this.typeForm.controls[key];
        if (control) {
          this.setFormValue(
            value,
            control as FormControl<boolean | vehicleSortFilterStatusEnum | null>
          );
        }
      } else if (isValidKeyFrom<keyof alertTypeForm>(key, this.alertTypeForm)) {
        const control = this.alertTypeForm.controls[key];
        if (control) {
          this.setFormValue(
            value,
            control as FormControl<boolean | vehicleSortFilterStatusEnum | null>
          );
        }
      } else if (isValidKeyFrom<keyof statusForm>(key, this.statusForm)) {
        const control = this.statusForm.controls[key] as FormControl<
          boolean | vehicleSortFilterStatusEnum | null
        >;
        if (control) {
          this.setFormValue(value, control);
        }
      } else if (isValidKeyFrom<keyof filterForm>(key, this.filterForm)) {
        const control = this.filterForm.controls[key] as FormControl<
          boolean | vehicleSortFilterStatusEnum | null
        >;
        if (control) {
          this.setFormValue(value, control);
        }
      }
    }

    this.countNumberOfActiveFilters();
    this.emitValues();
  }

  /**
   * @description - Toggles the filter dialog
   */
  openFilters() {
    this.showFilters = !this.showFilters;
    this.getSnapshotFiltersOnOpen();
  }

  /**
   * @description - Gets the snapshot of the filters when the dialog is opened
   */
  getSnapshotFiltersOnOpen() {
    this.snapshotValues = {
      filter: {
        severity:
          this.filterForm.get('severity')?.value ||
          vehicleSortFilterStatusEnum.SEVERITYHIGHTOLOW,
      },
      status: { ...this.statusForm.value },
      alertType: { ...this.alertTypeForm.value },
      group: { ...this.groupForm.value },
      type: { ...this.typeForm.value },
    };
  }

  /**
   * @description - Applies the snapshot filters when the dialog is closed by clicking outside
   */
  applySnapshotFilters() {
    this.filterForm.setValue({
      severity: this.snapshotValues.filter.severity,
    });

    this.statusForm.setValue(this.snapshotValues.status);
    this.alertTypeForm.setValue(this.snapshotValues.alertType);
    this.groupForm.setValue(this.snapshotValues.group);
    this.typeForm.setValue(this.snapshotValues.type);

    this.countNumberOfActiveFilters();
  }

  /**
   * @description - Resets the form values to their initial values
   */
  resetValues() {
    this.filterForm
      .get('severity')
      ?.setValue(vehicleSortFilterStatusEnum.SEVERITYHIGHTOLOW);

    Object.keys(this.statusForm.controls).forEach((controlName) => {
      this.statusForm.get(controlName)?.setValue(false);
    });

    Object.keys(this.alertTypeForm.controls).forEach((controlName) => {
      this.alertTypeForm.get(controlName)?.setValue(false);
    });

    Object.keys(this.groupForm.controls).forEach((controlName) => {
      this.groupForm.get(controlName)?.setValue(false);
    });

    Object.keys(this.typeForm.controls).forEach((controlName) => {
      this.typeForm.get(controlName)?.setValue(false);
    });

    this.onApplyFilter();
  }

  /**
   * @description Applies the filter and navigates to the current route with updated query parameters.
   */
  onApplyFilter() {
    this.router.navigate([], {
      queryParams: {
        ...this.changedQueryParams.groupForm,
        ...this.changedQueryParams.typeForm,
        ...this.changedQueryParams.alertTypeForm,
        ...this.changedQueryParams.statusForm,
        ...this.changedQueryParams.filterForm,
      },
    });

    this.emitValues();
    this.showFilters = false;
    this.countNumberOfActiveFilters();
  }

  /**
   * @description - Emits the values of the filters
   */
  emitValues() {
    this.valuesEmitter.emit({
      filter: {
        severity:
          this.filterForm.get('severity')?.value ||
          this.vehicleSortFilterStatusEnum.SEVERITYHIGHTOLOW,
      },
      status: {
        [VehicleStateType.Maintenance]:
          this.statusForm.get([VehicleStateType.Maintenance])?.value || false,
        [VehicleStateType.OffDuty]:
          this.statusForm.get([VehicleStateType.OffDuty])?.value || false,
        [VehicleStateType.OnDuty]:
          this.statusForm.get([VehicleStateType.OnDuty])?.value || false,
        [VehicleStateType.OutOfService]:
          this.statusForm.get([VehicleStateType.OutOfService])?.value || false,
      },
      alertType: {
        [VehicleAlertType.Late]:
          this.alertTypeForm.get([VehicleAlertType.Late])?.value || false,
        [VehicleAlertType.Early]:
          this.alertTypeForm.get([VehicleAlertType.Early])?.value || false,
        [VehicleAlertType.ServiceBreak]:
          this.alertTypeForm.get([VehicleAlertType.ServiceBreak])?.value ||
          false,
        [VehicleAlertType.None]:
          this.alertTypeForm.get([VehicleAlertType.None])?.value || false,
      },
      group: {
        [VehicleGroupType.East]:
          this.groupForm.get([VehicleGroupType.East])?.value || false,
        [VehicleGroupType.NorthWest]:
          this.groupForm.get([VehicleGroupType.NorthWest])?.value || false,
        [VehicleGroupType.SouthEast]:
          this.groupForm.get([VehicleGroupType.SouthEast])?.value || false,
        [VehicleGroupType.West]:
          this.groupForm.get([VehicleGroupType.West])?.value || false,
      },
      type: {
        [VehicleType.Autobus]:
          this.typeForm.get([VehicleType.Autobus])?.value || false,
        [VehicleType.AutobusAdapt]:
          this.typeForm.get([VehicleType.AutobusAdapt])?.value || false,
        [VehicleType.AutobusScolaire]:
          this.typeForm.get([VehicleType.AutobusScolaire])?.value || false,
        [VehicleType.Automobile]:
          this.typeForm.get([VehicleType.Automobile])?.value || false,
        [VehicleType.Camion]:
          this.typeForm.get([VehicleType.Camion])?.value || false,
        [VehicleType.Camion18Roues]:
          this.typeForm.get([VehicleType.Camion18Roues])?.value || false,
        [VehicleType.Camionette]:
          this.typeForm.get([VehicleType.Camionette])?.value || false,
        [VehicleType.Coach]:
          this.typeForm.get([VehicleType.Coach])?.value || false,
        [VehicleType.Fourgonette]:
          this.typeForm.get([VehicleType.Fourgonette])?.value || false,
        [VehicleType.Minibus]:
          this.typeForm.get([VehicleType.Minibus])?.value || false,
      },
    });
  }

  /**
   * @description - Counts the number of active filters
   */
  countNumberOfActiveFilters() {
    this.filterCount = 0;

    [
      this.statusForm,
      this.alertTypeForm,
      this.groupForm,
      this.typeForm,
    ].forEach((form) => {
      for (const field in form.controls) {
        if (form.get(field)?.value) {
          this.filterCount++;
        }
      }
    });
  }

  /**
   * @description - Sets the counters for each filter
   * @param vehicles - The list of vehicles to filter
   */
  setFiltersCounters(vehicles: VehicleView[]) {
    this.typeList.forEach((type) => {
      const typeCount = vehicles.filter(
        (vehicle) => vehicle.vehicleType === type.controlName
      ).length;
      type.count = typeCount;
    });

    this.statusList.forEach((status) => {
      const statusCount = vehicles.filter(
        (vehicle) => vehicle.state === status.controlName
      ).length;
      status.count = statusCount;
    });

    this.alertTypeList.forEach((alertType) => {
      const alertTypeCount = vehicles.filter(
        (vehicle) => vehicle.alertType === alertType.controlName
      ).length;
      alertType.count = alertTypeCount;
    });

    this.GroupList.forEach((group) => {
      const groupCount = vehicles.filter(
        (vehicle) => vehicle.group === group.controlName
      ).length;
      group.count = groupCount;
    });
  }
}
