<div class="agenda-item-container">
  <span
    class="item-status-element"
    [ngClass]="{
      inactive:
        status === vehicleState.OffDuty || status === vehicleState.Maintenance,
      active: status === vehicleState.OnDuty,
      broke: status === vehicleState.OutOfService
    }"
  ></span>
  <div class="item-title-element text-caption--semibold">{{ title }}</div>
</div>
