import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'astus-map-layer-selector',
  templateUrl: './map-layer-selector.component.html',
  styleUrls: ['./map-layer-selector.component.scss'],
})
export class MapLayerSelectorComponent {
  @Input() isLayerDefault = true;
  @Input() isLayerSatellite = false;
  @Input() isLayerTraffic = false;
  @Input() isLayerBike = false;
  @Input() isLayerOutdoors = false;

  @Output() setDefaultLayer = new EventEmitter();
  @Output() setSatelliteLayer = new EventEmitter();
  @Output() setTrafficLayer = new EventEmitter();
  @Output() setBikeLayer = new EventEmitter();
  @Output() setOutdoorsLayer = new EventEmitter();
  @Output() closeClicked = new EventEmitter();

  @Input() isShown = false;
}
