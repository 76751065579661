import { Component, HostListener, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  Data,
  NavigationEnd,
  PRIMARY_OUTLET,
  Router,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap } from 'rxjs';

import { menuActions, showMenuFeature } from '../../store';

import { resourcesRouteEnum } from './resources-header/resources-header.enum';

@Component({
  selector: 'astus-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  route = '';
  showMenu!: boolean;
  isMobile = window.innerWidth < 600;
  pageTitle = '';
  termsConditionsTitle = '';
  settingsTitle = '';
  isRouteResources = false;

  noMenuRoutes = [
    'equipment-warranty',
    'privacy-policy',
    'license-agreement',
    'settings',
  ];

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private store: Store
  ) {
    this.store.select(showMenuFeature).subscribe((showMenu) => {
      this.showMenu = showMenu;
    });
  }

  ngOnInit(): void {
    this.route = this.router.url;
    this.isRouteResources = this.route.includes(resourcesRouteEnum.RESOURCES);
    this.subscribeToRouteChangeEvents();
  }

  private setTitleFromRouteData(routeData: Data) {
    this.pageTitle = routeData && routeData['title'] ? routeData['title'] : '';
  }

  private setTermsConditionsFromRouteData(routeData: Data) {
    this.termsConditionsTitle =
      routeData && routeData['terms-conditions']
        ? routeData['terms-conditions']
        : '';
  }

  private setSettingsTitleFromRouteData(routeData: Data) {
    if (routeData && routeData['settings-title'] && this.isMobile) {
      this.settingsTitle = routeData['settings-title'];
    } else if (routeData && routeData['settings-title'] && !this.isMobile) {
      this.settingsTitle = 'MENU.LABELS.SETTINGS';
    } else {
      this.settingsTitle = '';
    }
  }

  private getLatestChild(route: ActivatedRoute) {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  private subscribeToRouteChangeEvents() {
    // Set initial title
    const latestRoute = this.getLatestChild(this.activeRoute);
    if (latestRoute) {
      latestRoute.data.subscribe((data) => {
        this.setTitleFromRouteData(data);
        this.setSettingsTitleFromRouteData(data);
        this.setTermsConditionsFromRouteData(data);
      });
    }
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activeRoute),
        map((route) => this.getLatestChild(route)),
        filter((route) => route.outlet === PRIMARY_OUTLET),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        this.setTitleFromRouteData(event);
        this.setSettingsTitleFromRouteData(event);
        this.setTermsConditionsFromRouteData(event);

        this.route = this.router.url;
        this.isRouteResources = this.route.includes(
          resourcesRouteEnum.RESOURCES
        );
      });
  }

  toggleMenu(showMenu: boolean) {
    this.store.dispatch(menuActions.toggleMenu({ showMenu }));
  }

  public get showTitle(): boolean {
    if (this.pageTitle.length || this.settingsTitle.length) {
      if (this.isMobile && this.showMenu) return false;
      return true;
    }
    return false;
  }

  public get displayMenu() {
    return !this.noMenuRoutes.filter((route) => this.route === '/' + route)
      .length;
  }
  @HostListener('window:resize')
  onResize() {
    this.isMobile = window.innerWidth < 600;

    const latestRoute = this.getLatestChild(this.activeRoute);
    if (latestRoute) {
      latestRoute.data.subscribe((data) => {
        this.setSettingsTitleFromRouteData(data);
      });
    }
  }
}
