import { Component, Input } from '@angular/core';

import { statusColorEnum } from './status.enum';

@Component({
  selector: 'ad-status-component',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusOrganism {
  @Input() color: statusColorEnum = statusColorEnum.NEUTRAL;
}
