import { Clipboard } from '@angular/cdk/clipboard';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  tableHeadersType,
  tablePropsInterface,
  vehicleTableInterface as adsVehicleTableInterface,
  IconAtomGlyphType,
  tableRowEventType,
  userTableInterface as adsUserTableInterface,
} from '@common/ng-design-system';

import { SortingHelper } from '@common/ng-design-system';
import { LaunchDarklyService } from '@common/ts-feature-flag';
import { raise } from '@common/utils';
import {
  UpsertVehicleDto,
  VehicleDto,
  CircuitView,
} from '@fms/ng-fms-api-client';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import classNames from 'classnames';
import { Subscription, combineLatest, filter } from 'rxjs';

import { appRoutesEnum, resourceTabType } from '../app-routing.module';
import { DateFormatPipe } from '../pipes/dateformat/dateformat.pipe';
import { DistancePipe } from '../pipes/distance/distance.pipe';

import { HoursFormatPipe } from '../pipes/hours-format/hours-format.pipe';
import {
  alertActions,
  getUserResourcesVehiclesDataTableColumns,
} from '../store';

import { formatUserFields } from './helpers';

import { parseDataVehicle } from './helpers/parse-data.helper';
import { vehicleFormInterface } from './infobox-resource/vehicle-form/vehicle-form.component';
import {
  ResourceUserAndDriverModel,
  ResourceUserFormModel,
} from './models/user-forms-models';

import {
  circuitTableInterface,
  driverTableInterface,
  vehicleTableInterface,
} from './resources';
import {
  getCircuitError,
  getCircuitListData,
  getInfoboxState,
  getUserListData,
  getVehicleError,
  getVehicleListData,
  resourceActions,
} from './store';
import {
  emptyStateMessage,
  emptyStateDescription,
  emptyStateIcon,
  resourceDataType,
} from './table-empty-state.helpers';

import {
  getHeaderTranslations,
  getVehicleHeaderTranslations,
  tableCircuitHeaders,
  tableDriverHeaders,
} from './table-headers';

enum formControlStatusEnum {
  VALID = 'VALID',
  INVALID = 'INVALID',
  PENDING = 'PENDING',
  DISABLED = 'DISABLED',
}
@Component({
  selector: 'astus-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.scss'],
})
export class ResourceComponent implements OnInit, OnDestroy {
  // Circuit resources data needed to be displayed in the table
  circuitData: tablePropsInterface[] = [];
  circuitHeaders = tableCircuitHeaders;
  circuitDataSubscriptions = new Subscription();
  getCircuitErrorSubscription = new Subscription();

  // User resources data needed to be displayed in the table
  driverData: tablePropsInterface[] = [];
  driverDataDefault: tablePropsInterface[] = [];
  driverDataSubscriptions = new Subscription();
  driverHeaders = tableDriverHeaders;
  tempDriverFetchSubscription = new Subscription();
  userFormToEdit: adsUserTableInterface | null = null;

  // Vehicle resources data needed to be displayed in the table
  vehicleData: adsVehicleTableInterface[] = [];
  vehicleDataDefault: adsVehicleTableInterface[] = [];
  vehicleDataSubscriptions = new Subscription();
  vehicleHeaders: tableHeadersType = [];
  tempVehicleFetchSubscription = new Subscription();
  vehicleDataToEdit: adsVehicleTableInterface | null = null;

  formControlStatus = formControlStatusEnum;

  displayInfobox = false;
  isEditMode = false;
  isMobile = window.innerWidth < 800;

  selectedTab: resourceDataType = 'circuits';
  currentId: string | null = null;
  currentIndexRow: number | null = null;
  searchValue: string | null = null;
  widgetErrorMessage = '';
  widgetProgressMessage = '';
  widgetTranslationValues = {
    circuits: {
      error: '',
      progress: '',
    },
    vehicles: {
      error: '',
      progress: '',
    },
    users: {
      error: '',
      progress: '',
    },
  };

  widgetTranslationValues$ = new Subscription();
  private routerEventsSubscription = new Subscription();
  storeUserColumnsSubscription = new Subscription();
  vehicleHeaderTranslationSubscription = new Subscription();
  private infoboxSubscription = new Subscription();
  getVehicleErrorSubscription = new Subscription();

  vehicleForm: FormGroup<vehicleFormInterface> | null = null;
  userForm: FormGroup<ResourceUserFormModel> | null = null;
  data: (
    | circuitTableInterface
    | driverTableInterface
    | vehicleTableInterface
  )[] = this.circuitData as circuitTableInterface[];

  headers: tableHeadersType = this.circuitHeaders;
  fixedColumns = 3;
  selectedWidgetIndex = 0;
  circuitsProblemsData = {
    count: 2,
  };
  vehiclesProblemsData = {
    count: 6,
  };
  driversProblemsData = {
    count: 12,
  };

  searchTimeout: ReturnType<typeof setTimeout> = setTimeout(() => '', 10);

  resourceServiceObservable$ = new Subscription();

  showCircuits$ = this.launchDarklyService.getFlagValue$('Ressources_Circuits');
  showCircuitsValue = false;
  showCircuitsSub = new Subscription();

  errorMessage = emptyStateMessage;
  errorDescription = emptyStateDescription;
  iconGlyph = emptyStateIcon;

  constructor(
    public translate: TranslateService,
    private distancePipe: DistancePipe,
    private dateFormatPipe: DateFormatPipe,
    private hoursFormatPipe: HoursFormatPipe,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private clipboard: Clipboard,
    private launchDarklyService: LaunchDarklyService
  ) {}

  ngOnInit(): void {
    this.showCircuitsSub = this.showCircuits$.subscribe((isShowCircuit) => {
      this.showCircuitsValue = isShowCircuit;
      this.viewIsReady();
    });
  }

  ngOnDestroy(): void {
    // reset resources store
    this.store.dispatch(resourceActions.resetCircuits());

    // unsubscribe all subscriptions started in this component
    this.widgetTranslationValues$.unsubscribe();
    this.routerEventsSubscription.unsubscribe();
    this.resourceServiceObservable$.unsubscribe();
    this.vehicleDataSubscriptions.unsubscribe();
    this.driverDataSubscriptions.unsubscribe();
    this.infoboxSubscription.unsubscribe();
    this.getVehicleErrorSubscription.unsubscribe();
    this.circuitDataSubscriptions.unsubscribe();
    this.getCircuitErrorSubscription.unsubscribe();
    this.storeUserColumnsSubscription.unsubscribe();
    this.vehicleHeaderTranslationSubscription.unsubscribe();
  }

  viewIsReady(): void {
    this.currentId = this.activatedRoute.snapshot.paramMap.get('id');
    this.initColumns();
    getHeaderTranslations(this.translate);
    this.getWidgetTranslations();
    this.initTabUrl();
    this.sortDataWithName();

    this.searchValue = this.activatedRoute.snapshot.queryParamMap.get('s');

    this.routerEventsSubscription = this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe(() => {
        this.currentId = this.activatedRoute.snapshot.paramMap.get('id');

        const tab = this.activatedRoute.snapshot.paramMap.get('tab');
        if (tab && tab !== this.selectedTab) {
          this.dataSelect(tab as resourceDataType);
        }
      });

    // get the circuit list data from the store
    this.store.dispatch(resourceActions.getAllCircuits());
    this.circuitDataSubscriptions = this.store
      .select(getCircuitListData)
      .subscribe((circuits) => {
        this.circuitData =
          this.transformCircuitsToCircuitTableInterface(circuits);

        if (
          this.activatedRoute.snapshot.paramMap.get('tab') ===
          resourceTabType.CIRCUITS
        ) {
          this.dataSelect(this.selectedTab);
        }
      });

    this.getCircuitErrorSubscription = this.store
      .select(getCircuitError)
      .subscribe((error) => {
        if (error) {
          this.showNewAlert(this.translate.instant(error.message));
          this.store.dispatch(resourceActions.circuitClearError());
        }
      });

    // get the vehicle list data from the store
    this.store.dispatch(resourceActions.getAllVehicles());
    this.vehicleDataSubscriptions = this.store
      .select(getVehicleListData)
      .subscribe((vehiclesList) => {
        this.vehicleData =
          this.transformVehiclesStoreToVehicleTableInterface(vehiclesList);
        this.vehicleDataDefault = [...this.vehicleData];
        if (
          this.activatedRoute.snapshot.paramMap.get('tab') ===
            resourceTabType.VEHICLES ||
          this.activatedRoute.snapshot.paramMap.get('tab') === null
        ) {
          this.dataSelect(this.selectedTab);
          this.openInfoboxVehicle();
        }
      });

    this.getVehicleErrorSubscription = this.store
      .select(getVehicleError)
      .subscribe((error) => {
        if (error) {
          this.showNewAlert(this.translate.instant(error.message));
          this.store.dispatch(resourceActions.vehicleClearError());
        }
      });

    // get the user list data from the store
    this.driverDataSubscriptions = this.store
      .select(getUserListData)
      .subscribe((userList) => {
        this.driverData =
          this.transformUsersStoreToDriverTableInterface(userList);

        this.driverDataDefault = [...this.driverData];
        if (
          this.activatedRoute.snapshot.paramMap.get('tab') ===
          resourceTabType.DRIVERS
        ) {
          this.dataSelect(this.selectedTab);
        }
      });

    this.infoboxSubscription = this.store
      .select(getInfoboxState)
      .subscribe((isInfoBoxOpen) => {
        if (isInfoBoxOpen) this.onCreateResourcesClick();
      });

    // TODO: delete this code once circuits are no longer in mockdata
    if (this.selectedTab === resourceTabType.CIRCUITS) {
      this.dataSelect(this.selectedTab);
    }
  }

  public get showInfoboxClass() {
    return classNames(this.displayInfobox ? 'show' : 'hide');
  }

  /**
   * @description this function is used to sort the data by name
   */
  sortDataWithName() {
    this.data = SortingHelper.sortByProperty(this.data, 'name');
  }

  /**
   * this function is used to remap the driver data for the FE
   * @param {driverTableInterface[]} data
   * @returns {driverTableInterface[]}
   */
  formatDriverData(data: driverTableInterface[]) {
    return data.map((item) => ({
      ...item,
      employeeExp: item.employeeExp
        ? this.dateFormatPipe.transform(item.employeeExp)
        : '',
      permitExp: item.permitExp
        ? this.dateFormatPipe.transform(item.permitExp)
        : '',
    }));
  }

  /**
   * this function is used to remap the vehicle data for the FE
   * @param {vehicleTableInterface[]} data
   * @returns {vehicleTableInterface[]}
   */
  formatVehicleData(data: adsVehicleTableInterface[]) {
    return data.map((item) => {
      const maintenanceStartHours = item.maintenanceHours
        ? this.hoursFormatPipe.convertDateTo24Hr(
            new Date(item.maintenanceHours.start)
          )
        : '';

      const maintenanceEndHours = item.maintenanceHours
        ? this.hoursFormatPipe.convertDateTo24Hr(
            new Date(item.maintenanceHours.end)
          )
        : '';
      return {
        ...item,
        distanceRemaining: item.distanceRemaining
          ? this.distancePipe.transform(item.distanceRemaining)
          : '',
        odometer: item.odometer
          ? this.distancePipe.transform(item.odometer)
          : '',
        lastConnection: item.lastConnection
          ? `${this.dateFormatPipe.transform(
              item.lastConnection
            )} - ${this.hoursFormatPipe.transform(
              this.hoursFormatPipe.convertDateTo24Hr(
                new Date(item.lastConnection)
              )
            )}`
          : '',
        maintenance: item.maintenance
          ? `Check up ${this.dateFormatPipe.transform(
              item.maintenance
            )} ${this.hoursFormatPipe.transform(
              maintenanceStartHours
            )}-${this.hoursFormatPipe.transform(maintenanceEndHours)}`
          : '',
        update: `${this.dateFormatPipe.transform(
          item.update
        )} - ${this.hoursFormatPipe.transform(
          this.hoursFormatPipe.convertDateTo24Hr(new Date(item.update))
        )}`,
      };
    });
  }

  /**
   * Transform the users store data (from backend) to the user data for the table
   * @param data - the users from the store (backend)
   * @returns {tablePropsInterface[]} - the users for the table transformed according to the table interface
   */
  transformUsersStoreToDriverTableInterface(
    data: adsUserTableInterface[]
  ): tablePropsInterface[] {
    return data.map((item) => ({
      ...item,
      update: this.removeDateAndTranslateIt(item.update), // TODO: remove this when we have the real data
      employeeExp: item.expirationSingleJob,
      permitExp: item.licenseExpirationDate,
    }));
  }

  /**
   * Transform the vehicles store data (from backend) to the vehicles data for the table
   * @param data - the vehicles from the store (backend)
   * @returns {tablePropsInterface[]} - the vehicles for the table transformed according to the table interface
   */
  transformVehiclesStoreToVehicleTableInterface(
    data: VehicleDto[]
  ): adsVehicleTableInterface[] {
    return data.map((item) => ({
      ...parseDataVehicle(item),
      update: this.removeDateAndTranslateIt(item.modified || ''), // TODO: remove this when we have the real data
    }));
  }

  transformCircuitsToCircuitTableInterface(
    data: CircuitView[]
  ): tablePropsInterface[] {
    return data.map((item) => ({
      ...item,
      routes: item.routesCount.toString(),
      studentsTotal: item.studentsTotal.toString(),
      stops: item.routes
        .reduce((acc, route) => acc + route.stops.length, 0)
        .toString(),
      time: item.timeToTravel.toString(),
    }));
  }

  /**
   * @description - this function is used to filter the vehicles based on the search value
   */
  filterVehicles() {
    if (!this.searchValue) {
      this.vehicleData = this.vehicleDataDefault;
    } else {
      this.vehicleData = this.vehicleDataDefault.filter((user) =>
        Object.values(user).some((value) =>
          value
            .toString()
            .toLowerCase()
            .includes(this.searchValue?.toLowerCase())
        )
      );
    }

    this.data = this.formatVehicleData(this.vehicleData);
  }

  /**
   * @description - this function is used to filter the drivers based on the search value
   */
  filterDrivers() {
    if (!this.searchValue) {
      this.driverData = this.driverDataDefault;
    } else {
      this.driverData = this.driverDataDefault.filter((user) =>
        Object.values(user).some((value) =>
          value
            .toString()
            .toLowerCase()
            .includes(this.searchValue?.toLowerCase())
        )
      );
    }

    this.data = this.formatDriverData(this.driverData);
  }

  onCreateResourcesClick() {
    if (
      this.selectedTab === resourceTabType.VEHICLES ||
      this.selectedTab === resourceTabType.DRIVERS
    ) {
      this.vehicleDataToEdit = null;
      this.userFormToEdit = null;
      this.displayInfobox = true;

      if (this.selectedTab === resourceTabType.VEHICLES) {
        this.store.dispatch(
          resourceActions.updateVehicleInfobox({
            vehicle: {
              name: '',
              vin: '',
            },
          })
        );
      }
    }
  }

  /**
   * this function is used to select the data to display
   * @param {resourceDataType} cat
   */
  dataSelect(cat: resourceDataType) {
    if (cat === 'circuits' && this.showCircuitsValue) {
      this.data = this.circuitData as circuitTableInterface[];
      this.headers = this.circuitHeaders;
      this.selectedTab = resourceTabType.CIRCUITS;
      this.fixedColumns = 3;
    } else if (cat === 'users') {
      this.filterDrivers();
      this.headers = this.driverHeaders;
      this.selectedTab = resourceTabType.DRIVERS;
      this.fixedColumns = 2;
    } else {
      this.filterVehicles();
      this.headers = this.vehicleHeaders;
      this.selectedTab = resourceTabType.VEHICLES;
      this.fixedColumns = 2;
    }
    this.handleUploadMessages();

    this.initCurrentRow();
  }

  /**
   * @description this method return the right key for translation
   * @returns {string} the key of translation
   */
  getKeyJsonTranslate(): string {
    switch (this.selectedTab) {
      case resourceTabType.CIRCUITS:
        return 'CIRCUIT';
      case resourceTabType.DRIVERS:
        return 'DRIVER';
      case resourceTabType.VEHICLES:
        return 'VEHICLE';
      default:
        return '';
    }
  }

  /**
   * @description show an alert (ex: bad url)
   * @param {string} title the title of alert
   * @returns {void}
   */
  showNewAlert(title: string) {
    this.store.dispatch(
      alertActions.showNewAlert({
        alert: { title, colorType: 'danger' },
      })
    );
  }

  /**
   * this function is used to get and set the translations for the widget
   */
  getWidgetTranslations() {
    this.widgetTranslationValues$ = combineLatest([
      this.translate.stream('UPLOAD_WIDGET_CIRCUITS.FILE_UPLOAD_ERROR'),
      this.translate.stream('UPLOAD_WIDGET_CIRCUITS.FILE_UPLOAD_PROGRESS'),
      this.translate.stream('UPLOAD_WIDGET_VEHICLES.FILE_UPLOAD_ERROR'),
      this.translate.stream('UPLOAD_WIDGET_VEHICLES.FILE_UPLOAD_PROGRESS'),
      this.translate.stream('UPLOAD_WIDGET_USERS.FILE_UPLOAD_ERROR'),
      this.translate.stream('UPLOAD_WIDGET_USERS.FILE_UPLOAD_PROGRESS'),
    ]).subscribe(
      ([
        circuitError,
        circuitProgress,
        vehicleError,
        vehicleProgress,
        driverError,
        driverProgress,
      ]) => {
        this.widgetTranslationValues = {
          circuits: {
            error: circuitError,
            progress: circuitProgress,
          },
          vehicles: {
            error: vehicleError,
            progress: vehicleProgress,
          },
          users: {
            error: driverError,
            progress: driverProgress,
          },
        };

        this.handleUploadMessages();
      }
    );
  }

  /**
   * @description
   * Redirect the route with param id
   * @param {tableRowEventType} row
   * @returns {void} void
   */
  onRowSelected(row: tableRowEventType): void {
    const data:
      | circuitTableInterface
      | driverTableInterface
      | vehicleTableInterface = row.data;
    const id = data.id?.toString();

    // if the id is different from the current id, redirect to the new id
    // else do nothing
    if (this.currentId !== id) {
      this.redirectTo(this.selectedTab, id);
    }
  }

  /**
   * @description this function is used to handle the tab change event
   * @param tab {resourceDataType}
   */
  onChangeTab(tab: resourceDataType) {
    this.closeInfoboxPanel();
    this.redirectTo(tab);
  }

  /**
   * this function is used to handle the rerouting upon tab selection
   * @param {string} tab
   */
  redirectTo(tab: string, id?: string) {
    const params: string[] = [appRoutesEnum.RESOURCES, tab];

    if (id) {
      params.push(id);
    } else {
      this.searchValue = null;
    }
    this.router.navigate(params);
  }

  /**
   * this function is used to set the selected tab based on the url
   */
  initTabUrl(): void {
    const tab = this.activatedRoute.snapshot.paramMap.get('tab');
    const validResources: string[] = [
      appRoutesEnum.CIRCUITS,
      appRoutesEnum.USERS,
      appRoutesEnum.VEHICLES,
    ];
    if (tab === resourceTabType.CIRCUITS && !this.showCircuitsValue) {
      this.selectedTab = resourceTabType.VEHICLES;
      this.redirectTo(appRoutesEnum.VEHICLES);
    } else if (tab && validResources.includes(tab)) {
      this.selectedTab = tab as resourceDataType;
      if (
        this.currentId &&
        tab !== resourceTabType.VEHICLES &&
        !this.data.some(
          (d: tablePropsInterface) => d.id.toString() === this.currentId
        )
      ) {
        this.showNewAlert(
          `${this.translate.instant(
            `SNACKBAR.ERROR.ID_NOT_FOUND.${this.getKeyJsonTranslate()}`,
            { id: this.currentId }
          )}`
        );
        this.redirectTo(tab);
      }
    } else {
      this.showNewAlert(
        this.translate.instant('SNACKBAR.ERROR.RESOURCE_NOT_FOUND', {
          resource: tab,
        })
      );
      if (this.showCircuitsValue) {
        this.redirectTo(appRoutesEnum.CIRCUITS);
      } else {
        this.redirectTo(appRoutesEnum.VEHICLES);
      }
    }
  }

  /**
   * @description find and define the index if there is a param id from url
   * @returns {void}
   */
  initCurrentRow() {
    const currentId = this.currentId;
    this.currentIndexRow = null;
    if (currentId) {
      const found = this.data.findIndex((d) => d.id?.toString() === currentId);
      if (found !== -1) {
        this.currentIndexRow = found;
      }
    }
  }

  /**
   * this function is used to set the widget messages based on the selected tab
   */
  handleUploadMessages() {
    this.widgetErrorMessage =
      this.widgetTranslationValues[this.selectedTab].error;
    this.widgetProgressMessage =
      this.widgetTranslationValues[this.selectedTab].progress;
  }

  onVehicleFormChange(form: FormGroup<vehicleFormInterface>) {
    // dispatch the form updated into the store
    this.store.dispatch(
      resourceActions.updateVehicleInfobox({
        vehicle: this.buildVehicle(form),
      })
    );

    this.vehicleForm = form;
  }

  onUserFormChange(form: FormGroup<ResourceUserFormModel>) {
    this.store.dispatch(
      resourceActions.updateUserInfobox({
        user: this.formToUserInfobox(form),
      })
    );
    this.userForm = form;
  }

  onInfoboxCancelClick() {
    this.isEditMode = false;
    this.closeInfoboxPanel();

    if (this.selectedTab === resourceTabType.VEHICLES) {
      this.vehicleForm = null;
      this.router.navigate([appRoutesEnum.RESOURCES, resourceTabType.VEHICLES]);
    }

    if (this.selectedTab === resourceTabType.DRIVERS) {
      this.userForm = null;
      this.store.dispatch(resourceActions.resetUserInfobox());
      this.router.navigate([appRoutesEnum.RESOURCES, resourceTabType.DRIVERS]);
    }
  }

  buildVehicle(vehicleForm: FormGroup<vehicleFormInterface>): UpsertVehicleDto {
    const vehicle: UpsertVehicleDto = {
      name: vehicleForm.value.name?.trim() || '',
      vin: vehicleForm.value.vin?.trim() || '',
    };

    if (vehicleForm.value.plateNumber) {
      vehicle.immatriculation = {
        plateNumber: vehicleForm.value.plateNumber,
        country: vehicleForm.value.country || undefined,
        province: vehicleForm.value.province || undefined,
        timezone: vehicleForm.value.timezone || undefined,
      };
    }

    if (vehicleForm.value.iotDeviceId) {
      vehicle.iotDeviceId = vehicleForm.value.iotDeviceId;
    } else if (vehicleForm.value.tagSerialNumber && vehicleForm.value.imei) {
      vehicle.iotDevice = {
        serialNumber: vehicleForm.value.tagSerialNumber.toString(),
        imei: +vehicleForm.value.imei,
        softwareVersion: vehicleForm.value.softwareVersion || undefined,
        lastConnected: vehicleForm.value.lastConnection || undefined,
        type: vehicleForm.value.tagType || undefined,
        groups: [],
      };
    }

    if (vehicleForm.value.modelId) {
      vehicle.modelId = vehicleForm.value.modelId;
    } else if (
      vehicleForm.value.model &&
      vehicleForm.value.brand &&
      vehicleForm.value.year &&
      vehicleForm.value.fuelType
    ) {
      vehicle.model = {
        brand: vehicleForm.value.brand,
        name: vehicleForm.value.model,
        year: +vehicleForm.value.year,
        fuelType: vehicleForm.value.fuelType,
      };

      if (vehicleForm.value.vehicleType) {
        vehicle.model._class = { name: vehicleForm.value.vehicleType };
      }
    }

    if (vehicleForm.value.homePortId) {
      vehicle.homePortId = vehicleForm.value.homePortId;
    } else if (vehicleForm.value.homePort) {
      vehicle.homePort = {
        name: vehicleForm.value.homePort,
      };
    }

    if (vehicleForm.value.carrierId) {
      vehicle.carrierId = vehicleForm.value.carrierId;
    } else if (vehicleForm.value.carrier) {
      vehicle.carrier = {
        name: vehicleForm.value.carrier,
      };
    }

    if (vehicleForm.value.groups?.length) {
      vehicle.group = { name: vehicleForm.value.groups[0] }; // TODO: handle multiple groups
    }

    return vehicle;
  }

  onInfoboxSaveClick() {
    if (this.selectedTab === 'vehicles' && this.vehicleForm?.valid) {
      // handle the form

      // Field not used in from the form
      // - groups
      // - country
      // - province
      // - timezone
      // - resource sharing

      // Field hardcoded we don't have in the form
      // - updatedDate
      // - seats
      // - maintenance
      // - lastPosition
      // - module
      // - distanceRemaining
      // - odometer
      // - circuit

      if (this.selectedTab === resourceTabType.VEHICLES) {
        if (this.isEditMode) {
          // update the vehicle, dispatch event to the store
          const updatedVehicle = this.buildVehicle(this.vehicleForm);

          const id = this.currentId || raise('Vehicle id not found');
          this.store.dispatch(
            resourceActions.updateVehicle({
              id: id,
              vehicle: updatedVehicle,
            })
          );
        } else {
          const vehicle: UpsertVehicleDto = this.buildVehicle(this.vehicleForm);
          this.store.dispatch(
            resourceActions.createVehicle({
              vehicle,
            })
          );
        }
      }
      // reset url when save action
      this.router.navigate([appRoutesEnum.RESOURCES, resourceTabType.VEHICLES]);
      this.vehicleForm = null;
      this.isEditMode = false;
      this.closeInfoboxPanel();
    }

    if (this.selectedTab === 'users' && this.userForm?.valid) {
      const userData = this.formToUserInfobox(this.userForm);
      if (this.isEditMode) {
        this.store.dispatch(
          resourceActions.updateUserInformation({
            user: {
              ...this.userFormToEdit,
              ...userData,
            },
          })
        );
      } else {
        this.store.dispatch(
          resourceActions.createUser({
            user: userData,
          })
        );
        this.closeInfoboxPanel();

        this.userForm = null;
      }
    }
  }

  formToUserInfobox(
    form: FormGroup<ResourceUserFormModel>
  ): adsUserTableInterface {
    const consistentFields = formatUserFields(
      form.value as ResourceUserAndDriverModel
    );
    const defaultDriverInfo: adsUserTableInterface = {
      id: form.value.id,
      update: 'Hier 12:32',
      isDisabled: false,
      name: 'Marc-André Cadieux',
      firstName: 'Marc-André',
      lastName: 'Cadieux',
      language: 'Français',
      rolesPermissions: form.value.rolesPermissions
        ? [...form.value.rolesPermissions.map((role) => role.name)]
        : [],
      isDriver: false,
      hos: '5h20',
      phone: '(111) 123-4567',
      email: 'm.cadieux@email.com',
      circuits: '002, 4700, 1234, 3456',
      vehiclePref: 'Autobus 003',
      employeeNo: consistentFields.singleJob ?? 'JH00020003',
      expirationSingleJob: '10/01/2026',
      licenseClass: 'Classe 2',
      licenseNumber: 'L1531-171274-08',
      licenseExpirationDate: '10/01/2026',
      transporter: 'Autocar Deslisle',
    };
    return { ...defaultDriverInfo, ...consistentFields };
  }

  /**
   * this function is used to handle the row click event
   * and initialize the vehicle data to edit to be used as input for the vehicle info box and sets the edit mode to true
   * @param {tableRowEventType} row
   */
  onTableRowClick(row: tableRowEventType) {
    this.isEditMode = true;
    if (this.selectedTab === resourceTabType.VEHICLES) {
      this.displayInfobox = true;
      this.vehicleDataToEdit = row.data as adsVehicleTableInterface;
    } else if (this.selectedTab === resourceTabType.DRIVERS) {
      this.displayInfobox = true;
      this.userFormToEdit = row.data as adsUserTableInterface;
    }

    this.onRowSelected(row);
  }

  openInfoboxVehicle() {
    if (this.currentId && this.vehicleData.length) {
      const vehicleFound = this.vehicleData.find(
        (vehicle) => vehicle.id === this.currentId
      );
      if (vehicleFound) {
        this.displayInfobox = true;
        this.isEditMode = true;
        // this.store.dispatch(resourceActions.openInfoboxPanel());
        this.vehicleDataToEdit = vehicleFound as adsVehicleTableInterface;
      } else {
        this.showNewAlert(
          `${this.translate.instant(
            `SNACKBAR.ERROR.ID_NOT_FOUND.${this.getKeyJsonTranslate()}`,
            { id: this.currentId }
          )}`
        );

        this.redirectTo(this.selectedTab);
      }
    }
  }

  searchHandler(searchValue: string) {
    clearTimeout(this.searchTimeout);
    this.searchValue = searchValue;

    this.searchTimeout = setTimeout(() => {
      if (this.selectedTab === resourceTabType.VEHICLES) {
        this.filterVehicles();
      } else if (this.selectedTab === resourceTabType.DRIVERS) {
        this.filterDrivers();
      }

      if (searchValue === '') {
        this.router.navigate([], {
          queryParams: { s: null },
          queryParamsHandling: 'merge',
        });
      } else {
        this.router.navigate([], {
          queryParams: { s: searchValue },
          queryParamsHandling: 'merge',
        });
      }
    }, 500);
  }

  unsubscribeFromTempSubscriptions() {
    this.tempVehicleFetchSubscription.unsubscribe();
    this.tempDriverFetchSubscription.unsubscribe();
  }

  /**
   *  @description
   * Called when the share button is clicked, and,
   * copies the current URL to the clipboard and shows an alert message to tell user that URL is copied
   */
  onClickCopyAlert(): void {
    const copiedToClipboardMessage = this.translate.instant(
      'SHARE_LINK.ALERT_COPIED_CLIPBOARD'
    );
    const checkIcon: IconAtomGlyphType = 'check';

    this.clipboard.copy(window.location.href);

    this.store.dispatch(
      alertActions.showNewAlert({
        alert: {
          title: copiedToClipboardMessage,
          icon: checkIcon,
          colorType: 'success',
        },
      })
    );
  }

  initColumns() {
    this.vehicleHeaderTranslationSubscription = getVehicleHeaderTranslations(
      this.translate
    ).subscribe((headers) => {
      this.storeUserColumnsSubscription = this.store
        .select(getUserResourcesVehiclesDataTableColumns)
        .subscribe((columns) => {
          this.headers = columns
            .map((header, index) => {
              return {
                dataKey: header.dataKey,
                label: headers[index],
              };
            })
            .filter((header) =>
              columns.some(
                (column) => column.dataKey === header.dataKey && column.selected
              )
            );
          this.vehicleHeaders = this.headers;
        });
    });
  }

  @HostListener('window:resize')
  onResize() {
    this.isMobile = window.innerWidth < 800;
  }

  /**
   * Closes the infobox panel
   * @returns {void}
   * */
  closeInfoboxPanel(): void {
    this.displayInfobox = false;
    this.store.dispatch(resourceActions.closeInfoboxPanel());
  }

  /**
   * /!\ THIS FUNCTION MUST BE REMOVED WHEN WE HAVE THE REAL DATA
   * This function remove :
   * - the word 'Hier' and translate it to 'YESTERDAY'
   * - the word "Aujourd'hui" and translate it to 'TODAY'
   * Currently, we have a mock data with 'Hier' or 'Aujourd'hui' word in the date
   * @param date
   * @returns string
   */
  removeDateAndTranslateIt(date: string): string {
    const yesterdayInFrench = 'Hier';
    const todayInFrench = "Aujourd'hui";

    if (date.includes(yesterdayInFrench)) {
      return date.replace(
        yesterdayInFrench,
        this.translate.instant('RESOURCES.YESTERDAY') // TODO: remove this translation when we have the real data
      );
    }

    if (date.includes(todayInFrench)) {
      return date.replace(
        todayInFrench,
        this.translate.instant('RESOURCES.TODAY') // TODO: remove this translation when we have the real data
      );
    }

    // Date should not contains yesterday or today
    return date;
  }
}
