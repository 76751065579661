<div [ngClass]="{ 'is-skeleton': this.isLoading }">
  <div class="header">
    <ad-card-info
      [tooltipPosition]="'left'"
      [isSkeleton]="isLoading"
      [title]="title || ''"
      [hideAvatars]="true"
      [infoItems]="[
        { status: statusColor, text: statusText },
        { icon: 'seat', text: infoSeat || '' },
        { icon: 'fuel', text: infoFuel || '' }
      ]"
    >
    </ad-card-info>
    <div *ngIf="moreOptions.length" class="header-more-info" moreInfo>
      <div class="header-more-info-container">
        <ad-popover
          [(display)]="showMoreOptions"
          [options]="moreOptions"
          class="header-more-info-popover"
          (selectOption)="handleMoreOptionClick($event)"
          position="bottom"
        >
          <button
            (click)="onMoreOptionsClick()"
            class="header-more-info-popover-button"
          >
            <ad-icon
              [color]="isLoading ? 'neutral-200' : 'neutral-500'"
              glyph="more-vertical"
              class="more-options-icon"
              [ngClass]="this.isLoading ? 'is-loading' : 'is-not-loading'"
              svgClass="header-icons-svg"
            ></ad-icon>
          </button>
        </ad-popover>
      </div>
    </div>
  </div>
  <!-- Skeleton -->
  <ng-container *ngIf="isLoading; else infoContainer">
    <div class="info-container-skeleton">
      <!-- Header -->
      <div class="info-container-skeleton-header">
        <div class="info-container-skeleton-header-1"></div>
        <div class="info-container-skeleton-header-2"></div>
      </div>
      <!-- Info container -->
      <div *ngFor="let _1_ of [1, 2, 3, 4]" class="info-content-skeleton">
        <div class="info-content-skeleton-start"></div>
        <div class="info-content-skeleton-box">
          <!-- First box info -->
          <div
            class="info-content-skeleton-first-box-container"
            *ngIf="_1_ === 1"
          >
            <div class="info-content-skeleton-first-box-container">
              <div class="info-content-skeleton-first-box-container-1"></div>
              <div class="info-content-skeleton-first-box-container-2">
                <div
                  class="info-content-skeleton-first-box-container-2-first"
                ></div>
                <div
                  class="info-content-skeleton-first-box-container-2-second"
                ></div>
              </div>
            </div>
            <div class="info-content-skeleton-second-box">
              <div class="info-content-skeleton-second-box-1"></div>
              <div class="info-content-skeleton-second-box-2"></div>
            </div>
          </div>
          <!-- Other box info -->
          <div class="info-content-skeleton-other-box" *ngIf="_1_ !== 1">
            <div
              *ngFor="let _2_ of [1, 2, 3, 4, 5, 6, 7]"
              class="info-content-skeleton-other-box-container"
            >
              <div
                *ngIf="
                  (_1_ === 3 && _2_ < 5) || (_1_ === 4 && _2_ < 3) || _1_ === 2
                "
                class="info-content-skeleton-other-box-content"
                [ngClass]="{
                  'info-content-skeleton-other-box-content-not-first': _2_ !== 1
                }"
              >
                <div
                  *ngIf="_2_ < 5 && _2_ !== 3 && _2_ !== 1"
                  class="info-content-skeleton-other-box-content-1"
                ></div>
                <div
                  *ngIf="_2_ < 5 && (_2_ === 3 || _2_ === 1)"
                  class="info-content-skeleton-other-box-content-2"
                ></div>
                <div
                  *ngIf="_2_ < 5 && _2_ !== 2 && _2_ !== 4"
                  class="info-content-skeleton-other-box-content-3"
                ></div>
                <div
                  *ngIf="_2_ < 5 && (_2_ === 2 || _2_ === 4)"
                  class="info-content-skeleton-other-box-content-4"
                ></div>
                <div
                  *ngIf="_2_ >= 5 && _2_ !== 5 && _2_ !== 7"
                  class="info-content-skeleton-other-box-content-5"
                ></div>
                <div
                  *ngIf="_2_ >= 5 && (_2_ === 5 || _2_ === 7)"
                  class="info-content-skeleton-other-box-content-6"
                ></div>
                <div
                  *ngIf="_2_ >= 5 && _2_ !== 6"
                  class="info-content-skeleton-other-box-content-7"
                ></div>
                <div
                  *ngIf="_2_ >= 5 && _2_ === 6"
                  class="info-content-skeleton-other-box-content-8"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Content -->
  <ng-template #infoContainer>
    <div class="info-container">
      <div class="info-route" *ngIf="route">
        <ad-icon
          glyph="location-filled"
          class="info-icon"
          color="neutral-700"
          svgClass="header-icons-svg"
        ></ad-icon>
        <div class="info-route-text">
          {{ route }}
        </div>
      </div>
      <div
        class="info-bus-information-container"
        *ngIf="circuits || preference || type"
      >
        <div class="info-bus-information-vehicle">
          {{
            'CARDS.MONITORING_CARD.VEHICLE_MONITORING.INFORMATION'
              | translate
              | uppercase
          }}
        </div>
        <div class="info-bus-information-vehicle-container">
          <div *ngIf="circuits" class="info-bus-information-circuits-container">
            <div class="info-bus-information-circuits-title">
              {{
                'CARDS.MONITORING_CARD.VEHICLE_MONITORING.CIRCUITS' | translate
              }}
            </div>
            <div class="info-bus-information-preference-text-2">
              {{ circuits }}
            </div>
          </div>
          <div *ngIf="preference" class="info-bus-information-preference">
            <div class="info-bus-information-preference-text-1">
              {{
                'CARDS.MONITORING_CARD.VEHICLE_MONITORING.PREFERENCE'
                  | translate
              }}
            </div>
            <div class="info-bus-information-preference-text-2">
              {{ preference }}
            </div>
          </div>
          <div *ngIf="type" class="info-bus-information-type">
            <div class="info-bus-information-preference-text-1">
              {{ 'CARDS.MONITORING_CARD.VEHICLE_MONITORING.TYPE' | translate }}
            </div>
            <div class="info-bus-information-preference-text-2">
              {{ type }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="info-bus-detail-container"
        *ngIf="
          (odometer && odometerReadTime) ||
          vin ||
          plaque ||
          (model && year) ||
          fuel ||
          transporter ||
          homeport ||
          groups
        "
      >
        <div class="info-bus-detail-title">
          {{
            'CARDS.MONITORING_CARD.VEHICLE_MONITORING.DETAILS'
              | translate
              | uppercase
          }}
        </div>
        <div class="info-bus-detail-content">
          <div
            *ngIf="odometer && odometerReadTime"
            class="info-bus-detail-contents"
          >
            <div class="info-bus-detail-contents-title">
              {{
                'CARDS.MONITORING_CARD.VEHICLE_MONITORING.ODOMETER' | translate
              }}
            </div>
            <div class="info-bus-detail-contents-title-2">
              {{ odometer }}km
              <span class="info-bus-detail-contents-title-2-normal">
                ({{ odometerReadTime }})</span
              >
            </div>
          </div>
          <div *ngIf="vin" class="info-bus-detail-contents">
            <div class="info-bus-detail-contents-title">
              {{ 'CARDS.MONITORING_CARD.VEHICLE_MONITORING.VIN' | translate }}
            </div>
            <div class="info-bus-detail-contents-title-2">
              {{ vin }}
            </div>
          </div>
          <div *ngIf="plaque" class="info-bus-detail-contents">
            <div class="info-bus-detail-contents-title">
              {{
                'CARDS.MONITORING_CARD.VEHICLE_MONITORING.PLAQUE' | translate
              }}
            </div>
            <div class="info-bus-detail-contents-title-2">
              {{ plaque }}
            </div>
          </div>
          <div *ngIf="model && year" class="info-bus-detail-contents">
            <div class="info-bus-detail-contents-title">
              {{ 'CARDS.MONITORING_CARD.VEHICLE_MONITORING.MODEL' | translate }}
            </div>
            <div class="info-bus-detail-contents-title-2">
              {{ model }}
              <span class="info-bus-detail-contents-title-2-normal">
                ({{ year }})</span
              >
            </div>
          </div>
          <div *ngIf="fuel" class="info-bus-detail-contents-fuel">
            <div class="info-bus-detail-contents-title">
              {{ 'CARDS.MONITORING_CARD.VEHICLE_MONITORING.FUEL' | translate }}
            </div>
            <div class="center info-bus-detail-contents-title-2">
              <ad-icon
                glyph="fuel"
                class="info-bus-detail-contents-icons"
                color="neutral-400"
                svgClass="info-bus-detail-contents-icons-svg"
              ></ad-icon>
              <span class="center info-bus-detail-contents-title-2">{{
                fuel
              }}</span>
            </div>
          </div>
          <div *ngIf="transporter" class="info-bus-detail-contents">
            <div class="info-bus-detail-contents-title">
              {{
                'CARDS.MONITORING_CARD.VEHICLE_MONITORING.TRANSPORTER'
                  | translate
              }}
            </div>
            <div class="info-bus-detail-contents-title-2">
              {{ transporter }}
            </div>
          </div>
          <div *ngIf="homeport" class="info-bus-detail-contents">
            <div class="info-bus-detail-contents-title">
              {{
                'CARDS.MONITORING_CARD.VEHICLE_MONITORING.HOMEPORT' | translate
              }}
            </div>
            <div class="info-bus-detail-contents-title-2">
              {{ homeport }}
            </div>
          </div>
          <div *ngIf="groups" class="info-bus-detail-contents-fleet">
            <div class="info-bus-detail-contents-title">
              {{
                'CARDS.MONITORING_CARD.VEHICLE_MONITORING.GROUPS' | translate
              }}
            </div>
            <div class="info-bus-detail-contents-title-2">
              {{ groups }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="info-bus-telematics-container"
        *ngIf="
          serialNumber || telematicType || softwareVersion || lastConnection
        "
      >
        <div class="info-bus-detail-title">
          {{
            'CARDS.MONITORING_CARD.VEHICLE_MONITORING.TELEMATICS_MODULE'
              | translate
              | uppercase
          }}
        </div>
        <div class="info-bus-telematics-content">
          <div *ngIf="serialNumber" class="info-bus-detail-contents">
            <div class="info-bus-serial-number-contents">
              {{
                'CARDS.MONITORING_CARD.VEHICLE_MONITORING.SERIAL_NUMBER'
                  | translate
              }}
            </div>
            <div class="info-bus-detail-contents-title-2">
              {{ serialNumber }}
            </div>
          </div>
          <div *ngIf="telematicType" class="info-bus-detail-contents">
            <div class="info-bus-detail-contents-title">
              {{ 'CARDS.MONITORING_CARD.VEHICLE_MONITORING.TYPE' | translate }}
            </div>
            <div class="info-bus-detail-contents-title-2">
              {{ telematicType }}
            </div>
          </div>
          <div *ngIf="softwareVersion" class="info-bus-detail-contents">
            <div class="info-bus-serial-number-contents">
              {{
                'CARDS.MONITORING_CARD.VEHICLE_MONITORING.SOFTWARE_VERSION'
                  | translate
              }}
            </div>
            <div class="info-bus-detail-contents-title-2">
              {{ softwareVersion }}
            </div>
          </div>
          <div
            *ngIf="lastConnection"
            class="info-bus-last-connection-container"
          >
            <div class="info-bus-title-container">
              <span class="info-bus-title-container-overflow">
                {{
                  'CARDS.MONITORING_CARD.VEHICLE_MONITORING.LAST_CONNECTION'
                    | translate
                }}
              </span>
            </div>
            <div class="info-bus-subtitle-container">
              {{ lastConnection | dateFormat }}
              <div class="info-bus-subtitle-container-divider"></div>
              <span class="info-bus-subtitle-container-overflow">
                {{ lastConnection | dateFormat }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="info-bus-maintenance-container"
        *ngIf="checkUp || changeOilDate"
      >
        <div class="info-bus-maintenance-container-title">
          {{
            'CARDS.MONITORING_CARD.VEHICLE_MONITORING.MAINTENANCE_SCHEDULE'
              | translate
              | uppercase
          }}
        </div>
        <div class="info-bus-maintenance-schedule-container">
          <div *ngIf="checkUp" class="info-bus-detail-contents">
            <div class="check-up-change-oil text-caption">
              <ad-icon
                glyph="maintenance-filled"
                color="neutral-400"
                class="spec-icon"
              >
              </ad-icon>
              <span class="text-ellipsis overflow-hidden">
                {{
                  'CARDS.MONITORING_CARD.VEHICLE_MONITORING.CHECK_UP'
                    | translate
                }}
              </span>
            </div>
            <div class="info-bus-subtitle-container" *ngIf="checkUpDate">
              {{ checkUpDate | dateFormat }}
              <div
                class="info-bus-subtitle-container-divider"
                *ngIf="checkUpDate"
              ></div>
              <span class="info-bus-subtitle-container-overflow">{{
                checkUp | date : 'shortTime' | lowercase
              }}</span>
            </div>
          </div>
          <div
            *ngIf="changeOilDate"
            class="info-bus-information-maintenance-schedule"
          >
            <div class="check-up-change-oil text-caption">
              <ad-icon
                glyph="oil"
                color="neutral-400"
                class="spec-icon"
              ></ad-icon>
              <span class="text-ellipsis overflow-hidden">
                {{
                  'CARDS.MONITORING_CARD.VEHICLE_MONITORING.CHANGE_OIL'
                    | translate
                }}
              </span>
            </div>
            <div class="info-bus-subtitle-container">
              {{ changeOilDate | dateFormat }}
              <div
                class="info-bus-subtitle-container-divider"
                *ngIf="changeOilDate"
              ></div>
              <span
                class="info-bus-subtitle-container-overflow"
                *ngIf="changeOil"
                >{{ changeOil | dateFormat }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
