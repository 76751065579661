import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { MapboxHelper } from './mapbox.helper';
import { CONFIGMapBox, IMapboxConfig } from './mapbox.interface';
import { MapboxService } from './mapbox.service';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [],
  exports: [],
})
export class MapboxModule {
  static forRoot(config: IMapboxConfig): ModuleWithProviders<MapboxModule> {
    return {
      ngModule: MapboxModule,
      providers: [
        { provide: CONFIGMapBox, useValue: config },
        MapboxService,
        MapboxHelper,
      ],
    };
  }
}
