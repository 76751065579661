import { Component, HostListener } from '@angular/core';

import { Store } from '@ngrx/store';

import { dateActions, getTimeLineStateFeature } from '../../../../store';

@Component({
  selector: 'astus-timeline-button',
  templateUrl: './timeline-button.component.html',
  styleUrls: ['./timeline-button.component.scss'],
})
export class TimelineButtonComponent {
  isMobile = window.innerWidth < 800;

  timelineLiveStatus = true; // by default go to "Live" mode

  constructor(private store: Store) {
    this.store.select(getTimeLineStateFeature).subscribe((timeline) => {
      this.timelineLiveStatus = timeline.timeLineStatus;
    });
  }

  onTimelineStatusChange(timelineStatusSelected: boolean) {
    this.timelineLiveStatus = timelineStatusSelected;

    // dispatch the timeline options to the store via action
    this.store.dispatch(
      dateActions.setTimelineLiveStatus({
        timelineLiveStatus: timelineStatusSelected,
      })
    );
  }

  @HostListener('window:resize')
  onResize() {
    this.isMobile = window.innerWidth < 800;
  }
}
