<div class="container" [ngClass]="{ 'is-open': this.showMenu }">
  <div class="main-content">
    <ad-drawer>
      <div class="logo-content">
        <img
          src="'../../../../../../assets/images/logo_astus.svg"
          class="astus-logo"
          alt="Astus Logo"
          title="Astus Logo"
        />
      </div>
      <ad-drawer-item
        *ngIf="notificationCenterFlagValue$ | async"
        (click)="toggleNotificationCenter()"
        [highlighted]="false"
        label="{{ 'NOTIFICATION_CENTER.TITLE' | translate }}"
        icon="notification"
        notificationType="digit"
        [notificationCount]="notificationCount"
      ></ad-drawer-item>

      <ad-separator></ad-separator>

      <ad-drawer-item
        (click)="closeMenu()"
        routerLink="supervision"
        routerLinkActive="is-active"
        [highlighted]="false"
        label="{{ 'MENU.LABELS.MAP' | translate }}"
        icon="map_options"
      ></ad-drawer-item>

      <ad-drawer-item
        routerLink="resources"
        routerLinkActive="is-active"
        (click)="closeMenu()"
        [highlighted]="false"
        label="{{ 'MENU.LABELS.RESOURCES' | translate }}"
        icon="resources"
      ></ad-drawer-item>

      <ad-drawer-item
        *ngIf="reportsFlagValue$ | async"
        routerLink="reports"
        routerLinkActive="is-active"
        (click)="closeMenu()"
        [highlighted]="false"
        label="{{ 'MENU.LABELS.REPORTS' | translate }}"
        icon="analytics"
      ></ad-drawer-item>

      <ad-drawer-item
        routerLink="settings"
        routerLinkActive="is-active"
        (click)="closeMenu()"
        [highlighted]="false"
        label="{{ 'MENU.LABELS.SETTINGS' | translate }}"
        icon="settings"
      ></ad-drawer-item>

      <ad-separator></ad-separator>
      <ad-drawer-item
        (click)="navigateToLegacy()"
        [highlighted]="false"
        label="{{ 'MENU.LABELS.LEGACY' | translate }}"
        icon="open_new"
      ></ad-drawer-item>

      <ad-drawer-item
        *ngIf="assistanceFlagValue$ | async"
        (click)="toggleAssistance()"
        [highlighted]="true"
        label="{{ 'ASSISTANCE.TITLE' | translate }}"
        icon="support"
      ></ad-drawer-item>
      <ad-drawer-item
        *ngIf="termsAndConditionsFlagValue$ | async"
        (click)="toggleTermsAndConditions()"
        [highlighted]="false"
        label="{{ 'MENU.LABELS.TERMS_AND_CONDITIONS' | translate }}"
        icon="contract"
      ></ad-drawer-item>
      <ad-drawer-item
        (click)="handleLogout()"
        [highlighted]="false"
        label="{{ 'MENU.LABELS.LOGOUT' | translate }}"
        icon="logout"
      ></ad-drawer-item>
    </ad-drawer>

    <!---Panels--->
    <ad-drawer-panel
      *ngIf="assistanceFlagValue$ | async"
      [isOpen]="showAssistance"
      [navOpen]="this.showMenu"
    >
      <ad-drawer
        headerTitle="{{ 'ASSISTANCE.TITLE' | translate }}"
        headerGlyph="chevron_left"
        (headerIconClicked)="toggleAssistance()"
      >
        <astus-assistance-page></astus-assistance-page>
      </ad-drawer>
    </ad-drawer-panel>

    <ad-drawer-panel
      *ngIf="notificationCenterFlagValue$ | async"
      [isOpen]="showNotificationCenter"
      [navOpen]="this.showMenu"
    >
      <ad-drawer
        headerTitle="{{ 'NOTIFICATION_CENTER.TITLE' | translate }}"
        headerGlyph="chevron_left"
        (headerIconClicked)="toggleNotificationCenter()"
        class="notification-center"
      >
        <astus-notification-center
          [notificationData]="this.notifications"
          [notificationCount]="this.notificationCount"
          (readAllNotifications)="readAllNotifications()"
        ></astus-notification-center>
      </ad-drawer>
    </ad-drawer-panel>

    <ad-drawer-panel
      *ngIf="termsAndConditionsFlagValue$ | async"
      [isOpen]="showTermsAndConditions"
      [navOpen]="this.showMenu"
    >
      <ad-drawer
        headerTitle="{{ 'TC_MENU.TITLE' | translate }}"
        headerGlyph="chevron_left"
        (headerIconClicked)="toggleTermsAndConditions()"
      >
        <ad-drawer-item
          (click)="closeMenu()"
          routerLink="equipment-warranty"
          routerLinkActive="is-active"
          [highlighted]="false"
          label="{{ 'TC_MENU.LABELS.WARRANTY' | translate }}"
          icon="page"
        ></ad-drawer-item>
        <ad-drawer-item
          (click)="closeMenu()"
          routerLink="privacy-policy"
          routerLinkActive="is-active"
          [highlighted]="false"
          label="{{ 'TC_MENU.LABELS.PRIVACY' | translate }}"
          icon="rds"
        ></ad-drawer-item>
        <ad-drawer-item
          (click)="closeMenu()"
          routerLink="license-agreement"
          routerLinkActive="is-active"
          [highlighted]="false"
          label="{{ 'TC_MENU.LABELS.LICENSE' | translate }}"
          icon="contract"
        ></ad-drawer-item>
      </ad-drawer>
    </ad-drawer-panel>
  </div>
</div>
