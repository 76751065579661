export enum controlPanelCircuitStatus {
  ON_TIME = 'on-time',
  NO_USER = 'no-user',
}

export enum controlPanelRouteStatusEnum {
  ON_TIME = 'on-time',
}

export enum controlPanelStopStatusEnum {
  ON_TIME = 'on-time',
  DONE = 'done',
}

export enum controlPanelUsersStatusEnum {
  ON_WAY = 'on-way',
  ON_DUTY = 'on-duty',
  OFF_DUTY = 'off-duty',
}
