/**
 * FMS API
 * In charge of providing data from the legacy Astus FMS API to the different FMS apps
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type VehicleType =
  | 'Autobus'
  | 'Automobile'
  | 'Camion'
  | 'Camionette'
  | 'Camion 18 roues'
  | 'Coach'
  | 'Fourgonette'
  | 'Autobus scolaire'
  | 'Autobus adapté'
  | 'Minibus';

export const VehicleType = {
  Autobus: 'Autobus' as VehicleType,
  Automobile: 'Automobile' as VehicleType,
  Camion: 'Camion' as VehicleType,
  Camionette: 'Camionette' as VehicleType,
  Camion18Roues: 'Camion 18 roues' as VehicleType,
  Coach: 'Coach' as VehicleType,
  Fourgonette: 'Fourgonette' as VehicleType,
  AutobusScolaire: 'Autobus scolaire' as VehicleType,
  AutobusAdapt: 'Autobus adapté' as VehicleType,
  Minibus: 'Minibus' as VehicleType,
};
