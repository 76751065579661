<astus-settings-button-layout
  [disabled]="!formChanged"
  [processState]="processState"
  (saveEmitter)="saveForm()"
  (cancelEmitter)="openCancelModal()"
  (resetProcessState)="
    processState = this.buttonEnums.READY; formGroup.markAsPristine()
  "
>
  <header class="header">
    <h1 class="title">
      {{ 'SETTINGS_NOTIFICATIONS.TITLE' | translate }}
    </h1>
    <p class="description">
      {{ 'SETTINGS_NOTIFICATIONS.DESCRIPTION' | translate }}
    </p>
  </header>
  <form [formGroup]="formGroup">
    <fieldset formGroupName="high">
      <div class="title">
        {{ 'SETTINGS_NOTIFICATIONS.SEVERE-ALERT-TITLE' | translate }}
      </div>
      <div class="with-dropdown">
        <ad-checkbox
          [text]="'SETTINGS_NOTIFICATIONS.SNACKBAR' | translate"
          formControlName="snackbar"
        ></ad-checkbox>
        <ad-dropdown
          *ngIf="formGroup.get('high.snackbar')?.value"
          class="time-dropdown"
          [items]="snackbarSecondsArray"
          formControlName="snackbarTime"
        ></ad-dropdown>
      </div>
      <ad-checkbox
        [text]="'SETTINGS_NOTIFICATIONS.MESSAGE' | translate"
        formControlName="message"
      ></ad-checkbox>
      <ad-checkbox
        [text]="'SETTINGS_NOTIFICATIONS.NOTIFICATIONS' | translate"
        formControlName="notifications"
      ></ad-checkbox>
      <ad-checkbox
        [text]="'SETTINGS_NOTIFICATIONS.EMAIL' | translate"
        formControlName="email"
      ></ad-checkbox>
      <ad-checkbox
        [text]="'SETTINGS_NOTIFICATIONS.BANNER-MESSAGE-SYSTEM' | translate"
        formControlName="banner"
      ></ad-checkbox>
    </fieldset>
    <fieldset formGroupName="medium">
      <div class="title">
        {{ 'SETTINGS_NOTIFICATIONS.MEDIUM-ALERT-TITLE' | translate }}
      </div>
      <div class="with-dropdown">
        <ad-checkbox
          [text]="'SETTINGS_NOTIFICATIONS.SNACKBAR' | translate"
          formControlName="snackbar"
        ></ad-checkbox>
        <ad-dropdown
          *ngIf="formGroup.get('medium.snackbar')?.value"
          class="time-dropdown"
          [items]="snackbarSecondsArray"
          formControlName="snackbarTime"
        ></ad-dropdown>
      </div>
      <ad-checkbox
        [text]="'SETTINGS_NOTIFICATIONS.MESSAGE' | translate"
        formControlName="message"
      ></ad-checkbox>
      <ad-checkbox
        [text]="'SETTINGS_NOTIFICATIONS.NOTIFICATIONS' | translate"
        formControlName="notifications"
      ></ad-checkbox>
      <ad-checkbox
        [text]="'SETTINGS_NOTIFICATIONS.EMAIL' | translate"
        formControlName="email"
      ></ad-checkbox>
      <ad-checkbox
        [text]="'SETTINGS_NOTIFICATIONS.BANNER-MESSAGE-SYSTEM' | translate"
        formControlName="banner"
      ></ad-checkbox>
    </fieldset>
    <fieldset formGroupName="low">
      <div class="title">
        {{ 'SETTINGS_NOTIFICATIONS.WEAK-ALERT-TITLE' | translate }}
      </div>
      <div class="with-dropdown">
        <ad-checkbox
          [text]="'SETTINGS_NOTIFICATIONS.SNACKBAR' | translate"
          formControlName="snackbar"
        ></ad-checkbox>
        <ad-dropdown
          *ngIf="formGroup.get('low.snackbar')?.value"
          class="time-dropdown"
          [items]="snackbarSecondsArray"
          formControlName="snackbarTime"
        ></ad-dropdown>
      </div>
      <ad-checkbox
        [text]="'SETTINGS_NOTIFICATIONS.MESSAGE' | translate"
        formControlName="message"
      ></ad-checkbox>
      <ad-checkbox
        [text]="'SETTINGS_NOTIFICATIONS.NOTIFICATIONS' | translate"
        formControlName="notifications"
      ></ad-checkbox>
      <ad-checkbox
        [text]="'SETTINGS_NOTIFICATIONS.EMAIL' | translate"
        formControlName="email"
      ></ad-checkbox>
      <ad-checkbox
        [text]="'SETTINGS_NOTIFICATIONS.BANNER-MESSAGE-SYSTEM' | translate"
        formControlName="banner"
      ></ad-checkbox>
    </fieldset>
    <fieldset formGroupName="system">
      <div class="title">
        {{ 'SETTINGS_NOTIFICATIONS.SYSTEM-ALERT-TITLE' | translate }}
      </div>
      <div class="with-dropdown">
        <ad-checkbox
          [text]="'SETTINGS_NOTIFICATIONS.SNACKBAR' | translate"
          formControlName="snackbar"
        ></ad-checkbox>
        <ad-dropdown
          *ngIf="formGroup.get('system.snackbar')?.value"
          class="time-dropdown"
          [items]="snackbarSecondsArray"
          formControlName="snackbarTime"
        ></ad-dropdown>
      </div>
      <ad-checkbox
        [text]="'SETTINGS_NOTIFICATIONS.MESSAGE' | translate"
        formControlName="message"
      ></ad-checkbox>
      <ad-checkbox
        [text]="'SETTINGS_NOTIFICATIONS.NOTIFICATIONS' | translate"
        formControlName="notifications"
      ></ad-checkbox>
      <ad-checkbox
        [text]="'SETTINGS_NOTIFICATIONS.EMAIL' | translate"
        formControlName="email"
      ></ad-checkbox>
      <ad-checkbox
        [text]="'SETTINGS_NOTIFICATIONS.BANNER-MESSAGE-SYSTEM' | translate"
        formControlName="banner"
      ></ad-checkbox>
    </fieldset>
    <fieldset formGroupName="schedule">
      <div class="title">
        {{ 'SETTINGS_NOTIFICATIONS.SCHEDULE.TITLE' | translate }}
      </div>
      <p class="description">
        {{
          'SETTINGS_NOTIFICATIONS.SCHEDULE.DESCRIPTION.FIRST-PART' | translate
        }}
      </p>
      <p class="secondPart">
        {{
          'SETTINGS_NOTIFICATIONS.SCHEDULE.DESCRIPTION.SECOND-PART' | translate
        }}
      </p>
      <div class="schedule-fields">
        <ad-dropdown
          class="schedule-dropdown"
          formControlName="days"
          [items]="scheduleDaysArray"
          (dropdownElementChange)="checkForScheduleCustom($event)"
        ></ad-dropdown>
        <div class="schedule-fields-hours">
          <ad-dropdown
            class="hours-dropdown"
            [items]="scheduleStartHoursArray"
            formControlName="timeStart"
            (dropdownElementChange)="timeStartClicked($event)"
          ></ad-dropdown>

          <span>
            {{ 'SETTINGS_NOTIFICATIONS.SCHEDULE.TO' | translate }}
          </span>
          <ad-dropdown
            class="hours-dropdown"
            [items]="scheduleEndHoursArray"
            formControlName="timeEnd"
            (dropdownElementChange)="timeEndClicked($event)"
          ></ad-dropdown>
        </div>
      </div>
    </fieldset>
    <fieldset>
      <div class="title">
        {{ 'SETTINGS_NOTIFICATIONS.INACTIVITY.TITLE' | translate }}
      </div>
      <ad-dropdown
        class="inactivity-dropdown"
        formControlName="inactivity"
        [label]="'SETTINGS_NOTIFICATIONS.INACTIVITY.DESCRIPTION' | translate"
        [items]="inactivityArray"
      ></ad-dropdown>
    </fieldset>
  </form>
</astus-settings-button-layout>

<ad-modal
  [isOpen]="isWeekdayModalOpen"
  [closeOnOutsideClick]="false"
  (outsideModalClick)="weekdayModalClose()"
>
  <p top class="text-p3">
    {{
      'SETTINGS_NOTIFICATIONS.SCHEDULE.DAYS-DROPDOWN.WEEKDAY-PICKER.TITLE'
        | translate
    }}
  </p>
  <ng-container middle>
    <ad-weekday-picker
      [selectedDays]="currWeekdays"
      (selectedDaysChange)="weekdayChangeValue($event)"
      [daysValue]="weekdaysTranslations"
    ></ad-weekday-picker>
  </ng-container>
  <div bottom class="weekdays-btn">
    <button
      type="button"
      astus-design-button
      kind="outlined"
      color="neutral"
      (click)="weekdayModalClose()"
    >
      {{
        'SETTINGS_NOTIFICATIONS.SCHEDULE.DAYS-DROPDOWN.WEEKDAY-PICKER.CANCEL'
          | translate
      }}
    </button>
    <button type="button" astus-design-button (click)="weekdayModalSave()">
      {{
        'SETTINGS_NOTIFICATIONS.SCHEDULE.DAYS-DROPDOWN.WEEKDAY-PICKER.SAVE'
          | translate
      }}
    </button>
  </div>
</ad-modal>

<ad-modal
  [isOpen]="isCancelModalOpen"
  class="modal-cancel"
  (outsideModalClick)="closeCancelModal()"
>
  <div top class="information-container">
    <div>
      <ad-icon [glyph]="'alert'" class="icon" [color]="'warning-400'"></ad-icon>
    </div>
    <div class="text-p1--semibold title-text">
      {{
        'SETTINGS_NOTIFICATIONS.CANCEL_SETTING_NOTIFICATIONS_MODAL.TITLE'
          | translate
      }}
    </div>
    <div class="text-p3 subtitle-text">
      {{
        'SETTINGS_NOTIFICATIONS.CANCEL_SETTING_NOTIFICATIONS_MODAL.SUBTITLE'
          | translate
      }}
    </div>
  </div>
  <div middle class="button-container">
    <button
      astus-design-button
      [size]="'md'"
      kind="outlined"
      color="neutral"
      class="cancel-button"
      (click)="closeCancelModal()"
    >
      {{
        'SETTINGS_NOTIFICATIONS.CANCEL_SETTING_NOTIFICATIONS_MODAL.CANCEL_BUTTON'
          | translate
      }}
    </button>
    <button
      astus-design-button
      [size]="'md'"
      kind="filled"
      color="primary"
      class="confirm-button"
      (click)="resetForm()"
    >
      {{
        'SETTINGS_NOTIFICATIONS.CANCEL_SETTING_NOTIFICATIONS_MODAL.CONFIRM_BUTTON'
          | translate
      }}
    </button>
  </div>
</ad-modal>
