import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';

import { AuthService } from '@auth0/auth0-angular';
import { LaunchDarklyService } from '@common/ts-feature-flag';
import { Store } from '@ngrx/store';

import {
  getNotifications,
  menuActions,
  notificationActions,
  notificationInterface,
  showMenuFeature,
} from '../../../store';

@Component({
  selector: 'astus-menu-navbar',
  templateUrl: './menu-navbar.component.html',
  styleUrls: ['./menu-navbar.component.scss'],
})
export class MenuNavbarComponent {
  showMenu!: boolean;
  showAssistance = false;
  showNotificationCenter = false;
  notifications: notificationInterface[] = [];
  notificationCount = 0;
  showTermsAndConditions = false;

  constructor(
    private auth: AuthService,
    @Inject(DOCUMENT) private doc: Document,
    private store: Store,
    private launchDarklyService: LaunchDarklyService
  ) {
    this.store.select(showMenuFeature).subscribe((showMenu) => {
      this.showMenu = showMenu;
      if (!showMenu) {
        this.showNotificationCenter = false;
        this.showAssistance = false;
        this.showTermsAndConditions = false;
      }
    });
    this.store.select(getNotifications).subscribe((notifications) => {
      this.notifications = notifications;
      this.notificationCount = notifications.filter((n) => n.unread).length;
    });
  }

  assistanceFlagValue$ =
    this.launchDarklyService.getFlagValue$('Aide_Assistance');

  notificationCenterFlagValue$ = this.launchDarklyService.getFlagValue$(
    'CentreDesNotifications'
  );

  readAllNotifications() {
    this.store.dispatch(notificationActions.markAllAsRead());
  }

  termsAndConditionsFlagValue$ = this.launchDarklyService.getFlagValue$(
    'Aide_TermesEtConditions'
  );

  reportsFlagValue$ = this.launchDarklyService.getFlagValue$('rapports');

  closeMenu() {
    this.store.dispatch(menuActions.closeMenu());
  }

  toggleAssistance() {
    this.showAssistance = !this.showAssistance;
  }

  toggleNotificationCenter() {
    this.showNotificationCenter ? this.readAllNotifications() : null;
    this.showNotificationCenter = !this.showNotificationCenter;
    this.showAssistance = false;
  }

  toggleTermsAndConditions() {
    this.showTermsAndConditions = !this.showTermsAndConditions;
  }

  handleLogout(): void {
    this.closeMenu();
    this.auth.logout({
      logoutParams: {
        returnTo: this.doc.location.origin,
      },
    });
  }

  navigateToLegacy() {
    window.location.href = 'https://fms-pp.astus.com/';
  }
}
