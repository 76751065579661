<div class="search-container">
  <div class="search-input-container" (click)="handleSearchInputClick()">
    <ad-icon
      class="search-icon"
      glyph="search"
      svgClass="search-icon-svg"
      color="neutral-600"
    ></ad-icon>
    <input
      [value]="value ? value : ''"
      [placeholder]="placeholder"
      (keyup)="onInputChange($event)"
      class="search-input text-p1"
      [ngClass]="{
        'has-trailing-icon': trailingIcon,
        'is-open':
          dropdownOpened &&
          ((searchResult.length && value) || searchHistory?.length),
        'small-size text-p3': size === 'S'
      }"
    />
    <ad-icon
      *ngIf="trailingIcon"
      (click)="trailingIconClick($event)"
      class="search-icon-trailing"
      [ngClass]="{
        'icon-disabled': !value,
        'is-not-opened': !dropdownOpened && !value
      }"
      [glyph]="trailingIcon"
      svgClass="search-icon-svg"
      [color]="value ? 'neutral-600' : 'neutral-300'"
    ></ad-icon>
  </div>
  <div
    class="list-ctn"
    [ngClass]="{
      'is-open': dropdownOpened && (value || searchHistory?.length)
    }"
  >
    <ul
      [class]="SEARCH_LIST_OPTIONS_BASE_STRING"
      [ngClass]="{ 'history-list': !value }"
    >
      <li
        class="list-item text-p3"
        [ngClass]="{ 'is-hovered': hoveredIndex === i }"
        *ngFor="let search of searchList; let i = index"
        [id]="SEARCH_LIST_OPTION_BASE_STRING + i"
        [value]="search"
        (mouseenter)="hoveredIndex = i"
        (mouseleave)="hoveredIndex = null"
      >
        <div class="list-item-text" (click)="clickSelectItem(search)">
          <ad-icon
            *ngIf="!value"
            glyph="history"
            color="neutral-400"
            svgClass="search-icon-svg"
            class="history-icon"
          ></ad-icon>
          <span class="list-item-name">
            {{ search }}
          </span>
        </div>
      </li>
      <li class="list-empty text-caption" *ngIf="!searchList.length">
        <div class="list-empty-title text-p3">
          {{ emptySearchTitle }}
        </div>
        <div class="list-empty-text">
          {{ emptySearchFirstText }}
          "{{ value }}"
          {{ emptySearchSecondText }}
        </div>
      </li>
    </ul>
  </div>
</div>
