import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  ColorType,
  popoverOptionType,
  progressInfoStatusEnum,
} from '@common/ng-design-system';
import { TranslateService } from '@ngx-translate/core';
import { Subject, combineLatest, takeUntil } from 'rxjs';

import { appRoutesEnum, resourceTabType } from '../../../../app-routing.module';
import { controlPanelCardDataRoutesInterface } from '../../../map-control-panel/map-control-panel.component.d';
import { MapListCircuitCardComponent } from '../../../map-list/map-list-circuit-card/map-list-circuit-card.component';

@Component({
  selector: 'astus-circuit-monitoring-card',
  templateUrl: './circuit-monitoring-card.component.html',
  styleUrls: ['./circuit-monitoring-card.component.scss'],
})
export class CircuitMonitoringCardComponent implements OnInit, OnDestroy {
  @Input() isLoading = false;

  // Info card stuff
  @Input() id: string | number | undefined;
  @Input() title = '';
  @Input() titleSmall = '';
  @Input() subtitle = '';
  @Input() subtitleSmall = '';
  @Input() infoRoute = '';
  @Input() infoUser = '';
  @Input() userName = '';
  @Input() vehicleName = '';

  @Input() progressPercentage: number | undefined;
  @Input() progressTime = '';
  @Input() progressStatus: progressInfoStatusEnum = progressInfoStatusEnum.LOW;

  @Input() routes: controlPanelCardDataRoutesInterface[] = [];

  @Output() moreOptionsEvent = new EventEmitter<boolean>();

  @Output() backClickEvent = new EventEmitter<boolean>();

  @Output() routeSelectClickEvent =
    new EventEmitter<controlPanelCardDataRoutesInterface>();

  moreOptions: popoverOptionType[] = [];
  moreOptionEditTag = 'edit-circuit';
  showMoreOptions = false;

  destroy$ = new Subject<void>();

  constructor(
    public mapListCircuitCardComponent: MapListCircuitCardComponent,
    private translateService: TranslateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    combineLatest([this.translateService.stream('CARDS.MODIFY_CIRCUIT')])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([modifyCircuit]) => {
        if (this.id !== undefined) {
          this.moreOptions = [
            {
              id: `${this.moreOptionEditTag}-${this.id}`,
              value: modifyCircuit,
            },
          ];
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * @description
   * Color for the progress bar based on the data provided.
   * Progress status is used to determine the color.
   * @returns ColorType
   */
  public get progressType(): ColorType {
    switch (this.progressStatus) {
      case 'low':
      case 'medium':
        return 'warning';
      case 'high':
        return 'danger';
      default:
        return 'primary';
    }
  }

  /**
   * @description
   * On click select route, emit the event to the parent component.
   * @param {controlPanelCardDataRoutesInterface} route
   * @returns {void}
   */
  selectRoute(route: controlPanelCardDataRoutesInterface): void {
    this.routeSelectClickEvent.emit(route);
  }

  /**
   * @description
   * On click of the more options, emit the event to the parent component.
   * @returns {void}
   */
  onMoreOptionsClick(): void {
    this.showMoreOptions = !this.showMoreOptions;
    this.moreOptionsEvent.emit();
  }

  /**
   * @description Handles the popover option click
   * Checks for the "Modify circuit" option and navigates to the circuit edit form in Resources
   * @param {popoverOptionType} option
   */
  handleMoreOptionClick(option: popoverOptionType) {
    if (
      option.id?.indexOf(this.moreOptionEditTag) !== -1 &&
      this.id !== undefined
    ) {
      const url = `${appRoutesEnum.RESOURCES}/${resourceTabType.CIRCUITS}/${this.id}`;
      this.router.navigate([url]);
    }
  }
}
