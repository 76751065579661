<ad-card
  *ngIf="vehicle"
  (hoveredCardChange)="hoveredEvent.emit(vehicle)"
  [moreOptions]="moreOptions"
  (popoverOptionSelect)="handleMoreOptionClick($event)"
  class="card-content"
  (clickCardEvent)="cardClick.emit({ data: vehicle })"
  (assignEvent)="assignEvent.emit(vehicle)"
  [title]="vehicle.title || ''"
  [titleSmall]="vehicle.title2 || ''"
  [subtitle]="vehicle.subtitle || ''"
  [subtitleSmall]="vehicle.subtitle2 || ''"
  [hideAvatars]="true"
  [infoItems]="[
    { status: statusColor, text: statusText },
    { icon: 'seat', text: vehicle.textInfo || '' },
    { icon: 'fuel', text: vehicle.textInfo2 || '' }
  ]"
  [ctaButtonText]="!hideAssignButton ? ('CARDS.ASSIGN_BTN' | translate) : ''"
  [tooltipPosition]="isFirst ? 'bottom' : 'top'"
  [isSelected]="isSelected"
></ad-card>
