import { Inject, Injectable, NgZone } from '@angular/core';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { BehaviorSubject, Observable, firstValueFrom, map } from 'rxjs';

import { CONFIG } from './launch-darkly.constants';
import { FeatureGroup, TenantsType } from './launch-darkly.enum';
import {
  LaunchDarklyConfigInterface,
  LaunchDarklyContextInterface,
} from './launch-darkly.interface';

@Injectable({
  providedIn: 'root',
})
export class LaunchDarklyService {
  ldClientIsReady = new BehaviorSubject<boolean>(false);
  ldClientIsInitiated = new BehaviorSubject<boolean>(false);
  private ldClient: LDClient.LDClient | null = null;
  private flagsSubject: BehaviorSubject<{ [key: string]: boolean }> =
    new BehaviorSubject({});

  flags$: Observable<{ [key: string]: boolean }> =
    this.flagsSubject.asObservable();

  constructor(
    private ngZone: NgZone,
    @Inject(CONFIG) private config: LaunchDarklyConfigInterface
  ) {}

  /**
   * Get the value of a feature flag as an observable.
   * @param flagKey - The key of the feature flag.
   * @param defaultValue - The default value if the flag is not found.
   * @returns {Observable<boolean>} An boolean observable that emits the value of the feature flag.
   */
  getFlagValue$(flagKey: string, defaultValue = false): Observable<boolean> {
    return this.flags$.pipe(map((flags) => flags[flagKey] || defaultValue));
  }

  /**
   * Get the value of a feature flag.
   * Should be use in very specific cases where the flag value is needed synchronously.
   * The getFlagValue$ method should be used in almost every cases.
   * @param flagKey - The key of the feature flag.
   * @param defaultValue - The default value if the flag is not found.
   * @returns {boolean} The value of the feature flag as a boolean.
   */
  getFlagValue(flagKey: string, defaultValue = false): boolean {
    if (this.ldClient) {
      return this.ldClient.variation(flagKey, defaultValue);
    }
    return defaultValue;
  }

  /**
   * Get all the feature flags and their values synchronously.
   * @returns {boolean} An object containing all the feature flags and their values.
   */
  getAllFlags(): { [key: string]: boolean } {
    if (this.ldClient) {
      const flags = this.ldClient.allFlags();
      this.flagsSubject.next(flags);
      return flags;
    }
    return {};
  }

  /**
   * Infer, from the tenant, the feature group to pass to launch darkly
   * @param TenantsType - A valid tenant (or localhost)
   * @return {FeatureGroup} An enum corresponding to the group to pass to launch darkly in order to get the right features for your tenant.
   */
  getClientContext(tenant: string | undefined): FeatureGroup {
    switch (tenant) {
      case TenantsType.CLIENTHORIZON:
      case TenantsType.VIDEOTRON:
        return FeatureGroup.HORIZON;
      case TenantsType.CLIENTSCOLAIRE:
      case TenantsType.LOCALHOST:
        return FeatureGroup.SCOLAIRE;
      default:
        return FeatureGroup.HORIZON;
    }
  }

  async initLaunchDarklyWithResolve(context: LaunchDarklyContextInterface) {
    const isClientReady = await firstValueFrom(this.ldClientIsReady);
    const isClientInitialized = await firstValueFrom(this.ldClientIsInitiated);

    return new Promise((resolve) => {
      if (!isClientReady) {
        if (!this.ldClient || !isClientInitialized) {
          this.ldClient = LDClient.initialize(
            this.config.clientSideId,
            context,
            {
              logger: undefined,
            }
          );
          this.ldClientIsInitiated.next(true);
        }

        this.ldClient.on('ready', () => {
          this.ldClientIsReady.next(true);
          this.ngZone.run(() => {
            this.updateFlags();
          });
          resolve('ready');
        });

        this.ldClient.on('change', () => {
          this.ngZone.run(() => {
            this.updateFlags();
          });
        });
      } else {
        resolve('ready');
      }
    });
  }

  private updateFlags() {
    if (this.ldClient) {
      const flags = this.ldClient.allFlags();
      this.flagsSubject.next(flags);
    }
  }
}
