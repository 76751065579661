<div class="upload-container" *ngIf="!isMobile">
  <div class="upload-wrapper">
    <ad-upload
      [fileTypes]="acceptedFiles"
      fontIcon="upload-filled"
      label="{{
        'UPLOAD_WIDGET_' + selectedTab.toUpperCase() + '.LABEL' | translate
      }}"
      linkText="{{
        'UPLOAD_WIDGET_' + selectedTab.toUpperCase() + '.LINK_TEXT' | translate
      }}"
      buttonText="{{
        'UPLOAD_WIDGET_' + selectedTab.toUpperCase() + '.BUTTON_TEXT'
          | translate
      }}"
      footerText="{{
        'UPLOAD_WIDGET_' + selectedTab.toUpperCase() + '.FOOTER_TEXT'
          | translate
      }}"
      footerTextColored="{{
        'UPLOAD_WIDGET_' + selectedTab.toUpperCase() + '.FOOTER_TEXT_COLORED'
          | translate
      }}"
      footerIcon="download"
      (uploadDropEvent)="onFileSelected($event)"
      (change)="onFileSelected($event)"
      (buttonEvent)="onButtonClick($event)"
    ></ad-upload>
  </div>

  <div class="file-container" #fileContainer>
    <div
      class="scrollbar-container"
      #scrollBar
      [ngClass]="{
        'border scroll-bar': uploads.length > 3
      }"
    >
      <div class="uploaded-file-container">
        <ng-container *ngFor="let upload of uploads; let i = index">
          <div class="file-info-container" #fileInfoContainer>
            <div class="file-info-wrapper">
              <div class="file-info-icon-wrapper">
                <ad-icon glyph="file" color="neutral-700"></ad-icon>
              </div>
              <div class="file-info-block">
                <h1 class="text-p3--semibold max-two-lines">
                  {{ upload.fileName }}
                </h1>
                <p class="text-caption max-two-lines">
                  {{ errorMessage }}
                  {{ upload.error === true ? errorMessage : progressMessage }}
                </p>
              </div>
            </div>
            <div class="file-info-icon-wrapper">
              <ad-icon
                class="pointer"
                glyph="close"
                color="neutral-700"
                (click)="removeFile(i)"
              ></ad-icon>
            </div>
          </div>
          <div class="progress-bar-wrapper">
            <ad-progress-bar-line
              *ngIf="upload.error === false"
              [kind]="'determinate'"
              [color]="'primary'"
              [progress]="upload.progress"
            ></ad-progress-bar-line>
            <ad-progress-bar-line
              *ngIf="upload.error === true"
              [kind]="'determinate'"
              [color]="'danger'"
              [progress]="100"
            ></ad-progress-bar-line>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<!-- Display the mobile upload container -->
<div *ngIf="isMobile" class="mobile-upload-container">
  <button
    astus-design-button
    [size]="'md'"
    kind="filled"
    color="primary"
    class="button cancel-button"
    (click)="onButtonClick($event)"
  >
    {{
      'UPLOAD_WIDGET_' + selectedTab.toUpperCase() + '.BUTTON_TEXT' | translate
    }}
  </button>

  <div class="file-model-download">
    <span class="text-caption">
      {{
        'UPLOAD_WIDGET_' + selectedTab.toUpperCase() + '.FOOTER_TEXT'
          | translate
      }}
      <span class="text-caption--semibold highlighted-text">
        {{
          'UPLOAD_WIDGET_' + selectedTab.toUpperCase() + '.FOOTER_TEXT_COLORED'
            | translate
        }}
      </span>
    </span>

    <div class="download-icon-container">
      <ad-icon
        glyph="download"
        statusIcon
        class="download-icon"
        [color]="'primary-400'"
      ></ad-icon>
    </div>
  </div>
</div>

<!-- Display upload drawer -->
<astus-upload-options-drawer
  *ngIf="uploadDrawerOpen && isMobile"
  [resourceSelected]="selectedTab"
  (drawerIsClosing)="uploadMobileDrawerClosed()"
  (createResource)="onCreateResourcesButtonClick()"
  (change)="onFileSelected($event)"
></astus-upload-options-drawer>
