import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { GoogleMapsHelper } from './google-maps.helper';

import {
  CONFIGGoogleMaps,
  GoogleMapsDirectionsResponse,
  IGoogleMapsComputeRoutesRequestBody,
  IGoogleMapsConfig,
  IGoogleMapsCoordinates,
  IGoogleMapsMapData,
} from './google-maps.interface';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  constructor(
    private http: HttpClient,
    @Inject(CONFIGGoogleMaps) private config: IGoogleMapsConfig,
    private googleMapsHelper: GoogleMapsHelper
  ) {}

  /**
   * This function is meant to fetch data. Since the data is not defined yet, it return mock data.
   * In the future, this function is meant to either be completely edited or deleted
   *
   * @returns A mock data to be used with the map and APIs
   */
  getMapData(): Observable<IGoogleMapsMapData> {
    return of({
      origin: {
        lat: 45.54056727568264,
        lng: -73.57309753631544,
      },
      destination: {
        lat: 45.49197243286864,
        lng: -73.63274986476522,
      },
      intersections: [
        {
          lat: 45.51654894177164,
          lng: -73.59684638527268,
        },
        {
          lat: 45.517256393898116,
          lng: -73.61808359934496,
        },
        {
          lat: 45.51118177342632,
          lng: -73.62323623489415,
        },
      ],
      areas: [
        {
          styles: {
            strokeColor: '#810FCB',
            strokeOpacity: 1.0,
            strokeWeight: 3.0,
            fillColor: '#810FCB',
            fillOpacity: 0.5,
          },
          paths: [
            { lat: 45.54671148523983, lng: -73.6074438571672 },
            { lat: 45.52385745846055, lng: -73.55524112444311 },
            { lat: 45.482147792107405, lng: -73.60679132347478 },
            { lat: 45.503189424613346, lng: -73.66643294561204 },
          ],
          innerPaths: [
            [
              { lat: 45.52925184577303, lng: -73.61775389734684 },
              { lat: 45.52330884735531, lng: -73.62323518428288 },
              { lat: 45.515719182780536, lng: -73.62936900537797 },
              { lat: 45.51068932300919, lng: -73.6171013631878 },
              { lat: 45.51498759475222, lng: -73.60900993961558 },
              { lat: 45.52559469028125, lng: -73.61122855575634 },
            ],
            [
              { lat: 45.5033466665723, lng: -73.61317574120777 },
              { lat: 45.5041839872232, lng: -73.62293932778948 },
              { lat: 45.50060363239209, lng: -73.6250403527501 },
              { lat: 45.4971963091048, lng: -73.61754257740043 },
            ],
          ],
        },
        {
          styles: {
            strokeColor: 'seagreen',
            strokeOpacity: 1.0,
            strokeWeight: 3.0,
            fillColor: 'seagreen',
            fillOpacity: 0.5,
          },
          paths: [
            { lat: 45.6143339238522, lng: -73.61617784659856 },
            { lat: 45.58367795726617, lng: -73.51150979167845 },
            { lat: 45.54155954247148, lng: -73.53863307501324 },
            { lat: 45.55544019592741, lng: -73.62104612822274 },
            { lat: 45.57321216709058, lng: -73.66207878762663 },
            { lat: 45.61907452967987, lng: -73.6327044062411 },
          ],
        },
      ],
    });
  }

  /**
   * Takes all the waypoints and calls the Routes API
   *
   * @param positionPoints - An array of all the positions of the route. Max 25 waypoints per request
   * @returns The Google Maps Direction API response
   */
  getComputedDirections(
    positionPoints: IGoogleMapsCoordinates[],
    callback: (response: GoogleMapsDirectionsResponse) => void
  ): void {
    const positionOrigin = positionPoints[0];
    const positionDestination = positionPoints.slice(-1)[0];
    const positionIntersections = positionPoints.slice(1, -1);

    this.getComputedDirectionsRequest(
      positionOrigin,
      positionDestination,
      positionIntersections
    ).subscribe((response: GoogleMapsDirectionsResponse) => {
      callback(response);
    });
  }

  /**
   * Calls the Direction API with the positions and returns an observable
   *
   * @param positionOrigin
   * @param positionDestination
   * @param positionIntersections
   * @returns An observable with the direction detail
   */
  getComputedDirectionsRequest(
    positionOrigin: IGoogleMapsCoordinates,
    positionDestination: IGoogleMapsCoordinates,
    positionIntersections?: IGoogleMapsCoordinates[]
  ): Observable<GoogleMapsDirectionsResponse> {
    const API_URL = 'https://routes.googleapis.com/directions/v2:computeRoutes';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Goog-Api-Key': this.config.apiKey,
        'X-Goog-FieldMask':
          'routes.duration,routes.distanceMeters,routes.polyline.encodedPolyline,routes.legs.steps',
      }),
    };

    // GoogleMapsComputeRoutesRequestBody
    let body: IGoogleMapsComputeRoutesRequestBody = {
      origin: this.googleMapsHelper.formatLatLng(positionOrigin),
      destination: this.googleMapsHelper.formatLatLng(positionDestination),
      travelMode: 'DRIVE',
      units: 'METRIC',
    };

    if (positionIntersections) {
      body = {
        ...body,
        intermediates: positionIntersections.map(
          (point: IGoogleMapsCoordinates) =>
            this.googleMapsHelper.formatLatLng(point)
        ),
      };
    }

    return this.http.post<GoogleMapsDirectionsResponse>(
      API_URL,
      body,
      httpOptions
    );
  }
}
