<div class="drag-boundary" [ngStyle]="divStyle">
  <div
    #drawer
    popover
    cdkDrag
    [cdkDragFreeDragPosition]="dragPosition"
    cdkDragBoundary=".drag-boundary"
    (cdkDragEnded)="onDragEnd()"
    class="drawer-container"
  >
    <!-- Drawer grabber -->
    <div class="drawer-grabber-container" cdkDragHandle #grabber>
      <div class="drawer-grabber"></div>
    </div>
    <!-- Drawer content -->
    <!-- basically it content projected by the parent -->
    <ng-content select="[drawerContent]"></ng-content>
  </div>
</div>
