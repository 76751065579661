import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateText',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, ellipsis: string, length: number): string {
    if (!value) return '';
    return value.length > length ? value.slice(0, length) + ellipsis : value;
  }
}
