import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
} from '@angular/router';

/**
 * @description
 * CustomRouteReuseStrategy is an Angular implementation of the RouteReuseStrategy interface,
 * providing a custom strategy for determining whether a component should be reused during navigation.
 * This strategy allows developers to define specific rules for component reuse, detachment, storage, and reattachment
 * based on their application requirements. The methods implemented in this class include shouldReuseRoute, shouldDetach,
 * store, shouldAttach, and retrieve, offering fine-grained control over the behavior of component instances
 * during route transitions.
 */
export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  shouldDetach(): boolean {
    return false;
  }

  store(): void {
    // keep empty
  }

  shouldAttach(): boolean {
    return false;
  }

  retrieve(): DetachedRouteHandle | null {
    return null;
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    return (
      future.routeConfig === curr.routeConfig || future.data['reuseComponent']
    );
  }
}
