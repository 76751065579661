<div class="ui-container">
  <div class="ui-layer-container">
    <ad-tooltip
      [text]="'MAP.UI.LAYERS' | translate"
      position="right"
      color="neutral"
    >
      <button
        ad-fab
        kind="tonal"
        glyph="map_options"
        [forceIconColor]="
          isLayerPreviewShown || isLayerSelectorShown
            ? 'primary-400'
            : 'neutral-700'
        "
        size="sm"
        (click)="handleShowLayerPreview()"
      ></button>
    </ad-tooltip>

    <astus-map-layer-preview
      class="ui-layer-preview"
      [isShown]="isLayerPreviewShown"
      (moreClicked)="handleShowLayerSelector()"
      [isLayerTraffic]="isLayerTraffic"
      [isLayerBike]="isLayerBike"
      (setTrafficLayer)="setTrafficLayer.emit()"
      (setBikeLayer)="setBikeLayer.emit()"
    ></astus-map-layer-preview>
  </div>
  <ad-fab-grouped class="ui-zoom-container">
    <ad-tooltip
      [text]="'MAP.UI.ZOOM_IN' | translate"
      position="right"
      color="neutral"
    >
      <button
        class="ui-zoom-in"
        ad-fab
        kind="tonal"
        glyph="plus"
        color="primary"
        size="sm"
        grouped="first"
        (click)="handleZoomIn()"
      ></button>
    </ad-tooltip>
    <ad-tooltip
      [text]="'MAP.UI.ZOOM_OUT' | translate"
      position="right"
      color="neutral"
    >
      <button
        class="ui-zoom-out"
        ad-fab
        kind="tonal"
        glyph="minus"
        color="primary"
        size="sm"
        grouped="item"
        (click)="handleZoomOut()"
      ></button>
    </ad-tooltip>
  </ad-fab-grouped>
  <ad-tooltip
    [text]="'MAP.UI.RESET' | translate"
    [position]="isMobile ? 'left' : 'right'"
    color="neutral"
  >
    <button
      ad-fab
      kind="tonal"
      glyph="reset"
      color="primary"
      size="sm"
      [disabled]="isResetDisabled"
      [ngClass]="[isResetDisabled ? 'is-reset-disabled' : 'is-reset-able']"
      (click)="handleReset()"
    ></button>
  </ad-tooltip>

  <astus-map-layer-selector
    #layerSelector
    class="ui-layer-selector"
    [isShown]="isLayerSelectorShown"
    [isLayerDefault]="isLayerDefault"
    [isLayerSatellite]="isLayerSatellite"
    [isLayerTraffic]="isLayerTraffic"
    [isLayerBike]="isLayerBike"
    [isLayerOutdoors]="isLayerOutdoors"
    (closeClicked)="closeLayorSelector()"
    (setDefaultLayer)="setDefaultLayer.emit()"
    (setSatelliteLayer)="setSatelliteLayer.emit()"
    (setTrafficLayer)="setTrafficLayer.emit()"
    (setBikeLayer)="setBikeLayer.emit()"
    (setOutdoorsLayer)="setOutdoorsLayer.emit()"
  ></astus-map-layer-selector>
</div>
