import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { fabGroupedOrientationType } from './fab-grouped';

@Component({
  selector: 'ad-fab-grouped',
  template: '<ng-content></ng-content>',
  styleUrls: ['./fab-grouped.component.scss'],
})
export class FabGroupedMolecule implements OnInit {
  @Input()
  orientation: fabGroupedOrientationType = 'vertical';

  @HostBinding('class') class = `is-${this.orientation}`;

  ngOnInit(): void {
    this.class = `is-${this.orientation}`;
  }
}
