<div class="infobox-container">
  <!-- Resources selected title  -->
  <header>
    <div class="header-title-container">
      <div class="text-p3--semibold title">
        <!-- render the vehicle title -->
        <span
          *ngIf="infoboxType === 'vehicles'"
          [ngClass]="{
            'empty-title': !vehicleInfoboxData?.name
          }"
        >
          {{
            vehicleInfoboxData && vehicleInfoboxData.name.length
              ? vehicleInfoboxData.name
              : ('INFOBOX.EMPTY_VEHICLE_TITLE' | translate)
          }}
        </span>

        <!-- render the user title -->
        <span
          *ngIf="infoboxType === 'users'"
          [ngClass]="{
            'empty-title': !userInfoboxData?.name
          }"
        >
          {{
            userInfoboxData?.name || ('INFOBOX.EMPTY_USER_TITLE' | translate)
          }}
        </span>
      </div>
      <div class="subtitle-container">
        <div class="status-container">
          <!-- render status for vehicle -->
          <span
            *ngIf="infoboxType === 'vehicles'"
            class="status"
            [ngClass]="{
              'empty-status':
                !vehicleInfoboxData?.name || !vehicleInfoboxData?.vehicleType
            }"
          ></span>
          <!-- render status for user -->
          <span
            *ngIf="infoboxType === 'users'"
            class="status"
            [ngClass]="{
              'empty-status':
                userInfoboxData?.rolesPermissions?.length === 0 ||
                !userInfoboxData?.name
            }"
          ></span>
        </div>
        <div class="text-footer subtitle">
          <!-- render subtitle for vehicle -->
          <span
            *ngIf="infoboxType === 'vehicles'"
            [ngClass]="{
              'empty-subtitle': !vehicleInfoboxData?.vehicleType
            }"
          >
            {{
              vehicleInfoboxData?.vehicleType ||
                ('INFOBOX.EMPTY_VEHICLE_SUBTITLE' | translate)
            }}
          </span>

          <!-- render subtitle for user -->
          <span
            *ngIf="infoboxType === 'users'"
            [ngClass]="{
              'empty-subtitle':
                userInfoboxData && userInfoboxData.rolesPermissions.length === 0
            }"
          >
            <!-- Use ternary here to check if the array of roles and permission is not empty  -->
            {{
              userInfoboxData?.rolesPermissions?.length
                ? userInfoboxData?.rolesPermissions?.join(', ')
                : ('INFOBOX.EMPTY_USER_SUBTITLE' | translate)
            }}
          </span>
        </div>
      </div>
    </div>

    <div #more_action_button class="more-action-icon">
      <div class="container" (click)="onMoreActionClick()">
        <ad-icon
          class="icon"
          [glyph]="'more-vertical'"
          [color]="'neutral-400'"
        ></ad-icon>
      </div>

      <!-- Overlay for more action click -->
      <!-- Should be delete the resources -->
      <div
        class="more-action-overlay"
        [ngClass]="{
        'more-action-overlay--open': moreActionShown,
        'more-action-overlay--close': !moreActionShown,
      }"
        (click)="onOverlayClick()"
      >
        <div class="overlay-content">
          <span class="text-p3" *ngIf="infoboxType === 'vehicles'">
            {{ 'INFOBOX.DELETE_BUTTON.VEHICLE' | translate }}</span
          >

          <button (click)="onDisableUserClick()">
            <span class="text-p3" *ngIf="infoboxType === 'users'">
              {{ 'INFOBOX.DELETE_BUTTON.USER' | translate }}</span
            >
          </button>
        </div>
      </div>
    </div>
  </header>

  <!-- Resources selected editable field -->
  <div class="content-container">
    <!-- Should inject form  -->
    <ng-content select="[infoBoxForm]"></ng-content>
  </div>

  <!-- Resources button  -->
  <footer>
    <button
      astus-design-button
      [size]="'sm'"
      kind="text"
      color="neutral"
      (click)="onCancelClick()"
    >
      {{ 'INFOBOX.CANCEL_BUTTON' | translate }}
    </button>
    <button
      astus-design-button
      [size]="'sm'"
      kind="filled"
      color="success"
      [disabled]="!!(!form?.valid || form?.pristine)"
      (click)="onSaveClick()"
    >
      {{ 'INFOBOX.SAVE_BUTTON' | translate }}
    </button>
  </footer>
</div>

<!-- Infobox Modals -->
<!-- Confirm cancel creation modal -->
<ad-modal
  [isOpen]="
    modalOpenedState.open &&
    (modalOpenedState.modalType === 'cancel-creation' ||
      modalOpenedState.modalType === 'cancel-edit' ||
      modalOpenedState.modalType === 'disable')
  "
  class="modal-cancel-creation"
  (outsideModalClick)="onCancelModalCreation()"
>
  <div top class="information-container">
    <div>
      <ad-icon [glyph]="'alert'" class="icon" [color]="'warning-400'"></ad-icon>
    </div>
    <!-- title -->
    <span class="text-p1--semibold title-text">
      <!-- handle the case when disable user -->

      <ng-container *ngIf="modalOpenedState.modalType === 'cancel-creation'">
        {{ 'INFOBOX.CANCEL_CREATION_MODAL.TITLE' | translate }}
      </ng-container>
      <ng-container *ngIf="modalOpenedState.modalType === 'cancel-edit'">
        {{ 'INFOBOX.CANCEL_EDIT_MODAL.TITLE' | translate }}
      </ng-container>
      <ng-container *ngIf="modalOpenedState.modalType === 'disable'">
        {{ 'INFOBOX.DISABLE_USER_MODAL.TITLE' | translate }}
      </ng-container>
    </span>
    <!-- title -->
    <!-- subtitle -->
    <span class="text-p3 subtitle-text" *ngIf="infoboxType === 'users'">
      <ng-container *ngIf="modalOpenedState.modalType === 'cancel-creation'">
        {{ 'INFOBOX.CANCEL_CREATION_MODAL.SUBTITLE_USER' | translate }}
      </ng-container>
      <ng-container *ngIf="modalOpenedState.modalType === 'cancel-edit'">
        {{ 'INFOBOX.CANCEL_EDIT_MODAL.SUBTITLE_USER' | translate }}
      </ng-container>
      <ng-container *ngIf="modalOpenedState.modalType === 'disable'">
        {{ 'INFOBOX.DISABLE_USER_MODAL.SUBTITLE' | translate }}
      </ng-container>
    </span>

    <div *ngIf="infoboxType === 'vehicles'" class="text-p3 subtitle-text">
      <span *ngIf="!isEditing">
        {{ 'INFOBOX.CANCEL_CREATION_MODAL.SUBTITLE_VEHICLE' | translate }}
      </span>

      <span *ngIf="isEditing">
        {{ 'INFOBOX.CANCEL_EDIT_MODAL.SUBTITLE_VEHICLE' | translate }}
      </span>
    </div>
  </div>
  <div middle class="button-container">
    <button
      astus-design-button
      [size]="'sm'"
      kind="outlined"
      color="neutral"
      (click)="onCancelModalCreation()"
      class="button cancel-button"
    >
      {{ 'INFOBOX.CANCEL_CREATION_MODAL.CANCEL_BUTTON' | translate }}
    </button>
    <button
      astus-design-button
      [size]="'sm'"
      kind="filled"
      color="success"
      class="button confirm-button"
      (click)="onConfirmCancelModalCreation()"
    >
      {{ 'INFOBOX.CANCEL_CREATION_MODAL.CONFIRM_BUTTON' | translate }}
    </button>
  </div>
</ad-modal>
