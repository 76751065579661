import { createReducer, on } from '@ngrx/store';

import { reportReducerInterface } from './report';
import { reportActions } from './report.action';

export const reportFeatureKey = 'reportFeatureKey';

export const initialState: reportReducerInterface = {
  reports: {
    list: [],
    infobox: {
      report: null,
    },
    loaded: false,
  },
};

export const reportReducer = createReducer<reportReducerInterface>(
  initialState,
  on(reportActions.reportListingSuccess, (_state, action) => ({
    ..._state,
    reports: {
      ..._state.reports,
      list: action.response,
    },
  })),
  on(reportActions.reportGenerateSuccess, (state, action) => ({
    ...state,
    reports: {
      ...state.reports,
      list: [...state.reports.list, action.response],
    },
  }))
);
