import {
  Component,
  OnDestroy,
  OnInit,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { userTableInterface as adsUserTableInterface } from '@common/ng-design-system';
import { ResourcesService, RolesService } from '@fms/ng-fms-api-client';

import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { ActiveCountriesAndSubdivisions } from '../../constants/active-countries-and-subdivisions';
import { userDropdownsData } from '../../constants/user-form-dropdown';
import {
  formatUserFields,
  getCountriesAndProvinces,
  getObjectDiff,
  getSpecificProvinceListAsDropdownItem,
  getTimezoneListAsDropdownItem,
  getTimezoneOffset,
  removeUndefinedFields,
} from '../../helpers';
import { UserFormDropdown } from '../../models/user-form-dropdown.models';
import {
  ResourceDriverFormModel,
  ResourceUserAndDriverModel,
  ResourceUserFormModel,
  ValuesTypeReturn,
} from '../../models/user-forms-models';

export const FIELD_MAX_CHARACTERS = {
  FIRST_NAME: 32,
  LAST_NAME: 32,
  PHONE: 15,
  MAIL: 320,
};

@Component({
  selector: 'astus-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit, OnDestroy {
  isDriver = false;
  fieldMaxCharacters = FIELD_MAX_CHARACTERS;

  userDropdowns: UserFormDropdown = userDropdownsData;

  userForm: FormGroup<ResourceUserFormModel> =
    this.fb.group<ResourceUserFormModel>({
      id: new FormControl(null),
      rolesPermissions: new FormControl([], Validators.required),
      isDriver: new FormControl(this.isDriver),
      lastName: new FormControl('', [
        Validators.required,
        Validators.maxLength(FIELD_MAX_CHARACTERS.LAST_NAME),
      ]),
      firstName: new FormControl('', [
        Validators.required,
        Validators.maxLength(FIELD_MAX_CHARACTERS.LAST_NAME),
      ]),
      phone: new FormControl('', [
        Validators.required,
        Validators.maxLength(FIELD_MAX_CHARACTERS.PHONE),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.maxLength(FIELD_MAX_CHARACTERS.MAIL),
      ]),
      language: new FormControl(''),
      active: new FormControl(''),
    });

  driverInfo: FormGroup<ResourceDriverFormModel> =
    this.fb.group<ResourceDriverFormModel>({
      licenseNumber: new FormControl(null, Validators.required),
      licenseIssuingCountry: new FormControl(''),
      licenseIssuingSubDivision: new FormControl(''),
      licenseClass: new FormControl(''),
      licenseExpirationDate: new FormControl(''),
      singleJob: new FormControl(''),
      expirationSingleJob: new FormControl(''),
      homePort: new FormControl('', Validators.required),
      timezone: new FormControl(''),
    });

  originalUserValues: Partial<ResourceUserAndDriverModel> = {};

  @Input() set userFormValues(originalValues: adsUserTableInterface | null) {
    setTimeout(() => {
      this.updateUserFormValues(originalValues);
    });
  }

  @Output() userFormEmitter: EventEmitter<FormGroup<ResourceUserFormModel>> =
    new EventEmitter();

  private rolePermissionSubscription$ = new Subscription();
  private resourceServiceSubscription$ = new Subscription();

  userFormChangeSubscription!: Subscription;
  countryChangeSubscription!: Subscription;
  subscription: Subscription[] = [];

  constructor(
    private resourcesService: ResourcesService,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private rolesService: RolesService
  ) {
    this.initControlsSubscriptions();
  }

  initControlsSubscriptions() {
    this.userFormChangeSubscription = this.userForm.valueChanges.subscribe(
      (valueChanges: Partial<ResourceUserAndDriverModel>) => {
        this.onUserFormChanges(valueChanges);
      }
    );
    this.subscription.push(this.userFormChangeSubscription);
    this.countryChangeSubscription =
      this.driverInfo.controls.licenseIssuingCountry.valueChanges.subscribe(
        (v) => {
          this.updateDefaultCountryAndSubdivision(v);
        }
      );
    this.subscription.push(this.countryChangeSubscription);

    // fetch roles and permissions
    this.rolePermissionSubscription$ = this.rolesService
      .rolesControllerGetRoles()
      .subscribe((roles) => {
        this.userDropdowns.rolesPermissions = {
          items: roles,
          placeholder: '',
        };
      });
  }

  updateDefaultCountryAndSubdivision(alphaCountry: string | null) {
    const targetCountryAndSubdivision = ActiveCountriesAndSubdivisions.find(
      (item) => item.country.alpha === alphaCountry
    );
    if (alphaCountry === null) {
      const { countries, subdivisions } = getCountriesAndProvinces(
        ActiveCountriesAndSubdivisions
      );

      this.userDropdowns.licenseIssuingCountry = countries;
      this.userDropdowns.licenseIssuingSubDivision = subdivisions;
    } else if (targetCountryAndSubdivision) {
      this.userDropdowns.licenseIssuingSubDivision =
        getSpecificProvinceListAsDropdownItem(targetCountryAndSubdivision);
    } else {
      this.userDropdowns.licenseIssuingSubDivision =
        getSpecificProvinceListAsDropdownItem({
          country: { alpha: alphaCountry },
        });
      this.driverInfo.controls.licenseIssuingSubDivision.reset();
    }
  }

  updateUserFormValues(originalValues: adsUserTableInterface | null) {
    this.resetFormValue();

    if (!originalValues || originalValues == null) {
      return;
    }
    const values = removeUndefinedFields(originalValues);
    const userControlNames = (Object.keys(this.userForm.controls) ??
      []) as (keyof ResourceUserFormModel)[];

    userControlNames.forEach((controlName) => {
      let value = values?.[controlName as keyof adsUserTableInterface];
      const currentControl = this.userForm.controls[controlName];
      if (controlName === 'rolesPermissions') {
        value = values.rolesPermissions.map((role) => ({
          name: role,
          id: `${role}`,
        }));
      }
      if (currentControl instanceof FormControl) {
        currentControl.setValue(value, { emitEvent: false });
        this.originalUserValues[controlName] =
          currentControl.value as ValuesTypeReturn<ResourceUserAndDriverModel>;
      }
    });

    const driverControlNames = (Object.keys(this.driverInfo.controls) ??
      []) as (keyof ResourceDriverFormModel)[];

    driverControlNames.forEach((controlName) => {
      let value = values?.[controlName as keyof adsUserTableInterface];
      if (controlName === 'singleJob') {
        value = values.employeeNo;
      }

      const currentControl = this.driverInfo.controls[controlName];
      if (currentControl instanceof FormControl) {
        currentControl.setValue(value, { emitEvent: false });
        this.originalUserValues.driverInfo
          ? (this.originalUserValues.driverInfo[controlName] = value)
          : (this.originalUserValues.driverInfo = {
              [controlName]:
                currentControl.value as ValuesTypeReturn<ResourceUserAndDriverModel>,
            });
      }
    });
    if (values?.isDriver) {
      this.userForm.addControl('driverInfo', this.driverInfo);
    } else {
      this.userForm.removeControl('driverInfo');
    }
    this.originalUserValues.isDriver = !!values?.isDriver;
    this.userForm.controls.isDriver.setValue(!!values?.isDriver, {
      emitEvent: false,
    });
  }

  private resetFormValue() {
    this.userForm.reset(undefined, { emitEvent: false });
    this.driverInfo.reset(undefined, { emitEvent: false });
    this.isDriver = false;
    this.originalUserValues = {};
  }

  ngOnInit(): void {
    this.initUserDropdownsAvailableOptions();
  }

  initUserDropdownsAvailableOptions() {
    const { countries, subdivisions } = getCountriesAndProvinces(
      ActiveCountriesAndSubdivisions
    );

    // translate the countries to the current language
    // according to the country key from the iso-3166 lib
    // basically, the country key is the same as the translation key
    // eg:  `USER_FORM.COUNTRY.${country.value}` => `USER_FORM.COUNTRY.US`
    countries.items = countries.items.map((country) => ({
      label: this.translateService.instant(
        `USER_FORM.COUNTRY.${country.value}`
      ),
      value: country.value,
    }));

    this.userDropdowns.licenseIssuingCountry = countries;

    this.userDropdowns.licenseIssuingSubDivision = subdivisions;
    const translateServiceSubscription = this.translateService
      .stream('TIMEZONES')
      .subscribe((res) => {
        this.userDropdowns.timeZone.items = getTimezoneListAsDropdownItem(res);
        const offset = getTimezoneOffset();
        const placeHolderCandidate = this.userDropdowns.timeZone.items.find(
          (item) => item.value === offset
        )?.label;
        this.userDropdowns.timeZone.placeholder =
          placeHolderCandidate ?? this.userDropdowns.timeZone.placeholder;
      });
    this.subscription.push(translateServiceSubscription);
    // Get dropdowns value from the API
    this.resourceServiceSubscription$ = this.resourcesService
      .resourcesControllerFindAllInfoboxUserResources()
      .subscribe((infoBoxUserData) => {
        const userDropdownsFromData: Partial<UserFormDropdown> = {
          language: {
            items: infoBoxUserData.language.map((language) => ({
              label: language,
              value: `${language}`,
            })),
            placeholder: null,
          },
          licenceClass: {
            items: (infoBoxUserData.licenceClass ?? []).map((license) => ({
              label: license,
              value: `${license}`,
            })),
            placeholder: null,
          },
          homePortType: {
            items: (infoBoxUserData.homePortType ?? []).map((homePort) => ({
              label: homePort,
              value: `${homePort}`,
            })),
            placeholder: null,
          },
        };
        this.userDropdowns = {
          ...this.userDropdowns,
          ...userDropdownsFromData,
        };
      });
    this.subscription.push(this.resourceServiceSubscription$);
  }

  ngOnDestroy(): void {
    this.subscription.forEach((sub) => sub.unsubscribe());
    this.rolePermissionSubscription$.unsubscribe();
  }

  onUserFormChanges(valueChanges: Partial<ResourceUserAndDriverModel>) {
    const valueToValidate = this.userForm.value as ResourceUserAndDriverModel;
    valueToValidate.driverInfo = this.driverInfo.value;
    const formattedValue = formatUserFields(valueToValidate, true);
    const formattedOriginalValue = formatUserFields(
      this.originalUserValues as ResourceUserAndDriverModel,
      true
    );

    const userFromChanges = getObjectDiff(
      formattedValue,
      formattedOriginalValue
    );
    if (userFromChanges.length === 0) {
      this.userForm.markAsPristine();
    }

    this.switchUserType(valueChanges);
    this.userFormEmitter.emit(this.userForm);
  }

  switchUserType(valueChanges: Partial<ResourceUserAndDriverModel>) {
    const isDriver = valueChanges['isDriver'];
    const driverInfo = !!this.userForm.controls.driverInfo;
    if (isDriver && !driverInfo) {
      this.userForm.addControl('driverInfo', this.driverInfo, {
        emitEvent: false,
      });
    } else if (!isDriver && driverInfo) {
      this.userForm.removeControl('driverInfo', { emitEvent: false });
    }
    this.isDriver = !!isDriver;
  }
}
