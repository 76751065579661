<dialog #ad_modal class="modal">
  <!-- content projection top content-->
  <div class="top">
    <ng-content select="[top]"></ng-content>
  </div>

  <!-- content projection for middle content-->
  <div class="middle">
    <ng-content select="[middle]"></ng-content>
  </div>

  <!-- content projection for bottom content -->
  <div class="bottom">
    <ng-content select="[bottom]"></ng-content>
  </div>
</dialog>
