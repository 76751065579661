import { Component, Input, OnChanges } from '@angular/core';
import classNames from 'classnames';

import { ColorType } from '../../../common-ng-design-system.colors';

import { ProgressBarKind } from './progress-bar.enum';

@Component({
  selector: 'ad-progress-bar-line',
  template: `
    <div [ngClass]="containerClasses">
      <span
        [ngClass]="progressBarClasses"
        [ngStyle]="progressBarWidth"
        class="success"
      >
      </span>
    </div>
  `,
})
export class ProgressBarLineMolecule implements OnChanges {
  @Input()
  kind: ProgressBarKind = 'determinate';

  @Input()
  progress?: number;

  @Input()
  buffer?: number;

  @Input()
  color: ColorType = 'primary';

  progressBarWidth = { width: '0' };

  ngOnChanges() {
    this.progressBarAnimate();
  }

  public get containerClasses() {
    return classNames(
      'progress-bar-line-container',
      this.color,
      this.kind === 'buffer' && 'progress-bar-dotted'
    );
  }

  /**
   * @description
   * Set the progress bar color based on the color input value
   * @returns string
   */
  public get progressBarClasses() {
    return classNames('progress-bar-wrapper', this.color);
  }

  /**
   * @description
   * Animate the progress bar width based on the progress input value
   * @returns void
   */
  progressBarAnimate() {
    const barWidth = `${this.progress}%`;

    setTimeout(() => {
      this.progressBarWidth = { width: barWidth };
    });
  }

  public get progressCap() {
    if (this.progress) {
      if (this.progress >= 100) return 100;
      if (this.progress <= 0) return 0;
    }
    return this.progress;
  }
}
