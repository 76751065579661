import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import {
  getUserResourcesVehiclesDataTableColumns,
  userActions,
} from '../../store';
import { getVehicleHeaderTranslations } from '../table-headers';

@Component({
  selector: 'astus-vehicle-list-columns',
  templateUrl: './vehicle-list-columns.component.html',
  styleUrls: ['./vehicle-list-columns.component.scss'],
})
export class VehicleListColumnsComponent implements OnInit, OnDestroy {
  initialValues: { [key: string]: boolean } = {};
  buttonApplyDisabled = true;
  buttonResetDisabled = true;

  valueChangesSubscription = new Subscription();
  vehicleHeaderTranslationsSubscription = new Subscription();
  storeSubscription = new Subscription();

  formGroup!: FormGroup;
  columns: { controlName: string; text: string }[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.vehicleHeaderTranslationsSubscription = getVehicleHeaderTranslations(
      this.translateService
    ).subscribe((headers) => {
      this.storeSubscription = this.store
        .select(getUserResourcesVehiclesDataTableColumns)
        .subscribe((columns) => {
          if (columns.length) {
            this.columns = columns.map((header, index) => {
              return {
                controlName: header.dataKey,
                text: headers[index],
              };
            });

            if (!this.formGroup) {
              this.formGroup = this.formBuilder.group(
                this.initFormColumns(columns)
              );
            }
          }
        });
    });

    this.initialValues = this.formGroup.value;
    this.buttonResetDisabled = this.allSelected(this.initialValues);
    this.valueChangesSubscription = this.formGroup.valueChanges.subscribe(
      (values) => {
        this.buttonApplyDisabled =
          JSON.stringify(values) === JSON.stringify(this.initialValues) ||
          this.allDeselected(values);
        this.buttonResetDisabled = this.allSelected(values);
      }
    );
  }

  ngOnDestroy(): void {
    this.valueChangesSubscription.unsubscribe();
    this.vehicleHeaderTranslationsSubscription.unsubscribe();
    this.storeSubscription.unsubscribe();
  }

  undoChanges() {
    this.formGroup.setValue(this.initialValues);
  }

  // save the selected columns
  onApply() {
    const resourcesVehicleColumns = Object.entries(this.formGroup.value).map(
      (v) => {
        const [dataKey, selected] = v as [dataKey: string, selected: boolean];
        return {
          dataKey,
          selected,
        };
      }
    );

    this.store.dispatch(
      userActions.saveUserColumns({ resourcesVehicleColumns })
    );
    this.initialValues = this.formGroup.value;
  }

  // reset all checkboxes to true
  onReset() {
    const resourcesVehicleColumns = Object.entries(this.formGroup.value).map(
      (v) => {
        const [dataKey] = v as [dataKey: string, selected: boolean];
        return {
          dataKey,
          selected: true,
        };
      }
    );

    this.store.dispatch(
      userActions.saveUserColumns({ resourcesVehicleColumns })
    );

    const data: { [key: string]: boolean } = {};
    for (const column of resourcesVehicleColumns) {
      data[column.dataKey] = true;
    }
    this.formGroup.setValue(data);
    this.initialValues = this.formGroup.value;
  }

  allSelected(val: { [key: string]: boolean }) {
    return Object.values(val).every((value) => value === true);
  }

  allDeselected(val: { [key: string]: boolean }) {
    return Object.values(val).every((value) => value === false);
  }

  initFormColumns(columns: { dataKey: string; selected: boolean }[]) {
    const data: { [key: string]: boolean[] } = {};
    columns.forEach((header) => {
      data[header.dataKey] = [header.selected];
    });

    return data;
  }
}
