<div class="supervision-header-container" *ngIf="showFeatureHeader">
  <div class="datepicker-wrapper">
    <ad-date-picker
      [date]="date"
      (dateSelected)="handleDate($event)"
      [dateRangeFilters]="[
        { range: 'today', label: 'primeng.today' | translate }
      ]"
      [currentLanguage]="currentLanguage"
    ></ad-date-picker>
  </div>

  <div class="timeline-wrapper" *ngIf="timeLineIsShown">
    <!-- Hour picker in mobile mode -->
    <div
      class="mobile-hour-picker-container"
      *ngIf="!timeLineStatus && isMobile"
    >
      <div class="separator"></div>

      <div class="start-end-date-container text-p2">
        <span (click)="openMobilePicker(true)">
          {{ pickersDate.firstHourSelection | hoursFormat }}
        </span>
        :
        <span (click)="openMobilePicker(false)">
          {{ pickersDate.secondHourSelection | hoursFormat }}
        </span>
      </div>
    </div>

    <astus-timeline-button></astus-timeline-button>

    <!-- Hour picker in desktop mode -->
    <astus-hour-picker
      *ngIf="!isMobile"
      [firstHourSelected]="pickersDate.firstHourSelection"
      [secondHourSelected]="pickersDate.secondHourSelection"
      [isLiveMode]="timeLineStatus"
      (firstHourSelectedChange)="handleFirstHourSelection($event)"
      (secondHourSelectedChange)="handleSecondHoursSelection($event)"
    ></astus-hour-picker>
  </div>
</div>

<!-- hour picker drawer for start and end date -->
<astus-hour-picker-mobile
  *ngIf="isMobile && showHourPicker"
  (userCancelledSelection)="userCancelledSelectionInHourPicker()"
  (userSelectedElement)="userSelectedElementInHourPicker($event)"
  [hoursPickerData]="pickersDate"
  [startHoursIsSelected]="showStartHourPicker"
></astus-hour-picker-mobile>
