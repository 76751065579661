<div
  class="card-container"
  [id]="id"
  [ngClass]="{
    'is-hovered-selected': !isSkeleton && (hovered || isSelected),
    'is-skeleton': isSkeleton
  }"
>
  <div class="card-content">
    <ad-card-info
      (clickCardEvent)="selectCard()"
      [isSkeleton]="isSkeleton"
      [hideAvatars]="hideAvatars"
      [firstAvatarText]="firstAvatarText"
      [firstAvatarTooltip]="firstAvatarTooltip"
      [firstAvatarFallbackGlyph]="firstAvatarFallbackGlyph"
      [secondAvatarText]="secondAvatarText"
      [secondAvatarTooltip]="secondAvatarTooltip"
      [secondAvatarFallbackGlyph]="secondAvatarFallbackGlyph"
      [tooltipPosition]="tooltipPosition"
      [title]="title"
      [titleSmall]="titleSmall"
      [subtitle]="subtitle"
      [subtitleSmall]="subtitleSmall"
      [infoItems]="infoItems"
    ></ad-card-info>
    <div
      class="card-more-info"
      moreInfo
      [ngClass]="{ 'show-more': hovered || isSelected }"
    >
      <!-- Assign Button -->
      <div class="card-assigned" *ngIf="ctaButtonText && ctaButtonText.length">
        <button
          class="card-button-assign text-footer"
          astus-design-button
          [size]="'xs'"
          kind="filled"
          color="primary"
          (click)="onAssignClick()"
        >
          {{ ctaButtonText }}
        </button>
      </div>
      <div class="card-more-options">
        <ad-popover
          [(display)]="showMoreOptions"
          [options]="moreOptions"
          class="card-popover"
          position="left"
          (selectOption)="popoverOptionSelect.emit($event)"
          (displayChange)="popoverDisplayChange.emit($event)"
        >
          <button class="card-popover-button" (click)="onMoreOptionsClick()">
            <ad-icon
              [color]="isSkeleton ? 'neutral-200' : 'neutral-500'"
              glyph="more-vertical"
              [customSize]="24"
              class="card-more-options-icon"
              [ngClass]="isSkeleton ? 'is-skeleton' : 'is-not-skeleton'"
            ></ad-icon>
          </button>
        </ad-popover>
      </div>
    </div>
  </div>
  <ad-progress-info-component
    *ngIf="progressPercent"
    [time]="progressTime"
    [percentage]="progressPercent"
    [status]="progressStatus"
  ></ad-progress-info-component>
  <ad-alert-card
    *ngIf="alertCommunicateText.length"
    [hovered]="hovered"
    [communicateText]="alertCommunicateText"
    [resolveText]="alertResolveText"
    [status]="alertStatus"
    [title]="alertTitle"
    [subtitle]="alertSubtitle"
    [description]="alertDescription"
  ></ad-alert-card>
</div>
