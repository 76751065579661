import { DropdownItemInterface } from '@common/ng-design-system';

import { PickerInterface } from '../hour-picker-mobile.component';

/**
 * This function calculates the previous, selected and next index of the minutes list
 * The function gonna find the index of the selected minute in the minutes list
 * and return the previous, selected and next index
 * The function handle the case when the selected minute is at the beginning or at the end of the list
 * @param {string | number} minuteSelected - The selected minute (should be '10')
 * @param DropdownItemInterface[] minutesList - The list of minutes formatted as DropdownItemInterface with data like { label: '00', value: '00' }
 * @returns {PickerInterface} - The object with the previous, selected and next index according to the minutes list
 * @example getIndexForMinutesPickerAccordingSelectedMinutes('45', [ { label: '00', value: '00' }, { label: '15', value: '15' }, { label: '30', value: '30' }, { label: '45', value: '45' } ])
 * => { previousIndex: 2, selectedIndex: 3, nextIndex: 0 } // previous index = 30, selected index = 45, next index = 00
 */
export function getIndexForMinutesPickerAccordingSelectedMinutes(
  minuteSelected: string | number,
  minutesList: DropdownItemInterface[]
): PickerInterface {
  // Find the index of the selected minute in the minutes list
  const selectedMinuteIndex = minutesList.findIndex(
    (minute) => minute.value === minuteSelected
  );

  return {
    previousIndex:
      // If the selected minute is at the beginning of the list, the previous index should be the last element of the list or the previous element
      selectedMinuteIndex === 0
        ? minutesList.length - 1
        : selectedMinuteIndex - 1,
    selectedIndex: selectedMinuteIndex,
    // If the selected minute is at the end of the list, the next index should be the first element of the list or the next element
    nextIndex:
      selectedMinuteIndex + 1 === minutesList.length
        ? 0
        : selectedMinuteIndex + 1,
  };
}

/**
 * This function calculates the previous, selected and next index of the hours list
 * The function gonna find the index of the selected hour in the hours list
 * and return the previous, selected and next index
 * The function handle the case when the selected hour is at the beginning or at the end of the list
 * @param {string | number} hoursSelected - The selected hour (should be '10')
 * @param DropdownItemInterface[] hoursList - The list of hours formatted as DropdownItemInterface with data like { label: '01', value: '01' }
 * @returns {PickerInterface} - The object with the previous, selected and next index according to the hours list
 * @example getIndexForHoursPickerAccordingSelectedHours('02', [ { label: '01', value: '01' }, { label: '02', value: '02' }, { label: '03', value: '03' }, { label: '04', value: '04' } ])
 * => { previousIndex: 0, selectedIndex: 1, nextIndex: 2 } // previous index = 01, selected index = 02, next index = 03
 */
export function getIndexForHoursPickerAccordingSelectedHours(
  hoursSelected: string | number,
  hoursList: DropdownItemInterface[]
): PickerInterface {
  // Find the index of the selected hour in the hours list
  const selectedHourIndex = hoursList.findIndex(
    (hour) => hour.value === hoursSelected
  );

  return {
    // If the selected hour is at the beginning of the list, the previous index should be the last element of the list or the previous element
    previousIndex:
      selectedHourIndex === 0 ? hoursList.length - 1 : selectedHourIndex - 1,
    selectedIndex: selectedHourIndex,
    // If the selected hour is at the end of the list, the next index should be the first element of the list or the next element
    nextIndex:
      selectedHourIndex + 1 === hoursList.length ? 0 : selectedHourIndex + 1,
  };
}
