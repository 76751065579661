import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';

import { DateService } from '../../services/date.service';
import { getUserHoursFormat } from '../../store';

import { hoursFormatEnum } from './hours-format.enum';

@Pipe({
  name: 'hoursFormat',
  standalone: true,
})
export class HoursFormatPipe implements PipeTransform {
  hoursFormat = hoursFormatEnum.TWELVE;

  constructor(private store: Store, private dateService: DateService) {
    store.select(getUserHoursFormat).subscribe((hoursFormat) => {
      this.hoursFormat = hoursFormat;
    });
  }

  /**
   * @description
   * Transform an hour value to a 12 or 24 hours format
   * @example {{ '08:00' | hoursFormat }} => 8:00am / 08:00
   * @example {{ '14:00' | hoursFormat }} => 2:00pm / 14:00
   * @example {{ '00:00' | hoursFormat }} => 0:00am / 00:00
   * @example {{ '12:00' | hoursFormat }} => 12:00pm / 12:00
   * @example {{ '23:59' | hoursFormat }} => 11:59pm / 23:59
   *
   * In TS : this.hoursFormatPipe.transform(hours)
   *
   * @param {string} time
   *
   * @returns {string} theformatted hours
   */
  transform(time24hr: string): string {
    if (this.hoursFormat === hoursFormatEnum.TWELVE) {
      return this.dateService.convertToAMPM(time24hr);
    } else {
      // The split is to remove the first "0" like "07:00" to be "7:00"
      return parseInt(time24hr.split(':')[0]) + ':' + time24hr.split(':')[1];
    }
  }

  convertDateTo24Hr(date: Date): string {
    return `${date.getHours().toString().padStart(2, '0')}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  }
}
