<form class="driver-form-container" [formGroup]="userForm">
  <!-- Roles and permissions section -->
  <div class="fields-container">
    <div class="section-divider">
      <span class="text-caption--semibold">
        {{ 'USER_FORM.SECTION.ROLES_PERMISSIONS' | translate }}
      </span>
    </div>
    <astus-resource-role-permission
      formControlName="rolesPermissions"
      [rolesPermissionsList]="userDropdowns.rolesPermissions.items"
      [placeholder]="'USER_FORM.PLACEHOLDER.ROLES_PERMISSIONS' | translate"
      [errors]="
        userForm.get('rolesPermissions')?.dirty ||
        userForm.get('rolesPermissions')?.touched
          ? userForm.controls['rolesPermissions'].errors
          : null
      "
    >
    </astus-resource-role-permission>

    <span
      class="text-caption error-message"
      *ngIf="(userForm.get('rolesPermissions')?.dirty || userForm.get('rolesPermissions')?.touched) 
            && userForm.controls['rolesPermissions'].errors?.['required']"
    >
      {{ 'USER_FORM.ERROR.ROLES_PERMISSIONS' | translate }}
    </span>

    <ad-checkbox [size]="'sm'" formControlName="isDriver">
      <div customCheckBoxText class="checkbox-custom-text">
        <span class="text-caption"
          >{{ 'USER_FORM.CHECKBOX.USER_IS_DRIVER' | translate }}
        </span>
        <span class="text-caption--semibold">
          {{ 'USER_FORM.CHECKBOX.HIGHLIGHTED_TEXT' | translate }}
        </span>
      </div>
    </ad-checkbox>
  </div>

  <!-- General section -->
  <div class="fields-container--space-between">
    <div class="section-divider">
      <span class="text-caption--semibold">
        {{ 'USER_FORM.SECTION.GENERAL' | translate }}
      </span>
    </div>

    <div class="input-with-custom-label">
      <label class="text-caption--semibold input-label">
        {{ 'USER_FORM.LABEL.NAME' | translate }}
        <!-- TODO: handle the required dynamically according the form input required field -->
        {{ 'USER_FORM.LABEL.REQUIRED' | translate }}
      </label>

      <div class="two-field-item">
        <div class="input-container">
          <ad-input
            [type]="'text'"
            [placeholder]="'USER_FORM.PLACEHOLDER.FIRST_NAME' | translate"
            formControlName="firstName"
            [errors]="
              userForm.get('firstName')?.dirty ||
              userForm.get('firstName')?.touched
                ? userForm.controls['firstName'].errors
                : null
            "
          ></ad-input>
        </div>
        <div class="input-container">
          <ad-input
            [type]="'text'"
            [placeholder]="'USER_FORM.PLACEHOLDER.LAST_NAME' | translate"
            formControlName="lastName"
            [errors]="
              userForm.get('lastName')?.dirty ||
              userForm.get('lastName')?.touched
                ? userForm.controls['lastName'].errors
                : null
            "
          ></ad-input>
        </div>
      </div>
      <!-- First / Last name errors handling -->
      <div class="error-container">
        <div class="first-name-error">
          <!-- First name max required error -->
          <span
            class="text-caption error-message"
            *ngIf="(userForm.get('firstName')?.dirty || userForm.get('firstName')?.touched) 
            && userForm.controls['firstName'].errors?.['required']"
          >
            {{ 'USER_FORM.ERROR.FIRST_NAME' | translate }}
          </span>
          <!-- First name max length field -->
          <span
            class="text-caption error-message"
            *ngIf="(userForm.get('firstName')?.dirty || userForm.get('firstName')?.touched) 
            && userForm.controls['firstName'].errors?.['maxlength']"
          >
            {{
              'USER_FORM.ERROR.MAX_LENGTH'
                | translate : { value: fieldMaxCharacters.FIRST_NAME }
            }}
          </span>
        </div>

        <div class="last-name-error">
          <!-- Last name required error -->
          <span
            class="text-caption error-message"
            *ngIf="(userForm.get('lastName')?.dirty || userForm.get('lastName')?.touched) 
            && userForm.controls['lastName'].errors?.['required']"
          >
            {{ 'USER_FORM.ERROR.LAST_NAME' | translate }}
          </span>
          <!-- Last name max length field -->
          <span
            class="text-caption error-message"
            *ngIf="(userForm.get('lastName')?.dirty || userForm.get('lastName')?.touched) 
            && userForm.controls['lastName'].errors?.['maxlength']"
          >
            {{
              'USER_FORM.ERROR.MAX_LENGTH'
                | translate : { value: fieldMaxCharacters.LAST_NAME }
            }}
          </span>
        </div>
      </div>
    </div>

    <div class="input-with-custom-label">
      <label class="text-caption--semibold">
        {{ 'USER_FORM.LABEL.PHONE' | translate }}
        <!-- TODO: handle the required dynamically according the form input required field -->
        {{ 'USER_FORM.LABEL.REQUIRED' | translate }}
      </label>

      <ad-input
        [placeholder]="'USER_FORM.PLACEHOLDER.PHONE' | translate"
        formControlName="phone"
        [errors]="
          userForm.get('phone')?.dirty || userForm.get('phone')?.touched
            ? userForm.controls['phone'].errors
            : null
        "
      ></ad-input>

      <span
        class="text-caption error-message"
        *ngIf="(userForm.get('phone')?.dirty || userForm.get('phone')?.touched) 
            && userForm.controls['phone'].errors?.['required']"
      >
        {{ 'USER_FORM.ERROR.PHONE' | translate }}
      </span>

      <!-- Phone max length field -->
      <span
        class="text-caption error-message"
        *ngIf="(userForm.get('phone')?.dirty || userForm.get('phone')?.touched) 
            && userForm.controls['phone'].errors?.['maxlength']"
      >
        {{
          'USER_FORM.ERROR.MAX_LENGTH'
            | translate : { value: fieldMaxCharacters.PHONE }
        }}
      </span>
    </div>

    <div class="input-with-custom-label">
      <label class="text-caption--semibold">
        {{ 'USER_FORM.LABEL.MAIL' | translate }}
        <!-- TODO: handle the required dynamically according the form input required field -->
        {{ 'USER_FORM.LABEL.REQUIRED' | translate }}
      </label>
      <ad-input
        [type]="'email'"
        [placeholder]="'USER_FORM.PLACEHOLDER.MAIL' | translate"
        formControlName="email"
        [errors]="
          userForm.get('email')?.dirty || userForm.get('email')?.touched
            ? userForm.controls['email'].errors
            : null
        "
      ></ad-input>

      <span
        class="text-caption error-message"
        *ngIf="(userForm.get('email')?.dirty || userForm.get('email')?.touched) 
            && userForm.controls['email'].errors?.['required']"
      >
        {{ 'USER_FORM.ERROR.MAIL' | translate }}
      </span>
      <!-- Mail max length field -->
      <span
        class="text-caption error-message"
        *ngIf="(userForm.get('email')?.dirty || userForm.get('email')?.touched) 
            && userForm.controls['email'].errors?.['maxlength']"
      >
        {{
          'USER_FORM.ERROR.MAX_LENGTH'
            | translate : { value: fieldMaxCharacters.MAIL }
        }}
      </span>
    </div>

    <ad-dropdown
      [label]="'USER_FORM.LABEL.LANGUAGE' | translate"
      [placeholder]="'USER_FORM.LABEL.LANGUAGE' | translate"
      formControlName="language"
      [items]="userDropdowns.language.items"
    >
    </ad-dropdown>
  </div>

  <!-- detail section -->
  <ng-container>
    <div
      class="fields-container--space-between"
      [formGroup]="driverInfo"
      *ngIf="isDriver"
    >
      <div class="section-divider">
        <span class="text-caption--semibold"
          >{{ 'USER_FORM.SECTION.DETAILS' | translate }}
        </span>
      </div>

      <ad-input
        [type]="'tel'"
        [label]="'USER_FORM.LABEL.DRIVER_LICENSE_NUMBER' | translate"
        [placeholder]="'USER_FORM.LABEL.DRIVER_LICENSE_NUMBER' | translate"
        [required]="true"
        formControlName="licenseNumber"
      ></ad-input>

      <div class="two-field-item">
        <div class="input-container">
          <ad-dropdown
            [label]="
              'USER_FORM.LABEL.DRIVER_LICENSE_ISSUING_COUNTRY' | translate
            "
            [placeholder]="
              'USER_FORM.PLACEHOLDER.DRIVER_LICENSE_ISSUING_COUNTRY' | translate
            "
            formControlName="licenseIssuingCountry"
            [items]="userDropdowns.licenseIssuingCountry.items"
          >
          </ad-dropdown>
        </div>
        <div class="input-container">
          <ad-dropdown
            [label]="
              'USER_FORM.LABEL.DRIVER_LICENSE_ISSUING_SUBDIVISION' | translate
            "
            [placeholder]="
              'USER_FORM.PLACEHOLDER.DRIVER_LICENSE_ISSUING_SUBDIVISION'
                | translate
            "
            formControlName="licenseIssuingSubDivision"
            [items]="userDropdowns.licenseIssuingSubDivision.items"
          >
          </ad-dropdown>
        </div>
      </div>

      <div class="two-field-item">
        <div class="input-container">
          <ad-dropdown
            [label]="'USER_FORM.LABEL.LICENSE_CLASS' | translate"
            formControlName="licenseClass"
            [placeholder]="'USER_FORM.PLACEHOLDER.LICENSE_CLASS' | translate"
            [items]="userDropdowns.licenceClass.items"
          >
          </ad-dropdown>
        </div>
        <div class="input-container">
          <ad-input
            [label]="
              'USER_FORM.LABEL.DRIVER_LICENSE_ISSUING_COUNTRY_EXP' | translate
            "
            [placeholder]="
              'SETTINGS_LANGUAGE_AND_FORMATS.DATE_FORMAT.DD/MM/YYYY' | translate
            "
            formControlName="licenseExpirationDate"
          >
          </ad-input>
        </div>
      </div>

      <div class="two-field-item">
        <div class="input-container">
          <ad-dropdown
            [label]="'USER_FORM.LABEL.SINGLE_JOB' | translate"
            [placeholder]="'USER_FORM.PLACEHOLDER.SINGLE_JOB' | translate"
            formControlName="singleJob"
            [items]="userDropdowns.singleJob.items"
          ></ad-dropdown>
        </div>
        <div class="input-container">
          <ad-input
            [label]="'USER_FORM.LABEL.SINGLE_JOB_EXP' | translate"
            [placeholder]="
              'SETTINGS_LANGUAGE_AND_FORMATS.DATE_FORMAT.DD/MM/YYYY' | translate
            "
            formControlName="expirationSingleJob"
          ></ad-input>
        </div>
      </div>

      <ad-dropdown
        [label]="'USER_FORM.LABEL.DRIVER_HOMEPORT' | translate"
        [placeholder]="'USER_FORM.PLACEHOLDER.DRIVER_HOMEPORT' | translate"
        [required]="true"
        formControlName="homePort"
        [items]="userDropdowns.homePortType.items"
      ></ad-dropdown>
      <ad-dropdown
        [label]="'USER_FORM.LABEL.TIMEZONE' | translate"
        [placeholder]="'USER_FORM.PLACEHOLDER.TIMEZONE' | translate"
        formControlName="timezone"
        [items]="userDropdowns.timeZone.items"
        class="timezone-dropdown"
      ></ad-dropdown>
    </div>
  </ng-container>

  <!-- Share resources section -->
  <div class="fields-container--space-between">
    <div class="section-divider">
      <span class="text-caption--semibold">
        {{ 'USER_FORM.SECTION.SHARE' | translate }}
      </span>
    </div>

    <div class="two-field-item">
      <ad-radio
        [size]="'sm'"
        [text]="'USER_FORM.RADIO.INACTIVE' | translate"
        value="inactive"
        formControlName="active"
        [selectedValue]="userForm.controls['active'].value"
      ></ad-radio>
      <ad-radio
        [size]="'sm'"
        [text]="'USER_FORM.RADIO.ACTIVE' | translate"
        value="active"
        formControlName="active"
        [selectedValue]="userForm.controls['active'].value"
      ></ad-radio>
    </div>
  </div>
</form>
