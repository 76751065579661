import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DropdownItemInterface } from '@common/ng-design-system';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, combineLatest } from 'rxjs';

import { getUserNotificationsSettings, userActions } from '../../store';

import { buttonProcessStateEnum } from '../settings.enum';

import {
  settingsNotificationsInactivityEnum,
  settingsNotificationsScheduleDaysEnum,
} from './settings-notifications.component.enum';

@Component({
  selector: 'astus-settings-notifications',
  templateUrl: './settings-notifications.component.html',
  styleUrls: ['./settings-notifications.component.scss'],
})
export class SettingsNotificationsComponent implements OnInit, OnDestroy {
  snackbarSecondsArray: DropdownItemInterface[] = this.generateSecondsArray();
  scheduleDaysArray: DropdownItemInterface[] = [];
  scheduleStartHoursArray: DropdownItemInterface[] = [];
  scheduleEndHoursArray: DropdownItemInterface[] = [];
  inactivityArray: DropdownItemInterface[] = [];
  weekdaysTranslations: string[] = [];
  isWeekdayModalOpen = false;
  isCancelModalOpen = false;
  currWeekdays: number[] = [];
  customDefaultValue = '';
  customWeekdaysValue = '';
  formChanged = false;
  formSubscription$!: Subscription;
  initialFormValue = {};
  defaultStartHour = 4;
  defaultEndHour = 20;
  buttonEnums = buttonProcessStateEnum;
  processState: buttonProcessStateEnum = buttonProcessStateEnum.READY;

  formGroup = this.fb.group({
    high: this.fb.group({
      snackbar: [true],
      snackbarTime: [10],
      message: [true],
      notifications: [true],
      email: [true],
      banner: [true],
    }),
    medium: this.fb.group({
      snackbar: [false],
      snackbarTime: 50,
      message: [true],
      notifications: [true],
      email: [true],
      banner: [true],
    }),
    low: this.fb.group({
      snackbar: [true],
      snackbarTime: 30,
      message: [false],
      notifications: [true],
      email: [true],
      banner: [true],
    }),
    system: this.fb.group({
      snackbar: [true],
      snackbarTime: 90,
      message: [true],
      notifications: [false],
      email: [false],
      banner: [true],
    }),
    schedule: this.fb.group({
      days: [settingsNotificationsScheduleDaysEnum.MONDAYTOFRIDAY],
      timeStart: [4],
      timeEnd: [20],
      customWeekdays: [[1]],
    }),
    inactivity: [settingsNotificationsInactivityEnum.INSTANTLY],
  });

  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.setOptionsWithTranslations();
    this.setWeekdaysTranslations();
    this.initializeNotificationsSettings();
    this.scheduleStartHoursArray = this.generateHoursArray(
      this.formGroup.get('schedule')?.get('timeEnd')?.value ??
        this.defaultStartHour,
      true
    );
    this.scheduleEndHoursArray = this.generateHoursArray(
      this.formGroup.get('schedule')?.get('timeStart')?.value ??
        this.defaultEndHour,
      false
    );
    this.onChanges();
  }

  ngOnDestroy(): void {
    this.formSubscription$.unsubscribe();
  }

  /**
   * Subscribe to the form changes and verify if the form changed or not
   **/
  onChanges() {
    // verify if the form changed or not
    this.formSubscription$ = this.formGroup.valueChanges.subscribe((value) => {
      if (JSON.stringify(value) !== JSON.stringify(this.initialFormValue)) {
        this.formChanged = true;
      } else {
        this.formChanged = false;
      }
    });
  }

  /**
   * Fetch the user notifications settings from the store and initialize the form with the values
   **/
  initializeNotificationsSettings() {
    this.store
      .select(getUserNotificationsSettings)
      .subscribe((userNotificationsSettings) => {
        if (userNotificationsSettings) {
          // checks here beause of the userNotificationsSettings object that might be undefined
          if (userNotificationsSettings.high) {
            this.formGroup
              .get('high')
              ?.patchValue(userNotificationsSettings.high);
          }

          if (userNotificationsSettings.medium) {
            this.formGroup
              .get('medium')
              ?.patchValue(userNotificationsSettings.medium);
          }

          if (userNotificationsSettings.low) {
            this.formGroup
              .get('low')
              ?.patchValue(userNotificationsSettings.low);
          }

          if (userNotificationsSettings.system) {
            this.formGroup
              .get('system')
              ?.patchValue(userNotificationsSettings.system);
          }

          if (userNotificationsSettings.schedule) {
            this.formGroup
              .get('schedule')
              ?.patchValue(userNotificationsSettings.schedule);
          }
          if (userNotificationsSettings.inactivity) {
            this.formGroup
              .get('inactivity')
              ?.patchValue(userNotificationsSettings.inactivity);
          }
        }
      });
    // set the initial value of the form
    this.initialFormValue = this.formGroup.value;
  }

  /**
   * Save the form and dispatch the action to save the user notifications settings to the store
   **/
  saveForm() {
    this.processState = buttonProcessStateEnum.PROCESSING;
    this.store.dispatch(
      userActions.saveUserNotificationsSettings({
        notificationsSettings: {
          high: this.formGroup.get('high')?.value,
          medium: this.formGroup.get('medium')?.value,
          low: this.formGroup.get('low')?.value,
          system: this.formGroup.get('system')?.value,
          schedule: this.formGroup.get('schedule')?.value,
          inactivity: this.formGroup.get('inactivity')?.value,
        },
      })
    );
    this.processState = buttonProcessStateEnum.SUCCESS;
    // reinitialize the initial value of the form now that the form is saved
    this.initialFormValue = this.formGroup.value;
    this.formChanged = false;
  }

  /**
   * Reset the form to the initial value
   **/
  resetForm() {
    this.formGroup.patchValue(this.initialFormValue);
    this.processState = buttonProcessStateEnum.READY;
    this.formGroup.markAsPristine();
    this.formChanged = false;
    this.isCancelModalOpen = false;
  }

  /**
   * Fetch translations and set the dropdown options
   * @returns void
   */
  setOptionsWithTranslations() {
    const translateKeysToFetch = [
      'SETTINGS_NOTIFICATIONS.SCHEDULE.DAYS-DROPDOWN.EVERYDAY',
      'SETTINGS_NOTIFICATIONS.SCHEDULE.DAYS-DROPDOWN.MONDAY-TO-FRIDAY',
      'SETTINGS_NOTIFICATIONS.SCHEDULE.DAYS-DROPDOWN.CUSTOM',
      'SETTINGS_NOTIFICATIONS.INACTIVITY.DROPDOWN.DISABLED',
      'SETTINGS_NOTIFICATIONS.INACTIVITY.DROPDOWN.INSTANTLY',
      'SETTINGS_NOTIFICATIONS.INACTIVITY.DROPDOWN.AFTER-1-MINUTES',
      'SETTINGS_NOTIFICATIONS.INACTIVITY.DROPDOWN.AFTER-5-MINUTES',
      'SETTINGS_NOTIFICATIONS.INACTIVITY.DROPDOWN.AFTER-15-MINUTES',
      'SETTINGS_NOTIFICATIONS.INACTIVITY.DROPDOWN.AFTER-30-MINUTES',
      'SETTINGS_NOTIFICATIONS.INACTIVITY.DROPDOWN.AFTER-1-HOUR',
    ];
    const translateObservables = translateKeysToFetch.map((key) =>
      this.translateService.stream(key)
    );

    combineLatest(translateObservables).subscribe((translations) => {
      this.customDefaultValue = translations[2];
      this.scheduleDaysArray = [
        {
          value: settingsNotificationsScheduleDaysEnum.EVERYDAY,
          label: translations[0],
        },
        {
          value: settingsNotificationsScheduleDaysEnum.MONDAYTOFRIDAY,
          label: translations[1],
        },
        {
          value: settingsNotificationsScheduleDaysEnum.CUSTOM,
          label: this.customDefaultValue + this.customWeekdaysValue,
        },
      ];
      this.weekdayFormatSelectedLabel();

      this.inactivityArray = [
        {
          value: settingsNotificationsInactivityEnum.DISABLED,
          label: translations[3],
        },
        {
          value: settingsNotificationsInactivityEnum.INSTANTLY,
          label: translations[4],
        },
        {
          value: settingsNotificationsInactivityEnum.MINUTE,
          label: translations[5],
        },
        {
          value: settingsNotificationsInactivityEnum.MINUTES5,
          label: translations[6],
        },
        {
          value: settingsNotificationsInactivityEnum.MINUTES15,
          label: translations[7],
        },
        {
          value: settingsNotificationsInactivityEnum.MINUTES30,
          label: translations[8],
        },
        {
          value: settingsNotificationsInactivityEnum.HOUR,
          label: translations[9],
        },
      ];
    });
  }

  /**
   * Fetch translations and set the weekdaysTranslations array
   * @returns void
   */
  setWeekdaysTranslations() {
    const translateKeysToFetch = [
      'SETTINGS_NOTIFICATIONS.SCHEDULE.DAYS-DROPDOWN.WEEKDAY-PICKER.SUNDAY',
      'SETTINGS_NOTIFICATIONS.SCHEDULE.DAYS-DROPDOWN.WEEKDAY-PICKER.MONDAY',
      'SETTINGS_NOTIFICATIONS.SCHEDULE.DAYS-DROPDOWN.WEEKDAY-PICKER.TUESDAY',
      'SETTINGS_NOTIFICATIONS.SCHEDULE.DAYS-DROPDOWN.WEEKDAY-PICKER.WEDNESDAY',
      'SETTINGS_NOTIFICATIONS.SCHEDULE.DAYS-DROPDOWN.WEEKDAY-PICKER.THURSDAY',
      'SETTINGS_NOTIFICATIONS.SCHEDULE.DAYS-DROPDOWN.WEEKDAY-PICKER.FRIDAY',
      'SETTINGS_NOTIFICATIONS.SCHEDULE.DAYS-DROPDOWN.WEEKDAY-PICKER.SATURDAY',
    ];
    const translateObservables = translateKeysToFetch.map((key) =>
      this.translateService.stream(key)
    );

    combineLatest(translateObservables).subscribe((translations) => {
      this.weekdaysTranslations = translations;
    });
  }

  /**
   * Check if the selected schedule is custom and opens the weekday modal
   * @param item DropdownItemInterface | null
   * @returns void
   */
  checkForScheduleCustom(item: DropdownItemInterface | null) {
    if (item && item.value === settingsNotificationsScheduleDaysEnum.CUSTOM) {
      this.isWeekdayModalOpen = true;
    }
  }

  /**
   * Gets the selected values from ad-weekday-picker
   * @param event number[]
   * @returns void
   */
  weekdayChangeValue(event: number[]) {
    this.currWeekdays = event;
  }

  /**
   * Set the selected weekdays to the currWeekdays array from ad-weekday-picker
   * and formats the "custom" label with the selected weekdays
   * @param event number[]
   * @returns void
   */
  weekdayFormatSelectedLabel() {
    this.currWeekdays.sort();
    this.customWeekdaysValue = this.currWeekdays.length
      ? ` (${
          this.weekdaysTranslations[this.currWeekdays[0]] +
          (this.currWeekdays.length > 1 ? '...' : '')
        })`
      : '';

    const currItem = this.scheduleDaysArray.find(
      (item) => item.value === settingsNotificationsScheduleDaysEnum.CUSTOM
    );
    if (currItem) {
      currItem.label = this.customDefaultValue + this.customWeekdaysValue;
    }
  }

  /**
   * Close the weekday modal and reset the selected weekdays to the previous value
   * @returns void
   */
  weekdayModalClose() {
    this.isWeekdayModalOpen = false;
    const customWeekdays = this.formGroup
      .get('schedule')
      ?.get('customWeekdays')?.value;
    this.currWeekdays =
      customWeekdays !== null && customWeekdays !== undefined
        ? (customWeekdays as number[])
        : [];
  }

  /**
   * Save the selected weekdays and close the modal
   * @returns void
   */
  weekdayModalSave() {
    this.isWeekdayModalOpen = false;
    this.formGroup
      .get('schedule')
      ?.get('customWeekdays')
      ?.setValue(this.currWeekdays as unknown as null);

    this.weekdayFormatSelectedLabel();
  }

  /**
   * Generate an array of seconds 5s to 120s with 5s increments
   * @returns DropdownItemInterface[]
   */
  generateSecondsArray(): DropdownItemInterface[] {
    const newArray: DropdownItemInterface[] = [];
    for (let i = 0; i < 12; i++) {
      const second = (i + 1) * 10;
      newArray.push({
        value: second,
        label: second + 's',
      });
    }

    return newArray;
  }

  /**
   * Update the start hours array and the form value when the end time is selected
   * @param item DropdownItemInterface | null
   * @returns void
   *
   */
  timeEndClicked(item: DropdownItemInterface | null) {
    if (item !== null) {
      // update the start hours array to disable the hours that are after the selected end time
      const value =
        typeof item.value === 'string' ? parseInt(item.value) : item.value;
      this.scheduleStartHoursArray = this.generateHoursArray(value, true);
      // update the form value
      this.formGroup.get('schedule')?.get('timeEnd')?.setValue(value);
    }
  }

  /**
   * Update the end hours array and the form value when the start time is selected
   * @param item DropdownItemInterface | null
   * @returns void
   */
  timeStartClicked(item: DropdownItemInterface | null) {
    if (item !== null) {
      const value =
        typeof item.value === 'string' ? parseInt(item.value) : item.value;
      // update the end hours array to disable the hours that are before the selected start time
      this.scheduleEndHoursArray = this.generateHoursArray(value, false);
      // update the form value
      this.formGroup.get('schedule')?.get('timeStart')?.setValue(value);
    }
  }

  /**
   * Generate an array of hours 1h to 24h with 1h increments respective to the possible
   * options depending on the choice of the start or end time. the second parameter is used to see if we are generating the start or end hours array
   * @param limit number
   * @param isStartHoursArray boolean
   * @returns DropdownItemInterface[]
   */
  generateHoursArray(
    limit: number,
    isStartHoursArray: boolean
  ): DropdownItemInterface[] {
    const newArray: DropdownItemInterface[] = [];
    for (let i = 0; i < 24; i++) {
      const hour = i + 1;
      newArray.push({
        value: hour,
        label: hour + 'h',
        disabled: isStartHoursArray ? i >= limit : i < limit - 1,
      });
    }

    return newArray;
  }

  openCancelModal() {
    this.isCancelModalOpen = true;
  }
  closeCancelModal() {
    this.isCancelModalOpen = false;
  }
}
