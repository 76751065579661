<div class="reports__header">
  <h1 class="text-subHeader--semibold reports__header__title">
    {{ 'REPORTS.MAIN-TITLE' | translate }}
  </h1>

  <button
    astus-design-button
    class="reports__header__generate-btn"
    [size]="'sm'"
    (click)="openGenerateModal()"
  >
    {{ 'REPORTS.GENERATE-BUTTON' | translate }}
  </button>
</div>

<div class="reports__list">
  <ad-table
    data-test-ressources-table
    [data]="data"
    [headers]="headers"
    [stickyColumns]="fixedColumns"
    [sorting]="true"
    (iconEvent)="onExportIconClick($event)"
    dataType="reports"
  ></ad-table>
  <div class="empty-table empty-table-container" *ngIf="data.length === 0">
    <ad-table-empty-state
      [icon]="'page'"
      [errorMessage]="errorTitleMessage"
      [errorDescription]="errorDescriptionMessage"
    >
    </ad-table-empty-state>
  </div>
</div>

<ad-modal
  class="reports__generate-modal"
  [isOpen]="isGenerateModalOpen"
  [closeOnOutsideClick]="false"
  (outsideModalClick)="closeGenerateModal()"
>
  <h2 class="text-p1--semibold reports__generate-modal__title" top>
    {{ 'REPORTS.GENERATE-MODAL.TITLE' | translate }}
  </h2>

  <form
    middle
    class="reports__generate-modal__form"
    [formGroup]="generateReportForm"
  >
    <div class="reports__generate-modal__time">
      <astus-date-and-time-fields
        [label]="'REPORTS.GENERATE-MODAL.START-LABEL' | translate"
        [date]="startDate"
        [time]="startTime"
        (dateChange)="onDateTimeValueChange($event, 'startDate')"
        (timeChange)="onDateTimeValueChange($event, 'startTime')"
      ></astus-date-and-time-fields>
      <astus-date-and-time-fields
        class="reports__generate-modal__right-date-time-field"
        [label]="'REPORTS.GENERATE-MODAL.END-LABEL' | translate"
        [date]="endDate"
        [time]="endTime"
        (dateChange)="onDateTimeValueChange($event, 'endDate')"
        (timeChange)="onDateTimeValueChange($event, 'endTime')"
      ></astus-date-and-time-fields>
    </div>

    <ad-dropdown
      [placeholder]="'REPORTS.GENERATE-MODAL.REPORTS-PLACEHOLDER' | translate"
      [items]="reportsItems"
      formControlName="reportType"
    >
    </ad-dropdown>

    <ad-dropdown
      [placeholder]="vehiclesLabelValue"
      (click)="onVehicleDropdownClick($event)"
    >
      <div class="reports__generate-modal__vehicles" formGroupName="vehicles">
        <ad-checkbox
          [text]="'REPORTS.GENERATE-MODAL.VEHICLES-ALL' | translate"
          (checkboxEvent)="onVehicleAllToggle()"
          [selected]="isAllVehiclesSelected"
        ></ad-checkbox>
        <div class="reports__generate-modal__vehicles__list">
          <ad-checkbox
            *ngFor="let vehicle of vehiclesItems"
            [formControlName]="vehicle.value.toString()"
            [text]="vehicle.label"
            [value]="vehicle.value.toString()"
          ></ad-checkbox>
        </div>
      </div>
    </ad-dropdown>
  </form>

  <div bottom class="reports__generate-modal__controls">
    <button
      astus-design-button
      [size]="'sm'"
      [kind]="'outlined'"
      [color]="'neutral'"
      (click)="closeGenerateModal()"
    >
      {{ 'REPORTS.GENERATE-MODAL.CANCEL-BUTTON' | translate }}
    </button>
    <button
      astus-design-button
      [size]="'sm'"
      (click)="generateReport()"
      [disabled]="!hasChangedValues"
    >
      {{ 'REPORTS.GENERATE-MODAL.GENERATE-BUTTON' | translate }}
    </button>
  </div>
</ad-modal>

<ad-modal
  [isOpen]="isCancelModalOpen"
  class="reports__cancel-modal"
  (outsideModalClick)="goBackFromCancelModal()"
>
  <div top>
    <div>
      <ad-icon [glyph]="'alert'" class="icon" [color]="'warning-400'"></ad-icon>
    </div>
    <div class="text-p1--semibold reports__cancel-modal__title-text">
      {{ 'REPORTS.CANCEL-MODAL.TITLE' | translate }}
    </div>
    <div class="text-p3 reports__cancel-modal__subtitle-text">
      {{ 'REPORTS.CANCEL-MODAL.TEXT' | translate }}
    </div>
  </div>
  <div middle class="reports__cancel-modal__button-container">
    <button
      astus-design-button
      [size]="'md'"
      kind="outlined"
      color="neutral"
      class="reports__cancel-modal__cancel-button"
      (click)="goBackFromCancelModal()"
    >
      {{ 'REPORTS.CANCEL-MODAL.CANCEL-BUTTON' | translate }}
    </button>
    <button
      astus-design-button
      [size]="'md'"
      kind="filled"
      color="primary"
      class="reports__cancel-modal__confirm-button"
      (click)="confirmCancel()"
    >
      {{ 'REPORTS.CANCEL-MODAL.CONFIRM-BUTTON' | translate }}
    </button>
  </div>
</ad-modal>
