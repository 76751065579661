<div class="header-container">
  <button
    *ngIf="displayMenu"
    type="button"
    class="menu-button"
    [ngClass]="{ 'menu-button--close': this.showMenu }"
    (click)="toggleMenu(showMenu)"
  >
    <div class="bar"></div>
    <div class="bar"></div>
    <div class="bar"></div>
  </button>
  <astus-settings-header *ngIf="route === '/settings'"></astus-settings-header>
  <router-outlet name="header-top"></router-outlet>
  <div *ngIf="showTitle" class="text-subHeader--semibold">
    {{ pageTitle | translate }}
  </div>
  <div *ngIf="showTitle" class="text-subHeader--semibold">
    {{ settingsTitle | translate }}
  </div>
  <div
    *ngIf="termsConditionsTitle.length"
    class="tc-title text-subHeader--semibold"
  >
    {{ termsConditionsTitle | translate }}
  </div>
  <div *ngIf="showMenu && isMobile">
    <img src="assets/images/logo.svg" alt="logo" class="logo" />
  </div>

  <astus-resources-header
    class="create-resources-button"
    *ngIf="isRouteResources"
  ></astus-resources-header>
</div>
