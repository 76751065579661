import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FaviconService {
  changeFavicon(url: string): void {
    const oldLink = document.querySelector('link');
    const newLink = document.createElement('link');
    newLink.rel = 'icon';
    newLink.type = 'image/x-icon';
    newLink.href = url;

    if (!oldLink) {
      throw new Error('No favicon link found in the document');
    }

    document.head.replaceChild(newLink, oldLink);
  }
}
