import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { appRoutesEnum } from '../../../app-routing.module';
import {
  dateActions,
  getDateFeature,
  getTimeLineStateFeature,
  hoursPickerState,
  menuActions,
  showMenuFeature,
} from '../../../store';

@Component({
  selector: 'astus-supervision-header',
  templateUrl: './supervision-header.component.html',
  styleUrls: ['./supervision-header.component.scss'],
})
export class SupervisionHeaderComponent implements OnInit {
  route = '';
  showMenu!: boolean;
  showFeatureHeader = true;
  date!: Date | Date[];
  isMobile = window.innerWidth < 600;
  resizeSubscription!: Subscription;
  currentLanguage = '';

  showHourPicker = false;
  showStartHourPicker = true;
  pickersDate: hoursPickerState = {
    firstHourSelection: '00:00',
    secondHourSelection: '00:00',
  };

  // accessible url for the timeline button
  // for now it should be the supervision page
  timelineAccessibleUrl = [appRoutesEnum.SUPERVISION];
  timeLineIsShown = false;
  timeLineStatus = true;

  constructor(
    private store: Store,
    private router: Router,
    private translateService: TranslateService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    this.route = this.router.url;
    this.displayTheFeatureHeaderOrNot();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.route = event.urlAfterRedirects;
        this.displayTheFeatureHeaderOrNot();
      }
    });

    // Subscribe to the store
    // get the menu opening state
    this.store.select(showMenuFeature).subscribe((showMenu) => {
      this.showMenu = showMenu;
      this.displayTheFeatureHeaderOrNot();
    });

    // get the date
    this.store.select(getDateFeature).subscribe((date) => {
      this.date = date;
    });

    // get the pickers date
    this.store.select(getTimeLineStateFeature).subscribe((timeline) => {
      this.pickersDate = timeline.hoursPickersState;
      this.showStartHourPicker = timeline.hourPickerOpenedInStartDate;
      this.timeLineStatus = timeline.timeLineStatus;
    });

    this.currentLanguage = this.translateService.currentLang;
  }

  ngOnInit(): void {
    this.timeLineIsShown = this.router.url.includes(
      this.timelineAccessibleUrl.toString()
    );
  }

  @HostListener('window:resize')
  onResize() {
    this.isMobile = window.innerWidth < 600;
    this.displayTheFeatureHeaderOrNot();
  }

  /**
   * This function is called when the user clicks on the date picker
   * @param {Date | Date[]} date - The date selected by the user
   */
  handleDate(date: Date | Date[]) {
    this.store.dispatch(dateActions.setDate({ date }));
  }

  /**
   * Handle the first hour selection and save it to the store
   * @param string firstHourSelection - the selected hour
   */
  handleFirstHourSelection(firstHourSelection: string) {
    this.store.dispatch(
      dateActions.setHoursTimelineFirstPicker({ firstHourSelection })
    );
  }

  /**
   * Handle the second hour selection and save it to the store
   * @param string secondHourSelection - the selected hour
   */
  handleSecondHoursSelection(secondHourSelection: string) {
    this.store.dispatch(
      dateActions.setHoursTimelineSecondPicker({ secondHourSelection })
    );
  }

  toggleMenu(showMenu: boolean) {
    this.store.dispatch(menuActions.toggleMenu({ showMenu }));
  }

  /**
   * This function render the supervision header feature or not
   * Should hide and set the supervision component width to auto
   * if the user open the menu on mobile
   */
  displayTheFeatureHeaderOrNot() {
    if (this.isMobile) {
      if (this.showMenu) {
        this.showFeatureHeader = false;
      } else {
        this.showFeatureHeader = true;
        this.renderer.setStyle(this.el.nativeElement, 'width', '100%');
        return;
      }
    } else {
      this.showFeatureHeader = true;
    }
    this.renderer.setStyle(this.el.nativeElement, 'width', 'auto');
  }

  /**
   * This function is called when the user cancel the selection in the hour picker
   */
  userCancelledSelectionInHourPicker() {
    this.showHourPicker = false;
  }

  /**
   * This function is called when the user select an element in the hour picker
   * @param {timelineState} dataFromHourPicker - The data from the hour picker
   */
  userSelectedElementInHourPicker(dataFromHourPicker: hoursPickerState) {
    this.showHourPicker = false;
    this.pickersDate = {
      ...this.pickersDate,
    };

    this.store.dispatch(
      dateActions.setHoursTimelineFirstPicker({
        firstHourSelection: dataFromHourPicker.firstHourSelection,
      })
    );

    this.store.dispatch(
      dateActions.setHoursTimelineSecondPicker({
        secondHourSelection: dataFromHourPicker.secondHourSelection,
      })
    );
  }

  /**
   * This function is called when the user clicks on the hours picker
   */
  openMobilePicker(openStartHours: boolean) {
    this.showHourPicker = true;
    this.showStartHourPicker = openStartHours;
  }
}
