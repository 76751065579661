import { createFeatureSelector, createSelector } from '@ngrx/store';

import { reportReducerInterface } from './report';
import { reportFeatureKey } from './report.reducer';

export const reportState =
  createFeatureSelector<reportReducerInterface>(reportFeatureKey);

export const getReportListData = createSelector(
  reportState,
  (state) => state.reports.list
);
