<form class="card calendar-container">
  <p-calendar
    [(ngModel)]="date"
    [inline]="true"
    name="date"
    [selectionMode]="selectionMode"
    (onSelect)="handleDateSelect($event)"
  >
  </p-calendar>
  <div class="calendar-radio-container" *ngIf="showSelectionModeFilters">
    <ad-radio
      [text]="selectionModeLabels.single"
      name="date-select"
      value="single"
      (click)="toggleSelectionMode('single')"
      [selected]="selectionMode === 'single'"
    ></ad-radio>
    <ad-radio
      [text]="selectionModeLabels.range"
      name="date-select"
      (click)="toggleSelectionMode('range')"
      value="range"
      [selected]="selectionMode === 'range'"
    ></ad-radio>
  </div>

  <div *ngIf="dateRangeFilters?.length" class="calendar-chip-container">
    <ad-chip
      *ngFor="let dateRange of dateRangeFilters"
      [selected]="filterSelectionState(dateRange?.range)"
      (selectChipEvent)="dateFilter(dateRange?.range)"
      color="primary"
      >{{ dateRange.label }}</ad-chip
    >
  </div>
</form>
