import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownItemInterface } from '@common/ng-design-system';

import { hoursPickerState } from '../../store';

import {
  getIndexForHoursPickerAccordingSelectedHours,
  getIndexForMinutesPickerAccordingSelectedMinutes,
} from './helpers/hour-picker-mobile.helper';

export interface PickerInterface {
  previousIndex: number;
  selectedIndex: number;
  nextIndex: number;
}
@Component({
  selector: 'astus-hour-picker-mobile',
  templateUrl: './hour-picker-mobile.component.html',
  styleUrls: ['./hour-picker-mobile.component.scss'],
})
export class HourPickerMobileComponent implements OnInit {
  // Component Input
  @Input() hoursPickerData: hoursPickerState = {
    firstHourSelection: '00:00',
    secondHourSelection: '00:00',
  };

  @Input() startHoursIsSelected = true;

  // Component Output
  @Output() userSelectedElement: EventEmitter<hoursPickerState> =
    new EventEmitter<hoursPickerState>();

  @Output() userCancelledSelection: EventEmitter<void> =
    new EventEmitter<void>();

  // Component list data
  hoursForPicker: DropdownItemInterface[] = [];
  minutesForPicker: DropdownItemInterface[] = [];

  // Hours / minute state management
  hoursPicker: PickerInterface = {
    previousIndex: 0,
    selectedIndex: 0,
    nextIndex: 1,
  };
  minutesPicker: PickerInterface = {
    previousIndex: 0,
    selectedIndex: 0,
    nextIndex: 1,
  };

  ngOnInit(): void {
    // Generate the hours and minutes for the picker
    this.hoursForPicker = this.generateHoursForPicker();
    this.minutesForPicker = this.generateMinutesForPicker();

    // Initialize the hours picker data with the selected hours
    this.displayTheSelectedHoursMinutePickerData();
  }

  /**
   * This function is called when the user clicks on the hours picker
   * @param {boolean} isNextHours - True if the user clicked on the next hours (bottom element in the picker)
   * false if the user clicked on the previous hours
   * @param DropdownItemInterface selectedHours - The selected hours
   */
  onHoursChange(
    isNextHours: boolean,
    selectedHours: DropdownItemInterface
  ): void {
    const adjustIndex = (index: number, isNext: boolean): number => {
      const newIndex = isNext ? index + 1 : index - 1;
      if (this.checkIfIndexIsOutOfRangeOfArrayOfHours(newIndex)) {
        return isNext ? 0 : this.hoursForPicker.length - 1;
      }
      return newIndex;
    };

    this.hoursPicker = {
      previousIndex: adjustIndex(this.hoursPicker.previousIndex, isNextHours),
      selectedIndex: adjustIndex(this.hoursPicker.selectedIndex, isNextHours),
      nextIndex: adjustIndex(this.hoursPicker.nextIndex, isNextHours),
    };

    if (this.startHoursIsSelected) {
      this.hoursPickerData = {
        firstHourSelection: this.formatHoursAndMinutes(
          selectedHours.value,
          this.minutesForPicker[this.minutesPicker.selectedIndex].value
        ),
        secondHourSelection: this.hoursPickerData.secondHourSelection,
      };
    } else {
      this.hoursPickerData = {
        firstHourSelection: this.hoursPickerData.firstHourSelection,
        secondHourSelection: this.formatHoursAndMinutes(
          selectedHours.value,
          this.minutesForPicker[this.minutesPicker.selectedIndex].value
        ),
      };
    }
  }

  /**
   * This function is called when the user clicks on the minutes picker
   * @param boolean isNextMinutes - True if the user clicked on the next minutes (bottom element in the picker)
   * false if the user clicked on the previous minutes
   * @param DropdownItemInterface selectedMinutes - The selected minutes
   */
  onMinutesChange(
    isNextMinutes: boolean,
    selectedMinutes: DropdownItemInterface
  ) {
    const adjustIndex = (index: number, isNext: boolean): number => {
      const newIndex = isNext ? index + 1 : index - 1;
      if (this.checkIfIndexIsOutOfRangeOfArrayOfMinutes(newIndex)) {
        return isNext ? 0 : this.minutesForPicker.length - 1;
      }
      return newIndex;
    };

    this.minutesPicker = {
      previousIndex: adjustIndex(
        this.minutesPicker.previousIndex,
        isNextMinutes
      ),
      selectedIndex: adjustIndex(
        this.minutesPicker.selectedIndex,
        isNextMinutes
      ),
      nextIndex: adjustIndex(this.minutesPicker.nextIndex, isNextMinutes),
    };

    if (this.startHoursIsSelected) {
      this.hoursPickerData = {
        firstHourSelection: this.formatHoursAndMinutes(
          this.hoursForPicker[this.hoursPicker.selectedIndex].value,
          selectedMinutes.value
        ),
        secondHourSelection: this.hoursPickerData.secondHourSelection,
      };
    } else {
      this.hoursPickerData = {
        firstHourSelection: this.hoursPickerData.firstHourSelection,
        secondHourSelection: this.formatHoursAndMinutes(
          this.hoursForPicker[this.hoursPicker.selectedIndex].value,
          selectedMinutes.value
        ),
      };
    }
  }

  /**
   * This function is called when the user clicks on the cancel button
   */
  onCancelClick() {
    this.userCancelledSelection.emit();
  }

  /**
   * This function is called when the user clicks on the confirm button
   */
  onConfirmClick() {
    this.userSelectedElement.emit(this.hoursPickerData);
  }

  /**
   * Change the start hours tab
   * @param boolean tabSelected - The tab selected, true for start hours, false for end hours
   */
  changeStartHoursTab(tabSelected: boolean) {
    this.startHoursIsSelected = tabSelected;
    this.displayTheSelectedHoursMinutePickerData();
  }

  /**
   * This function generates the hours for the picker
   * @returns DropdownItemInterface[] - The hours for the picker in the format {label: string, value: number}
   * @example [{label: '00:00', value: 0}, {label: '01:00', value: 1}, ...]
   */
  generateHoursForPicker(): DropdownItemInterface[] {
    const newArray: DropdownItemInterface[] = [];
    for (let i = 0; i < 24; i++) {
      const hour = i;
      const value = `${hour < 10 ? `0${hour}` : hour}`;

      newArray.push({
        label: value,
        value: value,
      });
    }

    return newArray;
  }

  displayTheSelectedHoursMinutePickerData(): void {
    if (this.startHoursIsSelected) {
      this.hoursPicker = {
        ...getIndexForHoursPickerAccordingSelectedHours(
          this.hoursPickerData.firstHourSelection.split(':')[0],
          this.hoursForPicker
        ),
      };

      this.minutesPicker = {
        ...getIndexForMinutesPickerAccordingSelectedMinutes(
          this.hoursPickerData.firstHourSelection.split(':')[1],
          this.minutesForPicker
        ),
      };
    } else {
      this.hoursPicker = {
        ...getIndexForHoursPickerAccordingSelectedHours(
          this.hoursPickerData.secondHourSelection.split(':')[0],
          this.hoursForPicker
        ),
      };

      this.minutesPicker = {
        ...getIndexForMinutesPickerAccordingSelectedMinutes(
          this.hoursPickerData.secondHourSelection.split(':')[1],
          this.minutesForPicker
        ),
      };
    }
  }

  /**
   * This function generates the minutes for the picker
   * @returns DropdownItemInterface[] - The minutes for the picker in the format {label: string, value: number}
   * @example [{label: '00', value: 0}, {label: '15', value: 15}, ...]
   */
  generateMinutesForPicker(): DropdownItemInterface[] {
    const newArray: DropdownItemInterface[] = [];
    for (let i = 0; i < 60; i += 15) {
      const minute = i;
      const value = `${minute < 10 ? `0${minute}` : minute}`;

      newArray.push({
        label: value,
        value,
      });
    }

    return newArray;
  }

  /**
   * This function checks if the index is out of range of the array of hours
   * @param hourSelectedIndex - The index of the hour selected
   * @returns boolean - True if the index is out of range, false otherwise
   */
  private checkIfIndexIsOutOfRangeOfArrayOfHours(
    hourSelectedIndex: number
  ): boolean {
    return (
      hourSelectedIndex < 0 || hourSelectedIndex >= this.hoursForPicker.length
    );
  }

  /**
   * This function checks if the index is out of range of the array of minutes
   * @param minuteSelectedIndex - The index of the minute selected
   * @returns boolean - True if the index is out of range, false otherwise
   */
  private checkIfIndexIsOutOfRangeOfArrayOfMinutes(
    minuteSelectedIndex: number
  ): boolean {
    return (
      minuteSelectedIndex < 0 ||
      minuteSelectedIndex >= this.minutesForPicker.length
    );
  }

  /**
   * This function formats the hours and minutes
   * @param {string | number} hours - The hours
   * @param {string | number} minutes - The minutes
   * @returns string - The formatted hours and minutes
   * @example formatHoursAndMinutes(1, 15) => '01:15'
   */
  formatHoursAndMinutes(
    hours: string | number,
    minutes: string | number
  ): string {
    return `${hours}:${minutes}`;
  }
}
