import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { ActivatedRoute } from '@angular/router';
import {
  DropdownItemInterface,
  vehicleTableInterface,
  vehicleTypeEnum,
} from '@common/ng-design-system';

import { LaunchDarklyService } from '@common/ts-feature-flag';
import { assert } from '@common/utils';
import { UpsertVehicleDto, VehicleModelDto } from '@fms/ng-fms-api-client';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { Subscription, map } from 'rxjs';

import {
  getCarrierTypeListAsDropdownItem,
  getValidCountryListAsDropdownItem,
  getFuelTypeListAsDropdownItem,
  getHomePortTypeListAsDropdownItem,
  getModuleTypeListAsDropdownItem,
  getProvinceListAsDropdownItem,
  getTagSerialNumberListAsDropdownItem,
  getTimezoneListAsDropdownItem,
  getVehicleTypeListAsDropdownItem,
  getImeiListAsDropdownItem,
} from '../../helpers';
import { DEFAULTVALUE } from '../../helpers/parse-data.helper';
import { resourceActions } from '../../store';
import { getVehicleFormDropdowns } from '../../store/resource.selector';

export type VehicleFormDropdowns = {
  vehicleType: DropdownItemInterface[];
  country: DropdownItemInterface[];
  province: DropdownItemInterface[];
  fuelType: DropdownItemInterface[];
  carrier: DropdownItemInterface[];
  homePort: DropdownItemInterface[];
  timeZone: DropdownItemInterface[];
  tagSerialNumber: DropdownItemInterface[];
  moduleType: DropdownItemInterface[];
  imei: DropdownItemInterface[];
};

export interface vehicleFormInterface {
  name: FormControl<string | null>;
  groups: FormControl<string[] | null>;
  vin: FormControl<string | null>;
  imei: FormControl<string | null>; // TODO: implement imei field in form
  plateNumber: FormControl<string | null>;
  country: FormControl<string | null>;
  province: FormControl<string | null>;
  brand: FormControl<string | null>;
  model: FormControl<string | null>;
  year: FormControl<string | null>;
  fuelType: FormControl<VehicleModelDto.FuelTypeEnum | null>;
  carrierId: FormControl<string | null>;
  carrier: FormControl<string | null>;
  homePortId: FormControl<string | null>;
  homePort: FormControl<string | null>;
  timezone: FormControl<string | null>;
  tagSerialNumber: FormControl<string | null>;
  tagType: FormControl<string | null>;
  softwareVersion: FormControl<string | null>;
  lastConnection: FormControl<string | null>;
  active: FormControl<string | null>;
  vehicleType: FormControl<vehicleTypeEnum | null>;
  iotDeviceId: FormControl<string | null>;
  modelId: FormControl<string | null>;
}
@Component({
  selector: 'astus-vehicle-form',
  templateUrl: './vehicle-form.component.html',
  styleUrls: ['./vehicle-form.component.scss'],
})
export class VehicleFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data: vehicleTableInterface | null = null;
  @Input() isEditing = false;

  // data share with template
  public vehicleFormDropdowns: VehicleFormDropdowns = {
    vehicleType: [],
    country: [],
    province: [],
    fuelType: [],
    carrier: [],
    homePort: [],
    timeZone: [],
    tagSerialNumber: [],
    moduleType: [],
    imei: [],
  };

  vehicleForm: FormGroup<vehicleFormInterface> =
    this.fb.group<vehicleFormInterface>({
      // General Information
      name: new FormControl('', [Validators.required]),
      vehicleType: new FormControl(null),
      groups: new FormControl(Array<string>()),
      // Details Information
      vin: new FormControl('', [Validators.required]),
      imei: new FormControl(null), // TODO: implement imei field in form
      plateNumber: new FormControl(''),
      country: new FormControl(''),
      province: new FormControl(''),
      brand: new FormControl(''),
      model: new FormControl(''),
      year: new FormControl(null),
      fuelType: new FormControl(null),
      carrierId: new FormControl(null),
      carrier: new FormControl(''),
      homePortId: new FormControl(null),
      homePort: new FormControl(''),
      timezone: new FormControl(''),
      // Telematics Information
      tagSerialNumber: new FormControl(null),
      tagType: new FormControl(''),
      softwareVersion: new FormControl(''),
      lastConnection: new FormControl(''),
      // Resource sharing
      active: new FormControl(''),
      iotDeviceId: new FormControl(null),
      modelId: new FormControl(null),
    });

  @Output() vehicleFormEmitter: EventEmitter<FormGroup<vehicleFormInterface>> =
    new EventEmitter();

  formSubscription$ = new Subscription();

  previousCountry = '';

  groupsFlag$ = this.launchDarklyService.getFlagValue$('Reglages_Groupes');

  activatedRouteSubscription = new Subscription();

  constructor(
    private translateService: TranslateService,
    private fb: FormBuilder,
    private launchDarklyService: LaunchDarklyService,
    private store: Store,
    private activatedRoute: ActivatedRoute
  ) {
    this.vehicleForm.valueChanges.subscribe(() => {
      this.vehicleFormEmitter.emit(this.vehicleForm);
    });

    this.fb.control('year').setValue(null);
    this.fb.control('tagSerialNumber').setValue(null);
  }

  ngOnInit(): void {
    // get timezones from translation
    this.translateService.stream('TIMEZONES').subscribe((res) => {
      this.vehicleFormDropdowns.timeZone = getTimezoneListAsDropdownItem(res);
    });
    if (this.data) {
      this.previousCountry = this.data.country;
    }

    this.activatedRouteSubscription = this.activatedRoute.params
      .pipe(
        map(({ id }: { id?: string }) => (id ? id : (id as undefined))) // assume undefined if id is not a string
      )
      .subscribe((id?: string) => {
        this.store.dispatch(resourceActions.findAllInfoboxVehicleLoad({ id }));
      });

    // Populate the dropdowns
    this.store
      .select(getVehicleFormDropdowns)
      .subscribe((infoboxVehicleView) => {
        this.vehicleFormDropdowns = {
          vehicleType: getVehicleTypeListAsDropdownItem(
            infoboxVehicleView.vehicleType
          ),
          carrier: getCarrierTypeListAsDropdownItem(
            infoboxVehicleView.carrierType
          ),
          fuelType: getFuelTypeListAsDropdownItem(infoboxVehicleView.fuelType),
          homePort: getHomePortTypeListAsDropdownItem(
            infoboxVehicleView.homePortType
          ),
          moduleType: getModuleTypeListAsDropdownItem(
            infoboxVehicleView.iotDeviceType
          ),
          tagSerialNumber: getTagSerialNumberListAsDropdownItem(
            infoboxVehicleView.iotDeviceType
          ),
          imei: getImeiListAsDropdownItem(infoboxVehicleView.iotDeviceType),
          // translate the countries to the current language
          // according to the country key from the iso-3166 lib
          // basically, the country key is the same as the translation key
          // eg:  `VEHICLES_FORM.COUNTRY.${country.value}` => `VEHICLES_FORM.COUNTRY.US`
          country: getValidCountryListAsDropdownItem().map((country) => ({
            label: this.translateService.instant(
              `VEHICLES_FORM.COUNTRY.${country.value}`
            ),
            value: country.value,
          })),
          province: getProvinceListAsDropdownItem(
            this.vehicleForm.value.country
          ),
          timeZone: [...this.vehicleFormDropdowns.timeZone],
        };
      });

    const vehicleFormCountry = this.vehicleForm.get('country');

    // Subscribe to country changes to update province dropdown and province value
    if (vehicleFormCountry) {
      this.formSubscription$ = vehicleFormCountry.valueChanges.subscribe(
        (value) => {
          // if the country has been changed and the form is not pristine, reset the province value
          if (this.previousCountry !== value) {
            if (!this.vehicleForm.pristine) {
              this.vehicleForm.get('province')?.setValue('');
            }
            this.previousCountry = value || '';
            this.vehicleFormDropdowns.province =
              getProvinceListAsDropdownItem(value);
          }
        }
      );
    } else {
      console.error('vehicleFormCountry is not defined');
    }
  }

  /**
   * when there is data passed to the component, it updates the form in the infobox
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    // reset the form state because every time a row is clicked the form has to be pristine
    this.vehicleForm.markAsPristine();

    if (changes['data'] && this.data) {
      this.vehicleForm.patchValue({
        year: this.data.year.toString(),
        carrier: this.data.carrier,
        fuelType: _.upperFirst(
          this.data.fuelType
        ) as VehicleModelDto.FuelTypeEnum,
        homePort: this.data.homePort,
        lastConnection: this.data.lastConnection,
        model: this.data.model,
        vin: this.data.vin,
        name: this.data.name,
        plateNumber: this.data.plateNumber,
        softwareVersion: this.data.softwareVersion,
        tagType: this.data.tagType,
        active: this.data.active,
        vehicleType: this.data.vehicleType || vehicleTypeEnum.bus,
        brand: this.data.brand,
        tagSerialNumber: this.data.serialNumber,
        province: this.data.province,
        country: this.data.country,
        timezone: this.data.timezone,
        imei: this.data.imei,
        modelId: this.data.modelId === DEFAULTVALUE ? null : this.data.modelId,
        iotDeviceId:
          this.data.iotDeviceId === DEFAULTVALUE ? null : this.data.iotDeviceId,
        carrierId:
          this.data.carrierId === DEFAULTVALUE ? null : this.data.carrierId,
        homePortId:
          this.data.homePortId === DEFAULTVALUE ? null : this.data.homePortId,
      });
    }
  }

  ngOnDestroy(): void {
    this.formSubscription$.unsubscribe();
    this.activatedRouteSubscription.unsubscribe();
  }

  onItemTypeChange(
    item: DropdownItemInterface | null,
    type: keyof UpsertVehicleDto
  ) {
    if (item === null) {
      return;
    }

    assert(!!item?.id, 'itemId is undefined');
    switch (type) {
      case 'iotDeviceId': {
        // TODO: item.id is forcerd to be string but we need to change the DropdownItemInterface.id type  to string
        this.vehicleForm.get('iotDeviceId')?.setValue(item.id.toString());
        this.vehicleForm
          .get('tagSerialNumber')
          ?.setValue(
            this.vehicleFormDropdowns.tagSerialNumber
              .find((tag) => tag.id === item.id)
              ?.value.toString() || null
          );
        this.vehicleForm
          .get('tagType')
          ?.setValue(
            this.vehicleFormDropdowns.moduleType
              .find((module) => module.id === item.id)
              ?.value.toString() || null
          );

        const imei = this.vehicleFormDropdowns.imei.find(
          (imei) => imei.id === item.id
        )?.value;
        this.vehicleForm.get('imei')?.setValue(imei ? imei.toString() : null);
        break;
      }
      case 'carrierId':
        // TODO: item.id is forcerd to be string but we need to change the DropdownItemInterface.id type  to string
        this.vehicleForm.get('carrierId')?.setValue(item.id.toString());
        break;

      case 'homePortId':
        // TODO: item.id is forcerd to be string but we need to change the DropdownItemInterface.id type  to string
        this.vehicleForm.get('homePortId')?.setValue(item.id.toString());
        break;

      case 'modelId':
        // TODO: item.id is forcerd to be string but we need to change the DropdownItemInterface.id type  to string
        this.vehicleForm.get('modelId')?.setValue(item.id.toString());
        break;
    }
  }
}
