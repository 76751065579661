import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { getUserDurationFormat } from '../../store';

import { durationEnum } from './duration.enum';

@Pipe({
  name: 'durationFormat',
  standalone: true,
})
export class DurationPipe implements PipeTransform {
  durationSystem = durationEnum['JJ hh:mm'];

  constructor(
    private store: Store,
    private translateService: TranslateService
  ) {
    store.select(getUserDurationFormat).subscribe((durationSystem) => {
      this.durationSystem = durationSystem;
    });
  }

  /**
   * @description
   * Transform a timestamp in milliseconds to a formatted duration
   * @example {{ 100000 | durationFormat }} => 00:01:40
   * @example {{ 24000000 | durationFormat }} => 06:40:00
   * @example {{ 420000000 | durationFormat }} => 116:40:00 / 4j 20:40:00
   *
   * In TS : this.durationPipe.transform(timestampInMilliseconds)
   *
   * @param {string | number} value timestamp in minutes
   *
   * @returns {string} the formatted duration
   */
  transform(duration: number): string {
    const durationInMilliseconds = duration * 60000;
    const days = Math.floor(durationInMilliseconds / 86400000);
    const hours = Math.floor((durationInMilliseconds % 86400000) / 3600 / 1000);
    const minutes = Math.floor((durationInMilliseconds % 3600000) / 60 / 1000);
    const seconds = Math.floor((durationInMilliseconds / 1000) % 60);

    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    const daysUnit = this.translateService.currentLang === 'en' ? 'd' : 'j';

    let formattedDuration = '';

    if (days > 0) {
      formattedDuration += `${days}${daysUnit} `;
    }

    formattedDuration += `${formattedHours}:${formattedMinutes}`;

    if (this.durationSystem === durationEnum['JJ hh:mm:ss']) {
      formattedDuration += `:${formattedSeconds}`;
    }

    return formattedDuration;
  }
}
