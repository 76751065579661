<astus-settings-button-layout
  [enableCancel]="true"
  (saveEmitter)="onSave()"
  (cancelEmitter)="onCancel()"
  [processState]="processState"
  [disabled]="processDisabled"
>
  <astus-settings-permissions-header
    [id]="roleId"
    [title]="title"
    [description]="description"
    [assocUsers]="assocUsers"
    [isReadOnly]="false"
    (titleInput)="headerTitle($event)"
    (descriptionInput)="headerDescription($event)"
    (cancelArrowClick)="onCancel()"
  ></astus-settings-permissions-header>

  <div class="list">
    <div class="permission-container" *ngIf="groupsFlag$ | async">
      <p class="text-p3--semibold title">
        {{
          'SETTINGS_ROLES-AND-PERMISSIONS.PERMISSIONS.ACCESS_PANEL.TITLE'
            | translate
        }}
      </p>
      <div class="dropdown">
        <astus-resource-role-permission
          [rolesPermissionsList]="rolesAndResourceDropDowns.roles"
          [placeholder]="'SETTINGS.NAV.GROUPS' | translate"
        >
        </astus-resource-role-permission>
      </div>
    </div>
    <div class="permission-categories">
      <astus-settings-permission-card-general
        [formGroup]="generalGroup"
      ></astus-settings-permission-card-general>
      <astus-settings-permission-card-administration
        [formGroup]="administrationGroup"
      ></astus-settings-permission-card-administration>
    </div>
  </div>
</astus-settings-button-layout>

<ad-modal
  [isOpen]="openCancelConfirmationModal"
  (outsideModalClick)="closeCancelModal()"
>
  <div middle class="modal-middle">
    <ad-icon glyph="alert"></ad-icon>
    <div class="text-p1--semibold modal-title">
      {{
        'SETTINGS_ROLES-AND-PERMISSIONS.PERMISSIONS.HEADER.CANCEL-MODAL.TITLE'
          | translate
      }}
    </div>
    <div class="text-p3 modal-description">
      {{
        'SETTINGS_ROLES-AND-PERMISSIONS.PERMISSIONS.HEADER.CANCEL-MODAL.DESCRIPTION'
          | translate
      }}
    </div>
    <div class="modal-bottom">
      <button
        astus-design-button
        kind="outlined"
        size="md"
        class="cancel-button"
        (click)="closeCancelModal()"
      >
        {{
          'SETTINGS_ROLES-AND-PERMISSIONS.PERMISSIONS.HEADER.CANCEL-MODAL.CANCEL'
            | translate
        }}
      </button>
      <button
        astus-design-button
        kind="filled"
        size="md"
        class="delete-button"
        (click)="redirectToRoles()"
      >
        {{
          'SETTINGS_ROLES-AND-PERMISSIONS.PERMISSIONS.HEADER.CANCEL-MODAL.CONFIRM'
            | translate
        }}
      </button>
    </div>
  </div>
</ad-modal>
