import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from '@auth0/auth0-angular';
import { TenantsType } from '@common/ts-feature-flag';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { PrimeNGConfig } from 'primeng/api';

import { notificationTypeEnum } from './components/menu/notification-center/notification-center.enums';
import { AppInitializerService } from './services/appInitializer.service';
import {
  getUserLanguage,
  notificationActions,
  getNotifications,
} from './store';
import { notificationInterface } from './store/global';
import { mockNotifications } from './store/notifications.mock';

@Component({
  selector: 'astus-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  currLanguage = 'fr';
  isAuth0Loading$ = this.authService.isLoading$;
  isAppLoaded = false;
  constructor(
    private translate: TranslateService,
    private authService: AuthService,
    private store: Store,
    private PrimeNGConfig: PrimeNGConfig,
    private appInitializerService: AppInitializerService,
    private titleService: Title
  ) {
    translate.setDefaultLang('fr');
    translate.use(this.currLanguage);

    if (this.currLanguage === 'fr') {
      registerLocaleData(localeFr);
    } else if (this.currLanguage === 'en') {
      registerLocaleData(localeEn);
    }
  }

  notifications: notificationInterface[] = [];
  ngOnInit(): void {
    this.appInitializerService.initializeApp().then(() => {
      this.isAppLoaded = true;

      this.translate
        .stream('primeng')
        .subscribe((res) => this.PrimeNGConfig.setTranslation(res));

      this.store.select(getUserLanguage).subscribe((user) => {
        if (user.language !== this.currLanguage) {
          this.translate.use(user.language.toLowerCase());
        }
      });

      this.store.dispatch(
        notificationActions.getNotifications({
          notifications: mockNotifications,
        })
      );
    });

    this.store.select(getNotifications).subscribe((notifications) => {
      this.notifications = notifications;
    });

    this.setTabTitle();
  }

  setTabTitle() {
    const tenant = window.location.hostname.split('.').shift();
    let title = 'Astus';

    switch (tenant) {
      case TenantsType.CLIENTHORIZON:
        title = 'Horizon';
        break;
      case TenantsType.CLIENTSCOLAIRE:
        title = 'Scolaire';
        break;
      case TenantsType.VIDEOTRON:
        title = 'Videotron';
        break;
      case TenantsType.LOCALHOST:
        title = 'Localhost';
        break;
      default:
        title = 'Astus';
    }

    this.titleService.setTitle(title);
  }

  generateNotifs = setInterval(() => {
    this.newNotification();
  }, 5000);

  newNotification = (): void => {
    const ranAsset = Math.floor(Math.random() * 100);
    const notif: notificationInterface = {
      id: mockNotifications.length + 10,
      type: this.getRandomNotificationType(),
      unread: true,
      createdDate: new Date().toLocaleDateString('fr-ca'),
      createdTime: new Date().toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      }),
      circuit: `Circuit ${Math.floor(Math.random() * 99)}`,
      route: `Route ${Math.floor(Math.random() * 99)}`,
      message: '24 Juin 2023 - 4:00 AM A 6:00 AM',
      asset: `Autobus ${ranAsset}`,
      assetNum: ranAsset,
      sender: 'Test user',
    };
    const newNotifs: notificationInterface[] = [...this.notifications, notif];
    const filtered = newNotifs.filter(
      (notif) => notif.type !== notificationTypeEnum.SEPARATOR
    );

    filtered.sort((a, b) => {
      return (
        new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
      );
    });

    this.store.dispatch(
      notificationActions.getNotifications({
        notifications: filtered,
      })
    );

    if (filtered.length > 10) {
      clearInterval(this.generateNotifs);
    }
  };

  getRandomNotificationType(): notificationTypeEnum {
    const values = Object.values(notificationTypeEnum);
    const randomIndex = Math.floor(Math.random() * values.length);
    return values[randomIndex] === notificationTypeEnum.SEPARATOR
      ? notificationTypeEnum.MISE
      : (values[randomIndex] as notificationTypeEnum);
  }
}
