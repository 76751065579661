import { Injectable } from '@angular/core';
import { CircuitsService, ResourcesService } from '@fms/ng-fms-api-client';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  exhaustMap,
  map,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';

import { resourceActions } from './resource.action';
import { getCircuitPagination } from './resource.selector';

@Injectable()
export class ResourceEffects {
  readonly getVehicles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resourceActions.getAllVehicles),
      exhaustMap(() =>
        this.resourcesService.resourcesControllerFindAllVehicleResources().pipe(
          map((vehicles) => ({
            type: resourceActions.vehiclesLoaded.type,
            vehicles: vehicles,
          })),
          catchError((error) =>
            of({
              type: resourceActions.vehiclesError.type,
              error: {
                message: 'Error loading vehicles',
                error,
              },
            })
          )
        )
      )
    )
  );

  /**
   * Get all circuits from the API
   */
  readonly getCircuits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resourceActions.getAllCircuits),
      withLatestFrom(this.store.select(getCircuitPagination)),
      exhaustMap(([, pagination]) =>
        this.circuitsService
          .circuitsControllerFindAllCircuits(
            true,
            pagination.page,
            pagination.pageSize
          )
          .pipe(
            map((circuits) => ({
              type: resourceActions.circuitsLoaded.type,
              circuits: circuits.items,
            })),
            catchError((error) =>
              of({
                type: resourceActions.circuitsError.type,
                error: {
                  message: 'Error loading circuits',
                  error,
                },
              })
            )
          )
      )
    )
  );

  /**
   * Import a circuit from a geobus file
   */
  readonly importGeobusCircuit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resourceActions.importGeobusCircuit),
      exhaustMap(({ filename, base64Data }) =>
        this.circuitsService
          .circuitsControllerImportCircuitFromGeobus({
            data: base64Data,
            filename,
          })
          .pipe(
            switchMap(() => [{ type: resourceActions.getAllCircuits.type }]),
            catchError((error) =>
              of({
                type: resourceActions.circuitsError.type,
                error: {
                  message: 'Error importing circuits',
                  error,
                },
              })
            )
          )
      )
    )
  );

  readonly createVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resourceActions.createVehicle),
      exhaustMap(({ vehicle }) =>
        this.resourcesService
          .resourcesControllerCreateResourceVehicle(vehicle)
          .pipe(
            map(() => ({
              type: resourceActions.getAllVehicles.type,
            })),
            catchError((error) =>
              of({
                type: resourceActions.vehiclesError.type,
                error: {
                  message: 'SNACKBAR.ERROR.VEHICLE_CREATION',
                  error,
                },
              })
            )
          )
      )
    )
  );

  readonly updateVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resourceActions.updateVehicle),
      exhaustMap(({ id, vehicle }) =>
        this.resourcesService
          .resourcesControllerUpdateResourceVehicle(id, vehicle)
          .pipe(
            map(() => ({
              type: resourceActions.getAllVehicles.type,
            })),
            catchError((error) =>
              of({
                type: resourceActions.vehiclesError.type,
                error: {
                  message: 'SNACKBAR.ERROR.VEHICLE_UPDATE',
                  error,
                },
              })
            )
          )
      )
    )
  );

  readonly findAllInfoboxVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resourceActions.findAllInfoboxVehicleLoad),
      exhaustMap(({ id }) =>
        this.resourcesService
          .resourcesControllerFindAllInfoboxVehicleResources(id)
          .pipe(
            map((vehicleFormDropdowns) => ({
              type: resourceActions.findAllInfoboxVehicleLoaded.type,
              vehicleFormDropdowns,
            })),
            catchError((error) =>
              of({
                type: resourceActions.vehiclesError.type,
                error: {
                  message: 'SNACKBAR.ERROR.UNEXPECTED',
                  error,
                },
              })
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private resourcesService: ResourcesService,
    private circuitsService: CircuitsService
  ) {}
}
