import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ColorType } from '../../common-ng-design-system.colors';

import { CheckboxSizes, CheckboxType } from './checkbox.enum';

@Component({
  selector: 'ad-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CheckboxMolecule,
    },
  ],
})
export class CheckboxMolecule implements ControlValueAccessor {
  @ViewChild('checkboxRef') checkboxRef:
    | ElementRef<HTMLInputElement>
    | undefined;

  customCheckBoxText!: ElementRef;

  @Input()
  text = '';

  @Input()
  selected = false;

  @Input()
  disabled = false;

  @Input()
  indeterminate = false;

  @Input()
  id = 'default';

  @Input()
  name = 'default';

  @Input()
  value = 'value';

  @Input()
  size: CheckboxSizes = 'md';

  @Input()
  color: ColorType = 'primary';

  @Input()
  type: CheckboxType = 'square';

  @Input()
  formControlName: string | undefined;

  @Output() checkboxEvent = new EventEmitter<boolean>();

  // Had put a default empty function here, it was causing a lint error
  // eslint-disable-next-line
  onChange = (inputValue: boolean) => {};

  // Had put a default empty function here, it was causing a lint error
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  touchedValue = false;

  /**
   * This function is called when the user changes the value of the input.
   * It emits the new value of the input.
   */
  onInputChange(): void {
    this.selected = !this.selected;
    this.checkboxEvent.emit(this.selected);
    this.onChange(this.selected);
  }

  /**
   * This function is called when the user clicks on the label. It prevents the default behavior of the click event
   * and calls the onInputChange function.
   */
  handleInputChange(): void {
    if (this.type === 'square') {
      if (this.checkboxRef) {
        this.checkboxRef.nativeElement.indeterminate = false;
      }
      this.indeterminate = false;
    }
    this.onInputChange();
  }

  writeValue(currValue: boolean) {
    this.selected = currValue;
  }

  // I need to use "any" here because the Angular function literally uses "any" as the type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  // I need to use "any" here because the Angular function literally uses "any" as the type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touchedValue) {
      this.onTouched();
      this.touchedValue = true;
    }
  }

  setDisabledState(disabledValue: boolean) {
    this.disabled = disabledValue;
  }
}
