import { InjectionToken } from '@angular/core';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';

export const CONFIGMapBox = new InjectionToken<IMapboxConfig>('mapbox-config');

export interface IMapboxConfig {
  username: string;
  accessToken: string;
  mapDefaultStyle: string;
  mapSatelliteStyle: string;
  mapTrafficStyle: string;
  mapBikeStyle: string;
  mapOutdoorsStyle: string;
}

export type IMapboxDirectionProfiles =
  | 'driving-traffic'
  | 'driving'
  | 'walking'
  | 'cycling';

export enum IMapboxFeatureType {
  Point = 'circle',
  Polygon = 'fill',
  LineString = 'line',
}

export interface IMapboxDirectionLocalization {
  lat: number;
  lng: number;
}

export interface IMapboxDataset {
  owner: string;
  id: string;
  created: string;
  modified: string;
  bounds: [number, number] | [number, number, number, number];
  features: number;
  size: number;
  name?: string;
  description?: string;
}

export interface IMapboxDatasetFeatures {
  type: string;
  features: Feature<Geometry, GeoJsonProperties>[];
}

export interface IMapboxDirectionsResponse {
  code: string;
  waypoints?: IMapboxWaypoint[];
  routes: IMapboxRoute[];
  uuid: string;
}

interface IMapboxWaypoint {
  name: string;
  location: [number, number];
}

export interface IMapboxRoute {
  distance: number;
  waypoints?: IMapboxWaypoint[];
  duration: number;
  geometry: IMapboxGeometry;
  weight: number;
  weight_name: string;
  legs: IMapboxLeg[];
  voiceLocale?: string;
}

export interface IMapboxGeometry {
  coordinates: Array<[number, number]>;
  // coordinates: Array<Array<number[] | number> | number>;
  type: string;
}

interface IMapboxLeg {
  distance: number;
  duration: number;
  summary: string;
  steps: IMapboxStep[];
  annotation?: IMapboxAnnotation;
}

interface IMapboxStep {
  distance: number;
  duration: number;
  geometry: string;
  name: string;
  mode: string;
  maneuver: IMapboxManeuver;
  voiceInstructions: IMapboxVoiceInstruction[];
}

interface IMapboxManeuver {
  bearing_after: number;
  bearing_before: number;
  location: [number, number];
  modifier: string;
  type: string;
}

interface IMapboxVoiceInstruction {
  distanceAlongGeometry: number;
  announcement: string;
  ssmlAnnouncement?: string;
}

interface IMapboxAnnotation {
  distance: number[];
  duration: number[];
  speed: number[];
  maxspeed: number[];
  congestion: string[];
  name: string[];
  exit: string[];
  pronunciation: string[];
}

export interface IMapboxPaintProperties {
  // Fill properties
  'fill-opacity'?: number;
  'fill-color'?: string;
  'fill-outline-color'?: string;
  'fill-translate'?: number[];
  'fill-translate-anchor'?: 'map' | 'viewport';
  'fill-pattern'?: string;

  // Line properties
  'line-opacity'?: number;
  'line-color'?: string;
  'line-translate'?: number[];
  'line-translate-anchor'?: 'map' | 'viewport';
  'line-width'?: number;
  'line-gap-width'?: number;
  'line-offset'?: number;
  'line-blur'?: number;
  'line-dasharray'?: number[];
  'line-pattern'?: string;

  // Symbol properties
  'icon-opacity'?: number;
  'icon-color'?: string;
  'icon-halo-color'?: string;
  'icon-halo-width'?: number;
  'icon-halo-blur'?: number;
  'icon-translate'?: number[];
  'icon-translate-anchor'?: 'map' | 'viewport';
  'icon-rotate'?: number;
  'icon-size'?: number;
  'icon-text-fit'?: 'none' | 'width' | 'height' | 'both';
  'icon-text-fit-padding'?: number[];

  // Text properties
  'text-opacity'?: number;
  'text-color'?: string;
  'text-halo-color'?: string;
  'text-halo-width'?: number;
  'text-halo-blur'?: number;
  'text-translate'?: number[];
  'text-translate-anchor'?: 'map' | 'viewport';
  'text-rotate'?: number;
  'text-size'?: number;
  'text-max-width'?: number;
  'text-line-height'?: number;
  'text-letter-spacing'?: number;
  'text-justify'?: 'auto' | 'left' | 'center' | 'right';
  'text-anchor'?: 'center' | 'left' | 'right' | 'top' | 'bottom';
  'text-max-angle'?: number;
  'text-rotate-alignment'?: 'auto' | 'map' | 'viewport';
  'text-padding'?: number;
  'text-keep-upright'?: boolean;
  'text-transform'?: 'none' | 'uppercase' | 'lowercase';
  'text-offset'?: number[];
  'text-allow-overlap'?: boolean;
  'text-ignore-placement'?: boolean;
  'text-optional'?: boolean;
  'text-pitch-alignment'?: 'auto' | 'map' | 'viewport';

  // Circle properties
  'circle-radius'?: number;
  'circle-color'?: string;
  'circle-blur'?: number;
  'circle-opacity'?: number;
  'circle-translate'?: number[];
  'circle-translate-anchor'?: 'map' | 'viewport';
  'circle-scale'?: 'map' | 'viewport';
  'circle-stroke-width'?: number;
  'circle-stroke-color'?: string;
}

export interface IMapboxLayoutProperties {
  // Symbol layout properties
  'symbol-placement'?: 'point' | 'line' | 'line-center';
  'symbol-spacing'?: number;
  'symbol-avoid-edges'?: boolean;
  'symbol-sort-key'?: number;
  'symbol-z-order'?: 'auto' | 'viewport-y' | 'source';

  // Line layout properties
  'line-cap'?: 'butt' | 'round' | 'square';
  'line-join'?: 'bevel' | 'round' | 'miter';
  'line-miter-limit'?: number;
  'line-round-limit'?: number;
  'line-sort-key'?: number;

  // Fill layout properties
  'fill-sort-key'?: number;

  // Circle layout properties
  'circle-sort-key'?: number;

  // Fill extrusion layout properties
  'fill-extrusion-sort-key'?: number;
  'fill-extrusion-height'?: number;
  'fill-extrusion-base'?: number;
  'fill-extrusion-opacity'?: number;
  'fill-extrusion-color'?: string;
  'fill-extrusion-translate'?: number[];
  'fill-extrusion-translate-anchor'?: 'map' | 'viewport';
  'fill-extrusion-pattern'?: string;
  'fill-extrusion-vertical-gradient'?: boolean;

  // Heatmap layout properties
  'heatmap-radius'?: number;
  'heatmap-weight'?: number;
  'heatmap-intensity'?: number;
  'heatmap-color'?: string;
  'heatmap-opacity'?: number;

  // Raster layout properties
  'raster-opacity'?: number;
  'raster-hue-rotate'?: number;
  'raster-brightness-min'?: number;
  'raster-brightness-max'?: number;
  'raster-saturation'?: number;
  'raster-contrast'?: number;
  'raster-resampling'?: 'linear' | 'nearest';

  // Background layout properties
  'background-color'?: string;
  'background-pattern'?: string;

  // Transition properties
  'transition-duration'?: number;
  'transition-delay'?: number;
}

export type CircuitBounceRouteType = {
  circuitId: string;
  coordinates: [number, number][];
};

export type MapViewArea = {
  topLeft: IMapboxDirectionLocalization;
  topRight: IMapboxDirectionLocalization;
  bottomRight: IMapboxDirectionLocalization;
  bottomLeft: IMapboxDirectionLocalization;
};
