<div class="alert-card-container" [ngClass]="status">
  <!-- Title/Subtitle/Icon Container -->
  <div class="alert-card-title-container">
    <!-- Icon Container -->
    <div class="alert-card-icon-container" *ngIf="icon">
      <ad-icon class="alert-icon" [glyph]="icon" [color]="iconColor"></ad-icon>
    </div>
    <!-- Title/Subtitle Container -->
    <div class="col">
      <div class="alert-title hide-scrollbar text-caption--semibold">
        {{ title }}
      </div>
      <div
        class="alert-title hide-scrollbar text-caption"
        *ngIf="subtitle.length"
      >
        {{ subtitle }}
      </div>
    </div>
  </div>
  <!-- Description Container-->
  <div
    class="description-container hide-scrollbar text-footer"
    *ngIf="description.length"
  >
    {{ description }}
  </div>
  <!-- Button Container -->
  <div
    class="button-container"
    *ngIf="communicateText.length || resolveText.length"
  >
    <button
      *ngIf="communicateText.length"
      class="text-caption"
      astus-design-button
      [size]="'xs'"
      kind="tonal"
      color="neutral"
      (click)="this.communicateEvent.emit()"
    >
      {{ communicateText }}
    </button>
    <button
      *ngIf="resolveText.length"
      class="text-caption"
      astus-design-button
      [size]="'xs'"
      kind="filled"
      color="resolve"
      (click)="this.solveEvent.emit()"
    >
      {{ resolveText }}
    </button>
  </div>
</div>
