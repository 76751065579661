import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { popoverOptionType } from '@common/ng-design-system';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';

import { appRoutesEnum, resourceTabType } from '../../../app-routing.module';
import { controlPanelCircuitsCardInterface } from '../../map-control-panel/map-control-panel.component.d';

@Component({
  selector: 'astus-map-list-circuit-card',
  templateUrl: './map-list-circuit-card.component.html',
  styleUrls: ['./map-list-circuit-card.component.scss'],
})
export class MapListCircuitCardComponent implements OnInit {
  @Input() circuit: controlPanelCircuitsCardInterface | undefined = undefined;
  @Input() isFirst = false;
  @Input() isSelected = false;
  @Input() hideAssignButton = false;

  moreOptions: popoverOptionType[] = [];
  moreOptionEditTag = 'edit-circuit';

  @Output() cardClick = new EventEmitter();
  @Output() assignEvent = new EventEmitter();
  @Output() hoveredEvent = new EventEmitter();

  constructor(
    private translateService: TranslateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.translateService.stream('CARDS.MODIFY_CIRCUIT'),
    ]).subscribe(([modifyCircuit]) => {
      this.moreOptions = [
        {
          id: `${this.moreOptionEditTag}-${this.circuit?.id}`,
          value: modifyCircuit,
        },
      ];
    });
  }

  /**
   * @description
   * Use to format the driver's name. It takes the first letter of the first two groups of string, generally the first and last name
   * @returns {void}
   */
  public formatVehicleAvatarTextValue(name: string): string {
    const driverName = name.split(' ');
    return (
      driverName[0].charAt(0) +
      (driverName.length > 1 ? driverName.at(-1)?.charAt(0) : '')
    );
  }

  /**
   * @description
   * Use to format the vehicle's name. It searches for numbers to parse them
   * If there is no number, it simply takes the name
   * @returns {void}
   */
  public formatVehicleAvatarIconTooltipValue(name: string): string {
    return name.match(/\d+/g)
      ? parseInt(name.replace(/\D/g, '')).toString()
      : name;
  }

  /**
   * @description
   * Use to format the circuit's name. It searches for numbers to parse them
   * If there is no number, it simply takes the name
   * @returns {void}
   */
  public formatVehicleName(name: string): string {
    if (name.match(/\d+/g)) {
      return parseInt(name.replace(/\D/g, '')).toString();
    } else {
      return name;
    }
  }

  /**
   * @description Handles the popover option click
   * Checks for the "Modify circuit" option and navigates to the circuit edit form in Resources
   * @param {popoverOptionType} option
   */
  handleMoreOptionClick(option: popoverOptionType) {
    if (option.id?.indexOf(this.moreOptionEditTag) !== -1) {
      const url = `${appRoutesEnum.RESOURCES}/${resourceTabType.CIRCUITS}/${this.circuit?.id}`;
      this.router.navigate([url]);
    }
  }
}
