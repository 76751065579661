<div class="settings-header" *ngIf="isMobile">
  <ad-icon
    class="settings-icon"
    glyph="chevron_left"
    color="neutral-900"
    (click)="onIconClick()"
  ></ad-icon>
  <div>
    <ad-icon glyph="settings" color="neutral-700"></ad-icon>
  </div>
</div>
