import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'astus-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  images = [
    '../../../../../assets/images/dog_1.svg',
    '../../../../../assets/images/plug.svg',
  ];

  backgroundImage = '';
  constructor(private location: Location) {}

  ngOnInit() {
    const index = Math.floor(Math.random() * this.images.length);
    this.backgroundImage = this.images[index];
  }
  goBack() {
    this.location.back();
  }
}
