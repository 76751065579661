import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';

import { Router } from '@angular/router';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';

import { alertActions, snackbarState } from '../../store';

import { snackbarAnimations } from './alert-snackbar.animation';

@Component({
  selector: 'astus-alert-snackbar',
  templateUrl: './alert-snackbar.component.html',
  styleUrls: ['./alert-snackbar.component.scss'],
  animations: snackbarAnimations,
})
export class AlertSnackbarComponent implements OnInit, AfterViewInit {
  snackbars: snackbarState[] = [];
  unresolvedSnackbars: snackbarState[] = [];

  showOpenCloseAllContainerDelayed = false;
  count = 0;
  snooze = false;
  timeoutIds: ReturnType<typeof setTimeout>[] = [];
  headerHeight = 0;
  containerBottom = 0;
  snackbarHeight = 0;
  showSnoozeButton = false;

  showStaticContainer = false;

  @Input() timeToAutoClose = 20000;
  @ViewChild('snackbarInner', { read: ElementRef }) snackbarInner?: ElementRef;
  @ViewChild('notificationContainer', { static: false })
  notificationContainer?: ElementRef;
  @ViewChild('staticNotificationContainer', { static: false })
  staticNotificationContainer?: ElementRef;

  constructor(
    private actionsSubject: ActionsSubject,
    private router: Router,
    private elRef: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.getSnackbars();
    this.setRootStyles();
  }

  ngAfterViewInit(): void {
    this.updateSnackbarHeight();
  }

  /**
   * Retrieves snackbars from the actions subject and adds them to the snackbars array.
   * Also performs various UI updates and sets a timeout to automatically close the snackbar.
   */
  getSnackbars() {
    this.actionsSubject
      .pipe(ofType(alertActions.showNewSnackbar))
      .subscribe((snackbar) => {
        const newId = this.count++;
        const newSnackbar = {
          ...snackbar.snack,
          id: newId,
          hide: false,
          resolved: false,
        };
        this.snackbars.push(newSnackbar);
        this.unresolvedSnackbars.push(newSnackbar);

        this.updateSnackbarHeight();
        this.updateSnoozeButtonVisibility();

        const timeoutId = setTimeout(() => {
          this.onCloseSnackbar(newId);
        }, this.timeToAutoClose);

        this.timeoutIds.push(timeoutId);

        // add animate-in class to the newly added snackbar in the static container
        setTimeout(() => {
          const addedSnackbar = document.querySelector(
            `.snackbar-container:nth-child(${this.snackbars.length})`
          );
          if (addedSnackbar) {
            addedSnackbar.classList.add('animate-in');
          }
          this.scrollToBottom();
        });
      });
  }

  /**
   * Scrolls the notification container to the bottom.
   */
  private scrollToBottom(): void {
    if (this.notificationContainer) {
      this.notificationContainer.nativeElement.scrollTop =
        this.notificationContainer.nativeElement.scrollHeight;
    }
    if (this.staticNotificationContainer) {
      this.staticNotificationContainer.nativeElement.scrollTop =
        this.staticNotificationContainer.nativeElement.scrollHeight;
    }
  }

  /**
   * Sets the headerHeight and containerBottom properties based on the computed styles.
   */
  private setRootStyles() {
    const root = document.documentElement;
    const style = getComputedStyle(root);
    this.headerHeight =
      parseInt(style.getPropertyValue('--header-height'), 10) || 0;
    this.containerBottom =
      parseInt(
        window
          .getComputedStyle(this.elRef.nativeElement)
          .getPropertyValue('--snackbar-system-bottom'),
        10
      ) || 0;
  }

  /**
   * Clears the notification at the specified index.
   * It removes the snackbar element from the DOM, updates the snackbars array, and performs UI updates.
   * @param index - The index of the snackbar to clear.
   */
  clearNotification(index: number) {
    const snackbar = document.querySelector(
      `.snackbar-container:nth-child(${index + 1})`
    );
    this.renderer.removeClass(snackbar, 'animate-in');
    if (snackbar) {
      this.renderer.addClass(snackbar, 'animate-out');

      setTimeout(() => {
        snackbar.remove();
        this.snackbars.splice(index, 1);
        this.unresolvedSnackbars.splice(index, 1);

        // Check if there are any remaining snackbars
        if (this.snackbars.length === 0) {
          // If there are no more snackbars, hide the Snooze button as well
          this.showSnoozeButton = false;
          this.showStaticContainer = false;
        }
      }, 500); // this time should match the duration of the snackarAnimation (see snackbar.animation.ts file)
    }
  }

  /**
   * Closes the snackbar with the specified id.
   *
   * @param id - The id of the snackbar to close.
   */
  onCloseSnackbar(id: number) {
    const snackbarToUpdate = this.snackbars.find(
      (snackbar) => snackbar.id === id
    );
    if (snackbarToUpdate) {
      const updatedSnackbar = { ...snackbarToUpdate, hide: true };
      const index = this.snackbars.findIndex((snackbar) => snackbar.id === id);
      this.snackbars[index] = updatedSnackbar;
      this.unresolvedSnackbars.splice(0, 1);
    }

    this.updateSnoozeButtonVisibility();

    /**
     * If there are no more unresolved snackbars, snooze the notifications and
     * show the open/close all container after a delay of 500 milliseconds which is the delay
     * for the last snackbar to end its snackarAnimation.
     */
    if (this.unresolvedSnackbars.length === 0) {
      this.onSnooze();
      setTimeout(() => {
        this.showOpenCloseAllContainerDelayed = true;
      }, 500);
    }
  }

  /**
   * Opens the snackbar with the specified ID and updates its resolved status.
   * If the snackbar is found, it updates the resolved status to true and navigates to the specified URL.
   * @param id The ID of the snackbar to be opened.
   */
  onOpenSnackbar(id: number) {
    const snackbarToUpdate = this.snackbars.find(
      (snackbar) => snackbar.id === id
    );
    if (snackbarToUpdate) {
      const updatedSnackbar = {
        ...snackbarToUpdate,
        resolved: true,
        hide: true,
      };
      const index = this.snackbars.findIndex((snackbar) => snackbar.id === id);
      this.snackbars[index] = updatedSnackbar;
      this.router.navigate([updatedSnackbar.content.url]);
    }
  }

  /**
   * Updates the visibility of the snooze button based on the calculated height percentage.
   */
  updateSnoozeButtonVisibility() {
    const maxHeight = 100; // The maximum height percentage at which the snooze button should be displayed
    this.showSnoozeButton = this.calculateHeightPercentage() >= maxHeight;
  }

  /**
   * Updates the height of one snackbar. Used to calculate the height percentage after a snackbar creation.
   */
  updateSnackbarHeight() {
    if (this.snackbarInner) {
      this.snackbarHeight = this.snackbarInner.nativeElement.offsetHeight;
    }
  }

  /**
   * Calculates the height percentage of the alert snackbars. Used to determine if the snooze
   *  button should be displayed.
   * @returns The height percentage of the alert snackbars.
   */
  calculateHeightPercentage(): number {
    const screenHeight = window.innerHeight;
    const snackbarsHeight = this.snackbars.length * this.snackbarHeight;

    return (
      (snackbarsHeight /
        (screenHeight - this.headerHeight - this.containerBottom)) *
      100
    );
  }

  /**
   * Checks if all snackbars are hidden.
   * @returns {boolean} True if all snackbars are hidden, false otherwise.
   */
  isAllHidden(): boolean {
    return this.snackbars.every((snackbar) => snackbar.hide);
  }

  /**
   * Toggles the snooze state and clears any existing timeouts.
   */
  onSnooze() {
    this.snooze = !this.snooze;
    if (this.snooze) {
      this.timeoutIds.forEach((timeout) => {
        clearTimeout(timeout);
      });
      this.timeoutIds = [];
    }
  }

  /**
   * Closes the static container and performs additional actions.
   * Adds the 'animate-out' class to the staticNotificationContainer element.
   * Uses setTimeout to remove the container after the animation duration.
   * Removes the container from the DOM.
   */
  closeStaticContainer() {
    const staticNotificationContainer =
      this.staticNotificationContainer?.nativeElement;
    staticNotificationContainer.classList.add('animate-out');

    setTimeout(() => {
      this.showStaticContainer = false;
      this.showOpenCloseAllContainerDelayed = true;
      staticNotificationContainer.remove();
    }, 500);
  }

  /**
   * Closes all snackbars and performs additional actions.
   */
  onCloseAll() {
    this.snackbars = [];
    this.unresolvedSnackbars = [];
    this.onSnooze();
    this.showSnoozeButton = false;
    this.showOpenCloseAllContainerDelayed = false;
  }

  /**
   * Unhides all unresolved snackbars. Used when the snooze button is clicked.
   */
  unhideAllUnresolved() {
    this.snackbars.forEach((snackbar) => {
      if (!snackbar.resolved) {
        snackbar.hide = false;
      }
    });
  }

  /**
   * Opens all alerts. Used when the snooze button is clicked.
   */
  openAll() {
    this.unresolvedSnackbars = [...this.snackbars];
    this.showOpenCloseAllContainerDelayed = false;
    this.showStaticContainer = true;

    // Scroll to the bottom of the static container before the container is shown

    this.onSnooze();
    this.showSnoozeButton = true;

    setTimeout(() => {
      this.scrollToBottom();
    }, 500);
  }

  /**
   * Returns the number of unresolved snackbars.
   * @returns The number of unresolved snackbars.
   */
  getNumberOfUnresolvedSnackbars(): number {
    return this.snackbars.filter((snackbar) => !snackbar.resolved).length;
  }

  /**
   * Closes the notification container and triggers the closing animation.
   * After the animation is complete, the notification container is removed from the DOM.
   * The `showOpenCloseAllContainerDelayed` flag is set to `true` to indicate that the #openCloseAll container should be shown after a delay.
   */
  closeNotificationContainer() {
    const notificationContainer = this.notificationContainer?.nativeElement;
    this.renderer.addClass(notificationContainer, 'animate-out');
    setTimeout(() => {
      this.notificationContainer?.nativeElement.remove();

      this.showOpenCloseAllContainerDelayed = true;
    }, 500);
  }
}
