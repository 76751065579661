import { createReducer, on } from '@ngrx/store';

import { buttonTabEnum } from '../map-view-tabs-button/map-view-tabs-button.component';

import { mapReducerInterface } from './map';
import { mapAPIActions } from './map.action';

export const mapFeatureKey = 'mapFeature';

export const mapInitialState: mapReducerInterface = {
  stopCoordinated: {
    loaded: false,
    data: [],
  },
  circuits: {
    loaded: false,
    data: [],
  },
  vehicles: {
    loaded: false,
    data: [],
  },
  users: {
    loaded: false,
    data: [],
  },

  supervisionModeSelected: buttonTabEnum.MAP,
  supervisionSegmentUrl: null,
  vehiclePositions: {},
  vehiclesPositions: [],
};

export const mapFeatureReducer = createReducer<mapReducerInterface>(
  mapInitialState,
  on(mapAPIActions.stopsLoaded, (_state, { stopCoordinatedViewList }) => ({
    ..._state,
    stopCoordinated: {
      loaded: true,
      data: stopCoordinatedViewList,
    },
  })),
  on(mapAPIActions.stopsError, (_state, { error, message }) => ({
    ..._state,
    error: {
      error,
      message,
    },
  })),
  on(mapAPIActions.circuitsLoaded, (_state, { circuitsPanelList }) => ({
    ..._state,
    circuits: {
      loaded: true,
      data: circuitsPanelList,
    },
  })),
  on(mapAPIActions.circuitsError, (_state, { error, message }) => ({
    ..._state,
    circuits: {
      ..._state.circuits,
      loaded: true, // circuit is loaded, but with error
    },
    error: {
      error,
      message,
    },
  })),
  on(mapAPIActions.vehiclesLoaded, (_state, { vehiclesPanelList }) => ({
    ..._state,
    vehicles: {
      loaded: true,
      data: vehiclesPanelList,
    },
  })),
  on(mapAPIActions.vehiclesError, (_state, { error, message }) => ({
    ..._state,
    error: {
      error,
      message,
    },
  })),
  on(mapAPIActions.usersLoaded, (_state, { usersPanelList }) => ({
    ..._state,
    users: {
      loaded: true,
      data: usersPanelList,
    },
  })),
  on(mapAPIActions.usersError, (_state, { error, message }) => ({
    ..._state,
    error: {
      error,
      message,
    },
  })),

  on(
    mapAPIActions.supervisionModeSelected,
    (_state, { supervisionModeSelected }) => ({
      ..._state,
      supervisionModeSelected,
    })
  ),

  on(
    mapAPIActions.supervisionSegmentUrl,
    (_state, { supervisionSegmentUrl }) => ({
      ..._state,
      supervisionSegmentUrl,
    })
  ),

  on(mapAPIActions.vehiclePositionLoaded, (_state, { vehiclePosition }) => {
    const { id, vssSignals } = vehiclePosition;
    const newPosition = {
      ..._state.vehiclePositions,
      [id]: vssSignals,
    };

    return {
      ..._state,
      vehiclePositions: newPosition,
    };
  }),

  on(
    mapAPIActions.vehiclesPositionsLoaded,
    (_state, { vehiclesPositions }) => ({
      ..._state,
      vehiclesPositions,
    })
  )
);
