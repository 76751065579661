import { Component, Input, OnInit } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';

import { alertActions, alertState } from '../store';

@Component({
  selector: 'astus-alert-system',
  templateUrl: './alert-system.component.html',
  styleUrls: ['./alert-system.component.scss'],
})
export class AlertSystemComponent implements OnInit {
  alerts: alertState[] = [];
  count = 1;

  @Input()
  timeToAutoClose = 8000;

  constructor(private actionsSubject: ActionsSubject) {}

  ngOnInit(): void {
    this.actionsSubject
      .pipe(ofType(alertActions.showNewAlert))
      .subscribe((alert) => {
        const newId = this.count++;
        this.alerts.push({
          ...alert.alert,
          id: newId,
          hide: false,
        });

        setTimeout(() => {
          this.onCloseAlert(newId);
        }, this.timeToAutoClose);
      });
  }

  alertId(index: number, item: alertState): number {
    return item.id;
  }

  onCloseAlert(id: number) {
    const alertToUpdate = this.alerts.find((alert) => alert.id === id);
    if (alertToUpdate) {
      const updatedAlert = { ...alertToUpdate, hide: true };
      const index = this.alerts.findIndex((alert) => alert.id === id);
      this.alerts[index] = updatedAlert;
    }
  }
}
