<div class="sort-filter-container">
  <div
    class="filter-fab-container"
    (click)="openOrCloseDialog()"
    [ngClass]="{ '--active': open }"
  >
    <ad-icon kind="tonal" [glyph]="icon" class="hide-icon"></ad-icon>
    <span class="text-p3">{{ labelContent }}</span>
  </div>
  <dialog
    class="overlay-container"
    @dropdownAnimation
    *ngIf="open"
    [ngClass]="{ 'is-open': open }"
  >
    <header class="header-container">
      <div class="title--semibold">{{ headerTitle }}</div>
    </header>
    <div class="content-container">
      <ng-content select="content-filters"></ng-content>
    </div>
    <footer class="footer-container">
      <button
        class="cta"
        astus-design-button
        kind="outlined"
        color="neutral"
        size="xs"
        (click)="onReset()"
        [disabled]="buttonResetDisabled"
      >
        {{ resetLabel }}
      </button>
      <button
        class="cta"
        astus-design-button
        kind="filled"
        color="primary"
        size="xs"
        (click)="onApply()"
        [disabled]="buttonApplyDisabled"
      >
        {{ applyLabel }}
      </button>
    </footer>
  </dialog>
</div>
