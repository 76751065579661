import type { Environment } from './environment.type';

export const environment: Environment = {
  production: true,
  name: 'docker',
  auth0: {
    domain: '%%AUTH0_DOMAIN%%',
    clientId: '%%AUTH0_CLIENT_ID%%',
    authorizationParams: {
      // eslint-disable-next-line camelcase
      redirect_uri: window.location.origin,
      audience: '%%AUTH0_AUDIENCE%%',
    },
  },
  api: {
    serverUrl: '%%API_SERVER_URL%%',
  },
  googleMaps: {
    apiKey: '%%GOOGLEMAPS_API_KEY%%',
  },
  mapbox: {
    accessToken: '%%MAPBOX_ACCESS_TOKEN%%',
    username: '%%MAPBOX_USERNAME%%',
    mapDefaultStyle: '%%MAPBOX_MAP_DEFAULT_STYLE%%',
    mapSatelliteStyle: '%%MAPBOX_MAP_SATELLITE_STYLE%%',
    mapTrafficStyle: '%%MAPBOX_MAP_TRAFFIC_STYLE%%',
    mapBikeStyle: '%%MAPBOX_MAP_BIKE_STYLE%%',
    mapOutdoorsStyle: '%%MAPBOX_MAP_OUTDOOR_STYLE%%',
  },
  here: {
    apiKey: '%%HERE_API_KEY%%',
    vectorStyle: '%%HERE_VECTOR_STYLE%%',
    mapStyle: '%%HERE_MAP_STYLE%%',
  },
  launchDarkly: {
    clientSideId: '%%LAUNCHDARKLY_CLIENT_SIDE_ID%%',
    clientContext: 'Scholar',
  },
};
